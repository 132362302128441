import React, { useState } from 'react';
import './CoachPageImage.scss';
import AvatarIcon from '../../icons/avatar.svg';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import { getPublicFile } from '../../core/api';
import ImageCropUploadModal from '../Modals/ImageCropUploadModal/ImageCropUploadModal';
import Tooltip from '../Tooltip/Tooltip';

interface iProps {
  imageId: string;
  isInEditor: boolean;
  setNewImageId: (imageId: any) => void;
  className?: string;
}

const MAX_IMAGE_SIZE = 3 * 1024 * 1024;
const MAX_FILE_TYPES = ['png', 'jpeg', 'svg'];

const CoachPageImage: React.FC<iProps> = ({
  imageId,
  isInEditor,
  setNewImageId,
  className = ''
}) => {
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);

  const handleImageModalClose = () => {
    setIsImageModalOpened(false);
  };

  const handleImageModalOpen = () => {
    setIsImageModalOpened(true);
  };

  const handleImageUploaded = (imageId: string) => {
    setNewImageId(imageId);
    setIsImageModalOpened(false);
  };

  const handleClickDelete = () => {
    setNewImageId('');
  };

  return (
    <div
      className={`CoachPageImage ${
        imageId.length > 1 ? 'CoachPageImage--has-image' : ''
      } ${className}`}
    >
      {isInEditor ? (
        <div className='CoachPageImage__inner'>
          <div
            className='CoachPageImage__button'
            onClick={handleImageModalOpen}
          >
            <Tooltip
              trigger={<PencilIcon />}
              content={<>Edit</>}
              triggerType='hover'
            />
          </div>
          <div
            className='CoachPageImage__button CoachPageImage__button--content-left'
            onClick={handleClickDelete}
          >
            <Tooltip
              trigger={<BucketIcon />}
              content={<>Delete</>}
              triggerType='hover'
            />
          </div>
        </div>
      ) : null}
      <div className='CoachPageImage__image-wrapper CZ__icon'>
        {imageId.length > 1 ? (
          <img
            src={getPublicFile(imageId)}
            alt=''
            className='CoachPageImage__image'
          />
        ) : (
          <AvatarIcon />
        )}
      </div>
      <ImageCropUploadModal
        isOpened={isImageModalOpened}
        fileMaxSize={MAX_IMAGE_SIZE}
        fileFormats={MAX_FILE_TYPES}
        imageMaxWidth={480}
        aspect={1}
        onClose={handleImageModalClose}
        onUploaded={handleImageUploaded}
        className='CZ__disabled'
      />
    </div>
  );
};

export default CoachPageImage;
