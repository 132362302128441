import React, { useState, useEffect } from 'react';
import './CoachSignUp.styles.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TilePage from '../../components/TilePage/TilePage';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import SignUpForm from './SignUpForm';
import { getPricingPlans } from '../../core/api';
import { PricingPlanViewModel } from '../../core/backend/api';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';

const stripePromise = loadStripe('pk_test_kNGCTOj1OCQSyrwOcJsIOdzE002q6ywdRs');

const CoachSignUp: React.FC<any> = () => {
  const [pricingPlans, setPricingPlans] = useState<PricingPlanViewModel[]>([]);
  useEffect(() => {
    getPricingPlans().then((data) => {
      setPricingPlans(data);
    });
  }, []);

  return (
    <TilePage
      className="CoachSignUp"
      title="Sign Up"
      tileStyle={{ width: 648 }}
    >
      {pricingPlans.length > 0 && (
        <>
          <ModalHeader>
            <h2>Activate your account</h2>
            <h3>Provide your name, set password and payment method on file</h3>
            <h4>All fields are required</h4>
          </ModalHeader>
          <Elements stripe={stripePromise}>
            <SignUpForm pricingPlans={pricingPlans} />
          </Elements>
        </>
      )}
      <GlobalLoader isLoading={pricingPlans.length === 0} />
    </TilePage>
  );
};

export default CoachSignUp;
