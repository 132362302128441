import { iFormConfigItem } from '../../core/types';
import {
  isEmail,
  isNotEmpty,
  isSiteNameShortEnough,
  isSiteNameReserved,
  isSiteNameSymbolsAllowed
} from '../../core/formValidation';
import { CoachAccountViewModel } from '../../core/backend/api';

export const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] },
  { field: 'phoneNumber', checkIsValid: [] },
  { field: 'city', checkIsValid: [] },
  { field: 'state', checkIsValid: [] },
  { field: 'country', checkIsValid: [] },
  {
    field: 'siteName',
    checkIsValid: [
      isNotEmpty,
      isSiteNameSymbolsAllowed,
      isSiteNameShortEnough,
      isSiteNameReserved
    ]
  }
];

export const getEmptyAccount = (): CoachAccountViewModel => {
  return {
    socialEmail: '',
    supportEmail: '',
    stripeApiKey: '',
    userId: '',
    firstName: '',
    lastName: '',
    city: '',
    country: '',
    state: '',
    email: '',
    phoneNumber: '',
    imageId: '',
    pricingPlanId: 0,
    siteCustomizationJson: '',
    isStripeConnected: false,
    isZoomConnected: false,
    siteName: '',
    timeZone: ''
  };
};
