import React, { useState, useEffect } from 'react';
import './FileModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import FormInput from '../../FormInput/FormInput';
import { observer } from 'mobx-react';
import {
  uploadFileCoachFilesLibrary,
  editLibraryItem
} from '../../../core/api';
import { getBytes } from '../../../core/helpers';
import { useStores } from '../../../hooks';
import RowWithDelete from '../../RowWithDelete/RowWithDelete';
import Loader from '../../Loader/Loader';
import LibraryModal from '../../../containers/LibraryModal/LibraryModal';
import {
  isAddFileDataValid,
  isAddFileInputValid,
  getFormErrors
} from './FileModal.helpers';
import { FileResponseViewModel } from '../../../core/backend/api';
import { FileResponseViewModelWithExt } from '../../../core/types';
import UploadedFileItem from './UploadedFileItem';

interface iProps {
  isOpened: boolean;
  close: () => void;
  onBack: () => void;
  updateFiles: (data: FileResponseViewModel[]) => void;
  activeFile: FileResponseViewModelWithExt;
}

const acceptedExt = [
  '.doc',
  '.docx',
  '.xlsx',
  '.xls',
  '.txt',
  '.pdf',
  '.ppt',
  '.pptx',
  '.zip',
  '.mp3',
  '.wav',
  '.jpg',
  '.jpeg',
  '.png',
  '.key'
];
const maxFileSize = 25; // in MB

const initialValues = {
  name: '',
  description: ''
};

const FileModal: React.FC<iProps> = ({
  isOpened,
  close,
  updateFiles,
  onBack,
  activeFile
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  const [fileError, setFileError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<undefined | File>(undefined);
  const [isValidationShown, setIsValidationShown] = useState(false);

  const [values, setValues] = useState<Partial<FileResponseViewModelWithExt>>(
    initialValues
  );
  const [errors, setErrors] = useState(initialValues);

  const handleUpload = () => {
    if (!file) {
      setFileError('File not addded');
      return setIsValidationShown(true);
    }

    setIsValidationShown(false);

    if (!isFormValid()) {
      return setIsValidationShown(true);
    }

    setIsLoading(true);
    uploadFileCoachFilesLibrary(file, values.name, values.description)
      .then((res) => {
        updateFiles([res]);
        setIsValidationShown(false);
        close();
        notificationStore.addNotification({
          text: 'File',
          textColored: 'was added',
          duration: 3000
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    if (!activeFile) return;

    setIsValidationShown(false);

    if (!isFormValid()) {
      return setIsValidationShown(true);
    }

    setIsLoading(true);
    editLibraryItem(values.id, {
      name: values.name,
      description: values.description
    })
      .then((res) => {
        updateFiles([res]);
        setIsValidationShown(false);
        close();
        notificationStore.addNotification({
          text: 'File',
          textColored: 'was updated',
          duration: 3000
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    const nameArr = file.name.split('.');
    const ext = `.${nameArr[nameArr.length - 1]}`;

    if (file?.size > getBytes(maxFileSize, 'MB')) {
      setFileError("File isn't uploaded. Check file format and size");
      return;
    }

    if (!acceptedExt.includes(ext)) {
      setFileError("File isn't uploaded. File type not allowed");
      return;
    }

    setFile(file);
    setFileError('');
  };

  const handleBack = () => {
    close();
    onBack();
  };

  const isFormValid = () => {
    setErrors(getFormErrors(values));
    return isAddFileDataValid(values);
  };

  const handleChangeField = (key: 'name' | 'description') => (
    value: string
  ) => {
    setValues({
      ...values,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpened) {
      setIsValidationShown(false);
      setValues(activeFile || initialValues);
      setErrors(initialValues);
      setFile(undefined);
    }
  }, [isOpened, activeFile]);

  const renderFileItem = () => {
    if (!activeFile) {
      return (
        <>
          {file ? (
            <>
              <RowWithDelete
                onDelete={() => setFile(undefined)}
                className='mt-3'
                item={
                  <UploadedFileItem
                    ext={file.type.split('/')[1]}
                    title={file.name}
                  />
                }
              />
              <Button
                className='FileModal__file-button button__inversed'
                handleClick={handleUpload}
                disabled={isLoading || !file}
              >
                Upload
              </Button>
            </>
          ) : (
            <>
              <div title={fileError} className='FileModal__error-message'>
                {fileError}
              </div>
              <label className='FileModal__file-button button__inversed'>
                Add file
                <input
                  type='file'
                  onChange={onChangeHandle}
                  className='FileModal__file-input'
                  accept={acceptedExt.join(', ')}
                />
              </label>
              <div className='FileModal__hint'>
                You can upload{' '}
                {acceptedExt.slice(0, acceptedExt.length - 1).join(', ')} and{' '}
                {acceptedExt.slice(-1)[0]} format
                <div> with a maximum size up to {maxFileSize} MB</div>
              </div>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <UploadedFileItem
          ext={activeFile.ext}
          title={`${activeFile.name}.${activeFile.ext}`}
          className='mt-3'
        />
        <Button
          className='FileModal__file-button button__inversed'
          handleClick={handleSave}
          disabled={isLoading}
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 506 }}>
      <div className='FileModal'>
        {isLoading && <Loader />}
        <LibraryModal onClickBack={activeFile ? undefined : handleBack}>
          <div className='FileModal__title'>
            {activeFile ? 'Edit file' : 'Add new file'}
          </div>
          <div className='FileModal__content'>
            <FormInput
              autoFocus
              inputClassName='FormInput__input--FileModal'
              name='fileNameInLib'
              label='File name field'
              value={values.name}
              handleChange={handleChangeField('name')}
              placeholder='Enter name'
              isInvalid={
                isValidationShown && !isAddFileInputValid('name', values)
              }
              errorMessage={errors.name}
            />
            <FormInput
              inputClassName='FormInput__input--FileModal'
              name='fileDescInLib'
              label='Description field'
              value={values.description}
              handleChange={handleChangeField('description')}
              placeholder='Enter description'
              isInvalid={
                isValidationShown && !isAddFileInputValid('description', values)
              }
              errorMessage={errors.description}
            />
            {renderFileItem()}
          </div>
        </LibraryModal>
      </div>
    </Modal>
  );
};

export default observer(FileModal);
