import moment, { Moment } from 'moment-timezone';
import {
  Availability,
  AvailabilityInterval,
  CountryPhoneData,
  CountryPhoneItem
} from './types';
import timezones from '../json/timezones.json';
import States from '../json/states-usa.json';
import usaTimezones from '../json/usa-timezones.json';
import rawCountries from '../json/countriesPhonesData.json';
import getGlobal from './globals';
import { getClientApiLink } from './api';
import { SortOrder } from './enums';
import abbrs from '../json/time-zone-abbreviations.json';

moment.fn.zoneName = function () {
  const abbr = this.zoneAbbr();
  return (abbrs as Record<string, string>)[abbr] || '';
};

export function getTimezone(tz: string) {
  return (timezones.mapping as { [key: string]: string })[tz] || tz || '';
}

export function getTimezoneName(tz: string) {
  const label = (timezones.labels as { [key: string]: string })[tz];
  if (!label) {
    const parts = tz.split('/');
    let location =
      parts.length > 1
        ? parts.slice(1).join('/').replace(/(_)/g, ' ').replace(/(\/)/g, ', ')
        : '';
    let name = moment.tz(tz).zoneName();
    if (!isNaN(parseInt(name, 10))) {
      name = '';
    }
    if (!name) {
      name = location + ' Time';
      location = '';
    }
    return `${name || ''}${name && location ? ' - ' : ''}${
      location ? location : ''
    }`;
  }

  return label;
}

export function normalizeTz(tz: string) {
  const offset = moment.tz(tz).utcOffset();
  const timezone = moment.tz(tz).format('Z');
  return {
    id: tz,
    offset,
    name: `(GMT${timezone}) ${getTimezoneName(tz)}`
  };
}

export function getTimezones(curTz: string) {
  const tzs = (timezones.items as string[]).reduce((acc, tz) => {
    acc.push(normalizeTz(tz));
    return acc;
  }, []);

  if (
    curTz &&
    !(timezones.mapping as { [key: string]: string })[curTz] &&
    !(timezones.items as string[]).includes(curTz)
  ) {
    tzs.push(normalizeTz(curTz));
  }

  return tzs.sort((a, b) => {
    return a.offset - b.offset;
  });
}

export function makeEnding(string: string, count: number = 0) {
  switch (string) {
    default: {
      return `${string}${count === 1 ? '' : 's'}`;
    }
  }
}

export function getCurrentDayTimeStamp() {
  return moment.utc(Date.now()).startOf('day').valueOf();
}

export function toggleInArray(array: any[], ...values: any[]) {
  let result = [...array];
  if (values.length === 0) return [];
  values.forEach((value) => {
    if (array.includes(value)) {
      result = result.filter((item) => item !== value);
    } else {
      result = [...result, value];
    }
  });
  return result;
}

export function getDayAvailability(
  availability: Availability,
  day: Moment
): AvailabilityInterval[] {
  const weekDay = day.format('d');
  const date = day.valueOf();
  const singleDates = availability.singleDates.filter(
    (item) => item.date === date
  );
  const excludeDates = availability.excludeDates.filter(
    (item) => item.date === date
  );
  const weekly = availability.weekly.filter((item) => item.day === +weekDay);

  if (singleDates.length > 0) {
    return singleDates.map((item) => item.interval);
  } else if (excludeDates.length > 0) {
    return [];
  } else {
    return weekly.map((item) => item.interval);
  }
}

export function createLink(str: string) {
  return str
    .trim()
    .toLowerCase()
    .replace(/([^A-z\d]+)/g, ' ')
    .trim()
    .replace(/\s/g, '_');
}

type TimePeriod = 'seconds' | 'minutes' | 'hours' | 'days';

export function getMilliseconds(time: number, from: TimePeriod) {
  switch (from) {
    case 'seconds': {
      return time * 1000;
    }
    case 'minutes': {
      return time * 1000 * 60;
    }
    case 'hours': {
      return time * 1000 * 60 * 60;
    }
    case 'days': {
      return time * 1000 * 60 * 60 * 24;
    }
    default: {
      return time;
    }
  }
}

export function getFromMilliseconds(time: number, from: TimePeriod) {
  switch (from) {
    case 'seconds': {
      return time / 1000;
    }
    case 'minutes': {
      return time / 1000 / 60;
    }
    case 'hours': {
      return time / 1000 / 60 / 60;
    }
    case 'days': {
      return time / 1000 / 60 / 60 / 24;
    }
    default: {
      return time;
    }
  }
}

export interface iIntervalValidation {
  isValid: boolean;
  errorMessage: string;
}

export function validateIntervalOverlaps(
  interval: AvailabilityInterval,
  otherIntervals: AvailabilityInterval[]
): iIntervalValidation {
  if (interval.timeStartMs >= interval.timeEndMs) {
    /* negative or 0 interval`s duration */
    return {
      isValid: false,
      errorMessage: "Your end time can't be before your start time"
    };
  }

  const isValid = otherIntervals.reduce((flag, { timeStartMs, timeEndMs }) => {
    return (
      flag &&
      (timeStartMs >= interval.timeEndMs || interval.timeStartMs >= timeEndMs)
    );
  }, true);

  return {
    isValid,
    errorMessage: isValid ? '' : 'The intervals are overlapping'
  };
}

export function validateIntervalDuration(
  interval: AvailabilityInterval,
  minDuration: number
): iIntervalValidation {
  const isValid = interval.timeEndMs - interval.timeStartMs >= minDuration;
  return {
    isValid,
    errorMessage: isValid
      ? ''
      : 'The interval duration is less than the appointment block duration combined with buffers. Your clients will not be able to schedule appointments with you.'
  };
}

export function isIntervalsValid(
  intervals: AvailabilityInterval[],
  minDuration?: number
): boolean {
  const isOverlapsValid = intervals.reduce((flag, interval, index) => {
    return (
      flag &&
      validateIntervalOverlaps(interval, [
        ...intervals.slice(0, index),
        ...intervals.slice(index + 1)
      ]).isValid
    );
  }, true);

  const isDurationValid = minDuration
    ? intervals.reduce((flag, interval, index) => {
        return flag && validateIntervalDuration(interval, minDuration).isValid;
      }, true)
    : true;

  return isOverlapsValid && isDurationValid;
}

export function getTimeInZone(timeMs: number, timeZone: string): number {
  const zone = moment.tz.zone(timeZone);
  if (zone !== null) {
    const utcOffsetMin = zone.utcOffset(new Date(timeMs).getTime());
    return timeMs - getMilliseconds(utcOffsetMin, 'minutes');
  }
  return 0;
}

export function getTimeInUtc(timeMs: number, timeZone: string): number {
  const zone = moment.tz.zone(timeZone);
  if (zone !== null) {
    const utcOffsetMin = zone.utcOffset(new Date(timeMs).getTime());
    return timeMs + getMilliseconds(utcOffsetMin, 'minutes');
  }
  return 0;
}

export function logTimeStamp(time: number) {
  return moment.utc(time).format('MMM DD, ddd hh:mm a');
}

export function copyToClipboard(text: string) {
  const textarea = document.createElement('TEXTAREA');
  document.body.appendChild(textarea);
  (textarea as HTMLTextAreaElement).value = text;
  (textarea as HTMLTextAreaElement).select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

type Units = 'B' | 'KB' | 'MB' | 'GB';

export function getBytes(number: number, units: Units) {
  switch (units) {
    case 'KB':
      return number * 1024;
    case 'MB':
      return number * 1024 * 1024;
    case 'GB':
      return number * 1024 * 1024 * 1024;
    default:
      return number;
  }
}

export function getOffsetTop(element: HTMLElement, parent: HTMLElement) {
  let offsetTop = 0;
  while (element && element !== parent) {
    offsetTop += element.offsetTop;
    element = element.offsetParent as HTMLElement;
  }
  return offsetTop;
}

export function getScrollParent(element: HTMLElement) {
  let parent = element.parentElement;
  parent.scrollTop = parent.scrollTop || 1;
  while (
    (!parent.scrollTop || parent.scrollHeight - parent.clientHeight < 10) &&
    parent !== document.body
  ) {
    parent = parent.parentElement;
    parent.scrollTop = parent.scrollTop || 1;
  }
  return parent;
}

export function highlightMatches(str: string, match: string) {
  match = match.replace(/[^a-z\d]/gi, '\\$&');
  return str.replace(new RegExp(`(${match})`, 'gi'), '<b>$1</b>');
}

export function hexToHSLArray(H: string) {
  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
}

export const getExt = (awsUrl: string) => {
  const arr = awsUrl?.split('.');
  return arr?.[arr.length - 1] || '';
};

export const sortArrayByTimestampDesc = <T>(
  createdProperty: keyof T,
  updatedProperty?: keyof T
) => (a: T, b: T) => {
  const aTimestamp = ((a[updatedProperty] ||
    a[createdProperty]) as unknown) as number;
  const bTimestamp = ((b[updatedProperty] ||
    b[createdProperty]) as unknown) as number;
  return bTimestamp - aTimestamp;
};
export const sortArrayBy = <T>(
  property: keyof T,
  order: SortOrder = SortOrder.Asc
) => (a: T, b: T) => {
  const getValue = (item: T) => {
    const value = item[property];
    return typeof value === 'string' ? value.toLowerCase() : value;
  };

  const aValue = getValue(a);
  const bValue = getValue(b);

  if (aValue < bValue) {
    return order === SortOrder.Desc ? 1 : -1;
  }
  if (aValue > bValue) {
    return order === SortOrder.Desc ? -1 : 1;
  }
  return 0;
};

export function beautifyTimeZone(timeZone: string) {
  const isUsaTimezone = usaTimezones.includes(timeZone);
  return timeZone
    .replace(/(_)/g, ' ')
    .replace(/(\/)/g, ', ')
    .replace(isUsaTimezone ? 'America' : ' ', isUsaTimezone ? 'USA' : ' ');
}

export const searchBy = (value: string, searchString: string) =>
  value?.toLowerCase().includes(searchString?.trim().toLowerCase());

export const getFormattedDuration = (minutes: number) => {
  const ms = minutes * 1000 * 60;
  const days = Math.ceil(moment.duration(ms).asDays());
  const hours = moment.duration(ms).hours();
  const mins = moment.duration(ms).minutes();
  if (moment.duration(ms).days()) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }
  return `${hours ? `${hours} h ` : ''}${mins ? `${mins} min` : ''}`;
};

export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1);
};

export const openLinkNewTab = (link: string) => {
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', link);
  element.setAttribute('target', '_blank');
  element.setAttribute('rel', 'noopener noreferrer');
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

export function downloadFileFromMemory(
  fileName: string,
  fileExt: string,
  id: string
) {
  const url = `${getGlobal(
    'api'
  )}/FileHelp/GetFile/${fileName}.${fileExt}?keyId=${id}`;

  window.location.assign(url);
}

export function downloadClientFileFromMemory(
  fileName: string,
  fileExt: string,
  id: string
) {
  const url = `${getClientApiLink(
    'FileHelp/GetFile'
  )}/${fileName}.${fileExt}?keyId=${id}`;

  window.location.assign(url);
}

export const stopVideo = (selector: string) => {
  const iframe = document.querySelector(selector) as HTMLIFrameElement;
  if (iframe !== null) {
    const src = iframe.src;
    iframe.src = src;
  }
};

export function iOS(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export const even = (index: number) => index % 2 === 0;

export const odd = (index: number) => index % 2 !== 0;

export const getServerModelValidation = (
  error: any,
  defaultField?: string
): Record<string, string> => {
  if (error.status === 400) {
    if (typeof error.errors === 'object') {
      return Object.keys(error.errors).reduce((acc, key) => {
        const parts = key.split('.');
        const field = parts.length > 1 ? parts[1] : parts[0];
        return {
          ...acc,
          [field.charAt(0).toLowerCase() + field.slice(1)]: Array.isArray(
            error.errors[key]
          )
            ? error.errors[key][0]
            : error.errors[key]
        };
      }, {});
    } else if (defaultField) {
      return {
        [defaultField]: error.message
      };
    }
  }
  return {};
};

export const getStateAbbreviature = (state: string) => {
  const curState = States.find(({ name }) => name === state);
  if (!curState) return state;
  return curState.state_code;
};

function initCountries(countries: CountryPhoneData[]) {
  let hiddenAreaCodes: CountryPhoneItem[] = [];

  const initializedCountries = [].concat(
    ...countries.map((country) => {
      const countryItem: CountryPhoneItem = {
        name: country[0],
        regions: country[1],
        iso2: country[2],
        countryCode: country[3],
        dialCode: country[3],
        format: getMask(country[3], country[4]),
        priority: country[5] || 0
      };

      const areaItems: CountryPhoneItem[] = [];

      country[6] &&
        country[6].map((areaCode: string) => {
          const areaItem = { ...countryItem };
          areaItem.dialCode = country[3] + areaCode;
          areaItem.isAreaCode = true;
          areaItem.areaCodeLength = areaCode.length;

          areaItems.push(areaItem);
          return null;
        });

      if (areaItems.length > 0) {
        countryItem.mainCode = true;
        hiddenAreaCodes = hiddenAreaCodes.concat(areaItems);
        return [countryItem];
      } else {
        return [countryItem];
      }
    })
  );

  return [initializedCountries, hiddenAreaCodes];
}

const guessSelectedCountry = (value: string | number) => {
  const inputNumber = !!value ? `${value}`.replace(/\D/g, '') : '';

  const [initializedCountries, hiddenAreaCodes] = initCountries(
    rawCountries as CountryPhoneData[]
  );

  let mainCode;
  hiddenAreaCodes.some((country) => {
    if (inputNumber.startsWith(country.dialCode)) {
      initializedCountries.some((o) => {
        if (country.iso2 === o.iso2 && o.mainCode) {
          mainCode = o;
          return true;
        }
        return false;
      });
      return true;
    }
    return false;
  });
  if (mainCode) return mainCode;

  const bestGuess = initializedCountries.reduce(
    (selectedCountry, country) => {
      if (inputNumber.startsWith(country.dialCode)) {
        if (country.dialCode.length > selectedCountry.dialCode.length) {
          return country;
        }
        if (
          country.dialCode.length === selectedCountry.dialCode.length &&
          country.priority < selectedCountry.priority
        ) {
          return country;
        }
      }
      return selectedCountry;
    },
    { dialCode: '', priority: 10001 }
  );

  return bestGuess;
};

function getMask(dialCode: string, predefinedMask: string) {
  const defaultMask = '... ... ... ... ..';
  if (!predefinedMask) {
    return '+' + ''.padEnd(dialCode.length, '.') + ' ' + defaultMask;
  } else {
    return '+' + ''.padEnd(dialCode.length, '.') + ' ' + predefinedMask;
  }
}

export const formatPhoneNumber = (value: string) => {
  if (!value) return '';
  const text = `${value}`.replace(/[^\d.]/g, '');
  const country = guessSelectedCountry(text);
  if (!country) return text;

  const { format } = country;
  if (!text || text.length === 0) {
    return '+';
  }
  if ((text && text.length < 2) || !format) {
    return text;
  }

  const formattedObject = format.split('').reduce(
    (
      acc: {
        formattedText: string;
        remainingText: string[];
      },
      character: string
    ) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText
        };
      }

      const [head, ...tail] = acc.remainingText;

      return {
        formattedText: acc.formattedText + head,
        remainingText: tail
      };
    },
    {
      formattedText: '',
      remainingText: text.split('')
    }
  );

  let formattedNumber = formattedObject.formattedText || text || '';

  if (
    !!formattedNumber &&
    formattedNumber !== text &&
    formattedNumber.includes('(') &&
    !formattedNumber.includes(')')
  )
    formattedNumber += ')';
  return formattedNumber;
};

export const normalizePrice = (price: number) => {
  return new Intl.NumberFormat(
    'en-US',
    Number.isInteger(price) ? {} : { minimumFractionDigits: 2 }
  ).format(price);
};

export const saveToLocalStorage = <T>(key: string, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};

export const isSimilarColors = (color1: string, color2: string): boolean => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);
  return rgb1.reduce<boolean>(
    (acc, val, index) => acc && Math.abs(val - rgb2[index]) < 20,
    true
  );
};

export const hexToRgb = (color: string): [number, number, number] => {
  color = color.replace('#', '');
  if (color.length !== 3 && color.length !== 6) {
    return [0, 0, 0];
  }
  return color.length === 6
    ? [
        parseInt(color.slice(0, 2), 16),
        parseInt(color.slice(2, 4), 16),
        parseInt(color.slice(4, 6), 16)
      ]
    : [
        parseInt(color[0].repeat(2), 16),
        parseInt(color[1].repeat(2), 16),
        parseInt(color[2].repeat(2), 16)
      ];
};
