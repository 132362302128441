import React from 'react';
import PdfIcon from '../icons/thumbnail_pdf.svg';
import TxtIcon from '../icons/thumbnail_txt.svg';
import DocIcon from '../icons/thumbnail_doc.svg';
import JpgIcon from '../icons/thumbnail_jpg.svg';
import LinkIcon from '../icons/thumbnail_link.svg';
import VideoIcon from '../icons/thumbnail_video.svg';
import Mp3Icon from '../icons/thumbnail_mp3.svg';
import XlsIcon from '../icons/thumbnail_xls.svg';
import PptIcon from '../icons/thumbnail_ppt.svg';
import ZipIcon from '../icons/thumbnail_zip.svg';
import PngIcon from '../icons/thumbnail_png.svg';
import FileIcon from '../icons/file.svg';

interface iProps {
  type: string;
}

const Thumbnail: React.FC<iProps> = ({ type }) => {
  switch (type) {
    case 'pdf': {
      return <PdfIcon />;
    }
    case 'doc':
    case 'docx': {
      return <DocIcon />;
    }
    case 'xls':
    case 'xlsx': {
      return <XlsIcon />;
    }
    case 'ppt':
    case 'pptx': {
      return <PptIcon />;
    }
    case 'zip': {
      return <ZipIcon />;
    }
    case 'wav':
    case 'mp3': {
      return <Mp3Icon />;
    }
    case 'png': {
      return <PngIcon />;
    }
    case 'txt': {
      return <TxtIcon />;
    }
    case 'jpg':
    case 'jpeg': {
      return <JpgIcon />;
    }
    case 'link': {
      return <LinkIcon />;
    }
    case 'video': {
      return <VideoIcon />;
    }
    default:
      return <FileIcon />;
  }
};

export default Thumbnail;
