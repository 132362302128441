import { useState, useRef, useEffect, useCallback } from 'react';
import { Area } from 'react-easy-crop/types';
import { uploadPublicFile } from '../../core/api';
import {
  CroppedArea,
  getCroppedFile,
  CroppedData
} from './ImageCropper.helpers';

export const useCropper = (
  onChange: (croppedArea: string) => void,
  initialArea: string,
  imgSrc: string,
  thumbId: string,
  maxWidth?: number
) => {
  const initialCropArea = useRef(undefined);
  const initialCroppedImageId = useRef(undefined);
  const initialOriginalImageId = useRef(undefined);
  const [croppedData, setCroppedData] = useState<CroppedData | undefined>(
    undefined
  );
  const [isUploadingCroppedImg, setIsUploadingCroppedImg] = useState(false);

  const onCrop = useCallback(
    (croppedAreaPixels?: Area, croppedArea?: CroppedArea) => {
      if (croppedAreaPixels) {
        setCroppedData({
          croppedAreaPixels,
          imgSrc,
          rotation: croppedArea.rotation
        });
        if (JSON.stringify(croppedArea) !== initialArea) {
          onChange(JSON.stringify(croppedArea));
        }
      } else {
        onChange('');
      }
    },
    [onChange, imgSrc, initialArea]
  );

  const uploadCroppedImg = async () => {
    if (!croppedData) {
      return '';
    }

    if (
      initialArea !== '' &&
      initialArea === initialCropArea.current &&
      thumbId !== '' &&
      thumbId === initialCroppedImageId.current &&
      imgSrc !== '' &&
      imgSrc === initialOriginalImageId.current
    ) {
      return thumbId;
    }

    if (!imgSrc) return '';

    try {
      setIsUploadingCroppedImg(true);
      const file = await getCroppedFile(croppedData, maxWidth);
      const res = await uploadPublicFile(file);
      setIsUploadingCroppedImg(false);
      return res.createdFileName;
    } catch (error) {
      setIsUploadingCroppedImg(false);
      return '';
    }
  };

  useEffect(() => {
    if (!initialCropArea.current && initialArea)
      initialCropArea.current = initialArea;
  }, [initialArea]);

  useEffect(() => {
    if (!initialCroppedImageId.current) initialCroppedImageId.current = thumbId;
  }, [thumbId]);

  useEffect(() => {
    if (!initialOriginalImageId.current && imgSrc) {
      initialOriginalImageId.current = imgSrc;
    }
    if (!imgSrc) onChange('');
  }, [imgSrc, onChange]);

  return {
    onCrop,
    uploadCroppedImg,
    isUploadingCroppedImg
  };
};
