import React, { useState } from 'react';
import { FileResponseViewModelWithExt } from '../../core/types';
import DownloadIcon from '../../icons/download.svg';
import {
  downloadClientFileFromMemory,
  openLinkNewTab,
  stopVideo
} from '../../core/helpers';
import { getClientFilesLibraryFileID } from '../../core/api';
import CopyIcon from '../../icons/copy.svg';
import { copyToClipboard, getExt } from '../../core/helpers';
import { useStores } from '../../hooks';
import {
  getLinkType,
  getValidLink,
  parseLink
} from '../CoachLibraryAll/CoachLibraryAll.helpers';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import ViewIcon from '../../icons/view-icon.svg';
import FileItem from '../../components/FileItem/FileItem';
import { FileResponseViewModel } from '../../core/backend/api';
import { NotificationTypesEnum } from '../../core/enums';
import Tooltip from '../../components/Tooltip/Tooltip';

interface iProps {
  file: FileResponseViewModel;
  setActiveFile: React.Dispatch<
    React.SetStateAction<FileResponseViewModelWithExt>
  >;
  setIsVideoModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  expandedId: string | number;
  setExpandedId: (id: string | number) => void;
}

const ClientLibraryDownloadsItem: React.FC<iProps> = ({
  file,
  setActiveFile,
  setIsVideoModalOpened,
  expandedId,
  setExpandedId
}) => {
  const { awsUrl, fileLink, name, id } = file;
  const {
    rootStore: { notificationStore }
  } = useStores();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const linkType = fileLink ? getLinkType(fileLink) : undefined;
  const isVideoLink = fileLink && linkType === 'video';
  const videoLink = isVideoLink ? parseLink(fileLink) : undefined;

  const ext = getExt(awsUrl);

  const handleDownloadClick = async () => {
    try {
      setIsDownloading(true);
      try {
        const guid = await getClientFilesLibraryFileID(id);
        downloadClientFileFromMemory(name, ext, guid);
      } catch (error) {
        notificationStore.addNotification({
          text: '',
          textColored: `You cannot download the file\nbecause it was deleted by Coach \nor access was denied`,
          duration: 3000,
          type: NotificationTypesEnum.Error
        });
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const handleView = async () => {
    stopVideo(`#FileItem-video-${expandedId}`);
    setIsVideoModalOpened(true);
    setActiveFile({
      ...file,
      ext: 'video',
      fileLink: videoLink
    });
  };

  const handleIconClick = () => {
    if (!fileLink) return handleDownloadClick();
    if (!isVideoLink) return openLinkNewTab(getValidLink(fileLink));
    handleView();
  };

  const handleCopy = () => {
    if (!fileLink) return;
    copyToClipboard(
      videoLink ? getValidLink(videoLink) : getValidLink(fileLink)
    );
    notificationStore.addNotification({
      text: 'Link',
      textColored: 'was copied to your clipboard'
    });
  };

  const menuListItems = [
    {
      name: 'Copy link',
      callback: handleCopy,
      icon: (
        <span className='ClientLibraryDownloads__dd-menu-icon'>
          <CopyIcon />
        </span>
      )
    },
    {
      name: 'View',
      callback: handleView,
      icon: (
        <span className='ClientLibraryDownloads__dd-menu-icon'>
          <ViewIcon />
        </span>
      )
    }
  ];

  return (
    <FileItem
      file={{ ...file, ext }}
      linkType={linkType}
      isDownloading={isDownloading}
      className='mb-4 pt-sm-4 mx-md-n1'
      nameMaxLength={30}
      onIconClick={handleIconClick}
      isExpanded={expandedId === file.id}
      onToggle={setExpandedId}
    >
      {!isVideoLink ? (
        <Tooltip
          className='ClientLibraryDownloads__download'
          trigger={
            <span onClick={fileLink ? handleCopy : handleDownloadClick}>
              {fileLink ? <CopyIcon /> : <DownloadIcon />}
            </span>
          }
          content={<>{fileLink ? 'Copy' : 'Download'}</>}
          triggerType='hover'
          minWidth={70}
        />
      ) : (
        <DropdownMenu
          list={menuListItems}
          withPortal={false}
          className='ClientLibraryDownloads__dropdown'
        />
      )}
    </FileItem>
  );
};

export default ClientLibraryDownloadsItem;
