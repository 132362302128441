import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import './SuperAdminUsers.styles.scss';
import { columns, UserItem } from './SuperAdminUsers.helpers';
import Loader from '../../components/Loader/Loader';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { TableAction } from '../../components/Table/Table';
import EmptyClients from '../../images/empty-clients.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import PencilIcon from '../../icons/pencil.svg';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';
import SearchInput from '../../components/SearchInput/SearchInput';
import {
  createCoach,
  getAllCoachesSuperAdmin,
  getPricingPlans,
  resetCoachPasswordSuperAdmin
} from '../../core/api';
import Button from '../../components/Button/Button';
import {
  ClientRoutesEnum,
  SortOrder,
  SuperAdminRoutesEnum
} from '../../core/enums';
import ShareIcon from '../../icons/share.svg';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { normalizePricingPlansToIOption } from '../../components/Modals/AdminCoachModal/AdminCoachModal.helpers';
import AdminCoachModal from '../../components/Modals/AdminCoachModal/AdminCoachModal';
import { useDebouncedCallback, useStores } from '../../hooks';
import PreviewIcon from '../../icons/preview.svg';
import getGlobal from '../../core/globals';
import { CoachCreateViewModel } from '../../core/backend/api';
import { defaultTableSearchFn } from '../../components/Table/Table.helpers';
import { iOption } from '../../core/types';
import { usePagingSorting } from '../../hooks';

interface ConfirmationData {
  title: string;
  confirmBtnText: string;
  callback: () => void;
}

const SuperAdminUsers: React.FC<RouteComponentProps> = () => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const history = useHistory();
  const [data, setData] = useState<UserItem[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [confirmationModalData, setConfirmationModalData] = useState<
    ConfirmationData
  >(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setIsConfirmationDone = useDebouncedCallback(() => {
    setConfirmationModalData(null);
    setIsSubmitting(false);
  }, 450);
  const [isAddCoachModalOpened, setIsAddCoachModalOpened] = useState<boolean>(
    false
  );
  const [pricingPlans, setPricingPlans] = useState<iOption[]>([]);
  const {
    searchText,
    handleSearch,
    searchedItems: searchedUsers
  } = useSearchInput<UserItem>(data, defaultTableSearchFn(columns));

  const {
    pageNumber,
    onPageChange,
    pageSize,
    getCurrentPageData,
    sort,
    onSortChange
  } = usePagingSorting<UserItem>(searchedUsers.length, isLoading, 20, {
    sortBy: 'createdOn',
    sortOrder: SortOrder.Asc
  });

  useEffect(() => {
    reFetchData();
  }, []);

  const reFetchData = () => {
    setIsLoading(true);

    Promise.all([getAllCoachesSuperAdmin(), getPricingPlans()])
      .then(([coaches, plans]) => {
        const coachesWithPlans = coaches.map((coach) => {
          return {
            ...coach,
            plan:
              plans.find((plan) => plan.id === coach.plan)?.name || coach.plan
          };
        });
        setData(coachesWithPlans);
        setPricingPlans(normalizePricingPlansToIOption(plans));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const confirmResendPassword = (user: UserItem) => {
    setIsSubmitting(true);
    resetCoachPasswordSuperAdmin(user.userId).then(() => {
      setIsConfirmModalOpened(false);
      setIsConfirmationDone();
      notificationStore.addNotification({
        text: `${user.fullName} password was reseted`
      });
    });
  };

  const onResetPassword = (user: UserItem) => {
    setIsConfirmModalOpened(true);
    setConfirmationModalData({
      title: `Are you sure you want to reset password for "${user.fullName}"?`,
      confirmBtnText: 'Reset',
      callback: () => confirmResendPassword(user)
    });
  };

  const actions: TableAction<UserItem>[] = [
    {
      title: 'Edit',
      callback: (user: UserItem) => {
        history.push(SuperAdminRoutesEnum.User.replace(':userId', user.userId));
      },
      icon: <PencilIcon />
    },
    {
      title: 'Reset password',
      callback: onResetPassword,
      icon: <ShareIcon />
    },
    {
      title: 'View coach page',
      callback: (user: UserItem) => {
        window.open(
          `${getGlobal('domain')}${ClientRoutesEnum.CoachPage.replace(
            ':siteName',
            user.siteName
          )}`,
          '_blank'
        );
      },
      icon: (
        <div className='SuperAdminUsers__PreviewIcon d-flex aling-items-center justify-content-center'>
          <PreviewIcon />
        </div>
      ),
      isAvailable: (item: UserItem) => !!item.siteName
    }
  ];

  const handleSaveData = (payload: CoachCreateViewModel) => {
    return createCoach(payload).then(() => {
      reFetchData();
      setIsAddCoachModalOpened(false);
      notificationStore!.addNotification({
        text: (
          <>
            <p className='mb-1'>New user is added.</p>
            <p>
              Credentials to the account
              <br />
              were sent to the indicated
              <br />
              User's email.
            </p>
          </>
        ),
        duration: 3500
      });
    });
  };

  const renderItems = () => {
    if (isLoading) {
      return (
        <div className='SuperAdminUsers__table-loader'>
          <Loader />
        </div>
      );
    }
    if (searchedUsers.length === 0) {
      return (
        <EmptyState
          className='mt-5'
          icon={<EmptyClients />}
          text='There are no users'
        />
      );
    }
    return (
      <Table<UserItem>
        sort={sort}
        totalCount={searchedUsers.length}
        withPaging
        onPageChange={onPageChange}
        onSort={onSortChange}
        pageSize={pageSize}
        pageNumber={pageNumber}
        data={getCurrentPageData(searchedUsers)}
        columns={columns}
        actions={actions}
        idField='userId'
        baseUrl={SuperAdminRoutesEnum.User}
        searchText={searchText}
        rowHeight={49}
        clientSorting
      />
    );
  };

  return (
    <div className='SuperAdminUsers p-sm-4 p-3'>
      <div className='SuperAdminUsers__header row mb-sm-4 mb-3'>
        <div className='SuperAdminUsers__title col-sm-12 col-6 mb-3'>Users</div>
        <div className='col-sm-6 col-12 order-sm-2 order-3'>
          <SearchInput onSearch={handleSearch} searchText={searchText} />
        </div>
        <div className='col-6 d-flex justify-content-end order-sm-3 order-2'>
          <Button
            handleClick={() => {
              setIsAddCoachModalOpened(true);
            }}
            className='button__sm-width flex-shrink-0'
          >
            + Add user
          </Button>
        </div>
      </div>
      {renderItems()}
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={() => setIsConfirmModalOpened(false)}
        title={confirmationModalData?.title}
        disableConfirmBtn={isSubmitting}
        confirmBtnText={confirmationModalData?.confirmBtnText}
        confirmCallback={confirmationModalData?.callback}
      />
      <AdminCoachModal
        isOpened={isAddCoachModalOpened}
        close={() => {
          setIsAddCoachModalOpened(false);
        }}
        onSave={handleSaveData}
        pricingPlans={pricingPlans}
      />
    </div>
  );
};

export default observer(SuperAdminUsers);
