import React, { useEffect, useState } from 'react';
import SearchInput from '../SearchInput/SearchInput';
import Table, { TableColumn } from '../Table/Table';
import { useMedia, usePagingSorting, useSearch } from '../../hooks';
import TransactionsItem from './TransactionsItem';
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import { ConfirmModalEnum, SortOrder } from '../../core/enums';
import { useRef } from 'react';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import './TransactionsList.styles.scss';
import { BillingItemViewModel } from '../../core/backend/api';
import ConfirmModal, {
  iProps as ConfirmModalData
} from '../../components/Modals/ConfirmModal/ConfirmModal';
import TransactionModal from './TransactionModal';

export interface Props {
  title?: string;
  className?: string;
  isLoading: boolean;
  transactions: BillingItemViewModel[];
  columns: Array<TableColumn<BillingItemViewModel>>;
  getActions: (
    actionCallback: (type: any, item: BillingItemViewModel) => void
  ) => {
    title: string;
    callback: (item: BillingItemViewModel) => void;
    isAvailable: (item: BillingItemViewModel) => boolean;
  }[];
  rowHeight: number;
  headerHeight?: number;
  fetchTransactions: (
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: SortOrder,
    searchText?: string
  ) => void;
  totalCount: number;
  itemsOnPage?: number;
  getConfimModalData: (
    confirmModalType: ConfirmModalEnum,
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnsDisabled: (i: boolean) => void
  ) => Partial<ConfirmModalData> & { confirm: () => void; cancel?: () => void };
  flow: 'client' | 'coach';
  onView: (item: BillingItemViewModel) => void;
}

const TransactionsList: React.FC<Props> = ({
  title = '',
  className = '',
  isLoading,
  transactions,
  columns,
  getActions,
  rowHeight,
  headerHeight,
  fetchTransactions,
  itemsOnPage,
  totalCount,
  getConfimModalData,
  flow,
  onView
}) => {
  const isTablet = useMedia(['(min-width: 576px)'], [true], false);
  const scrollTarget = useRef<HTMLDivElement>();
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalEnum>();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isConfirmBtnsDisabled, setIsConfirmBtnDisabled] = useState(false);
  const [isTransacrionModalOpened, setIsTransacrionModalOpened] = useState(
    false
  );
  const [activeRecord, setActiveRecord] = useState<BillingItemViewModel>();
  const { searchText, onSearchChange, debouncedSearchText } = useSearch(
    '',
    400
  );

  const {
    pageNumber,
    onPageChange,
    pageSize,
    onSortChange,
    sort
  } = usePagingSorting<BillingItemViewModel>(
    totalCount,
    isLoading,
    itemsOnPage || 20,
    {
      sortBy: 'date',
      sortOrder: SortOrder.Desc
    }
  );

  const { sortBy, sortOrder } = sort;

  useEffect(() => {
    if (pageNumber && pageSize) {
      fetchTransactions(
        pageNumber,
        pageSize,
        sortBy,
        sortOrder,
        debouncedSearchText
      );
    }
  }, [
    fetchTransactions,
    pageNumber,
    pageSize,
    sortBy,
    sortOrder,
    debouncedSearchText
  ]);

  const handleConfirmationClose = () => {
    setIsConfirmModalOpened(false);
  };

  const actionCallback = (
    type: ConfirmModalEnum,
    item: BillingItemViewModel
  ) => {
    setConfirmModalType(type);
    setIsConfirmBtnDisabled(false);
    setIsConfirmModalOpened(true);
    setActiveRecord(item);
  };

  const actions = getActions(actionCallback);

  const confirmModalData = getConfimModalData(
    confirmModalType,
    activeRecord,
    handleConfirmationClose,
    setIsConfirmBtnDisabled
  );

  const handleClickItem = (record: BillingItemViewModel) => () => {
    setActiveRecord(record);
    setIsTransacrionModalOpened(true);
  };

  const renderPayments = () => {
    return (
      <>
        <Table<BillingItemViewModel>
          className={`Table--TransactionsList ${
            !transactions.length ? 'hidden' : ''
          }`}
          data={transactions}
          columns={columns}
          actions={actions}
          withPaging
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          idField='id'
          isShownMobileRenderer={!isTablet}
          onPageChange={onPageChange}
          pageNumber={pageNumber}
          onSort={onSortChange}
          sort={sort}
          totalCount={totalCount}
          MobileRenderer={({ item, actions }) => (
            <TransactionsItem
              item={item}
              actions={actions}
              searchString=''
              onClick={handleClickItem}
            />
          )}
        />
        {!transactions.length && !isLoading && (
          <div
            className='position-relative flex-grow-1'
            style={{ minHeight: '280px' }}
          >
            <EmptyState
              size='md'
              text="You don't have payments yet"
              absoluteCenter
              icon={<EmptyStateIcon />}
              style={{ top: isTablet ? 'calc(50% - 50px)' : undefined }}
            />
          </div>
        )}
      </>
    );
  };

  const handleCloseTransactionModal = () => {
    setIsTransacrionModalOpened(false);
  };

  return (
    <div className={`TransactionsList  ${className}`} ref={scrollTarget}>
      <div className='d-flex align-items-center justify-content-between pb-3 px-3 px-sm-0'>
        <div className='TransactionsList__title'>{title}</div>
        <SearchInput
          className='ml-3'
          onSearch={onSearchChange}
          searchText={searchText}
          collapsible
        />
      </div>
      <GlobalLoader isLoading={isLoading} />
      {renderPayments()}
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={handleConfirmationClose}
        title={confirmModalData?.title}
        text={confirmModalData?.text}
        confirmBtnText={confirmModalData?.confirmBtnText}
        cancelBtnText={confirmModalData?.cancelBtnText}
        confirmCallback={confirmModalData?.confirm}
        cancelCallback={confirmModalData?.cancel}
        disableConfirmBtn={isConfirmBtnsDisabled}
        disableCancelBtn={isConfirmBtnsDisabled}
        confirmBtnClass='button__confirm-delete'
      />
      <TransactionModal
        isOpened={isTransacrionModalOpened}
        transaction={activeRecord}
        flow={flow}
        onClose={handleCloseTransactionModal}
        onView={onView}
      />
    </div>
  );
};

export default TransactionsList;
