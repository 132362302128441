import React, { useState } from 'react';
import ColorPickerWrapper from '../ColorPickerWrapper/ColorPickerWrapper';
import NoImage from '../../icons/no-image.svg';
import FileUploader from '../FileUploader/FileUploader';
import { getPublicFile } from '../../core/api';
import CustomizeSidebarTab from './CustomizeSidebarTab';
import Scrollbars from 'react-custom-scrollbars';
import FormInput from '../FormInput/FormInput';
import { MAX_IMAGE_SIZE, MAX_FILE_TYPES } from './CustomizeSidebar.helpers';
import { iCoachPageCustomizationData } from '../../core/types';
import Button from '../Button/Button';
interface Props {
  onChangeField: (field: string) => (value: any) => void;
  data: iCoachPageCustomizationData;
  onResetStyles: () => void;
}

const CustomizeStylesTab: React.FC<Props> = ({
  onChangeField,
  data,
  onResetStyles
}) => {
  return (
    <CustomizeSidebarTab>
      <div className='d-flex justify-content-end'>
        <Button
          type='button'
          className='mt-2 d-inline-flex p-0'
          variations={['naked']}
          handleClick={onResetStyles}
        >
          Back to default styles
        </Button>
      </div>
      <FormInput handleChange={() => {}} value={''} label='Primary color'>
        <ColorPickerWrapper
          color={data.primaryColor}
          setColor={onChangeField('primaryColor')}
        />
      </FormInput>
      <FormInput handleChange={() => {}} value={''} label='Secondary color'>
        <ColorPickerWrapper
          color={data.secondaryColor}
          setColor={onChangeField('secondaryColor')}
        />
      </FormInput>
      <div className='CustomizeSidebar__block'>
        <div className='CustomizeSidebar__title'>Text style</div>
        <FormInput handleChange={() => {}} value={''} label='Headline color'>
          <ColorPickerWrapper
            color={data.headlineColor}
            setColor={onChangeField('headlineColor')}
          />
        </FormInput>
        <FormInput handleChange={() => {}} value={''} label='Paragraph color'>
          <ColorPickerWrapper
            color={data.paragraphColor}
            setColor={onChangeField('paragraphColor')}
          />
        </FormInput>
        <FormInput handleChange={() => {}} value={''} label='Additional color'>
          <ColorPickerWrapper
            color={data.additionalColor}
            setColor={onChangeField('additionalColor')}
          />
        </FormInput>
      </div>

      <div className='CustomizeSidebar__block'>
        <div className='CustomizeSidebar__title'>Page background</div>
        <FormInput handleChange={() => {}} value={''} label='Background color'>
          <ColorPickerWrapper
            color={data.backgroundColor}
            setColor={onChangeField('backgroundColor')}
          />
        </FormInput>
      </div>

      <div className='CustomizeSidebar__block'>
        <div className='CustomizeSidebar__title mb-3'>Logo</div>
        <FileUploader
          className='CoachLogo__UploadArea'
          description='Place your logo here'
          fileMaxSize={MAX_IMAGE_SIZE}
          fileTypes={MAX_FILE_TYPES}
          showPreview={!!data.logoSrc}
          onFileUploaded={onChangeField('logoSrc')}
        >
          {data.logoSrc ? (
            <img
              src={getPublicFile(data.logoSrc)}
              alt='Coach logo'
              className='CoachLogo__preview'
            />
          ) : (
            <div className='CoachLogo__UploadArea__placeholder'>
              <NoImage />
            </div>
          )}
        </FileUploader>
      </div>
    </CustomizeSidebarTab>
  );
};

export default CustomizeStylesTab;
