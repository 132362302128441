import { BookingItemTypeEnum, iBookingItem } from '../../../core/types';
import { iInfoBlockData } from '../../../components/InfoBlock/InfoBlock';
import { ScheduleItemType } from '../../../core/enums';

export const getInfoBlockData = (
  data: iBookingItem,
  timeZone: string,
  checkIsShowLocation?: boolean
): iInfoBlockData => {
  if (!data) return null;
  return {
    id: data.id,
    type:
      data.type === BookingItemTypeEnum.Meeting
        ? data.isScheduled
          ? ScheduleItemType.OneToOne
          : ScheduleItemType.Event
        : ScheduleItemType.Appointment,
    name: data.name,
    userName: '',
    durationMinutes: data.durationMinutes,
    membersCountLimitString:
      data.membersCountLimit === 1 ? 'One-on-One' : 'Group',
    price: data.price.toString(),
    location: checkIsShowLocation
      ? data.showLocationWhileBooking
        ? data.location
        : ''
      : data.location,
    timeZone: timeZone,
    date: data.timestamp,
    showCoachAvatar: false,
    imageId: data.thumbnailImageId,
    remindBefore: data.remindBeforeMs,
    showSelectBefore: false
  };
};
