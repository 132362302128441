import {
  getInputErrors,
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty
} from '../../core/formValidation';
import { iFormConfigItem } from '../../core/types';

const FormConfig: iFormConfigItem[] = [
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] }
];

export function isFormInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getFormInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isFormDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
