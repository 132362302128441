import React from 'react';
import './StripeConnectHint.styles.scss';
import { Link } from 'react-router-dom';
import { CoachRoutesEnum } from '../../core/enums';

interface Props {
  isShown: boolean;
  onConnectStripe: () => void;
}

const StripeConnectHint = ({ isShown, onConnectStripe }: Props) => {
  if (!isShown) return null;
  return (
    <div className='StripeConnectHint'>
      <Link
        to={CoachRoutesEnum.ConnectStripe}
        className='StripeConnectHint__link'
        onClick={onConnectStripe}
      >
        Connect
      </Link>
      &nbsp;your Stripe account to add a cost.
    </div>
  );
};

export default StripeConnectHint;
