import React from 'react';
import FormInput from '../../../components/FormInput/FormInput';
import {
  getServiceInputErrors,
  isServiceDataValid,
  isServiceInputValid
} from './CoachInvoiceForm.helpers';
import { InvoiceServiceViewModel } from '../../../core/backend/api';
import {
  formatValueAsInteger,
  formatValueAsPrice
} from '../../../core/formValidation';
import { normalizePrice } from '../../../core/helpers';
import Button from '../../../components/Button/Button';
import BucketIcon from '../../../icons/delete.svg';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';

interface Props {
  item: InvoiceServiceViewModel;
  isValidationShown: boolean;
  onChange: (data: InvoiceServiceViewModel) => void;
  onDelete: () => void;
  onEdit?: () => void;
  onClick: () => void;
}

const CoachInvoiceFormItem: React.FC<Props> = ({
  item,
  isValidationShown,
  onChange,
  onDelete,
  onEdit,
  onClick
}) => {
  const handleFieldChange = (field: keyof InvoiceServiceViewModel) => (
    value: any
  ) => {
    onChange({
      ...item,
      [field]: value
    });
  };

  return (
    <div
      className={`CoachInvoiceForm__items-row position-relative ${
        isValidationShown && !isServiceDataValid(item)
          ? 'CoachInvoiceForm__items-row--error'
          : ''
      }`}
      onClick={onClick}
    >
      <div className='CoachInvoiceForm__items-cell pr-3'>
        <FormInput
          name='serviceTitle'
          value={item.title}
          className='d-sm-block d-none'
          isInvalid={isValidationShown && !isServiceInputValid('title', item)}
          errorMessage={getServiceInputErrors('title', item)[0]}
          handleChange={handleFieldChange('title')}
          placeholder='Name'
        />
        <span className='d-sm-none d-block mt-3 CoachInvoiceForm__item-title'>
          {item.title || (
            <span className='CoachInvoiceForm__empty-title'>no item name</span>
          )}{' '}
          x {item.quantity}
        </span>
      </div>
      <div className='CoachInvoiceForm__items-cell pr-3'>
        <FormInput
          name='serviceQuantity'
          value={item.quantity}
          valueFormatter={formatValueAsInteger}
          isInvalid={
            isValidationShown && !isServiceInputValid('quantity', item)
          }
          errorMessage={getServiceInputErrors('quantity', item)[0]}
          handleChange={handleFieldChange('quantity')}
          placeholder='Qty'
          maxLength={3}
        />
      </div>
      <div className='CoachInvoiceForm__items-cell pr-3'>
        <FormInput
          name='servicePrice'
          value={item.price}
          valueFormatter={formatValueAsPrice}
          isInvalid={isValidationShown && !isServiceInputValid('price', item)}
          errorMessage={getServiceInputErrors('price', item)[0]}
          handleChange={handleFieldChange('price')}
          placeholder='Price'
          prepend='$'
          maxLength={5}
        />
      </div>
      <div className='CoachInvoiceForm__items-cell pr-3 mt-3 d-flex align-items-center justify-content-end'>
        ${normalizePrice((item.price || 0) * (item.quantity || 0))}
      </div>
      <div className='CoachInvoiceForm__items-cell mt-3 d-flex align-items-center justify-content-center'>
        <Button
          type='button'
          className='d-sm-block d-none'
          variations={['naked']}
          handleClick={onDelete}
        >
          <BucketIcon />
        </Button>
        <DropdownMenu
          list={[
            {
              name: 'Edit',
              callback: onEdit
            },
            {
              name: 'Remove',
              callback: onDelete
            }
          ]}
          className='d-sm-none d-block'
        />
      </div>
      <div className='d-sm-none'>
        <ErrorMessage
          style={{ bottom: '2px' }}
          isVisible={isValidationShown && !isServiceDataValid(item)}
        >
          please fill {!isServiceInputValid('title', item) ? 'name' : ''}
          {!isServiceInputValid('title', item) &&
          !isServiceInputValid('price', item)
            ? ' and '
            : ''}
          {!isServiceInputValid('price', item) ? 'price' : ''}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default CoachInvoiceFormItem;
