import React, { useState, useCallback, useRef } from 'react';
import axios from 'axios';
import './ClientBilling.styles.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import TransactionsList from '../../components/TransactionsList/TransactionsList';
import { BillingItemViewModel } from '../../core/backend/api';
import { useStores } from '../../hooks';
import { deleteClientPayment, getClientBilling } from '../../core/api';
import { getColumns } from '../../components/TransactionsList/TransactionsList.helpers';
import InfoBlockModal from '../../components/Modals/InfoBlockModal/InfoBlockModal';
import { iInfoBlockData } from '../../components/InfoBlock/InfoBlock';
import {
  ConfirmModalEnum,
  ScheduleItemType,
  TransactionsEntityEnum,
  EventStatus,
  InvoiceStatusEnum,
  NotificationTypesEnum,
  SortOrder
} from '../../core/enums';
import { getFromMilliseconds } from '../../core/helpers';
import { useLocation } from 'react-router-dom';
import ClientInvoiceStripeActions, {
  ClientInvoiceStripeActionsRef
} from '../ClientInvoiceView/ClientInvoiceStripeActions';
import { Elements } from '@stripe/react-stripe-js';
import { elementsOptions } from '../../components/Modals/ClientPayInvoice/ClientPayInvoice.helpers';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { normalizeInvoiceFromBackEnd } from '../../core/normalization';
import { PaginatedResponse } from '../../core/types';
import { useFeatures } from '../../hooks/useFeatures';

const PAGE_SIZE = 20;

const ClientBilling = () => {
  const { search } = useLocation();
  const { rootStore } = useStores();
  const { invoicesEnabled } = useFeatures();
  const { userStore, notificationStore, coachInfo } = rootStore;
  const params = new URLSearchParams(search);
  const page = params.get('page');
  const sortBy = params.get('sortBy');
  const sortOrder = +params.get('sortOrder') as SortOrder;
  const searchText = params.get('searchText');
  const actionsRef = useRef<ClientInvoiceStripeActionsRef>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PaginatedResponse<BillingItemViewModel>>({
    items: [],
    totalCount: 0
  });
  const [activeItem, setActiveItem] = useState<BillingItemViewModel>();
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);
  const [infoBlockData, setInfoBlockData] = useState<iInfoBlockData>(null);

  const loadData = () => {
    fetchTransactions(+page, PAGE_SIZE, sortBy, sortOrder, searchText);
  };

  const fetchTransactions = useCallback(
    (
      page: number,
      pageSize: number,
      sortBy: string,
      sortOrder: SortOrder,
      searchText: string
    ) => {
      setIsLoading(true);
      getClientBilling(page, pageSize, sortBy, sortOrder, searchText)
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          if (!(e instanceof axios.Cancel)) {
            setIsLoading(false);
          }
        });
    },
    []
  );

  const getActions = useCallback(
    () => [
      {
        title: 'Cancel recurring payment',
        callback: (item: BillingItemViewModel) => {
          setActiveItem(item);
          actionsRef.current.onCancelSubscription();
        },
        isAvailable: (item: BillingItemViewModel) => {
          return (
            item.entity === TransactionsEntityEnum.RecurringInvoice &&
            (((item.invoice?.status as number) as InvoiceStatusEnum) ===
              InvoiceStatusEnum.paid ||
              ((item.invoice?.status as number) as InvoiceStatusEnum) ===
                InvoiceStatusEnum.uncollectible)
          );
        }
      },
      ...(coachInfo.isStripeConnected
        ? [
            {
              title: 'Change payment method',
              callback: (item: BillingItemViewModel) => {
                setActiveItem(item);
                actionsRef.current.onChangeMethod();
              },
              isAvailable: (item: BillingItemViewModel) => {
                return (
                  item.entity === TransactionsEntityEnum.RecurringInvoice &&
                  (((item.invoice?.status as number) as InvoiceStatusEnum) ===
                    InvoiceStatusEnum.paid ||
                    ((item.invoice?.status as number) as InvoiceStatusEnum) ===
                      InvoiceStatusEnum.uncollectible)
                );
              }
            }
          ]
        : [])
    ],
    [coachInfo.isStripeConnected]
  );

  const getConfimModalData = (
    confirmModalType: ConfirmModalEnum,
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnDisabled: (i: boolean) => void
  ) => {
    const text =
      activeRecord?.entity === TransactionsEntityEnum.Appointment ||
      activeRecord?.entity === TransactionsEntityEnum.Meeting
        ? activeRecord.entity + ' '
        : 'Invoice #';
    switch (confirmModalType) {
      case ConfirmModalEnum.delete:
        return {
          title: `Delete record for ${text}${activeRecord?.entityName}`,
          text: 'Are you sure you want to remove this record from the list?',
          confirmBtnText: 'Yes',
          cancelBtnText: 'No',
          confirm: handleConfirmDelete(
            activeRecord,
            closeModal,
            setIsConfirmBtnDisabled
          )
        };
      default:
        return {
          title: '',
          text: '',
          confirmBtnText: '',
          confirm: () => {}
        };
    }
  };

  const handleConfirmDelete = (
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnDisabled: (i: boolean) => void
  ) => () => {
    setIsConfirmBtnDisabled(true);
    setIsLoading(true);
    deleteClientPayment(activeRecord.id)
      .then(() => {
        loadData();

        notificationStore.addNotification({
          text: `Transaction`,
          textColored: 'deleted successfully'
        });
      })
      .catch(() => {
        notificationStore.addNotification({
          text: `Transaction`,
          textColored: 'delete failed',
          type: NotificationTypesEnum.Error
        });
        setIsLoading(false);
      })
      .finally(() => {
        closeModal();
      });
  };

  const handleView = (item: BillingItemViewModel) => {
    if (item.entity === TransactionsEntityEnum.Meeting) {
      const entityData = item.coachEvent;
      if (!entityData) return;
      setInfoBlockData({
        id: entityData.id,
        type: entityData.type as number,
        name: entityData.name,
        userName: '',
        durationMinutes: getFromMilliseconds(entityData.durationMs, 'minutes'),
        membersCountLimitString:
          entityData.availableSpots === 1 ? 'One-on-One' : 'Group',
        price: entityData.price.toString(),
        location: entityData.location,
        date: entityData.timestamp,
        showCoachAvatar: false,
        timeZone: userStore.timeZone,
        imageId: entityData.thumbnailImageId,
        remindBefore: entityData.remindBeforeMs,
        showSelectBefore: false,
        isCanceled: (entityData.status as number) !== EventStatus.Active
      });
    } else {
      const entityData = item.appointment;
      if (!entityData) return;
      setInfoBlockData({
        id: entityData.id,
        type: ScheduleItemType.Appointment,
        name: entityData.name,
        userName: '',
        durationMinutes: getFromMilliseconds(entityData.durationMs, 'minutes'),
        membersCountLimitString:
          entityData.availableSpots === 1 ? 'One-on-One' : 'Group',
        price: entityData.price.toString(),
        location: entityData.location,
        date: entityData.timestamp,
        showCoachAvatar: false,
        timeZone: userStore.timeZone,
        imageId: entityData.thumbnailImageId,
        remindBefore: entityData.remindBeforeMs,
        showSelectBefore: false,
        isCanceled: (entityData.status as number) !== EventStatus.Active
      });
    }
    setIsViewPopupOpened(true);
  };

  return (
    <div className='ClientBilling p-sm-4 py-3'>
      <PageTitle title='Billing' />

      <TransactionsList
        title='Billing'
        onView={handleView}
        flow={rootStore.flow}
        getConfimModalData={getConfimModalData}
        itemsOnPage={PAGE_SIZE}
        totalCount={data.totalCount}
        isLoading={isLoading}
        columns={getColumns(rootStore.flow, handleView, invoicesEnabled)}
        getActions={getActions}
        transactions={data.items}
        rowHeight={73}
        headerHeight={40}
        fetchTransactions={fetchTransactions}
      />
      <InfoBlockModal
        isOpened={isViewPopupOpened}
        close={() => setIsViewPopupOpened(false)}
        data={infoBlockData}
      />
      <Elements
        stripe={rootStore.coachStripePromise}
        options={elementsOptions as StripeElementsOptions}
      >
        <ClientInvoiceStripeActions
          invoice={
            activeItem ? normalizeInvoiceFromBackEnd(activeItem?.invoice) : null
          }
          onSuccess={loadData}
          ref={actionsRef}
        />
      </Elements>
    </div>
  );
};

export default ClientBilling;
