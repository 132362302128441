import React, { useEffect, useState } from 'react';
import './Account.styles.scss';
import './BillingSettings.styles.scss';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import Button from '../../components/Button/Button';
import CheckedIcon from '../../icons/checked-green.svg';
import {
  changePassword,
  getCoachAccount,
  updateCoachAccount,
  disconnectZoom,
  disconnectGoogle,
  getPricingPlans,
  getAppointmentBlocks,
  disconnectStripe
} from '../../core/api';
import {
  getGoogleConnectUrl,
  getZoomConnectUrl,
  storeDataBeforeStripeConnect,
  storeDataBeforeZoomConnect,
  STRIPE_CONNECTED_KEY,
  ZOOM_CONNECTED_KEY,
  GOOGLE_CONNECTED_KEY
} from '../../core/integrations';

import {
  ChangePasswordViewModel,
  CoachAccountViewModel,
  PricingPlanViewModel
} from '../../core/backend/api';
import StripeImg from '../../images/stripe.png';
import ZoomImg from '../../images/zoom.png';
import GoogleCalendarImg from '../../images/google-calendar.png';
import { observer } from 'mobx-react';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BillingIntervalEnum,
  CoachRoutesEnum,
  LocationTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import { FormConfig, getEmptyAccount } from './Account.helpers';
import PersonalDataForm from '../../components/PersonalDataForm/PersonalDataForm';
import { useFeatures, useStores } from '../../hooks';
import { PersonalData } from '../../components/PersonalDataForm/PersonalDataForm';
import SupportEmailForm from '../../components/SupportEmailForm/SupportEmailForm';
import { AppointmentBlock } from '../../core/types';
import TextEllipse from '../../components/TextEllipse';
import PageTitle from '../../components/PageTitle/PageTitle';
import { normalizePrice } from '../../core/helpers';
import {
  restoreDataAfterZoomConnect,
  restoreDataAfterStripeConnect
} from '../../core/integrations';

const Account: React.FC = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();
  const { zoomEnabled, googleCalendarEnabled } = useFeatures();

  const history = useHistory();
  const { pathname, search } = useLocation();
  const [initialData, setInitialData] = useState<CoachAccountViewModel>(
    getEmptyAccount()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [pricingPlans, setPricingPlans] = useState<PricingPlanViewModel[]>([]);
  const [
    isConfirmStripeDisconnectOpen,
    setIsConfirmStripeDisconnectOpen
  ] = useState<boolean>(false);
  const [
    isConfirmZoomDisconnectOpen,
    setIsConfirmZoomDisconnectOpen
  ] = useState<boolean>(false);

  const ZOOM_CONNECT_URL = getZoomConnectUrl(initialData.userId);
  const GOOGLE_CONNECT_URL = getGoogleConnectUrl(initialData.userId);

  const params = new URLSearchParams(search);
  const isAfterZoomConnected = !!params.get(ZOOM_CONNECTED_KEY);
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);
  const isAfterGoogleConnected = !!params.get(GOOGLE_CONNECTED_KEY);

  const currentPricingPlan = pricingPlans.find(
    ({ id }) => id === initialData.pricingPlanId
  );

  useEffect(() => {
    reFetchData();
    fetchPricingPlans();
  }, []);

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, null);
  };

  const handleDisconnectStripe = () => {
    setIsConfirmStripeDisconnectOpen(true);
  };

  const handleDisconnectStripeCancel = () => {
    setIsConfirmStripeDisconnectOpen(false);
  };

  const handleDisconnectStripeConfirm = () => {
    setIsConfirmStripeDisconnectOpen(false);
    onDisconnectStripe();
  };

  const onDisconnectStripe = async () => {
    setIsLoading(true);
    try {
      await disconnectStripe();

      userStore.loadData();
      setInitialData({
        ...initialData,
        isStripeConnected: false
      });
      notificationStore.addNotification({
        type: NotificationTypesEnum.Success,
        text: 'Stripe is successfully disconnected.'
      });
    } catch (error) {
      notificationStore.addNotification({
        type: NotificationTypesEnum.Error,
        text: 'Disconnect stripe failed.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectZoom = () => {
    storeDataBeforeZoomConnect(pathname, null);
  };

  const handleDisconnectZoom = () => {
    setIsConfirmZoomDisconnectOpen(true);
  };

  const handleDisconnectZoomCancel = () => {
    setIsConfirmZoomDisconnectOpen(false);
  };

  const handleDisconnectZoomConfirm = () => {
    setIsConfirmZoomDisconnectOpen(false);
    onDisconnectZoom();
  };

  const onDisconnectZoom = async () => {
    setIsLoading(true);
    try {
      await disconnectZoom();
      const appointmentBlocks = await getAppointmentBlocks();

      userStore.updateData({ isZoomConnected: false });

      const updatedZoomBlocks = appointmentBlocks.filter(
        (a: AppointmentBlock) =>
          a.locationType === LocationTypeEnum.Custom && a.location === ''
      );
      if (!!updatedZoomBlocks.length) {
        history.push(CoachRoutesEnum.AppointmentBlocks);
      } else {
        setInitialData({
          ...initialData,
          isZoomConnected: false
        });
      }
      notificationStore.addNotification({
        type: NotificationTypesEnum.Success,
        text: 'Zoom is successfully disconnected.'
      });
    } catch (error) {
      notificationStore.addNotification({
        type: NotificationTypesEnum.Error,
        text: 'Disconnect zoom failed.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnectGoogle = () => {
    setIsLoading(true);
    disconnectGoogle()
      .then(() => {
        userStore.updateData({ isGoogleConnected: false, googleUser: '' });
        setInitialData({
          ...initialData,
          isGoogleConnected: false
        });
        notificationStore.addNotification({
          type: NotificationTypesEnum.Success,
          text: 'Google Calendar is successfully disconnected.'
        });
      })
      .catch(() => {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text: 'Disconnect google failed.'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reFetchData = () => {
    setIsLoading(true);
    getCoachAccount()
      .then((newData) => {
        setInitialData(newData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchPricingPlans = () => {
    getPricingPlans().then((data) => {
      setPricingPlans(data);
    });
  };

  const handleSavePersonalData = (payload: PersonalData) => {
    return updateCoachAccount(payload as CoachAccountViewModel)
      .then(() => {
        notificationStore!.addNotification({
          text: 'Your account was updated'
        });
        userStore.updateData({
          imageId: payload.imageId,
          siteName: payload.siteName,
          timeZone: payload.timeZone
        });
        reFetchData();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveSupportEmail = (supportEmail: string) => {
    const payload = {
      ...initialData,
      supportEmail
    };
    return updateCoachAccount(payload)
      .then(() => {
        notificationStore!.addNotification({
          text: 'Your support email was updated'
        });
        reFetchData();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveChangePassword = (payload: ChangePasswordViewModel) => {
    setIsLoading(true);
    return changePassword(payload)
      .then(() => {
        notificationStore.addNotification({
          text: 'Your password was updated'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isAfterZoomConnected) {
      restoreDataAfterZoomConnect(true);
    }
    if (isAfterStripeConnected) {
      restoreDataAfterStripeConnect(true);
    }
    if (
      [
        isAfterZoomConnected,
        isAfterStripeConnected,
        isAfterGoogleConnected
      ].some((p) => p)
    ) {
      history.replace(pathname);
    }
    // eslint-disable-next-line
  }, [isAfterStripeConnected, isAfterZoomConnected, isAfterGoogleConnected]);

  return (
    <>
      <PageTitle title='Account' />
      <GlobalLoader isLoading={isLoading} />
      <div className='Account p-sm-4 p-3'>
        <PersonalDataForm
          initialData={initialData}
          onSave={handleSavePersonalData}
          formConfig={FormConfig}
          withSiteName
        />

        <ChangePasswordForm
          email={initialData.email}
          onSave={handleSaveChangePassword}
          className='row mx-n2'
        />

        <SupportEmailForm
          email={initialData.supportEmail}
          onSave={handleSaveSupportEmail}
          className='row mx-n2'
        />
        <div className='BillingSettings Account__block row mx-n2'>
          <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
            <div className='BillingSettings__title'>Use Stripe payments</div>
            <div className='BillingSettings__payment'>
              <div className='BillingSettings__image mr-sm-3 mr-2'>
                <img src={StripeImg} alt='' />
              </div>
              <div className='BillingSettings__column d-flex flex-column'>
                <div className='BillingSettings__description'>
                  Connect your Stripe account to take payments through Stripe
                </div>
                {initialData.isStripeConnected ? (
                  <div className='d-flex justify-content-between flex-sm-row flex-column'>
                    <div className='d-flex  flex-column mr-3 mw-100'>
                      <div className='BillingSettings__connected mr-4'>
                        <div className='BillingSettings__connected-icon'>
                          <CheckedIcon />
                        </div>
                        Connected
                      </div>
                      <div className='Account__user'>
                        <TextEllipse text={userStore.stripeUser}>
                          <span>{userStore.stripeUser}</span>
                        </TextEllipse>
                      </div>
                    </div>
                    <Button
                      type='button'
                      className='button__inversed button__sm-width button__no-margin button__mt'
                      handleClick={handleDisconnectStripe}
                    >
                      Disconnect
                    </Button>
                  </div>
                ) : (
                  <Button
                    type='button'
                    className='align-self-start button__inversed button__md-width button__no-margin button__mt'
                    link={CoachRoutesEnum.ConnectStripe}
                    data-testid='stripe-connect-button'
                    handleClick={handleConnectStripe}
                  >
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='BillingSettings Account__block row mx-n2'>
          <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
            <div className='BillingSettings__title'>
              Use Zoom for your meetings
            </div>
            <div className='BillingSettings__payment'>
              <div className='BillingSettings__image mr-sm-3 mr-2'>
                <img src={ZoomImg} alt='' />
              </div>
              <div className='BillingSettings__column'>
                <div className='BillingSettings__description'>
                  Connect your Zoom account to hold online meetings and
                  appointments with your clients.
                </div>
                {!zoomEnabled ? (
                  <Button
                    type='button'
                    className='button__inversed button__md-width button__no-margin button__mt'
                    disabled
                  >
                    Coming soon
                  </Button>
                ) : initialData.isZoomConnected ? (
                  <div className='d-flex justify-content-between flex-sm-row flex-column'>
                    <div className='d-flex  flex-column mr-3 mw-100'>
                      <div className='BillingSettings__connected mr-4'>
                        <div className='BillingSettings__connected-icon'>
                          <CheckedIcon />
                        </div>
                        Connected
                      </div>
                      <div className='Account__user'>
                        <TextEllipse text={userStore.zoomUserEmail}>
                          <span>{userStore.zoomUserEmail}</span>
                        </TextEllipse>
                      </div>
                    </div>
                    <Button
                      type='button'
                      className='button__inversed button__sm-width button__no-margin button__mt'
                      handleClick={handleDisconnectZoom}
                    >
                      Disconnect
                    </Button>
                  </div>
                ) : (
                  <Button
                    className='button__inversed button__md-width button__no-margin button__mt'
                    url={ZOOM_CONNECT_URL}
                    handleClick={handleConnectZoom}
                  >
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='BillingSettings Account__block row mx-n2'>
          <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
            <div className='BillingSettings__title'>Use Google Calendar</div>
            <div className='BillingSettings__payment'>
              <div className='BillingSettings__image mr-sm-3 mr-2'>
                <div className='p-3'>
                  <img src={GoogleCalendarImg} alt='' />
                </div>
              </div>
              <div className='BillingSettings__column'>
                <div className='BillingSettings__description'>
                  Connect your calendar to get all your CoachPro meetings and
                  appointments there.
                </div>
                {!googleCalendarEnabled ? (
                  <Button
                    type='button'
                    className='button__inversed button__md-width button__no-margin button__mt'
                    disabled
                  >
                    Coming soon
                  </Button>
                ) : initialData.isGoogleConnected ? (
                  <div className='d-flex justify-content-between flex-sm-row flex-wrap flex-column align-items-sm-center align-items-start'>
                    <div className='d-flex  flex-column mr-3 mw-100'>
                      <div className='BillingSettings__connected'>
                        <span className='BillingSettings__connected-icon'>
                          <CheckedIcon />
                        </span>
                        Connected
                      </div>
                      <div className='Account__user'>
                        <TextEllipse text={userStore.googleUser}>
                          <span>{userStore.googleUser}</span>
                        </TextEllipse>
                      </div>
                    </div>

                    <Button
                      type='button'
                      className='button__inversed button__sm-width button__no-margin button__mt'
                      handleClick={handleDisconnectGoogle}
                    >
                      Disconnect
                    </Button>
                  </div>
                ) : (
                  <Button
                    className='button__inversed button__md-width button__no-margin button__mt'
                    url={GOOGLE_CONNECT_URL}
                  >
                    Connect
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        {currentPricingPlan && (
          <div className='Account__block row mx-n2 Account__block--no-border-bottom'>
            <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
              <div className='Account__title'>
                Current Package Plan
                {/* <Button
                   className="button__naked"
                   handleClick={() => {}}
                >
                   Change plan
                </Button> */}
              </div>
              <div className='Account__plan-table row align-items-start px-4 py-3 mt-3'>
                <div className='col-sm-7 mb-sm-0 mb-3'>
                  <div className='Account__plan-title'>Package name</div>
                  <div
                    className='Account__plan-text'
                    data-testid='package-plan-name'
                  >
                    {currentPricingPlan!.name}
                  </div>
                </div>
                <div className='col-sm-5 mb-sm-0 mb-3'>
                  <div className='Account__plan-title'>
                    {
                      BillingIntervalEnum[
                        currentPricingPlan!.subscription!.billingInterval
                      ]
                    }{' '}
                    price
                  </div>
                  <div
                    className='Account__plan-text'
                    data-testid='package-plan-price'
                  >
                    {currentPricingPlan!.subscription
                      ? `$${normalizePrice(
                          currentPricingPlan!.subscription!.priceAmount!
                        )}`
                      : 'Free'}
                  </div>
                </div>
                {/* <div className='col-sm-4 col-6 mb-sm-0 mb-3'>
                  <div className='Account__plan-title'>
                    Commission per session
                  </div>
                  <div className='Account__plan-text'>
                    {currentPricingPlan!.systemFeePercent
                      ? `${currentPricingPlan!.systemFeePercent}%`
                      : 'Free'}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>

      <ConfirmModal
        isOpened={isConfirmZoomDisconnectOpen}
        close={handleDisconnectZoomCancel}
        title='Disconnect Zoom'
        text={
          <>
            If you disconnect your Zoom account we'll remove Zoom links from all
            scheduled appointments. To get them back you'll have to add a Custom
            location to each appointment block. Otherwise, your clients won't be
            able to join appointments with you.
            <br /> Also, you won't be able to reschedule existing meetings or
            create new meetings & appointment blocks with Zoom location.
            <br /> Are you sure you want to do this?
          </>
        }
        confirmBtnText='Yes'
        confirmCallback={handleDisconnectZoomConfirm}
        cancelBtnText='No'
        cancelCallback={handleDisconnectZoomCancel}
      />
      <ConfirmModal
        isOpened={isConfirmStripeDisconnectOpen}
        close={handleDisconnectStripeCancel}
        title='Disconnect Stripe'
        text={
          <>
            If you disconnect your Stripe account you will lose some billing
            information related to your current Stripe account.
            <br /> Also, you won't be able to make refunds for already done
            transactions.
            <br /> Your clients will not see transactions for created recurring
            invoices and will not be able to manage their recurring invoices.
            <br /> All your paid meetings, paid appointment blocks, open
            invoices will be hidden either.
            <br /> Are you sure you want to do this?
          </>
        }
        confirmBtnText='Yes'
        confirmCallback={handleDisconnectStripeConfirm}
        cancelBtnText='No'
        cancelCallback={handleDisconnectStripeCancel}
      />
    </>
  );
};

export default observer(Account);
