import React from 'react';
import Paginate from 'react-paginate';
import NextIcon from '../../icons/next.svg';
import PreviousIcon from '../../icons/previous.svg';
import './Pagination.styles.scss';

interface Props {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  onPageGhange?: (page: number) => void;
  className?: string;
}

export const Pagination: React.FC<Props> = ({
  pageSize,
  pageNumber,
  totalCount,
  onPageGhange,
  className
}) => {
  const pageCount = Math.ceil(totalCount / pageSize);

  const handlePageChange = ({ selected }: { selected: number }) => {
    onPageGhange && onPageGhange(selected + 1);
  };

  return (
    totalCount > pageSize && (
      <div className={className}>
        <Paginate
          forcePage={pageNumber - 1}
          pageCount={pageCount}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          containerClassName='Pagination'
          onPageChange={handlePageChange}
          nextLabel={<NextIcon />}
          previousLabel={<PreviousIcon />}
        />
      </div>
    )
  );
};
