import React, { FC } from 'react';
import './TermsAndConditions.styles.scss';
import TilePage from '../../components/TilePage/TilePage';

const TermsAndCondition: FC<{}> = () => {
  return (
    <TilePage
      className='TermsAndConditions'
      title='Terms & Conditions'
      tileStyle={{ width: 648 }}
    >
      <h1>Terms & Conditions</h1>
    </TilePage>
  );
};

export default TermsAndCondition;
