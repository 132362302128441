import './InvoicesItem.styles.scss';
import React, { useState, useRef } from 'react';
import { Invoice } from '../../core/types';
import AvatarIcon from '../../icons/avatar-small.svg';
import { getPublicFile } from '../../core/api';
import Badge from '../../components/Badge/Badge';
import { getBadgeType } from '../../pages/CoachInvoices/CoachInvoices.helpers';
import { InvoiceStatusEnum } from '../../core/enums';
import moment from 'moment';
import cn from 'classnames';
import { useOnClickOutside } from '../../hooks';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import TextEllipse from '../../components/TextEllipse';
import { normalizePrice } from '../../core/helpers';
import { ListItemProps } from '../Dropdown/Dropdown';

export type NameOptionType = 'name' | 'number';

interface Props {
  item: Invoice;
  onClick: (item: Invoice) => void;
  actions: ListItemProps[];
  withAvatar?: boolean;
  nameOption: NameOptionType;
}

const TOUCH_DURATION = 500;

const InvoicesItem: React.FC<Props> = ({
  item: invoice,
  onClick,
  actions,
  withAvatar = true,
  nameOption
}) => {
  const ref = useRef();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [isActive, setIsActive] = useState<boolean>(false);

  useOnClickOutside(
    ref,
    () => {
      setIsActive(false);
    },
    isActive
  );

  const status = InvoiceStatusEnum[
    invoice.status
  ] as keyof typeof InvoiceStatusEnum;

  const handleTouchEnd = () => {
    if (timerId) clearTimeout(timerId);
  };

  const handleLongTouch = () => {
    setIsActive(!isActive);
  };

  const handleTouchStart = () => {
    if (!actions.length) {
      return;
    }
    setTimerId(setTimeout(handleLongTouch, TOUCH_DURATION));
  };

  const handleClick = () => {
    onClick(invoice);
  };

  const renderTextElipse = (nameOption: NameOptionType) => {
    if (nameOption === 'name') {
      return (
        <TextEllipse
          text={`${invoice.client?.firstName} ${invoice.client?.lastName}`}
        >
          <span>
            {invoice.client?.firstName} {invoice.client?.lastName}
          </span>
        </TextEllipse>
      );
    }
    if (nameOption === 'number') {
      return (
        <TextEllipse text={`${invoice.number}`}>
          <span>{invoice.number}</span>
        </TextEllipse>
      );
    }
  };

  return (
    <div
      className='InvoicesItem d-flex align-items-stretch'
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      ref={ref}
    >
      <div
        className={cn(
          'd-flex align-items-stretch w-100 InvoicesItem__container',
          {
            'InvoicesItem__container--opened': isActive
          }
        )}
      >
        <div
          className='p-3 flex-shrink-0  d-flex align-items-center w-100'
          onClick={handleClick}
        >
          {withAvatar && (
            <div className='InvoicesItem__avatar flex-shrink-0 mr-3'>
              {invoice.client?.imageId ? (
                <img src={getPublicFile(invoice.client.imageId)} alt='avatar' />
              ) : (
                <AvatarIcon />
              )}
            </div>
          )}
          <div className='InvoicesItem__content d-flex justify-content-between flex-grow-1'>
            <div className='InvoicesItem__info'>
              <div className='InvoicesItem__client mb-1 mr-3'>
                {renderTextElipse(nameOption)}
              </div>
              <Badge
                type={getBadgeType(status)}
                title={status.toUpperCase()}
                className='Badge--InvoicesItem'
              />
            </div>
            <div className='flex-shrink-0 d-flex flex-column align-items-end'>
              <div className='InvoicesItem__total mb-2'>
                ${normalizePrice(invoice.amount)}
              </div>
              <div className='InvoicesItem__date'>
                Due on {moment.utc(invoice.dueDate).format('MMM D')}
              </div>
            </div>
          </div>
        </div>
        <div className='InvoicesItem__menu flex-shrink-0 d-flex justify-content-center align-items-center'>
          <DropdownMenu
            list={actions}
            className='DropdownMenu--table'
            withPortal
            fullScreen
            title={`Invoice #${invoice.number}`}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoicesItem;
