import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

import { Point, Area } from 'react-easy-crop/types';
import { CroppedArea } from './ImageCropper.helpers';
import Slider from 'rc-slider';
import RotateIcon from '../../icons/rotate.svg';
import './ImageCropper.styles.scss';
import 'rc-slider/assets/index.css';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  imageSrc: string;
  aspect: number;
  onCrop: (croppedAreaPixels: Area, cropped: CroppedArea) => void;
  croppedArea?: CroppedArea;
}

const ImageCropper: React.FC<Props> = ({
  imageSrc,
  aspect,
  onCrop,
  croppedArea
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const onCropComplete = (_: any, croppedAreaPixels: Area) => {
    onCrop(croppedAreaPixels, {
      zoom,
      crop,
      rotation
    });
  };

  const handleRotation = () => {
    let newRotation = 0;
    if (rotation === 270) {
      newRotation = 0;
    } else {
      newRotation = rotation + 90;
    }
    setRotation(newRotation);
  };

  const handleMediaLoaded = () => {
    if (croppedArea) {
      croppedArea.rotation && setRotation(croppedArea.rotation);
      setZoom(croppedArea.zoom);
      setCrop(croppedArea.crop);
    } else {
      setCrop({ x: 0, y: 0 });
      setRotation(0);
    }
  };

  return (
    <div className='ImageCropper'>
      <Cropper
        image={imageSrc}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        onMediaLoaded={handleMediaLoaded}
        showGrid={false}
        maxZoom={5}
        minZoom={1}
        rotation={rotation}
        zoomSpeed={0.5}
      />
      <div className='ImageCropper__controls'>
        <div className='ImageCropper__zoom'>
          <div className='ImageCropper__zoomLabel'>Zoom </div>
          <Slider
            step={0.05}
            min={1}
            max={10}
            value={zoom}
            onChange={(value) => {
              setZoom(value);
            }}
            defaultValue={zoom}
            handleStyle={{ backgroundColor: '#37A0CE', border: '#37A0CE' }}
            trackStyle={{ backgroundColor: '#37A0CE' }}
            railStyle={{ backgroundColor: '#fff' }}
          />
          <div className='ImageCropper__rotate' onClick={handleRotation}>
            <Tooltip
              trigger={<RotateIcon />}
              content={<span>Rotate</span>}
              triggerType='hover'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
