import React from 'react';
import CheckedIcon from '../../icons/checked.svg';
import TextEllipse from '../TextEllipse';

interface iProps {
  labelText: string | JSX.Element;
  onChange: () => void;
  isChecked: any;
  noIcon?: boolean;
  isPartialChecked?: boolean;
  isRadioBtn?: boolean;
  className?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  tabIndex?: number;
}

const SelectItem: React.FC<iProps> = ({
  isChecked,
  labelText,
  onFocus,
  onBlur,
  className = '',
  noIcon = true,
  isDisabled,
  tabIndex
}) => {
  return (
    <div
      className={`SelectItem ${
        isChecked ? 'SelectItem--selected' : ''
      } ${className}`}
    >
      {!noIcon ? (
        <div
          className={`SelectItem__square ${
            isChecked ? 'SelectItem__square--checked' : ''
          }`}
        >
          <span
            className={`SelectItem__icon ${
              isChecked ? 'SelectItem__icon--checked' : ''
            }`}
          >
            <CheckedIcon />
          </span>
        </div>
      ) : null}
      {onFocus && onBlur ? (
        <>
          {typeof labelText !== 'string' ? (
            React.cloneElement(labelText, {
              tabIndex: isDisabled ? -1 : tabIndex ? tabIndex : 0,
              onFocus: onFocus,
              onBlur: onBlur
            })
          ) : (
            <input
              type='text'
              value={labelText}
              className='SelectItem__input'
              readOnly
              onFocus={onFocus}
              onBlur={onBlur}
              tabIndex={isDisabled ? -1 : tabIndex ? tabIndex : 0}
            />
          )}
        </>
      ) : (
        <TextEllipse text={labelText as string}>
          <span>{labelText}</span>
        </TextEllipse>
      )}
    </div>
  );
};

export default SelectItem;
