import './CoachClientsItem.styles.scss';
import React, { useState, useRef } from 'react';
import { CoachContactItem } from '../../core/types';
import AvatarIcon from '../../icons/avatar-small.svg';
import { getPublicFile } from '../../core/api';
import cn from 'classnames';
import { useOnClickOutside } from '../../hooks';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import { TableAction } from '../../components/Table/Table';
import TextEllipse from '../../components/TextEllipse';
import { highlightMatches } from '../../core/helpers';

export type NameOptionType = 'name' | 'number';

interface Props {
  item: CoachContactItem;
  onClick: (client: CoachContactItem) => () => void;
  actions: TableAction<CoachContactItem>[];
  withAvatar?: boolean;
  nameOption: NameOptionType;
  searchText: string;
}

const TOUCH_DURATION = 500;

const CoachClientsItem: React.FC<Props> = ({
  item: client,
  onClick,
  actions,
  searchText,
  withAvatar = true
}) => {
  const ref = useRef();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [isActive, setIsActive] = useState<boolean>(false);

  useOnClickOutside(
    ref,
    () => {
      setIsActive(false);
    },
    isActive
  );

  const handleTouchEnd = () => {
    if (timerId) clearTimeout(timerId);
  };

  const handleLongTouch = () => {
    setIsActive(!isActive);
  };

  const handleTouchStart = () => {
    setTimerId(setTimeout(handleLongTouch, TOUCH_DURATION));
  };

  return (
    <div
      className='CoachClientsItem d-flex align-items-stretch'
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      ref={ref}
    >
      <div
        className={cn(
          'd-flex align-items-stretch w-100 CoachClientsItem__container',
          {
            'CoachClientsItem__container--opened': isActive
          }
        )}
      >
        <div
          className='flex-shrink-0 d-flex align-items-center w-100 CoachClientsItem__content'
          onClick={onClick(client)}
        >
          {withAvatar && (
            <div className='CoachClientsItem__avatar flex-shrink-0 mr-3'>
              {client.imageId ? (
                <img src={getPublicFile(client.imageId)} alt='avatar' />
              ) : (
                <AvatarIcon />
              )}
            </div>
          )}
          <div className='CoachClientsItem__info d-inline-flex flex-column justify-content-between flex-grow-1'>
            <TextEllipse text={client.fullName}>
              <span
                className='CoachClientsItem__name'
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    String(`${client.fullName}`),
                    searchText
                  )
                }}
              />
            </TextEllipse>
            <TextEllipse text={client.email}>
              <span
                className='CoachClientsItem__name'
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    String(`${client.email}`),
                    searchText
                  )
                }}
              />
            </TextEllipse>
          </div>
        </div>
        <div className='CoachClientsItem__menu flex-shrink-0 d-flex justify-content-center align-items-center'>
          <DropdownMenu
            list={actions
              .filter((a) => (a.isAvailable ? a.isAvailable(client) : true))
              .map((act) => ({
                name: act.title,
                callback: () => act.callback(client),
                icon: act.icon ? (
                  <div className='Table__icon'>{act.icon}</div>
                ) : undefined
              }))}
            className='DropdownMenu--table'
            withPortal
            fullScreen
            title={client.fullName}
          />
        </div>
      </div>
    </div>
  );
};

export default CoachClientsItem;
