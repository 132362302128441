import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import CoachPage from '../../components/CoachPage/CoachPage';
import { iBookingItem } from '../../core/types';
import { observer } from 'mobx-react';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  getCoachBookedEvents,
  isClientResetPasswordTokenValid
} from '../../core/api';
import ClientSignInModal from '../../components/Modals/ClientSignInModal/ClientSignInModal';
import ClientForgotPasswordModal from '../../components/Modals/ClientForgotPasswordModal/ClientForgotPasswordModal';
import ClientNewPasswordModal from '../../components/Modals/ClientNewPasswordModal/ClientNewPasswordModal';
import ResetLinkExpired from '../../components/ResetLinkExpired/ResetLinkExpired';
import { iNewPasswordData } from '../../components/NewPassword/NewPassword';
import { getPublicFile } from '../../core/api';
import { useStores } from '../../hooks';
import ClientUserStore from '../../store/ClientUserStore';
import PageTitle from '../../components/PageTitle/PageTitle';
import { ClientRoutesEnum } from '../../core/enums';
import { getRoutePath } from '../../core/globals';

const ClientCoachPage = () => {
  const { rootStore } = useStores<ClientUserStore>();
  const {
    coachInfo: coachAccountData,
    userStore,
    notificationStore
  } = rootStore;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [newPassData, setNewPassData] = useState<iNewPasswordData>({
    email: '',
    token: ''
  });
  const [isSignInPopupOpened, setIsSignInPopupOpened] = useState<boolean>(
    false
  );
  const [isForgotPopupOpened, setIsForgotPopupOpened] = useState<boolean>(
    false
  );
  const [isForgotPopupLinkExpired, setIsForgotPopupLinkExpired] = useState<
    boolean
  >(false);
  const [isNewPasswordPopupOpened, setIsNewPasswordPopupOpened] = useState<
    boolean
  >(false);
  const [isTileOpening, setIsTileOpening] = useState<boolean>(false);
  const [bookingItems, setBookingItems] = useState<iBookingItem[]>([]);
  const coachPageCustomizationData = rootStore.coachCustomizationInfo;
  const { pathname, search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  useEffect(() => {
    const isNewPassModalOpened = pathname.indexOf('resetPassword') > 0;
    if (search !== '') {
      const params = new URLSearchParams(search);
      const isEmailConfirmed = params.get('isEmailConfirmed') === 'true';
      const token = params.get('token') || '';
      const email = params.get('email') || '';
      const redirectToParam = params.get('redirectTo') || '';
      if (redirectToParam) {
        userStore.setRedirectToAfterSignIn(redirectToParam);
        setIsSignInPopupOpened(true);
      }
      if (isEmailConfirmed) {
        userStore.setShowEmailConfirmed(true);
      }
      if (token !== '' && email !== '') {
        isClientResetPasswordTokenValid({ email, token }).then((isValid) => {
          setIsForgotPopupLinkExpired(!isValid);
          if (isValid) {
            if (isNewPassModalOpened) {
              setIsNewPasswordPopupOpened(true);
            }
            setNewPassData({
              token,
              email
            });
          }
        });
      }

      history.replace(pathname);
    }
  }, [search]);

  const reFetchData = () => {
    setIsLoading(true);
    getCoachBookedEvents()
      .then((data) => {
        setBookingItems(data);
      })
      .catch(() => {
        setBookingItems([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTileClickCallback = async (tileData: iBookingItem) => {
    let updatedItem;
    if (isTileOpening) return;
    setIsTileOpening(true);
    await getCoachBookedEvents().then((data) => {
      setBookingItems(data);
      updatedItem = data.find((item) => item.id === tileData.id);
      setIsTileOpening(false);
    });
    const item = updatedItem ? updatedItem : tileData;
    history.push(`/${rootStore.siteName}/${item.link}`);
  };

  const normalizePageTitle = (name: string) => {
    if (!name) {
      return '';
    }
    return name
      .split('_')
      .filter((i) => !!i)
      .map((symbol) => symbol[0].toUpperCase() + symbol.substring(1))
      .join(' ');
  };

  return (
    <>
      <PageTitle title={normalizePageTitle(coachAccountData.siteName)} />
      <GlobalLoader isLoading={isLoading || isTileOpening} />
      {rootStore.coachCustomizationInfo.logoSrc && !userStore.isSignedIn ? (
        <img
          src={getPublicFile(rootStore.coachCustomizationInfo.logoSrc)}
          alt='logo'
          className='CoachPage__logo'
        />
      ) : null}
      {!isLoading && (
        <CoachPage
          coachData={coachPageCustomizationData}
          coachAccountData={coachAccountData}
          bookingItems={bookingItems}
          isSignInButtonHidden={userStore!.isSignedIn}
          handleTileClick={handleTileClickCallback}
          handleSignInClick={() => setIsSignInPopupOpened(true)}
        />
      )}
      {!userStore.isSignedIn && (
        <ClientSignInModal
          isOpened={isSignInPopupOpened}
          close={() => setIsSignInPopupOpened(false)}
          showForgot={() => setIsForgotPopupOpened(true)}
        />
      )}
      <ClientForgotPasswordModal
        isOpened={isForgotPopupOpened}
        close={() => setIsForgotPopupOpened(false)}
      />
      <ResetLinkExpired
        isOpened={isForgotPopupLinkExpired}
        close={() => setIsForgotPopupLinkExpired(false)}
        showSignIn={() => setIsSignInPopupOpened(true)}
        showForgot={() => setIsForgotPopupOpened(true)}
      />
      <ClientNewPasswordModal
        isOpened={isNewPasswordPopupOpened && !isForgotPopupLinkExpired}
        close={() => setIsNewPasswordPopupOpened(false)}
        data={newPassData}
        onError={() => {
          setIsForgotPopupLinkExpired(true);
          setIsNewPasswordPopupOpened(false);
        }}
        onSucces={(data) => {
          userStore.signIn(data);
          notificationStore.addNotification({
            text: 'Your password has been changed'
          });
          history.push(getRoutePath(ClientRoutesEnum.CoachPage));
        }}
      />
    </>
  );
};

export default observer(ClientCoachPage);
