import React from 'react';
import { NoteViewModel } from '../../core/backend/api';
import moment from 'moment';
import './Note.styles.scss';
import AccordionButton from '../AccordionButton/AccordionButton';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import TextEllipse from '../TextEllipse';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  note: NoteViewModel;
  onEdit?: () => void;
  onDelete?: (note: NoteViewModel) => void;
  withShared?: boolean;
  accordionClassName?: string;
  breakpoint?: 'sm' | 'md';
}

const Note: React.FC<Props> = ({
  note,
  onEdit,
  onDelete,
  withShared = false,
  accordionClassName = '',
  breakpoint = 'md'
}) => {
  return (
    <div className='Note px-xl-4 p-3 mb-sm-4 mb-3'>
      <div className='d-flex align-items-start'>
        <div className='d-flex flex-column flex-grow-1 w-100'>
          <div className='d-flex justify-content-between'>
            <div className={`d-flex flex-${breakpoint}-row flex-column`}>
              <div className='Note__date'>
                {moment.utc(note.createdOn).format('ll')}
              </div>
              {note.modifiedOn && (
                <div className='Note__date d-flex aling-intems-center'>
                  <div
                    className={`px-xl-3 px-1 d-none d-${breakpoint}-inline-block`}
                  >
                    &#8226;
                  </div>
                  <div>
                    Edited at {moment.utc(note.modifiedOn).format('ll')}
                  </div>
                </div>
              )}
            </div>

            {withShared && note.isShared && (
              <div className='Note__shared align-self-start'>Shared</div>
            )}
          </div>
          <div className='d-flex align-items-start'>
            <AccordionButton
              className={`flex-grow-1 ${accordionClassName}`}
              nodeContent={
                <div
                  className='Note__text'
                  dangerouslySetInnerHTML={{
                    __html: note.description
                  }}
                />
              }
            >
              <TextEllipse className='Note__title' text={note.title}>
                <span>{note.title}</span>
              </TextEllipse>
            </AccordionButton>
            {onEdit && onDelete && (
              <div className='d-flex flex-shrink-0 ml-3 mr-n1 mt-2'>
                <Tooltip
                  className='d-flex align-items-center justify-content-center'
                  trigger={
                    <button
                      className='Note__btn flex-shrink-0'
                      onClick={onEdit}
                    >
                      <PencilIcon />
                    </button>
                  }
                  content={<>Edit</>}
                  triggerType='hover'
                />
                <div className='px-1 px-sm-2 ml-sm-n1'></div>
                <Tooltip
                  minWidth={62}
                  className='d-flex align-items-center justify-content-center'
                  trigger={
                    <button
                      className='Note__btn flex-shrink-0'
                      onClick={() => {
                        onDelete(note);
                      }}
                    >
                      <BucketIcon />
                    </button>
                  }
                  content={<>Remove</>}
                  triggerType='hover'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
