import React from 'react';
import './CoachDashboardScheduleItem.styles.scss';
import GroupIcon from '../../icons/two-members.svg';
import ViewIcon from '../../icons/view-icon.svg';
import classnames from 'classnames';
import { iListViewItem } from '../../core/types';
import moment from 'moment';
import Button from '../Button/Button';
import TextEllipse from '../TextEllipse';
import Tooltip from '../Tooltip/Tooltip';
import { ScheduleItemType } from '../../core/enums';

interface iProps {
  item: iListViewItem;
  className?: string;
  onView: (id: number, itemType: ScheduleItemType) => void;
}

const CoachDashboardScheduleItem: React.FC<iProps> = ({
  item,
  className = '',
  onView
}) => {
  const classNames = classnames('CoachDashboardScheduleItem', className, {
    'CoachDashboardScheduleItem--readonly': item.isCanceled
  });

  const handleView = () => {
    onView(item.id, item.itemType);
  };
  return (
    <div className={classNames}>
      <div
        className='CoachDashboardScheduleItem__dot flex-shrink-0'
        style={{ backgroundColor: item.cellBgColor }}
      ></div>
      <div className='CoachDashboardScheduleItem__content flex-grow-1'>
        <div className='d-flex aling-items-center flex-sm-row flex-column'>
          <div className='CoachDashboardScheduleItem__date flex-grow-1'>
            {moment.utc(item.date).format('MMM D, h:mma')}
          </div>
          {item.itemType === ScheduleItemType.Event && (
            <div className='CoachDashboardScheduleItem__members-info'>
              <GroupIcon />
              {item.details.membersToAvailable}
            </div>
          )}
        </div>
        <TextEllipse
          className='CoachDashboardScheduleItem__text'
          text={item.details.text}
        >
          <span>{item.details.text}</span>
        </TextEllipse>
      </div>
      <Tooltip
        className='d-flex align-items-center justify-content-center'
        trigger={
          <Button
            handleClick={handleView}
            variations={['naked']}
            className='ml-0 p-0 CoachDashboardScheduleItem__icon flex-shrink-0'
          >
            <ViewIcon />
          </Button>
        }
        content={<>View</>}
        triggerType='hover'
        withPortal
      />
    </div>
  );
};

export default CoachDashboardScheduleItem;
