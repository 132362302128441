import { FileResponseViewModel } from '../../core/backend/api';
import { searchBy } from '../../core/helpers';

export function getLinkType(value: string) {
  const isVideo =
    value.split('|')[0] === 'youtube' || value.split('|')[0] === 'vimeo';

  return isVideo ? 'video' : 'link';
}

export function parseLink(value: string) {
  const link = value.split('|');
  return link[link.length - 1];
}

export const getValidLink = (link: string) => {
  const protocols = ['http', 'https', 'ftp'];
  if (!protocols.some((p) => link.startsWith(p + '://'))) {
    return 'http://' + link;
  }
  return link;
};

export const filterForSearch = (
  debouncedSearchString: string,
  file: FileResponseViewModel
) => {
  let link = '';

  if (file.fileLink) {
    const isVideoLink = getLinkType(file.fileLink) === 'video';
    link = isVideoLink ? parseLink(file.fileLink) : file.fileLink;
  }

  return (
    searchBy(file.name, debouncedSearchString) ||
    searchBy(file.description, debouncedSearchString) ||
    searchBy(link, debouncedSearchString)
  );
};
