import React, { useState, useEffect } from 'react';
import './GrantAccessModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { FileResponseViewModel } from '../../../core/backend/api';
import { getExt } from '../../../core/helpers';
import { getLinkType } from '../../../pages/CoachLibraryAll/CoachLibraryAll.helpers';
import FileItem from '../../FileItem/FileItem';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import EmptyState from '../../EmptyState/EmptyState';
import FileIcon from '../../../icons/file_large.svg';
import { useDebounce } from '../../../hooks';
import SearchInput from '../../SearchInput/SearchInput';
import { useSearchInput } from '../../SearchInput/useSearchInput';
import { filterForSearch } from '../../../pages/CoachLibraryAll/CoachLibraryAll.helpers';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  files: FileResponseViewModel[];
  onSubmit: (selectedIds: number[]) => Promise<void>;
  clientName: string;
}

const GrantAccessModal: React.FC<Props> = ({
  onClose,
  isOpened,
  files,
  onSubmit,
  clientName
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [expandedId, setExpandedId] = useState<string | number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    searchText,
    handleSearch,
    searchedItems: searchedFiles
  } = useSearchInput(files, filterForSearch);
  const debouncedIsSubmitting = useDebounce(isSubmitting, 200);

  const handleFileSelect = (id: number) => () => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) return prev.filter((fileId) => fileId !== id);
      return [...prev, id];
    });
  };

  const handleSelectAll = () => {
    if (selectedIds.length === files.length) return setSelectedIds([]);
    setSelectedIds(files.map((f) => f.id));
  };

  useEffect(() => {
    isOpened && setSelectedIds([]);
  }, [isOpened]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(selectedIds).finally(() => {
      setIsSubmitting(false);
      onClose();
    });
  };

  return (
    <Modal
      close={onClose}
      isOpened={isOpened}
      style={{
        width: 1130,
        overflow: 'hidden',
        paddingBottom: 0
      }}
      className='GrantAccessModal'
    >
      <div className='d-flex flex-column justify-content-between GrantAccessModal__inner'>
        <div className='d-flex flex-column flex-grow-1'>
          <div className='GrantAccessModal__title mb-2'>Grant Access</div>
          <div className='GrantAccessModal__text GrantAccessModal__text--centered mb-3'>
            Select files you would like to share with {clientName}
          </div>
          <div className='d-flex justify-content-between justify-content-sm-end align-items-center mb-md-4 mb-3'>
            {!!files.length && (
              <div className='flex-shrink-0 mr-3'>
                <FormCheckbox
                  isSquare
                  id='select-all'
                  checked={selectedIds.length === files.length}
                  handleChange={handleSelectAll}
                  className='mr-0'
                  label='Select All'
                />
              </div>
            )}
            <SearchInput onSearch={handleSearch} searchText={searchText} />
          </div>

          {searchedFiles.length > 0 ? (
            <div className='row GrantAccessModal__container px-md-1 mx-md-n3'>
              {searchedFiles.map((file) => {
                const { id, awsUrl, fileLink } = file;
                const ext = getExt(awsUrl);
                const linkType = fileLink ? getLinkType(fileLink) : undefined;

                return (
                  <div className='col-md-6 px-md-3' key={id}>
                    <FileItem
                      file={{ ...file, ext }}
                      linkType={linkType}
                      isDownloading={false}
                      nameMaxLength={35}
                      isExpanded={expandedId === id}
                      onToggle={setExpandedId}
                      className='mx-md-n1'
                    >
                      <div className='GrantAccessModal__fileCheckbox d-flex align-items-center justify-content-center'>
                        <FormCheckbox
                          isSquare
                          id={`file-${id}`}
                          checked={selectedIds.includes(id)}
                          handleChange={handleFileSelect(id)}
                          labelBefore='Share with Client'
                          className='mr-0'
                        />
                      </div>
                    </FileItem>
                  </div>
                );
              })}
            </div>
          ) : (
            <EmptyState text='There are no files here' icon={<FileIcon />} />
          )}
        </div>
        <div className='d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center GrantAccessModal__footer pt-3'>
          <div className='GrantAccessModal__text'>
            {!!files.length && <>Selected {selectedIds.length} files</>}
          </div>
          <div className='d-flex flex-column flex-sm-row'>
            <Button
              handleClick={onClose}
              className='button__sm-width button__full-width mr-sm-3 button__inversed mt-sm-0'
            >
              Cancel
            </Button>
            <Button
              handleClick={handleSubmit}
              className='button__sm-width button__full-width mt-sm-0'
              disabled={debouncedIsSubmitting || selectedIds.length === 0}
            >
              Grant Access
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GrantAccessModal;
