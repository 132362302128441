import { BookingItemTypeEnum } from '../../core/types';

export const titles = {
  [BookingItemTypeEnum.AppointmentBlock]: [
    'Add new Appointment',
    'Confirm Appointment'
  ],
  [BookingItemTypeEnum.Appointment]: [
    'Sign up for Appointment',
    'Confirm Appointment'
  ],
  [BookingItemTypeEnum.Meeting]: ['Sign up for Meeting', 'Confirm Meeting']
};
