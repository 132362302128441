import React, { useState } from 'react';
import { FileResponseViewModelWithExt } from '../../core/types';
import {
  getLinkType,
  getValidLink,
  parseLink
} from '../CoachLibraryAll/CoachLibraryAll.helpers';
import {
  copyToClipboard,
  downloadFileFromMemory,
  getExt,
  openLinkNewTab,
  stopVideo
} from '../../core/helpers';
import DownloadIcon from '../../icons/download.svg';
import BanIcon from '../../icons/ban.svg';
import CopyIcon from '../../icons/copy.svg';
import { useStores } from '../../hooks';
import { getCoachFilesLibraryFileID, unShareFiles } from '../../core/api';
import Loader from '../../components/Loader/Loader';
import ViewIcon from '../../icons/view-icon.svg';
import FileItem from '../../components/FileItem/FileItem';
import { FileResponseViewModel } from '../../core/backend/api';
import './CoachClientLibraryAccessFile.styles.scss';
import Tooltip from '../../components/Tooltip/Tooltip';
import Button from '../../components/Button/Button';
import { NotificationTypesEnum } from '../../core/enums';

interface Props {
  file: FileResponseViewModel;
  clientId: string;
  onRemoveAccess: (id: number) => void;
  setActiveFile: React.Dispatch<
    React.SetStateAction<FileResponseViewModelWithExt>
  >;
  setIsVideoModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  expandedId: string | number;
  setExpandedId: (id: string | number) => void;
}

const CoachClientLibraryAccessFile: React.FC<Props> = ({
  file,
  clientId,
  onRemoveAccess,
  setActiveFile,
  setIsVideoModalOpened,
  expandedId,
  setExpandedId
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  const { id, awsUrl, fileLink, name } = file;
  const ext = getExt(awsUrl);
  const linkType = fileLink ? getLinkType(fileLink) : undefined;
  const isVideoLink = linkType && linkType === 'video';
  const videoLink = isVideoLink && parseLink(fileLink);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const handleDownload = async () => {
    stopVideo(`#FileItem-video-${expandedId}`);
    setIsDownloading(true);
    try {
      const guid = await getCoachFilesLibraryFileID(id);
      downloadFileFromMemory(name, ext, guid);
    } catch (e) {
      notificationStore.addNotification({
        text: '',
        textColored: `File not found.`,
        duration: 3000,
        type: NotificationTypesEnum.Error
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleView = () => {
    stopVideo(`#FileItem-video-${expandedId}`);
    setIsVideoModalOpened(true);
    setActiveFile({
      ...file,
      ext: 'video',
      fileLink: videoLink
    });
  };

  const handleIconClick = () => {
    if (!fileLink) return handleDownload();
    if (!isVideoLink) return openLinkNewTab(getValidLink(fileLink));
    handleView();
  };

  const getMenuList = () => {
    const menuList = [
      {
        name: 'Remove Access',
        callback: () => {
          unShareFiles([file.id], [clientId]).then(() => {
            onRemoveAccess(file.id);
            notificationStore.addNotification({
              text: 'Removed',
              textColored: 'access for file',
              duration: 3000
            });
          });
        },
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <BanIcon />
          </div>
        )
      },
      {
        name: 'Download',
        callback: handleDownload,
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <DownloadIcon />
          </div>
        )
      }
    ];

    if (fileLink && !isVideoLink) {
      menuList[1] = {
        name: 'Copy link',
        callback: () => {
          if (!fileLink) return;
          copyToClipboard(videoLink || fileLink);
          notificationStore.addNotification({
            text: 'Link',
            textColored: 'was copied to your clipboard'
          });
        },
        icon: <CopyIcon />
      };
    }

    if (isVideoLink) {
      menuList[1] = {
        name: 'View',
        callback: handleView,
        icon: <ViewIcon />
      };
    }

    return menuList;
  };

  return (
    <div className='CoachClientLibraryAccessFile'>
      {isDownloading && <Loader />}
      <FileItem
        className='FileItem--CoachClientLibraryAccessFile pt-sm-4'
        file={{ ...file, ext }}
        linkType={linkType}
        isDownloading={isDownloading}
        onIconClick={handleIconClick}
        isExpanded={expandedId === file.id}
        onToggle={setExpandedId}
      >
        <div className='CoachClientLibraryAccessFile__btns'>
          {getMenuList().map((menuItem) => (
            <Tooltip
              key={menuItem.name}
              trigger={
                <Button
                  className={
                    menuItem.name === 'Remove Access'
                      ? 'flex-shrink-0 mx-2 aling-items-start'
                      : 'flex-shrink-0 aling-items-start'
                  }
                  variations={['naked']}
                  handleClick={menuItem.callback}
                >
                  {menuItem.icon}
                </Button>
              }
              content={
                <>
                  {menuItem.name === 'Remove Access' ? 'Remove' : menuItem.name}
                </>
              }
              minWidth={70}
            />
          ))}
        </div>
      </FileItem>
    </div>
  );
};

export default CoachClientLibraryAccessFile;
