import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import Dropdown, { Alignment } from '../Dropdown/Dropdown';

interface iProps {
  color: string;
  className?: string;
  setColor: (arg0: string) => void;
  align?: Alignment;
}

const ColorPickerWrapper: React.FC<iProps> = ({
  color,
  setColor,
  align = 'right',
  className = ''
}) => {
  const [textColor, setTextColor] = useState<string>(color);
  const pattern = new RegExp('^#([a-fA-F0-9]){0,6}$');

  useEffect(() => setTextColor(color), [color]);

  const handleChange = (color: any) => {
    setTextColor(color.hex);
  };

  const handlePickerColor = (color: any) => {
    setColor(color.hex);
  };

  const handleInputTextColor = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (pattern.test(value)) {
      setTextColor(value);
      if (value.length === 4 || value.length === 7) {
        setColor(value);
      }
    }
  };

  return (
    <div className={`CustomizeSidebar__color-input-wrapper ${className}`}>
      <input
        type='text'
        className='CustomizeSidebar__color-text-input'
        value={textColor}
        onChange={handleInputTextColor}
        tabIndex={1}
      />
      <Dropdown
        className={`Dropdown--color-picker`}
        trigger={
          <button
            className='CustomizeSidebar__color-input-button'
            style={{ backgroundColor: color }}
            type='button'
          />
        }
        align={align}
        withPortal={false}
      >
        {() => (
          <ChromePicker
            color={textColor}
            onChange={handleChange}
            onChangeComplete={handlePickerColor}
            disableAlpha
          />
        )}
      </Dropdown>
    </div>
  );
};

export default ColorPickerWrapper;
