import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SuperAdminRoutesEnum } from './enums';
import SuperAdminUsers from '../pages/SuperAdminUsers/SuperAdminUsers';
import SuperAdminUserAccount from '../pages/SuperAdminUserAccount/SuperAdminUserAccount';
import SuperAdminWrapper from '../containers/SuperAdminWrapper/SuperAdminWrapper';
import { iRoute } from './routes';

const routes: iRoute[] = [
  {
    path: SuperAdminRoutesEnum.Users,
    component: SuperAdminUsers,
    exact: true
  },
  {
    path: SuperAdminRoutesEnum.User,
    component: SuperAdminUserAccount
  }
];

const AdminRoutes = () => {
  return (
    <Switch>
      <SuperAdminWrapper>
        <Route exact path={SuperAdminRoutesEnum.Admin}>
          <Redirect to={SuperAdminRoutesEnum.Users} />
        </Route>
        {routes.map((route) => {
          return <Route key={route.path} {...route} />;
        })}
      </SuperAdminWrapper>
    </Switch>
  );
};

export default AdminRoutes;
