import React, { useContext, useEffect, useRef, useState } from 'react';
import './TilesCollapsibleGroup.styles.scss';
import { iBookingItem } from '../../core/types';
import Button from '../Button/Button';
import { AppScrollbarsContext } from '../../App';
import { getOffsetTop } from '../../core/helpers';

export interface iItemRendererProps {
  item: iBookingItem;
  isVisible: boolean;
}

interface iProps {
  title: string;
  titleClassName?: string;
  headerClassName?: string;
  events: iBookingItem[];
  maxShownItems: number;
  className?: string;
  ItemRenderer: React.FC<iItemRendererProps>;
}

const TilesCollapsibleGroup: React.FC<iProps> = ({
  title,
  titleClassName = '',
  headerClassName = '',
  events,
  maxShownItems,
  className = '',
  ItemRenderer,
  ...rest
}) => {
  const [showAllEvents, setShowAllEvents] = useState<boolean>(false);
  const eventRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const scroll = useContext(AppScrollbarsContext);

  useEffect(() => {
    if (showAllEvents) {
      setTimeout(() => {
        scroll.scrollTop(
          getOffsetTop(titleRef.current as HTMLDivElement, scroll.container) - 8
        );
      }, 50);
    }
  }, [showAllEvents]);

  const setMaxHeight = (
    isExpanded: boolean,
    accordionRef: React.MutableRefObject<HTMLDivElement>
  ) => {
    return {
      maxHeight: isExpanded ? accordionRef!.current!.scrollHeight : 0
    };
  };

  return (
    <div className={`TilesCollapsibleGroup ${className}`} {...rest}>
      <div
        ref={titleRef}
        className={`TilesCollapsibleGroup__header ${headerClassName}`}
      >
        <div className={`TilesCollapsibleGroup__title ${titleClassName}`}>
          {title}
        </div>
        {events.length > maxShownItems && (
          <div className='d-flex align-items-center flex-shrink-0'>
            <Button
              variations={['naked']}
              handleClick={() => setShowAllEvents(!showAllEvents)}
              className='pl-3'
              type='button'
            >
              {showAllEvents ? 'Collapse' : 'View all'}
            </Button>
            <span className='TilesCollapsibleGroup__view-all-count'>
              {events.length}
            </span>
          </div>
        )}
      </div>
      <div className='row mx-md-n3 mx-n2'>
        {events.slice(0, maxShownItems).map((item) => (
          <ItemRenderer item={item} isVisible key={item.id} />
        ))}
      </div>
      <div
        ref={eventRef}
        style={setMaxHeight(showAllEvents, eventRef)}
        className='row mx-md-n3 mx-n2 TilesCollapsibleGroup__list'
      >
        {events.slice(maxShownItems).map((item) => (
          <ItemRenderer item={item} isVisible={showAllEvents} key={item.id} />
        ))}
      </div>
    </div>
  );
};

export default TilesCollapsibleGroup;
