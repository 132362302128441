import {
  getInputErrors,
  isNotEmpty,
  isMaxlengthValid,
  isDataValid,
  isInputValid
} from '../../core/formValidation';
import { Checker } from '../../core/types';

const MAX_TITLE_LENGTH = 100;

export const isTitleShortEnough = isMaxlengthValid(MAX_TITLE_LENGTH);

const isDescriptionNotEmpty = (str: string) => {
  return stripHTML(str).trim() !== '';
};

const formConfig = [
  { field: 'title', checkIsValid: [isNotEmpty, isTitleShortEnough] },
  { field: 'description', checkIsValid: [isDescriptionNotEmpty] }
];

export const getFormErrors = <T>(data: T) => {
  return {
    title: getInputErrors(formConfig, getErrorMessage)('title', data)[0],
    description: getInputErrors(formConfig)('description', data)[0]
  };
};

const getErrorMessage = (checker: Checker) => {
  let message: string | undefined = undefined;

  switch (checker) {
    case isTitleShortEnough: {
      message = `should have at most ${MAX_TITLE_LENGTH} characters`;
      break;
    }
    case isDescriptionNotEmpty: {
      message = 'should not be empty';
      break;
    }
    default: {
      message = undefined;
    }
  }

  return message;
};

export function isNoteDataValid(data: any) {
  return isDataValid(formConfig)(data);
}

export function isNoteInputValid(key: string, data: any) {
  return isInputValid(formConfig)(key, data);
}

function stripHTML(str: string) {
  return str.replace(/(<([^>]+)>)/gi, '');
}
