import React, { useReducer, useState, useEffect } from 'react';
import './ContactModal.styles.scss';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import {
  getDefaultData,
  isContactDataValid,
  isContactInputValid,
  reducer,
  CountriesList,
  StatesList,
  getCountryId,
  getStatesByCountry,
  getStateId,
  USA_COUNTRY_ID,
  getContactInputErrors as getInputErrors
} from './ContactModal.helpers';
import { iOption } from '../../../core/types';
import { CoachContactsViewModel } from '../../../core/backend/api';
import Select from '../../Select/Select';
import CustomizedPhoneInput from '../../../components/CustomizedPhoneInput';
import { getServerModelValidation } from '../../../core/helpers';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';

interface iProps {
  isOpened: boolean;
  close: () => void;
  data?: CoachContactsViewModel;
  emailsInUse: string[];
  onSave: (
    data: CoachContactsViewModel
  ) => Promise<CoachContactsViewModel | void>;
  isSubmitting: boolean;
}

const HAS_DEFAULT_ID = false;

const ContactModal: React.FC<iProps> = ({
  isOpened,
  close,
  data: initialData,
  emailsInUse,
  onSave,
  isSubmitting
}) => {
  const isEdit = !!(initialData && initialData.clientId);

  const [localData, setLocalData] = useReducer(
    reducer,
    initialData || getDefaultData()
  );
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isEmailUsed, setIsEmailUsed] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [statesByCountry, setStatesByCountry] = useState<iOption[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});

  const countries: iOption[] = [
    {
      id: '',
      value: '',
      name: 'None'
    },
    ...CountriesList
  ];

  useEffect(() => {
    setIsValidationShown(false);
    if (isOpened) {
      const data = initialData
        ? {
            ...initialData,
            phoneNumber: initialData.phoneNumber || getDefaultData().phoneNumber
          }
        : getDefaultData();
      setLocalData(data);
      const countryId = getCountryId(data.country, isEdit, HAS_DEFAULT_ID);
      setSelectedCountry(countryId);
      const states = [
        { id: '', name: 'None', value: '' },
        ...getStatesByCountry(countryId)
      ];
      setStatesByCountry(states);
      if (states.length) {
        setSelectedState(getStateId(data.state, states, isEdit));
      }
    }
  }, [isOpened, initialData, isEdit]);

  const isUSASelected = () =>
    selectedCountry === USA_COUNTRY_ID && statesByCountry.length;

  const handleChangeField = (field: string) => (value: any) => {
    setLocalData({
      ...localData,
      [field]: field === 'email' ? value.trim() : value
    });

    setServerValidation((errors) => ({
      ...errors,
      [field]: ''
    }));

    if (localData.email !== value && isEmailUsed) {
      setIsEmailUsed(false);
    }
  };

  const handleChangeCountry = (countryId: string) => {
    setSelectedCountry(countryId);
    setStatesByCountry([
      { id: '', name: 'None', value: '' },
      ...StatesList.filter((state) => state.value === countryId)
    ]);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const country = countries!.find(
      (country) => country.id === selectedCountry
    );
    const state =
      selectedState &&
      statesByCountry!.find((state) => state.id === selectedState);

    const isEmailFree = emailsInUse.every(
      (email) => email.toLowerCase() !== localData.email.toLowerCase()
    );

    const payload = {
      ...localData,
      country: country ? country.name : '',
      state: state && isUSASelected() ? state.name : ''
    };

    if (isContactDataValid(payload) && isEmailFree) {
      try {
        await onSave(payload);
        setServerValidation({});
      } catch (error) {
        setServerValidation(getServerModelValidation(error));
        setIsValidationShown(true);
      }
    } else {
      setIsValidationShown(true);
      setIsEmailUsed(!isEmailFree);
    }
  };

  const isUSA = isUSASelected();
  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 648 }}>
      <div className='ContactModal'>
        <ModalHeader>
          <h2>{isEdit ? 'Edit' : 'Add'} contact</h2>
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <div className='row mx-n2'>
            <div className='col-sm-6 px-2'>
              <FormInput
                autoFocus
                name='firstName'
                label='First name'
                value={localData.firstName}
                isInvalid={
                  isValidationShown &&
                  !isContactInputValid('firstName', localData)
                }
                errorMessage='This field is required'
                handleChange={handleChangeField('firstName')}
                placeholder='First name'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                name='lastName'
                label='Last name'
                value={localData.lastName}
                isInvalid={
                  isValidationShown &&
                  !isContactInputValid('lastName', localData)
                }
                errorMessage='This field is required'
                handleChange={handleChangeField('lastName')}
                placeholder='Last name'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                name='email'
                label='Email'
                value={localData.email}
                isInvalid={
                  isValidationShown &&
                  (!isContactInputValid('email', localData) ||
                    isEmailUsed ||
                    !!serverValidation['email'])
                }
                errorMessage={
                  isEmailUsed
                    ? 'The client with this email already exists'
                    : serverValidation['email'] || 'Please input correct email'
                }
                handleChange={handleChangeField('email')}
                placeholder='Email'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                name='number'
                label='Phone Number'
                value={localData.phoneNumber}
                isInvalid={
                  isValidationShown &&
                  !isContactInputValid('phoneNumber', localData)
                }
                errorMessage={`Phone number ${
                  getInputErrors('phoneNumber', localData)[0]
                }`}
              >
                <CustomizedPhoneInput
                  value={localData.phoneNumber}
                  onChange={(value) => handleChangeField('phoneNumber')(value)}
                  inputClass={
                    isValidationShown &&
                    !isContactInputValid('phoneNumber', localData)
                      ? 'PhoneInputInput--error'
                      : ''
                  }
                />
              </FormInput>
            </div>
            <div className='col-sm-6 px-2'>
              <Select
                options={countries}
                value={selectedCountry}
                title={'Country'}
                onChange={handleChangeCountry}
                className='Select--contact-country'
                classNameSuffix='AddContact'
                placeholder='Select Country'
                withPortal
              />
            </div>
            {isUSA && (
              <div className='col-sm-6 px-2'>
                <Select
                  options={statesByCountry}
                  value={selectedState}
                  title={'State'}
                  onChange={setSelectedState}
                  className='Select--contact-state'
                  classNameSuffix='ContactModal'
                  placeholder='Select State'
                  withPortal
                />
              </div>
            )}
            <div className='col-sm-6 px-2'>
              <FormInput
                name='city'
                label='City'
                value={localData.city}
                isInvalid={
                  isValidationShown && !isContactInputValid('city', localData)
                }
                errorMessage='This field is required'
                handleChange={handleChangeField('city')}
                placeholder='Add City'
              />
            </div>
            {(!isEdit || (isEdit && initialData?.isGuest)) && (
              <div className='col-sm-6 px-2 mt-3'>
                <FormCheckbox
                  id='isGuest'
                  checked={!localData.isGuest}
                  handleChange={() => {
                    handleChangeField('isGuest')(!localData.isGuest);
                  }}
                  label='Create a Client account'
                />
              </div>
            )}
          </div>
          {((!isEdit && !localData.isGuest) ||
            (isEdit && initialData?.isGuest && !localData.isGuest)) && (
            <p className='ContactModal__description mt-2'>
              When you click "Save", we'll create an account for this client and
              send credentials to the Client's portal to the indicated email.
            </p>
          )}
          <div className='row mt-4 mx-n2'>
            <div className='col-sm-6 px-2'>
              <Button
                className='flex-grow-1'
                type='button'
                variations={['gray', 'full-width', 'sm-width']}
                handleClick={close}
              >
                Cancel
              </Button>
            </div>
            <div className='col-sm-6 px-2'>
              <Button
                className='flex-grow-1'
                variations={['full-width', 'sm-width']}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ContactModal;
