import React, { useState, useEffect, useCallback, useMemo } from 'react';
// import Select from '../../components/Select/Select';
// import DateRangePickerDropdown from '../../components/DateRangePickerDropdown/DateRangePickerDropdown';
// import { normalizePrice } from '../../core/helpers';
// import moment from 'moment';
// import Chart from '../../components/Chart/Chart';
import {
  getCoachPayments,
  refundClientPayment,
  deleteCoachPayment,
  cancelAppointment,
  cancelClientScheduleEvent
} from '../../core/api';
import { Payment } from '../../core/types';
import { useStores } from '../../hooks';
import { getColumns, getConfirmModalData } from './CoachPayments.helpers';
import './CoachPayments.styles.scss';
import {
  CoachRoutesEnum,
  ConfirmModalEnum,
  NotificationTypesEnum,
  PaymentEntityTypeEnum,
  PaymentStatusEnum,
  RefundStatusEnum
} from '../../core/enums';
import { useLocation } from 'react-router-dom';
import PaymentsList from '../../components/PaymentsList/PaymentsList';
import { TableAction } from '../../components/Table/Table';
import ConfirmModal, {
  iProps as ConfirmModalData
} from '../../components/Modals/ConfirmModal/ConfirmModal';
import PageTitle from '../../components/PageTitle/PageTitle';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import Button from '../../components/Button/Button';
import { storeDataBeforeStripeConnect } from '../../core/integrations';
import { iTab } from '../../components/TabsMenu/TabsMenu';

const tabs: iTab[] = [
  {
    name: 'All',
    link: CoachRoutesEnum.Transactions,
    queryParams: {
      status: ''
    }
  },
  {
    name: 'Paid',
    link: CoachRoutesEnum.Transactions,
    queryParams: {
      status: `${PaymentStatusEnum.paid}`
    }
  },
  {
    name: 'Refunded',
    link: CoachRoutesEnum.Transactions,
    queryParams: {
      status: `${PaymentStatusEnum.refunded}`
    }
  },
  {
    name: 'Uncollectible',
    link: CoachRoutesEnum.Transactions,
    queryParams: {
      status: `${PaymentStatusEnum.uncollectible}`
    }
  }
];

const CoachPayments = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const status = params.get('status')
    ? (+params.get('status') as PaymentStatusEnum)
    : null;
  const sortBy = params.get('sortBy') as keyof Payment;
  const sortOrder = params.get('sortOrder');

  // const { currentTimestamp, lastMonthInterval, last7DaysInterval } = useRef(
  //   getRangeFilters()
  // ).current;

  // const [rangeFilter, setRangeFilter] = useState(RangeFilterEnum.Today);
  // const [range, setRange] = useState([currentTimestamp, currentTimestamp]);
  // const [chartData, setChartData] = useState<CoachPayment[]>([]);
  // const [comparingData, setComparingData] = useState<CoachComparablePayments>();
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(true);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [activePayment, setActivePayment] = useState<Payment>(undefined);
  const [isConfirmBtnsDisabled, setIsConfirmBtnsDisabled] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalEnum>();
  const { isStripeConnected } = userStore;

  const columns = useMemo(() => getColumns(status === null), [status]);

  // const handlerangeFilterChange = (value: RangeFilterEnum | undefined) => {
  //   setRangeFilter(value);
  //   fetchChartDataOnFilterChange(value);
  // };

  // const fetchChartDataOnFilterChange = (value: RangeFilterEnum | undefined) => {
  //   let newRange: number[];
  //   switch (value) {
  //     case RangeFilterEnum.Today:
  //       newRange = [currentTimestamp, currentTimestamp];
  //       break;
  //     case RangeFilterEnum.Last7Days:
  //       newRange = last7DaysInterval;
  //       break;
  //     case RangeFilterEnum.LastMonth:
  //       newRange = lastMonthInterval;
  //       break;
  //   }
  //   if (!newRange) {
  //     return;
  //   }
  //   fetchChartData(newRange);
  //   setRange(newRange);
  // };

  // const handleRangeSelect = (newRange: number[]) => {
  //   setRange(newRange);
  //   switch (JSON.stringify(newRange)) {
  //     case JSON.stringify([currentTimestamp, currentTimestamp]):
  //       setRangeFilter(RangeFilterEnum.Today);
  //       break;
  //     case JSON.stringify(last7DaysInterval):
  //       setRangeFilter(RangeFilterEnum.Last7Days);
  //       break;
  //     case JSON.stringify(lastMonthInterval):
  //       setRangeFilter(RangeFilterEnum.LastMonth);
  //       break;
  //     default:
  //       setRangeFilter(RangeFilterEnum.None);
  //   }
  // };

  const showRefundFailedErrorNotification = () => {
    notificationStore.addNotification({
      text: 'Automatic refund failed.',
      textColored:
        'Try to give a refund\n manually using your\n Stripe account.',
      type: NotificationTypesEnum.Error
    });
  };

  const showAlreadyRefundedErrorNotification = () => {
    notificationStore.addNotification({
      text: 'Already refunded',
      textColored: 'This payment already refunded.',
      type: NotificationTypesEnum.Error
    });
  };

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, null);
  };

  const handleConfirmRefund = () => {
    if (isConfirmBtnsDisabled) {
      return;
    }
    setIsConfirmBtnsDisabled(true);
    setIsLoadingPayments(true);
    refundClientPayment(activePayment.stripePaymentIntentId)
      .then((res) => {
        if (
          res === RefundStatusEnum.SuccessfullyCreated ||
          res === RefundStatusEnum.CreatedInStripe
        ) {
          notificationStore.addNotification({
            text: 'Refund',
            textColored: 'is done',
            duration: 3000
          });
        }
        if (
          res === RefundStatusEnum.StripeException ||
          res === RefundStatusEnum.NotSucceeded
        ) {
          showRefundFailedErrorNotification();
        }
        if (res === RefundStatusEnum.AlreadyCreated) {
          showAlreadyRefundedErrorNotification();
        }
        fetchPayments();
      })
      .catch(() => {
        showRefundFailedErrorNotification();
      })
      .finally(() => {
        handleConfirmationClose();
      });
  };

  const fetchPayments = useCallback(() => {
    if (isStripeConnected && sortBy && sortOrder) {
      setIsLoadingPayments(true);
      getCoachPayments(
        {
          sortBy: sortBy,
          sortOrder: +sortOrder
        },
        status
      )
        .then((response) => {
          setPayments(response);
        })
        .finally(() => {
          setIsLoadingPayments(false);
        });
    }
  }, [isStripeConnected, sortBy, sortOrder, status]);

  // const fetchChartData = useCallback(
  //   (newRange = range) => {
  //     setIsLoading(true);
  //     getChartCoachPayments(
  //       newRange[0],
  //       moment.utc(newRange[1]).endOf('day').valueOf()
  //     )
  //       .then((res) => {
  //         setChartData(res);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   },
  //   [range]
  // );

  // useEffect(() => {
  //   setIsLoading(true);
  //   setIsLoadingPayments(true);
  //   Promise.all([
  //     getChartCoachPayments(
  //       currentTimestamp,
  //       moment.utc(currentTimestamp).endOf('day').valueOf()
  //     ),
  //     getComparableCoachPayments(currentTimestamp),
  //     getCoachPayments()
  //   ])
  //     .then(([chartDataRes, comparableRes, paymentsRes]) => {
  //       setChartData(chartDataRes);
  //       setComparingData(comparableRes);
  //       setPayments(paymentsRes);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //       setIsLoadingPayments(false);
  //     });
  // }, [currentTimestamp]);

  useEffect(fetchPayments, [fetchPayments]);

  const actionCallback = (type: ConfirmModalEnum, item: Payment) => {
    setConfirmModalType(type);
    setIsConfirmBtnsDisabled(false);
    setIsConfirmModalOpened(true);
    setActivePayment(item);
  };

  const actions: Array<TableAction<Payment>> = [
    {
      title: 'Give Refund',
      callback: (item: Payment) =>
        actionCallback(ConfirmModalEnum.refund, item),
      isAvailable: (item) => {
        return [PaymentStatusEnum.paid].includes(item.status);
      }
    }
    // {
    //   title: 'Delete',
    //   callback: (item: Payment) =>
    //     actionCallback(ConfirmModalEnum.delete, item),
    //   isAvailable: (item) => {
    //     return [PaymentStatusEnum.refunded].includes(item.status);
    //   }
    // }
  ];

  const handleConfirmationClose = () => {
    setIsConfirmModalOpened(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmBtnsDisabled(true);
    setIsLoadingPayments(true);
    deleteCoachPayment(activePayment.id)
      .then(() => {
        fetchPayments();
        notificationStore.addNotification({
          text: `Payment`,
          textColored: 'deleted successfully'
        });
      })
      .catch(() => {
        notificationStore.addNotification({
          text: `Payment`,
          textColored: 'delete failed',
          type: NotificationTypesEnum.Error
        });
      })
      .finally(() => {
        handleConfirmationClose();
      });
  };

  const handleRefundAndCancel = (
    id: number,
    itemType: PaymentEntityTypeEnum
  ) => () => {
    let cancel;

    if (itemType === PaymentEntityTypeEnum.Appointment) {
      cancel = cancelAppointment;
    } else {
      cancel = cancelClientScheduleEvent.bind(
        null,
        activePayment?.client.clientId
      );
    }

    if (!cancel) return;
    setIsConfirmBtnsDisabled(true);
    cancel(id)
      .then(() => {
        handleConfirmationClose();
        fetchPayments();
      })
      .catch(() => {
        notificationStore.addNotification({
          text: 'Something went wrong.',
          textColored: 'Try one more time,\n please.',
          duration: 3000,
          type: NotificationTypesEnum.Error
        });
      })
      .finally(() => {
        setIsConfirmBtnsDisabled(false);
      });
  };

  const confimModalData: Partial<
    ConfirmModalData & { confirm: () => void; cancel: () => void }
  > = {
    ...getConfirmModalData(
      confirmModalType,
      activePayment?.description,
      activePayment?.entityType
    ),
    confirm:
      confirmModalType === ConfirmModalEnum.delete
        ? handleConfirmDelete
        : handleConfirmRefund,
    cancel:
      confirmModalType === ConfirmModalEnum.delete ||
      activePayment?.entityType === PaymentEntityTypeEnum.Invoice
        ? undefined
        : handleRefundAndCancel(
            activePayment?.entityId,
            activePayment?.entityType
          )
  };

  return (
    <div className='CoachPayments'>
      <PageTitle title='Transactions' />

      <div className='CoachPayments__title mb-sm-4 mb-3 d-none d-sm-block'>
        Transactions
      </div>
      {/* <div className='CoachPayments__container mb-4'>
        <div className='CoachPayments__header CoachPayments__header--revenue d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between'>
          <div className='CoachPayments__revenueTitle pb-sm-0 px-4'>
            Revenue
          </div>
          <div className='d-flex align-items-center p-3 px-sm-4 py-sm-0'>
            <Select
              options={rangeFilterOptions}
              value={rangeFilter}
              onChange={handlerangeFilterChange}
              className='Select--CoachPayments'
              placeholder='-'
            />
            <DateRangePickerDropdown
              range={range}
              onSelect={handleRangeSelect}
              onClose={fetchChartData}
            />
          </div>
        </div>
        <div className='CoachPayments__chart pb-4'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className='CoachPayments__summary pt-4 pb-3 pl-3 pl-sm-4'>
                $
                {normalizePrice(
                  chartData.reduce((sum, payment) => {
                    return sum + payment.amount;
                  }, 0)
                )}
              </div>
              <Chart
                lineDataKey='amount'
                xAxisDataKey='timestamp'
                data={chartData}
                YaxisFormatter={amountFormatter}
                XaxisFormatter={dateFormatter(range, currentTimestamp)}
                yAxisDomain={chartData.length === 0 ? [500, 1500] : undefined}
              />
            </>
          )}
        </div>
        <CoachPaymentsComparing data={comparingData} />
      </div> */}
      {/* <div className='CoachPayments__header'>
          <div className='px-4'>Previous transactions</div>
        </div> */}
      {userStore.isStripeConnected ? (
        <>
          <PaymentsList
            className='CoachPayments__container'
            tabs={tabs}
            isLoading={isLoadingPayments}
            columns={columns}
            actions={actions}
            payments={payments}
            rowHeight={73}
            headerHeight={40}
          />
          <ConfirmModal
            isOpened={isConfirmModalOpened}
            close={handleConfirmationClose}
            title={confimModalData.title}
            text={confimModalData.text}
            confirmBtnText={confimModalData.confirmBtnText}
            cancelBtnText={confimModalData.cancelBtnText}
            confirmCallback={confimModalData.confirm}
            cancelCallback={confimModalData.cancel || handleConfirmationClose}
            disableConfirmBtn={isConfirmBtnsDisabled}
            disableCancelBtn={isConfirmBtnsDisabled}
            confirmBtnClass='button__confirm-delete'
          />
        </>
      ) : (
        <div
          className='position-relative flex-grow-1 mx-n2'
          style={{ minHeight: '290px' }}
        >
          <EmptyState
            size='md'
            text="You don't have payments yet. Connect a Stripe account to receive payments and create invoices."
            absoluteCenter
            icon={<EmptyStateIcon />}
            style={{ width: '320px' }}
          >
            <Button
              type='button'
              className='button__md-width button__mt ml-0'
              link={CoachRoutesEnum.ConnectStripe}
              handleClick={handleConnectStripe}
            >
              Connect Stripe
            </Button>
          </EmptyState>
        </div>
      )}
    </div>
  );
};

export default CoachPayments;
