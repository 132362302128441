import React from 'react';
import BucketIcon from '../../icons/delete.svg';
import './RowWithDelete.styles.scss';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  onDelete: () => void;
  item: JSX.Element;
  className?: string;
  isDeletable?: boolean;
}

const RowWithDelete: React.FC<Props> = ({
  onDelete,
  item,
  className = '',
  isDeletable = true
}) => {
  return (
    <div className={`RowWithDelete ${className}`}>
      {item}
      {isDeletable && (
        <Tooltip
          minWidth={62}
          trigger={
            <div className='RowWithDelete__delete' onClick={() => onDelete()}>
              <BucketIcon />
            </div>
          }
          content={<>Remove</>}
          triggerType='hover'
          withPortal
        />
      )}
    </div>
  );
};

export default RowWithDelete;
