import moment from 'moment';
import { iFormConfigItem, UserStore } from '../../core/types';
import { SignupCoachAdditionalInfoViewModel } from '../../core/backend/api';
import {
  getInputErrors,
  isDataValid,
  isInputValid,
  isNotEmpty,
  isSiteNameReserved,
  isSiteNameShortEnough
} from '../../core/formValidation';
import { getTimezone } from '../../core/helpers';

export function getDefaultData(
  userStore: UserStore
): SignupCoachAdditionalInfoViewModel {
  return {
    firstName: userStore.firstName || '',
    lastName: userStore.lastName || '',
    country: userStore.country || '',
    state: userStore.state || '',
    city: userStore.city || '',
    industry: '',
    phoneNumber: userStore.phoneNumber || '',
    siteName: userStore.siteName || '',
    timeZone: getTimezone(userStore.timeZone || moment.tz.guess())
  };
}

export const reducer = (
  oldData: SignupCoachAdditionalInfoViewModel,
  newData: SignupCoachAdditionalInfoViewModel
): SignupCoachAdditionalInfoViewModel => newData;

const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'city', checkIsValid: [isNotEmpty] },
  { field: 'country', checkIsValid: [isNotEmpty] },
  { field: 'phoneNumber', checkIsValid: [isNotEmpty] },
  // { field: 'industry', checkIsValid: [isNotEmpty] },
  {
    field: 'siteName',
    checkIsValid: [isNotEmpty, isSiteNameShortEnough, isSiteNameReserved]
  }
];

export function isCompleteRegistrationInputValid(
  inputField: string,
  data: any
) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getCompleteRegistrationInputErrors(
  inputField: string,
  data: any
) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isCompleteRegistrationDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
