import React, { ReactNode } from 'react';
import BackIcon from '../../icons/back.svg';
import PreviewIcon from '../../icons/preview.svg';
import Button from '../Button/Button';
import getGlobal from '../../core/globals';
import PreviousIcon from '../../icons/previous.svg';
import CloseIcon from '../../icons/cross-big.svg';
import './CustomizeSidebar.styles.scss';
import { useOverflow } from '../../hooks';

interface Props {
  onSubmit: any;
  isOpen: boolean;
  onToggle: any;
  coachAccountData: any;
  onTabClose: any;
  children: ReactNode;
  handleView?: () => void;
  selectedMenuItem: number;
  tabName: string;
}

const CustomizeSidebar: React.FC<Props> = ({
  onSubmit,
  isOpen,
  onToggle,
  coachAccountData,
  onTabClose,
  children,
  handleView,
  selectedMenuItem,
  tabName
}) => {
  useOverflow(isOpen);

  return (
    <form
      className={`CustomizeSidebar CZ__disabled ${
        !isOpen ? 'CustomizeSidebar--collapsed' : ''
      }`}
      onSubmit={onSubmit}
    >
      <button
        className='d-lg-none CustomizeSidebar__toggle'
        type='button'
        onClick={onToggle}
      >
        <PreviousIcon />
      </button>
      <div className='CustomizeSidebar__header d-flex flex-column'>
        <div className='d-flex'>
          <div className='d-flex flex-column flex-grow-1'>
            <a
              className='CustomizeSidebar__header-url d-flex align-items-center mb-2 align-self-start'
              href={`${getGlobal('domain')}/${coachAccountData.siteName}/`}
              target='_blank'
              onClick={handleView}
              rel='noopener noreferrer'
            >
              <span className='mr-2'>View your page</span>
              <PreviewIcon />
            </a>
            <div className='CustomizeSidebar__header-title'>
              Customize your page
            </div>
          </div>
          <Button
            variations={['naked']}
            className='mr-n3 d-md-none'
            handleClick={onToggle}
            type='button'
          >
            <CloseIcon />
          </Button>
        </div>
        {selectedMenuItem > 0 ? (
          <div className='CustomizeSidebar__back-button' onClick={onTabClose}>
            <BackIcon />
            <span className='CustomizeSidebar__back-button-text'>
              {tabName}
            </span>
          </div>
        ) : null}
      </div>
      {children}
      <div className='CustomizeSidebar__footer'>
        <Button className='mt-0' variations={['full-width', 'xs-width']}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default CustomizeSidebar;
