import React, { useState, ReactElement, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment';
import './WeekCalendar.styles.scss';
import { getCurrentDayTimeStamp } from '../../core/helpers';
import Tooltip from '../Tooltip/Tooltip';
import NextIcon from '../../icons/next.svg';
import InfoIcon from '../../icons/info.svg';

export interface iCellRendererProps {
  day: Moment;
  isDisabled: boolean;
  className: string;
}

export enum WeekCalendarMode {
  Month = 'Month',
  TwoWeeks = 'TwoWeeks'
}

interface iProps {
  mode?: WeekCalendarMode;
  initDate?: number;
  ItemRenderer: React.FC<iCellRendererProps>;
  functionalBlock?: ReactElement<any>;
  className?: string;
  handleChangeMove?: (startTimeStamp: number, endTimeStamp: number) => void;
  allowToGoBackward?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  tooltip?: boolean;
}

const WeekCalendar: React.FC<iProps> = ({
  mode = WeekCalendarMode.TwoWeeks,
  initDate,
  ItemRenderer,
  functionalBlock,
  className = '',
  handleChangeMove,
  allowToGoBackward = false,
  isInvalid = false,
  errorMessage = '',
  tooltip = false
}) => {
  const [datesRange, setDatesRange] = useState<Moment[]>([]);
  const [weeks, setWeeks] = useState<Moment[][]>([]);

  const currentDayTimeStamp = getCurrentDayTimeStamp();

  const isBackwardInPast = currentDayTimeStamp > datesRange[0]?.valueOf();

  useEffect(() => {
    const isInitial = !datesRange.length;
    const day = initDate
      ? moment.utc(initDate)
      : isInitial
      ? moment.utc(currentDayTimeStamp)
      : datesRange[0];
    let startDate = day.clone();

    if (mode === WeekCalendarMode.TwoWeeks) {
      startDate.startOf('week');
      if (
        startDate.clone().endOf('week').valueOf() < currentDayTimeStamp &&
        startDate.clone().endOf('week').add(1, 'week').valueOf() >
          currentDayTimeStamp
      ) {
        startDate.add(1, 'week');
      }
    } else {
      if (isInitial) {
        startDate.startOf('month').startOf('week');
      } else {
        startDate = moment
          .utc((datesRange[0].valueOf() + datesRange[1].valueOf()) / 2)
          .startOf('month')
          .startOf('week');
      }
    }
    let endDate = day.clone();

    if (mode === WeekCalendarMode.TwoWeeks) {
      endDate = startDate.clone().endOf('week').add(1, 'week');
    } else {
      if (isInitial) {
        endDate.endOf('month').endOf('week');
      } else {
        endDate = moment
          .utc((datesRange[0].valueOf() + datesRange[1].valueOf()) / 2)
          .endOf('month')
          .endOf('week');
      }
    }
    setDatesRange([startDate, endDate]);
  }, [mode, initDate]);

  useEffect(() => {
    if (datesRange.length && handleChangeMove) {
      handleChangeMove(datesRange[0].valueOf(), datesRange[1].valueOf());
    }
  }, [datesRange]);

  const moveBack = () => {
    const [startDate, endDate] = datesRange;
    if (mode === WeekCalendarMode.TwoWeeks) {
      setDatesRange([
        startDate.clone().subtract(2, 'week'),
        endDate.clone().subtract(2, 'week')
      ]);
    } else {
      const someDayInCurrentMonth = startDate.clone().add(7, 'day');
      const newStartDate = someDayInCurrentMonth
        .clone()
        .subtract(1, 'month')
        .startOf('month')
        .startOf('week');
      const newEndDate = someDayInCurrentMonth
        .clone()
        .subtract(1, 'month')
        .endOf('month')
        .endOf('week');
      setDatesRange([newStartDate, newEndDate]);
    }
  };

  const moveForward = () => {
    const [startDate, endDate] = datesRange;
    if (mode === WeekCalendarMode.TwoWeeks) {
      setDatesRange([
        startDate.clone().add(2, 'week'),
        endDate.clone().add(2, 'week')
      ]);
    } else {
      const someDayInCurrentMonth = startDate.clone().add(7, 'day');
      const newStartDate = someDayInCurrentMonth
        .clone()
        .add(1, 'month')
        .startOf('month')
        .startOf('week');
      const newEndDate = someDayInCurrentMonth
        .clone()
        .add(1, 'month')
        .endOf('month')
        .endOf('week');
      setDatesRange([newStartDate, newEndDate]);
    }
  };

  const getCalendarDays = useCallback(() => {
    const weeks: Moment[][] = [];
    if (!datesRange.length) return [];
    const weeksCount = datesRange[1]
      .clone()
      .add(1, 'day')
      .diff(datesRange[0], 'week');

    for (let weeksCounter = 0; weeksCounter < weeksCount; weeksCounter++) {
      const daysOfWeek = [];
      for (let weekDaysCounter = 0; weekDaysCounter < 7; weekDaysCounter++) {
        daysOfWeek.push(
          datesRange[0]
            .clone()
            .add(weeksCounter, 'week')
            .add(weekDaysCounter, 'day')
        );
      }
      weeks.push(daysOfWeek);
    }

    return weeks;
  }, [datesRange]);

  useEffect(() => {
    setWeeks(getCalendarDays());
  }, [datesRange, getCalendarDays]);

  const getDatesLabel = () => {
    const [firstDay, lastDay] = datesRange;
    const isSameYear = firstDay.format('YYYY') === lastDay.format('YYYY');

    const datesLabel = `${firstDay.format('MMM')} ${firstDay.format('D')}${
      isSameYear ? '' : ', ' + firstDay.format('YYYY')
    } - ${lastDay.format('MMM')} ${lastDay.format('D')}`;
    const monthLabel = `${firstDay.clone().add(7, 'day').format('MMMM')}`;

    return `${
      mode === WeekCalendarMode.TwoWeeks ? datesLabel : monthLabel
    }, ${lastDay.format('YYYY')}`;
  };

  const renderDaysNames = () => {
    return (
      weeks[0]?.map((day, index) => (
        <div key={index} className='WeekCalendar__cell WeekCalendar__day-name'>
          {day.format('ddd')}
        </div>
      )) || []
    );
  };

  const renderWeeks = () => {
    return weeks.map((days, index) => (
      <div
        key={index}
        className={`WeekCalendar__cells ${
          isInvalid ? 'WeekCalendar__cells--invalid' : ''
        }`}
      >
        {days.map((day) => {
          const diffMonth =
            mode === WeekCalendarMode.Month &&
            day.get('month') !==
              datesRange[0].clone().add(7, 'day').get('month');
          return (
            <div
              key={day.valueOf()}
              className='WeekCalendar__cell WeekCalendar__day'
            >
              <ItemRenderer
                day={day}
                isDisabled={day.valueOf() < currentDayTimeStamp}
                className={`${
                  diffMonth ? 'WeekCalendar__cell--other-month' : ''
                }`}
              />
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    !!datesRange.length && (
      <div
        className={`WeekCalendar ${className} ${
          isInvalid ? 'WeekCalendar__invalid' : ''
        }`}
        data-testid='availability-calendar'
      >
        <div className='WeekCalendar__header'>
          <div className='WeekCalendar__dates-block d-flex align-items-center justify-content-between flex-grow-1 flex-wrap flex-shrink-1'>
            <div className='d-inline pl-2 ml-1'>
              <span className='mr-2 mb-2'>
                <span data-testid='week-calendar-dates-range'>
                  {getDatesLabel()}
                </span>
                {tooltip && (
                  <Tooltip
                    className='d-md-none d-inline vertical-align-middle ml-2'
                    trigger={<InfoIcon />}
                    content={
                      <span>
                        Set your available hours when people can schedule
                        meetings with you. Click on Calendar and add some
                        intervals where you are available to meet with your
                        clients.
                      </span>
                    }
                    triggerType='hover'
                    minWidth={170}
                  />
                )}
              </span>
            </div>
            <div className='d-inline-block'>
              <div
                className={`WeekCalendar__button WeekCalendar__button--prev ${
                  isBackwardInPast && !allowToGoBackward
                    ? 'WeekCalendar__button--disabled'
                    : ''
                }`}
                onClick={moveBack}
                data-testid='week-calendar-previous-week-btn'
              >
                <NextIcon />
              </div>
              <div
                className='WeekCalendar__button ml-2 ml-sm-3 WeekCalendar__button--next'
                onClick={moveForward}
                data-testid='week-calendar-next-week-btn'
              >
                <NextIcon />
              </div>
            </div>
          </div>
          {functionalBlock && (
            <div className='WeekCalendar__legend'>{functionalBlock}</div>
          )}
        </div>
        <div className='WeekCalendar__body'>
          <div className='WeekCalendar__cells--no-border d-none d-md-flex'>
            {renderDaysNames()}
          </div>

          {renderWeeks()}
        </div>
        {isInvalid && errorMessage !== '' && (
          <div
            className='WeekCalendar__error'
            data-testid='week-calendar-error'
          >
            {errorMessage}
          </div>
        )}
      </div>
    )
  );
};

export default WeekCalendar;
