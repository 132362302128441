import React from 'react';
import CloseIcon from '../../icons/cross.svg';
import './TagWithDelete.styles.scss';

interface Props {
  id: string;
  title: string;
  onDelete: (id: string) => () => void;
}

const TagWithDelete: React.FC<Props> = ({ id, title, onDelete }) => {
  return (
    <div className='TagWithDelete'>
      {title}
      <div className='TagWithDelete__delete' onClick={onDelete(id)}>
        <CloseIcon />
      </div>
    </div>
  );
};

export default TagWithDelete;
