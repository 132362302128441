import {
  isDataValid,
  isInputValid,
  isNotEmpty,
  isEmail,
  getInputErrors
} from '../../../core/formValidation';
import { iFormConfigItem } from '../../../core/types';
import {
  CoachCreateViewModel,
  PricingPlanViewModel
} from '../../../core/backend/api';
import { iOption } from '../../../core/types';

export const normalizePricingPlansToIOption = (
  list: PricingPlanViewModel[]
): iOption[] => {
  return list.map((item: PricingPlanViewModel) => {
    return {
      id: item.id,
      value: item.id,
      name: item.name
    };
  });
};

export const getDefaultData = (): CoachCreateViewModel => ({
  email: '',
  pricingPlanId: null,
  isAdmin: false
});

const FormConfig: iFormConfigItem[] = [
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] }
];

export function isFieldInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isFormDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}

export function getFieldInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}
