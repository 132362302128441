import React, { useEffect, useState } from 'react';
import TilePage from '../../components/TilePage/TilePage';
import NewPassword, {
  iNewPasswordData
} from '../../components/NewPassword/NewPassword';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  sendNewPasswordRequest,
  isCoachResetPasswordTokenValid
} from '../../core/api';
import { useHistory, useLocation } from 'react-router-dom';
import { CoachRoutesEnum } from '../../core/enums';
import ResetLinkExpired from '../../components/ResetLinkExpired/ResetLinkExpired';
import { useStores } from '../../hooks';

let routeToGo = CoachRoutesEnum.SignIn;

const ResetPasswordPage: React.FC<any> = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    isResetLinkExpiredPopupOpen,
    setIsResetLinkExpiredPopupOpen
  ] = useState<boolean>(false);
  const [data, setData] = useState<iNewPasswordData>({ email: '', token: '' });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search !== '') {
      const params = new URLSearchParams(location.search);
      const token = params.get('token') || '';
      const email = params.get('email') || '';
      if (token === '' || email === '') {
        history.push(CoachRoutesEnum.SignIn);
      } else {
        isCoachResetPasswordTokenValid({ email, token }).then((isValid) => {
          setIsResetLinkExpiredPopupOpen(!isValid);
        });
        setData({
          token,
          email
        });
      }
    }
  }, []);

  const request = async (newPass: string, repeatPass: string) => {
    setIsLoading(true);
    try {
      await sendNewPasswordRequest({
        ...data,
        password: newPass,
        confirmPassword: repeatPass
      });
    } catch (event) {
      setIsLoading(false);
      if (event.errors.InvalidToken.length) {
        setIsResetLinkExpiredPopupOpen(true);
      }
      return;
    }
    await userStore.signIn({
      email: data.email.trim(),
      password: newPass
    });
    setIsLoading(false);
    notificationStore.addNotification({
      text: 'Your password has been changed'
    });
  };

  const setRouteToGo = (route: any) => {
    routeToGo = route;
  };

  return (
    <TilePage
      className='NewPasswordPage'
      title='Log In'
      tileStyle={{ maxWidth: 440, width: '100%' }}
    >
      <GlobalLoader isLoading={isLoading} />
      <ResetLinkExpired
        isOpened={isResetLinkExpiredPopupOpen}
        close={() => {
          setIsResetLinkExpiredPopupOpen(false);
          history.push(routeToGo);
        }}
        showSignIn={() => setRouteToGo(CoachRoutesEnum.SignIn)}
        showForgot={() => setRouteToGo(CoachRoutesEnum.ForgotPassword)}
      />

      <NewPassword sendRequest={request} buttonTitle='Save & Sign in' />
    </TilePage>
  );
};

export default ResetPasswordPage;
