import React from 'react';
import './PageHeader.styles.scss';
import { useLocation, useHistory } from 'react-router-dom';
import BackIcon from '../../icons/arrow-left.svg';
import Button from '../Button/Button';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { CoachRoutesEnum, SuperAdminRoutesEnum } from '../../core/enums';
// import LogoImage from '../../images/logo.svg';
import { useStores } from '../../hooks';
import CoachUserStore from '../../store/CoachUserStore';
import { Link } from 'react-router-dom';
import { getPublicFile } from '../../core/api';

interface iProps {
  isEditor?: boolean;
  sideMenuOpened: boolean;
  onSideMenuToggle: (arg0: boolean) => void;
}

const PageHeader: React.FC<iProps> = ({
  isEditor,
  sideMenuOpened,
  onSideMenuToggle
}) => {
  const {
    rootStore: { userStore }
  } = useStores();

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const fromDashboard = params.get('d') === 'true';

  const handleMenuToggle = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent
  ) => {
    e.nativeEvent.stopImmediatePropagation();
    onSideMenuToggle(!sideMenuOpened);
  };

  const handleMenuToggleTouchStart = (e: React.TouchEvent) => {
    // for preventing useOnClickOutside that opens sideMenu again
    e.nativeEvent.stopImmediatePropagation();
  };

  const renderCentralButton = () => {
    return (
      <Button
        className='PageHeader__back-button button__back-to-admin'
        component='Link'
        link={fromDashboard ? CoachRoutesEnum.Dashboard : CoachRoutesEnum.Pages}
      >
        <BackIcon />
        <span className='PageHeader__back-button-text'>
          back to {fromDashboard ? 'Dashboard' : 'Pages'}
        </span>
      </Button>
    );
  };

  const goToAccountPage = () => {
    if (location.pathname !== CoachRoutesEnum.Account) {
      history.push(CoachRoutesEnum.Account);
    }
  };
  const goToSuperAdminPage = () => {
    if (location.pathname !== SuperAdminRoutesEnum.Admin) {
      history.push(SuperAdminRoutesEnum.Admin);
    }
  };

  const getList = () => {
    const list = [
      {
        name: 'My Account',
        callback: goToAccountPage
      },
      {
        name: 'Logout',
        callback: userStore.signOut,
        dataTestid: 'logout-btn'
      }
    ];

    if ((userStore as CoachUserStore).isAdmin) {
      list.unshift({
        name: 'Super Admin',
        callback: goToSuperAdminPage
      });
    }
    return list;
  };

  return (
    <div className='PageHeader CZ__disabled py-2 px-md-4 px-sm-3 px-2'>
      <div className='PageHeader__column'>
        {!isEditor && (
          <>
            <div
              className={`PageHeader__menu d-lg-none mr-sm-4 mr-3 flex-shrink-0 ${
                sideMenuOpened ? 'PageHeader__menu--cross' : ''
              }`}
              id='hamburger'
              onMouseDown={handleMenuToggle}
              onTouchStart={handleMenuToggleTouchStart}
            >
              <div className='PageHeader__menu-line PageHeader__menu-line--one' />
              <div className='PageHeader__menu-line PageHeader__menu-line--two' />
              <div className='PageHeader__menu-line PageHeader__menu-line--three' />
            </div>
            <Link
              to={CoachRoutesEnum.Dashboard}
              className='d-flex align-items-center'
            >
              {/* <LogoImage /> */}
            </Link>
          </>
        )}
      </div>
      {isEditor ? (
        renderCentralButton()
      ) : (
        <div className='PageHeader__column flex-grow-1 ml-3 mr-sm-4 mr-3'>
          <div
            id='headerContent'
            className='PageHeader__header-сontent d-flex justify-content-end flex-grow-1 align-items-center'
          ></div>
        </div>
      )}
      <div className='PageHeader__column'>
        <DropdownMenu list={getList()} withPortal={false}>
          <div className='PageHeader__user-circle' data-testid='coach-menu'>
            {userStore.userData.imageId ? (
              <img
                src={getPublicFile(userStore.userData.imageId)}
                alt='Avatar'
              />
            ) : (
              `${userStore.userData.firstName.charAt(
                0
              )}${userStore.userData.lastName.charAt(0)}`
            )}
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default PageHeader;
