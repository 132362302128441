export function readURLasBASE64(file: File) {
  return new Promise<string>((resolve) => {
    if (file) {
      const reader: FileReader = new FileReader();
      reader.onload = function (e: Event) {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          resolve('');
        }
      };

      reader.readAsDataURL(file);
    } else {
      resolve('');
    }
  });
}

export function formatFileSize(size: number) {
  if (size < 1024) {
    return `${size} b`;
  }
  if (size < 1024 * 1024) {
    return `${size / 1024} kb`;
  }
  if (size < 1024 * 1024 * 1024) {
    return `${size / (1024 * 1024)} Mb`;
  }
  if (size < 1024 * 1024 * 1024 * 1024) {
    return `${size / (1024 * 1024 * 1024)} Gb`;
  }
}

export const roundFormatedFileSize = (size: number) => {
  const sizeStringsArr = formatFileSize(size).split(' ');
  const unit = sizeStringsArr[1].toUpperCase() as 'MB' | 'B' | 'GB' | 'KB';
  const value =
    unit === 'B' ? sizeStringsArr[0] : Number(sizeStringsArr[0]).toFixed(1);

  return value + unit;
};

export const messageFormats = (fileTypes: string[]) =>
  fileTypes.reduce(
    (message, format, index) =>
      `${message}${
        index !== 0 ? (index + 1 < fileTypes.length ? ', ' : ' and ') : ''
      }.${format}`,
    ''
  );
