import React, { ReactNode, useEffect, useRef } from 'react';
import DropDownWithoutPortal from '../DropDownWithoutPortal/DropDownWithoutPortal';
import Scrollbars from 'react-custom-scrollbars';
import { useOnClickOutside } from '../../hooks';
import './SharingInputDropdown.styles.scss';

interface Props {
  isOpened: boolean;
  close: () => void;
  selectedByKey: number;
  children: ReactNode;
}

const SharingInputDropdown: React.FC<Props> = ({
  isOpened,
  close,
  selectedByKey,
  children
}) => {
  const dropDownList = useRef<HTMLDivElement | null>(null);
  const scrollbaref = useRef<(Scrollbars & { view: HTMLDivElement }) | null>(
    null
  );
  useOnClickOutside(dropDownList, close, isOpened);

  useEffect(() => {
    if (selectedByKey !== -1 && dropDownList.current) {
      const item = dropDownList.current.children[selectedByKey];
      if (!item || !(item instanceof HTMLElement)) return;
      const itemRect = item.getBoundingClientRect();
      const scrollableContainerRect = scrollbaref.current.view.getBoundingClientRect();
      if (
        itemRect.bottom >= scrollableContainerRect.bottom ||
        itemRect.top <= scrollableContainerRect.top
      ) {
        scrollbaref.current.scrollTop(item.offsetTop);
      }
    }
  }, [selectedByKey]);

  return (
    <DropDownWithoutPortal shown={isOpened}>
      <Scrollbars
        className='SharingInputDropdown'
        style={{
          minHeight: '180px'
        }}
        ref={scrollbaref}
      >
        <div ref={dropDownList}>{children}</div>
      </Scrollbars>
    </DropDownWithoutPortal>
  );
};

export default SharingInputDropdown;
