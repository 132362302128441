export enum GroupMembersTypeEnum {
  NotSet,
  Single,
  Group
}

export enum PaymentMethodTypesEnum {
  NotSet,
  Free,
  Stripe
}

export enum BillingIntervalEnum {
  None = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4
}

export enum ScheduleItemType {
  Appointment = 0,
  Event = 1,
  OneToOne = 2
}

export enum CoachRoutesEnum {
  Admin = '/admin',
  Home = '/__testHomePage' /* temp route for testing */,
  TermsAndConditions = '/terms',
  SignIn = '/',
  SignUp = '/__signUp__',
  CompleteRegistration = '/completeRegistration',
  ForgotPassword = '/forgotPassword',
  ResetPassword = '/resetPassword',
  NotificationSettings = '/account/notificationSettings',
  Schedule = '/schedule',
  Calendar = '/schedule/calendar',
  New = '/schedule/new',
  Requests = '/schedule/requests',
  AppointmentBlocks = '/schedule/appointmentBlocks',
  AppointmentBlockNew = '/schedule/appointmentBlocks/new',
  AppointmentBlockDetails = '/schedule/appointmentBlocks/:blockId',
  Meetings = '/schedule/meetings',
  MeetingNew = '/schedule/meetings/new',
  MeetingDetails = '/schedule/meetings/:meetingId',
  ScheduleMeetingNew = '/schedule/add',
  ScheduleMeetingDetails = '/schedule/:meetingId',
  Account = '/account',
  CoachClients = '/contacts',
  CoachClientNotes = '/contacts/:clientId/notes',
  CoachClientTransactions = '/contacts/:clientId/transactions',
  CoachClientSchedule = '/contacts/:clientId/schedule',
  CoachClientLibraryAccess = '/contacts/:clientId/libraryAccess',
  Library = '/library',
  LibraryHistory = '/library/history',
  Pages = '/pages',
  CoachPageEditor = '/pages/coachPageEditor',
  ClientPageEditor = '/pages/clientPageEditor',
  Dashboard = '/dashboard',
  Invoices = '/invoices',
  InvoiceNew = '/invoices/new',
  InvoiceEdit = '/invoices/:id/edit',
  Invoice = '/invoices/:id',
  Transactions = '/transactions',
  ConnectStripe = '/account/stripeIntegration',
  Error = '/error/:number'
}

export enum SuperAdminRoutesEnum {
  Admin = '/admin',
  Users = '/admin/users',
  User = '/admin/users/:userId'
}

export function isCoachRoute(siteName: string) {
  let isRoute = false;

  if (siteName === '') return true;

  for (let coachRoutesEnumKey in CoachRoutesEnum) {
    // @ts-ignore
    if (CoachRoutesEnum[coachRoutesEnumKey].indexOf(siteName) === 1) {
      isRoute = true;
    }
  }

  return isRoute;
}

export enum ClientRoutesEnum {
  Calendar = '/:siteName/schedule',
  Invites = '/:siteName/schedule/invites',
  Archive = '/:siteName/schedule/archive',
  CoachPage = '/:siteName',
  CoachPageSignedIn = '/:siteName/coachPage',
  Library = '/:siteName/library',
  Downloads = '/:siteName/library/downloads',
  Payments = '/:siteName/payments',
  Billing = '/:siteName/billing',
  Notes = '/:siteName/library/notes',
  Account = '/:siteName/account',
  Dashboard = '/:siteName/dashboard',
  Booking = '/:siteName/:link',
  CompleteRegistration = '/:siteName/completeRegistration',
  CoachPageNewPass = '/:siteName/resetPassword',
  Invoices = '/:siteName/invoices',
  Invoice = '/:siteName/invoices/:number',
  InvoicePay = '/:siteName/invoices/:number/pay'
}

export enum BookingItemColorsEnum {
  Appointment = '#BB6BD9',
  Meeting = '#009688'
}

export enum RefundStatusEnum {
  AlreadyCreated,
  CreatedInStripe,
  StripeException,
  NotSucceeded,
  SuccessfullyCreated
}

export enum NotificationTypesEnum {
  Success,
  Error
}

export enum EventStatus {
  Active = 0,
  Deleted = 1,
  Canceled = 2
}

export enum LocationTypeEnum {
  Custom = 1,
  Zoom = 2
}

export enum InvoiceStatusEnum {
  draft,
  open,
  paid,
  late,
  canceled,
  uncollectible,
  refunded,
  processing
}

export enum InvoiceTypeEnum {
  OneTime,
  Recurring
}

export enum InvoiceBillingInterval {
  None = 0,
  Daily = 1,
  Weekly,
  Monthly,
  Yearly,
  Custom
}

export enum TaxType {
  Amount = 0,
  Rate = 1
}

export enum PaymentStatusEnum {
  pending = 0,
  paid = 1,
  refunded = 2,
  uncollectible = 3
}

export enum RangeFilterEnum {
  None,
  Today,
  Last7Days,
  LastMonth
}

export enum SortOrder {
  Asc = 1,
  Desc = 2
}

export enum ConfirmModalEnum {
  refund,
  delete,
  cancel,
  cancelRecuringInvoice
}

export enum TransactionsStatusEnum {
  Paid,
  Refunded,
  Uncollectible,
  Canceled,
  Open,
  Late
}
export enum TransactionsEntityEnum {
  OneTimeInvoice = 'OneTimeInvoice',
  RecurringInvoice = 'RecurringInvoice',
  Meeting = 'Meeting',
  Appointment = 'Appointment'
}
export enum ClientEventStatusEnum {
  Active = 'Active',
  Canceled = 'Canceled'
}

export enum StripeRefundStatusEnum {
  Succeeded = 'succeeded'
}

export enum PaymentEntityTypeEnum {
  Invoice,
  Meeting,
  OneToOne,
  Appointment
}
