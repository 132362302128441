import React from 'react';
import CompletedIcon from '../../icons/completed-setup.svg';
import { Link } from 'react-router-dom';
import './QuickStartSetupItem.styles.scss';
import { iQuickStartSetupItem } from './QuickStartSetup';
import cn from 'classnames';
interface Props {
  item: iQuickStartSetupItem;
  maxWidth?: number;
}

const QuickStartSetupItem: React.FC<Props> = ({ item, maxWidth }) => {
  const content = (
    <>
      <div className='d-flex align-items-center d-sm-block'>
        <div
          className={cn(
            'QuickStartSetupItem__icon QuickStartSetupItem__icon--big mr-3 mb-0 mb-sm-2',
            {
              QuickStartSetupItem__icon_yellow: item.key === 'isScheduled',
              QuickStartSetupItem__icon_green:
                item.key === 'isStripeAccountConnected'
            }
          )}
        >
          {item.icon}
        </div>
        <div className='QuickStartSetupItem__title'>{item.name}</div>
        <div className='QuickStartSetupItem__text d-none d-sm-block'>
          {item.description}
        </div>
      </div>

      {item.isCompleted && (
        <div className='QuickStartSetupItem__statusContainer'>
          <div className='QuickStartSetupItem__status'>
            <div className='QuickStartSetupItem__icon'>
              <CompletedIcon />
            </div>
            <div className='QuickStartSetupItem__statusText d-none d-sm-block'>
              Done
            </div>
          </div>
        </div>
      )}
    </>
  );
  if (item.key === 'isStripeAccountConnected') {
    return (
      <a
        className='QuickStartSetupItem'
        href={item.url}
        style={{ maxWidth: maxWidth - 16 || 'unset' }}
      >
        {content}
      </a>
    );
  }

  return (
    <Link
      className='QuickStartSetupItem'
      to={item.url}
      style={{ maxWidth: maxWidth - 16 || 'unset' }}
    >
      {content}
    </Link>
  );
};

export default QuickStartSetupItem;
