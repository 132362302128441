import React, { useState, ReactNode, useEffect } from 'react';
import './ShareFileModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { observer } from 'mobx-react';
import SharingInput from '../../SharingInput/SharingInput';
import { CoachContactsViewModel } from '../../../core/backend/api';
import { useStores } from '../../../hooks';
import {
  getSharedFileClients,
  postShareFile,
  unShareFiles
} from '../../../core/api';
import { FileResponseViewModelWithExt } from '../../../core/types';
import { NotificationTypesEnum } from '../../../core/enums';
import Loader from '../../Loader/Loader';
import SharedFilesContacts from '../../SharedFilesContacts/SharedFilesContacts';
import { searchBy, sortArrayBy } from '../../../core/helpers';
import { getLinkType } from '../../../pages/CoachLibraryAll/CoachLibraryAll.helpers';

interface iProps {
  isOpened: boolean;
  close: () => void;
  FileIcon: ReactNode;
  file: FileResponseViewModelWithExt;
  contacts: CoachContactsViewModel[];
  onShare?: (id: number) => void;
}

const ShareFileModal: React.FC<iProps> = ({
  isOpened,
  close,
  FileIcon,
  file,
  contacts,
  onShare
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const [sharedContactsIds, setSharedContactsIds] = useState<string[]>([]);
  const [selectedContactsIds, setSelectedContactsIds] = useState<string[]>([]);
  const [unsharedContactsIds, setUnsharedContactsIds] = useState<string[]>([]);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const type = file?.fileLink ? getLinkType(file?.fileLink) : 'file';

  const validate = () => {
    if (inputValue !== '') {
      const curContact = contacts.find(
        (contact) =>
          contact.email.toLowerCase() === inputValue.trim().toLowerCase()
      );
      if (!curContact) return false;
      return (
        selectedContactsIds.includes(curContact.clientId) ||
        sharedContactsIds.includes(curContact.clientId)
      );
    }
    return inputValue === '';
  };

  const handleClickShare = () => {
    if (!validate()) {
      notificationStore.addNotification({
        text: `You don't have this client in contacts.`,
        textColored: 'Please, add a new client to share access',
        duration: 3000,
        type: NotificationTypesEnum.Error
      });
      return;
    }

    setIsPending(true);
    Promise.all([
      unShareFiles([file.id], unsharedContactsIds),
      postShareFile([file.id], selectedContactsIds)
    ])
      .then(() => {
        onShare && onShare(file.id);
        notificationStore.addNotification({
          text: 'File',
          textColored: 'was shared',
          duration: 3000
        });
      })
      .finally(() => {
        setIsPending(false);
        close();
      });
  };

  useEffect(() => {
    if (file && isOpened) {
      setIsPending(true);
      getSharedFileClients(file.id)
        .then((res) => {
          setSharedContactsIds(res);
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [file, isOpened]);

  useEffect(() => {
    if (isOpened) {
      setSharedContactsIds([]);
      setSelectedContactsIds([]);
      setUnsharedContactsIds([]);
    }
  }, [isOpened]);

  const updateSharedContacts = (clientId: string) => {
    if (sharedContactsIds.includes(clientId)) {
      return removeSharedContactId(clientId);
    }
    setSelectedContactsIds((prevIds) => {
      if (prevIds.includes(clientId)) return removeIdFromArr(prevIds, clientId);
      return [...prevIds, clientId];
    });
    setUnsharedContactsIds((prevIds) => removeIdFromArr(prevIds, clientId));
  };

  const removeSharedContactId = (clientId: string) => {
    setUnsharedContactsIds((prevIds) => [...prevIds, clientId]);
    setSharedContactsIds((prevIds) => removeIdFromArr(prevIds, clientId));
  };

  const handleChangeInputValue = (value: string) => {
    setInputValue(value);
  };

  const handleSelect = (optionId: string) => {
    updateSharedContacts(optionId);
    handleChangeInputValue('');
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    handleChangeInputValue(value);
    const filtered: CoachContactsViewModel[] = contacts.filter((option) => {
      return (
        searchBy(option['firstName'], value) ||
        searchBy(option['email'], value) ||
        searchBy(option['lastName'], value)
      );
    });

    return filtered;
  };

  const handleUnselect = (clientId: string) => () =>
    updateSharedContacts(clientId);

  if (!file) return null;

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 506 }}>
      <div className='ShareFileModal'>
        {isPending && <Loader />}
        <div className='ShareFileModal__title'>Sharing {type}</div>
        <div className='ShareFileModal__file-info'>
          <div className='ShareFileModal__file-image'>{FileIcon}</div>
          <div className='ShareFileModal__file-info-content'>
            <div className='ShareFileModal__file-info-text'>{file.name}</div>
            <div className='ShareFileModal__file-info-members'>
              total {sharedContactsIds.length + selectedContactsIds.length} of{' '}
              {contacts.length} members
            </div>
          </div>
        </div>

        <SharingInput
          options={contacts}
          sharedIds={sharedContactsIds}
          selectedIds={selectedContactsIds}
          title='To:'
          placeholder='Email or name'
          className=''
          inputValue={inputValue}
          onSelect={handleSelect}
          onChangeInput={onChangeInput}
          onUnselect={handleUnselect}
        />
      </div>
      <SharedFilesContacts
        contacts={contacts
          .filter(({ clientId }) => sharedContactsIds.includes(clientId))
          .sort(sortArrayBy('firstName'))}
        onDelete={removeSharedContactId}
      />
      <Button
        className='button__full-width'
        handleClick={handleClickShare}
        disabled={isPending}
      >
        Share
      </Button>
    </Modal>
  );
};

const removeIdFromArr = (idsArr: string[], currentId: string) => {
  return idsArr.filter((id: string) => id !== currentId);
};

export default observer(ShareFileModal);
