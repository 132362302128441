import React from 'react';
import {
  getCurrentDayTimeStamp,
  normalizePrice,
  searchBy
} from '../../core/helpers';
import moment from 'moment';
import {
  ConfirmModalEnum,
  PaymentEntityTypeEnum,
  PaymentStatusEnum,
  RangeFilterEnum
} from '../../core/enums';
import { iOption, Payment } from '../../core/types';
import { TableColumn, TableColumnType } from '../../components/Table/Table';
import { getPublicFile } from '../../core/api';
import AvatarIcon from '../../icons/avatar-small.svg';
import TextEllipse from '../../components/TextEllipse';
import Badge, { BadgeType } from '../../components/Badge/Badge';

export const rangeFilterOptions: iOption[] = [
  {
    id: RangeFilterEnum.None,
    value: RangeFilterEnum.None,
    name: '-'
  },
  {
    id: RangeFilterEnum.Today,
    name: 'Today',
    value: RangeFilterEnum.Today
  },
  {
    id: RangeFilterEnum.Last7Days,
    name: 'Last 7 days',
    value: RangeFilterEnum.Last7Days
  },
  {
    id: RangeFilterEnum.LastMonth,
    name: 'Last month',
    value: RangeFilterEnum.LastMonth
  }
];

export const getRangeFilters = () => {
  const currentTimestamp = getCurrentDayTimeStamp();

  const lastMonthInterval = [
    moment.utc(currentTimestamp).clone().subtract(1, 'month').valueOf(),
    moment.utc(currentTimestamp).clone().valueOf()
  ];

  const last7DaysInterval = [
    moment
      .utc(currentTimestamp)
      .clone()
      .startOf('day')
      .subtract(6, 'days')
      .valueOf(),
    currentTimestamp
  ];

  return {
    currentTimestamp,
    lastMonthInterval,
    last7DaysInterval
  };
};

export const getBadgeType = (status: PaymentStatusEnum): BadgeType => {
  switch (status) {
    case PaymentStatusEnum.paid:
      return 'green';
    case PaymentStatusEnum.refunded:
      return 'gray';
    default:
      return 'disabled';
  }
};

export const filterForSearch = (
  debouncedSearchString: string,
  payment: Payment
) => {
  let amount = String(payment.amount || '');
  if (!debouncedSearchString.includes('.')) {
    amount = amount.split('.')[0];
  }

  return (
    searchBy(
      payment.client?.firstName + ' ' + payment.client?.lastName,
      debouncedSearchString
    ) ||
    searchBy(payment.client?.email, debouncedSearchString) ||
    searchBy(payment.description, debouncedSearchString) ||
    amount === debouncedSearchString ||
    searchBy(
      moment.utc(payment.date).format('MMM D, YYYY'),
      debouncedSearchString
    )
  );
};

export const getColumns = (
  isStatusSortable: boolean
): Array<TableColumn<Payment>> => [
  {
    field: 'date',
    title: 'DATE',
    type: TableColumnType.String,
    sortable: true,
    formatter: (v: string) => {
      return moment.utc(v).format('MMM D, YYYY');
    },
    minWidth: 125
  },
  {
    field: 'description',
    title: 'DESCRIPTION',
    type: TableColumnType.String,
    minWidth: 170,
    maxWidth: '3fr'
  },
  {
    field: 'client',
    title: 'CLIENT',
    type: TableColumnType.JSX,
    formatter: ({ client }: Payment) => {
      const email = client?.email?.toLowerCase();

      return (
        <div className='d-flex align-items-center PaymentsList__cell'>
          <div className='PaymentsItem__avatar PaymentsItem__avatar--sm flex-shrink-0 mr-3'>
            {client?.imageId ? (
              <img src={getPublicFile(client.imageId)} alt='avatar' />
            ) : (
              <AvatarIcon />
            )}
          </div>
          <span className='PaymentsList__ellipsis'>
            <TextEllipse text={`${client.firstName} ${client.lastName}`}>
              <span className='PaymentsList__name'>
                {client.firstName} {client.lastName}
              </span>
            </TextEllipse>
            <TextEllipse text={email}>
              <span className='PaymentsList__email'>{email}</span>
            </TextEllipse>
          </span>
        </div>
      );
    },
    minWidth: 200,
    maxWidth: '3fr'
  },
  {
    field: 'amount',
    title: 'AMOUNT',
    type: TableColumnType.String,
    sortable: true,
    formatter: (v: number) => {
      return '$' + normalizePrice(v);
    },
    minWidth: 100
  },
  {
    field: 'status',
    title: 'STATUS',
    type: TableColumnType.String,
    sortable: isStatusSortable,
    formatter: (v: PaymentStatusEnum) => {
      const status = PaymentStatusEnum[v] as keyof typeof PaymentStatusEnum;
      return (
        <Badge
          type={getBadgeType(v)}
          title={(status as string).toUpperCase()}
          className='Badge--CoachPayments'
        />
      );
    },
    minWidth: 135
  }
];

export const amountFormatter = (value: number) => {
  return `$${value}`;
};

export const dateFormatter = (range: number[], currentTimestamp: number) => (
  value: number | string
) => {
  if (range.length === 1) {
    return moment.utc(value).format('HH:mm');
  }

  if (
    value >= moment.utc(currentTimestamp).startOf('day').valueOf() &&
    value <= moment.utc(currentTimestamp).endOf('day').valueOf()
  )
    return 'Today';

  if (!value) return moment.utc(range[0]).format('D MMM');
  if (value === 'auto') return moment.utc(range[1]).format('D MMM');

  return moment.utc(value).format('D MMM');
};

export const getConfirmModalData = (
  confirmModalType: ConfirmModalEnum,
  description: string,
  entityType: PaymentEntityTypeEnum
) => {
  if (confirmModalType === ConfirmModalEnum.delete) {
    return {
      title: `Delete record for ${description}`,
      text: 'Are you sure you want to remove this record from the list?',
      confirmBtnText: 'Yes',
      cancelBtnText: 'No'
    };
  }
  if (confirmModalType === ConfirmModalEnum.refund) {
    const title = `Give refund for ${description}`;
    const confirmBtnText = 'Give refund';
    const questionText =
      'Do you want to give a refund or give a refund and cancel';
    const cancelText = confirmBtnText + ' & Cancel';

    if (entityType === PaymentEntityTypeEnum.Appointment) {
      return {
        title,
        text: `${questionText} this appointment?`,
        confirmBtnText,
        cancelBtnText: `${cancelText} appointment`
      };
    }

    if (
      entityType === PaymentEntityTypeEnum.Meeting ||
      entityType === PaymentEntityTypeEnum.OneToOne
    ) {
      return {
        title,
        text:
          entityType === PaymentEntityTypeEnum.OneToOne
            ? `${questionText} this meeting?`
            : `${questionText} registration for the client?`,
        confirmBtnText,
        cancelBtnText:
          entityType === PaymentEntityTypeEnum.OneToOne
            ? `${cancelText} meeting`
            : `${cancelText} registration`
      };
    }

    return {
      title,
      text: 'Are you sure you want to give a refund for this payment?',
      confirmBtnText: 'Yes',
      cancelBtnText: 'No'
    };
  }
};
