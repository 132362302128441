import React from 'react';
import moment from 'moment';
import './CoachClientScheduleItem.styles.scss';
import { CoachClientScheduleItem as ItemModel } from '../../core/types';
import { EventStatus, ScheduleItemType } from '../../core/enums';
import ViewIcon from '../../icons/view-icon.svg';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import { PALETTE } from '../../pages/CoachCalendar/CoachCalendar.helpers';
import classnames from 'classnames';
import GroupIcon from '../../icons/group-gray.svg';
import PriceIcon from '../../icons/dollar-circle.svg';
import Button from '../../components/Button/Button';
import { useMedia } from '../../hooks';
import TextEllipse from '../TextEllipse';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  item: ItemModel;
  isCompleted?: boolean;
  className?: string;
  onView: (item: ItemModel) => void;
  onEdit?: (item: ItemModel) => void;
  onCancel?: (item: ItemModel) => void;
  onCancelRegistration?: (item: ItemModel) => void;
}

const CoachClientScheduleItem: React.FC<Props> = ({
  item,
  isCompleted,
  className = '',
  onEdit,
  onView,
  onCancel,
  onCancelRegistration
}) => {
  const isCanceled = item.status !== EventStatus.Active;
  const isGroupMeeting = item.itemType === ScheduleItemType.Event;

  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const color = isCanceled
    ? PALETTE.canceled.cellBgColor
    : item.itemType === ScheduleItemType.Appointment
    ? PALETTE.appointment.cellCircleColor
    : item.itemType === ScheduleItemType.OneToOne
    ? PALETTE.scheduled.cellCircleColor
    : PALETTE.meeting.cellCircleColor;

  const menuList = [
    {
      name: 'View',
      callback: () => {
        onView(item);
      },
      icon: (
        <Tooltip
          className='d-flex align-items-center justify-content-center'
          trigger={
            <div className='CoachClientScheduleItem__icon'>
              <ViewIcon />
            </div>
          }
          content={<>View</>}
          triggerType='hover'
        />
      )
    },
    ...(!isCompleted && !isCanceled
      ? [
          {
            name: 'Edit',
            callback: () => {
              onEdit(item);
            },
            icon: <PencilIcon />
          }
        ]
      : []),
    ...(!isCompleted && !isCanceled && isGroupMeeting
      ? [
          {
            name: 'Cancel Registration',
            callback: () => {
              onCancelRegistration(item);
            },
            icon: <BucketIcon />
          }
        ]
      : []),
    ...(!isCompleted && !isCanceled
      ? [
          {
            name: 'Cancel',
            callback: () => {
              onCancel(item);
            },
            icon: <BucketIcon />
          }
        ]
      : [])
  ];

  return (
    <div
      className={classnames('CoachClientScheduleItem', className, {
        'CoachClientScheduleItem--completed p-sm-3 pl-3 p-2 pr-md-2 pr-sm-2': isCompleted,
        'pl-md-4 p-sm-3 pl-3 p-2 pr-md-2 pr-sm-2': !isCompleted,
        'CoachClientScheduleItem--readonly': isCanceled
      })}
      style={
        !isCanceled ? { borderLeftWidth: '4px', borderLeftColor: color } : {}
      }
    >
      <div className='CoachClientScheduleItem__date mr-3 flex-shrink-0'>
        <div className='CoachClientScheduleItem__day'>
          {moment.utc(item.timestamp).format('D')}
        </div>
        <div className='CoachClientScheduleItem__month'>
          {moment.utc(item.timestamp).format('MMM')}
        </div>
      </div>
      <div className='d-flex flex-column flex-grow-1 CoachClientScheduleItem__content-wrapper'>
        <div className='CoachClientScheduleItem__content d-flex flex-grow-1 flex-wrap justify-content-between align-items-start'>
          <div className='mb-1'>
            {!isCompleted && (
              <p className='CoachClientScheduleItem__label mr-3'>
                {isCanceled
                  ? item.itemType === ScheduleItemType.Appointment
                    ? 'CANCELED APPOINTMENT'
                    : item.itemType === ScheduleItemType.OneToOne
                    ? 'CANCELED SCHEDULE'
                    : 'CANCELED MEETING'
                  : item.itemType === ScheduleItemType.Appointment
                  ? 'CLIENT SCHEDULED APPOINTMENT'
                  : item.itemType === ScheduleItemType.OneToOne
                  ? 'COACH SCHEDULED FOR CLIENT'
                  : 'REGISTERED FOR MEETING'}
              </p>
            )}
            <div className='CoachClientScheduleItem__time-details mr-3 d-flex align-items-center'>
              {!isCompleted ? (
                <>
                  <span
                    className='CoachClientScheduleItem__time-details__prefix'
                    style={{ backgroundColor: color }}
                  ></span>
                  <div>
                    <div>
                      {moment
                        .utc(item.timestamp)
                        .format(isMobile ? 'D MMM, h:mma' : 'dddd, h:mma')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {item.itemType === ScheduleItemType.Appointment
                    ? 'Appointment'
                    : item.itemType === ScheduleItemType.OneToOne
                    ? 'Schedule'
                    : 'Meeting'}{' '}
                  on{' '}
                  {moment
                    .utc(item.timestamp)
                    .format('MMMM D _ h:mma')
                    .replace('_', 'at')}
                </>
              )}
            </div>
          </div>

          <div className='d-flex align-items-center mb-1'>
            {item.membersCountLimit > 1 && (
              <div className='d-flex mr-3 CoachClientScheduleItem__group'>
                <GroupIcon />
                <div className='CoachScheduleCalendarItem__members-text'>
                  {item.activeMembersCount}/{item.membersCountLimit}{' '}
                  <span className='d-none d-sm-inline'>members</span>
                </div>
              </div>
            )}
            <div
              className={`CoachClientScheduleItem__price mr-3 ${
                !item.price ? 'CoachClientScheduleItem__price--free' : ''
              }`}
            >
              {!!item.price && <PriceIcon />}
              {item.price ? `$${item.price}` : 'Free'}
            </div>
          </div>
        </div>
        <TextEllipse
          className='CoachClientScheduleItem__title'
          text={item.name}
        >
          <span>{item.name}</span>
        </TextEllipse>
      </div>
      {!isCompleted && !isCanceled ? (
        <DropdownMenu
          withPortal={false}
          list={menuList}
          className='CoachClientScheduleItem__dropdown'
        />
      ) : (
        <>
          {menuList.map((menuItem) => (
            <Button
              key={menuItem.name}
              handleClick={menuItem.callback}
              variations={['naked']}
              className='ml-0 p-0 CoachClientScheduleItem__btn'
            >
              {menuItem.icon}
            </Button>
          ))}
        </>
      )}
    </div>
  );
};

export default CoachClientScheduleItem;
