import React, { useEffect, useState } from 'react';
import './ClientBookingWizard.styles.scss';
import Button from '../../components/Button/Button';
import StepsVisualizer from '../StepsVisualizer/StepsVisualizer';
import SummaryStep from '../SummaryStep/SummaryStep';
import InfoBlock, { iInfoBlockData } from '../InfoBlock/InfoBlock';
import { titles } from './ClientBookingWizard.helpers';
import { observer } from 'mobx-react';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import {
  getDefaultPaymentData,
  iPaymentData,
  isTheDataValid as isPaymentDataValid
} from '../PaymentDetails/PaymentDetails.helpers';
import { BookingItemTypeEnum, iBookingItem } from '../../core/types';
import {
  EventStatus,
  NotificationTypesEnum,
  ScheduleItemType
} from '../../core/enums';
import AvailableDateTimePicker from '../AvailableDateTimePicker/AvailableDateTimePicker';
import { ClientInfoViewModel } from '../../core/backend/api';
import moment from 'moment-timezone';
import {
  attachClientToAppointment,
  attachClientToAppointmentWithoutToken,
  attachClientToEvent,
  attachClientToEventWithoutToken,
  cancelUnPaidAppointment,
  cancelUnPaidAppointmentWithoutToken,
  cancelUnPaidEvent,
  cancelUnPaidEventWithoutToken,
  createClient,
  createClientAppointment,
  getClientAppointmentBlockAvailableSlots,
  getClientInfoByEmail,
  getPublicFile
} from '../../core/api';
import {
  getMilliseconds,
  getTimeInZone,
  getTimezone
} from '../../core/helpers';
import { DEFAULT_TIME_SLOT_INTERVAL } from '../../core/defaults';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import { getDefaultData as getDefaultAppointmentData } from '../Modals/AppointmentModal/AppointmentModal.helpers';
import { isTheDataValid as isUserDataValid } from '../PersonalDetails/PersonalDetails.helpers';
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { Link } from 'react-router-dom';
import { useMedia, useStores } from '../../hooks';
import cn from 'classnames';
import PreventUnsavedChangesModal from '../Modals/PreventUnsavedEditsModal/PreventUnsavedEditsModal';
import { getSiteName } from '../../core/globals';

interface iProps {
  email: string;
  onClose: () => void;
  onReload: () => void;
  data: iBookingItem;
}

function getDefaultClientInfo(): ClientInfoViewModel {
  return {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    timeZone: getTimezone(moment.tz.guess())
  };
}

interface iLocalData {
  timeStampMs: number;
  timeMs: number;
}

interface iLogInWhileBookingData {
  id: number;
  timeStampMs?: number;
  timeMs?: number;
}

function getDefaultLocalData(): iLocalData {
  return {
    timeStampMs: 0,
    timeMs: -1
  };
}

const ClientBookingWizard: React.FC<iProps> = ({
  email,
  onClose,
  onReload,
  data
}) => {
  const { rootStore } = useStores();
  const { userStore, notificationStore } = rootStore;

  const isFromInvite = !!email;

  const [localData, setLocalData] = useState<iLocalData>(getDefaultLocalData());
  const [userData, setUserData] = useState<ClientInfoViewModel>(
    getDefaultClientInfo()
  );
  const [isPaid, setIsPaid] = useState(data.hasPrice);
  const [paymentData, setPaymentData] = useState<iPaymentData>(
    getDefaultPaymentData()
  );
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [isInvitedUserFound, setIsInvitedUserFound] = useState<boolean>(false);
  const [isInviteLoading, setIsInviteLoading] = useState<boolean>(isFromInvite);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isAttachClientFailed, setIsAttachClientFailed] = useState<boolean>(
    false
  );
  const [attachClientError, setAttachClientError] = useState<string>('');
  const [isCreateAppointmentFailed, setIsCreateAppointmentFailed] = useState<
    boolean
  >(false);
  const [createAppointmentError, setCreateAppointmentError] = useState<string>(
    ''
  );
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [isEventBooked, setIsEventBooked] = useState<boolean>(false);
  const [isPaymentFormShown, setIsPaymentFormShown] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [userSecret, setUserSecret] = useState<string>('');

  const [userId, setUserId] = useState<string>('');
  const [showUncompletedBooking, setShowUncompletedBooking] = useState<boolean>(
    true
  );
  const [shouldProceedAfterLogin, setShouldProceedAfterLogin] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const isDesktop = useMedia(['(min-width: 992px)'], [true], false);

  const subTitles = ['', 'Choose date and time', 'Enter details', 'Summary'];

  const width_options = [0, 994, 994, 600];

  const shouldProceedAfterDatePicked =
    data.type === BookingItemTypeEnum.AppointmentBlock &&
    userStore.isSignedIn &&
    !isPaid;

  useEffect(() => {
    let isFormStepValid = true;

    if (!userStore.isSignedIn) {
      isFormStepValid = isFormStepValid && isUserDataValid(userData);
    }

    if (isPaid) {
      isFormStepValid = isFormStepValid && isPaymentDataValid(paymentData);
    }
    setIsFormValid(isFormStepValid);
  }, [userData, paymentData, isPaid, userStore.isSignedIn]);

  useEffect(() => {
    if (shouldProceedAfterLogin && userStore.isSignedIn && isPaid) {
      setIsPaymentFormShown(true);
      setShouldProceedAfterLogin(false);
    }
  }, [shouldProceedAfterLogin, userStore.isSignedIn]);

  useEffect(() => {
    if (isFromInvite && !userStore.isSignedIn) {
      setIsInviteLoading(true);
      getClientInfoByEmail(email).then(
        (response) => {
          setIsInviteLoading(false);
          setIsInvitedUserFound(true);
          setIsPaymentFormShown(true);
          setUserId(response.id);
          setUserData(response);
        },
        () => {
          setIsInviteLoading(false);
          setUserData({ ...userData, email });
        }
      );
    }
  }, [isFromInvite, userStore.isSignedIn]);

  useEffect(() => {
    setUserId(isInvitedUserFound ? userId : '');
    setIsAttachClientFailed(false);
  }, [userData.email]);

  useEffect(() => {
    setCounter(counter + 1);
    setUserData(getDefaultClientInfo());
    setPaymentData(getDefaultPaymentData);
    setIsValidationShown(false);
    setIsPaymentFormShown(userStore.isSignedIn);
    setIsPaid(data.hasPrice);
    setIsAttachClientFailed(false);
    setIsPaymentFailed(false);
    setIsEventBooked(false);
    setUserId('');
    setUserSecret('');
    setCurrentStep(1);
    setShowUncompletedBooking(true);
    setLocalData({
      timeMs: -1,
      timeStampMs: data.timestamp
    });
  }, []);

  const handleClickBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleClickNext = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleChangePaymentField = (field: string) => (value: any) => {
    setIsPaymentFailed(false);
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [field]: value
    }));
  };

  const handleChangeUserField = (field: string) => (value: any) => {
    setUserData({
      ...userData,
      [field]: field === 'email' ? value.trim() : value
    });
  };

  const handleChangeLocalDataField = (field: string) => (value: any) => {
    setLocalData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };

  const getAvailableSlots = (startMs: number, endMs: number) => {
    return getClientAppointmentBlockAvailableSlots({
      appointmentTypeId: data.id,
      durationMs: getMilliseconds(data.durationMinutes!, 'minutes'),
      fromTimeStampUtc: startMs,
      toTimeStampUtc: endMs,
      intervalMs: DEFAULT_TIME_SLOT_INTERVAL,
      timeZone: userStore.timeZone
    });
  };

  const createAppointment = async () => {
    return await createClientAppointment({
      ...getDefaultAppointmentData(),
      appointmentTypeId: data.id,
      timeMs: localData.timeMs,
      timestampUtc: localData.timeStampMs,
      price: `${data.price}`,
      appointmentStatus: EventStatus.Active
    });
  };

  const attachClient = async (itemId: number, clientId: string) => {
    if (userStore.isSignedIn) {
      if (data.type === BookingItemTypeEnum.Meeting) {
        return await attachClientToEvent(itemId);
      } else {
        return await attachClientToAppointment(itemId);
      }
    } else {
      if (data.type === BookingItemTypeEnum.Meeting) {
        return await attachClientToEventWithoutToken(itemId, clientId);
      } else {
        return await attachClientToAppointmentWithoutToken(itemId, clientId);
      }
    }
  };

  const cancelUnPaid = async (itemId: number, clientId: string) => {
    if (userStore.isSignedIn) {
      if (data.type === BookingItemTypeEnum.Meeting) {
        return await cancelUnPaidEvent(itemId);
      } else {
        return await cancelUnPaidAppointment(itemId);
      }
    } else {
      if (data.type === BookingItemTypeEnum.Meeting) {
        return await cancelUnPaidEventWithoutToken(itemId, clientId);
      } else {
        return await cancelUnPaidAppointmentWithoutToken(itemId, clientId);
      }
    }
  };

  const handleSave = async () => {
    let clientSecret = userSecret;
    let isEventPaid = isPaid;
    let clientId = userId;

    if (isFormValid) {
      setIsLoading(true);
      let itemId = data.id;
      if (!isEventBooked) {
        if (data.type === BookingItemTypeEnum.AppointmentBlock) {
          try {
            const createdAppointment = await createAppointment();
            itemId = createdAppointment.id;
          } catch (error) {
            setIsLoading(false);
            setIsCreateAppointmentFailed(true);
            if (error.errorMessage) {
              setCreateAppointmentError(error.errorMessage[0]);
            } else {
              setCreateAppointmentError('Appointment creation failed');
            }

            return error;
          }
        }
        if (!userStore.isSignedIn && !clientId.length) {
          await createClient(userData)
            .then((response) => {
              clientId = response.data.clientId!;
            })
            .catch((error) => {});
          setUserId(clientId);
        }
        try {
          const resp = await attachClient(itemId, clientId);
          clientSecret = resp.clientSecret;
          setUserSecret(clientSecret);
          isEventPaid = !resp.isFree;
          if (isEventPaid !== isPaid) {
            setIsPaid(isEventPaid);
            onReload();
            if (
              data.type === BookingItemTypeEnum.AppointmentBlock &&
              isEventPaid
            ) {
              setCurrentStep(2);
              if (!isPaymentFormShown) {
                setIsPaymentFormShown(true);
              }
            }
            if (data.type === BookingItemTypeEnum.Meeting && isEventPaid) {
              setCurrentStep(1);
              if (!isPaymentFormShown) {
                setIsPaymentFormShown(true);
              }
            }
          }
          setIsEventBooked(true);
        } catch (error) {
          setIsLoading(false);
          setIsAttachClientFailed(true);
          if (error.errorMessage) {
            setAttachClientError(error.errorMessage[0]);
          } else {
            setAttachClientError('Something went wrong!');
          }

          return error;
        }
      }

      if (isEventPaid) {
        if (!clientSecret) {
          setIsLoading(false);
          setIsAttachClientFailed(true);
          setAttachClientError(
            `You already refunded for this ${
              data.type === BookingItemTypeEnum.Meeting && !data.isScheduled
                ? 'meeting'
                : 'appointment'
            }. You couldn't sign up for it again.`
          );
          return;
        }
        if (!isPaymentDataValid(paymentData)) {
          setIsLoading(false);
          return;
        }
        const stripeResponse = await stripe!.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements!.getElement(CardNumberElement)!,
            billing_details: {
              email: userStore.isSignedIn ? userStore.email : userData.email,
              address: {
                postal_code: paymentData.postalCode
              }
            }
          }
        });
        if (
          stripeResponse.paymentIntent &&
          stripeResponse.paymentIntent.status === 'succeeded'
        ) {
          console.log(`Stripe response:`, stripeResponse.paymentIntent.status);
        } else if (stripeResponse.error) {
          await cancelUnPaid(itemId, clientId);
          if (
            stripeResponse.error.code === 'resource_missing' ||
            stripeResponse.error.code === 'account_invalid'
          ) {
            rootStore.getCoachInfo();
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: `Payment ${data.name}`,
              textColored: 'Something went wrong. Please, try again later!',
              duration: 3000
            });
          }
          setIsEventBooked(false);
          setIsLoading(false);
          setIsPaymentFailed(true);
          setIsValidationShown(true);
          console.log('erROR', stripeResponse.error);
          return stripeResponse.error;
        }
      }

      setIsLoading(false);
      setShowUncompletedBooking(false);
      setCurrentStep(
        !isEventPaid &&
          isPaid &&
          data.type === BookingItemTypeEnum.AppointmentBlock
          ? 2
          : steps.length - 1
      );
      rootStore.incrementChangesCounter();
    } else {
      setIsValidationShown(true);
    }
  };

  const infoBlockData: iInfoBlockData = {
    id: data.id,
    name: data.name,
    userName: `${rootStore.coachInfo.firstName} ${rootStore.coachInfo.lastName}`,
    membersCountLimitString: '',
    price: `${data.price}`,
    description: data.description,
    timeZone: userStore.timeZone,
    remindBefore: data.remindBeforeMs,
    isRemindBeforeDisabled: true,
    imageId: data.thumbnailImageId,
    type: null,
    isCanceled: data.status !== EventStatus.Active,
    location: data.showLocationWhileBooking ? data.location : null
  };

  switch (data.type) {
    case BookingItemTypeEnum.Meeting: {
      infoBlockData.type = data.isScheduled
        ? ScheduleItemType.OneToOne
        : ScheduleItemType.Event;
      infoBlockData.membersCountLimitString = `${data.membersCountLimit} spots`;
      infoBlockData.datesEvent = [
        data.timestamp!,
        data.timestamp! + getMilliseconds(data.durationMinutes!, 'minutes')
      ];
      break;
    }
    case BookingItemTypeEnum.Appointment: {
      infoBlockData.type = ScheduleItemType.Appointment;
      infoBlockData.membersCountLimitString =
        data.membersCountLimit === 1 ? 'One-on-One' : 'Group';
      infoBlockData.date = data.timestamp!;
      infoBlockData.durationMinutes = data.durationMinutes!;
      break;
    }
    case BookingItemTypeEnum.AppointmentBlock: {
      infoBlockData.type = ScheduleItemType.Appointment;
      infoBlockData.membersCountLimitString =
        data.membersCountLimit === 1 ? 'One-on-One' : 'Group';
      infoBlockData.durationMinutes = data.durationMinutes!;
      if (currentStep === 2) {
        infoBlockData.date = localData.timeStampMs + localData.timeMs;
      } else {
        infoBlockData.description = data.description;
      }
      break;
    }
  }

  const isMeeting = data.type !== BookingItemTypeEnum.AppointmentBlock;

  const notAvailableBlock = (
    <div className='ClientBookingWizard__notAvailable w-100'>
      Check other events and appointments available&nbsp;
      <Link
        to={'/' + getSiteName()}
        className='ClientBookingWizard__link'
        onClick={onClose}
      >
        here
      </Link>
    </div>
  );

  const saveDateTimeDataBeforeLogIn = () => {
    const eventData: iLogInWhileBookingData = {
      id: data.id
    };
    if (!isMeeting) {
      eventData.timeStampMs = localData.timeStampMs;
      eventData.timeMs = localData.timeMs;
    }
    setShouldProceedAfterLogin(true);
  };

  const isNotAvailable =
    (data.type === BookingItemTypeEnum.Meeting
      ? data.timestamp < getTimeInZone(Date.now(), userStore.timeZone || '') ||
        data.status !== EventStatus.Active
      : data.status !== EventStatus.Active) ||
    (!rootStore.coachInfo.isStripeConnected && data.price > 0);

  const steps = [
    null, // 1st step
    <>
      <div className='ClientBookingWizard__step ClientBookingWizard__step--details'>
        <InfoBlock
          data={{
            ...infoBlockData,
            avatarImage: `${
              rootStore.coachInfo.imageId
                ? getPublicFile(rootStore.coachInfo.imageId)
                : ''
            }`,
            occupation: `${rootStore?.coachCustomizationInfo.position}`,
            isCanceled: false // for not showing canceled badge
          }}
          className='InfoBlock--ClientBookingWizard'
        />
        {(infoBlockData.type === ScheduleItemType.Appointment ||
          Number(infoBlockData.price) > 0) && (
          <div className='ClientBookingWizard__separator d-lg-none'></div>
        )}

        <div className='ClientBookingWizard__details-step'>
          <ErrorMessage
            isVisible={isCreateAppointmentFailed}
            style={{
              top: -24,
              textAlign: 'center',
              width: '100%'
            }}
          >
            {createAppointmentError}
          </ErrorMessage>
          <AvailableDateTimePicker
            isDisabled={isNotAvailable}
            key={counter}
            date={localData.timeStampMs}
            timeMs={localData.timeMs}
            getAvailability={getAvailableSlots}
            onChangeDate={handleChangeLocalDataField('timeStampMs')}
            onChangeTime={(time) => {
              setIsCreateAppointmentFailed(false);
              handleChangeLocalDataField('timeMs')(time);
            }}
          />
        </div>
      </div>
      {isNotAvailable && notAvailableBlock}
      <div className='ClientBookingWizard__form-footer flex-sm-row flex-sm-row-reverse'>
        <Button
          handleClick={
            shouldProceedAfterDatePicked ? handleSave : handleClickNext
          }
          className='ml-sm-3 ml-0 button--ClientBookingWizard order-2 order-sm-1'
          disabled={localData.timeMs < 0 || isNotAvailable}
          data-testid='booking-cofirm-btn'
        >
          Confirm
        </Button>
        <Button
          handleClick={onClose}
          className={cn(
            'button--ClientBookingWizard ml-0 mb-3 mb-sm-0 order-1 order-sm-2',
            {
              button__gray: !isNotAvailable
            }
          )}
          disabled={isNotAvailable}
          data-testid='booking-cancel-btn'
        >
          Cancel
        </Button>
      </div>
    </>, // 2nd step
    <>
      <div className='ClientBookingWizard__step ClientBookingWizard__step--details'>
        <InfoBlock
          data={{
            ...infoBlockData,
            showCoachAvatar: false,
            isCanceled: false // for not showing canceled badge
          }}
          className='InfoBlock--ClientBookingWizard'
        />
        {(infoBlockData.type === ScheduleItemType.Appointment ||
          Number(infoBlockData.price) > 0) && (
          <div className='ClientBookingWizard__separator d-lg-none'></div>
        )}
        <div className='ClientBookingWizard__details-step'>
          <div className='ClientBookingWizard__details-step-inner'>
            <ErrorMessage
              isVisible={
                isCreateAppointmentFailed ||
                (isAttachClientFailed &&
                  !(
                    !userStore.isSignedIn &&
                    !isInviteLoading &&
                    !isInvitedUserFound
                  ))
              }
              style={{
                top: -12,
                textAlign: 'center',
                width: '100%'
              }}
            >
              {isCreateAppointmentFailed
                ? createAppointmentError
                : attachClientError}
            </ErrorMessage>
            {isDesktop && userStore.isSignedIn && !isPaid && (
              <div
                className='ClientBookingWizard__description'
                dangerouslySetInnerHTML={{
                  __html: data.description
                }}
              />
            )}
            {!userStore.isSignedIn &&
              !isNotAvailable &&
              !isInviteLoading &&
              !isInvitedUserFound && (
                <>
                  <PersonalDetails
                    data={userData}
                    handleChangeField={handleChangeUserField}
                    isValidationShown={isValidationShown}
                    emailErrorMessage={
                      isAttachClientFailed ? attachClientError : ''
                    }
                    userStore={userStore}
                    saveDateTimeDataBeforeLogIn={saveDateTimeDataBeforeLogIn}
                  />
                </>
              )}
            {isPaid && isPaymentFormShown && (
              <>
                <PaymentDetails
                  className='PaymentDetails--ClientBookingWizard '
                  withImage
                  isFailed={isPaymentFailed}
                  data={paymentData}
                  onChangeField={handleChangePaymentField}
                  isValidationShown={isValidationShown}
                  paymentInfo={{
                    price: data.price,
                    paymentDescription: 'one-time payment'
                  }}
                />
                <br />
              </>
            )}
          </div>
        </div>
      </div>
      {isNotAvailable && notAvailableBlock}
      <div className='ClientBookingWizard__form-footer flex-sm-row flex-sm-row-reverse'>
        {!isPaymentFormShown && isPaid && (
          <Button
            handleClick={() => setIsPaymentFormShown(true)}
            className='ml-sm-3 ml-0 button--ClientBookingWizard order-2 order-sm-1'
            disabled={isNotAvailable}
          >
            Pay now
          </Button>
        )}
        {isPaymentFormShown && isPaid && (
          <Button
            handleClick={handleSave}
            className='ml-sm-3 ml-0 button--ClientBookingWizard order-2 order-sm-1'
            disabled={isNotAvailable}
          >
            Confirm &amp; Pay
          </Button>
        )}
        {!isPaid && (
          <Button
            handleClick={handleSave}
            className='ml-sm-3 ml-0 button--ClientBookingWizard order-2 order-sm-1'
            disabled={isNotAvailable}
            data-testid='booking-confirm-btn'
          >
            Confirm
          </Button>
        )}
        <Button
          handleClick={isMeeting ? onClose : handleClickBack}
          className={cn(
            'button--ClientBookingWizard ml-0 mb-3 mb-sm-0 order-1 order-sm-2',
            {
              button__gray: !isNotAvailable
            }
          )}
          disabled={isNotAvailable}
          data-testid='booking-cancel-btn'
        >
          {isMeeting ? 'Cancel' : 'Back'}
        </Button>
      </div>
    </>, // 3rd step
    <SummaryStep
      greetingMessage='Congratulations! Your spot is reserved.'
      link={data.location}
      footerText={
        !userStore.isSignedIn
          ? `We sent ${
              isMeeting ? 'meeting' : 'appointment'
            } details on your email along with credentials to your account.`
          : ''
      }
      className='SummaryStep--ClientBookingWizard'
    >
      <Button
        className='button__ClientBookingWizard-SummaryStep'
        handleClick={onClose}
      >
        Ok
      </Button>
    </SummaryStep>
  ];

  if (shouldProceedAfterDatePicked && !shouldProceedAfterLogin) {
    steps.splice(2, 1);
    subTitles.splice(2, 1);
    width_options.splice(2, 1);
  }

  if (isMeeting) {
    steps.splice(1, 1);
    subTitles.splice(1, 1);
    width_options.splice(1, 1);
  }

  const modalHeaderName = () => {
    if (data.type === BookingItemTypeEnum.Meeting && !userStore.isSignedIn) {
      if (!isPaid) {
        return titles[data.type][0];
      }
      return titles[data.type][!isPaymentFormShown ? 0 : 1];
    }
    return titles[data.type][currentStep === 1 ? 0 : 1];
  };

  return (
    <div className='ClientBookingWizard'>
      <GlobalLoader isLoading={isLoading} />
      <div
        className={`ClientBookingWizard__inner p-lg-5 p-3 ${
          isNotAvailable ? 'ClientBookingWizard__inner--canceled' : ''
        }
        `}
      >
        <div className='ModalHeader d-flex align-items-center flex-wrap'>
          <h2 className='ModalHeader__title mr-3'>{modalHeaderName()}</h2>
          {isNotAvailable && (
            <div className='ml-n2 p-2'>
              <span
                className='InfoBlock__badge position-relative'
                style={{ zIndex: 3 }}
              >
                This{' '}
                {data.type === BookingItemTypeEnum.Meeting && !data.isScheduled
                  ? 'meeting'
                  : 'appointment'}{' '}
                isn't available anymore.
              </span>
            </div>
          )}
        </div>
        <div className='ModalHeader__sub-title ModalHeader__sub-title--notInModal'>
          <h3>{subTitles[currentStep]}</h3>
        </div>

        <StepsVisualizer
          currentStep={currentStep}
          stepsTotal={steps.length - 2}
          className='StepsVisualizer--ClientBookingWizard'
        />
        {steps[currentStep]}
      </div>

      <PreventUnsavedChangesModal
        title={`Uncompleted ${
          data.type === BookingItemTypeEnum.Meeting
            ? 'registration'
            : 'appointment'
        }`}
        text={`Are you sure you want to skip ${
          data.type === BookingItemTypeEnum.Meeting
            ? "registration to the meeting? Your spot at this meeting won't be preserved"
            : "scheduling the appointment? Your appointment won't be saved"
        }.`}
        confirmBtnText={`Complete ${
          data.type === BookingItemTypeEnum.Meeting
            ? 'Registration'
            : 'Appointment'
        }`}
        onSave={() => {}}
        disableConfirmBtn={isLoading}
        initialData={
          data.status !== EventStatus.Active
            ? showUncompletedBooking.toString()
            : 'false'
        }
        currentData={showUncompletedBooking.toString()}
      />
    </div>
  );
};

export default observer(ClientBookingWizard);
