import React from 'react';
import './SummaryStep.styles.scss';
import HugeCheckedIcon from '../../icons/huge-checked.svg';
// import LinkIcon from '../../icons/link.svg';
// import CopyLinkInput from '../CopyLinkInput/CopyLinkInput';
// import { inject, observer } from 'mobx-react';
// import { iStores } from '../../store/RootStore';
// import NotificationStore from '../../store/NotificationStore';

interface iProps {
  greetingMessage: string;
  link?: string;
  inputLabelText?: string;
  footerText?: string;
  className?: string;
}

const SummaryStep: React.FC<iProps> = ({
  greetingMessage,
  link = '',
  inputLabelText = '',
  footerText = '',
  children,
  className = ''
}) => {
  return (
    <div className={`SummaryStep ${className}`}>
      <div className='SummaryStep__greets-text'>
        {React.createElement(HugeCheckedIcon, {
          className: 'SummaryStep__checked-icon'
        })}
        {greetingMessage}
      </div>
      {/*<div className="SummaryStep__link-input-label">*/}
      {/*{inputLabelText.length ? inputLabelText :'Your link to the video call'}*/}
      {/*</div>*/}
      {/*<CopyLinkInput link={link}/>*/}
      {footerText.length ? (
        <div className='SummaryStep__footer'>{footerText}</div>
      ) : null}
      {children}
    </div>
  );
};

export default SummaryStep;
