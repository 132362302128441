import { useState } from 'react';
import { ScheduleItemType } from '../core/enums';

interface ModalData {
  confirmBtnText: string;
  cancelBtnText: string;
  text: string;
  title: string;
  cancelType: CancelType;
}

export type CancelType = 'allClients' | 'singleClient';

export const useCancelConfirmationModal = () => {
  const [modalData, setModalData] = useState<ModalData>({
    confirmBtnText: 'Yes',
    cancelBtnText: 'No',
    text: '',
    title: '',
    cancelType: 'allClients'
  });

  const setData = (
    itemType: ScheduleItemType,
    isFree: boolean,
    activeMembers: number,
    cancelType: CancelType = 'allClients',
    isRefunded?: boolean
  ) => {
    let title = `Cancel ${
      itemType !== ScheduleItemType.Appointment ? 'Meeting' : 'Appointment'
    }`;
    let confirmBtnText = 'Yes',
      cancelBtnText = 'No',
      item =
        itemType !== ScheduleItemType.Appointment ? 'Meeting' : 'Appointment',
      text = `Are you sure that you want to cancel it ?`;

    if (
      itemType !== ScheduleItemType.Appointment &&
      isFree &&
      cancelType === 'singleClient'
    ) {
      title = 'Cancel registration';
      text =
        'Are you sure that you want to cancel registration for the client?';
    }

    if (!isFree) {
      const textPart = `You are trying to cancel a paid`;

      if (itemType !== ScheduleItemType.Appointment) {
        if (!!activeMembers) {
          if (isRefunded) {
            if (
              cancelType === 'singleClient' &&
              itemType === ScheduleItemType.Event
            ) {
              text =
                'Are you sure that you want to cancel registration for the client?';
            }
          } else {
            confirmBtnText = 'Cancel & Refund';
            cancelBtnText = `Cancel ${item}`;

            if (
              cancelType === 'singleClient' &&
              itemType !== ScheduleItemType.OneToOne
            ) {
              cancelBtnText = 'Cancel';
              title = 'Remove a member from the meeting';
              text =
                'This is a paid meeting. If you click Yes, this person will be automatically refunded. Do you want to proceed?';
              confirmBtnText = 'Yes';
              cancelBtnText = 'No';
            } else if (itemType === ScheduleItemType.OneToOne) {
              cancelBtnText = 'Skip';
              text = `${textPart} ${item.toLowerCase()}. If you press Cancel & Refund your client will be automatically refunded.`;
            } else {
              text = `${textPart} ${item.toLowerCase()}. If you press Cancel & Refund all members of this meeting will be automatically refunded. If you choose to Cancel you'll have to make a refund manually in your Stripe account.`;
            }
          }
        }
      }

      if (itemType === ScheduleItemType.Appointment) {
        if (!isRefunded) {
          cancelBtnText = 'Skip';
          confirmBtnText = `Cancel & Refund`;
          text = `${textPart} ${item.toLowerCase()}. If you press Cancel & Refund your client will be automatically refunded.`;
        }
      }
    }

    setModalData({
      confirmBtnText,
      cancelBtnText,
      text,
      title,
      cancelType
    });
  };
  return {
    confirmModalData: modalData,
    setConfirmModalData: setData
  };
};
