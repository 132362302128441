import React from 'react';
import './FormRadio.styles.scss';

interface iProps {
  id?: string;
  checked: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  tabIndex?: number;
}

const FormRadio: React.FC<iProps> = ({
  checked,
  handleChange,
  label,
  className = '',
  id,
  isDisabled = false,
  tabIndex,
  ...rest
}) => {
  return (
    <div className={`FormRadio ${className}`}>
      <input
        type='radio'
        className='FormRadio__input '
        id={id}
        checked={checked}
        onChange={handleChange}
        disabled={isDisabled}
        tabIndex={tabIndex}
        {...rest}
      />
      <label
        htmlFor={id}
        className={`FormRadio__label  ${
          isDisabled ? 'FormRadio__label--disabled' : ''
        }`}
      >
        <div
          className={`FormRadio__toggler flex-shrink-0 ${
            isDisabled ? 'FormRadio__toggler--disabled' : ''
          }`}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export default FormRadio;
