import React from 'react';
import { highlightMatches, normalizePrice, searchBy } from '../../core/helpers';
import { TableColumn, TableColumnType } from '../Table/Table';
import Badge, { BadgeType } from '../Badge/Badge';
import {
  TransactionsEntityEnum,
  CoachRoutesEnum,
  InvoiceStatusEnum,
  ClientRoutesEnum,
  BillingIntervalEnum
} from '../../core/enums';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BillingItemViewModel } from '../../core/backend/api';
import TextEllipse from '../TextEllipse';
import { getRoutePath } from '../../core/globals';

export const getBadgeType = (
  type: keyof typeof InvoiceStatusEnum
): BadgeType => {
  switch (type) {
    case 'draft':
      return 'gray';
    case 'open':
      return 'blue';
    case 'canceled':
      return 'yellow';
    case 'paid':
      return 'green';
    case 'late':
      return 'red';
    case 'uncollectible':
    default:
      return 'disabled';
  }
};

export const filterForSearch = (
  debouncedSearchString: string,
  t: BillingItemViewModel
) => {
  let amount = String(t.amount || '');
  if (!debouncedSearchString.includes('.')) {
    amount = amount.split('.')[0];
  }

  return (
    amount === debouncedSearchString ||
    searchBy(String(t.entityId), debouncedSearchString) ||
    searchBy(moment.utc(t.date).format('MMM D YYYY'), debouncedSearchString)
  );
};

export const getColumns: (
  flow: 'coach' | 'client',
  cb?: (item: BillingItemViewModel) => void,
  showInvoice?: boolean
) => TableColumn<BillingItemViewModel>[] = (flow, openViewModal, showInvoice) => [
  {
    field: 'date',
    title: 'DATE',
    type: TableColumnType.String,
    sortable: true,
    formatter: (v: string) => {
      return moment.utc(v).format('MMM D, YYYY');
    },
    minWidth: 120,
    maxWidth: '1fr'
  },
  {
    field: 'entity',
    title: 'DESCRIPTION',
    type: TableColumnType.JSX,
    formatter: (item: BillingItemViewModel, searchString) => {
      const { entity, entityName, description } = item;
      const handleOpenViewModal =
        entity === TransactionsEntityEnum.Appointment ||
        entity === TransactionsEntityEnum.Meeting
          ? () => {
              openViewModal(item);
            }
          : undefined;

      const name =
        entity === TransactionsEntityEnum.Appointment ||
        entity === TransactionsEntityEnum.Meeting
          ? entityName
          : `#${entityName}`;

      return (
        <div className='TransactionsList__description'>
          <TextEllipse text={`${description} ${name}`}>
            <span>
              {description}{' '}
              <span
                onClick={handleOpenViewModal}
                className={
                  entity === 'Appointment' || entity === 'Meeting'
                    ? 'TransactionsList__name'
                    : ''
                }
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(name, searchString)
                }}
              />
            </span>
          </TextEllipse>
        </div>
      );
    },
    minWidth: 200,
    maxWidth: '2fr'
  },
  {
    field: 'amount',
    title: 'AMOUNT',
    type: TableColumnType.JSX,
    sortable: true,
    formatter: ({ amount }: BillingItemViewModel) => {
      return <div className='mr-3'>$&nbsp;{normalizePrice(amount)}</div>;
    },
    minWidth: 100,
    maxWidth: '1fr'
  },
  {
    field: 'status',
    title: 'STATUS',
    type: TableColumnType.JSX,
    sortable: true,
    formatter: ({ status }: BillingItemViewModel) => {
      const badgeType = InvoiceStatusEnum[
        status
      ] as keyof typeof InvoiceStatusEnum;

      return (
        <Badge
          type={getBadgeType(badgeType)}
          title={badgeType}
          className='Badge--TransactionsList'
        />
      );
    },
    minWidth: 128,
    maxWidth: '1fr'
  },
  ...(showInvoice
    ? [{
      field: 'entityId',
    title: '',
    type: TableColumnType.JSX,
    formatter: (item: BillingItemViewModel) => {
      const { entity, entityId } = item;
      if (
        entity === TransactionsEntityEnum.Appointment ||
        entity === TransactionsEntityEnum.Meeting
      ) {
        return null;
      }
      return (
        <Link
          to={
            flow === 'coach'
              ? CoachRoutesEnum.Invoice.replace(':id', String(entityId))
              : getRoutePath(
                  ClientRoutesEnum.Invoice.replace(
                    ':number',
                    item.invoice?.number
                  )
                )
          }
          className='TransactionsList__link'
        >
          View Invoice
        </Link>
      );
    },
    minWidth: 124,
    maxWidth: '124px'
    } as TableColumn<BillingItemViewModel>]
    : []
  )
];

export const amountFormatter = (value: number) => {
  return `$${value}`;
};

export const dateFormatter = (range: number[], currentTimestamp: number) => (
  value: number | string
) => {
  if (range.length === 1) {
    return moment.utc(value).format('HH:mm');
  }

  if (
    value >= moment.utc(currentTimestamp).startOf('day').valueOf() &&
    value <= moment.utc(currentTimestamp).endOf('day').valueOf()
  )
    return 'Today';

  if (!value) return moment.utc(range[0]).format('D MMM');
  if (value === 'auto') return moment.utc(range[1]).format('D MMM');

  return moment.utc(value).format('D MMM');
};
