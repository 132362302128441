import React from 'react';
import './InputCustom.styles.scss';

interface iProps {
  value: string | number;
  title: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  step?: number;
  isInvalid?: boolean;
  className?: string;
  errorMessage?: string;
  tabIndex?: number;
}

const InputCustom: React.FC<iProps> = ({
  value,
  title,
  handleChange,
  handleBlur = () => {},
  step = 5,
  isInvalid = false,
  className = '',
  errorMessage,
  tabIndex,
  ...rest
}) => {
  return (
    <>
      <div
        className={`
                InputCustom
                ${value !== '' ? 'InputCustom--active' : ''}
                ${isInvalid ? 'InputCustom--error' : ''}
                ${className}
            `}
      >
        <input
          className={`
                    InputCustom__input
                    ${value !== '' ? 'InputCustom__input--active' : ''}
                    ${isInvalid ? 'InputCustom__input--error' : ''}
                `}
          type='number'
          step={step}
          min={0}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          tabIndex={tabIndex}
          {...rest}
        />

        <div
          className={`
                    InputCustom__text
                    ${value !== '' ? 'InputCustom__text--active' : ''}
                    ${isInvalid ? 'InputCustom__text--error' : ''}
                `}
        >
          {title}
        </div>
      </div>
      {isInvalid && !!errorMessage && (
        <div className='InputCustom__error-message'>{errorMessage}</div>
      )}
    </>
  );
};

export default InputCustom;
