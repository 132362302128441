import axios from 'axios';
import RootStore from '../store/RootStore';

export default function setInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const config = error.config;
      if (!config) {
        return Promise.reject(error);
      }

      if (error.response && error.response.status === 401) {
        if (
          RootStore.flow !== 'coach' ||
          !error.config.url.includes(RootStore.userStore.siteName)
        ) {
          RootStore.userStore.isSignedIn = false;
        }
      }

      return Promise.reject(error.response.data);
    }
  );
}
