import React, { useEffect, useState } from 'react';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import FormInput from '../../../components/FormInput/FormInput';

import Modal from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/ModalHeader/ModalHeader';
import { InvoiceTax } from '../../../core/types';
import {
  getDefaultData,
  getFormInputErrors,
  isFormDataValid,
  isFormInputValid
} from './CoachInvoiceTaxModal.helpers';
import { ListItemProps } from '../../../components/DropdownMenu/DropdownMenu';
import { TaxType } from '../../../core/enums';
import Button from '../../../components/Button/Button';
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import './CoachInvoiceTaxModal.styles.scss';
import { formatValueAsPrice } from '../../../core/formValidation';

interface Props {
  isOpened: boolean;
  data?: InvoiceTax;
  options: InvoiceTax[];
  close: () => void;
  onSubmit: (data: InvoiceTax) => void;
}

const CoachInvoiceTaxModal: React.FC<Props> = ({
  isOpened,
  data,
  options,
  close,
  onSubmit
}) => {
  const [localData, setLocalData] = useState<InvoiceTax>(getDefaultData());
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);

  useEffect(() => {
    if (isOpened) {
      if (data) {
        setLocalData(data);
      } else {
        setLocalData(getDefaultData());
      }
    }
  }, [isOpened]);

  const handleChangeField = (field: keyof InvoiceTax) => (value: any) => {
    setLocalData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleChange = (item: InvoiceTax) => {
    if (!item && localData.id) {
      const { name, type, value } = localData;
      setLocalData({ name, type, value });
    }
    if (item) {
      setLocalData({
        ...item
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsValidationShown(false);

    if (isFormDataValid(localData)) {
      onSubmit({ ...localData, value: +localData.value });
    } else {
      setIsValidationShown(true);
    }
  };

  const typeOptions: ListItemProps[] = [
    {
      name: '$',
      callback: () => handleChangeField('type')(TaxType.Amount)
    },
    {
      name: '%',
      callback: () => handleChangeField('type')(TaxType.Rate)
    }
  ];

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 424 }}>
      <div className='CoachInvoiceTaxModal'>
        <ModalHeader>
          <h2>Add tax rate</h2>
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <Autocomplete<InvoiceTax>
            options={options}
            optionKeyProp='id'
            value={localData.name}
            onChange={handleChangeField('name')}
            onSelect={handleChange}
            getOptionLabel={(opt: InvoiceTax) =>
              `${opt.name} ${opt.value}${opt.type === TaxType.Rate ? '%' : '$'}`
            }
            renderInput={({ value, ...rest }) => (
              <FormInput
                {...rest}
                value={value}
                autoFocus={!data}
                name='name'
                isInvalid={
                  isValidationShown && !isFormInputValid('name', localData)
                }
                errorMessage={getFormInputErrors('name', localData)[0]}
                placeholder='Tax name'
              />
            )}
          />
          <FormInput
            type='number'
            step='0.01'
            name='value'
            value={localData.value}
            valueFormatter={formatValueAsPrice}
            isInvalid={
              isValidationShown && !isFormInputValid('value', localData)
            }
            errorMessage={getFormInputErrors('value', localData)[0]}
            handleChange={handleChangeField('value')}
            placeholder={localData.type === TaxType.Rate ? 'Rate' : 'Amount'}
            append={
              <DropdownMenu
                list={typeOptions}
                className='CoachInvoiceTaxModal__dropdown'
                withToolitip={false}
                menuListClassName='DropdownMenu__list--CoachInvoiceTaxModal'
              >
                <Button type='button' variations={['inversed']}>
                  {localData.type === TaxType.Rate ? '%' : '$'}
                </Button>
              </DropdownMenu>
            }
          />
          <div className='d-flex mt-4 justify-content-end'>
            <Button
              type='button'
              variations={['inversed', 'xs-width']}
              handleClick={close}
            >
              Cancel
            </Button>
            <Button variations={['sm-width']}>Add tax rate</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CoachInvoiceTaxModal;
