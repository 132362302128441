import React from 'react';
import Button from '../Button/Button';
import ArrowIcon from '../../icons/arrow_in_circle.svg';
import './CreateNewItem.styles.scss';
import { iNewType } from '../Modals/AddNewModal/AddNewModal.constants';

interface Props {
  item: iNewType;
  imgBlockClassname?: string;
  className?: string;
}

const CreateNewItem: React.FC<Props> = ({
  item,
  imgBlockClassname = '',
  className = ''
}) => {
  return (
    <div className={`NewType ${className}`}>
      <div className='NewType__block'>
        <div className={`NewType__image ${imgBlockClassname}`}>{item.icon}</div>
        <div className='NewType__text'>
          <div className='NewType__title'>{item.title}</div>
          <div
            className='NewType__description'
            dangerouslySetInnerHTML={{
              __html: item.description
            }}
          />
        </div>
      </div>

      <Button className='NewType__button button__with-icon button__m-w-160'>
        Create
        <ArrowIcon />
      </Button>
    </div>
  );
};

export default CreateNewItem;
