import React, { useEffect, useReducer, useState } from 'react';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import {
  reducer,
  getDefaultData,
  isCompleteRegistrationDataValid as isDataValid,
  isCompleteRegistrationInputValid as isInputValid,
  getCompleteRegistrationInputErrors as getInputErrors
} from './CoachCompleteRegistration.helpers';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import TilePage from '../../components/TilePage/TilePage';
import { observer } from 'mobx-react';
import { createLink } from '../../core/helpers';
import getGlobal from '../../core/globals';
import { isSiteNameExists } from '../../core/api';
import {
  CountriesList,
  StatesList,
  getCountryId,
  getStatesByCountry,
  getStateId,
  USA_COUNTRY_ID
} from '../../components/Modals/ContactModal/ContactModal.helpers';
import Select from '../../components/Select/Select';
import CustomizedPhoneInput from '../../components/CustomizedPhoneInput';
import { useStores } from '../../hooks';
import { iOption } from '../../core/types';

let checkSiteNameTimer: number;

const CoachCompleteRegistration = () => {
  const {
    rootStore: { userStore }
  } = useStores();

  const [data, setData] = useReducer(reducer, getDefaultData(userStore));
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isSiteNameExist, setIsSiteNameExist] = useState(false);
  const countryId = getCountryId(data.country, true);
  const [selectedCountry, setSelectedCountry] = useState<string>(countryId);
  const states = getStatesByCountry(countryId);
  const [statesByCountry, setStatesByCountry] = useState<iOption[]>(states);
  const [selectedState, setSelectedState] = useState<string>(
    states.length ? getStateId(data.state, states) : ''
  );
  useEffect(() => {
    if (
      !userStore.siteName.trim() &&
      userStore.firstName.trim() &&
      userStore.lastName.trim()
    ) {
      handleChangeField('siteName')(
        createLink(`${userStore.firstName}_${userStore.lastName}`)
      );
    }
  }, []);

  useEffect(() => {
    if (
      data.siteName.trim() !== '' &&
      userStore.siteName.trim() !== data.siteName.trim()
    ) {
      clearTimeout(checkSiteNameTimer);
      checkSiteNameTimer = window.setTimeout(() => {
        isSiteNameExists(data.siteName.trim()).then((response) => {
          setIsSiteNameExist(response.data);
        });
      }, 300);
    } else {
      setIsSiteNameExist(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.siteName]);

  const handleNameBlur = () => {
    if (!data.siteName && data.firstName.trim() && data.lastName.trim()) {
      handleChangeField('siteName')(
        createLink(`${data.firstName}_${data.lastName}`)
      );
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const country = CountriesList!.find(
      (country) => country.id === selectedCountry
    );
    if (isDataValid({ ...data, country: country.name }) && !isSiteNameExist) {
      const state = statesByCountry!.find(
        (state) => state.id === selectedState
      );
      const payload = {
        ...data,
        country: country ? country.name : '',
        state: state && isUSASelected() ? state.name : ''
      };
      userStore.completeRegistration(payload).catch();
    } else {
      setIsValidationShown(true);
    }
  };

  const handleChangeField = (field: string) => (value: any) => {
    setData({
      ...data,
      [field]: value
    });
  };

  const isUSASelected = () =>
    selectedCountry === USA_COUNTRY_ID && statesByCountry.length;
  const getCustomBlockText = () =>
    getGlobal('domain').replace(/(^\w+:|^)\/\//, '') + '/';

  return (
    <TilePage
      className='CoachSignUp'
      title='Complete Your Registration'
      tileStyle={{ maxWidth: 648 }}
    >
      <ModalHeader>
        <h2>Complete your registration</h2>
        <h4>All fields are required</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit} noValidate autoComplete='off'>
        <div className='TileOption'>
          <div className='row mx-n2'>
            <div className='col-6 px-2'>
              <FormInput
                name='firstName'
                label='First Name'
                placeholder='First name'
                value={data.firstName}
                isInvalid={
                  isValidationShown && !isInputValid('firstName', data)
                }
                errorMessage='Please fill'
                handleChange={handleChangeField('firstName')}
                onBlur={handleNameBlur}
              />
            </div>
            <div className='col-6 px-2'>
              <FormInput
                name='lastName'
                label='Last Name'
                placeholder='Last name'
                value={data.lastName}
                isInvalid={isValidationShown && !isInputValid('lastName', data)}
                errorMessage='Please fill'
                handleChange={handleChangeField('lastName')}
                onBlur={handleNameBlur}
              />
            </div>
            <div className='col-sm-6 px-2'>
              <Select
                options={CountriesList}
                value={selectedCountry}
                title={'Country'}
                onChange={(countryId) => {
                  setSelectedCountry(countryId);
                  setStatesByCountry(
                    StatesList.filter((state) => state.value === countryId)
                  );
                }}
                className='Select--contact-country'
                classNameSuffix='CoachCompleteRegistration'
              />
            </div>
            {isUSASelected() && (
              <div className='col-sm-6 px-2'>
                <Select
                  options={statesByCountry}
                  value={selectedState}
                  title={'State'}
                  onChange={setSelectedState}
                  className='Select--contact-state'
                  classNameSuffix='CoachCompleteRegistration'
                  placeholder='Select State'
                />
              </div>
            )}
            <div className='col-sm-6 px-2'>
              <FormInput
                name='city'
                label='City'
                value={data.city}
                isInvalid={isValidationShown && !isInputValid('city', data)}
                errorMessage='This field is required'
                inputClassName='FormInput__input--CoachCompleteRegistration'
                handleChange={handleChangeField('city')}
                placeholder='Add city'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                label='Phone number'
                value={data.phoneNumber}
                isInvalid={
                  isValidationShown && !isInputValid('phoneNumber', data)
                }
                errorMessage={getInputErrors('phoneNumber', data)[0]}
                handleChange={handleChangeField('phoneNumber')}
                inputClassName='FormInput__input--phoneNumber'
              >
                <CustomizedPhoneInput
                  value={data.phoneNumber}
                  onChange={(value) => handleChangeField('phoneNumber')(value)}
                  inputClass={
                    isValidationShown && !isInputValid('phoneNumber', data)
                      ? 'PhoneInputInput--error'
                      : ''
                  }
                />
              </FormInput>
            </div>
            {/* <div className='col-sm-6 px-2'>
              <FormInput
                label='Industry'
                value={data.industry}
                isInvalid={isValidationShown && !isInputValid('industry', data)}
                errorMessage='Please fill'
                handleChange={handleChangeField('industry')}
              />
            </div> */}
          </div>
        </div>
        <FormInput
          label={`Name Your Coach Page (Don't worry - you can change this later in your account)`}
          value={data.siteName}
          isInvalid={
            isSiteNameExist ||
            (isValidationShown && !isInputValid('siteName', data))
          }
          errorMessage={
            isSiteNameExist
              ? 'This sitename is used by another user, please choose another'
              : `Coach Page name ${getInputErrors('siteName', data)[0]}`
          }
          handleChange={handleChangeField('siteName')}
          prepend={getCustomBlockText()}
          placeholder='first_name_last_name'
          valueFormatter={(value: string) => {
            return value.toLowerCase().replace(/[^a-z\d-_]/, '');
          }}
        />
        <div className='Tile__footer d-flex flex-sm-row flex-column'>
          <Button>Next</Button>
        </div>
      </form>
    </TilePage>
  );
};

export default observer(CoachCompleteRegistration);
