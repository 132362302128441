import React, { FC, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Availability,
  AvailabilityInterval,
  AvailabilityIntervalSingleDate,
  AvailabilityIntervalWeekly
} from '../../core/types';
import WeekCalendar from '../../components/WeekCalendar/WeekCalendar';
import CalendarAvailabilityCell from '../../components/CalendarAvailabilityCell/CalendarAvailabilityCell';
import { DayOfWeek } from '../../core/backend/api';
import ManageAvailabilityIntervalsModal from '../../components/Modals/ManageAvailabilityIntervalsModal/ManageAvailabilityIntervalsModal';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';

interface Props {
  initDate: number;
  availability: Availability;
  minIntervalMs: number;
  onChangeAvailability: (data: Availability) => void;
  isInvalid?: boolean;
  errorMessage?: string;
}

const CoachAppointmentBlockAvailabilityCalendar: FC<Props> = ({
  initDate,
  availability,
  minIntervalMs,
  onChangeAvailability,
  isInvalid,
  errorMessage = ''
}) => {
  const [isManageAvailabilityOpened, setIsManageAvailabilityOpened] = useState(
    false
  );
  const [manageAvailabilityData, setManageAvailabilityData] = useState<{
    day: Moment;
    intervals: AvailabilityInterval[];
  }>({
    day: null,
    intervals: []
  });
  const [
    isWeeklyAvailabilityConfirmationShown,
    setIsWeeklyAvailabilityConfirmationShown
  ] = useState(false);
  const [
    weeklyAvailabilityOverridesData,
    setWeeklyAvailabilityOverridesData
  ] = useState<{
    intervals: AvailabilityInterval[];
    days: DayOfWeek[];
    overrideDates: number[];
  }>(null);

  const handleCellClick = (day: Moment, intervals: AvailabilityInterval[]) => {
    setIsManageAvailabilityOpened(true);
    setManageAvailabilityData({ day, intervals });
  };

  const handleCloseAvailability = () => {
    setIsManageAvailabilityOpened(false);
  };

  const applyToDates = (intervals: AvailabilityInterval[], dates: number[]) => {
    const newAvailability = {
      ...availability,
      /* clear all intervals, which have same date */
      singleDates: availability.singleDates.filter(
        (interval: any) => !dates.includes(interval.date)
      ),
      excludeDates: availability.excludeDates.filter(
        (interval: any) => !dates.includes(interval.date)
      )
    };
    intervals.forEach((interval) => {
      dates.forEach((date) => {
        newAvailability.singleDates.push({
          interval,
          date
        });
      });
    });
    if (!intervals.length) {
      dates.forEach((date) => {
        const day = moment.utc(date).day();
        const weekly = newAvailability.weekly.find((w) => w.day === day);
        if (weekly) {
          newAvailability.excludeDates = [
            ...newAvailability.excludeDates,
            {
              date,
              interval: {
                timeStartMs: 0,
                timeEndMs: 24 * 60 * 60 * 1000
              }
            }
          ];
        }
      });
    }
    onChangeAvailability(newAvailability);
    handleCloseAvailability();
  };

  const handleApplyToWeekDays = (
    intervals: AvailabilityInterval[],
    days: DayOfWeek[]
  ) => {
    const matchedSingle = availability.singleDates
      .filter((interval: AvailabilityIntervalSingleDate) =>
        days.includes(moment.utc(interval.date).day())
      )
      .filter((ms) => {
        return !intervals.some(
          (i) =>
            ms.interval.timeStartMs === i.timeStartMs &&
            ms.interval.timeEndMs === i.timeEndMs
        );
      });
    const matchedExclude = availability.excludeDates
      .filter((interval: AvailabilityIntervalSingleDate) =>
        days.includes(moment.utc(interval.date).day())
      )
      .filter(() => !!intervals.length);

    if (matchedSingle.length || matchedExclude.length) {
      const overrideDates = new Set([
        ...matchedSingle.map((s) => s.date),
        ...matchedExclude.map((e) => e.date)
      ]);
      setIsWeeklyAvailabilityConfirmationShown(true);
      setWeeklyAvailabilityOverridesData({
        intervals,
        days,
        overrideDates: Array.from(overrideDates).sort()
      });
    } else {
      applyToWeekDays(intervals, days, true);
    }
  };

  const handleConfirmOverride = () => {
    applyToWeekDays(
      weeklyAvailabilityOverridesData.intervals,
      weeklyAvailabilityOverridesData.days,
      true
    );
    setIsWeeklyAvailabilityConfirmationShown(false);
  };

  const handleConfirmWithoutOverride = () => {
    applyToWeekDays(
      weeklyAvailabilityOverridesData.intervals,
      weeklyAvailabilityOverridesData.days,
      false
    );
    setIsWeeklyAvailabilityConfirmationShown(false);
  };

  const handleCloseOverride = () => {
    setIsWeeklyAvailabilityConfirmationShown(false);
  };

  const applyToWeekDays = (
    intervals: AvailabilityInterval[],
    days: DayOfWeek[],
    override: boolean
  ) => {
    const newAvailability: Availability = {
      ...availability,
      /* clear all single date intervals, which have an intersection with week days */
      singleDates: override
        ? availability.singleDates.filter(
            (interval: AvailabilityIntervalSingleDate) =>
              !days.includes(moment.utc(interval.date).day())
          )
        : availability.singleDates,
      excludeDates: override
        ? availability.excludeDates.filter(
            (interval: AvailabilityIntervalSingleDate) =>
              !days.includes(moment.utc(interval.date).day())
          )
        : availability.excludeDates,
      /* clear all weekly intervals, which are the same week day */
      weekly: availability.weekly.filter(
        (interval: AvailabilityIntervalWeekly) => !days.includes(interval.day)
      )
    };
    intervals.forEach((interval) => {
      days.forEach((day) => {
        newAvailability.weekly.push({
          interval,
          day
        });
      });
    });
    onChangeAvailability(newAvailability);
    handleCloseAvailability();
  };

  return (
    <>
      <WeekCalendar
        className='WeekCalendar WeekCalendar--appointment-block'
        initDate={initDate}
        tooltip
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        ItemRenderer={({ day, className, ...props }) => (
          <CalendarAvailabilityCell
            {...props}
            availability={availability}
            day={day}
            className={`${className} flex-row flex-md-column`}
            onClick={handleCellClick}
          />
        )}
      />
      <ManageAvailabilityIntervalsModal
        isOpened={isManageAvailabilityOpened}
        close={handleCloseAvailability}
        date={manageAvailabilityData.day?.valueOf()}
        data={manageAvailabilityData.intervals}
        minInterval={minIntervalMs}
        applyToDates={applyToDates}
        applyToWeekDays={handleApplyToWeekDays}
      />
      <ConfirmModal
        isOpened={isWeeklyAvailabilityConfirmationShown}
        close={handleCloseOverride}
        title='Weekly availability changes'
        text={
          <div>
            Your have specified different availability for the next{' '}
            {weeklyAvailabilityOverridesData?.overrideDates.length > 1
              ? 'dates'
              : 'date'}
            :{' '}
            {weeklyAvailabilityOverridesData?.overrideDates
              .map((i) => moment.utc(i).format('MMM D'))
              .join(', ')}
            <br />
            How do you want to proceed with{' '}
            {weeklyAvailabilityOverridesData?.overrideDates.length > 1
              ? 'these dates'
              : 'this date'}
            ?
          </div>
        }
        confirmCallback={handleConfirmOverride}
        cancelCallback={handleConfirmWithoutOverride}
        confirmBtnText='Override'
        cancelBtnText={`Don't change`}
      />
    </>
  );
};

export default CoachAppointmentBlockAvailabilityCalendar;
