import React, { ReactNode, useState, useEffect } from 'react';
import './ClientPage.styles.scss';
import Page from '../Page/Page';
import ClientPageHeader from '../../components/ClientPageHeader/ClientPageHeader';
import {
  ClientCustomizationStyles,
  CustomizationStyles
} from '../../components/CustomizeSidebar/CustomizeSidebar.helpers';
import { observer } from 'mobx-react';
import ClientSideMenu from '../../components/ClientSideMenu/ClientSideMenu';
import { ClientRoutesEnum } from '../../core/enums';
import { getRoutePath } from '../../core/globals';
import { useMedia, useStores } from '../../hooks';

interface iProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

const ClientPage: React.FC<iProps> = ({
  children,
  className = '',
  title = ''
}) => {
  const { rootStore } = useStores();
  const {
    userStore,
    clientBookingStore,
    coachInfo: coachAccountData
  } = rootStore;

  const isTablet = useMedia(['(min-width: 992px)'], [false], true);

  const [sideMenuOpened, setSideMenuOpened] = useState<boolean>(
    !isTablet && userStore.isSignedIn
  );

  useEffect(() => {
    setSideMenuOpened(!isTablet && userStore.isSignedIn);
  }, [isTablet, userStore.isSignedIn]);

  const handleSideMenuClose = () => {
    setSideMenuOpened(false);
  };

  return (
    <Page
      className={`
        ClientPage
        ${className}
        ${
          sideMenuOpened && !isTablet && !clientBookingStore.isBookingOpened
            ? 'Page--collapsed'
            : ''
        }
      `}
      title={title}
    >
      <CustomizationStyles coachData={rootStore.coachCustomizationInfo!} />
      <ClientCustomizationStyles
        coachData={rootStore.coachCustomizationInfo!}
      />
      {userStore.isSignedIn && userStore.isRegistrationCompleted && (
        <ClientPageHeader
          userData={userStore.userData}
          logoImg={rootStore.coachCustomizationInfo?.logoSrc}
          sideMenuOpened={sideMenuOpened}
          hideMenuButton={clientBookingStore.isBookingOpened}
          onSideMenuToggle={setSideMenuOpened}
          onLogout={userStore.signOut}
        />
      )}
      <ClientSideMenu
        onClose={handleSideMenuClose}
        menuList={[
          {
            name: 'Dashboard',
            iconName: 'MenuDashboardIcon',
            path: getRoutePath(ClientRoutesEnum.Dashboard)
          },
          {
            name: 'Schedule',
            iconName: 'MenuScheduleIcon',
            path: getRoutePath(ClientRoutesEnum.Calendar)
          },
          {
            name: 'Library',
            iconName: 'MenuTrainingIcon',
            path: getRoutePath(ClientRoutesEnum.Library)
          },
          {
            name: 'Billing',
            iconName: 'MenuInvoicingIcon',
            path: getRoutePath(ClientRoutesEnum.Billing)
          }
        ]}
        isOpened={sideMenuOpened && !clientBookingStore.isBookingOpened}
        coachInfo={coachAccountData}
        coachCustomizationInfo={rootStore.coachCustomizationInfo}
      />
      <div className='ClientPage__wrapper p-md-4 p-sm-3 p-2'>{children}</div>
    </Page>
  );
};

export default observer(ClientPage);
