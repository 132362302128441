import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { iRoute } from './routes';
import CoachSignIn from '../pages/CoachSignIn/CoachSignIn';
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import CoachCompleteRegistration from '../pages/CoachCompleteRegistration/CoachCompleteRegistration';
import Home from '../pages/Home/Home';

import SuperAdmin from '../core/SuperAdminRoutes';
import { CoachRoutesEnum } from './enums';
import TermsAndCondition from '../pages/TermsAndConditions/TermsAndConditions';
import CoachSignUp from '../pages/CoachSignUp/CoachSignUp';
import { loadIntercom } from './integrations';
import getGlobal from './globals';
import { useStores } from '../hooks';
import { isMobileDevice } from './helpers';
import CoachPortal from '../pages/CoachPortal/CoachPortal';
import CoachDashboardEditor from '../pages/CoachDashboardEditor/CoachDashboardEditor';
import CoachPageTile from '../pages/CoachPageEditor/CoachPageEditor';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import CoachConnectStripe from '../pages/CoachConnectStripe/CoachConnectStripe';

export const CoachAuthRoutes: iRoute[] = [
  {
    path: CoachRoutesEnum.SignIn,
    component: CoachSignIn,
    exact: true
  },
  {
    path: CoachRoutesEnum.Home,
    component: Home,
    exact: true
  },
  {
    path: CoachRoutesEnum.SignUp,
    component: CoachSignUp
  },
  {
    path: CoachRoutesEnum.ForgotPassword,
    component: ForgotPasswordPage
  },
  {
    path: CoachRoutesEnum.ResetPassword,
    component: ResetPasswordPage
  },
  {
    path: CoachRoutesEnum.TermsAndConditions,
    component: TermsAndCondition
  },
  { path: CoachRoutesEnum.Error, component: ErrorPage },
  {
    type: 'redirect',
    path: '*',
    redirectTo: CoachRoutesEnum.SignIn
  }
];

export const CoachPostAuthRoutes: iRoute[] = [
  {
    path: CoachRoutesEnum.CompleteRegistration,
    component: CoachCompleteRegistration
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: CoachRoutesEnum.CompleteRegistration
  }
];

export const CoachSignedInRoutes: iRoute[] = [
  {
    path: CoachRoutesEnum.Admin,
    component: SuperAdmin
  },
  {
    path: CoachRoutesEnum.CoachPageEditor,
    component: CoachPageTile
  },
  {
    path: CoachRoutesEnum.ClientPageEditor,
    component: CoachDashboardEditor
  },
  {
    path: CoachRoutesEnum.ConnectStripe,
    component: CoachConnectStripe
  },
  { path: CoachRoutesEnum.Error, component: ErrorPage },
  {
    path: '/',
    component: CoachPortal
  }
];

const CoachRoutes = () => {
  const {
    rootStore: { userStore }
  } = useStores();
  let routes: iRoute[] = [];
  const isAdmin = userStore.isAdmin;
  const { pathname } = useLocation();

  if (userStore.isSignedIn) {
    if (userStore.isDataLoaded) {
      if (userStore.isRegistrationCompleted) {
        routes = CoachSignedInRoutes.filter(
          (r) => (!isAdmin && r.path !== CoachRoutesEnum.Admin) || !!isAdmin
        );
      } else {
        routes = CoachPostAuthRoutes;
      }
    }
  } else {
    routes = CoachAuthRoutes;
    if (localStorage.getItem('signUpBackDoor') !== 'e6yJ98$,E/LDN2U') {
      routes = routes.filter((r) => r.path !== CoachRoutesEnum.SignUp);
    }
  }

  useEffect(() => {
    (async () => {
      const apiId = getGlobal('intercomApiKey');
      if (userStore.isSignedIn && userStore.isDataLoaded && !isMobileDevice()) {
        if (!window.Intercom) {
          await loadIntercom(apiId);
        }
        window.Intercom('boot', {
          app_id: apiId,
          email: userStore.userData.email,
          user_id: userStore.userData.id,
          created_at: Date.now(),
          pathname
        });
      } else if (!userStore.isSignedIn && window.Intercom) {
        window.Intercom('shutdown');
      }
    })();
  }, [userStore.isSignedIn, userStore.isDataLoaded]);

  useEffect(() => {
    if (userStore.isSignedIn && window.Intercom) {
      window.Intercom('update', {
        pathname
      });
    }
  }, [pathname, userStore.isSignedIn]);

  return (
    <Switch>
      {routes.map((route) => {
        if (route.type === 'redirect') {
          return (
            <Route key={`redirect-${route.path}`} exact path={route.path}>
              {(() => {
                return (
                  <div>
                    <Redirect to={route.redirectTo} />
                  </div>
                );
              })()}
            </Route>
          );
        }
        return <Route key={route.path} {...route} />;
      })}
    </Switch>
  );
};

export default observer(CoachRoutes);
