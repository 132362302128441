import moment from 'moment';
import React from 'react';
import { Invoice } from '../../core/types';
import { normalizePrice } from '../../core/helpers';
import './InvoiceCardClientBlock.styles.scss';
import TextEllipse from '../TextEllipse';
import { InvoiceTypeEnum, InvoiceBillingInterval } from '../../core/enums';

function getInvoiceBillingInterval(
  interval: InvoiceBillingInterval,
  value: number
) {
  let period = '';
  switch (interval) {
    case InvoiceBillingInterval.Daily:
      period = 'day';
      break;
    case InvoiceBillingInterval.Weekly:
      period = 'week';
      break;
    case InvoiceBillingInterval.Monthly:
      period = 'month';
      break;
    case InvoiceBillingInterval.Yearly:
      period = 'year';
      break;
  }

  return `${period}${value > 1 ? 's' : ''}`;
}
export interface iProps {
  invoiceData: Partial<Invoice>;
  amount?: number;
}

const InvoiceCardClientBlock: React.FC<iProps> = ({ invoiceData, amount }) => {
  const {
    client,
    dueDate,
    amount: total,
    startDate,
    type,
    intervalValue,
    billingInterval
  } = invoiceData;
  amount = amount || total;
  return (
    <div className='InvoiceCardClientBlock__group'>
      <p className='InvoiceCardClientBlock__title InvoiceCardClientBlock__group-item pb-2 CZ__invoice-field-secondary'>
        INVOICE FOR
      </p>
      <p className='InvoiceCardClientBlock__title InvoiceCardClientBlock__group-item pb-2 CZ__invoice-field-secondary'>
        AMOUNT DUE
      </p>
      <div className='InvoiceCardClientBlock__client-name InvoiceCardClientBlock__group-item pb-2 CZ__invoice-field'>
        <TextEllipse text={`${client?.firstName} ${client?.lastName}`}>
          <span>
            {client?.firstName} {client?.lastName}
          </span>
        </TextEllipse>
      </div>
      <p className='InvoiceCardClientBlock__client-price InvoiceCardClientBlock__group-item pb-2 CZ__invoice-field'>
        ${amount ? normalizePrice(amount) : 0}
      </p>
      <p className=' InvoiceCardClientBlock__client-address InvoiceCardClientBlock__group-item pb-4 CZ__invoice-field-secondary'>
        {/* {client?.address} */} &nbsp;
      </p>
      <div className='InvoiceCardClientBlock__client-date InvoiceCardClientBlock__group-item pb-4 CZ__invoice-field-secondary'>
        <p>Due on {moment.utc(dueDate).format('MMM D, YYYY')}</p>
        <p>Issued on {moment.utc(startDate).format('MMM D, YYYY')}</p>
        {type === InvoiceTypeEnum.Recurring && (
          <p>
            Billing interval:{' '}
            {intervalValue === 1
              ? InvoiceBillingInterval[billingInterval]
              : `${intervalValue} ${getInvoiceBillingInterval(
                  billingInterval,
                  intervalValue
                )}`}
          </p>
        )}
      </div>
    </div>
  );
};

export default InvoiceCardClientBlock;
