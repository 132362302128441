import React, { useState, useEffect } from 'react';
import './SharingHistory.styles.scss';
import { FileResponseViewModelWithExt } from '../../core/types';
import CoachLibrary from '../../containers/CoachLibrary/CoachLibrary';
import SharingHistoryItem from './SharingHistoryItem';
import {
  getCoachFilesLibrary,
  getCoachFilesLibraryFileById
} from '../../core/api';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import Thumbnail from '../../components/Thumbnail';
import { FileResponseViewModel } from '../../core/backend/api';
import EditSharingModal from '../../components/Modals/EditSharingModal/EditSharingModal';
import EmptyIcon from '../../icons/share_large.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import { filterForSearch } from './SharingHistory.helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';
import { getLinkType } from '../CoachLibraryAll/CoachLibraryAll.helpers';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react';
import VideoModal from '../../components/Modals/VideoModal/VideoModal';
import PageTitle from '../../components/PageTitle/PageTitle';

const SharingHistory: React.FC = () => {
  const {
    rootStore: {
      contactsStore: { contacts }
    }
  } = useStores();
  const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
  const [activeFile, setActiveFile] = useState<
    FileResponseViewModelWithExt | undefined
  >(undefined);
  const [files, setFiles] = useState<FileResponseViewModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVideoModalOpened, setIsVideoModalOpened] = useState<boolean>(false);

  const {
    searchText,
    handleSearch,
    searchedItems: searchedFiles
  } = useSearchInput(files, filterForSearch);

  useEffect(() => {
    setIsLoading(true);
    getCoachFilesLibrary()
      .then((filesList) => {
        setFiles(filesList);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onShare = (id: number) => {
    getCoachFilesLibraryFileById(id).then((res) => {
      setFiles((prevList) =>
        prevList.map((file) => {
          if (file.id === id) return res;
          return file;
        })
      );
    });
  };
  const handleVideoModalOpen = (file: FileResponseViewModelWithExt) => {
    setActiveFile(file);
    setIsVideoModalOpened(true);
  };

  const handleOpenSharingModal = (file: FileResponseViewModelWithExt) => {
    setActiveFile(file);
    setIsShareModalOpened(true);
  };

  return (
    <CoachLibrary className='SharingHistory'>
      <PageTitle title='Sharing History' />
      <GlobalLoader isLoading={isLoading} />
      <div className='SharingHistory__header'>
        <SearchInput onSearch={handleSearch} searchText={searchText} />
      </div>

      {searchedFiles.length > 0 ? (
        <div className='SharingHistory__list'>
          {searchedFiles.map((file) => {
            return (
              <SharingHistoryItem
                key={file.id}
                file={file}
                onOpenSharingModal={handleOpenSharingModal}
                contacts={contacts}
                onOpenVideoModal={handleVideoModalOpen}
              />
            );
          })}
        </div>
      ) : (
        <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
          <EmptyState
            text='There are no sharing files here'
            icon={<EmptyIcon />}
            className='mb-4 mt-2'
          />
        </div>
      )}
      <EditSharingModal
        isOpened={isShareModalOpened}
        close={() => setIsShareModalOpened(false)}
        FileIcon={
          <Thumbnail
            type={
              activeFile?.fileLink
                ? getLinkType(activeFile.fileLink)
                : activeFile?.ext
            }
          />
        }
        file={activeFile}
        contacts={contacts}
        onConfirm={onShare}
      />
      <VideoModal
        isOpened={isVideoModalOpened}
        close={() => setIsVideoModalOpened(false)}
        file={activeFile}
      />
    </CoachLibrary>
  );
};

export default observer(SharingHistory);
