import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import InvoiceCard from '../../components/InvoiceCard/InvoiceCard';
import {
  getClientInfoByEmail,
  getClientInvoiceByNumber,
  getPublicFile
} from '../../core/api';
import {
  ClientRoutesEnum,
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import PageTitle from '../../components/PageTitle/PageTitle';
import { getRoutePath } from '../../core/globals';
import { Invoice } from '../../core/types';
import { useStores } from '../../hooks';
import ArrowBack from '../../icons/arrow-left.svg';
import './ClientInvoiceView.styles.scss';
import { Elements } from '@stripe/react-stripe-js';
import { elementsOptions } from '../../components/Modals/ClientPayInvoice/ClientPayInvoice.helpers';
import { StripeElementsOptions } from '@stripe/stripe-js';
import ClientInvoiceStripeActions, {
  ClientInvoiceStripeActionsRef
} from './ClientInvoiceStripeActions';

const ClientInvoiceView = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { rootStore } = useStores();
  const {
    coachInfo: coachAccountData,
    notificationStore,
    userStore
  } = rootStore;
  const { number: num } = useParams<{ number: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localData, setLocalData] = useState<Invoice>(null);
  const actionsRef = useRef<ClientInvoiceStripeActionsRef>();

  const params = new URLSearchParams(search);
  const openRetryPayment = params.get('retry') === 'true';
  const openChangePaymentMethod = params.get('change') === 'true';

  const code = new URLSearchParams(search).get('code');
  const email = code
    ? // eslint-disable-next-line
      window.atob(code).replace(/[^a-z\d\.\@\_\-\+]/gi, '')
    : '';

  const loadData = useCallback(
    (withLoader = true) => {
      setIsLoading(withLoader);
      getClientInvoiceByNumber(num)
        .then((res: Invoice) => {
          setLocalData(res);
        })
        .catch((err) => {
          if (err.status === 404) {
            notificationStore.addNotification({
              text: 'Invoice not found',
              type: NotificationTypesEnum.Error
            });
            history.push(getRoutePath(ClientRoutesEnum.Billing));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [history, notificationStore, num]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!userStore.isSignedIn) {
      if (!email) {
        handleGoToCoachPage();
      } else {
        if (!!localData) {
          getClientInfoByEmail(email)
            .then((response) => {
              if (response.id !== localData.client.clientId) {
                handleGoToCoachPage();
              }
            })
            .catch(() => {
              handleGoToCoachPage();
            });
        }
      }
    }
  }, [email, localData, userStore.isSignedIn, history]);

  useEffect(() => {
    if (openRetryPayment && !!localData) {
      if (
        localData.type === InvoiceTypeEnum.Recurring &&
        localData.status === InvoiceStatusEnum.uncollectible
      ) {
        handleOpenRetryPayment();
      }
      params.delete('retry');
      history.replace(`${pathname}?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRetryPayment, localData, history, pathname]);

  useEffect(() => {
    if (openChangePaymentMethod && !!localData) {
      if (
        localData.type === InvoiceTypeEnum.Recurring &&
        (localData.status === InvoiceStatusEnum.paid ||
          localData.status === InvoiceStatusEnum.uncollectible)
      ) {
        handleChangePaymentMethod();
      }
      params.delete('change');
      history.replace(`${pathname}?${params.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openChangePaymentMethod, localData, history, pathname]);

  const handleBack = () => {
    if (userStore.isSignedIn) {
      history.push(getRoutePath(rootStore.prevUrl || ClientRoutesEnum.Billing));
    }
  };

  const handleFailAction = () => {
    loadData(false);
    rootStore.getCoachInfo();
  };

  const handleGoToCoachPage = () => {
    history.replace(getRoutePath(ClientRoutesEnum.CoachPage));
  };

  const handleChangePaymentMethod = () => {
    actionsRef.current.onChangeMethod();
  };

  const handleOpenRetryPayment = () => {
    actionsRef.current.onRetry();
  };

  const handleCancelRecurringPayment = () => {
    actionsRef.current.onCancelSubscription();
  };

  const renderButtons = () => {
    if (!localData) {
      return null;
    }
    if (localData.type === InvoiceTypeEnum.Recurring) {
      if (
        localData?.status === InvoiceStatusEnum.open ||
        localData?.status === InvoiceStatusEnum.late
      ) {
        return null;
      } else if (localData?.status === InvoiceStatusEnum.paid) {
        return (
          <div className='d-flex flex-column align-items-center'>
            <Button
              className='mt-2 CZ__invoice-primary-bg'
              variations={['full-width']}
              handleClick={handleChangePaymentMethod}
            >
              Change payment method
            </Button>
            <Button
              className='mt-2 ml-0'
              variations={['naked']}
              handleClick={handleCancelRecurringPayment}
            >
              Cancel recurring payment
            </Button>
          </div>
        );
      } else if (localData?.status === InvoiceStatusEnum.uncollectible) {
        return (
          <div className='d-flex flex-column align-items-center'>
            <div className='row mx-n2 w-100'>
              {localData?.paymentMethod && (
                <div className='col-12 col-sm-6 px-2'>
                  <Button
                    className='CZ__invoice-primary-bg'
                    variations={['full-width']}
                    handleClick={handleOpenRetryPayment}
                  >
                    Retry payment
                  </Button>
                </div>
              )}
              <div
                className={`col-12 px-2 ${
                  !!localData?.paymentMethod ? 'col-sm-6' : ''
                }`}
              >
                <Button
                  className='CZ__invoice-primary-bg'
                  variations={['full-width']}
                  handleClick={handleChangePaymentMethod}
                >
                  Change payment method
                </Button>
              </div>
            </div>
            <Button
              className='mt-2 ml-0'
              variations={['naked']}
              handleClick={handleCancelRecurringPayment}
            >
              Cancel recurring payment
            </Button>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      if (
        localData?.status !== InvoiceStatusEnum.open &&
        localData?.status !== InvoiceStatusEnum.late &&
        localData?.status !== InvoiceStatusEnum.uncollectible
      ) {
        // override button
        return <></>;
      } else {
        return null;
      }
    }
  };

  return (
    <div className='ClientInvoiceView mx-auto'>
      <PageTitle title={`Invoice - ${localData?.number}`} />
      <GlobalLoader isLoading={isLoading} />
      {!userStore.isSignedIn && rootStore.coachCustomizationInfo.logoSrc ? (
        <img
          src={getPublicFile(rootStore.coachCustomizationInfo.logoSrc)}
          alt='Logo'
          className='CoachPage__logo'
        />
      ) : null}
      <div className='ClientInvoiceView__container'>
        <Button
          className='mb-2'
          handleClick={handleBack}
          variations={['naked', 'ClientInvoiceView']}
          type='button'
        >
          <ArrowBack />
          <span>Back</span>
        </Button>
        <InvoiceCard
          link={getRoutePath(
            ClientRoutesEnum.InvoicePay.replace(':number', num)
          )}
          coachData={coachAccountData}
          invoiceData={localData}
          isStripeConnected={coachAccountData.isStripeConnected}
          buttonsBlock={renderButtons()}
          isClient
        />

        <Elements
          stripe={rootStore.coachStripePromise}
          options={elementsOptions as StripeElementsOptions}
        >
          <ClientInvoiceStripeActions
            invoice={localData}
            ref={actionsRef}
            onSuccess={handleBack}
            onFail={handleFailAction}
          />
        </Elements>
      </div>
    </div>
  );
};

export default ClientInvoiceView;
