import {
  AppointmentBlock,
  Availability,
  Checker,
  iFormConfigItem
} from '../../core/types';
import { LocationTypeEnum } from '../../core/enums';
import {
  isNotEmpty,
  isDurationValid,
  isInputValid,
  getInputErrors,
  isDataValid,
  isMaxValueValid,
  isMinValueValid,
  isMaxlengthValid
} from '../../core/formValidation';
import { isAvailabilityNotEmpty } from '../../core/normalization';
import {
  getCurrentDayTimeStamp,
  getMilliseconds,
  getDayAvailability
} from '../../core/helpers';
import moment from 'moment';

const isAvailabilityValid = (value: Availability, data: AppointmentBlock) => {
  return (
    !value.singleDates.filter(
      (sd) =>
        sd.interval.timeEndMs - sd.interval.timeStartMs <
        getMilliseconds(data.durationMinutes, 'minutes') +
          data.schedulesBuffer.beforeMs
    ).length &&
    !value.weekly.filter(
      (w) =>
        w.interval.timeEndMs - w.interval.timeStartMs <
        getMilliseconds(data.durationMinutes, 'minutes') +
          data.schedulesBuffer.beforeMs
    ).length
  );
};

export const MAX_NAME_LENGTH = 255;
export const MAX_LINK_LENGTH = 255;

const isNameShortEnough = isMaxlengthValid(MAX_NAME_LENGTH);
const isLinkShortEnough = isMaxlengthValid(MAX_LINK_LENGTH);

const FormConfig: iFormConfigItem[] = [
  { field: 'name', checkIsValid: [isNotEmpty, isNameShortEnough] },
  { field: 'location', checkIsValid: [isNotEmpty] },
  { field: 'locationType', checkIsValid: [isNotEmpty] },
  { field: 'link', checkIsValid: [isNotEmpty, isLinkShortEnough] },
  {
    field: 'durationMinutes',
    checkIsValid: [isMinValueValid(5), isMaxValueValid(480), isDurationValid]
  },
  {
    field: 'price',
    checkIsValid: [isNotEmpty, isMinValueValid(0), isMaxValueValid(999999)]
  },
  {
    field: 'availability',
    checkIsValid: [isAvailabilityNotEmpty, isAvailabilityValid]
  }
];

function getFormConfig(data: AppointmentBlock) {
  return FormConfig.filter(
    (c) => data.locationType !== LocationTypeEnum.Zoom || c.field !== 'location'
  );
}

export function isFormInputValid(inputField: string, data: AppointmentBlock) {
  const config = getFormConfig(data);
  return isInputValid(config)(inputField, data);
}

export function isFormDataValid(data: AppointmentBlock) {
  const config = getFormConfig(data);
  return isDataValid(config)(data);
}

export function getFormInputErrors(inputField: string, data: any) {
  return getInputErrors(getFormConfig(data), (checker: Checker) => {
    switch (checker) {
      case FormConfig.find((c) => c.field === 'price').checkIsValid[1]: {
        return 'minimum amount is 0';
      }
      case FormConfig.find((c) => c.field === 'price').checkIsValid[2]: {
        return 'maximum amount is 999 999';
      }
      case FormConfig.find((c) => c.field === 'availability').checkIsValid[0]: {
        return 'Add at least one time interval to your availability calendar';
      }
      case FormConfig.find((c) => c.field === 'availability').checkIsValid[1]: {
        return 'Some interval durations in your availability are less than your appointment block duration combined with buffers. Your clients will not be able to schedule appointments with you. Please, check your settings.';
      }
      case isNameShortEnough: {
        return `should have at most ${MAX_NAME_LENGTH} characters`;
      }
      case isLinkShortEnough: {
        return `should have at most ${MAX_LINK_LENGTH} characters`;
      }
      case FormConfig.find((c) => c.field === 'durationMinutes')
        .checkIsValid[0]: {
        return "minimum meeting duration can't be less than 5 min";
      }
      case FormConfig.find((c) => c.field === 'durationMinutes')
        .checkIsValid[1]: {
        return "maximum meeting duration can't be more than 480 min or 8 hours";
      }
      case FormConfig.find((c) => c.field === 'durationMinutes')
        .checkIsValid[2]: {
        const duration = data[inputField];
        const floorNearestValue = Math.floor(+duration / 5) * 5;
        const ceilNearestValue = Math.ceil(+duration / 5) * 5;
        return floorNearestValue
          ? `Please enter a valid value, the two nearest valid values are ${floorNearestValue} and ${ceilNearestValue}`
          : `Please enter a valid value, the nearest valid value is ${ceilNearestValue}`;
      }
      default:
        return undefined;
    }
  })(inputField, data);
}

export function getDefaultData(): AppointmentBlock {
  return {
    name: '',
    imageId: '',
    location: '',
    membersCountLimit: 1,
    description: '',
    link: '',
    durationMinutes: 30,
    availability: {
      singleDates: [],
      weekly: [],
      excludeDates: []
    },
    isPrivate: false,
    price: '0',
    schedulesBuffer: {
      afterMs: 0,
      beforeMs: 0
    },
    remindBeforeMs: 60 * 60 * 1000,
    thumbnailImageId: '',
    croppedArea: '',
    locationType: LocationTypeEnum.Custom,
    showLocationWhileBooking: true
  };
}

export function getAvailabilityStarts(availability: Availability): number {
  const { singleDates, weekly } = availability;
  const day = moment.utc(getCurrentDayTimeStamp());

  if (!singleDates.length && !weekly.length) {
    return day.valueOf();
  }
  if (!weekly.length && singleDates.length) {
    return singleDates.map((s) => s.date).sort()[0];
  }
  let hasAvailability = false;
  while (!hasAvailability) {
    hasAvailability = !!getDayAvailability(availability, day).length;
    if (!hasAvailability) {
      day.add(1, 'day');
    }
  }
  return day.valueOf();
}

export const buffers = [
  {
    id: '1',
    value: 0,
    name: '0 min'
  },
  {
    id: '2',
    value: getMilliseconds(5, 'minutes'),
    name: '5 min'
  },
  {
    id: '3',
    value: getMilliseconds(10, 'minutes'),
    name: '10 min'
  },
  {
    id: '4',
    value: getMilliseconds(15, 'minutes'),
    name: '15 min'
  },
  {
    id: '5',
    value: getMilliseconds(30, 'minutes'),
    name: '30 min'
  }
];
export const reminders = [
  {
    id: '1',
    value: 0,
    name: 'Not set'
  },
  {
    id: '2',
    value: getMilliseconds(5, 'minutes'),
    name: '5 min'
  },
  {
    id: '3',
    value: getMilliseconds(15, 'minutes'),
    name: '15 min'
  },
  {
    id: '4',
    value: getMilliseconds(30, 'minutes'),
    name: '30 min'
  },
  {
    id: '5',
    value: getMilliseconds(60, 'minutes'),
    name: '1 hour'
  }
];
