import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Modal from '../../Modal/Modal';
import { subTitles } from './MoreClientsForAppointmentModal.helpers';
import Loader from '../../Loader/Loader';
import ModalHeader from '../../ModalHeader/ModalHeader';
import StepsVisualizer from '../../StepsVisualizer/StepsVisualizer';
import { Appointment, AppointmentBlock } from '../../../core/types';
import InfoBlock from '../../InfoBlock/InfoBlock';
import SummaryStep from '../../SummaryStep/SummaryStep';
import InviteClients from '../../InviteClients/InviteClients';
import { updateAppointment } from '../../../core/api';
import Button from '../../Button/Button';
import '../AppointmentModal/AppointmentModal.styles.scss';
import { useStores } from '../../../hooks';
import { ScheduleItemType } from '../../../core/enums';

interface iProps {
  isOpened: boolean;
  close: () => void;
  data: Appointment;
  appointmentBlockData: AppointmentBlock;
}

const MoreInvitesForAppointmentModal: React.FC<iProps> = ({
  isOpened,
  close,
  data,
  appointmentBlockData
}) => {
  const {
    rootStore: { incrementChangesCounter, userStore }
  } = useStores();
  const [invites, setInvites] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickNext = () => setCurrentStep(currentStep + 1);

  useEffect(() => {
    if (isOpened) {
      setInvites(data.invites);
      setCurrentStep(1);
    }
  }, [isOpened]);

  const update = () => {
    updateAppointment({
      ...data,
      invites
    }).then(() => {
      setIsLoading(false);
      incrementChangesCounter();
      handleClickNext();
    });
  };

  const handleSave = () => {
    setIsLoading(true);
    update();
  };

  const steps = [
    null,
    <>
      <div className='AppointmentModal__step'>
        <div className='AppointmentModal__columns'>
          <div className='AppointmentModal__column'>
            <InfoBlock
              data={{
                id: appointmentBlockData.id,
                type: ScheduleItemType.Appointment,
                userName: `${userStore.firstName} ${userStore.lastName}`,
                name: appointmentBlockData.name,
                membersCountLimitString:
                  appointmentBlockData.membersCountLimit === 1
                    ? 'One-on-One'
                    : 'Group',
                durationMinutes: appointmentBlockData!.durationMinutes,
                price: data.price,
                timeZone: userStore.timeZone,
                showCoachAvatar: false,
                description: appointmentBlockData!.description,
                remindBefore: data.remindBefore,
                isRemindBeforeDisabled: true
              }}
            />
          </div>
          <div className='AppointmentModal__column'>
            <InviteClients
              invites={invites}
              setInvites={setInvites}
              initialInvites={data.invites}
              autoFocus={true}
            />
          </div>
        </div>
      </div>
      <div className='AppointmentModal__footer AppointmentModal__footer--items-to-right'>
        <Button className='button__md-width' handleClick={handleSave}>
          Next
        </Button>
      </div>
    </>,
    <SummaryStep
      greetingMessage='Congratulations! The appointment is updated.'
      link={appointmentBlockData.location}
    />
  ];

  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{
        // minWidth: width_options[currentStep],
        // maxWidth: width_options[currentStep],
        width: 'auto'
      }}
    >
      {isLoading && <Loader />}
      <div className='AppointmentModal'>
        <ModalHeader>
          <h2>Edit Appointment</h2>
          <h3>{subTitles[currentStep]}</h3>
        </ModalHeader>
        <StepsVisualizer currentStep={currentStep} stepsTotal={1} />
        {steps[currentStep]}
      </div>
    </Modal>
  );
};

export default observer(MoreInvitesForAppointmentModal);
