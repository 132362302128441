import React from 'react';
import './ResetLinkExpired.styles.scss';
import Modal from '../Modal/Modal';
import AlertIcon from '../../icons/huge-alert.svg';
import ModalHeader from '../ModalHeader/ModalHeader';
import Button from '..//Button/Button';

interface iProps {
  close: () => void;
  isOpened: boolean;
  showSignIn: () => void;
  showForgot: () => void;
}

const ResetLinkExpired: React.FC<iProps> = ({
  close,
  isOpened,
  showSignIn,
  showForgot
}) => {
  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 648 }}>
      <div className='ResetLinkExpired'>
        <ModalHeader>
          <h2>Reset link was expired</h2>
          <h4>Please reset your password again.</h4>
        </ModalHeader>
        <div className='ResetLinkExpired__icon'>
          <AlertIcon />
        </div>
        <div className='ResetLinkExpired__footer'>
          <Button
            className='button__inversed button__half-width button__ResetLinkExpired'
            handleClick={() => {
              showSignIn();
              close();
            }}
          >
            Sign in
          </Button>
          <Button
            className='button__half-width button__ResetLinkExpired'
            handleClick={() => {
              showForgot();
              close();
            }}
          >
            Reset password
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetLinkExpired;
