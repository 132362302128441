import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const PhoneInputWrapper = (Wrapped: any) => {
  return class extends React.Component<PhoneInputProps> {
    setRef = (c: { dropdownContainerRef: HTMLDivElement }) => {
      if (c && c.dropdownContainerRef) {
        c.dropdownContainerRef
          .querySelector('.selected-flag')
          .setAttribute('tabindex', '-1');
      }
    };

    render() {
      const props: PhoneInputProps = {
        ...this.props,
        masks: { gb: '.. .... ....' },
        placeholder: this.props.placeholder || '1 541 754 3010',
        countryCodeEditable:
          this.props.countryCodeEditable !== undefined
            ? this.props.countryCodeEditable
            : false,
        buttonClass: 'PhoneInputButton',
        dropdownClass: 'PhoneInputDropdown Scrollbar',
        preferredCountries: this.props.preferredCountries || ['us', 'ca'],
        country: this.props.country || 'us',
        inputClass: `PhoneInputInput ${this.props.inputClass || ''}`
      };
      return <Wrapped {...props} ref={this.setRef} />;
    }
  };
};

export default PhoneInputWrapper(PhoneInput);
