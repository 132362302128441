import React, { useEffect, useState } from 'react';
import CoachRoutes from './CoachRoutes';
import ClientRoutes from './ClientRoutes';
import { observer } from 'mobx-react';
import { useStores } from '../hooks';
import { useLocation } from 'react-router-dom';
import getGlobal from './globals';
import ReactGA from 'react-ga';

export interface iRoute {
  type?: string;
  path: string;
  component?: React.ComponentType<any>;
  exact?: boolean;
  redirectTo?: string;
}

const Routes = () => {
  const { rootStore } = useStores();
  const {
    userStore: { id, isSignedIn, isDataLoaded }
  } = rootStore;
  const { pathname } = useLocation();

  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (isInit) {
      ReactGA.pageview(pathname);
    }
  }, [pathname, isInit]);

  useEffect(() => {
    if (!isInit && (!isSignedIn || isDataLoaded)) {
      ReactGA.initialize(getGlobal('gaKey'), {
        titleCase: false,
        gaOptions: {
          userId: id
        }
      });
      setIsInit(true);
    }
  }, [isSignedIn, isDataLoaded, isInit]);

  useEffect(() => {
    if (isInit) {
      ReactGA.set({ userId: id });
    }
  }, [id, isInit]);

  if (rootStore.flow === 'client') {
    return <ClientRoutes />;
  } else if (rootStore.flow === 'coach') {
    return <CoachRoutes />;
  }
  return null;
};

export default observer(Routes);
