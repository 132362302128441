import React, { useEffect, useState, useCallback } from 'react';
import './ClientPayInvoice.styles.scss';
import { Invoice } from '../../../core/types';
import { useStores } from '../../../hooks';
import { Elements } from '@stripe/react-stripe-js';
import GlobalLoader from '../../GlobalLoader/GlobalLoader';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  getClientInfoByEmail,
  getClientInvoiceByNumber,
  getPublicFile
} from '../../../core/api';
import ClientPayInvoiceWizard from '../../ClientPayInvoiceWizard/ClientPayInvoiceWizard';
import { getRoutePath } from '../../../core/globals';
import { ClientRoutesEnum, NotificationTypesEnum } from '../../../core/enums';
import { elementsOptions } from './ClientPayInvoice.helpers';
import { StripeElementsOptions } from '@stripe/stripe-js';
import PageTitle from '../../PageTitle/PageTitle';
import Button from '../../Button/Button';
import ClientSignInModal from '../ClientSignInModal/ClientSignInModal';
import ClientSignUpModal from '../ClientSignUpModal/ClientSignUpModal';
import ClientForgotPasswordModal from '../ClientForgotPasswordModal/ClientForgotPasswordModal';
import { usePrevious } from '../../../hooks/usePrevious';

const ClientPayInvoice = () => {
  const { rootStore } = useStores();
  const { notificationStore, userStore } = rootStore;
  const wasSignedIn = usePrevious(userStore.isSignedIn);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localData, setLocalData] = useState<Invoice>(null);
  const [isSignInPopupOpened, setIsSignInPopupOpened] = useState<boolean>(
    false
  );
  const [isSignUpPopupOpened, setIsSignUpPopupOpened] = useState<boolean>(
    false
  );
  const [isForgotPopupOpened, setIsForgotPopupOpened] = useState<boolean>(
    false
  );
  const { number: num } = useParams<{ number: string }>();
  const { search } = useLocation();
  const history = useHistory();

  const code = new URLSearchParams(search).get('code');

  const email = code
    ? // eslint-disable-next-line
      window.atob(code).replace(/[^a-z\d\.\@\_\-\+]/gi, '')
    : '';

  useEffect(() => {
    if (!userStore.isSignedIn) {
      if (!email) {
        handleGoToCoachPage();
      } else {
        if (!!localData) {
          getClientInfoByEmail(email)
            .then((response) => {
              if (response.id !== localData.client.clientId) {
                handleGoToCoachPage();
              }
            })
            .catch(() => {
              handleGoToCoachPage();
            });
        }
      }
    }
  }, [email, localData, userStore.isSignedIn, history]);

  useEffect(() => {
    if (userStore.isSignedIn && wasSignedIn === false) {
      loadData();
    }
  }, [userStore.isSignedIn, wasSignedIn]);

  const loadData = useCallback(() => {
    return getClientInvoiceByNumber(num)
      .then((res: Invoice) => {
        setLocalData(res);
        return res;
      })
      .catch((err) => {
        if (err.status === 404) {
          notificationStore.addNotification({
            text: 'Invoice not found',
            type: NotificationTypesEnum.Error
          });
          // history.push(getRoutePath(ClientRoutesEnum.Invoices));
          history.push(getRoutePath(ClientRoutesEnum.Billing));
        }
      });
  }, [num]);

  useEffect(() => {
    setIsLoading(true);
    loadData().finally(() => {
      setIsLoading(false);
    });
  }, [loadData, notificationStore, history]);

  const handleClose = () => {
    history.push(getRoutePath(ClientRoutesEnum.Billing));
  };

  const handleGoToCoachPage = () => {
    history.replace(getRoutePath(ClientRoutesEnum.CoachPage));
  };

  const handleShowForgot = () => {
    setIsForgotPopupOpened(true);
    setIsSignInPopupOpened(false);
  };

  const handleShowSignIn = () => {
    setIsSignUpPopupOpened(false);
    setIsSignInPopupOpened(true);
  };

  const handleReload = () => {
    loadData();
    rootStore.getCoachInfo();
  };

  return (
    <div className='ClientPayInvoice'>
      <PageTitle title={`Invoice - ${localData?.number}`} />
      <GlobalLoader isLoading={isLoading} />
      {!userStore.isSignedIn && rootStore.coachCustomizationInfo.logoSrc ? (
        <img
          src={getPublicFile(rootStore.coachCustomizationInfo.logoSrc)}
          alt='Logo'
          className='CoachPage__logo'
        />
      ) : null}
      <Elements
        stripe={rootStore.coachStripePromise}
        options={elementsOptions as StripeElementsOptions}
      >
        <ClientPayInvoiceWizard
          invoiceData={localData}
          email={email}
          onClose={handleClose}
          onReload={handleReload}
          className='mx-auto'
        >
          {!userStore.isSignedIn && (
            <div className='d-flex align-items-center justify-content-center mt-3'>
              <Button
                handleClick={() => setIsSignUpPopupOpened(true)}
                variations={['naked']}
                className='mt-0 mx-1'
              >
                Sign up
              </Button>
              <span>or</span>
              <Button
                handleClick={() => setIsSignInPopupOpened(true)}
                variations={['naked']}
                className='mt-0 mx-1'
              >
                Sign in
              </Button>
            </div>
          )}
        </ClientPayInvoiceWizard>
      </Elements>
      <ClientSignInModal
        isOpened={isSignInPopupOpened}
        close={() => setIsSignInPopupOpened(false)}
        showForgot={handleShowForgot}
      />
      <ClientSignUpModal
        isOpened={isSignUpPopupOpened}
        email={email}
        close={() => setIsSignUpPopupOpened(false)}
        showSignIn={handleShowSignIn}
      />
      <ClientForgotPasswordModal
        isOpened={isForgotPopupOpened}
        close={() => setIsForgotPopupOpened(false)}
      />
    </div>
  );
};

export default ClientPayInvoice;
