import React, {
  useState,
  useEffect,
  FormEvent,
  useContext,
  useCallback,
  useRef
} from 'react';
import moment from 'moment';
import './CoachAppointmentBlock.styles.scss';
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox';
import {
  getDefaultData,
  isFormInputValid,
  getFormInputErrors,
  isFormDataValid,
  buffers,
  reminders,
  getAvailabilityStarts
} from './CoachAppointmentBlock.helpers';
import FormInput from '../../components/FormInput/FormInput';
import {
  checkPageNameExist,
  updateAppointmentBlock,
  getAppointmentBlock,
  createAppointmentBlock,
  getPublicFile
} from '../../core/api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { AppointmentBlock } from '../../core/types';
import {
  createLink,
  copyToClipboard,
  getOffsetTop,
  beautifyTimeZone,
  getMilliseconds,
  isMobileDevice,
  getServerModelValidation
} from '../../core/helpers';
import WYSIWYGEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import getGlobal from '../../core/globals';
import { CoachRoutesEnum, LocationTypeEnum } from '../../core/enums';
import { observer } from 'mobx-react';
import NoImage from '../../icons/no-image.svg';
import { formatValueAsPrice } from '../../core/formValidation';
import Button from '../../components/Button/Button';
import ButtonWithActiveState from '../../components/ButtonWithActiveState/ButtonWithActiveState';
import InputCustom from '../../components/InputCustom/InputCustom';
import Select from '../../components/Select/Select';
import FileUploader from '../../components/FileUploader/FileUploader';
import { AppScrollbarsContext } from '../../App';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { useCropper } from '../../components/ImageCropper/useCropper';
import ImageCropper from '../../components/ImageCropper/ImageCropper';
import ArrowIcon from '../../icons/arrow-left.svg';
import Location, { iLocation } from '../../components/Location/Location';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import {
  getZoomConnectUrl,
  restoreDataAfterStripeConnect,
  restoreDataAfterZoomConnect,
  storeDataBeforeStripeConnect,
  storeDataBeforeZoomConnect,
  STRIPE_CONNECTED_KEY,
  ZOOM_CONNECTED_KEY
} from '../../core/integrations';
import { useFeatures, useStores } from '../../hooks';
import CoachAppointmentBlockAvailabilityCalendar from './CoachAppointmentBlockAvailabilityCalendar';
import PageTitle from '../../components/PageTitle/PageTitle';
import StripeConnectHint from '../../components/StripeConnectHint/StripeConnectHint';

let checkLinkTimer: number;

const durations = [15, 30, 45, 60];

const MAX_IMAGE_SIZE = 3 * 1024 * 1024;
const MAX_FILE_TYPES = ['png', 'jpeg', 'svg'];

const CoachAppointmentBlock = () => {
  const {
    rootStore: { notificationStore, userStore }
  } = useStores();

  const { zoomEnabled } = useFeatures();

  const { blockId } = useParams<{ blockId: string }>();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const scroll = useContext(AppScrollbarsContext);
  const [localData, setLocalData] = useState(getDefaultData());
  const [availabilityInitDate, setAvailabilityInitDate] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isLocationTypeChanged, setIsLocationTypeChanged] = useState(false);
  const [isPageNameExist, setIsPageNameExist] = useState(false);
  const [customDuration, setCustomDuration] = useState<any>('');
  const [selectedBefore, setSelectedBefore] = useState<string>(buffers[0].id);
  const [selectedAfter, setSelectedAfter] = useState<string>(buffers[0].id);
  const [reminderTime, setReminderTime] = useState<string>(reminders[4].id);
  const [hasBuffers, setHasBuffers] = useState<boolean>(false);
  const initialLinkRef = useRef<string>('');
  const [initialLocation, setInitialLocation] = useState<iLocation>();

  const {
    isStripeConnected = false,
    siteName = '',
    timeZone = '',
    isZoomConnected = false,
    id: userId
  } = userStore || {};

  const params = new URLSearchParams(search);
  const isAfterZoomConnected = !!params.get(ZOOM_CONNECTED_KEY);
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);
  const isAfterIntegrationConnectRef = useRef(false);

  const beforeBuffer = buffers.find((buffer) => buffer.id === selectedBefore);
  const afterBuffer = buffers.find((buffer) => buffer.id === selectedAfter);

  const handleChange = useCallback((value: Partial<AppointmentBlock>) => {
    setLocalData((prevLocalData) => ({
      ...prevLocalData,
      ...value
    }));
  }, []);

  const handleChangeField = useCallback(
    (field: string) => (value: any) => {
      setLocalData((prevLocalData) => ({
        ...prevLocalData,
        [field]: value
      }));
    },
    []
  );

  const getAppointmentLinkPrefix = () => `${getGlobal('domain')}/${siteName}/`;

  const handleCopyLink = () => {
    copyToClipboard(`${getAppointmentLinkPrefix()}${localData.link}`);
    notificationStore.addNotification({
      text: 'Link to appointment block',
      textColored: 'was copied to your clipboard'
    });
  };

  const handleDurationButtonClick = (duration: string | number) => {
    handleChangeField('durationMinutes')(duration);
    setCustomDuration('');
  };

  const handleCustomDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = +event.target.value;
    handleChangeField('durationMinutes')(value);
    setCustomDuration(value.toString());
  };

  const handleCustomDurationBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = +event.target.value;
    if (durations.lastIndexOf(value) !== -1) {
      setCustomDuration('');
    }
  };

  const isFormValid = () => {
    return (
      isFormDataValid(getDataForSave()) &&
      !(localData.locationType === LocationTypeEnum.Zoom && !isZoomConnected) &&
      !isPageNameExist
    );
  };

  const getDataForSave = (): AppointmentBlock => {
    const reminderOption = reminders.find((item) => item.id === reminderTime);
    return {
      ...localData,
      schedulesBuffer: {
        beforeMs: hasBuffers ? beforeBuffer!.value : 0,
        afterMs: hasBuffers ? afterBuffer!.value : 0
      },
      remindBeforeMs: reminderOption!.value
    };
  };

  const setDataFromSource = (source: AppointmentBlock) => {
    setLocalData(source);
    setAvailabilityInitDate(getAvailabilityStarts(source.availability));

    const { schedulesBuffer, remindBeforeMs, durationMinutes } = source;
    if (!durations.includes(durationMinutes)) {
      setCustomDuration(durationMinutes);
    }

    if (schedulesBuffer?.beforeMs || schedulesBuffer?.afterMs) {
      setHasBuffers(true);
      const beforeBuffer = buffers.find(
        (buffer) => buffer.value === schedulesBuffer.beforeMs
      );
      const afterBuffer = buffers.find(
        (buffer) => buffer.value === schedulesBuffer.afterMs
      );
      setSelectedBefore(beforeBuffer!.id);
      setSelectedAfter(afterBuffer!.id);
    }
    if (remindBeforeMs) {
      const item = reminders.find((item) => item.value === remindBeforeMs);
      setReminderTime(item!.id);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsValidationShown(false);
    if (isFormValid()) {
      if (!initialLocation) {
        save();
        return;
      }
      if (
        localData.locationType !== initialLocation?.locationType ||
        localData.location !== initialLocation?.location
      ) {
        setIsLocationTypeChanged(true);
      } else {
        save();
      }
    } else {
      setIsValidationShown(true);
    }
  };

  const handleCancel = () => {
    if (isAfterIntegrationConnectRef.current) {
      return history.push(CoachRoutesEnum.Calendar);
    }
    history.goBack();
  };

  const save = async () => {
    const data = getDataForSave();
    const thumbnailImageId = await uploadCroppedImg();

    const updateMethod = localData.id
      ? updateAppointmentBlock
      : createAppointmentBlock;
    setIsLoading(true);

    updateMethod({
      ...data,
      thumbnailImageId
    })
      .then(() => {
        handleCancel();
        notificationStore.addNotification({
          text: `Appointment block is ${localData.id ? 'updated' : 'created'}`
        });
      })
      .catch((error) => {
        const { locationType } = getServerModelValidation(error);
        if (locationType) {
          userStore.updateData({ isZoomConnected: false });
        }
        setIsValidationShown(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onChangeCrop = useCallback(
    (croppedAreaString: string) => {
      handleChangeField('croppedArea')(croppedAreaString);
    },
    [handleChangeField]
  );

  const onFileUpload = (fileName: string) => {
    handleChangeField('imageId')(fileName);
    handleChangeField('thumbnailImageId')('');
    handleChangeField('croppedArea')('');
  };

  const { onCrop, uploadCroppedImg, isUploadingCroppedImg } = useCropper(
    onChangeCrop,
    localData.croppedArea,
    getPublicFile(localData.imageId),
    localData.thumbnailImageId,
    480
  );

  const handleConnectZoom = () => {
    storeDataBeforeZoomConnect(pathname, getDataForSave());
  };

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, getDataForSave());
  };

  useEffect(() => {
    if (isAfterStripeConnected || isAfterZoomConnected) {
      history.replace(pathname);
      isAfterIntegrationConnectRef.current = true;
    }
    // eslint-disable-next-line
  }, [isAfterStripeConnected, isAfterZoomConnected]);

  useEffect(() => {
    if (isValidationShown) {
      const invalidElement = document.querySelector<HTMLElement>(
        '.FormInput__input--error, .WeekCalendar__invalid, .Select__input--error, .InputCustom--error'
      );

      if (invalidElement) {
        scroll?.scrollTop(
          getOffsetTop(invalidElement, scroll.container) - 60 - 30
        );
      }
    }
  }, [isValidationShown, scroll]);

  useEffect(() => {
    clearTimeout(checkLinkTimer);
    if (
      localData.link.trim() !== '' &&
      localData.link.trim() !== initialLinkRef.current
    ) {
      checkLinkTimer = window.setTimeout(() => {
        checkPageNameExist(localData.link).then((isExist) => {
          setIsPageNameExist(isExist);
        });
      }, 300);
    } else {
      setIsPageNameExist(false);
    }
  }, [localData.link]);

  useEffect(() => {
    const restoreData = isAfterZoomConnected
      ? restoreDataAfterZoomConnect<AppointmentBlock>(true).data
      : isAfterStripeConnected
      ? restoreDataAfterStripeConnect<AppointmentBlock>(true).data
      : null;

    if (blockId) {
      setIsLoading(true);
      getAppointmentBlock(+blockId)
        .then((response) => {
          initialLinkRef.current = response.link;
          setInitialLocation({
            location: response.location,
            locationType: response.locationType,
            showLocationWhileBooking: response.showLocationWhileBooking
          });
          const source = restoreData || response;
          setDataFromSource(source);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (restoreData) {
      setDataFromSource(restoreData);
    }
  }, [blockId]);

  useEffect(() => {
    if (
      userId &&
      !isLoading &&
      !localData.id &&
      zoomEnabled &&
      !isAfterIntegrationConnectRef.current
    ) {
      handleChangeField('locationType')(
        isZoomConnected ? LocationTypeEnum.Zoom : LocationTypeEnum.Custom
      );
    }
  }, [
    userId,
    localData.id,
    isLoading,
    zoomEnabled,
    handleChangeField,
    isZoomConnected
  ]);

  const closeConfirmLocationTypeChangeModal = () => {
    handleChange(initialLocation);
    setIsLocationTypeChanged(false);
  };

  const confirmLocationTypeChange = async () => {
    save();
    setIsLocationTypeChanged(false);
  };

  const handleBlurNameField = (e: React.FocusEvent<HTMLElement>) => {
    if (localData.link === '' && (e.target as HTMLInputElement).value !== '') {
      handleChangeField('link')(createLink(localData.name));
    }
  };

  return (
    <>
      <PageTitle title='Appointment Block' />
      <GlobalLoader isLoading={isLoading} />
      <div className='mb-sm-3 mb-2 pb-1'>
        <Button variations={['naked']} handleClick={handleCancel}>
          <ArrowIcon />
          <span className='ml-2'>Back</span>
        </Button>
      </div>
      <form className='AppointmentBlock' onSubmit={handleSubmit} noValidate>
        <div className='row justify-content-between align-items-center mb-6'>
          <div className='col-12 col-md-auto mb-3 mb-md-0'>
            <div className='AppointmentBlock__title mb-2 d-flex align-items-center'>
              {localData.id ? 'Edit' : 'Setup new'} appointment block
            </div>
            <div className='AppointmentBlock__text'>
              Appointment Block Details
            </div>
          </div>
          <div className='col flex-grow-0'>
            <FormCheckbox
              id='isPrivate'
              checked={localData.isPrivate}
              handleChange={() => {
                handleChangeField('isPrivate')(!localData.isPrivate);
              }}
              labelBefore='Public'
              label='Private'
              testIdPrefix='privacy-switch'
            />
          </div>
        </div>
        <div className='row mx-n2 pb-3'>
          <div className='col-md-6 px-2 pt-3 d-flex'>
            <FileUploader
              className='UploadArea'
              description='Drag and drop or click to add an image'
              fileMaxSize={MAX_IMAGE_SIZE}
              fileTypes={MAX_FILE_TYPES}
              isImage
              showPreview={!!localData.imageId}
              onFileUploaded={onFileUpload}
              withHint={!localData.imageId}
              data-testid='appointment-block-image-uploader'
            >
              {!localData.imageId ? (
                <div className='UploadArea__placeholder'>
                  <NoImage />
                </div>
              ) : (
                <ImageCropper
                  croppedArea={
                    localData.croppedArea
                      ? JSON.parse(localData.croppedArea)
                      : undefined
                  }
                  aspect={16 / 9}
                  imageSrc={getPublicFile(localData.imageId)}
                  onCrop={onCrop}
                />
              )}
            </FileUploader>
          </div>
          <div className='col-md-6 px-2'>
            <FormInput
              autoFocus={!isMobileDevice()}
              handleChange={handleChangeField('name')}
              value={localData.name}
              label='Name'
              isInvalid={
                isValidationShown && !isFormInputValid('name', localData)
              }
              errorMessage={
                getFormInputErrors('name', localData)[0] || 'Please fill'
              }
              placeholder='e.g. V’s power Yoga'
              onBlur={handleBlurNameField}
              data-testid='name-input'
            />

            <FormInput handleChange={() => {}} value={''} label='Description'>
              <WYSIWYGEditor
                testId='description-editor'
                wrapperClassName='flex-grow-1'
                onChange={handleChangeField('description')}
                HTMLString={localData.description}
              />
            </FormInput>
          </div>
        </div>
        <div
          className={`pb-md-3 ${
            isValidationShown && !isFormInputValid('durationMinutes', localData)
              ? ''
              : 'pb-3'
          }`}
        >
          <div className='AppointmentBlock__subtitle'>Appointment duration</div>
          <div className='AppointmentBlock__duration-container mx-n2 mx-md-n1'>
            {durations.map((duration, index) => {
              const isActive =
                customDuration !== localData.durationMinutes &&
                localData.durationMinutes === duration;
              return (
                <div
                  key={duration}
                  className='col-6 px-2 mb-2 mb-md-0 col-md px-md-1'
                >
                  <ButtonWithActiveState
                    key={index}
                    isActive={isActive}
                    title={duration}
                    subTitle='min'
                    handleClick={() => handleDurationButtonClick(duration)}
                  />
                </div>
              );
            })}
            <div className='px-2 col px-md-1'>
              <InputCustom
                value={customDuration}
                title={'Custom duration'}
                isInvalid={
                  isValidationShown &&
                  !isFormInputValid('durationMinutes', localData)
                }
                handleChange={handleCustomDurationChange}
                errorMessage={
                  getFormInputErrors('durationMinutes', getDataForSave())[0]
                }
                handleBlur={handleCustomDurationBlur}
                data-testid='custom-duration-input'
              />
            </div>
          </div>
        </div>
        <div className='AppointmentBlock__group'>
          <div className='AppointmentBlock__subtitle'>Appointment timezone</div>
          <div className='AppointmentBlock__group-text'>
            You’re in {moment.tz(timeZone).zoneName()} -{' '}
            {beautifyTimeZone(timeZone)}. Your invitees will see your
            availability in their local timezone.
          </div>
        </div>
        <div className='AppointmentBlock__group pb-3'>
          <div className='AppointmentBlock__subtitle'>Availability</div>
          <div className='AppointmentBlock__group-text'>
            Set your available hours when people can schedule one-on-one
            appointments with you.
            <span className='d-none d-md-inline'>
              &nbsp;Click on Calendar and add some intervals where you are
              available to meet with your clients.
            </span>
          </div>
          <CoachAppointmentBlockAvailabilityCalendar
            initDate={availabilityInitDate}
            availability={localData.availability}
            onChangeAvailability={handleChangeField('availability')}
            minIntervalMs={
              getMilliseconds(localData.durationMinutes, 'minutes') +
              (beforeBuffer!.value || 0)
            }
            isInvalid={
              isValidationShown &&
              !isFormInputValid('availability', getDataForSave())
            }
            errorMessage={
              getFormInputErrors('availability', getDataForSave())[0]
            }
          />
        </div>
        <div className='AppointmentBlock__group'>
          <div className='AppointmentBlock__subtitle AppointmentBlock__subtitle--small'>
            Buffers
          </div>
          <div className='row pb-3 mx-n2'>
            <div className='col-sm-6 px-2'>
              <FormCheckbox
                id='hasBlockTime'
                checked={hasBuffers}
                handleChange={() => setHasBuffers(!hasBuffers)}
                label='Set an amount of time to block before and/or after a client schedules'
                testIdPrefix='buffers-switch'
              />
            </div>
          </div>

          <div className='row pb-3 mx-n2'>
            <div className='col-sm-6 mb-3 mb-sm-0 px-2 d-flex'>
              <Select
                className='mr-0 col pr-2'
                options={buffers}
                isDisabled={!hasBuffers}
                value={selectedBefore}
                title={'Before'}
                onChange={(value) => setSelectedBefore(value)}
                data-testid='buffers-before-select'
              />
              <Select
                className='mr-0 col pl-2'
                options={buffers}
                isDisabled={!hasBuffers}
                value={selectedAfter}
                title={'After'}
                onChange={(value) => setSelectedAfter(value)}
                data-testid='buffers-after-select'
              />
            </div>
            <div className='col-sm-6 px-2 row'>
              <div className='col-12 col-xl-6 pr-xl-2 d-flex'>
                <Select
                  className='mr-0 flex-grow-1'
                  options={reminders}
                  value={reminderTime}
                  title={'Reminder'}
                  onChange={(value) => setReminderTime(value)}
                  data-testid='reminder-select'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row AppointmentBlock__group pb-3 mx-n2'>
          <div className='col-md-6 px-2'>
            <Location
              locationType={localData.locationType}
              location={localData.location}
              locationTitle='Appointment location'
              showLocationWhileBooking={localData.showLocationWhileBooking}
              isZoomConnected={isZoomConnected}
              connectUrl={getZoomConnectUrl(userId)}
              onConnect={handleConnectZoom}
              onChange={handleChange}
              initialLocation={initialLocation}
              isInvalid={
                isValidationShown
                  ? {
                      location: !isFormInputValid('location', localData),
                      locationType: !isFormInputValid('locationType', localData)
                    }
                  : null
              }
              errorMessage={{
                location: getFormInputErrors('location', localData)[0],
                locationType: getFormInputErrors('locationType', localData)[0]
              }}
            />

            <FormInput
              className='FormInput--customize-prepend'
              handleChange={handleChangeField('link')}
              value={localData.link}
              label='Appointment page address'
              isInvalid={
                isPageNameExist ||
                (isValidationShown && !isFormInputValid('link', localData))
              }
              valueFormatter={createLink}
              errorMessage={
                isPageNameExist
                  ? 'This link is used for another page, please choose another'
                  : `${getFormInputErrors('link', localData)[0]}`
              }
              prepend={getAppointmentLinkPrefix()}
              append={
                <Button
                  type='button'
                  variations={['naked']}
                  handleClick={handleCopyLink}
                >
                  Copy
                </Button>
              }
              data-testid='page-address-input'
            />
          </div>
          <div className='col-md-6 px-2 row'>
            <div className='col-sm-6 pr-sm-2'>
              <FormInput
                className='FormInput--price FormInput--nowrap'
                type='number'
                isDisabled={!isStripeConnected}
                handleChange={handleChangeField('price')}
                valueFormatter={formatValueAsPrice}
                value={localData.price}
                isInvalid={
                  isValidationShown && !isFormInputValid('price', localData)
                }
                errorMessage={getFormInputErrors('price', localData)[0]}
                label='Amount to be collected'
                append='USD'
                labelClassName='letter-spacing-n1'
                data-testid='price-input'
              />
              <StripeConnectHint
                isShown={!isStripeConnected}
                onConnectStripe={handleConnectStripe}
              />
            </div>
          </div>
        </div>
        <div className='row mx-n2 pt-2'>
          <div className='col-sm-6 mb-3 mb-sm-0 col-12 px-2'>
            <Button
              className='button__stretch'
              variations={['gray']}
              type='button'
              handleClick={handleCancel}
              data-testid='cancel-btn'
            >
              Cancel
            </Button>
          </div>
          <div className='col-sm-6 mb-3 mb-sm-0 col-12 px-2'>
            <Button
              className='button__stretch'
              disabled={isLoading || isUploadingCroppedImg}
              data-testid='save-btn'
            >
              Save
            </Button>
          </div>
        </div>
      </form>
      <ConfirmModal
        isOpened={isLocationTypeChanged}
        close={closeConfirmLocationTypeChangeModal}
        title='Location update'
        text="We'll update the location for all scheduled appointments related to this appointment block. Are you sure you want to do this?"
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={confirmLocationTypeChange}
        cancelCallback={closeConfirmLocationTypeChangeModal}
      />
    </>
  );
};

export default observer(CoachAppointmentBlock);
