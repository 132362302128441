import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/remote-config';

import getGlobal from './globals';

interface FeatureFlags {
  zoomEnabled: boolean;
  googleCalendarEnabled: boolean;
  invoicesEnabled: boolean;
  [key: string]: string | string[] | number | boolean;
}

export const firebaseApp = firebase.initializeApp(getGlobal('firebase'));

const defaultValue: FeatureFlags = {
  zoomEnabled: false,
  googleCalendarEnabled: false,
  invoicesEnabled: false
};

export const FeatureFlagsContext = React.createContext<FeatureFlags>(
  defaultValue
);
const remoteConfig = firebaseApp.remoteConfig();

remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 0
};

const FeatureFlagsProvider: React.FC<{}> = ({ children }) => {
  const [config, setConfig] = useState<FeatureFlags>(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    remoteConfig.defaultConfig = config as any;
    remoteConfig
      .fetchAndActivate()
      .then((activated) => {
        if (!activated) {
          console.log('not activated');
        }
        return remoteConfig.getAll();
      })
      .then((remoteFlags) => {
        const newConfig = {
          ...config
        };
        Object.entries(remoteFlags).forEach(([key, value]) => {
          switch (typeof config[key]) {
            case 'boolean':
              newConfig[key] = value.asBoolean();
              break;
            case 'number':
              newConfig[key] = value.asNumber();
              break;
            case 'string':
            default:
              newConfig[key] = value.asString();
              break;
          }
        });
        setConfig(newConfig);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <FeatureFlagsContext.Provider value={config}>
      {!isLoading ? children : null}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;
