import React, { useRef, useState, useEffect } from 'react';
import './AvailableTime.styles.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

interface iProps {
  timeList: number[];
  isOpened: boolean;
  date: number;
  selectedTime: number;
  setSelectedTime: (timeMs: number) => void;
}

const AvailableTime: React.FC<iProps> = ({
  timeList,
  isOpened,
  date,
  selectedTime,
  setSelectedTime
}) => {
  const [isInstantlyOpened] = useState<boolean>(isOpened);
  const containerRef = useRef(null);
  const scrollbar = useRef<Scrollbars>();

  useEffect(() => {
    if (!containerRef.current) return;
    const rectBottom = containerRef.current.getBoundingClientRect().bottom;
    if (
      timeList.length > 0 &&
      rectBottom > (window.innerHeight || document.documentElement.clientHeight)
    ) {
      containerRef.current.scrollIntoView();
    }
  }, [timeList]);

  useEffect(() => {
    if (selectedTime < 0) {
      scrollbar.current.scrollTop(0);
    }
  }, [selectedTime]);

  useEffect(() => {
    if (selectedTime > 0) {
      scrollbar.current.scrollTop(timeList.indexOf(selectedTime) * 63);
    }
  }, [timeList]);

  const render = (
    <div>
      <div className='AvailableTime__date'>
        {moment.utc(date).format('dddd, MMMM D')}
      </div>
      <Scrollbars
        style={{
          minHeight: timeList.length > 0 ? 304 : 'unset',
          width: '100%'
        }}
        ref={scrollbar}
      >
        <div className='AvailableTime__list'>
          {timeList.map((time) => (
            <div
              key={time}
              className='AvailableTime__row'
              onClick={() => setSelectedTime(time)}
            >
              <span
                className={`AvailableTime__text ${
                  time === selectedTime ? 'AvailableTime__text--selected' : ''
                }`}
              >
                {moment.utc(time).format('hh:mm a')}
              </span>
            </div>
          ))}
        </div>
      </Scrollbars>
    </div>
  );

  if (isInstantlyOpened) {
    return (
      <div className='AvailableTime AvailableTime-enter-done'>{render}</div>
    );
  } else {
    return (
      <CSSTransition
        in={isOpened}
        timeout={350}
        classNames='AvailableTime'
        nodeRef={containerRef}
      >
        <div className='AvailableTime' ref={containerRef}>
          {render}
        </div>
      </CSSTransition>
    );
  }
};

export default AvailableTime;
