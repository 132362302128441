import React, { useState, useRef } from 'react';
import './InfoBlock.styles.scss';
import ClockIcon from '../../icons/clock.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import CalendarIcon from '../../icons/calendar.svg';
import PlanetIcon from '../../icons/planet.svg';
import AvatarIcon from '../../icons/avatar-small.svg';
import BellIcon from '../../icons/bell.svg';
import ImageIcon from '../../icons/image.svg';
import DesktopIcon from '../../icons/desktop-icon.svg';
import moment from 'moment/moment';
import Select from '../Select/Select';
import {
  getMilliseconds,
  getFormattedDuration,
  normalizePrice,
  normalizeTz,
  getTimezone
} from '../../core/helpers';
import { Scrollbars } from 'react-custom-scrollbars';
import { getPublicFile } from '../../core/api';
import cn from 'classnames';
import { useMedia, useStores, useWindowResize } from '../../hooks';
import { isUrl } from '../../core/formValidation';
import TextEllipse from '../TextEllipse';
import { ScheduleItemType } from '../../core/enums';

const buffers = [
  {
    id: '1',
    value: 0,
    name: '0 min'
  },
  {
    id: '2',
    value: getMilliseconds(5, 'minutes'),
    name: '5 min before'
  },
  {
    id: '3',
    value: getMilliseconds(15, 'minutes'),
    name: '15 min before'
  },
  {
    id: '4',
    value: getMilliseconds(30, 'minutes'),
    name: '30 min before'
  },
  {
    id: '5',
    value: getMilliseconds(1, 'hours'),
    name: '1 hour before'
  }
];

export interface iInfoBlockData {
  id: number;
  type: ScheduleItemType;
  name: string;
  userName: string;
  occupation?: string;
  description?: string;
  location?: string;
  durationMinutes?: number;
  membersCountLimitString: string;
  availableSpots?: number;
  price?: string;
  date?: number;
  showCoachAvatar?: boolean;
  avatarImage?: string;
  showSelectBefore?: boolean;
  timeZone?: string;
  datesEvent?: number[];
  remindBefore: number;
  setRemindBefore?: (value: number) => void;
  isRemindBeforeDisabled?: boolean;
  imageId?: string;
  isCanceled?: boolean;
}

interface iProps {
  data: iInfoBlockData;
  imageIcon?: JSX.Element;
  actions?: JSX.Element;
  className?: string;
}

const InfoBlock: React.FC<iProps> = ({
  data,
  className = '',
  imageIcon,
  actions
}) => {
  const {
    type,
    name,
    userName,
    occupation,
    membersCountLimitString,
    durationMinutes,
    location,
    price,
    description,
    date,
    showCoachAvatar = true,
    avatarImage = '',
    showSelectBefore = true,
    availableSpots = 0,
    timeZone = '',
    datesEvent = [],
    remindBefore = 0,
    setRemindBefore,
    isRemindBeforeDisabled = false,
    imageId = '',
    isCanceled
  } = data;

  const {
    rootStore: { userStore }
  } = useStores();

  const dimension = useMedia(
    [
      '(max-width: 575px)',
      '(min-width: 576px) and (max-width: 991px)',
      '(min-width: 992px)'
    ],
    ['mobile', 'tablet', 'desktop'],
    ''
  );

  const picutreRef = useRef(null);
  const [imgHeight, setImgHeight] = useState<number>(175);
  const descRef = useRef(null);

  useWindowResize(() => {
    if (!picutreRef.current) return setImgHeight(175);
    setImgHeight(picutreRef.current.clientWidth / 2);
  }, 100);

  const descriptionBlock = (
    <div className='InfoBlock__description-outer d-flex'>
      <Scrollbars autoHeight autoHeightMax={88}>
        <div
          className='InfoBlock__description'
          data-testid='event-description'
          ref={descRef}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </Scrollbars>
    </div>
  );

  const showDescription = () => {
    if (
      className === 'InfoBlock--ClientBookingWizard' &&
      data.type !== ScheduleItemType.Appointment &&
      Number(data.price) === 0 &&
      userStore.isSignedIn
    ) {
      return dimension === 'mobile' ? description && descriptionBlock : null;
    }

    return description && dimension !== 'tablet' && descriptionBlock;
  };

  const renderDates = () => {
    const startDate = moment.utc(datesEvent[0]).startOf('day').valueOf();
    const endDate = moment.utc(datesEvent[1]).startOf('day').valueOf();

    if (startDate === endDate) {
      return (
        <div className='InfoBlock__row'>
          <span className='InfoBlock__date-icon align-items-center d-flex'>
            <CalendarIcon />
          </span>
          <div className='InfoBlock__text InfoBlock__date'>
            <div className='d-flex align-items-center'>
              {moment.utc(datesEvent[0]).format('hh:mm a')} -{' '}
              {moment.utc(datesEvent[1]).format('hh:mm a')}
            </div>
            <div>{moment.utc(datesEvent[1]).format('MMMM D, YYYY')}</div>
          </div>
        </div>
      );
    }

    return (
      <div className='InfoBlock__row'>
        <span className='InfoBlock__date-icon align-items-center d-flex'>
          <CalendarIcon />
        </span>
        <div className='InfoBlock__text InfoBlock__date'>
          {datesEvent.map((date) => {
            const dateParts = moment
              .utc(date)
              .format('hh:mm a-MMMM D, YYYY')
              .split('-');

            return (
              <div className='InfoBlock__date-line--grid' key={date}>
                <div>{dateParts[0]}</div>
                <div className='mx-2'> - </div>
                <div>{dateParts[1]}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLocation = () => {
    const value = location || '';
    const isLocationUrl = isUrl(value);

    const text = isLocationUrl
      ? value.indexOf('://') > -1
        ? value.split('/')[2]
        : value.split('/')[0]
      : value;

    return (
      <div className='InfoBlock__row'>
        {isLocationUrl ? (
          <a
            className='InfoBlock__location'
            data-testid='event-location'
            href={value}
            target='_blank'
            rel='noopener noreferrer'
          >
            <DesktopIcon />
            <span className='InfoBlock__text'>{text}</span>
          </a>
        ) : (
          <span
            className='InfoBlock__location InfoBlock__location--text'
            data-testid='event-location'
          >
            <DesktopIcon />
            <span className='InfoBlock__text'>{value}</span>
          </span>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn('InfoBlock', className, {
        'InfoBlock--canceled': isCanceled
      })}
    >
      <div className='flex-shrink-sm-0 InfoBlock__picture-container'>
        {data.imageId !== undefined && (
          <div
            className='InfoBlock__picture mb-sm-3 mb-lg-0'
            ref={picutreRef}
            style={{ height: imgHeight }}
          >
            {imageId ? (
              <img
                src={getPublicFile(imageId)}
                alt=''
                className='InfoBlock__pic'
              />
            ) : (
              <>{!imageIcon ? <ImageIcon /> : imageIcon}</>
            )}
          </div>
        )}
        {description && dimension === 'tablet' && descriptionBlock}
      </div>
      <div className='InfoBlock__container'>
        <div className='InfoBlock__personal-info'>
          {showCoachAvatar && (
            <div className='InfoBlock__image'>
              {avatarImage ? (
                <img src={avatarImage} alt='' className='InfoBlock__img' />
              ) : (
                <AvatarIcon />
              )}
            </div>
          )}
          <div className='InfoBlock__about'>
            <div className='InfoBlock__name' data-testid='event-coach-name'>
              {userName}
            </div>
            <div className='InfoBlock__ocupation'>{occupation}</div>
          </div>
        </div>
        <div className='d-flex flex-column flex-sm-row align-items-sm-center d-sm-flex'>
          <div
            className='InfoBlock__title flex-grow-1 order-2 d-flex align-items-start'
            data-testid='event-title'
          >
            <TextEllipse text={name} lines={3}>
              <span>{name}</span>
            </TextEllipse>

            {actions}
          </div>
          {isCanceled && (
            <span className='InfoBlock__badge mb-2 mb-sm-0 order-sm-2'>
              This{' '}
              {type !== ScheduleItemType.Appointment
                ? 'meeting'
                : 'appointment'}{' '}
              is canceled
            </span>
          )}
        </div>
        <div className='InfoBlock__members-type' data-testid='event-type'>
          {membersCountLimitString}
          {availableSpots !== 0 ? (
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${availableSpots} spots`}
            </span>
          ) : null}
        </div>
        <div className='InfoBlock__row'>
          <DollarIcon />
          <span
            className='InfoBlock__text InfoBlock__text--mr'
            data-testid='event-price'
          >
            {price === '' || price === '0'
              ? 'Free'
              : `$${normalizePrice(Number(price))}`}
          </span>
          {durationMinutes ? (
            <>
              <ClockIcon />
              <span className='InfoBlock__text' data-testid='event-duration'>
                {getFormattedDuration(durationMinutes)}
              </span>
            </>
          ) : null}
        </div>
        {date ? (
          <div className={'InfoBlock__row'}>
            <span className='InfoBlock__date-icon'>
              <CalendarIcon />
            </span>
            <span className='InfoBlock__text InfoBlock__date'>
              <span className='InfoBlock__date-line' data-testid='event-time'>
                {moment.utc(date).format('hh:mm a')}
              </span>
              <span className='InfoBlock__date-line' data-testid='event-date'>
                {moment.utc(date).format('dddd, MMMM D, YYYY')}
              </span>
            </span>
          </div>
        ) : null}
        {datesEvent.length === 2 && renderDates()}
        <div className='InfoBlock__row mb-2'>
          <PlanetIcon />
          <span className='InfoBlock__text' data-testid='event-timezone'>
            {normalizeTz(getTimezone(timeZone)).name}
          </span>
        </div>
        {showDescription()}
        {showSelectBefore && (
          <div className='InfoBlock__footer'>
            <BellIcon />
            <Select
              isDisabled={isRemindBeforeDisabled}
              options={buffers}
              value={buffers.find(({ value }) => value === remindBefore)!.id}
              title={''}
              onChange={(value) => {
                const selectedBuffer = buffers.find(({ id }) => id === value);
                if (setRemindBefore && selectedBuffer)
                  setRemindBefore(selectedBuffer.value);
              }}
              className='Select--InfoBlock'
              withPortal={true}
              data-testid='reminder'
            />
          </div>
        )}
        {location && renderLocation()}
      </div>
    </div>
  );
};

export default InfoBlock;
