import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../hooks';
import cn from 'classnames';
import { getScrollParent, getOffsetTop } from '../../core/helpers';
import { Alignment } from '../Dropdown/Dropdown';

interface iProps {
  children: ReactElement<any>;
  shown: boolean;
  align?: Alignment;
  onClose?: () => void;
  fullScreen?: boolean;
}

const DropDownWithoutPortal: React.FC<iProps> = ({
  children,
  shown,
  align = 'left',
  fullScreen,
  onClose
}) => {
  const debouncedIsShown = useDebounce(shown, 200);
  const isShown = shown || debouncedIsShown;
  const dropdown = useRef(null);
  const [isTop, setIsTop] = useState(false);

  useEffect(() => {
    if (shown && !fullScreen && dropdown.current) {
      const scrollParent = getScrollParent(dropdown.current);
      if (
        getOffsetTop(dropdown.current, scrollParent) +
          dropdown.current?.clientHeight >
        scrollParent.scrollHeight
      ) {
        setIsTop(true);
      }
    }
    if (!shown) {
      setTimeout(() => {
        setIsTop(false);
      }, 200);
    }
  }, [shown, fullScreen, dropdown]);

  return isShown ? (
    <>
      <div
        className={cn('Dropdown__menu', `Dropdown__menu--${align}`, {
          fadeIn: shown,
          fadeOut: !shown,
          'Dropdown__menu--fullScreen': fullScreen,
          'Dropdown__menu--top': isTop
        })}
        ref={dropdown}
      >
        {children}
      </div>
      {fullScreen && (
        <div className='Dropdown__backdrop' onClick={onClose}></div>
      )}
    </>
  ) : null;
};

export default DropDownWithoutPortal;
