import React, { useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { highlightMatches } from '../../core/helpers';
import { Props as InputProps } from '../FormInput/FormInput';
import './Autocomplete.styles.scss';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

interface Props<T> {
  options: T[];
  optionKeyProp: keyof T;
  getOptionLabel: (opt: T) => string;
  searchBy?: (opt: T, value: string) => boolean;
  renderInput: (inputProps: Partial<InputProps>) => JSX.Element;

  renderOption?: (option: T, highlight: string) => JSX.Element;
  value: string;
  onChange: (value: string) => void;
  onSelect: (opt: T) => void;
  onBlur?: () => void;
}

const Autocomplete: <T>(props: Props<T>) => JSX.Element = ({
  options,
  optionKeyProp,
  getOptionLabel,
  searchBy,
  renderInput,
  renderOption,
  value,
  onChange,
  onSelect,
  onBlur
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useOnClickOutside(
    ref,
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  const handleInputChange = (val: string) => {
    if (!isOpen) setIsOpen(true);
    onChange(val);
  };

  const handleBlur = () => {
    setIsOpen(false);
    const option = options.find((o) => getOptionLabel(o) === value);
    if (option) {
      onSelect({ ...option });
    }
    if (onBlur) onBlur();
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleSelect: (opt: typeof options[0]) => void = (opt) => {
    onSelect(opt);
    setIsOpen(false);
  };

  const inputProps: Partial<InputProps> = {
    value,
    handleChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus
  };

  const trimmedInputValue = value.trim();

  const matches = options.filter(
    (option) =>
      trimmedInputValue !== '' &&
      (searchBy
        ? searchBy(option, trimmedInputValue)
        : getOptionLabel(option)
            .toLowerCase()
            .includes(trimmedInputValue.toLowerCase()))
  );

  return (
    <div className='Autocomplete' ref={ref}>
      {renderInput(inputProps)}
      {isOpen && matches.length > 0 && (
        <div className='Autocomplete__dropdown'>
          <Scrollbars autoHeight>
            {matches.map((item) => (
              <div
                className='Autocomplete__item'
                key={item[optionKeyProp].toString()}
                onMouseDown={() => handleSelect(item)}
              >
                {renderOption ? (
                  renderOption(item, trimmedInputValue)
                ) : (
                  <div
                    className='Autocomplete__item-inner'
                    dangerouslySetInnerHTML={{
                      __html: `${highlightMatches(
                        getOptionLabel(item),
                        trimmedInputValue
                      )}`
                    }}
                  />
                )}
              </div>
            ))}
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
