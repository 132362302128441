import React, { useEffect, useState } from 'react';
import './CoachDashboardEditor.styles.scss';
import {
  iCoachPageCustomizationData,
  WelcomeMessageData
} from '../../core/types';
import { updateMotivationMessage, getCoachAccount } from '../../core/api';
import { observer } from 'mobx-react';
import Dashboard from '../../components/Dashboard/Dashboard';
import SignedPage from '../../containers/SignedPage/SignedPage';
import FormInput from '../../components/FormInput/FormInput';
import {
  getEmptyData,
  meetings,
  appointments,
  menuList
} from './CoachDashboardEditor.helpers';
import { getMotivationMessage } from '../../core/api';
import Loader from '../../components/Loader/Loader';
import ClientSideMenu from '../../components/ClientSideMenu/ClientSideMenu';
import { CoachAccountViewModel } from '../../core/backend/api';
import {
  getDefaultData,
  CustomizationStyles,
  ClientCustomizationStyles
} from '../../components/CustomizeSidebar/CustomizeSidebar.helpers';
import { getEmptyAccount } from '../Account/Account.helpers';
import { useMedia, useStores } from '../../hooks';
import CustomizeSidebarMenu from '../../components/CustomizeSidebar/CustomizeSidebarMenu';
import CustomizeSidebarTab from '../../components/CustomizeSidebar/CustomizeSidebarTab';
import CustomizeSidebar from '../../components/CustomizeSidebar/CustomizeSidebar';
import BioIcon from '../../icons/bio.svg';

const CoachDashboardEditor = () => {
  const { rootStore } = useStores();
  const { notificationStore } = rootStore;

  const [coachCustomizationData, setCoachCustomizationData] = useState<
    iCoachPageCustomizationData
  >(getDefaultData());
  const [coachAccountData, setCoachAccountData] = useState<
    CoachAccountViewModel
  >(getEmptyAccount());
  const [editableData, setEditableData] = useState<WelcomeMessageData>(
    getEmptyData()
  );
  const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0);
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);

  const isTablet = useMedia(['(min-width: 992px)'], [false], true);
  const [isOpen, setIsOpen] = useState<boolean>(!isTablet);

  useEffect(() => {
    if (!isTablet) {
      setIsOpen(true);
    }
  }, [isTablet]);

  useEffect(() => {
    getMotivationMessage().then((response) => {
      setEditableData((prevData) => ({
        welcomeText: response.welcomeText || prevData.welcomeText,
        message: response.message || prevData.message
      }));
    });
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeField = (field: string) => (value: any) => {
    setEditableData({
      ...editableData,
      [field]: value
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSendingRequest(true);
    updateMotivationMessage(editableData)
      .then(() => {
        notificationStore.addNotification({
          text: 'Customization is saved.'
        });
      })
      .finally(() => setIsSendingRequest(false));
  };

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    getCoachAccount().then((data: CoachAccountViewModel) => {
      setCoachAccountData(data);
      if (data.siteCustomizationJson) {
        try {
          setCoachCustomizationData(JSON.parse(data.siteCustomizationJson));
        } catch (err) {
          setCoachCustomizationData(getDefaultData());
        }
      } else {
        setCoachCustomizationData(getDefaultData());
      }
    });
  };

  const handleTabClose = () => setSelectedMenuItem(0);

  return (
    <SignedPage
      title='Dashboard editor'
      className='CZ__bg CoachDashboardEditor'
    >
      <CustomizationStyles coachData={coachCustomizationData} />
      <ClientCustomizationStyles coachData={coachCustomizationData} />
      <div className='CoachDashboardEditor'>
        <ClientSideMenu
          menuList={menuList}
          isOpened={!isTablet}
          coachInfo={coachAccountData}
          coachCustomizationInfo={coachCustomizationData}
        />
        {isSendingRequest && <Loader />}
        <CustomizeSidebar
          onSubmit={handleSubmit}
          isOpen={isOpen}
          onToggle={handleToggle}
          coachAccountData={coachAccountData}
          selectedMenuItem={selectedMenuItem}
          onTabClose={handleTabClose}
          tabName={
            tabsMenuList.find((item) => selectedMenuItem === item.id)?.name
          }
        >
          {selectedMenuItem === 0 && (
            <CustomizeSidebarMenu
              onMenuItemSelect={setSelectedMenuItem}
              menuList={tabsMenuList}
            />
          )}
          {selectedMenuItem === 1 && (
            <CustomizeSidebarTab>
              <FormInput
                label='Welcome Title'
                placeholder='Welcome Title'
                value={editableData.welcomeText}
                handleChange={handleChangeField('welcomeText')}
                tabIndex={1}
              />
              <FormInput
                handleChange={() => {}}
                value={''}
                label='Your Message'
              >
                <textarea
                  className='CustomizeSidebar__bio-textarea'
                  value={editableData.message}
                  placeholder='Use this space to welcome your client, provide helpful information, share announcements, or whatever else you can imagine.'
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleChangeField('message')(event.target.value);
                  }}
                  tabIndex={1}
                />
              </FormInput>
            </CustomizeSidebarTab>
          )}
        </CustomizeSidebar>
        <Dashboard
          scheduled={[]}
          meetings={meetings}
          appointments={appointments}
          siteName={''}
          motivationData={editableData}
          isPreview
        />
      </div>
    </SignedPage>
  );
};

const tabsMenuList = [
  {
    id: 1,
    icon: <BioIcon />,
    name: 'Welcome',
    hint: 'Edit your personal message'
  }
];

export default observer(CoachDashboardEditor);
