import React from 'react';
import './ClientMeetingTile.styles.scss';
import { iBookingItem } from '../../core/types';
import { EventStatus } from '../../core/enums';
import moment from 'moment';
import Button from '../Button/Button';
import ImageIcon from '../../icons/image.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import { getPublicFile } from '../../core/api';
import { getFormattedDuration } from '../../core/helpers';

interface iProps {
  data: iBookingItem;
  className?: string;
  buttonName?: string;
  handleClick?: () => void;
  showDuration?: boolean;
  showImage?: boolean;
}

const ClientMeetingTile: React.FC<iProps> = ({
  data,
  className = '',
  buttonName = 'Register for this',
  handleClick = () => {},
  showDuration = false,
  showImage = true
}) => {
  const {
    name,
    status,
    timestamp,
    durationMinutes,
    thumbnailImageId,
    hasPrice
  } = data;
  const isCanceled = status === EventStatus.Canceled;
  const date = moment.utc(timestamp);

  return (
    <div
      className={`ClientMeetingTile ${className} ${
        isCanceled ? 'ClientMeetingTile--canceled' : ''
      }`}
    >
      <div className='ClientMeetingTile__image'>
        <div className='ClientMeetingTile__image-inner'>
          {thumbnailImageId ? (
            <img
              src={showImage ? getPublicFile(thumbnailImageId) : ''}
              alt=''
              className='ClientMeetingTile__img'
            />
          ) : (
            <ImageIcon />
          )}
        </div>
      </div>
      <div className='ClientMeetingTile__content'>
        <div className='d-flex'>
          <div className='ClientMeetingTile__name flex-grow-1'>{name}</div>
          {hasPrice && (
            <div className='ClientMeetingTile__is-paid'>
              <DollarIcon />
            </div>
          )}
        </div>
        <div className='ClientMeetingTile__date'>
          {showDuration
            ? getFormattedDuration(durationMinutes)
            : date.format('MMMM D, hh:mma')}
        </div>
        <Button
          handleClick={handleClick}
          className='button__ClientMeetingTile CZ__button-hover'
        >
          {buttonName}
        </Button>
      </div>
    </div>
  );
};

export default ClientMeetingTile;
