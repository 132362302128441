import React, { useEffect, useState } from 'react';
import './ClientScheduleTile.styles.scss';
import ScheduleIcon from '../../icons/schedule.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import OneOnOneIcon from '../../icons/one-on-one-circle.svg';
import GroupIcon from '../../icons/group-plus.svg';
import AccordionButton from '../AccordionButton/AccordionButton';
import { BookingItemTypeEnum, iBookingItem } from '../../core/types';
import { EventStatus } from '../../core/enums';
import moment from 'moment';
import CopyLinkInput from '../CopyLinkInput/CopyLinkInput';
import ClockIcon from '../../icons/clock.svg';
import ViewIcon from '../../icons/view-icon.svg';
import {
  copyToClipboard,
  getFormattedDuration,
  getMilliseconds,
  normalizePrice
} from '../../core/helpers';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import Button from '../Button/Button';
import { useStores } from '../../hooks';
import TextEllipse from '../TextEllipse';
import Tooltip from '../Tooltip/Tooltip';

interface iProps {
  data: iBookingItem;
  shortDetails?: boolean;
  showDay?: boolean;
  className?: string;
  hasLink?: boolean;
  menu?: ListItemProps[];
  onView?: (data: iBookingItem) => void;
}

const ClientScheduleTile: React.FC<iProps> = ({
  data,
  shortDetails,
  showDay,
  className = '',
  children,
  hasLink = false,
  menu,
  onView
}) => {
  const {
    name,
    price,
    durationMinutes,
    status,
    timestamp,
    type,
    description,
    location,
    membersCountLimit,
    hasPrice,
    isScheduled
  } = data;

  const [isDescription, setIsDescription] = useState<boolean>(false);

  const {
    rootStore: { notificationStore }
  } = useStores();

  useEffect(() => {
    const descr = document.createElement('div');
    descr.innerHTML = description;
    if (!!descr.textContent.replace(/\n/g, '')) {
      setIsDescription(true);
    }
  }, [description]);

  const isCanceled = status === EventStatus.Canceled;
  const date = moment.utc(timestamp);
  const endDate = moment.utc(
    timestamp! + getMilliseconds(durationMinutes!, 'minutes')
  );
  const endsTheSameDay =
    date.clone().startOf('day').valueOf() ===
    endDate.clone().startOf('day').valueOf();

  const handleCopyLink = (event?: React.MouseEvent) => {
    event!.stopPropagation();
    if (location) {
      copyToClipboard(location);
      notificationStore.addNotification({
        text: 'Link to appointment block',
        textColored: 'was copied to your clipboard'
      });
    }
  };

  const renderAccordionContent = () => {
    return (
      <>
        <div
          className='ClientScheduleTile__info-text'
          dangerouslySetInnerHTML={{
            __html: description
          }}
        />
        {!shortDetails && (
          <div className='ClientScheduleTile__info flex-sm-row flex-column'>
            <div className='ClientScheduleTile__info-item mr-0 mr-sm-4 mb-2 mb-sm-0'>
              <DollarIcon />
              <span className='ClientScheduleTile__info-item-text ml-2'>
                {hasPrice ? `$${normalizePrice(price)}` : 'Free'}
              </span>
            </div>
            {(type !== BookingItemTypeEnum.Meeting || isScheduled) && (
              <div className='ClientScheduleTile__info-item mr-0 mr-sm-4 mb-2 mb-sm-0'>
                <ClockIcon />
                <span className='ClientScheduleTile__info-item-text ml-2'>
                  {getFormattedDuration(durationMinutes)}
                </span>
              </div>
            )}
            <div className='ClientScheduleTile__info-item mr-0 mb-2 mb-sm-0'>
              {membersCountLimit === 1 ? <OneOnOneIcon /> : <GroupIcon />}
              <span className='ClientScheduleTile__info-item-text ml-2'>
                {membersCountLimit === 1 ? 'One-on-One' : 'Group'}
              </span>
            </div>
            {hasLink && !isCanceled && (
              <div className='ClientScheduleTile__info-item d-sm-none d-block mr-0 mr-sm-4 mb-2 mb-sm-0'>
                Location:
                <div className='ClientScheduleTile__info-item-text ClientScheduleTile__info-item-text--secondary mt-1'>
                  {location}
                </div>
                <Button
                  type='button'
                  variations={['naked']}
                  handleClick={handleCopyLink}
                  className='ml-n2 p-2'
                >
                  Copy link
                </Button>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <div
      className={`ClientScheduleTile p-lg-4 p-sm-3 p-2 mb-md-3 mb-2 ${className} ${
        isCanceled ? 'ClientScheduleTile--canceled' : ''
      }`}
    >
      {isCanceled && <div className='ClientScheduleTile__cenceled-overlay' />}
      <div className='ClientScheduleTile__wrapper flex-sm-row flex-column'>
        <div
          className={`ClientScheduleTile__inner ${
            !isCanceled && children ? 'ClientScheduleTile__inner--withBtn' : ''
          }`}
        >
          <div className='ClientScheduleTile__side-block CZ__icon'>
            {type === BookingItemTypeEnum.AppointmentBlock ? (
              <ScheduleIcon />
            ) : (
              <>
                <div
                  className='ClientScheduleTile__side-block-date'
                  data-class='event-date'
                >
                  {date.format('DD')}
                </div>
                <div className='ClientScheduleTile__side-block-day'>
                  {date.format('MMM')}
                </div>
              </>
            )}
          </div>
          <div className='ClientScheduleTile__content px-sm-3 px-2'>
            <div className='ClientScheduleTile__time-duration CZ__sub-heading'>
              <span
                className={`
                ClientScheduleTile__circle
                ${
                  type === BookingItemTypeEnum.Meeting && !isScheduled
                    ? 'ClientScheduleTile__circle--is-event'
                    : ''
                }
              `}
              />
              {(type !== BookingItemTypeEnum.Meeting || isScheduled) &&
                date.format(`${showDay ? 'dddd, ' : ''}hh:mm a`)}
              {type === BookingItemTypeEnum.Meeting &&
                !isScheduled &&
                (() => {
                  if (endsTheSameDay) {
                    return `${date.format(
                      `${showDay ? 'dddd, ' : ''}hh:mm a`
                    )} - ${endDate.format('hh:mm a')}`;
                  }
                  return `${date.format(
                    `${showDay ? 'dddd, ' : ''}hh:mm a, MMMM D`
                  )} - ${endDate.format(
                    `${showDay ? 'dddd, ' : ''}hh:mm a, MMMM D`
                  )}`;
                })()}
            </div>
            <div
              className={`
              ClientScheduleTile__text
              CZ__heading
              ${isCanceled ? 'ClientScheduleTile__text--canceled' : ''}
            `}
            >
              {isDescription ? (
                <AccordionButton
                  className='ClientScheduleTile__accordion'
                  nodeContent={renderAccordionContent()}
                  classSuffix='ClientScheduleTile'
                >
                  <TextEllipse text={name}>
                    <span>{name}</span>
                  </TextEllipse>
                </AccordionButton>
              ) : (
                <div className='ClientScheduleTile__title my-2'>
                  <TextEllipse text={name}>
                    <span>{name}</span>
                  </TextEllipse>
                </div>
              )}

              {hasLink && !isCanceled && (
                <div className='d-sm-flex d-none flex-column'>
                  <span>Location:</span>
                  <CopyLinkInput
                    link={location}
                    className='CopyLinkInput--ClientScheduleTile d-flex'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!isCanceled && children && (
          <div className='ClientScheduleTile__buttons mt-3'>{children}</div>
        )}
      </div>
      {!!onView && (
        <Tooltip
          trigger={
            <Button
              handleClick={() => {
                onView(data);
              }}
              variations={['naked']}
              className='ClientScheduleTile__viewBtn'
            >
              <ViewIcon />
            </Button>
          }
          content={<>View</>}
          triggerType='hover'
        />
      )}
      {menu && (
        <DropdownMenu
          list={menu}
          className='ClientScheduleTile__dropdown pl-sm-2 ml-sm-1'
          buttonClassName='CZ__dropdown-button'
        />
      )}
    </div>
  );
};

export default ClientScheduleTile;
