import React, { useState, useEffect, useRef } from 'react';
import { CoachContactsViewModel } from '../../core/backend/api';
import Button from '../Button/Button';
import './SharingInput.styles.scss';
import TagWithDelete from '../TagWithDelete/TagWithDelete';
import SharingInputDropdown from '../SharingInputDropdown/SharingInputDropdown';
import SharingInputItem from './SharingInputItem';
import { useArrowSelection } from '../../hooks/useArrowSelection';
import { sortArrayBy } from '../../core/helpers';
import { highlightMatches } from '../../core/helpers';

interface iProps {
  options: CoachContactsViewModel[];
  title?: string;
  onShare?: (id: any) => void;
  placeholder?: string;
  isCheckboxHidden?: boolean;
  className?: string;
  focus?: boolean;
  sharedIds: string[];
  selectedIds: string[];
  inputValue: string;
  onSelect: (id: string) => void;
  onChangeInput: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => CoachContactsViewModel[];
  withShareButton?: boolean;
  onUnselect: (clientId: string) => () => void;
}

const SharingInput: React.FC<iProps> = function ({
  options = [],
  title,
  onShare,
  placeholder = '',
  className = '',
  focus,
  sharedIds,
  selectedIds,
  onSelect,
  onChangeInput,
  onUnselect,
  inputValue,
  withShareButton = false
}) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<
    CoachContactsViewModel[]
  >([]);

  useEffect(() => {
    if (focus) {
      if (inputRef !== null && inputRef.current) inputRef.current.focus();
    }
  }, [focus]);

  const handleClick = () => {
    setIsDropdownOpened(true);

    setFilteredOptions(options);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filtered = onChangeInput(e);

    if (filtered.length) {
      setIsDropdownOpened(true);
      setFilteredOptions(filtered);
    } else {
      setIsDropdownOpened(false);
    }
  };

  const {
    arrowSelect,
    selectedByKey,
    setSelectedByKey
  } = useArrowSelection(filteredOptions, (client) => onSelect(client.clientId));

  const handleDropdownClose = () => {
    setIsDropdownOpened(false);
    setSelectedByKey(-1);
  };

  useEffect(() => {
    containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
  }, [selectedIds]);

  return (
    <div
      className={`SharingInput ${className}`}
      style={{ position: 'relative' }}
    >
      {title && <div className='SharingInput__title'>{title}</div>}
      <div className='d-flex align-items-stretch'>
        <div
          className='SharingInput__tagInput Scrollbar'
          onClick={() => {
            inputRef.current.focus();
          }}
          ref={containerRef}
        >
          {options
            .filter(({ clientId }) => selectedIds.includes(clientId))
            .map((client) => (
              <TagWithDelete
                title={`${client.firstName} ${client.lastName}`}
                id={client.clientId}
                onDelete={onUnselect}
                key={client.clientId}
              />
            ))}
          <input
            ref={inputRef}
            type='text'
            onKeyUp={arrowSelect}
            onChange={handleChangeInput}
            value={inputValue}
            placeholder={placeholder}
            onClick={handleClick}
            className='SharingInput__input'
          />
        </div>
        {onShare && withShareButton && (
          <Button
            className='button_SharingInput'
            handleClick={onShare}
            disabled={selectedIds.length === 0}
            type={undefined}
          >
            Share
          </Button>
        )}
      </div>
      <SharingInputDropdown
        close={handleDropdownClose}
        isOpened={isDropdownOpened}
        selectedByKey={selectedByKey}
      >
        {filteredOptions.sort(sortArrayBy('firstName')).map((option, index) => {
          const isSelected =
            onShare && withShareButton
              ? selectedIds.includes(option.clientId)
              : selectedIds.includes(option.clientId) ||
                sharedIds.includes(option.clientId);
          return (
            <SharingInputItem
              id={option.clientId}
              title={highlightMatches(
                `${option.firstName} ${option.lastName}`,
                inputValue
              )}
              index={index}
              selectedByKey={selectedByKey}
              onSelect={onSelect}
              isSelected={isSelected}
              key={option.clientId}
              imageId={option.imageId}
            />
          );
        })}
      </SharingInputDropdown>
    </div>
  );
};

export default SharingInput;
