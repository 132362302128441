import React from 'react';
import './CoachRequests.styles.scss';
import CoachSchedulePage from '../../containers/CoachSchedulePage/CoachSchedulePage';
import RequestTile from '../../components/RequestTile/RequstTile';

const CoachRequests: React.FC<any> = () => {
  const handleEditButtonClick = (itemId: number) => {};

  const handleRemoveButtonClick = (itemId: number) => {};

  return (
    <CoachSchedulePage title='Requests'>
      <div className='CoachRequests__tiles'>
        {Array(23)
          .fill('')
          .map((tile, index) => (
            <RequestTile
              key={index}
              handleEdit={() => handleEditButtonClick(1)}
              handleRemove={() => handleRemoveButtonClick(1)}
              color='#BB6BD9'
              title={'item.name'}
              duration={60}
              price={100}
              meetingType={'group'}
            />
          ))}
      </div>
    </CoachSchedulePage>
  );
};

export default CoachRequests;
