import CoachUserStore from './CoachUserStore';
import NotificationStore from './NotificationStore';
import { action, configure, observable } from 'mobx';
import setInterceptors from '../core/interceptors';
import { iCoachPageCustomizationData, UserStore } from '../core/types';
import getGlobal, { getSiteName } from '../core/globals';
import ClientUserStore from './ClientUserStore';
import { CoachAccountViewModel } from '../core/backend/api';
import { getEmptyAccount } from '../pages/Account/Account.helpers';
import ClientBookingStore from './ClientPopupsStore';
import ContactsStore from './ContactsStore';
import { loadStripe } from '@stripe/stripe-js';
import { getDefaultData as getDefaultCoachCustomizationData } from '../components/CustomizeSidebar/CustomizeSidebar.helpers';
import { CoachRoutesEnum, isCoachRoute } from '../core/enums';
import { getCoachInfoForClient } from '../core/api';

export interface iRootStore<T extends UserStore> {
  userStore: T;
  notificationStore: NotificationStore;
  clientBookingStore: ClientBookingStore;
  prevUrl: string;
  siteName: string;
  flow: 'client' | 'coach';
  coachInfo: CoachAccountViewModel;
  coachCustomizationInfo: iCoachPageCustomizationData;
  isCoachDataLoaded: boolean;
  changesCounter: number;
  coachStripePromise?: Promise<any>;
  contactsStore: ContactsStore;
  setPrevUrl: (url: string) => void;
  getCoachInfo: () => void;
  incrementChangesCounter: () => void;
}

export interface iStores<T extends UserStore> {
  rootStore: iRootStore<T>;
}

configure({ enforceActions: 'never' });

class RootStore implements iRootStore<UserStore> {
  userStore: CoachUserStore | ClientUserStore;
  notificationStore: NotificationStore;
  clientBookingStore: ClientBookingStore;
  contactsStore: ContactsStore;
  @observable siteName: string;
  @observable prevUrl: string;
  @observable coachInfo: CoachAccountViewModel;
  @observable coachCustomizationInfo: iCoachPageCustomizationData;
  @observable isCoachDataLoaded: boolean;
  @observable coachStripePromise?: Promise<any>;
  @observable changesCounter: number;
  @observable flow: 'client' | 'coach';

  constructor() {
    this.changesCounter = 0;
    this.siteName = getSiteName();
    this.coachInfo = getEmptyAccount();
    this.coachCustomizationInfo = getDefaultCoachCustomizationData();
    this.isCoachDataLoaded = false;
    this.flow = isCoachRoute(this.siteName) ? 'coach' : 'client';
    this.contactsStore = new ContactsStore();
    this.userStore =
      this.flow === 'coach'
        ? new CoachUserStore(this.contactsStore)
        : new ClientUserStore(this.siteName);
    this.notificationStore = new NotificationStore();
    this.clientBookingStore = new ClientBookingStore();

    setInterceptors();
  }

  @action setPrevUrl = (url: string) => {
    this.prevUrl = url;
  };

  @action getCoachInfo = async () => {
    try {
      this.isCoachDataLoaded = false;
      const data = await getCoachInfoForClient();
      this.coachInfo = data;
      if (data.siteCustomizationJson) {
        this.coachCustomizationInfo = JSON.parse(data.siteCustomizationJson!);
      }
      if (data.stripeApiKey) {
        this.coachStripePromise = loadStripe(getGlobal('stripeKey'), {
          stripeAccount: data.stripeApiKey
        });
      } else {
        this.coachStripePromise = Promise.resolve(null);
      }
      this.isCoachDataLoaded = true;
    } catch (e) {
      window.location.href = `${CoachRoutesEnum.Error.replace(
        ':number',
        String(e.status)
      )}`;
      // window.location.href = `${getGlobal('domain')}/${this.siteName}`;
    }
  };

  @action incrementChangesCounter = () => {
    this.changesCounter = this.changesCounter + 1;
  };
}

const rootStore = new RootStore();

export default rootStore;
