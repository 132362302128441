import React, { ReactNode } from 'react';
import './ErrorMessage.styles.scss';

const ErrorMessage: React.FC<{
  children: ReactNode;
  isVisible: boolean;
  style?: any;
}> = ({ children, isVisible, style, ...rest }) => {
  return (
    <div
      style={style}
      className={`ErrorMessage ${isVisible ? 'ErrorMessage--visible' : ''}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ErrorMessage;
