import React from 'react';
import './AppointmentBlockTile.scss';
import ClockIcon from '../../icons/clock.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import PrivateIcon from '../../icons/private.svg';
import NonPrivateIcon from '../../icons/nonPrivate.svg';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import {
  copyToClipboard,
  getFormattedDuration,
  normalizePrice
} from '../../core/helpers';
import { observer } from 'mobx-react';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { useStores } from '../../hooks';
import TextEllipse from '../TextEllipse';
import WarningIcon from '../../icons/warning.svg';

type MeetingType = 'single' | 'group';

const YELLOW = '#f5c51b';
const GREEN = '#6FCF97';

interface iProps {
  className?: string;
  title?: string;
  duration?: number;
  meetingType?: MeetingType;
  price?: number;
  handlePrivate?: () => void;
  handleEdit?: () => void;
  handleRemove?: () => void;
  handleClick?: () => void;
  link?: string;
  isPrivate?: boolean;
  isInvalid?: boolean;
}

const AppointmentBlockTile: React.FC<iProps> = ({
  className = '',
  title = '',
  duration = 0,
  price = 0,
  handlePrivate = () => null,
  handleEdit = () => null,
  handleRemove = () => null,
  handleClick,
  link,
  isPrivate = false,
  isInvalid = false
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  const classList = ['AppointmentBlockTile', className];

  if (handleClick) {
    classList.push('AppointmentBlockTile--clickable');
  }
  const menuList: ListItemProps[] = [];

  if (handlePrivate) {
    menuList.push({
      name: isPrivate ? 'Make Public' : 'Make private',
      callback: handlePrivate,
      icon: isPrivate ? <NonPrivateIcon /> : <PrivateIcon />
    });
  }
  if (handleEdit) {
    menuList.push({
      name: 'Edit',
      callback: handleEdit,
      icon: <PencilIcon />
    });
  }
  if (handleRemove) {
    menuList.push({
      name: 'Delete',
      callback: handleRemove,
      icon: <BucketIcon />
    });
  }

  const handleCopyLink = (event?: React.MouseEvent) => {
    event!.stopPropagation();
    if (link) {
      copyToClipboard(link);
      notificationStore.addNotification({
        text: 'Link to appointment block',
        textColored: 'was copied to your clipboard'
      });
    }
  };

  return (
    <div
      className={classList.join(' ')}
      style={isInvalid ? { borderColor: YELLOW } : { borderLeftColor: GREEN }}
      onClick={handleClick || (() => null)}
    >
      {link && menuList.length > 0 && (
        <DropdownMenu
          list={menuList}
          className='AppointmentBlockTile__dropdown'
        />
      )}
      <div className='d-flex'>
        {isInvalid && (
          <div className='AppointmentBlockTile__warning-icon mr-2 d-flex align-items-center flex-shrink-0'>
            <WarningIcon />
          </div>
        )}
        <TextEllipse
          className={`AppointmentBlockTile__name ${
            isInvalid ? 'AppointmentBlockTile__name--invalid' : ''
          }`}
          text={title}
        >
          <span>{title}</span>
        </TextEllipse>
      </div>
      <div className='AppointmentBlockTile__info'>
        <div className='AppointmentBlockTile__info-item mr-3 mr-sm-2 pr-md-1'>
          <ClockIcon />
          <span className='AppointmentBlockTile__info-text'>
            {getFormattedDuration(duration)}
          </span>
        </div>
        <div className='AppointmentBlockTile__info-item mr-3 mr-sm-2 pr-md-1'>
          <DollarIcon />
          <span className='AppointmentBlockTile__info-text'>
            {price ? `$ ${normalizePrice(price)}` : 'Free'}
          </span>
        </div>
        {isPrivate ? (
          <div className='MeetingTile__info-item'>
            <Tooltip
              className='Tooltip--flex'
              trigger={<PrivateIcon />}
              content={<span>Private</span>}
              triggerType='hover'
            />
          </div>
        ) : null}
      </div>
      <div className='AppointmentBlockTile__tiles'>
        <div className='AppointmentBlockTile__tile AppointmentBlockTile__tile--group'></div>
        <div className='AppointmentBlockTile__tile AppointmentBlockTile__tile--link'>
          {link ? (
            <Button
              className='button__copy-link'
              type='button'
              variations={['naked']}
              handleClick={handleCopyLink}
            >
              Copy link
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(AppointmentBlockTile);
