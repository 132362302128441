import React from 'react';
import './SuperAdminHeader.styles.scss';
import { useLocation, useHistory } from 'react-router-dom';
// import LogoImage from '../../images/logo_inversed.svg';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import { CoachRoutesEnum } from '../../core/enums';
import { Link } from 'react-router-dom';
import { getPublicFile } from '../../core/api';
import { useStores } from '../../hooks';
import cn from 'classnames';
import Button from '../../components/Button/Button';
import BackIcon from '../../icons/arrow-left.svg';

interface iProps {
  isSideMenuOpened: boolean;
  onSideMenuToggle: () => void;
}

const SuperAdminHeader: React.FC<iProps> = ({
  isSideMenuOpened,
  onSideMenuToggle
}) => {
  const {
    rootStore: {
      userStore: { userData, signOut }
    }
  } = useStores();

  const location = useLocation();
  const history = useHistory();

  const handleMenuToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation(); // to not trigger useOutsde hook inside sideMenu
    onSideMenuToggle();
  };

  const goToAccountPage = () => {
    if (location.pathname !== CoachRoutesEnum.Account) {
      history.push(CoachRoutesEnum.Account);
    }
  };
  const goToDashboardPage = () => {
    if (location.pathname !== CoachRoutesEnum.Dashboard) {
      history.push(CoachRoutesEnum.Dashboard);
    }
  };

  return (
    <div className='SuperAdminHeader py-2 px-md-4 px-sm-3 px-2'>
      <div className='d-flex align-items-center justify-content-between'>
        <div
          className={cn('SuperAdminHeader__menu d-lg-none mr-sm-4 mr-3', {
            'SuperAdminHeader__menu--cross': isSideMenuOpened
          })}
          onClick={handleMenuToggle}
        >
          <div className='SuperAdminHeader__menu-line SuperAdminHeader__menu-line--one' />
          <div className='SuperAdminHeader__menu-line SuperAdminHeader__menu-line--two' />
          <div className='SuperAdminHeader__menu-line SuperAdminHeader__menu-line--three' />
        </div>

        <Link
          to={CoachRoutesEnum.Dashboard}
          className='d-flex align-items-center'
        >
          {/* <LogoImage /> */}
        </Link>
      </div>
      <Button
        className='SuperAdminHeader__back-button button__back-to-admin mx-2'
        component='Link'
        link={CoachRoutesEnum.Dashboard}
      >
        <BackIcon />
        <span className='SuperAdminHeader__back-button-text'>
          back to Coach Portal
        </span>
      </Button>
      <div className='d-flex align-items-center'>
        <DropdownMenu
          list={[
            {
              name: 'Coach Portal',
              callback: goToDashboardPage
            },
            {
              name: 'My Account',
              callback: goToAccountPage
            },
            {
              name: 'Logout',
              callback: signOut
            }
          ]}
          className='DropdownMenu--logout'
          withPortal={false}
        >
          <div className='SuperAdminHeader__user-circle'>
            {userData.imageId ? (
              <img src={getPublicFile(userData.imageId)} alt='Avatar' />
            ) : (
              `${userData.firstName.charAt(0)}${userData.lastName.charAt(0)}`
            )}
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default SuperAdminHeader;
