import React, { useState } from 'react';
import {
  getFormInputErrors,
  isFormInputValid
} from './CoachInvoiceForm.helpers';
import { InvoiceServiceViewModel } from '../../../core/backend/api';
import Button from '../../../components/Button/Button';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import CoachInvoiceFormItem from './CoachInvoiceFormItem';
import { useMedia } from '../../../hooks/useMedia';
import CoachInvoiceItemModal from '../CoachInvoiceItemModal/CoachInvoiceItemModal';

interface Props {
  items: InvoiceServiceViewModel[];
  isValidationShown: boolean;
  onChange: (data: InvoiceServiceViewModel[]) => void;
}

const CoachInvoiceFormItems: React.FC<Props> = ({
  items,
  isValidationShown,
  onChange
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(null);

  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const handleServiceAdd = () => {
    if (isMobile) {
      setIsModalOpen(true);
      setActiveItemIndex(null);
    } else {
      onChange([
        ...items,
        {
          title: '',
          quantity: 1,
          price: 0
        }
      ]);
    }
  };

  const handleServiceRemove = (index: number) => () => {
    onChange([...items.slice(0, index), ...items.slice(index + 1)]);
  };

  const handleServiceChange = (index: number) => (
    item: InvoiceServiceViewModel
  ) => {
    if (isModalOpen) {
      setIsModalOpen(false);
      setActiveItemIndex(null);
    }

    onChange(
      index === null
        ? [...items, item]
        : [...items.slice(0, index), item, ...items.slice(index + 1)]
    );
  };

  const handleServiceEdit = (index: number) => () => {
    if (isMobile) {
      setIsModalOpen(true);
      setActiveItemIndex(index);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveItemIndex(null);
  };

  return (
    <>
      <div className='CoachInvoiceForm__items mt-n3 p-3'>
        <div className='CoachInvoiceForm__items-header'>
          <div className='CoachInvoiceForm__items-header__cell'>Item</div>
          <div className='CoachInvoiceForm__items-header__cell'>Qty</div>
          <div className='CoachInvoiceForm__items-header__cell'>Price</div>
          <div className='CoachInvoiceForm__items-header__cell text-right'>
            Total
          </div>
        </div>
        {items.map((service, index) => (
          <CoachInvoiceFormItem
            isValidationShown={isValidationShown}
            item={service}
            key={service.id || index}
            onChange={handleServiceChange(index)}
            onDelete={handleServiceRemove(index)}
            onEdit={handleServiceEdit(index)}
            onClick={isMobile ? handleServiceEdit(index) : undefined}
          />
        ))}
        {items.length === 0 && (
          <div className='d-flex align-items-center justify-content-between pt-3 d-sm-none CoachInvoiceForm__items-empty'>
            <span>no items</span>
            <span>US$0.00</span>
          </div>
        )}
      </div>
      <div className='row mx-n2 px-3'>
        <div className='col-sm-6 px-2 d-none d-sm-block'>
          <ErrorMessage
            style={{ marginTop: '-8px', position: 'relative' }}
            isVisible={
              isValidationShown &&
              !isFormInputValid('services', { services: items })
            }
          >
            {getFormInputErrors('services', { services: items })[0]}
          </ErrorMessage>
        </div>
      </div>
      <div className='row mx-n2 px-3 pt-1 mb-n1'>
        <div className='col-sm-6 px-2'>
          <Button
            className='ml-n1 mb-3 mb-sm-0'
            type='button'
            variations={['naked']}
            handleClick={handleServiceAdd}
          >
            Add item
          </Button>
          <div className='d-sm-none'>
            <ErrorMessage
              style={{ marginTop: '-4px', position: 'relative' }}
              isVisible={
                isValidationShown &&
                !isFormInputValid('services', { services: items })
              }
            >
              {getFormInputErrors('services', { services: items })[0]}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <CoachInvoiceItemModal
        isOpened={isModalOpen}
        data={activeItemIndex !== null ? items[activeItemIndex] : null}
        onSubmit={handleServiceChange(activeItemIndex)}
        close={handleModalClose}
      />
    </>
  );
};

export default CoachInvoiceFormItems;
