import React, { useEffect, useState } from 'react';
import './ClientArchive.styles.scss';
import ClientSchedulePage from '../../containers/ClientSchedulePage/ClientSchedulePage';
import ClientScheduleTile from '../../components/ClientScheduleTile/ClientScheduleTile';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { iBookingItem } from '../../core/types';
import moment from 'moment';
import { getClientBookingItemsWithMember } from '../../core/api';
import EmptyIcon from '../../images/empty-archive.svg';
import EmptyState from '../../components/EmptyState/EmptyState';

const ClientArchive: React.FC<any> = () => {
  const [data, setData] = useState<iBookingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    reFetchData();
  }, []);

  const reFetchData = () => {
    setIsLoading(true);
    const date = moment.utc();
    getClientBookingItemsWithMember(0, date.valueOf()).then((response) => {
      response.sort((a, b) => {
        return a.timestamp! <= b.timestamp! ? -1 : 1;
      });
      setData(response);
      setIsLoading(false);
    });
  };

  return (
    <ClientSchedulePage title='Archive'>
      <GlobalLoader isLoading={isLoading} />
      <div className='ClientArchive'>
        {data.length > 0 &&
          data.map((item, index) => (
            <ClientScheduleTile key={index} data={item} />
          ))}
        {!data.length && !isLoading && (
          <EmptyState
            icon={<EmptyIcon />}
            text='You have no completed meetings or appointments yet'
            absoluteCenter={true}
          />
        )}
      </div>
    </ClientSchedulePage>
  );
};

export default ClientArchive;
