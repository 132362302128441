import React, { useState, useEffect } from 'react';
import './ClientSignUpModal.styles.scss';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
// import FormCheckbox from "../../FormCheckbox/FormCheckbox";
import {
  getDefaultData,
  isFormInputValid,
  isFormDataValid,
  getFormInputErrors
} from './ClientSignUpModal.helpers';
import { observer } from 'mobx-react';
import GlobalLoader from '../../GlobalLoader/GlobalLoader';
import { useStores } from '../../../hooks';
import ClientUserStore from '../../../store/ClientUserStore';
import CustomizedPhoneInput from '../../CustomizedPhoneInput';
import { getServerModelValidation } from '../../../core/helpers';

interface iProps {
  isOpened: boolean;
  email?: string;
  close: () => void;
  showSignIn: () => void;
}

const ClientSignUpModal: React.FC<iProps> = ({
  isOpened,
  email = '',
  close,
  showSignIn
}) => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores<ClientUserStore>();

  const [data, setData] = useState(getDefaultData());
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (isOpened) {
      setData({ ...getDefaultData(), email });
    }
  }, [isOpened, email]);

  const handleChangeField = (field: string) => (value: any) => {
    setData({
      ...data,
      [field]: field === 'email' ? value.trim() : value
    });
    setServerValidation((errors) => ({
      ...errors,
      [field]: ''
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isFormDataValid(data)) {
      setIsLoading(true);
      userStore
        .signUp(data)
        .then(() => {
          notificationStore.addNotification({
            text: 'Your account has been successfully created!'
          });
          close();
        })
        .catch((error) => {
          setServerValidation(getServerModelValidation(error));
          setIsValidationShown(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 440 }}>
      <GlobalLoader isLoading={isLoading} />
      <ModalHeader>
        <h2>Sign Up</h2>
      </ModalHeader>
      <form onSubmit={handleSubmit} className='ClientSignUpModal'>
        <div className='row mx-n3'>
          <div className='col-12 px-3'>
            <FormInput
              tabIndex={0}
              name='email'
              label='Email'
              value={data.email}
              isInvalid={
                isValidationShown &&
                (!isFormInputValid('email', data) ||
                  !!serverValidation['email'])
              }
              errorMessage={
                serverValidation['email'] || 'Please input correct email'
              }
              handleChange={handleChangeField('email')}
            />
          </div>
          <div className='col-12 px-3'>
            <FormInput
              tabIndex={0}
              name='firstName'
              label='First Name'
              value={data.firstName}
              isInvalid={
                isValidationShown && !isFormInputValid('firstName', data)
              }
              errorMessage='This field is required'
              handleChange={handleChangeField('firstName')}
            />
          </div>
          <div className='col-12 px-3'>
            <FormInput
              tabIndex={0}
              name='lastName'
              label='Last Name'
              value={data.lastName}
              isInvalid={
                isValidationShown && !isFormInputValid('lastName', data)
              }
              errorMessage='This field is required'
              handleChange={handleChangeField('lastName')}
            />
          </div>
          <div className='col-12 px-3'>
            <FormInput
              name='phoneNumber'
              label='Phone number'
              value={data.phoneNumber}
              isInvalid={
                isValidationShown && !isFormInputValid('phoneNumber', data)
              }
              errorMessage={`Phone number ${
                getFormInputErrors('phoneNumber', data)[0]
              }`}
              handleChange={handleChangeField('phoneNumber')}
              inputClassName='FormInput__input--phoneNumber'
            >
              <CustomizedPhoneInput
                value={data.phoneNumber}
                onChange={(value) => handleChangeField('phoneNumber')(value)}
                inputClass={
                  isValidationShown && !isFormInputValid('phoneNumber', data)
                    ? 'PhoneInputInput--error'
                    : ''
                }
                inputProps={{
                  tabIndex: 0
                }}
              />
            </FormInput>
          </div>
        </div>
        <div className='mt-2 ClientSignUpModal__description'>
          When you tap on Sign Up you'll get an email with credentials to your
          account.
        </div>
        <Button className='button__full-width' data-testid='signup-btn'>
          Sign Up
        </Button>
        <div className='d-flex align-items-center mt-3 ClientSignUpModal__footer'>
          <span>Already have an acount?</span>
          <Button
            type='button'
            variations={['naked']}
            className='CZ__link'
            handleClick={showSignIn}
          >
            Sign in
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(ClientSignUpModal);
