import React, { useState, useEffect } from 'react';
import './AdminCoachModal.styles.scss';
import { CoachCreateViewModel } from '../../../core/backend/api';
import { getServerModelValidation } from '../../../core/helpers';
import { iOption } from '../../../core/types';
import Button from '../../Button/Button';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import FormInput from '../../FormInput/FormInput';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import Select from '../../Select/Select';

import {
  isFieldInputValid,
  getFieldInputErrors,
  isFormDataValid,
  getDefaultData
} from './AdminCoachModal.helpers';

interface iProps {
  isOpened: boolean;
  close: () => void;
  onSave: (data: CoachCreateViewModel) => Promise<unknown>;
  pricingPlans: iOption[];
}

const AdminCoachModal: React.FC<iProps> = ({
  isOpened,
  close,
  onSave,
  pricingPlans
}) => {
  const [localData, setLocalData] = useState<CoachCreateViewModel>(
    getDefaultData()
  );
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [isEmailUsed, setIsEmailUsed] = useState<boolean>(false);

  useEffect(() => {
    setIsValidationShown(false);
    if (isOpened) {
      setLocalData({
        ...getDefaultData(),
        pricingPlanId: pricingPlans[0].id
      });
    }
  }, [isOpened]);

  const handleChangeField = (field: string) => (value: any) => {
    setLocalData({
      ...localData,
      [field]: field === 'email' ? value.trim() : value
    });

    if (localData.email !== value && isEmailUsed) {
      setIsEmailUsed(false);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isFormDataValid(localData)) {
      setIsValidationShown(false);
      setIsSubmitting(true);
      onSave(localData)
        .catch((error) => {
          setIsValidationShown(true);
          setServerValidation(getServerModelValidation(error));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 648 }}>
      <div className='AdminCoachModal'>
        <ModalHeader>
          <h2>Add user</h2>
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <div className='row mx-n2'>
            <div className='col-sm-6 px-2'>
              <FormInput
                autoFocus
                name='email'
                label='Email'
                value={localData.email}
                isInvalid={
                  isValidationShown &&
                  (!isFieldInputValid('email', localData) ||
                    !!serverValidation['email'])
                }
                errorMessage={
                  getFieldInputErrors('email', localData)[0] ||
                  serverValidation['email']
                }
                handleChange={handleChangeField('email')}
                placeholder='Email'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <Select
                options={pricingPlans}
                value={localData.pricingPlanId}
                title={'Pricing plan'}
                onChange={handleChangeField('pricingPlanId')}
                className='Select--contact-country'
                classNameSuffix='AddContact'
                isError={
                  isValidationShown && !!serverValidation['pricingPlanId']
                }
                errorMessage={serverValidation['pricingPlanId']}
              />
            </div>
            <div className='col-12 px-2 mt-4'>
              <FormCheckbox
                id='isAdmin'
                checked={localData.isAdmin}
                handleChange={() =>
                  handleChangeField('isAdmin')(!localData.isAdmin)
                }
                label='Super Admin'
              />
              <div className='AdminCoachModal__text mt-2'>
                Give this user a Super Admin access to manage other coaches
              </div>
            </div>
          </div>
          <div className='row mt-4 mx-n2'>
            <div className='col-sm-6 px-2'>
              <Button
                className='flex-grow-1'
                type='button'
                variations={['gray', 'full-width', 'sm-width']}
                handleClick={close}
              >
                Cancel
              </Button>
            </div>
            <div className='col-sm-6 px-2'>
              <Button
                className='flex-grow-1'
                variations={['full-width', 'sm-width']}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AdminCoachModal;
