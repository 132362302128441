import React, { useMemo, useState } from 'react';
import Thumbnail from '../../components/Thumbnail';
import { FileResponseViewModelWithExt } from '../../core/types';
import ShareIcon from '../../icons/share.svg';
import moment from 'moment';
import {
  getLinkType,
  getValidLink,
  parseLink
} from '../CoachLibraryAll/CoachLibraryAll.helpers';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  CoachContactsViewModel,
  FileResponseViewModel
} from '../../core/backend/api';
import {
  downloadFileFromMemory,
  getExt,
  openLinkNewTab
} from '../../core/helpers';
import { getCoachFilesLibraryFileID } from '../../core/api';
import Loader from '../../components/Loader/Loader';
import TextEllipse from '../../components/TextEllipse';

interface Props {
  file: FileResponseViewModel;
  onOpenSharingModal: (file: FileResponseViewModelWithExt) => void;
  contacts: CoachContactsViewModel[];
  onOpenVideoModal: (file: FileResponseViewModelWithExt) => void;
}

const SharingHistoryItem: React.FC<Props> = ({
  file,
  onOpenSharingModal,
  contacts,
  onOpenVideoModal
}) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { awsUrl, clientIds } = file;
  const linkType = file.fileLink && getLinkType(file.fileLink);
  const isVideoLink = linkType === 'video';
  const videoLink = isVideoLink ? parseLink(file.fileLink) : undefined;

  const ext = getExt(awsUrl);

  const fileContacts = useMemo(
    () => contacts.filter((contact) => clientIds.includes(contact.clientId)),
    [contacts, clientIds]
  );

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const guid = await getCoachFilesLibraryFileID(file.id);
      downloadFileFromMemory(file.name, ext, guid);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleView = () => {
    onOpenVideoModal({
      ...file,
      ext: 'video',
      fileLink: videoLink
    });
  };

  const handleIconClick = () => {
    if (!file.fileLink) return handleDownload();
    if (!isVideoLink) return openLinkNewTab(getValidLink(file.fileLink));
    handleView();
  };

  const handleOpenSharingModal = () => onOpenSharingModal({ ...file, ext });

  return (
    <div className='SharingHistory__item'>
      {isDownloading && <Loader className='Loader_FileItem' />}
      <div className='SharingHistory__inner'>
        <div className='SharingHistory__image' onClick={handleIconClick}>
          <Thumbnail type={file.fileLink ? linkType : ext} />
        </div>
        <div className='SharingHistory__content'>
          <div className='SharingHistory__date'>
            {moment.utc(file.createdOn).format('ll')}
          </div>
          <div className='SharingHistory__name mb-2'>
            <TextEllipse text={file.name}>
              <span>{file.name}</span>
            </TextEllipse>
          </div>
          {file.fileLink && !isVideoLink ? (
            <a
              href={file.fileLink}
              className='SharingHistory__link'
              target='_blank'
              rel='noopener noreferrer'
            >
              {file.fileLink}
            </a>
          ) : null}
          <div className='SharingHistory__share flex-grow-1 justify-content-end pl-2'>
            <Tooltip
              trigger={
                <div className='SharingHistory__members'>
                  {file.clientIds.length > 1
                    ? `${file.clientIds.length} members`
                    : '1 member'}
                </div>
              }
              content={
                <div>
                  {fileContacts.slice(0, 5).map((contact) => (
                    <div
                      className='SharingHistory__member'
                      key={contact.clientId}
                    >
                      {contact.firstName} {contact.lastName}
                    </div>
                  ))}
                  {fileContacts.length > 5 && (
                    <div className='SharingHistory__member'>
                      and {fileContacts.length - 5} more...
                    </div>
                  )}
                </div>
              }
              minWidth={180}
              autoMaxWidth={false}
            />
            <Tooltip
              className='d-flex align-items-center justify-content-center'
              minWidth={84}
              trigger={
                <button
                  className='SharingHistory__shareButton flex-shrink-0 ml-2'
                  onClick={handleOpenSharingModal}
                >
                  <ShareIcon />
                </button>
              }
              content={<>Edit sharing</>}
              triggerType='hover'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingHistoryItem;
