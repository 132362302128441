import React, { useEffect, useState, useCallback } from 'react';
import './SuperAdminUserAccount.styles.scss';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import ArrowIcon from '../../icons/arrow-left.svg';

import {
  getCoachSuperAdmin,
  updateCoachAccountSuperAdmin,
  resetCoachPasswordSuperAdmin,
  updateCoachAccountIsAdmin
} from '../../core/api';

import { CoachAccountViewModel } from '../../core/backend/api';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { FormConfig, getEmptyAccount } from './SuperAdminUserAccount.helpers';
import PersonalDataForm from '../../components/PersonalDataForm/PersonalDataForm';
import { useStores } from '../../hooks';
import { PersonalData } from '../../components/PersonalDataForm/PersonalDataForm';
import ResetPassword from './ResetPassword';
import Button from '../../components/Button/Button';
import { SuperAdminRoutesEnum } from '../../core/enums';
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';

const SuperAdminUserAccount: React.FC = () => {
  const { rootStore } = useStores();

  const { notificationStore, userStore } = rootStore;

  const history = useHistory();

  const { userId } = useParams<{ userId: string }>();
  const [initialData, setInitialData] = useState<CoachAccountViewModel>(
    getEmptyAccount()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isAdminConfirmationShown, setIsAdminConfirmationShown] = useState(
    false
  );

  const reFetchData = useCallback(() => {
    setIsLoading(true);
    getCoachSuperAdmin(userId)
      .then((newData) => {
        setInitialData(newData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  const handleSavePersonalData = (payload: PersonalData) => {
    return updateCoachAccountSuperAdmin(
      userId,
      payload as CoachAccountViewModel
    )
      .then(() => {
        notificationStore!.addNotification({
          text: `${payload.firstName} ${payload.lastName} account was updated`
        });
        reFetchData();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdminToggle = () => {
    setIsLoading(true);
    updateCoachAccountIsAdmin(userId, !initialData.isAdmin)
      .then(() => {
        setIsAdminConfirmationShown(false);
        setInitialData({ ...initialData, isAdmin: !initialData.isAdmin });

        notificationStore!.addNotification({
          text: `${initialData.firstName} ${initialData.lastName} account was updated`
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleResetPassword = () => {
    resetCoachPasswordSuperAdmin(userId).then(() => {
      notificationStore!.addNotification({
        text: `${initialData.firstName} ${initialData.lastName} password was reseted`
      });
    });
  };

  useEffect(() => {
    reFetchData();
  }, [reFetchData]);

  const handleClickBack = () => {
    history.push(rootStore.prevUrl || SuperAdminRoutesEnum.Users);
  };

  return (
    <>
      <div className='mb-sm-3 mb-2 pb-1'>
        <Button variations={['naked']} handleClick={handleClickBack}>
          <ArrowIcon />
          <span className='ml-2'>Back</span>
        </Button>
      </div>
      <div className='SuperAdminUserAccount p-sm-4 p-3'>
        <GlobalLoader isLoading={isLoading} />
        <PersonalDataForm
          initialData={initialData}
          onSave={handleSavePersonalData}
          formConfig={FormConfig}
          withSiteName
          withAutoSiteName
          withSiteNameView
        />
        <ResetPassword
          className={`row mx-n2 ${
            userStore.id === userId ? 'ResetPassword--no-border-bottom' : ''
          }`}
          onReset={handleResetPassword}
        />

        {userStore.id !== userId && (
          <div className='SuperAdminUserAccount__block SuperAdminUserAccount__block--no-border-bottom'>
            <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
              <div className='SuperAdminUserAccount__title'>Administration</div>
              <div className='SuperAdminUserAccount__text'>
                Give this user a Super Admin access to manage other coaches
              </div>
              <FormCheckbox
                id='isAdmin'
                checked={initialData.isAdmin}
                handleChange={() => setIsAdminConfirmationShown(true)}
                label='Super Admin'
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpened={isAdminConfirmationShown}
        close={() => setIsAdminConfirmationShown(false)}
        title='Warning!'
        text={
          initialData.isAdmin
            ? `Are you sure that you want to refuse Super Admin access to ${initialData.firstName} ${initialData.lastName}?`
            : `Are you sure that you want to provide Super Admin access to ${initialData.firstName} ${initialData.lastName}?`
        }
        confirmCallback={handleAdminToggle}
        confirmBtnText='Yes'
        cancelBtnText='No'
        disableConfirmBtn={isLoading}
      />
    </>
  );
};

export default observer(SuperAdminUserAccount);
