import React from 'react';
import './ConfirmModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';

export interface iProps {
  isOpened: boolean;
  close: () => void;
  title: string;
  text?: string | JSX.Element;
  confirmBtnText: string;
  cancelBtnText?: string;
  confirmCallback: () => void;
  disableConfirmBtn?: boolean;
  disableCancelBtn?: boolean;
  cancelCallback?: () => void;
  confirmBtnClass?: string;
  className?: string;
  testIdPrefix?: string;
}

const ConfirmModal: React.FC<iProps> = ({
  isOpened,
  close,
  title,
  text = '',
  confirmBtnText,
  cancelBtnText = 'Cancel',
  confirmCallback,
  cancelCallback,
  disableConfirmBtn = false,
  disableCancelBtn = false,
  confirmBtnClass = '',
  className = '',
  testIdPrefix = 'confirm-modal'
}) => {
  return (
    <Modal
      className={className}
      close={close}
      isOpened={isOpened}
      style={{ width: 648 }}
      data-testid={testIdPrefix}
    >
      <div className='ConfirmModal'>
        <div
          className='ConfirmModal__title'
          data-testid={`${testIdPrefix}-title`}
        >
          {title}
        </div>
        <div
          className='ConfirmModal__text'
          data-testid={`${testIdPrefix}-text`}
        >
          {text}
        </div>
        <div className='ConfirmModal__footer'>
          <Button
            className='button__sm-width button__inversed'
            handleClick={cancelCallback || close}
            type='button'
            disabled={disableCancelBtn}
            data-testid={`${testIdPrefix}-cancel-button`}
          >
            {cancelBtnText}
          </Button>
          <Button
            className={`button__sm-width ${
              confirmBtnClass ? confirmBtnClass : ''
            }`}
            handleClick={confirmCallback}
            type='button'
            disabled={disableConfirmBtn}
            data-testid={`${testIdPrefix}-confirm-button`}
          >
            {confirmBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
