import React, { useEffect, useState, useRef } from 'react';
import AdminPage from '../../containers/AdminPage/AdminPage';
import CoachPageCustomizeSidebar from '../../components/CoachPageCustomizeSidebar/CoachPageCustomizeSidebar';
import {
  CustomizationStyles,
  ClientCustomizationStyles,
  getDefaultData
} from '../../components/CustomizeSidebar/CustomizeSidebar.helpers';
import {
  BookingItemTypeEnum,
  iBookingItem,
  iCoachPageCustomizationData
} from '../../core/types';
import CoachPage from '../../components/CoachPage/CoachPage';
import {
  getCoachAccount,
  setCoachPageInfo,
  signOutClient
} from '../../core/api';
import { CoachAccountViewModel } from '../../core/backend/api';
import { getEmptyAccount } from '../Account/Account.helpers';
import { BookingItemColorsEnum } from '../../core/enums';
import { getMilliseconds } from '../../core/helpers';
import { getEmptyBookingItem } from '../../store/ClientPopupsStore';
import './CoachPageEditor.styles.scss';
import { useStores } from '../../hooks';
import PreventUnsavedChangesModal from '../../components/Modals/PreventUnsavedEditsModal/PreventUnsavedEditsModal';
import { isFormDataValid } from './CoachPageEditor.helpers';
import { isEmail, isNotEmpty } from '../../core/formValidation';

const CoachPageEditor = () => {
  const dataBeforeEditRef = useRef<CoachAccountViewModel>();

  const [coachCustomizationData, setCoachCustomizationData] = useState<
    iCoachPageCustomizationData
  >(getDefaultData());
  const [coachAccountData, setCoachAccountData] = useState<
    CoachAccountViewModel
  >(getEmptyAccount());
  const [imageId, setImageId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);

  const {
    rootStore: { userStore, notificationStore }
  } = useStores();

  useEffect(() => {
    reFetchData();
  }, []);

  const updateImageId = (value: any) => {
    setCoachAccountData({
      ...coachAccountData,
      imageId: value
    });
  };

  const updateSocialEmail = (value: string) => {
    setCoachAccountData({
      ...coachAccountData,
      socialEmail: value
    });
  };

  useEffect(() => {
    updateImageId(imageId);
  }, [imageId]);

  const reFetchData = () => {
    getCoachAccount()
      .then((data: CoachAccountViewModel) => {
        dataBeforeEditRef.current = data;
        setCoachAccountData(data);
        setImageId(data.imageId);
        if (data.siteCustomizationJson) {
          try {
            setCoachCustomizationData(JSON.parse(data.siteCustomizationJson));
          } catch (err) {
            setCoachCustomizationData(getDefaultData());
          }
        } else {
          setCoachCustomizationData(getDefaultData());
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const bookingItemsList: iBookingItem[] = [
    {
      ...getEmptyBookingItem(),
      id: 1,
      name: 'Sample Paid Group Appointment',
      color: BookingItemColorsEnum.Appointment,
      timestamp: getMilliseconds(10000, 'days') + getMilliseconds(10, 'hours'),
      hasPrice: true,
      type: BookingItemTypeEnum.Appointment
    },
    {
      ...getEmptyBookingItem(),
      id: 2,
      name: 'Sample Appointment Block',
      color: BookingItemColorsEnum.Appointment,
      durationMinutes: 40,
      type: BookingItemTypeEnum.AppointmentBlock
    },
    {
      ...getEmptyBookingItem(),
      id: 3,
      name: 'Sample Event',
      timestamp: getMilliseconds(10002, 'days') + getMilliseconds(14, 'hours')
    },
    {
      ...getEmptyBookingItem(),
      id: 4,
      name: 'Sample Free Group Appointment',
      color: BookingItemColorsEnum.Appointment,
      timestamp: getMilliseconds(10001, 'days') + getMilliseconds(9, 'hours'),
      type: BookingItemTypeEnum.Appointment
    },
    {
      ...getEmptyBookingItem(),
      id: 5,
      name: 'Sample Appointment Block',
      color: BookingItemColorsEnum.Appointment,
      durationMinutes: 60,
      hasPrice: true,
      type: BookingItemTypeEnum.AppointmentBlock
    },
    {
      ...getEmptyBookingItem(),
      id: 6,
      name: 'Sample Appointment Block',
      color: BookingItemColorsEnum.Appointment,
      durationMinutes: 15,
      type: BookingItemTypeEnum.AppointmentBlock
    }
  ];

  const handleView = (siteName?: string) => {
    signOutClient(siteName || coachAccountData.siteName);
  };

  const isFormValid = () => {
    return (
      isFormDataValid(coachCustomizationData) &&
      (isEmail(coachAccountData.socialEmail.toLowerCase()) ||
        !isNotEmpty(coachAccountData.socialEmail))
    );
  };

  const handleSave = () => {
    setIsValidationShown(false);
    if (isFormValid()) {
      let updatedCustomizationData = {
        ...coachCustomizationData,
        socialLinks: coachCustomizationData.socialLinks.filter((l) => !!l)
      };

      if (
        JSON.stringify(updatedCustomizationData) ===
        JSON.stringify(getDefaultData())
      ) {
        updatedCustomizationData = null;
      }

      setCoachPageInfo(
        updatedCustomizationData,
        imageId,
        coachAccountData.socialEmail
      ).then(() => {
        reFetchData();
        userStore.updateData({ imageId });

        notificationStore.addNotification({
          text: 'Customization is saved.'
        });
      });
    } else {
      setIsValidationShown(true);
    }
  };

  // const iFrameLink = isViewMode
  //   ? window.location.origin +
  //     ClientRoutesEnum.CoachPage.replace(':siteName', coachAccountData.siteName)
  //   : '';

  return (
    <AdminPage title='Coach Admin' className='CZ__bg'>
      {/* {isViewMode ? (
        isLoading ? null : (
          <div className='CoachPageEditor__iframe embed-responsive flex-grow-1'>
            <iframe
              className='embed-responsive-item'
              src={iFrameLink}
              allowFullScreen
              title='Coach page editor'
            ></iframe>
          </div>
        )
      ) : ( */}
      <>
        <CoachPageCustomizeSidebar
          data={coachCustomizationData}
          updateViewData={setCoachCustomizationData}
          coachAccountData={coachAccountData}
          handleSave={handleSave}
          handleView={() => handleView()}
          updateSocialEmail={updateSocialEmail}
          isValidationShown={isValidationShown}
        />
        <CustomizationStyles coachData={coachCustomizationData} />
        <ClientCustomizationStyles coachData={coachCustomizationData} />
        <CoachPage
          coachData={coachCustomizationData}
          coachAccountData={coachAccountData}
          bookingItems={bookingItemsList}
          handleTileClick={() => null}
          setNewImageId={setImageId}
          isInEditor
        />
      </>
      {/* )} */}
      <PreventUnsavedChangesModal
        onSave={handleSave}
        disableConfirmBtn={isLoading}
        initialData={JSON.stringify(dataBeforeEditRef.current)}
        currentData={JSON.stringify(coachAccountData)}
        text='Do you want to save your edits on this page?'
        confirmBtnText='Yes'
        cancelBtnText='No'
        className='CZ__disabled'
      />
    </AdminPage>
  );
};

export default CoachPageEditor;
