import React, { useEffect, useState, useRef } from 'react';
import './TimePickerDropdown.styles.scss';
import FormInput from '../FormInput/FormInput';
import DropDownWithPortal from '../DropDownWithPortal/DropDownWithPortal';
import DropDownWithoutPortal from '../DropDownWithoutPortal/DropDownWithoutPortal';
import { useOnClickOutside } from '../../hooks';
import { isMobileDevice } from '../../core/helpers';
import Scrollbars from 'react-custom-scrollbars';

interface iProps {
  timeMs: number;
  onChange: (time: number) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  label?: string;
  minuteStep: number;
  withPortal?: boolean;
  className?: string;
}

interface IOption {
  value: number;
  label: string;
}

const getLabel = (time: number) => {
  const timeInMinutes = time / 60 / 1000;
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  const hours12 = hours % 12 === 0 ? 12 : hours % 12;
  return `${hours12 > 9 ? '' : '0'}${hours12}:${
    minutes > 9 ? '' : '0'
  }${minutes} ${hours >= 12 ? 'pm' : 'am'}`;
};

const DAY_DURATION_MINUTES = 24 * 60;

// const TIME_REGEXP = /((0[0-9])|11|12):([0-5][0-9]) (a|p)m/gi;

const TimePickerDropdown: React.FC<iProps> = ({
  timeMs,
  onChange,
  isInvalid = false,
  isDisabled,
  errorMessage,
  label,
  minuteStep,
  withPortal,
  className = '',
  ...rest
}) => {
  const [value, setValue] = useState<string>('');
  const [options, setOptions] = useState<IOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const dropdown = useRef<Scrollbars>();
  const timepickerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const list = new Array(Math.floor(DAY_DURATION_MINUTES / minuteStep))
      .fill(0)
      .map((_, index) => {
        const value = index * minuteStep * 60 * 1000;

        return {
          value: value,
          label: getLabel(value)
        };
      });

    setOptions(list);
  }, [minuteStep]);

  useOnClickOutside(
    timepickerRef,
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  useEffect(() => {
    const index = options.findIndex((o) => o.value === timeMs);
    setValue(index > -1 ? options[index].label : getLabel(timeMs));
    setActiveIndex(index);
  }, [options, timeMs]);

  useEffect(() => {
    if (isOpen) {
      dropdown.current.scrollTop(activeIndex * 40);
    }
  }, [isOpen, activeIndex]);

  const handleBlur = () => {
    const opt = options.find((o) =>
      o.label.replace(/(:| )+/g, '').includes(value.replace(/(:| )+/g, ''))
    );
    if (opt) {
      onChange(opt.value);
      setValue(opt.label);
    }
    setIsOpen(false);
  };

  const handleFocus = (
    event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>
  ) => {
    setIsOpen(!isOpen);
  };

  const handleSelectTime = (opt: IOption) => {
    setIsOpen(false);
    onChange(opt.value);
  };

  const handleChange = (value: string) => {
    const index = options.findIndex(
      (o) =>
        o.label.replace(/(:| )+/g, '').indexOf(value.replace(/(:| )+/g, '')) ===
        0
    );
    if (index > -1) {
      setActiveIndex(index);
      setValue(value);
    }
  };

  const renderList = () => {
    return (
      <Scrollbars ref={dropdown} style={{ height: 200 }}>
        <ul>
          {options.map((opt, index) => (
            <li
              className={`${opt.value === timeMs ? 'selected' : ''} ${
                index === activeIndex ? 'active' : ''
              }`}
              key={opt.value}
              onMouseDown={() => handleSelectTime(opt)}
            >
              {opt.label}
            </li>
          ))}
        </ul>
      </Scrollbars>
    );
  };

  return (
    <div
      className={`TimePickerDropdown ${className} ${isOpen ? 'open' : ''}`}
      ref={timepickerRef}
      {...rest}
    >
      <FormInput
        handleChange={handleChange}
        onBlur={handleBlur}
        value={value}
        label={label}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
        className='FormInput--no-mt'
        inputClassName='TimePickerDropdown__input'
        isDisabled={isDisabled}
        onFocus={handleFocus}
      >
        {isMobileDevice() ? <div onClick={handleFocus}>{value}</div> : null}
      </FormInput>
      {withPortal ? (
        <DropDownWithPortal
          triggerRef={timepickerRef}
          shown={isOpen}
          onClose={() => setIsOpen(false)}
        >
          {renderList()}
        </DropDownWithPortal>
      ) : (
        <DropDownWithoutPortal shown={isOpen}>
          {renderList()}
        </DropDownWithoutPortal>
      )}
    </div>
  );
};

export default TimePickerDropdown;
