import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import './MoreInvitesForEventModal.styles.scss';
import Modal from '../../Modal/Modal';
import { subTitles, width_options } from './MoreInvitesForEventModal.helpers';
import Loader from '../../Loader/Loader';
import ModalHeader from '../../ModalHeader/ModalHeader';
import StepsVisualizer from '../../StepsVisualizer/StepsVisualizer';
import { CoachEvent } from '../../../core/types';
import InfoBlock from '../../InfoBlock/InfoBlock';
import SummaryStep from '../../SummaryStep/SummaryStep';
import InviteClients from '../../InviteClients/InviteClients';
import { updateEvent } from '../../../core/api';
import Button from '../../Button/Button';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useMedia, useStores } from '../../../hooks';
import { StripeRefundStatusEnum, ScheduleItemType } from '../../../core/enums';
import PromptModal from '../PromptModal/PromptModal';

interface iProps {
  isOpened: boolean;
  close: () => void;
  data: CoachEvent;
  onUpdate: () => void;
}

const MoreInvitesForEventModal: React.FC<iProps> = ({
  isOpened,
  close,
  data,
  onUpdate
}) => {
  const {
    rootStore: { userStore, incrementChangesCounter }
  } = useStores();
  const isDesktop = useMedia(['(min-width: 992px)'], [true], false);

  const { members: initialMembers, invites: initialInvites } = data;
  const [invites, setInvites] = useState<string[]>([]);
  const [membersToRemove, setMembersToRemove] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const inviteClientsPlaceholder = useMedia(
    ['(min-width: 992px)', '(min-width: 480px)'],
    ['Start typing', "Start typing client's email to send an invite"],
    'Start typing'
  );

  const isPaid = !!+data.price;

  const handleClickNext = () => setCurrentStep(currentStep + 1);

  useEffect(() => {
    if (isOpened) {
      setMembersToRemove([]);
      setInvites(initialInvites);
      setCurrentStep(1);
    }
  }, [isOpened]);

  const handleSave = () => {
    if (membersToRemove.length > 0) {
      setIsConfirmModalOpened(true);
    } else {
      updateCurrentEvent();
    }
  };

  const updateCurrentEvent = () => {
    handleCloseConfirmModal();
    setIsLoading(true);
    updateEvent({
      ...data,
      invites,
      membersToRemove
    })
      .then(() => {
        incrementChangesCounter();
        onUpdate();
        handleClickNext();
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          incrementChangesCounter();
          onUpdate();
          handleClickNext();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpened(false);
  };

  const handleAcceptConfirmation = () => {
    updateCurrentEvent();
  };

  const handleCancelConfirmation = () => {
    setMembersToRemove([]);
    handleCloseConfirmModal();
  };

  const steps = [
    null,
    <div className='MoreInvitesForEventModal__step'>
      <div className='MoreInvitesForEventModal__columns'>
        <InfoBlock
          data={{
            id: data.id,
            type: ScheduleItemType.Event,
            userName: `${userStore.firstName} ${userStore.lastName}`,
            name: data.name,
            membersCountLimitString: `${data.availableSpots} spots`,
            datesEvent: [
              data.startDateTimeStamp + data.startTimeMs,
              data.endDateTimeStamp + data.endTimeMs
            ],
            price: data.price,
            timeZone: userStore.timeZone,
            showCoachAvatar: false,
            remindBefore: data.remindBeforeMs,
            isRemindBeforeDisabled: true,
            imageId: data.thumbnailImageId,
            description: data.description
          }}
          className='InfoBlock--MoreInvitesForEventModal'
        />
        <div className='MoreInvitesForEventModal__separator d-lg-none'></div>
        <div className='d-flex flex-column MoreInvitesForEventModal__inviteClientsContainer'>
          <div className='flex-grow-1'>
            <InviteClients
              className='InviteClients--MoreInvitesForEventModal'
              invites={invites}
              initialInvites={initialInvites}
              initialMembers={initialMembers}
              setInvites={setInvites}
              membersToRemove={membersToRemove}
              setMembersToRemove={setMembersToRemove}
              autoFocus={isDesktop}
              errorMessage={'Please, add at least one client'}
              placeholder={inviteClientsPlaceholder}
            />
          </div>
          <div className='MoreInvitesForEventModal__footer MoreInvitesForEventModal__footer--items-to-right'>
            <Button className={`button__md-width`} handleClick={handleSave}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>,
    <SummaryStep
      greetingMessage='Congratulations! The meeting is updated.'
      link={data.location}
    />
  ];

  const hasMembersForRefund = data.members
    .filter((member) =>
      membersToRemove.some((clientId) => member.clientId === clientId)
    )
    .some((m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded);

  const cofirmModalData = {
    title:
      isPaid && hasMembersForRefund
        ? 'Remove a member(-s) from the meeting'
        : 'Cancel registration',
    text:
      isPaid && hasMembersForRefund
        ? 'This is a paid meeting. If you remove this person(-s) will be automatically refunded. Do you want to proceed?'
        : 'Are you sure that you want to cancel registration for the client(s)?'
  };

  return (
    <>
      <Modal
        close={close}
        isOpened={isOpened}
        style={{
          width: width_options[currentStep],
          overflow: 'auto'
        }}
      >
        {isLoading && <Loader />}
        <div className='MoreInvitesForEventModal'>
          <ModalHeader>
            <h2>Setup Meeting</h2>
            <h3>{subTitles[currentStep]}</h3>
          </ModalHeader>
          <StepsVisualizer currentStep={currentStep} stepsTotal={1} />
          {steps[currentStep]}
        </div>
      </Modal>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={handleCloseConfirmModal}
        title={cofirmModalData.title}
        text={cofirmModalData.text}
        confirmBtnText='Yes'
        confirmCallback={handleAcceptConfirmation}
        cancelCallback={handleCancelConfirmation}
        cancelBtnText='No'
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            Some payments for removed clients were made using your previous
            Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
    </>
  );
};

export default observer(MoreInvitesForEventModal);
