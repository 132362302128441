import { iFormConfigItem } from '../../core/types';
import {
  getInputErrors,
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty
} from '../../core/formValidation';

const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] },
  { field: 'phoneNumber', checkIsValid: [isNotEmpty] }
];

export function isTheInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getTheInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isTheDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
