import React, { ReactElement, useState, useRef, useCallback } from 'react';
import DropDownWithPortal from '../DropDownWithPortal/DropDownWithPortal';
import DropDownWithoutPortal from '../DropDownWithoutPortal/DropDownWithoutPortal';
import './Dropdown.styles.scss';
import { useMedia, useOnClickOutside, useOverflow } from '../../hooks';
import Button from '../Button/Button';
import Tooltip, { Props as TooltipProps } from '../Tooltip/Tooltip';

export interface ListItemProps {
  name: string;
  callback: () => void;
  icon?: any;
  dataTestid?: string;
}

export type Alignment = 'right' | 'left' | 'center';

interface iProps {
  trigger: JSX.Element;
  children?: (cb?: () => void) => ReactElement<any>;
  className?: string;
  align?: Alignment;
  withPortal?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
}

const Dropdown: React.FC<iProps> = ({
  className = '',
  children,
  trigger,
  align = 'left',
  withPortal = true,
  fullScreen = false,
  onClose
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const isMobile = useMedia(['(min-width: 576px)'], [false], true);
  const dropdown = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    setIsDropdownOpened(false);
    onClose && onClose();
  }, [onClose]);

  useOnClickOutside(dropdown, handleClose, isDropdownOpened && !withPortal);

  useOverflow(isDropdownOpened && isDropdownOpened, 'dropdown-opened');

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsDropdownOpened(!isDropdownOpened);
  };

  let triggerProps: Record<string, any> = {
    ref: buttonRef,
    className: `${trigger.props.className} Dropdown__trigger`
  };

  if (trigger.type === Button) {
    triggerProps['handleClick'] = handleClickButton;
  } else if (trigger.type === Tooltip) {
    triggerProps['trigger'] = React.cloneElement(
      (trigger.props as TooltipProps).trigger,
      {
        onClick: handleClickButton,
        ref: buttonRef
      }
    );
    delete triggerProps.ref;
  } else {
    triggerProps['onClick'] = handleClickButton;
  }

  const triggerElem = React.cloneElement(trigger, triggerProps);

  return (
    <div
      className={`Dropdown ${className} ${
        isDropdownOpened ? 'Dropdown--opened' : ''
      }`}
      ref={dropdown}
    >
      {triggerElem}
      {withPortal ? (
        <DropDownWithPortal
          triggerRef={buttonRef}
          shown={isDropdownOpened}
          onClose={handleClose}
          fullScreen={fullScreen && isMobile}
          align={align}
        >
          {children(handleClose)}
        </DropDownWithPortal>
      ) : (
        <DropDownWithoutPortal
          shown={isDropdownOpened}
          align={align}
          fullScreen={fullScreen && isMobile}
          onClose={handleClose}
        >
          {children(handleClose)}
        </DropDownWithoutPortal>
      )}
    </div>
  );
};

export default Dropdown;
