import React from 'react';
import { getPublicFile } from '../../core/api';
import { CoachAccountViewModel } from '../../core/backend/api';
import { InvoiceStatusEnum, InvoiceTypeEnum, TaxType } from '../../core/enums';
import { Invoice } from '../../core/types';
import { capitalize, normalizePrice } from '../../core/helpers';
import Button from '../Button/Button';
import InvoiceCardClientBlock from '../InvoiceCardClientBlock/InvoiceCardClientBlock';
import { CustomizationInvoiceStyles } from './InvoiceCard.helpers';
import './InvoiceCard.styles.scss';
import Badge from '../Badge/Badge';
import Warning from '../Warning/Warning';

interface iProps {
  coachData: Partial<CoachAccountViewModel>;
  className?: string;
  invoiceData: Partial<Invoice>;
  isClient?: boolean;
  isStripeConnected?: boolean;
  link?: string;
  buttonsBlock?: JSX.Element;
}

const InvoiceCard: React.FC<iProps> = ({
  link,
  coachData,
  invoiceData,
  className = '',
  isClient = false,
  isStripeConnected = true,
  buttonsBlock
}) => {
  if (!invoiceData) {
    return null;
  }
  const isRefunded = invoiceData.status === InvoiceStatusEnum.refunded;
  const isOneTimePaid =
    invoiceData.status === InvoiceStatusEnum.paid &&
    invoiceData.type === InvoiceTypeEnum.OneTime;

  const isDisabled =
    invoiceData.status === InvoiceStatusEnum.canceled ||
    isRefunded ||
    isOneTimePaid ||
    !isStripeConnected ||
    (!!invoiceData.subscriptionId && !invoiceData.subscription);
  const isUncollectible =
    invoiceData.status === InvoiceStatusEnum.uncollectible;

  const subTotal =
    invoiceData?.services?.reduce(
      (acc, s) => acc + (s.quantity || 0) * (s.price || 0),
      0
    ) || 0;
  const tax = invoiceData.tax
    ? invoiceData.tax.type === TaxType.Amount
      ? invoiceData.tax.value
      : Math.round(invoiceData.tax.value * subTotal) / 100
    : 0;

  const badgeTitle =
    (isRefunded && 'This invoice is already refunded') ||
    (isOneTimePaid && 'This invoice is already paid') ||
    'This invoice is unavailable';

  const renderServices = () => {
    return invoiceData.services.map((s, index) => {
      return (
        <div
          key={index}
          className='pb-4 d-flex justify-content-between CZ__invoice-field'
        >
          <p className='InvoiceCard__service-title'>{s.title}</p>
          <div className='d-flex justify-content-between ml-3 flex-grow-1'>
            <p>x{s.quantity}</p>
            <p>${normalizePrice(s.price * s.quantity)}</p>
          </div>
        </div>
      );
    });
  };

  buttonsBlock = buttonsBlock || (
    <Button
      className='mt-2 CZ__invoice-primary-bg'
      link={link || ''}
      variations={['full-width']}
    >
      Pay this invoice
    </Button>
  );

  return (
    <>
      {invoiceData && coachData && (
        <div
          className={`InvoiceCard ${className} ${
            isDisabled ? 'InvoiceCard--disabled' : ''
          }`}
        >
          <div className='InvoiceCard__coach-info d-flex align-items-center justify-content-between mb-2 mb-sm-3 CZ__invoice-headline-text CZ__invoice-secondary-bg'>
            <div>
              <p className='mb-1 InvoiceCard__order-id'>{invoiceData.number}</p>
              <p className='mb-1 InvoiceCard__coach-initials'>
                Invoice from {coachData.firstName} {coachData.lastName}
              </p>
              {/* <p className='InvoiceCard__coach-address'>
                {coachData.city} {coachData.state}
              </p> */}
            </div>
            {invoiceData.customizeData.logoSrc && (
              <img
                src={getPublicFile(invoiceData.customizeData.logoSrc)}
                alt=''
                className='InvoiceCard__logo'
              />
            )}
          </div>
          <div className='p-4 mb-2 mb-sm-3 InvoiceCard__invoice-info d-flex flex-column'>
            {isDisabled && (
              <Badge
                className='mb-3 align-self-center'
                type='red'
                title={badgeTitle}
              />
            )}
            <InvoiceCardClientBlock
              invoiceData={invoiceData}
              amount={subTotal + tax}
            />

            {isUncollectible &&
              (isClient ? (
                <Warning
                  className='mb-2 mb-sm-3'
                  type='red'
                  title='Failed payment'
                >
                  ${normalizePrice(invoiceData.amount)} payment to{' '}
                  {coachData.firstName} {coachData.lastName} was unsuccessful.{' '}
                  {invoiceData.paymentMethod ? (
                    <span>
                      We were unable to charge your{' '}
                      {capitalize(invoiceData.paymentMethod?.card?.brand)}{' '}
                      ending in {invoiceData.paymentMethod?.card?.last4} for
                      your invoice {invoiceData.number}. Please, retry payment
                      or enter a new payment method using the buttons below.
                    </span>
                  ) : (
                    <span>
                      This could happen when a credit card doesn't have enough
                      funds or is declined by a bank for other reasons.
                    </span>
                  )}
                </Warning>
              ) : (
                <Warning
                  className='mb-2 mb-sm-3'
                  type='red'
                  title='Failed payment'
                >
                  ${invoiceData.amount} payment from{' '}
                  {invoiceData.client.firstName} {invoiceData.client.lastName}{' '}
                  was unsuccessful. This could happen when a credit card doesn't
                  have enough funds or is declined by a bank for other
                  reasons.  
                </Warning>
              ))}
            {buttonsBlock}
          </div>
          <div className='p-4 InvoiceCard__summary mb-2 mb-sm-3 CZ__invoice-field'>
            <div className='InvoiceCard__services'>
              <p className='InvoiceCard__title mb-4 CZ__invoice-field-secondary'>
                SUMMARY
              </p>
              {invoiceData.services.length ? renderServices() : ''}
            </div>
            <div className='row flex-row-reverse'>
              <div className='col-6 pl-2'>
                {invoiceData.tax && (
                  <>
                    <div className='d-flex justify-content-between mt-4 pl-1'>
                      <p>Subtotal</p>
                      <p>${normalizePrice(subTotal)}</p>
                    </div>
                    <div className='d-flex justify-content-between mt-4 pl-1'>
                      <p>{invoiceData.tax.name}</p>
                      <p>${normalizePrice(tax)}</p>
                    </div>
                  </>
                )}
                <div className='d-flex justify-content-between InvoiceCard__total mt-4 pl-1'>
                  <p>Total</p>
                  <p>${normalizePrice(subTotal + tax)}</p>
                </div>
              </div>
            </div>
          </div>
          {invoiceData.memo && (
            <div className='p-4 InvoiceCard__notes'>
              <p className='InvoiceCard__title mb-4 CZ__invoice-field-secondary'>
                NOTES
              </p>
              <div className='d-flex'>
                {coachData.imageId ? (
                  <img
                    src={getPublicFile(coachData.imageId)}
                    alt=''
                    className='InvoiceCard__coach-img'
                  />
                ) : (
                  <div className='InvoiceCard__user-circle mr-2 flex-shrink-0'>
                    {coachData.firstName.charAt(0)}
                    {coachData.lastName.charAt(0)}
                  </div>
                )}
                <p
                  className='InvoiceCard__memo d-flex align-items-center CZ__invoice-field'
                  dangerouslySetInnerHTML={{
                    __html: `${invoiceData.memo
                      .split('\n')
                      .map((r) => r + '<br/>')
                      .join('')}`
                  }}
                ></p>
              </div>
            </div>
          )}
          <CustomizationInvoiceStyles
            customizeData={invoiceData.customizeData}
          />
        </div>
      )}
    </>
  );
};

export default InvoiceCard;
