import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  CancelType,
  useCancelConfirmationModal,
  useDebouncedCallback,
  useStores
} from '../../hooks';
import {
  getClientSchedule,
  cancelAppointment,
  getAppointment,
  getAppointmentBlock,
  cancelClientScheduleEvent,
  cancelEvent,
  getEvent
} from '../../core/api';
import {
  normalizeClientScheduleAppointmentFromBackEnd,
  normalizeClientScheduleEventFromBackEnd
} from '../../core/normalization';
import {
  CoachClientScheduleItem as ItemModel,
  Appointment,
  AppointmentBlock
} from '../../core/types';
import {
  StripeRefundStatusEnum,
  EventStatus,
  NotificationTypesEnum,
  ScheduleItemType
} from '../../core/enums';
import { CoachRoutesEnum } from '../../core/enums';

import CoachClient from '../../containers/CoachClient/CoachClient';
import { getDefaultData as getEmptyAppointment } from '../../components/Modals/AppointmentModal/AppointmentModal.helpers';
import { getDefaultData as getEmptyAppointmentBlock } from '../../pages/CoachAppointmentBlock/CoachAppointmentBlock.helpers';
import RescheduleAppointment from '../../components/Modals/RescheduleAppointment/RescheduleAppointment';
import CoachClientScheduleList from '../../components/CoachClientScheduleList/CoachClientScheduleList';
import CoachClientScheduleItem from '../../components/CoachClientScheduleList/CoachClientScheduleItem';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';

import { getTimeInUtc } from '../../core/helpers';
import CoachInfoBlockModal from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import CoachInfoBlockModalActions from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModalActions';
import { getCoachClientScheduleInfoBlockData } from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal.helpers';
import { iCoachInfoBlockData } from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import PageTitle from '../../components/PageTitle/PageTitle';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';

const CoachClientSchedule: React.FC<RouteComponentProps<{
  clientId: string;
}>> = ({
  match: {
    params: { clientId }
  }
}) => {
  const history = useHistory();
  const {
    rootStore: {
      userStore,
      contactsStore: { contacts },
      notificationStore
    }
  } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [upcoming, setUpcoming] = useState<ItemModel[]>([]);
  const [completed, setCompleted] = useState<ItemModel[]>([]);
  const [isViewItemOpen, setIsViewItemOpen] = useState<boolean>(false);
  const [infoBlockData, setInfoBlockData] = useState<iCoachInfoBlockData>(null);
  const [activeItem, setActiveItem] = useState<
    ItemModel & { membersRefunded?: boolean }
  >(null);
  const [
    isRescheduleAppointmentOpen,
    setIsRescheduleAppointmentOpen
  ] = useState<boolean>(false);
  const [rescheduleAppointmentData, setRescheduleAppointmentData] = useState<{
    data: Appointment;
    appointmentBlockData: AppointmentBlock;
  }>({
    data: getEmptyAppointment(),
    appointmentBlockData: getEmptyAppointmentBlock()
  });
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const [selectedEventId, setSelectedEventId] = useState<number>(0);
  const [selectedEventType, setSelectedEventType] = useState<ScheduleItemType>(
    null
  );

  const [isCancelling, setIsCancelling] = useState(false);
  const {
    confirmModalData,
    setConfirmModalData
  } = useCancelConfirmationModal();

  const setCancellingDone = useDebouncedCallback(() => {
    setIsCancelling(false);
  }, 450);

  const currentTimeStamp = moment.utc().valueOf();

  useEffect(() => {
    reFetchData();
  }, [clientId]);

  const reFetchData = () => {
    setIsLoading(true);
    getClientSchedule(clientId)
      .then((res) => {
        const data = [
          ...res.getAppointments.map((i) =>
            normalizeClientScheduleAppointmentFromBackEnd(i)
          ),
          ...res.getEvents.map((i) =>
            normalizeClientScheduleEventFromBackEnd(i)
          )
        ];
        setUpcoming(
          data
            .filter(
              (i) =>
                getTimeInUtc(i.timestamp, userStore.timeZone) > currentTimeStamp
            )
            .sort((a, b) => a.timestamp - b.timestamp)
        );
        setCompleted(
          data
            .filter(
              (i) =>
                getTimeInUtc(i.timestamp, userStore.timeZone) < currentTimeStamp
            )
            .sort((a, b) => b.timestamp - a.timestamp)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleView = async (item: ItemModel) => {
    setActiveItem(item);
    if (item.itemType !== ScheduleItemType.Appointment) {
      setIsLoading(true);
      getEvent(item.id)
        .then((data) => {
          setInfoBlockData(
            getCoachClientScheduleInfoBlockData(
              item,
              userStore.timeZone,
              clientId,
              data.members,
              data.invites
            )
          );
          setIsViewItemOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setInfoBlockData(
        getCoachClientScheduleInfoBlockData(
          item,
          userStore.timeZone,
          clientId,
          [
            {
              ...contacts.find((c) => c.clientId === clientId),
              clientStatus: EventStatus[item.status],
              refundStatus: item.refundStatus
            }
          ],
          []
        )
      );
      setIsViewItemOpen(true);
    }
  };

  const handleEdit = (item: ItemModel) => {
    if (item.itemType === ScheduleItemType.OneToOne) {
      history.push(
        CoachRoutesEnum.ScheduleMeetingDetails.replace(
          ':meetingId',
          item.id.toString()
        )
      );
    } else if (item.itemType === ScheduleItemType.Event) {
      history.push(
        CoachRoutesEnum.MeetingDetails.replace(':meetingId', item.id.toString())
      );
    } else {
      getAppointment(item.id).then((appointment) => {
        getAppointmentBlock(appointment.appointmentTypeId).then(
          (appointmentBlock) => {
            setRescheduleAppointmentData({
              data: appointment,
              appointmentBlockData: appointmentBlock
            });
            setIsRescheduleAppointmentOpen(true);
            setIsViewItemOpen(false);
          }
        );
      });
    }
  };

  const closeConfirmModal = () => setIsConfirmModalOpened(false);

  const handleCancel = (cancelType: CancelType) => async (item: ItemModel) => {
    const { id, itemType, price } = item;

    let membersRefunded =
      item.refundStatus === StripeRefundStatusEnum.Succeeded;

    if (itemType === ScheduleItemType.Event && cancelType === 'allClients') {
      try {
        setIsLoading(true);
        const event = await getEvent(item.id);
        membersRefunded = event.members.every(
          (m) => m.refundStatus === StripeRefundStatusEnum.Succeeded
        );
      } finally {
        setIsLoading(false);
      }
    }

    setConfirmModalData(
      itemType,
      price === 0,
      item.activeMembersCount,
      cancelType,
      membersRefunded
    );
    setActiveItem({ ...item, membersRefunded });
    setSelectedEventId(id);
    setSelectedEventType(itemType);
    setIsConfirmModalOpened(true);
  };

  const cancelItem = (
    id: number,
    itemType: ScheduleItemType,
    withRefund = false
  ) => {
    let cancel: (
      id: number,
      withRefund: boolean
    ) => Promise<unknown> = cancelAppointment;

    if (itemType !== ScheduleItemType.Appointment) {
      if (confirmModalData.cancelType === 'singleClient') {
        cancel = cancelClientScheduleEvent.bind(null, clientId);
      } else {
        cancel = cancelEvent;
      }
    }

    setIsLoading(true);

    setIsCancelling(true);
    cancel(id, withRefund)
      .then(() => {
        setSelectedEventId(0);
        setActiveItem(null);
        closeConfirmModal();
        setIsViewItemOpen(false);
        reFetchData();
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          setSelectedEventId(0);
          setActiveItem(null);
          closeConfirmModal();
          setIsViewItemOpen(false);
          reFetchData();
        } else {
          notificationStore.addNotification({
            text: 'Something went wrong.',
            textColored: 'Try one more time,\n please.',
            duration: 3000,
            type: NotificationTypesEnum.Error
          });
        }
      })
      .finally(() => {
        setCancellingDone();
      });
  };

  const confirmRefundConfirmation = () =>
    cancelItem(
      selectedEventId,
      selectedEventType,
      activeItem.price > 0 && !activeItem.membersRefunded
    );

  const handleRejectConfirm = () => {
    if (
      activeItem.price === 0 ||
      activeItem.itemType === ScheduleItemType.OneToOne ||
      activeItem.itemType === ScheduleItemType.Appointment ||
      (activeItem.itemType === ScheduleItemType.Event &&
        (activeItem.membersRefunded ||
          confirmModalData.cancelType === 'singleClient'))
    ) {
      return closeConfirmModal();
    }

    cancelItem(selectedEventId, selectedEventType, false);
  };

  const renderViewModal = () => {
    return (
      <CoachInfoBlockModal
        isOpened={isViewItemOpen}
        data={infoBlockData}
        close={() => setIsViewItemOpen(false)}
        actions={
          <CoachInfoBlockModalActions
            data={infoBlockData}
            onCancel={() => handleCancel('allClients')(activeItem)}
            onEdit={() => handleEdit(activeItem)}
          />
        }
      />
    );
  };

  return (
    <CoachClient>
      <PageTitle title='Schedule' />
      <GlobalLoader isLoading={isLoading} />
      <CoachClientScheduleList
        title='Upcoming'
        eventsList={upcoming}
        subTitle={
          !isLoading && !!upcoming.length
            ? 'These are meetings or appoiments this client has signed up for.'
            : 'You don’t have an appointment or appointments that this client has subscribed to.'
        }
        ItemRenderer={({ item }) => (
          <CoachClientScheduleItem
            className='mb-3'
            item={item}
            onView={handleView}
            onEdit={handleEdit}
            onCancel={handleCancel('allClients')}
            onCancelRegistration={handleCancel('singleClient')}
          />
        )}
      />

      <CoachClientScheduleList
        title='Completed'
        eventsList={completed}
        subTitle={
          !isLoading && !completed.length
            ? 'You don’t have a completed appointment or appointments that this client has subscribed to.'
            : ''
        }
        ItemRenderer={({ item }) => (
          <CoachClientScheduleItem
            className='mb-3'
            item={item}
            isCompleted={true}
            onView={handleView}
          />
        )}
      />

      {renderViewModal()}
      <RescheduleAppointment
        isOpened={isRescheduleAppointmentOpen}
        close={() => setIsRescheduleAppointmentOpen(false)}
        rescheduled={reFetchData}
        {...rescheduleAppointmentData}
      />
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={closeConfirmModal}
        title={confirmModalData.title}
        text={confirmModalData.text}
        confirmBtnText={confirmModalData.confirmBtnText}
        confirmCallback={confirmRefundConfirmation}
        disableConfirmBtn={isCancelling}
        disableCancelBtn={isCancelling}
        cancelBtnText={confirmModalData.cancelBtnText}
        cancelCallback={handleRejectConfirm}
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            Some payments were made using your previous Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
    </CoachClient>
  );
};

export default CoachClientSchedule;
