import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { iRoute } from './routes';
import ClientCoachPage from '../pages/ClientCoachPage/ClientCoachPage';
import ClientCalendar from '../pages/ClientCalendar/ClientCalendar';
import ClientInvites from '../pages/ClientInvites/ClientInvites';
import ClientArchive from '../pages/ClientArchive/ClientArchive';
import ClientLibraryDownloads from '../pages/ClientLibraryDownloads/ClientLibraryDownloads';
import ClientLibraryNotes from '../pages/ClientLibraryNotes/ClientLibraryNotes';
import ClientAccount from '../pages/ClientAccount/ClientAccount';
import { ClientRoutesEnum } from './enums';
import { getRoutePath } from './globals';
import ClientDashboard from '../pages/ClientDashboard/ClientDashboard';
import ClientCompleteRegistration from '../pages/ClientCompleteRegistration/ClientCompleteRegistration';
import ClientBookingPage from '../pages/ClientBookingPage/ClientBookingPage';
// import ClientPayments from '../pages/ClientPayments/ClientPayments';
import ClientBilling from '../pages/ClientBilling/ClientBilling';
import ClientInvoiceView from '../pages/ClientInvoiceView/ClientInvoiceView';
// import ClientInvoices from '../pages/ClientInvoices/ClientInvoices';
import { useFeatures, useStores } from '../hooks';
import ClientPayInvoice from '../components/Modals/ClientPayInvoice/ClientPayInvoice';
import ClientPage from '../containers/ClientPage/ClientPage';
import GlobalLoader from '../components/GlobalLoader/GlobalLoader';
import { usePrevious } from '../hooks/usePrevious';

const ClientAfterPreLoadRoutes: iRoute[] = [
  { path: ClientRoutesEnum.CoachPage, component: ClientCoachPage, exact: true },
  {
    path: ClientRoutesEnum.CoachPageNewPass,
    component: ClientCoachPage,
    exact: true
  },
  {
    path: ClientRoutesEnum.InvoicePay,
    component: ClientPayInvoice
  },
  {
    path: ClientRoutesEnum.Invoice,
    component: ClientInvoiceView
  },
  { path: ClientRoutesEnum.Booking, component: ClientBookingPage },
  {
    path: '*',
    redirectTo: ClientRoutesEnum.CoachPage,
    type: 'redirect'
  }
];
// this routes set is not used now
const ClientAfterSignedInRoutes: iRoute[] = [
  {
    path: ClientRoutesEnum.CompleteRegistration,
    component: ClientCompleteRegistration,
    exact: true
  },
  {
    path: '*',
    redirectTo: ClientRoutesEnum.CompleteRegistration,
    type: 'redirect'
  }
];

const ClientSignedInRoutes: iRoute[] = [
  // { path: ClientRoutesEnum.CoachPageSignedIn, component: ClientCoachPage },
  { path: ClientRoutesEnum.Invites, component: ClientInvites },
  { path: ClientRoutesEnum.Archive, component: ClientArchive },
  { path: ClientRoutesEnum.Calendar, component: ClientCalendar },
  // { path: ClientRoutesEnum.Payments, component: ClientPayments },
  { path: ClientRoutesEnum.Billing, component: ClientBilling },
  { path: ClientRoutesEnum.InvoicePay, component: ClientPayInvoice },
  { path: ClientRoutesEnum.Invoice, component: ClientInvoiceView },
  // { path: ClientRoutesEnum.Invoices, component: ClientInvoices },
  { path: ClientRoutesEnum.Account, component: ClientAccount },
  { path: ClientRoutesEnum.Dashboard, component: ClientDashboard },
  { path: ClientRoutesEnum.Downloads, component: ClientLibraryDownloads },
  { path: ClientRoutesEnum.Notes, component: ClientLibraryNotes },
  {
    path: ClientRoutesEnum.Library,
    redirectTo: ClientRoutesEnum.Downloads,
    type: 'redirect'
  },
  { path: ClientRoutesEnum.Booking, component: ClientBookingPage },
  {
    path: '*',
    redirectTo: ClientRoutesEnum.Dashboard,
    type: 'redirect'
  }
];

const redirectToLoginRoutes = [
  ClientRoutesEnum.Invoice,
  ClientRoutesEnum.InvoicePay
];

const ClientRoutes = () => {
  const { pathname, search } = useLocation();
  const { rootStore } = useStores();
  const { invoicesEnabled } = useFeatures();
  const { userStore } = rootStore;
  const wasSignedIn = usePrevious(userStore.isSignedIn);

  useEffect(() => {
    rootStore.getCoachInfo();
  }, [rootStore]);

  let routes: iRoute[];

  const renderRoutes = (routes: iRoute[]) => (
    <Switch>
      {routes
        .filter((r) => invoicesEnabled || !r.path.includes('/invoices'))
        .map((route) => {
          if (route.type === 'redirect') {
            return (
              <Route
                key={`redirect-${route.path}`}
                exact
                path={route.path}
                render={() => {
                  if (!userStore.isSignedIn && !wasSignedIn) {
                    const redirectTo = redirectToLoginRoutes.find(
                      (r) => r === route.path
                    );
                    const query = redirectTo
                      ? `?redirectTo=${pathname + search}`
                      : '';
                    return (
                      <Redirect
                        to={`${getRoutePath(route.redirectTo)}${query}`}
                      />
                    );
                  } else {
                    const redirectTo = userStore.redirectToAfterSignIn;
                    if (redirectTo) {
                      userStore.setRedirectToAfterSignIn('');
                      return <Redirect to={redirectTo} />;
                    }
                    return <Redirect to={getRoutePath(route.redirectTo)} />;
                  }
                }}
              />
            );
          }
          return <Route key={route.path} {...route} />;
        })}
    </Switch>
  );

  if (rootStore.isCoachDataLoaded) {
    if (userStore.isDataLoaded && userStore.isSignedIn) {
      if (userStore.isRegistrationCompleted) {
        routes = ClientSignedInRoutes.filter(
          (r) => invoicesEnabled || !r.path.includes('/invoices')
        );
      } else {
        // this is not reachable now
        // because in store if userStore.isRegistrationCompleted is false
        // userStore.isDataLoaded not set to true, and we send request to complete registration
        routes = ClientAfterSignedInRoutes;
      }
    } else {
      routes = ClientAfterPreLoadRoutes;
    }
  }

  return (
    <>
      <GlobalLoader isLoading={!rootStore.isCoachDataLoaded} />
      <ClientPage>
        {rootStore.isCoachDataLoaded ? renderRoutes(routes) : null}
      </ClientPage>
    </>
  );
};

export default observer(ClientRoutes);
