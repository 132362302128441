import React, { ReactNode, useEffect, useState } from 'react';
import './SignedPage.scss';
import Page from '../Page/Page';
import PageHeader from '../../components/PageHeader/PageHeader';
import SideMenu from '../../components/SideMenu/SideMenu';
import { CoachRoutesEnum } from '../../core/enums';
import { useLocation } from 'react-router-dom';
import { useMedia } from '../../hooks';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/useStores';
import { useFeatures } from '../../hooks/useFeatures';

interface iProps {
  children: ReactNode;
  className?: string;
  title?: string;
  style?: any;
}

const SignedPage: React.FC<iProps> = ({
  children,
  className = '',
  title = '',
  style = {}
}) => {
  const {
    rootStore: { userStore }
  } = useStores();
  const { invoicesEnabled } = useFeatures();
  const isTablet = useMedia(['(min-width: 992px)'], [false], true);

  const [sideMenuOpened, setSideMenuOpened] = useState<boolean>(!isTablet);
  const location = useLocation();

  const isClientPageEditor =
    location.pathname.indexOf(CoachRoutesEnum.ClientPageEditor) >= 0;

  const isEditorPage =
    location.pathname.indexOf(CoachRoutesEnum.CoachPageEditor) >= 0 ||
    isClientPageEditor;

  useEffect(() => {
    setSideMenuOpened(!isTablet);
  }, [isTablet]);

  const handleSideMenuClose = () => {
    setSideMenuOpened(false);
  };

  return (
    <Page
      className={`
          SignedPage
          ${
            sideMenuOpened && !isTablet && !isEditorPage
              ? 'Page--collapsed'
              : ''
          }
          ${className}
          ${isEditorPage ? 'SignedPage--collapsed-right' : ''}
          ${isClientPageEditor ? 'SignedPage--collapsed-left' : ''}
      `}
      title={title}
      style={style}
      landscapeRoutesDisabled={[
        CoachRoutesEnum.InvoiceNew,
        CoachRoutesEnum.InvoiceEdit
      ]}
    >
      <PageHeader
        onSideMenuToggle={setSideMenuOpened}
        sideMenuOpened={sideMenuOpened}
        isEditor={isEditorPage}
      />
      <SideMenu
        menuList={[
          {
            name: 'Dashboard',
            iconName: 'MenuDashboardIcon',
            path: CoachRoutesEnum.Dashboard,
            hasWarnings:
              userStore.isTimeZoneDiffers ||
              userStore.isGoogleWasDisconnected ||
              userStore.wasStripeConnected
          },
          {
            name: 'Schedule',
            iconName: 'MenuScheduleIcon',
            path: CoachRoutesEnum.Schedule
            // childMenuItems: [
            //   {
            //     name: 'Schedule',
            //     path: CoachRoutesEnum.Calendar
            //   },
            //   {
            //     name: 'Availability blocks',
            //     path: CoachRoutesEnum.AppointmentBlocks
            //   },
            //   {
            //     name: 'Group events',
            //     path: CoachRoutesEnum.Meetings
            //   }
            // ]
          },
          {
            name: 'Clients',
            iconName: 'MenuContactsIcon',
            path: CoachRoutesEnum.CoachClients
          },

          // {
          //     name: 'Stats',
          //     iconName: 'MenuStatsIcon',
          //     path: '/notSet',
          // },
          {
            name: 'Pages',
            iconName: 'MenuPagesIcon',
            path: CoachRoutesEnum.Pages
          },
          {
            name: 'Library',
            iconName: 'MenuTrainingIcon',
            path: CoachRoutesEnum.Library
          },
          ...(invoicesEnabled
            ? [
                {
                  name: 'Payments',
                  iconName: 'MenuPaymentsIcon',
                  childMenuItems: [
                    {
                      name: 'Transactions',
                      path: CoachRoutesEnum.Transactions
                    },
                    {
                      name: 'Invoices',
                      path: CoachRoutesEnum.Invoices
                    }
                  ]
                }
              ]
            : [])
        ]}
        isOpened={sideMenuOpened && !isEditorPage}
        isTablet={isTablet}
        onClose={handleSideMenuClose}
      />
      <div className={`SignedPage__content p-md-4 p-sm-3 p-2`}>{children}</div>
    </Page>
  );
};

export default observer(SignedPage);
