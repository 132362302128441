import React, { useState, useCallback } from 'react';
import CoachClient from '../../containers/CoachClient/CoachClient';
import TransactionsList from '../../components/TransactionsList/TransactionsList';
import { getColumns } from '../../components/TransactionsList/TransactionsList.helpers';
import {
  ConfirmModalEnum,
  InvoiceStatusEnum,
  NotificationTypesEnum,
  RefundStatusEnum,
  ScheduleItemType,
  TransactionsEntityEnum,
  EventStatus,
  SortOrder,
  CoachRoutesEnum
} from '../../core/enums';
import { useLocation, useParams } from 'react-router-dom';
import {
  cancelAppointment,
  cancelClientEvent,
  cancelClientScheduleEvent,
  cancelEvent,
  deleteCoachPayment,
  getCoachClientBilling,
  getEvent,
  refundClientPayment
} from '../../core/api';
import { BillingItemViewModel, MeetingType } from '../../core/backend/api';
import { useFeatures, useStores } from '../../hooks';
import CoachInfoBlockModal, {
  iCoachInfoBlockData
} from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import { getFromMilliseconds } from '../../core/helpers';
import { PaginatedResponse } from '../../core/types';
import axios from 'axios';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import Button from '../../components/Button/Button';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';
import { storeDataBeforeStripeConnect } from '../../core/integrations';

const PAGE_SIZE = 20;

const CoachClientTransactions = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const { invoicesEnabled } = useFeatures();
  const { rootStore } = useStores();
  const { notificationStore, userStore } = rootStore;

  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const page = params.get('page');
  const sortBy = params.get('sortBy');
  const sortOrder = +params.get('sortOrder') as SortOrder;
  const searchText = params.get('searchText');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PaginatedResponse<BillingItemViewModel>>({
    items: [],
    totalCount: 0
  });
  const [infoBlockData, setInfoBlockData] = useState<iCoachInfoBlockData>(null);
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );

  const loadData = () => {
    fetchTransactions(+page, PAGE_SIZE, sortBy, sortOrder, searchText);
  };

  const fetchTransactions = useCallback(
    (
      page: number,
      pageSize: number,
      sortBy: string,
      sortOrder: SortOrder,
      searchText: string
    ) => {
      if (userStore.isStripeConnected) {
        setIsLoading(true);
        getCoachClientBilling(
          clientId,
          page,
          pageSize,
          sortBy,
          sortOrder,
          searchText
        )
          .then((res) => {
            setData(res);
            setIsLoading(false);
          })
          .catch((e) => {
            if (!(e instanceof axios.Cancel)) {
              setIsLoading(false);
            }
          });
      }
    },
    [userStore.isStripeConnected, clientId]
  );

  const getActions = useCallback(
    (actionCallback: (type: any, item: BillingItemViewModel) => void) => [
      {
        title: 'Give refund',
        callback: (item: BillingItemViewModel) => {
          actionCallback(ConfirmModalEnum.refund, item);
        },
        isAvailable: (item: BillingItemViewModel) => {
          return (
            ((item.status as unknown) as InvoiceStatusEnum) ===
            InvoiceStatusEnum.paid
          );
        }
      }
      // {
      //   title: 'Delete',
      //   callback: (item: BillingItemViewModel) => {
      //     actionCallback(ConfirmModalEnum.delete, item);
      //   },
      //   isAvailable: (item: BillingItemViewModel) => {
      //     return (
      //       ((item.status as unknown) as InvoiceStatusEnum) ===
      //       InvoiceStatusEnum.refunded
      //     );
      //   }
      // }
    ],
    []
  );

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, null);
  };

  const handleConfirmRefund = (
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnsDisabled: (i: boolean) => void
  ) => () => {
    setIsConfirmBtnsDisabled(true);
    setIsLoading(true);
    refundClientPayment(activeRecord.stripePaymentIntentId)
      .then((res) => {
        if (
          res === RefundStatusEnum.SuccessfullyCreated ||
          res === RefundStatusEnum.CreatedInStripe
        ) {
          notificationStore.addNotification({
            text: 'Refund',
            textColored: 'is done',
            duration: 3000
          });
        }
        if (
          res === RefundStatusEnum.StripeException ||
          res === RefundStatusEnum.NotSucceeded
        ) {
          showErrorNotification();
        }
        loadData();
      })
      .catch(() => {
        showErrorNotification();
      })
      .finally(() => {
        closeModal();
      });
  };

  const showErrorNotification = () => {
    notificationStore.addNotification({
      text: 'Automatic refund failed.',
      textColored:
        'Try to give a refund\n manually using your\n Stripe account.',
      type: NotificationTypesEnum.Error
    });
  };

  const handleConfirmDelete = (
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnsDisabled: (i: boolean) => void
  ) => () => {
    setIsConfirmBtnsDisabled(true);
    setIsLoading(true);
    deleteCoachPayment(activeRecord.id)
      .then(() => {
        loadData();

        notificationStore.addNotification({
          text: `Transaction`,
          textColored: 'deleted successfully'
        });
      })
      .catch(() => {
        notificationStore.addNotification({
          text: `Transaction`,
          textColored: 'delete failed',
          type: NotificationTypesEnum.Error
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  const handleRefundAndCancel = (
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnsDisabled: (i: boolean) => void
  ) => () => {
    let cancel: () => Promise<unknown>;

    if (activeRecord.entity === TransactionsEntityEnum.Appointment) {
      cancel = () => cancelAppointment(activeRecord.entityId);
    }
    if (activeRecord.entity === TransactionsEntityEnum.Meeting) {
      if (
        (activeRecord.coachEvent?.type as number) === ScheduleItemType.OneToOne
      ) {
        cancel = () => cancelEvent(activeRecord.entityId, true);
      } else {
        cancel = () =>
          cancelClientScheduleEvent(clientId, activeRecord.entityId);
      }
    }

    setIsConfirmBtnsDisabled(true);
    cancel()
      .then(() => {
        closeModal();
        loadData();
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          closeModal();
          loadData();
        } else {
          notificationStore.addNotification({
            text: 'Something went wrong.',
            textColored: 'Try one more time,\n please.',
            duration: 3000,
            type: NotificationTypesEnum.Error
          });
        }
      })
      .finally(() => {
        setIsConfirmBtnsDisabled(false);
      });
  };

  const getConfimModalData = (
    confirmModalType: ConfirmModalEnum,
    activeRecord: BillingItemViewModel,
    closeModal: () => void,
    setIsConfirmBtnsDisabled: (i: boolean) => void
  ) => {
    const text =
      activeRecord?.entity === TransactionsEntityEnum.Appointment ||
      activeRecord?.entity === TransactionsEntityEnum.Meeting
        ? activeRecord.entity + ' '
        : 'Invoice #';

    if (confirmModalType === ConfirmModalEnum.delete) {
      return {
        title: `Delete record for ${text}${activeRecord?.entityName}`,
        text: 'Are you sure you want to remove this record from the list?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        confirm: handleConfirmDelete(
          activeRecord,
          closeModal,
          setIsConfirmBtnsDisabled
        )
      };
    }
    if (confirmModalType === ConfirmModalEnum.refund) {
      const text =
        activeRecord?.entity === TransactionsEntityEnum.Appointment ||
        activeRecord?.entity === TransactionsEntityEnum.Meeting
          ? activeRecord.entity + ' '
          : 'Invoice #';
      const title = `Give refund for ${text}${activeRecord?.entityName}`;
      const confirmBtnText = 'Give refund';
      const questionText =
        'Do you want to give a refund or give a refund and cancel';
      const cancelText = confirmBtnText + ' & Cancel';

      const confirm = handleConfirmRefund(
        activeRecord,
        closeModal,
        setIsConfirmBtnsDisabled
      );

      const cancel = handleRefundAndCancel(
        activeRecord,
        closeModal,
        setIsConfirmBtnsDisabled
      );

      if (activeRecord.entity === TransactionsEntityEnum.Appointment) {
        return {
          title,
          text: `${questionText} this appointment?`,
          confirmBtnText,
          cancelBtnText: `${cancelText} appointment`,
          confirm,
          cancel
        };
      }

      if (activeRecord.entity === TransactionsEntityEnum.Meeting) {
        const isScheduled =
          (activeRecord.coachEvent.type as number) ===
          ScheduleItemType.OneToOne;
        return {
          title,
          text: isScheduled
            ? `${questionText} this meeting?`
            : `${questionText} registration for the client?`,
          confirmBtnText,
          cancelBtnText: isScheduled
            ? `${cancelText} meeting`
            : `${cancelText} registration`,
          confirm,
          cancel
        };
      }

      return {
        title: `Give refund for ${text}${activeRecord?.entityName}`,
        text: 'Are you sure you want to give a refund for this transaction?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        confirm: handleConfirmRefund(
          activeRecord,
          closeModal,
          setIsConfirmBtnsDisabled
        )
      };
    }
  };

  const handleView = (item: BillingItemViewModel) => {
    if (item.entity === TransactionsEntityEnum.Meeting) {
      const entityData = item.coachEvent;
      if (!entityData) return;
      setIsLoading(true);
      getEvent(item.entityId)
        .then((res) => {
          setInfoBlockData({
            id: entityData.id,
            type: entityData.type as number,
            name: entityData.name,
            userName: '',
            durationMinutes: getFromMilliseconds(
              entityData.durationMs,
              'minutes'
            ),
            membersCountLimitString:
              entityData.availableSpots === 1 ? 'One-on-One' : 'Group',
            price: entityData.price.toString(),
            location: entityData.location,
            date: entityData.timestamp,
            showCoachAvatar: false,
            timeZone: userStore.timeZone,
            imageId: entityData.thumbnailImageId,
            remindBefore: entityData.remindBeforeMs,
            showSelectBefore: false,
            isCanceled: (entityData.status as number) !== EventStatus.Active,
            members: res.members,
            invites: res.invites
          });
          setIsViewPopupOpened(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const entityData = item.appointment;
      if (!entityData) return;
      setInfoBlockData({
        id: entityData.id,
        type: ScheduleItemType.Appointment,
        name: entityData.name,
        userName: '',
        durationMinutes: getFromMilliseconds(entityData.durationMs, 'minutes'),
        membersCountLimitString:
          entityData.availableSpots === 1 ? 'One-on-One' : 'Group',
        price: entityData.price.toString(),
        location: entityData.location,
        date: entityData.timestamp,
        showCoachAvatar: false,
        timeZone: userStore.timeZone,
        imageId: entityData.thumbnailImageId,
        remindBefore: entityData.remindBeforeMs,
        showSelectBefore: false,
        isCanceled: (entityData.status as number) !== EventStatus.Active,
        members: entityData.members,
        invites: entityData.invites
      });
      setIsViewPopupOpened(true);
    }
  };

  return (
    <CoachClient className='CoachClientTransactions'>
      {userStore.isStripeConnected ? (
        <div className='mx-sm-0 mx-n3'>
          <TransactionsList
            title='Transactions'
            flow={rootStore.flow}
            onView={handleView}
            itemsOnPage={PAGE_SIZE}
            totalCount={data.totalCount}
            isLoading={isLoading}
            columns={getColumns(rootStore.flow, handleView, invoicesEnabled)}
            getActions={getActions}
            transactions={data.items}
            rowHeight={73}
            headerHeight={40}
            fetchTransactions={fetchTransactions}
            getConfimModalData={getConfimModalData}
          />
          <CoachInfoBlockModal
            isOpened={isViewPopupOpened}
            close={() => setIsViewPopupOpened(false)}
            data={infoBlockData}
          />
        </div>
      ) : (
        <div
          className='position-relative flex-grow-1 mx-sm-0 mx-n2'
          style={{ minHeight: '290px' }}
        >
          <EmptyState
            size='md'
            text="Client doesn't have payments yet. Connect a Stripe account to receive payments and create invoices."
            absoluteCenter
            icon={<EmptyStateIcon />}
            style={{ width: '320px' }}
          >
            <Button
              type='button'
              className='button__md-width button__mt ml-0'
              link={CoachRoutesEnum.ConnectStripe}
              handleClick={handleConnectStripe}
            >
              Connect Stripe
            </Button>
          </EmptyState>
        </div>
      )}
      <PromptModal
        text={
          <>
            Some payments were made using your previous Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
    </CoachClient>
  );
};

export default CoachClientTransactions;
