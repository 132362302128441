import { CoachContactsViewModel } from '../../../core/backend/api';
import { iFormConfigItem } from '../../../core/types';
import {
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty,
  getInputErrors
} from '../../../core/formValidation';
import Countries from '../../../json/countries.json';
import States from '../../../json/states-usa.json';
import { iOption } from '../../../core/types';

export const reducer = (
  storedData: CoachContactsViewModel,
  newData: CoachContactsViewModel
) => newData;

export const getDefaultData = (): CoachContactsViewModel => ({
  clientId: null,
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  state: '',
  city: '',
  phoneNumber: '1',
  timeZone: '',
  isGuest: false
});

const FormConfig: iFormConfigItem[] = [
  {
    field: 'firstName',
    checkIsValid: [(value, data) => (data.isGuest ? true : isNotEmpty(value))]
  },
  {
    field: 'lastName',
    checkIsValid: [(value, data) => (data.isGuest ? true : isNotEmpty(value))]
  },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] }
  // { field: 'country', checkIsValid: [isNotEmpty]},
  // { field: 'state', checkIsValid: [isNotEmpty]},
  // { field: 'city', checkIsValid: [isNotEmpty]},
  // { field: 'phoneNumber', checkIsValid: [isNotEmpty]},
];

export function isContactInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isContactDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}

export function getContactInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}

interface iCountry {
  id: number;
  name: string;
}

export interface iState {
  id: number;
  name: string;
  country_id: number;
  state_code: string;
}

export const USA_COUNTRY_ID = '233';

const normalizeCountriesToIOption = (list: iCountry[]) => {
  return list.map((item: iCountry) => {
    return {
      id: item.id + '',
      value: item.id + '',
      name: item.name
    };
  });
};

const normalizeStatesToIOption = (list: iState[]) => {
  return list.map((item: iState) => {
    return {
      id: item.id + '',
      value: item.country_id + '',
      name: item.name
    };
  });
};

export const CountriesList: iOption[] = normalizeCountriesToIOption(Countries);
export const StatesList: iOption[] = normalizeStatesToIOption(States);

export const getCountryId = (
  name: string,
  isEdit: boolean = false,
  hasDefaultId: boolean = true
) => {
  const country = isEdit
    ? CountriesList.find((country) => country.name === name)
    : false;
  return country ? country.id : hasDefaultId ? CountriesList[232].id : '';
};

export const getStatesByCountry = (countryId: string) => {
  return StatesList.filter((state) => state.value === countryId);
};

export const getStateId = (
  name: string,
  states: iOption[],
  isEdit: boolean = false
) => {
  const state = isEdit ? states.find((state) => state.name === name) : false;
  return state ? state.id : states[0].id;
};

export const getCityId = (
  name: string,
  cities: iOption[],
  isEdit: boolean = false
) => {
  const city = isEdit ? cities.find((city) => city.name === name) : false;
  return city ? city.id : '';
};
