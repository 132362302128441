import { useState, useCallback } from 'react';
import { Invoice } from '../../../core/types';

export const useSendInvoiceModal = (
  invoice: Invoice,
  api: (invoice?: Invoice) => Promise<any>
) => {
  const [isSendInvoiceModalOpened, setIsSendInvoiceModalOpened] = useState(
    false
  );
  const [isSending, setIsSending] = useState(false);

  const closeSendInvoiceModal = () => {
    setIsSendInvoiceModalOpened(false);
  };

  const openSendInvoiceModal = useCallback(() => {
    setIsSendInvoiceModalOpened(true);
  }, []);

  const sendInvoice = () => {
    setIsSending(true);
    return api(invoice).finally(() => {
      setIsSending(false);
    });
  };

  return {
    isSendInvoiceModalOpened,
    isSending,
    closeSendInvoiceModal,
    openSendInvoiceModal,
    sendInvoice
  };
};
