import React, { useState, useEffect, useMemo, useRef } from 'react';

import './WYSIWYGEditor.styles.scss';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface iProps {
  onChange: (value: string) => void;
  HTMLString?: string;
  wrapperClassName?: string;
  tabIndex?: number;
  testId?: string;
}

const toolbarSettings = {
  options: [
    'inline',
    'blockType',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'emoji',
    'history'
  ],
  inline: {
    options: ['bold', 'italic', 'monospace']
  },
  textAlign: {
    inDropdown: true
  },
  list: {
    options: ['unordered', 'ordered']
  }
};

const WYSIWYGEditor: React.FC<iProps> = ({
  wrapperClassName = '',
  onChange,
  HTMLString = '',
  tabIndex,
  testId
}) => {
  const ref = useRef<object>();
  const { entityMap } = convertFromHTML(HTMLString);
  const contentBlock = htmlToDraft(HTMLString);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlock.contentBlocks, entityMap)
    )
  );

  const value = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  useEffect(() => {
    if (value !== HTMLString) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (value !== HTMLString) {
      const contentBlock = htmlToDraft(HTMLString);

      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
            entityMap
          )
        )
      );
    }
  }, [HTMLString, entityMap]);

  useEffect(() => {
    const editorRefObj = ref.current as object & { editor: HTMLDivElement };
    if (testId && editorRefObj) {
      (editorRefObj.editor as HTMLDivElement)?.setAttribute(
        'data-testid',
        testId
      );
    }
  }, [testId, ref]);

  return (
    <Editor
      editorRef={(node) => {
        ref.current = node;
      }}
      tabIndex={tabIndex}
      editorState={editorState}
      wrapperClassName={`WYSIWYGEditor__wysiwyg-wrapper ${wrapperClassName}`}
      toolbarClassName='WYSIWYGEditor__wysiwyg-toolbar'
      editorClassName='WYSIWYGEditor__wysiwyg-editor Scrollbar'
      onEditorStateChange={setEditorState}
      toolbar={toolbarSettings}
      stripPastedStyles={true}
    />
  );
};

export default WYSIWYGEditor;
