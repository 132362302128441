import React from 'react';
import ReactDOM from 'react-dom';
import './Notification.styles.scss';
import CheckedIcon from '../../icons/checked-notification.svg';
import ErrorIcon from '../../icons/exclamation_circle.svg';
import { observer } from 'mobx-react';
import { iNotification } from '../../store/NotificationStore';
import cn from 'classnames';
import { NotificationTypesEnum } from '../../core/enums';
import { useStores } from '../../hooks';

const Notification = () => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const handleClick = (id: number) => () =>
    notificationStore.removeNotification(id);

  return ReactDOM.createPortal(
    <div className='NotificationList mx-2 mt-2 mx-sm-3 mt-sm-3 mx-md-4 mt-md-4'>
      {notificationStore!.notifications.length
        ? notificationStore!.notifications.map(
            (notification: iNotification) => {
              const isError = notification.type === NotificationTypesEnum.Error;
              return (
                <div
                  className={cn('Notification', {
                    Notification_error: isError
                  })}
                  key={notification.id}
                  onClick={handleClick(notification.id)}
                >
                  <div className='Notification__icon flex-shrink-0'>
                    {isError ? <ErrorIcon /> : <CheckedIcon />}
                  </div>
                  <div className='Notification__content'>
                    <div className='Notification__text'>
                      {notification.text}
                    </div>
                    <div
                      className={cn('Notification__text', {
                        Notification__text_success: !isError,
                        Notification__text_error: isError
                      })}
                    >
                      {typeof notification.textColored === 'string'
                        ? notification.textColored
                            ?.split('\n')
                            .map((line, i) => <p key={i}>{line}</p>)
                        : notification.textColored}
                    </div>
                  </div>
                </div>
              );
            }
          )
        : null}
    </div>,
    document.getElementById('notifications')!
  );
};

export default observer(Notification);
