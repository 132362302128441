import React from 'react';
import PencilIcon from '../../icons/pencil-circle.svg';
import './CoachClientCard.styles.scss';
import OneOnOneIcon from '../../icons/one-on-one.svg';
import { getPublicFile } from '../../core/api';
import { CoachContactItem } from '../../core/types';
import Tooltip from '../Tooltip/Tooltip';

interface Props {
  client: CoachContactItem;
  onEdit?: () => void;
}

interface Field {
  title: string;
  field: keyof CoachContactItem;
}

const fields: Field[] = [
  { title: 'Name', field: 'fullName' },
  { title: 'Phone', field: 'formattedPhoneNumber' },
  { title: 'Email', field: 'email' },
  { title: 'Location', field: 'address' }
];

const CoachClientCard: React.FC<Props> = ({ client, onEdit }) => {
  return (
    <div className='CoachClientCard p-3'>
      {onEdit && (
        <button className='CoachClientCard__editButton' onClick={onEdit}>
          <Tooltip
            className='position-relative'
            trigger={<PencilIcon />}
            content={<>Edit</>}
            triggerType='hover'
          />
        </button>
      )}
      <div className='d-flex align-items-center justify-content-center'>
        <div className='CoachClientCard__avatar'>
          {client.imageId ? (
            <img src={getPublicFile(client.imageId)} alt='avatar' />
          ) : (
            <OneOnOneIcon />
          )}
        </div>
      </div>
      {fields.map((field) => {
        return (
          <div
            className='d-flex mb-2 justify-content-md-center justify-content-start'
            key={field.field}
          >
            <div className='CoachClientCard__field mr-3'>{field.title}:</div>
            <div className='CoachClientCard__fieldValue flex-grow-1'>
              {client[field.field]}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CoachClientCard;
