import React, { ReactNode } from 'react';
import './CoachLibrary.styles.scss';
import TabsMenu, { iTab } from '../../components/TabsMenu/TabsMenu';
import { CoachRoutesEnum } from '../../core/enums';

interface iProps {
  children: ReactNode;
  className?: string;
}

const CoachLibrary: React.FC<iProps> = ({ children, className }) => {
  const tabs: iTab[] = [
    {
      name: 'All files',
      link: CoachRoutesEnum.Library
    },
    {
      name: 'Sharing history',
      link: CoachRoutesEnum.LibraryHistory
    }
  ];

  return (
    <div className='CoachLibrary p-sm-4 p-3'>
      <div className='CoachLibrary__header mb-3 mb-sm-0'>
        <TabsMenu tabsList={tabs} />
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export default CoachLibrary;
