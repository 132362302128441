import React from 'react';
import './ButtonWithActiveState.styles.scss';
import CheckedIcon from '../../icons/checked.svg';

interface iProps {
  isActive: boolean;
  title: any;
  subTitle: string;
  handleClick: () => void;
  className?: string;
}

const ButtonWithActiveState: React.FC<iProps> = ({
  isActive,
  title,
  subTitle,
  handleClick,
  className = ''
}) => (
  <div
    key={title}
    className={`ButtonWithActiveState ${
      isActive ? 'ButtonWithActiveState--active' : ''
    } ${className}`}
    onClick={handleClick}
    data-testid={`btn-${title}-${subTitle}`}
  >
    {isActive ? (
      <div className='ButtonWithActiveState__icon'>
        <CheckedIcon />
      </div>
    ) : null}
    <div
      className={`ButtonWithActiveState__value ${
        isActive ? 'ButtonWithActiveState__value--active' : ''
      }`}
    >
      {title}
    </div>
    <div
      className={`ButtonWithActiveState__text ${
        isActive ? 'ButtonWithActiveState__text--active' : ''
      }`}
    >
      {subTitle}
    </div>
  </div>
);

export default ButtonWithActiveState;
