import React, { ReactNode, useEffect, useState } from 'react';
import './CoachSchedulePage.styles.scss';
import { CoachRoutesEnum } from '../../core/enums';
import TabsMenu, { iTab } from '../../components/TabsMenu/TabsMenu';
import { getAppointmentBlockInvalidZoom } from '../../core/api';
import WarningIcon from '../../icons/warning.svg';
import PageTitle from '../../components/PageTitle/PageTitle';

interface Props {
  children: ReactNode;
  title: string;
}

const CoachSchedulePage: React.FC<Props> = ({ children, title }) => {
  const [
    isAppointmentBlockInvalidZoom,
    setIsAppointmentBlockInvalidZoom
  ] = useState(false);

  useEffect(() => {
    getAppointmentBlockInvalidZoom().then((res) => {
      setIsAppointmentBlockInvalidZoom(res);
    });
  }, []);

  const tabs: iTab[] = [
    {
      name: 'Schedule',
      link: CoachRoutesEnum.Calendar
    },
    // {
    //     name: 'Requests',
    //     link: CoachRoutesEnum.Requests,
    // },
    {
      name: 'Appointment Blocks',
      link: CoachRoutesEnum.AppointmentBlocks,
      icon: isAppointmentBlockInvalidZoom ? (
        <div className='d-flex align-items-center mr-2 SchedulePage__icon'>
          <WarningIcon />
        </div>
      ) : null
    },
    {
      name: 'Meetings',
      link: CoachRoutesEnum.Meetings
    }
  ];

  return (
    <>
      <PageTitle title={title} />
      <TabsMenu tabsList={tabs} className='mt-sm-0 mt-1' />
      {children}
    </>
  );
};

export default CoachSchedulePage;
