import React, { useEffect, useRef, useState } from 'react';
import { Invoice, StripeSubscription } from '../../core/types';
import PaymentMethodModal from '../../components/Modals/PaymentMethodModal/PaymentMethodModal';
import {
  CardNumberElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import PaymentResultModal from '../../components/Modals/PaymentResultModal/PaymentResultModal';
import ChangePaymentMethodModal from '../../components/Modals/ChangePaymentMethodModal/ChangePaymentMethodModal';
import {
  cancelInvoiceSubscription,
  updateInvoiceSubscription
} from '../../core/api';
import { iPaymentData } from '../../components/PaymentDetails/PaymentDetails.helpers';
import { StripeError } from '@stripe/stripe-js';
import { useStores } from '../../hooks/useStores';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { NotificationTypesEnum } from '../../core/enums';

interface Props {
  invoice: Invoice;
  onSuccess: () => void;
  onFail?: () => void;
}

export interface ClientInvoiceStripeActionsRef {
  onRetry: () => void;
  onChangeMethod: () => void;
  onCancelSubscription: () => void;
}

const ClientInvoiceStripeActions = React.forwardRef<
  ClientInvoiceStripeActionsRef,
  Props
>(({ invoice, onSuccess, onFail = () => {} }, ref) => {
  const {
    rootStore: { notificationStore, coachInfo }
  } = useStores();
  const innerRef = useRef<ClientInvoiceStripeActionsRef>();
  const [isRetryPaymentModalOpened, setIsRetryPaymentModalOpened] = useState<
    boolean
  >(false);
  const [isPaymentResultModalOpened, setIsPaymentResultModalOpened] = useState<
    boolean
  >(false);
  const [paymentResult, setPaymentResult] = useState<'succeeded' | 'error'>();
  const [isRetryingPayment, setIsRetryingPayment] = useState<boolean>(false);
  const [isChangeMethodModalOpened, setIsChangeMethodModalOpened] = useState<
    boolean
  >(false);
  const [isChangingMethod, setIsChangingMethod] = useState<boolean>(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<StripeError>(null);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState<boolean>(
    false
  );
  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    innerRef.current = {
      onRetry,
      onChangeMethod,
      onCancelSubscription
    };
  }, []);

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  }, [innerRef, ref]);

  const onRetry = () => {
    setIsRetryPaymentModalOpened(true);
  };

  const onChangeMethod = () => {
    setIsChangeMethodModalOpened(true);
  };

  const onCancelSubscription = () => {
    setIsCancelConfirmOpen(true);
  };

  const handleRetryPaymentConfirm = async () => {
    setIsRetryingPayment(true);

    const paymentIntent = invoice.subscription?.latest_invoice?.payment_intent;

    if (
      paymentIntent?.status === 'requires_action' ||
      paymentIntent?.status === 'requires_payment_method' ||
      paymentIntent?.status === 'requires_confirmation'
    ) {
      try {
        const stripeResponse = await stripe.confirmCardPayment(
          paymentIntent?.client_secret,
          {
            payment_method: invoice.paymentMethod?.id
          }
        );
        if (stripeResponse.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          handleRetryPaymentClose();
          handlePaymentResultOpen('error');
        } else {
          handleRetryPaymentClose();
          handlePaymentResultOpen('succeeded');
        }
      } catch (e) {
        handleRetryPaymentClose();
        handlePaymentResultOpen('error');
        onFail();
      }
    }
  };

  const handlePaymentResultConfirm = () => {
    handlePaymentResultClose();
    if (paymentResult === 'succeeded') {
      onSuccess();
    } else {
      setIsChangeMethodModalOpened(true);
    }
  };

  const handleChangeMethodSubmit = async (data: iPaymentData) => {
    setIsChangingMethod(true);
    try {
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: elements!.getElement(CardNumberElement)!,
        billing_details: {
          email: invoice.client.email,
          address: {
            postal_code: data.postalCode
          }
        }
      });
      if (stripeResponse.error) {
        setIsChangingMethod(false);
        setStripeError(stripeResponse.error);
        setIsPaymentFailed(true);
        onFail();
      } else {
        try {
          const subscription = await updateInvoiceSubscription(
            invoice.number,
            stripeResponse.paymentMethod.id,
            invoice.client.clientId
          );
          const result = await handlePaymentThatRequiresCustomerAction({
            subscription,
            paymentMethodId: stripeResponse.paymentMethod.id
          });
          if (result && result.subscription) {
            handleChangeMethodClose();
            if (result.subscription.status === 'active') {
              onSuccess();
              notificationStore.addNotification({
                text: `Invoice #${invoice.number}`,
                textColored: 'payment method changed successfully',
                duration: 3000
              });
            } else {
              handlePaymentResultOpen('succeeded');
            }
          }
        } catch (e) {
          setIsChangingMethod(false);
          if (
            (e.stripeError && e.stripeError.code === 'resource_missing') ||
            e.status === 400
          ) {
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: `Invoice ${invoice.number}`,
              textColored: (
                <>
                  You are unable to change payment method for this invoice.
                  <br /> Please, contact your coach at{' '}
                  <a
                    className='link'
                    href={`mailto:${coachInfo?.supportEmail}`}
                  >
                    {coachInfo?.supportEmail}
                  </a>
                </>
              ),
              duration: 8000
            });
            handleChangeMethodClose();
          } else if (e.stripeError) {
            setStripeError(e.stripeError);
            setIsPaymentFailed(true);
          } else {
            handleChangeMethodClose();
            handlePaymentResultOpen('error');
          }
          onFail();
        }
      }
    } catch (e) {
      setIsChangingMethod(false);
      setStripeError({ message: 'Something went wrong!' } as StripeError);
      setIsPaymentFailed(true);
      onFail();
    }
  };

  const handlePaymentThatRequiresCustomerAction = async ({
    subscription,
    paymentMethodId
  }: {
    subscription: StripeSubscription;
    paymentMethodId: string;
  }) => {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      paymentIntent.status === 'requires_payment_method'
    ) {
      try {
        const stripeResponse = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId
          }
        );
        if (stripeResponse.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          setIsChangingMethod(false);
          setStripeError(stripeResponse.error);
          setIsPaymentFailed(true);
          return;
        } else {
          if (stripeResponse.paymentIntent.status === 'succeeded') {
            setIsChangingMethod(false);
            return {
              subscription: subscription,
              paymentMethodId: paymentMethodId
            };
          }
        }
      } catch (e) {
        console.error('Handle confirm Card Payment failed', e);
      }
    } else {
      // No customer action needed.
      return { subscription, paymentMethodId };
    }
  };

  const handleCancelRecurringConfirm = async () => {
    setIsCanceling(true);
    try {
      await cancelInvoiceSubscription(invoice.number);

      notificationStore.addNotification({
        text: `Invoice ${invoice.number}`,
        textColored: 'recurring payment canceled.'
      });
      onSuccess();
    } catch (e) {
      notificationStore.addNotification({
        type: NotificationTypesEnum.Error,
        text: `Invoice ${invoice.number}`,
        textColored: (
          <>
            We are unable to process cancel for this invoice.
            <br /> Please, contact your coach at{' '}
            <a className='link' href={`mailto:${coachInfo?.supportEmail}`}>
              {coachInfo?.supportEmail}
            </a>
          </>
        ),
        duration: 8000
      });
      onFail();
    }
    setIsCanceling(false);
    setIsCancelConfirmOpen(false);
  };

  const handleRetryPaymentClose = () => {
    setIsRetryPaymentModalOpened(false);
    setIsRetryingPayment(false);
  };

  const handlePaymentResultOpen = (type: 'succeeded' | 'error') => {
    setIsPaymentResultModalOpened(true);
    setPaymentResult(type);
  };

  const handlePaymentResultClose = () => {
    setIsPaymentResultModalOpened(false);
  };

  const handleChangeMethodClose = () => {
    setIsChangeMethodModalOpened(false);
    setIsPaymentFailed(false);
    setStripeError(null);
    setIsChangingMethod(false);
  };

  const handleCancelRecurringClose = () => {
    setIsCancelConfirmOpen(false);
  };

  return (
    <>
      <PaymentMethodModal
        isOpened={isRetryPaymentModalOpened}
        paymentMethod={invoice?.paymentMethod}
        isSubmitting={isRetryingPayment}
        onClose={handleRetryPaymentClose}
        onSubmit={handleRetryPaymentConfirm}
        title='Retry payment'
        btnText='Retry'
      />
      <PaymentResultModal
        isOpened={isPaymentResultModalOpened}
        type={paymentResult}
        onClose={handlePaymentResultClose}
        onSubmit={handlePaymentResultConfirm}
        btnText={
          paymentResult === 'succeeded' ? 'Close' : 'Change payment method'
        }
      />
      <ChangePaymentMethodModal
        isOpened={isChangeMethodModalOpened}
        isPaymentFailed={isPaymentFailed}
        error={stripeError}
        isSubmitting={isChangingMethod}
        onClose={handleChangeMethodClose}
        onSubmit={handleChangeMethodSubmit}
        title='Change payment method'
      />
      <ConfirmModal
        isOpened={isCancelConfirmOpen}
        close={handleCancelRecurringClose}
        cancelCallback={handleCancelRecurringClose}
        confirmCallback={handleCancelRecurringConfirm}
        confirmBtnText='Yes'
        cancelBtnText='No'
        disableConfirmBtn={isCanceling}
        title='Cancel recurring payment'
        text={`Are you sure you want to cancel recurring payment for invoice #${invoice?.number}`}
      />
    </>
  );
});

export default ClientInvoiceStripeActions;
