import React, { useEffect, useState } from 'react';
import './ClientAccount.styles.scss';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  getClientAccount,
  updateClientAccount,
  clientChangePassword
} from '../../core/api';
import {
  ChangePasswordViewModel,
  ClientAccountViewModel
} from '../../core/backend/api';
import { observer } from 'mobx-react';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import PersonalDataForm from '../../components/PersonalDataForm/PersonalDataForm';
import { FormConfig, getEmptyAccount } from './ClientAccount.helpers';
import { useStores } from '../../hooks';
import PageTitle from '../../components/PageTitle/PageTitle';

const ClientAccount: React.FC = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();
  const [initialData, setInitialData] = useState<ClientAccountViewModel>(
    getEmptyAccount()
  );
  const [isLoading, setIsLoading] = useState(false);
  // const [noNotifications, setNoNotifications] = useState(false);

  useEffect(() => {
    reFetchData();
  }, []);

  const reFetchData = () => {
    setIsLoading(true);
    getClientAccount().then((newData) => {
      setInitialData(newData);
      setIsLoading(false);
    });
  };

  const handleSavePersonalData = (payload: ClientAccountViewModel) => {
    setIsLoading(true);
    return updateClientAccount(payload)
      .then(() => {
        notificationStore!.addNotification({
          text: 'Your account was updated'
        });
        userStore.updateData({
          imageId: payload.imageId,
          timeZone: payload.timeZone
        });
        reFetchData();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSaveChangePassword = (newData: ChangePasswordViewModel) => {
    setIsLoading(true);
    return clientChangePassword(newData)
      .then(() => {
        notificationStore.addNotification({
          text: 'Your password was updated'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className='ClientAccount p-sm-4 p-3'>
      <PageTitle title='Account' />
      <GlobalLoader isLoading={isLoading} />
      <PersonalDataForm
        initialData={initialData}
        onSave={handleSavePersonalData}
        formConfig={FormConfig}
      />
      <ChangePasswordForm
        email={initialData.email}
        onSave={handleSaveChangePassword}
        className='ChangePasswordForm--no-border-bottom row mx-n2'
      />
      {/* change pass */}
      {/*<div className="ClientAccount__block Account__block--no-border-bottom ClientAccount__block--pad-left">*/}
      {/*<div className="ClientAccount__title">Notifications</div>*/}
      {/*<div className="ClientAccount__sub-title">My account</div>*/}
      {/*<FormCheckbox*/}
      {/*className="FormCheckbox--mt"*/}
      {/*id="notifications"*/}
      {/*checked={noNotifications}*/}
      {/*handleChange={() => setNoNotifications(!noNotifications)}*/}
      {/*label="Don`t send me notifications"*/}
      {/*/>*/}
      {/*</div>*/}
    </div>
  );
};

export default observer(ClientAccount);
