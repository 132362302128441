import React from 'react';
import './Pages.styles.scss';
import { PagesOptions as options } from './Pages.helpers';
import ComputerIcon from '../../icons/computer.svg';
import ProfileIcon from '../../icons/profile.svg';
import ViewIcon from '../../icons/view-icon.svg';
import { CoachRoutesEnum } from '../../core/enums';
import PencilIcon from '../../icons/pencil.svg';
import LinkIcon from '../../icons/link_sharp.svg';
import getGlobal from '../../core/globals';
import Button from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/useStores';
import { copyToClipboard } from '../../core/helpers';
import { signOutClient } from '../../core/api';
import PageTitle from '../../components/PageTitle/PageTitle';

interface iconsProps {
  [index: string]: any;
}

const icons: iconsProps = {
  ComputerIcon: <ComputerIcon />,
  ProfileIcon: <ProfileIcon />
};

const Pages: React.FC<{}> = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();

  const handleCopyLink = () => {
    copyToClipboard(`${getGlobal('domain')}/${userStore.siteName}`);
    notificationStore.addNotification({
      text: 'Link to Your Coach Page',
      textColored: 'was copied to your clipboard'
    });
  };

  const handleView = async () => {
    try {
      await signOutClient(userStore.siteName);
    } catch {}
  };

  return (
    <div className='Pages'>
      <PageTitle title='Pages' />
      {options.map((option, index) => {
        return (
          <div className='Pages__option' key={index}>
            <div className='Pages__option-icon'>{icons[option.iconName]}</div>
            <div className='Pages__option-content'>
              <div className='Pages__option-content-header'>
                <div className='Pages__option-title'>{option.title}</div>
              </div>
              <div className='Pages__option-actions'>
                {option.linkName === CoachRoutesEnum.CoachPageEditor && (
                  <>
                    <a
                      href={`${getGlobal('domain')}/${userStore.siteName}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={handleView}
                      className='button button__naked button--link'
                    >
                      <ViewIcon />
                      <span className='ml-2'>View</span>
                    </a>

                    <Button handleClick={handleCopyLink} variations={['naked']}>
                      <LinkIcon />
                      <span className='ml-2'>Copy link</span>
                    </Button>
                  </>
                )}
                <Button
                  component='Link'
                  link={option.linkName}
                  variations={['naked']}
                >
                  <PencilIcon />
                  <span className='ml-2'>Edit</span>
                </Button>
              </div>
              <div className='Pages__option-description'>
                {option.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(Pages);
