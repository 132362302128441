import React from 'react';
import './FormCheckbox.styles.scss';
import CheckedIcon from '../../icons/checked.svg';

interface iProps {
  id?: string;
  checked: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  labelBefore?: string;
  isSquare?: boolean;
  isCustom?: boolean;
  isDisabled?: boolean;
  testIdPrefix?: string;
}

const FormCheckbox: React.FC<iProps> = ({
  id = '',
  checked,
  handleChange,
  label = '',
  className = '',
  labelBefore = '',
  isSquare = false,
  isCustom = false,
  isDisabled = false,
  testIdPrefix
}) => {
  const formCheckboxClassList = new Set([
    'FormCheckbox',
    labelBefore ? 'FormCheckbox--label-before' : '',
    isDisabled ? 'FormCheckbox--disabled' : '',
    className
  ]);
  const labelTextClassList = new Set([
    'FormCheckbox__label-text',
    labelBefore && checked ? 'FormCheckbox__label-text--checked' : '',
    isCustom ? 'FormCheckbox__label-text--custom' : ''
  ]);
  return (
    <div className={Array.from(formCheckboxClassList).join(' ')}>
      <input
        type='checkbox'
        className='FormCheckbox__input'
        id={id}
        checked={checked}
        onChange={handleChange}
        disabled={isDisabled}
        data-testid={testIdPrefix ? `${testIdPrefix}-input` : ''}
      />
      {isSquare ? (
        <label
          htmlFor={id}
          className={`
                    FormCheckbox__label
                `}
        >
          <div
            className={`FormCheckbox__square ${
              checked ? 'FormCheckbox__square--checked' : ''
            }`}
          >
            <span
              className={`FormCheckbox__icon ${
                checked ? 'FormCheckbox__icon--checked' : ''
              }`}
            >
              <CheckedIcon />
            </span>
          </div>
          <span
            className={`
                            FormCheckbox__label-text
                            FormCheckbox__label-text--is-square
                            ${
                              checked
                                ? 'FormCheckbox__label-text--is-square-checked'
                                : ''
                            }
                            `}
            data-testid={testIdPrefix ? `${testIdPrefix}-label` : ''}
          >
            {label}
          </span>
        </label>
      ) : (
        <label
          htmlFor={id}
          className={`
                    FormCheckbox__label
                    ${labelBefore ? 'FormCheckbox__label--label-before' : ''}
                `}
        >
          {labelBefore ? (
            <span
              className={`
                                FormCheckbox__label-text-before
                                 ${
                                   !checked
                                     ? 'FormCheckbox__label-text-before--not-checked'
                                     : ''
                                 }
                                 ${
                                   isCustom
                                     ? 'FormCheckbox__label-text-before--custom'
                                     : ''
                                 }
                                 `}
              data-testid={testIdPrefix ? `${testIdPrefix}-label-before` : ''}
            >
              {labelBefore}
            </span>
          ) : null}
          <div
            className={`FormCheckbox__toggler ${
              checked ? 'FormCheckbox__toggler--checked' : ''
            }`}
          >
            <div
              className={`FormCheckbox__circle ${
                checked ? 'FormCheckbox__circle--checked' : ''
              }`}
            />
          </div>
          <span
            className={Array.from(labelTextClassList).join(' ')}
            data-testid={testIdPrefix ? `${testIdPrefix}-label` : ''}
          >
            {label}
          </span>
        </label>
      )}
    </div>
  );
};

export default FormCheckbox;
