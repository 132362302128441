import React, { ReactNode } from 'react';
import Button from '../../components/Button/Button';
import ArrowIcon from '../../icons/arrow-left.svg';
import './LibraryModal.styles.scss';

interface Props {
  children: ReactNode;
  onClickBack?: () => void;
}

const LibraryModal: React.FC<Props> = ({ children, onClickBack }) => {
  return (
    <div className='LibraryModal'>
      {onClickBack && (
        <Button
          className='LibraryModal__btn'
          variations={['naked']}
          handleClick={onClickBack}
        >
          <ArrowIcon />
          <span className='ml-2'>Back</span>
        </Button>
      )}
      {children}
    </div>
  );
};

export default LibraryModal;
