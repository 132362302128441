import { iFormConfigItem, UserStore } from '../../core/types';
import {
  getInputErrors,
  isDataValid,
  isInputValid,
  isNotEmpty,
  isPhoneNumberLongEnough
} from '../../core/formValidation';
import moment from 'moment-timezone';
import { SignupClientAdditionalInfoViewModel } from '../../core/backend/api';
import { getTimezone } from '../../core/helpers';

export function getDefaultData(
  store: UserStore
): SignupClientAdditionalInfoViewModel {
  return {
    country: store.country || '',
    state: store.state || '',
    city: store.city || '',
    phoneNumber: store.phoneNumber || '',
    timeZone: getTimezone(store.timeZone || moment.tz.guess())
  };
}

const FormConfig: iFormConfigItem[] = [
  { field: 'city', checkIsValid: [isNotEmpty] },
  { field: 'country', checkIsValid: [isNotEmpty] },
  { field: 'phoneNumber', checkIsValid: [isNotEmpty, isPhoneNumberLongEnough] }
];

export function isCompleteRegistrationInputValid(
  inputField: string,
  data: any
) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getCompleteRegistrationInputErrors(
  inputField: string,
  data: any
) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isCompleteRegistrationDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
