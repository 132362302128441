import { CoachRoutesEnum } from '../../core/enums';

export const PagesOptions = [
  {
    title: 'Your Coach Page',
    description: `Your coach page is the page your customers see when they want to schedule an appointment or see your upcoming availability. Use this space to showcase your brand and personality by adding your colors, logo, and bio.`,
    iconName: 'ComputerIcon',
    linkName: CoachRoutesEnum.CoachPageEditor
  },
  {
    title: 'Client’s Portal',
    description: `The client portal is the private login area of your clients. It’s their personal space where they see upcoming appointments, and schedule new ones. They’ll also be able to access their personal library and see content that you share with them.`,
    iconName: 'ProfileIcon',
    linkName: CoachRoutesEnum.ClientPageEditor
  }
];
