import React, { useEffect, useState } from 'react';
import './ClientInvites.styles.scss';
import ClientSchedulePage from '../../containers/ClientSchedulePage/ClientSchedulePage';
import ClientScheduleTile from '../../components/ClientScheduleTile/ClientScheduleTile';
import { BookingItemTypeEnum, iBookingItem } from '../../core/types';
import {
  cancelClientAppointmentInvite,
  cancelClientEventInvite,
  getClientBookingItemsWithInvites
} from '../../core/api';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import Button from '../../components/Button/Button';
import { observer } from 'mobx-react';
import CancelIcon from '../../icons/cancel-icon.svg';
import EmptyIcon from '../../images/empty-email.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import InfoBlockModal from '../../components/Modals/InfoBlockModal/InfoBlockModal';
import { getInfoBlockData } from '../../components/Modals/InfoBlockModal/InfoBlockModal.helpers';
import { useStores } from '../../hooks';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';

const ClientInvites = () => {
  const { rootStore } = useStores();
  const { userStore } = rootStore;
  const [data, setData] = useState<iBookingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewItem, setViewItem] = useState<iBookingItem>(null);
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    setIsLoading(true);
    getClientBookingItemsWithInvites().then((response) => {
      setData(response);
      setIsLoading(false);
    });
  };

  const handleCancelInvite = (item: iBookingItem) => () => {
    setIsLoading(true);
    const update =
      item.type === BookingItemTypeEnum.Meeting
        ? cancelClientEventInvite
        : cancelClientAppointmentInvite;

    update(item.id).then(() => {
      rootStore.incrementChangesCounter();
      setIsLoading(false);
      setIsViewPopupOpened(false);
    });
  };

  const handleCalendarItemView = (data: iBookingItem) => {
    setIsViewPopupOpened(true);
    setViewItem(data);
  };

  return (
    <ClientSchedulePage title='Invites'>
      <GlobalLoader isLoading={isLoading} />
      <div className='ClientInvites'>
        {data.length > 0 &&
          data.map((item, index) => (
            <ClientScheduleTile
              key={index}
              data={item}
              menu={[
                {
                  icon: <CancelIcon />,
                  name: "I can't attend",
                  callback: handleCancelInvite(item)
                }
              ]}
              onView={handleCalendarItemView}
            >
              <Button
                className='button__sm-width flex-grow-1'
                link={`/${rootStore.siteName}/${item.link}`}
              >
                {item.hasPrice ? 'Confirm & Pay' : 'Confirm'}
              </Button>
            </ClientScheduleTile>
          ))}
        {!data.length && !isLoading && (
          <EmptyState
            icon={<EmptyIcon />}
            text='You have no invites yet'
            absoluteCenter={true}
          />
        )}
      </div>
      <InfoBlockModal
        isOpened={isViewPopupOpened}
        close={() => setIsViewPopupOpened(false)}
        data={getInfoBlockData(viewItem, userStore.timeZone, true)}
        actions={
          isViewPopupOpened && (
            <div className='flex-grow-1 ml-sm-3 ml-2 d-flex justify-content-end'>
              <DropdownMenu
                list={[
                  {
                    icon: <CancelIcon />,
                    name: `I can't attend`,
                    callback: handleCancelInvite(viewItem)
                  }
                ]}
                buttonClassName='CZ__dropdown-button'
              />
            </div>
          )
        }
        footer={
          isViewPopupOpened && (
            <div className='pt-2'>
              <Button
                className='button__sm-width'
                link={`/${rootStore.siteName}/${viewItem.link}`}
              >
                {viewItem.hasPrice ? 'Confirm & Pay' : 'Confirm'}
              </Button>
            </div>
          )
        }
      />
    </ClientSchedulePage>
  );
};

export default observer(ClientInvites);
