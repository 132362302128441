import { iFormConfigItem } from '../../core/types';
import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  isInputValid,
  isNotEmpty,
  isPasswordLongEnough,
  getInputErrors,
  isDataValid,
  isNotSpacesOnSides
} from '../../core/formValidation';

const PasswordsFormConfig: iFormConfigItem[] = [
  {
    field: 'newPassword',
    compareField: 'repeatNewPassword',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  },
  {
    field: 'repeatNewPassword',
    compareField: 'newPassword',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  }
];

export function isPasswordsInputValid(inputField: string, data: any) {
  return isInputValid(PasswordsFormConfig)(inputField, data);
}

export function getPasswordsInputErrors(inputField: string, data: any) {
  return getInputErrors(PasswordsFormConfig)(inputField, data);
}

export function isPasswordsDataValid(data: any) {
  return isDataValid(PasswordsFormConfig)(data);
}
