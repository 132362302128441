import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CoachRoutesEnum,
  ConfirmModalEnum,
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import { getColumns } from './CoachInvoices.helpers';
import { useStores } from '../../hooks';
import './CoachInvoices.styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { TableAction } from '../../components/Table/Table';
import { Invoice } from '../../core/types';
import {
  deleteCoachInvoice,
  getCoachInvoices,
  refundCoachInvoice,
  updateCoachInvoiceStatus
} from '../../core/api';
import InvoicesList from '../../components/InvoicesList/InvoicesList';
import ConfirmModal, {
  iProps as ConfirmModalData
} from '../../components/Modals/ConfirmModal/ConfirmModal';
import SendInvoiceModal from '../../components/Modals/SendInvoiceModal/SendInvoiceModal';
import { useSendInvoiceModal } from '../../components/Modals/SendInvoiceModal/useSendInvoiceModal';
import PageTitle from '../../components/PageTitle/PageTitle';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import {
  getStripeConnectUrl,
  storeDataBeforeStripeConnect
} from '../../core/integrations';
import { iTab } from '../../components/TabsMenu/TabsMenu';

const tabs: iTab[] = [
  {
    name: 'All',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: ''
    }
  },
  {
    name: 'Drafts',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.draft}`
    }
  },
  {
    name: 'Open',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.open}`
    }
  },
  {
    name: 'Paid',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.paid}`
    }
  },
  {
    name: 'Late',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.late}`
    }
  },
  {
    name: 'Refunded',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.refunded}`
    }
  },
  {
    name: 'Uncollectible',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.uncollectible}`
    }
  },
  {
    name: 'Canceled',
    link: CoachRoutesEnum.Invoices,
    queryParams: {
      status: `${InvoiceStatusEnum.canceled}`
    }
  }
];

const CoachInvoices = () => {
  const {
    rootStore: { notificationStore, userStore }
  } = useStores();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const status = params.get('status')
    ? (+params.get('status') as InvoiceStatusEnum)
    : null;
  const sortBy = params.get('sortBy') as keyof Invoice;
  const sortOrder = params.get('sortOrder');

  const [invoices, setInvoices] = useState([]);
  const [activeInvoice, setActiveInvoice] = useState<Invoice>();
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalEnum>();
  const STRIPE_URL = getStripeConnectUrl(userStore.id);
  const { isStripeConnected } = userStore;

  const columns = useMemo(() => getColumns(status === null), [status]);

  const {
    isSendInvoiceModalOpened,
    isSending,
    closeSendInvoiceModal,
    openSendInvoiceModal,
    sendInvoice
  } = useSendInvoiceModal(activeInvoice, (invoice) =>
    updateCoachInvoiceStatus(invoice.id, InvoiceStatusEnum.open)
  );

  const reFetch = useCallback(() => {
    if (isStripeConnected && sortBy && sortOrder) {
      setIsLoading(true);
      getCoachInvoices(
        {
          sortBy: sortBy,
          sortOrder: +sortOrder
        },
        status
      )
        .then((res) => {
          setInvoices(res);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isStripeConnected, sortBy, sortOrder, status]);

  useEffect(reFetch, [reFetch]);

  const cancelCoachInvoice = (id: string | number) => {
    return updateCoachInvoiceStatus(id, InvoiceStatusEnum.canceled);
  };

  const handleConfirmAction = useCallback(
    (
      action: (id: string | number) => Promise<unknown>,
      notification: string,
      actionStatus: string
    ) => () => {
      if (isConfirmBtnDisabled) {
        return;
      }
      setIsConfirmBtnDisabled(true);
      setIsLoading(true);
      action(activeInvoice.id)
        .then(() => {
          notificationStore.addNotification({
            text: `Invoice ${activeInvoice.number}`,
            textColored: notification
          });
        })
        .catch((err) => {
          if (err.status === 404) {
            notificationStore.addNotification({
              text: `Invoice ${activeInvoice.number} not found`,
              type: NotificationTypesEnum.Error
            });
          } else {
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: `Invoice ${activeInvoice.number}`,
              textColored: `payment ${actionStatus} failed.`
            });
          }
        })
        .finally(() => {
          reFetch();
          handleConfirmationClose();
        });
    },
    [activeInvoice, isConfirmBtnDisabled, notificationStore, reFetch]
  );

  const handleItemClick = (item: Invoice) => {
    history.push(
      (item.status === InvoiceStatusEnum.draft
        ? CoachRoutesEnum.InvoiceEdit
        : CoachRoutesEnum.Invoice
      ).replace(':id', String(item.id))
    );
  };

  const handleConfirmationClose = () => {
    setIsConfirmModalOpened(false);
  };

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, null);
  };

  const handleSend = () => {
    sendInvoice()
      .then(() => {
        notificationStore.addNotification({
          text: `Invoice ${activeInvoice.number} sent`
        });
        reFetch();
        closeSendInvoiceModal();
      })
      .catch((err) => {
        if (err.status === 404) {
          notificationStore.addNotification({
            text: `Invoice ${activeInvoice.number} not found`,
            type: NotificationTypesEnum.Error
          });
          reFetch();
          closeSendInvoiceModal();
        }
      });
  };

  const actionCallback = (type: ConfirmModalEnum, item: Invoice) => {
    setConfirmModalType(type);
    setIsConfirmBtnDisabled(false);
    setIsConfirmModalOpened(true);
    setActiveInvoice(item);
  };

  const actions: Array<TableAction<Invoice>> = useMemo(
    () => [
      {
        title: 'View',
        callback: (item: Invoice) => {
          history.push(CoachRoutesEnum.Invoice.replace(':id', String(item.id)));
        },
        isAvailable: (item) => {
          return [
            InvoiceStatusEnum.open,
            InvoiceStatusEnum.paid,
            InvoiceStatusEnum.late,
            InvoiceStatusEnum.canceled,
            InvoiceStatusEnum.uncollectible,
            InvoiceStatusEnum.refunded
          ].includes(item.status);
        }
      },
      {
        title: 'Edit',
        callback: (item: Invoice) => {
          history.push(
            CoachRoutesEnum.InvoiceEdit.replace(':id', String(item.id))
          );
        },
        isAvailable: (item) => {
          return [InvoiceStatusEnum.draft].includes(item.status);
        }
      },
      {
        title: 'Delete',
        callback: (item: Invoice) => {
          actionCallback(ConfirmModalEnum.delete, item);
        },
        isAvailable: (item) => {
          return [InvoiceStatusEnum.draft].includes(item.status);
        }
      },
      {
        title: 'Download as PDF',
        callback: (item: Invoice) => {
          window.open(
            `/api/Invoices/${item.id}/pdf/invoice_${item.number}`,
            '_blank'
          );
        },
        isAvailable: (item) => {
          return ![InvoiceStatusEnum.draft].includes(item.status);
        }
      },
      {
        title: 'Cancel',
        callback: (item: Invoice) => {
          actionCallback(ConfirmModalEnum.cancel, item);
        },
        isAvailable: (item) => {
          return (
            [
              InvoiceStatusEnum.open,
              InvoiceStatusEnum.late,
              InvoiceStatusEnum.uncollectible
            ].includes(item.status) ||
            (item.type === InvoiceTypeEnum.Recurring &&
              item.status === InvoiceStatusEnum.paid)
          );
        }
      },
      {
        title: 'Send',
        callback: (item: Invoice) => {
          setActiveInvoice(item);
          openSendInvoiceModal();
        },
        isAvailable: (item) => {
          return [InvoiceStatusEnum.draft].includes(item.status);
        }
      }
    ],
    [history, openSendInvoiceModal]
  );

  let confimModalData: Partial<ConfirmModalData & { callback: () => void }>;
  switch (confirmModalType) {
    case ConfirmModalEnum.delete:
      confimModalData = {
        title: `Delete invoice #${activeInvoice?.number}`,
        text: 'Are you sure that you want to delete it?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        callback: handleConfirmAction(
          deleteCoachInvoice,
          'deleteded successfully',
          ConfirmModalEnum[1]
        )
      };
      break;
    case ConfirmModalEnum.cancel:
      confimModalData = {
        title: `Cancel invoice #${activeInvoice?.number}`,
        text: 'Are you sure that you want to cancel it?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        callback: handleConfirmAction(
          cancelCoachInvoice,
          'canceled successfully',
          ConfirmModalEnum[2]
        )
      };
      break;
    case ConfirmModalEnum.refund:
    default:
      confimModalData = {
        title: `Give refund for Invoice ${activeInvoice?.number}`,
        text: 'Are you sure you want to give a refund for this payment?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        callback: handleConfirmAction(
          refundCoachInvoice,
          'payment is refunded.',
          ConfirmModalEnum[0]
        )
      };
      break;
  }

  return (
    <div className='CoachInvoices'>
      <PageTitle title='Invoices' />
      <div className='d-flex justify-content-between align-items-center mb-sm-4'>
        <div className='CoachInvoices__title d-none d-sm-block'>Invoices</div>
        {isStripeConnected && (
          <Button
            link={CoachRoutesEnum.InvoiceNew}
            className='ml-sm-3 ml-0 button--CoachInvoices order-2 order-sm-1 px-3 d-flex align-items-center'
          >
            <span className='CoachInvoices__plus'>+</span> New Invoice
          </Button>
        )}
      </div>
      {isStripeConnected ? (
        <>
          <InvoicesList
            className='CoachInvoices__container'
            tabs={tabs}
            isLoading={isLoading}
            invoices={invoices}
            columns={columns}
            actions={actions}
            baseUrl={CoachRoutesEnum.CoachClientSchedule}
            rowHeight={73}
            headerHeight={48}
            onClick={handleItemClick}
          />
          <SendInvoiceModal
            coachAccountData={userStore.userData}
            invoice={activeInvoice}
            onClose={closeSendInvoiceModal}
            isOpened={isSendInvoiceModalOpened}
            onSend={handleSend}
            isSending={isSending}
          />
          <ConfirmModal
            isOpened={isConfirmModalOpened}
            close={handleConfirmationClose}
            title={confimModalData.title}
            text={confimModalData.text}
            confirmBtnText={confimModalData.confirmBtnText}
            cancelBtnText={confimModalData.cancelBtnText}
            confirmCallback={confimModalData.callback}
            disableConfirmBtn={isConfirmBtnDisabled}
            confirmBtnClass='button__confirm-delete'
          />
        </>
      ) : (
        <div
          className='position-relative flex-grow-1 mx-n2'
          style={{ minHeight: '290px' }}
        >
          <EmptyState
            size='md'
            text="You don't have payments yet. Connect a Stripe account to receive payments and create invoices."
            absoluteCenter
            icon={<EmptyStateIcon />}
            style={{ width: '320px' }}
          >
            <Button
              type='button'
              className='button__md-width button__mt ml-0'
              link={CoachRoutesEnum.ConnectStripe}
              handleClick={handleConnectStripe}
            >
              Connect Stripe
            </Button>
          </EmptyState>
        </div>
      )}
    </div>
  );
};

export default CoachInvoices;
