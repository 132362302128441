import React from 'react';
import AvatarIcon from '../../icons/avatar-small.svg';
import CheckedIcon from '../../icons/checked.svg';
import cn from 'classnames';
import './SharingInputItem.styles.scss';
import { getPublicFile } from '../../core/api';

interface Props {
  onSelect: (id: string) => void;
  isSelected: boolean;
  index?: number;
  selectedByKey?: number;
  id: string;
  title: string;
  imageId: string;
}

const SharingInputItem: React.FC<Props> = ({
  onSelect,
  isSelected,
  index,
  selectedByKey,
  id,
  title,
  imageId
}) => {
  const handleSelect = () => onSelect(id);
  return (
    <div
      className={cn('SharingInputItem', {
        'SharingInputItem--selected': selectedByKey === index
      })}
      onClick={handleSelect}
    >
      <div
        className={cn('SharingInputItem__checked-block', {
          'SharingInputItem__checked-block--checked': isSelected
        })}
      >
        {isSelected && <CheckedIcon />}
      </div>

      {imageId ? (
        <div className='SharingInputItem__image'>
          <img src={getPublicFile(imageId)} alt='avatar' />
        </div>
      ) : (
        <div className='SharingInputItem__image'>
          <AvatarIcon />
        </div>
      )}
      <div className='SharingInputItem__content'>
        <div
          className='SharingInputItem__name'
          dangerouslySetInnerHTML={{
            __html: title
          }}
        />
      </div>
    </div>
  );
};

export default SharingInputItem;
