import { isDataValid, isNotEmpty, isUrl } from '../../core/formValidation';
import { iCoachPageCustomizationData } from '../../core/types';

export const formConfig = [
  { field: 'socialLinks', checkIsValid: [isSocialLinksIsUrls] }
];

export function isSocialLinksIsUrls(links: string[]) {
  return links.every((l) => isUrl(l) || !isNotEmpty(l));
}

export function isFormDataValid(data: iCoachPageCustomizationData) {
  return isDataValid(formConfig)(data);
}
