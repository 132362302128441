import React, { useEffect, useState, useMemo, useCallback } from 'react';
import moment, { Moment } from 'moment';
import { DayOfWeek } from '../../core/backend/api';
import { getCurrentDayTimeStamp } from '../../core/helpers';
import NextIcon from '../../icons/next.svg';
import './CalendarView.styles.scss';

export interface Props {
  value: number | number[];
  mode: 'picker' | 'range';
  className?: string;
  shouldHighlightDate?: (date: number) => boolean;
  shouldDisableDate?: (date: number) => boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  onMonthChange?: (monthTimeStamp: number) => void;
  onDayClick: (date: number) => void;
  onDayFocus?: (date: number) => void;
  initialDate?: number;
}

const CalendarView: React.FC<Props> = ({
  value,
  mode,
  className,
  shouldHighlightDate,
  shouldDisableDate,
  disablePast,
  disableFuture,
  onDayClick,
  onDayFocus,
  onMonthChange,
  initialDate
}) => {
  const today = useMemo(() => getCurrentDayTimeStamp(), []);
  const startDate =
    (Array.isArray(value) ? value[0] : value) || initialDate || today;

  if (mode === 'range' && !Array.isArray(value)) {
    throw new Error('It "range" mode value should be an array');
  }

  const [firstMonthDay, setFirstMonthDay] = useState(
    moment.utc(startDate).startOf('month')
  );

  useEffect(() => {
    if (onMonthChange) {
      onMonthChange(firstMonthDay.valueOf());
    }
  }, [firstMonthDay, onMonthChange]);

  useEffect(() => {
    const newDate = moment.utc(startDate).startOf('month');
    if (newDate.valueOf() !== firstMonthDay.valueOf()) {
      setFirstMonthDay(newDate);
    }
  }, [startDate]);

  const weeks = useMemo(() => getCalendarDays(firstMonthDay), [firstMonthDay]);

  const handleNextMonthClick = () => {
    setFirstMonthDay((prev) => prev.clone().add(1, 'month'));
  };
  const handlePrevMonthClick = () => {
    setFirstMonthDay((prev) => prev.clone().subtract(1, 'month'));
  };

  const isPrevMonthDisabled = disablePast && firstMonthDay.valueOf() <= today;
  const isNextMonthDisabled =
    disableFuture && firstMonthDay.clone().endOf('month').valueOf() > today;

  const isDateDisabled = useCallback(
    (date: number) =>
      (shouldDisableDate && shouldDisableDate(date)) ||
      (disablePast && date < today) ||
      (disableFuture && date > today),
    [shouldDisableDate, disablePast, today, disableFuture]
  );

  const isDateAvailable = useCallback(
    (date: number) => shouldHighlightDate && shouldHighlightDate(date),
    [shouldHighlightDate]
  );

  const isDateReadOnly = useCallback(
    (date: number) => {
      return shouldHighlightDate && !shouldHighlightDate(date);
    },
    [shouldHighlightDate]
  );

  const isCheckedEnable = useCallback(
    (date: number) => {
      return !isDateDisabled(date) && !isDateReadOnly(date);
    },
    [isDateDisabled, isDateReadOnly]
  );

  const handleDayClick = useCallback(
    (day: number) => () => {
      isCheckedEnable(day) && onDayClick(day);
    },
    [isCheckedEnable, onDayClick]
  );

  const handleDayFocus = useCallback(
    (day: number) => () => {
      isCheckedEnable(day) && onDayFocus && onDayFocus(day);
    },
    [isCheckedEnable, onDayFocus]
  );

  return (
    <div className={`Calendar ${className ? className : ''}`}>
      <div className='Calendar__head'>
        <div className='Calendar__nav-container'>
          <div
            className={`Calendar__nav Calendar__nav--prev ${
              isPrevMonthDisabled ? 'Calendar__nav--disabled' : ''
            }`}
            onClick={handlePrevMonthClick}
          >
            <NextIcon />
          </div>
        </div>
        <div className='Calendar__current-month'>
          {weeks[0][0].format('MMMM YYYY')}
        </div>
        <div className='Calendar__nav-container'>
          <div
            className={`Calendar__nav Calendar__next--next ${
              isNextMonthDisabled ? 'Calendar__nav--disabled' : ''
            }`}
            onClick={handleNextMonthClick}
          >
            <NextIcon />
          </div>
        </div>
      </div>
      <div className='Calendar__body'>
        <div className='Calendar__grid'>
          {weeks[1].map((day) => (
            <div key={day.valueOf()} className='Calendar__cell'>
              {day.format('ddd')}
            </div>
          ))}
        </div>
        <div className='Calendar__body-content'>
          {weeks.map((week, index) => {
            const key = week[0].valueOf();

            if (index === 0 && week.length < 7) {
              week = [...Array(7 - week.length).map(() => null), ...week];
            }

            return (
              <div key={key} className='Calendar__grid'>
                {week.map((day, i) => {
                  if (!day) {
                    return <div key={i}></div>;
                  }

                  const classList = new Set([
                    'Calendar__cell',
                    'Calendar__day'
                  ]);

                  const dayValue = day.valueOf();

                  const isActive =
                    mode === 'picker'
                      ? (Array.isArray(value)
                          ? value
                          : value
                          ? [value]
                          : []
                        ).includes(dayValue)
                      : Array.isArray(value) && value.length === 2
                      ? dayValue >= value[0] && dayValue <= value[1]
                      : false;

                  const isFirst =
                    mode === 'range' &&
                    Array.isArray(value) &&
                    value.length === 2 &&
                    dayValue === value[0];
                  const isLast =
                    mode === 'range' &&
                    Array.isArray(value) &&
                    value.length === 2 &&
                    dayValue === value[1];

                  if (isActive) {
                    classList.add('Calendar__day--active');
                  }
                  if (isFirst) {
                    classList.add('Calendar__day--first');
                  }
                  if (isLast) {
                    classList.add('Calendar__day--last');
                  }
                  if (isDateAvailable(day.valueOf())) {
                    classList.add('Calendar__day--available');
                  }

                  if (isDateDisabled(day.valueOf())) {
                    classList.add('Calendar__day--disabled');
                  }
                  if (isDateReadOnly(day.valueOf())) {
                    classList.add('Calendar__day--read-only');
                  }
                  const dayClassName = Array.from(classList).join(' ');

                  return (
                    <div
                      key={day.valueOf()}
                      onClick={handleDayClick(day.valueOf())}
                      onMouseEnter={handleDayFocus(day.valueOf())}
                      className={dayClassName}
                      data-day={day.day()}
                      data-month={day.month()}
                      data-year={day.year()}
                    >
                      <span className='Calendar__day-text'>
                        {day.format('D')}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getCalendarDays = (firstMonthDay: Moment): Moment[][] => {
  const days: Moment[] = [];
  const startDay = firstMonthDay.clone();
  const startDateMonth = startDay.month();

  while (startDay.month() === startDateMonth) {
    days.push(startDay.clone());
    startDay.add(1, 'days');
  }

  return days.reduce((weeksAcc: Moment[][], day) => {
    if (weeksAcc.length === 0 || day.day() === DayOfWeek.NUMBER_0) {
      weeksAcc.push([]);
    }
    weeksAcc[weeksAcc.length - 1].push(day);
    return weeksAcc;
  }, []);
};

export default CalendarView;
