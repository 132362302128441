import React from 'react';
import { InvoiceCustomizeData } from '../../core/types';

interface iProps {
  customizeData: InvoiceCustomizeData;
}

export const CustomizationInvoiceStyles: React.FC<iProps> = ({
  customizeData
}) => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
                .CZ__invoice-headline-text {
                  color: ${customizeData.headlineColor};
                }
                .CZ__invoice-secondary-bg {
                  background-color: ${customizeData.backgroundColor};
                }
                .CZ__invoice-primary-bg {
                  background-color: ${customizeData.primaryColor};
                }
                .CZ__invoice-logo-placeholder {
                  border: 1px dashed ${customizeData.headlineColor};
                }
                .CZ__invoice-field {
                    color: ${customizeData.paragraphColor};
                }
                .CZ__invoice-field-secondary {
                    color: ${customizeData.paragraphColor};
                    opacity: ${
                      customizeData.paragraphColor ? '0.7' : undefined
                    };
                }
            `
      }}
    />
  );
};
