import React, { useEffect, useState } from 'react';
import {
  BookingItemTypeEnum,
  iBookingItem,
  WelcomeMessageData
} from '../../core/types';
import {
  cancelClientAppointment,
  cancelClientEvent,
  getClientBookingItemsWithMember,
  getCoachBookedEvents
} from '../../core/api';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { observer } from 'mobx-react';
import Dashboard from '../../components/Dashboard/Dashboard';
import { getClientMotivationMessage } from '../../core/api';
import NotificationModal from '../../components/Modals/NotificationModal/NotificationModal';
import EnvelopeIcon from '../../icons/envelope.svg';
import ClientUserStore from '../../store/ClientUserStore';
import moment from 'moment';
import { useDebouncedCallback, useStores } from '../../hooks';
import { getInfoBlockData } from '../../components/Modals/InfoBlockModal/InfoBlockModal.helpers';
import { iInfoBlockData } from '../../components/InfoBlock/InfoBlock';
import InfoBlockModal from '../../components/Modals/InfoBlockModal/InfoBlockModal';
import CancelIcon from '../../icons/cancel-icon.svg';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { getEmptyData } from '../CoachDashboardEditor/CoachDashboardEditor.helpers';
import Warning from '../../components/Warning/Warning';
import {
  StripeRefundStatusEnum,
  NotificationTypesEnum
} from '../../core/enums';
import { ERROR_MESSAGES } from '../../core/errorMessages';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';
import PageTitle from '../../components/PageTitle/PageTitle';
interface ConfirmationData {
  title: string;
  text: string;
  callback: () => void;
}

const ClientDashboard = () => {
  const { rootStore } = useStores<ClientUserStore>();
  const { userStore, notificationStore, coachInfo } = rootStore;
  const [scheduled, setScheduled] = useState<iBookingItem[]>([]);
  const [data, setData] = useState<iBookingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [motivationData, setMotivationData] = useState<WelcomeMessageData>({
    welcomeText: '',
    message: ''
  });
  const [infoBlockData, setInfoBlockData] = useState<iInfoBlockData>(null);
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const [confirmationModalData, setConfirmationModalData] = useState<
    ConfirmationData
  >(null);
  const [activeItem, setActiveItem] = useState<iBookingItem>();

  const setIsConfirmationDone = useDebouncedCallback(() => {
    setConfirmationModalData(null);
    setIsSubmitting(false);
  }, 450);

  useEffect(() => {
    const defaultWelcome = getEmptyData();
    reFetchData();
    getClientMotivationMessage().then((response) => {
      setMotivationData({
        welcomeText: response.welcomeText || defaultWelcome.welcomeText,
        message: response.message || defaultWelcome.message
      });
    });
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    setIsLoading(true);

    const date = moment.utc();

    Promise.all([
      getClientBookingItemsWithMember(date.clone().valueOf()),
      getCoachBookedEvents()
    ])
      .then(([bookedResponse, availableResponse]) => {
        bookedResponse.sort((a, b) => {
          return a.timestamp! <= b.timestamp! ? -1 : 1;
        });
        availableResponse.sort((a, b) => {
          return a.timestamp! <= b.timestamp! ? -1 : 1;
        });
        setScheduled(bookedResponse);
        setData(availableResponse);
      })
      .catch(() => {
        setScheduled([]);
        setData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCalendarItemView = (data: iBookingItem) => {
    setIsViewPopupOpened(true);
    setInfoBlockData(getInfoBlockData(data, userStore.timeZone));
    setActiveItem(data);
  };

  const handleCancel = (item: iBookingItem) => () => {
    const isMeeting =
      item.type === BookingItemTypeEnum.Meeting && !item.isScheduled;
    const entity = isMeeting ? 'meeting' : 'appointment';
    setIsConfirmModalOpened(true);
    setIsViewPopupOpened(false);
    setConfirmationModalData({
      title: `Cancel ${entity}`,
      text: `Are you sure you want to cancel this ${entity}${
        item.hasPrice && item.refundStatus !== StripeRefundStatusEnum.Succeeded
          ? ' and get refunded'
          : ''
      }?`,
      callback: () => confirmCancel(item)
    });
  };

  const confirmCancel = (item: iBookingItem) => {
    setIsSubmitting(true);
    const update =
      item.type === BookingItemTypeEnum.Meeting
        ? cancelClientEvent
        : cancelClientAppointment;

    update(item.id)
      .then(() => {
        rootStore.incrementChangesCounter();
        setIsConfirmModalOpened(false);
      })
      .catch((err) => {
        setIsConfirmModalOpened(false);
        if (err.status === 400) {
          if (err.detail === 'RefundFailed') {
            setIsRefundPromptModalOpened(true);
            setActiveItem(item);
            rootStore.incrementChangesCounter();
          } else {
            const message =
              err.detail === 'AlreadyRefunded'
                ? ERROR_MESSAGES.AlreadyRefundedEvent
                : ERROR_MESSAGES.SomethingWentWrong;
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: 'Refund failed',
              textColored: message
            });
          }
        }
      })
      .finally(() => {
        setIsConfirmationDone();
      });
  };

  const meetings = data.filter(
    (item) => item.type === BookingItemTypeEnum.Meeting
  );
  const appointments = data.filter(
    (item) => item.type !== BookingItemTypeEnum.Meeting
  );

  const entity =
    activeItem?.type === BookingItemTypeEnum.Meeting && !activeItem?.isScheduled
      ? 'meeting'
      : 'appointment';

  return (
    <>
      <PageTitle title='Dashboard' />
      <GlobalLoader isLoading={isLoading} />
      {userStore.isTimeZoneDiffers && (
        <Warning
          className='mb-3'
          onClose={userStore.setTimezoneLastCheckTimestamp}
        >
          We've noticed that your local timezone differs from the timezone set
          in your account. Please, check your account settings.
        </Warning>
      )}
      <Dashboard
        isLoadingData={isLoading}
        scheduled={scheduled}
        meetings={meetings}
        siteName={rootStore.siteName}
        appointments={appointments}
        motivationData={motivationData}
        onView={handleCalendarItemView}
      />
      <NotificationModal
        isOpened={userStore.showEmailConfirmed}
        close={() => userStore.setShowEmailConfirmed(false)}
        title='Thank you!'
        subTitle='Your email is confirmed. We sent meeting details on your email along with credentials to your account'
        icon={<EnvelopeIcon />}
      />
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={() => setIsConfirmModalOpened(false)}
        title={confirmationModalData && confirmationModalData.title}
        text={confirmationModalData && confirmationModalData.text}
        disableConfirmBtn={isSubmitting}
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={
          confirmationModalData && confirmationModalData.callback
        }
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            The {entity} is canceled, but we are unable to process refund for
            this {entity} automatically.
            <br /> Please, contact your coach at{' '}
            <a className='link' href={`mailto:${coachInfo?.supportEmail}`}>
              {coachInfo?.supportEmail}
            </a>
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
      <InfoBlockModal
        isOpened={isViewPopupOpened}
        close={() => setIsViewPopupOpened(false)}
        data={infoBlockData}
        actions={
          <div className='flex-grow-1 ml-sm-3 ml-2 d-flex justify-content-end'>
            <DropdownMenu
              list={[
                {
                  icon: <CancelIcon />,
                  name:
                    infoBlockData && +infoBlockData.price > 0
                      ? activeItem.refundStatus ===
                        StripeRefundStatusEnum.Succeeded
                        ? 'Cancel'
                        : 'Cancel & Ask Refund'
                      : `I can't attend`,
                  callback: handleCancel(activeItem)
                }
              ]}
              buttonClassName='CZ__dropdown-button'
            />
          </div>
        }
      />
    </>
  );
};

export default observer(ClientDashboard);
