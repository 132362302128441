import React from 'react';
import LinkIcon from '../../../icons/link.svg';
import RowWithDelete from '../../RowWithDelete/RowWithDelete';
import './AddedLink.styles.scss';
import { FileResponseViewModelWithExt } from '../../../core/types';

interface Props {
  link: Partial<FileResponseViewModelWithExt>;
  onDelete: () => void;
}

const AddedLink: React.FC<Props> = ({ link, onDelete }) => {
  return (
    <div className='AddedLink'>
      <RowWithDelete
        onDelete={onDelete}
        item={<div className='AddedLink__label'>{link.name}</div>}
        className='RowWithDelete_margin-bottom_6'
      />
      <div className='AddedLink__item'>
        <div className='AddedLink__icon'>
          <LinkIcon />
        </div>
        <div className='AddedLink__url'>{link.fileLink}</div>
      </div>
    </div>
  );
};

export default AddedLink;
