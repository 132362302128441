import {
  CoachContactsViewModel,
  DayOfWeek,
  SignInInfoViewModel,
  SchedulesBufferViewModel,
  SignUpCoachViewModel,
  CoachContactsExtendedViewModel,
  FileResponseViewModel,
  InvoiceServiceViewModel,
  ClientAppointmentInfoViewModel,
  ClientEventInfoViewModel,
  InvoiceViewModel
} from './backend/api';
import {
  BookingItemColorsEnum,
  EventStatus,
  LocationTypeEnum,
  InvoiceStatusEnum,
  TaxType,
  PaymentStatusEnum,
  InvoiceTypeEnum
} from './enums';
import { action, computed, observable } from 'mobx';
import { UserViewModel } from './backend/api';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import moment from 'moment';
import { SortOrder, ScheduleItemType, InvoiceBillingInterval } from './enums';
import { getTimezone } from './helpers';

export interface AvailabilityInterval {
  timeStartMs: number; // utc 0
  timeEndMs: number;
}

export interface AvailabilityIntervalSingleDate {
  date: number;
  interval: AvailabilityInterval;
}

export interface AvailabilityIntervalExcludeDate {
  date: number;
  interval: AvailabilityInterval;
}

export interface AvailabilityIntervalWeekly {
  day: DayOfWeek;
  interval: AvailabilityInterval;
}

export interface Availability {
  singleDates: AvailabilityIntervalSingleDate[];
  excludeDates?: AvailabilityIntervalExcludeDate[];
  weekly: AvailabilityIntervalWeekly[];
}

export interface iOption<T = any> {
  id: T;
  value?: any;
  name: string;
  content?: JSX.Element;
}

export interface PaginatedResponse<T> {
  totalCount: number;
  items: Array<T>;
}

export interface AppointmentBlock {
  id?: number;
  name: string;
  description: string;
  location: string;
  link: string;
  durationMinutes: number;
  availability: Availability;
  isPrivate: boolean;
  membersCountLimit: number;
  price: string;
  imageId: string;
  remindBeforeMs: number;
  schedulesBuffer: SchedulesBufferViewModel;
  thumbnailImageId: string;
  croppedArea: string;
  locationType: LocationTypeEnum;
  showLocationWhileBooking: boolean;
}

export type Checker = (value: any, data?: any) => boolean;

export interface iFormConfigItem {
  field: string;
  compareField?: string;
  checkIsValid: Checker[];
}

export interface AvailabilitySettingsType {
  hasOtherSettings: boolean;
  minTimeCanChangeAppointmentInAdvanceMs: number;
  schedulesBuffer: {
    beforeMs?: number;
    afterMs?: number;
  };
  notice: {
    periodMs?: number;
  };
}

export interface iInterval {
  itemType: string;
  id: number;
  date: number;
  cellText: string;
  cellMembersText: string;
  cellTextColor: string;
  cellBgColor: string;
  cellBorderColor?: string;
  cellCircleColor?: string;
}

export interface iListViewItem {
  itemType: ScheduleItemType;
  id: number;
  date: number;
  isCanceled: boolean;
  details: {
    title: string;
    text: string;
    duration: number;
    membersToAvailable: string;
    membersAmount: number;
    price: number;
    location: string;
    members: CoachContactsExtendedViewModel[];
  };
  cellTextColor: string;
  cellBgColor?: string;
  cellBorderColor?: string;
  cellCircleColor?: string;
}

export interface iCoachPageCustomizationData {
  logoSrc: string;
  position: string;
  bio: string;
  primaryColor: string;
  secondaryColor: string;
  headlineColor: string;
  paragraphColor: string;
  additionalColor: string;
  backgroundColor: string;
  socialLinks: string[];
}

export interface Appointment {
  id: number;
  appointmentTypeId: number;
  timestampUtc: number;
  timeMs: number;
  price: string;
  invites: string[];
  members: CoachContactsViewModel[];
  appointmentStatus: EventStatus;
  remindBefore: number;
  locationType: LocationTypeEnum;
  location: string;
  duration: number;
}

export interface CoachEvent {
  id: number;
  name: string;
  description: string;
  link: string;
  availableSpots: number;
  startDateTimeStamp: number;
  endDateTimeStamp: number;
  startTimeMs: number;
  endTimeMs: number;
  invites: string[];
  members: CoachContactsExtendedViewModel[];
  membersToRemove?: string[];
  price: string;
  coachEventStatus: EventStatus;
  remindBeforeMs: number;
  isScheduled: boolean;
  isPrivate: boolean;
  imageId: string;
  thumbnailImageId: string;
  croppedArea: string;
  location: string;
  locationType: LocationTypeEnum;
  showLocationWhileBooking?: boolean;
}

export enum BookingItemTypeEnum {
  Meeting,
  Appointment,
  AppointmentBlock
}

export interface iBookingItem {
  id: number;
  name: string;
  timestamp?: number;
  durationMinutes?: number;
  color: BookingItemColorsEnum;
  hasPrice: boolean;
  price: number;
  type: BookingItemTypeEnum;
  description: string;
  link: string;
  location: string;
  locationType: LocationTypeEnum;
  showLocationWhileBooking?: boolean;
  status: EventStatus;
  membersCountLimit: number;
  remindBeforeMs: number;
  isScheduled?: boolean;
  thumbnailImageId: string;
  refundStatus?: string | null;
}

export interface FileResponseViewModelWithExt extends FileResponseViewModel {
  ext: string;
}

export interface CoachContactItem extends CoachContactsViewModel {
  address: string;
  fullName: string;
  formattedPhoneNumber: string;
}

export interface CoachSteps {
  isEventCreated: boolean;
  isFileAdded: boolean;
  isPageCistomized: boolean;
  isScheduled: boolean;
  isStripeAccountConnected: boolean;
}

export interface CoachClientScheduleItem {
  id: number;
  name: string;
  description: string;
  itemType: ScheduleItemType;
  price: number;
  timestamp: number;
  duration: number;
  location: string;
  status: EventStatus;
  refundStatus: string;
  membersCountLimit: number;
  imageId: string;
  activeMembersCount?: number;
}

export abstract class UserStore {
  @observable isSignedIn: boolean = false;
  @observable isDataLoaded: boolean = false;
  @observable isRegistrationCompleted: boolean = false;
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable siteName: string = '';
  @observable timeZone: string = '';
  @observable isTimeZoneDiffers: boolean = false;
  @observable email: string = '';
  @observable country: string = '';
  @observable state: string = '';
  @observable city: string = '';
  @observable phoneNumber: string = '';
  @observable id: string = '';
  @observable imageId: string = '';
  @observable redirectToAfterSignIn = '';

  abstract signOut(): void;
  abstract signIn(data: SignInInfoViewModel): Promise<any>;
  abstract signUp(
    data: SignUpCoachViewModel & { stripePaymentMethodId: string }
  ): Promise<any>;
  abstract completeRegistration(data: any): Promise<any>;
  abstract loadData(): void;

  @action updateData(data: Partial<UserStore>) {
    Object.assign(this, data);
    if (data.timeZone) {
      this.checkTimeZoneDiff();
    }
  }

  abstract get lsTimeZoneLastCheckTimestampKey(): string;

  @action checkTimeZoneDiff = (): void => {
    if (getTimezone(this.timeZone) !== getTimezone(moment.tz.guess())) {
      const lastCheckTimestamp = localStorage.getItem(
        this.lsTimeZoneLastCheckTimestampKey
      );
      if (!lastCheckTimestamp || isNaN(+lastCheckTimestamp)) {
        this.isTimeZoneDiffers = true;
      } else {
        const showFrom = moment
          .utc(+lastCheckTimestamp)
          .add(5, 'day')
          .startOf('day')
          .valueOf();
        if (showFrom < moment.utc().valueOf()) {
          this.isTimeZoneDiffers = true;
        }
      }
    } else {
      this.isTimeZoneDiffers = false;
    }
  };

  @action setTimezoneLastCheckTimestamp = (): void => {
    localStorage.setItem(
      this.lsTimeZoneLastCheckTimestampKey,
      moment.utc().valueOf().toString()
    );
    this.isTimeZoneDiffers = false;
  };

  @action setRedirectToAfterSignIn = (to: string): void => {
    this.redirectToAfterSignIn = to;
  };

  @computed get userData(): Partial<UserViewModel> {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      imageId: this.imageId,
      timeZone: this.timeZone
    };
  }
}

export type StripeEvent =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

export interface iToken {
  auth_token: string;
}
export interface iCity {
  city_name: string;
}
export interface EventInvoice {
  stripeId: string;
  name: string;
  price: number;
  eventStatus: EventStatus;
  eventType: 'event' | 'appointment';
  status: string;
  date: number;
  clientId: string;
  eventId: number;
  type: 'payment' | 'refund';
  isCanceled: boolean;
}

export interface Invoice {
  id?: number;
  amount?: number;
  status: InvoiceStatusEnum;
  type: InvoiceTypeEnum;
  billingInterval: InvoiceBillingInterval;
  intervalValue: number;
  client: CoachContactItem;
  startDate: number;
  dueDate: number;
  createdOn?: number;
  modifiedOn?: number;
  services: InvoiceServiceViewModel[];
  memo: string;
  tax: InvoiceTax;
  customizeData: InvoiceCustomizeData;
  number?: string;
  subscriptionId?: string;
  subscription?: StripeSubscription;
  paymentMethod?: StripePaymentMethod;
}
export interface InvoiceTax {
  id?: number;
  type: TaxType;
  name: string;
  value?: number;
}
export interface InvoiceCustomizeData {
  primaryColor: string;
  backgroundColor: string;
  headlineColor: string;
  paragraphColor: string;
  logoSrc: string;
}

export interface CountryPhoneItem {
  mainCode?: boolean;
  isAreaCode?: boolean;
  areaCodeLength?: number;
  name: string;
  regions: string[];
  iso2: string;
  countryCode: string;
  dialCode: string;
  format: string;
  priority: number;
}

// CountryPhoneData model:
// [
//    Country name,
//    Regions,
//    iso2 code,
//    International dial code,
//    Format (if available),
//    Order priority (if >1 country with same dial code),
//    Area codes (if >1 country with same dial code)
// ]
//
// Regions:
// ['america', 'europe', 'asia', 'oceania', 'africa']
//
// Sub-regions:
// ['north-america', 'south-america', 'central-america', 'carribean',
//  'eu-union', 'ex-ussr', 'ex-yugos', 'baltic', 'middle-east', 'north-africa']
export type CountryPhoneData = [
  string,
  string[],
  string,
  string?,
  string?,
  number?,
  string[]?
];

export interface WelcomeMessageData {
  welcomeText: string;
  message: string;
}

export interface InvoiceForSave {
  id?: number;
  startDate: number;
  dueDate: number;
  clientId: string;
  services: InvoiceServiceViewModel[];
  tax: InvoiceTax;
  memo: string;
  customizeData: InvoiceCustomizeData;
}

export interface Payment {
  id: string;
  amount: number;
  status: PaymentStatusEnum;
  client: CoachContactItem;
  description: string;
  date: number;
  stripePaymentIntentId: string;
  clientAppointment?: ClientAppointmentInfoViewModel;
  clientEvent?: ClientEventInfoViewModel;
  invoice?: InvoiceViewModel;
  entityId: number;
  entityType: number;
}

export interface CoachPayment {
  timestamp: number | string;
  amount: number;
}

export interface CoachComparablePaymentItem {
  amount: number;
  comparable?: number;
}
export interface CoachComparablePayments {
  today: CoachComparablePaymentItem;
  yesterday: CoachComparablePaymentItem;
  week: CoachComparablePaymentItem;
  month: CoachComparablePaymentItem;
}

export interface SortParams<T> {
  sortBy: keyof T;
  sortOrder: SortOrder;
}

export interface StripeSubscription {
  id: string;
  latest_invoice: StripeInvoice;
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid';
}

export interface StripeInvoice {
  id: string;
  payment_intent: StripePaymentIntent;
  status: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
}

export interface StripePaymentIntent {
  id: string;
  payment_method: StripePaymentMethod;
  status:
    | 'requires_payment_method'
    | 'requires_confirmation'
    | 'requires_action'
    | 'processing'
    | 'requires_capture'
    | 'canceled'
    | 'succeeded';
  client_secret: string;
}

export interface StripePaymentMethod {
  id: string;
  card: {
    brand: string;
    country: string;
    exp_month: number;
    exp_year: number;
    funding: string;
    last4: string;
  };
}

export interface Rect {
  right: number;
  top: number;
  left: number;
  bottom: number;
  x: number;
  y: number;
  width: number;
}
