import React from 'react';
import Tooltip from '../../components/Tooltip/Tooltip';
import './CoachDashboardSmallTile.styles.scss';
import HintIcon from '../../icons/hint.svg';
import { isMobileDevice } from '../../core/helpers';

interface Props {
  title: string;
  hint: string;
  children: React.ReactNode;
}

const CoachDashboardSmallTile: React.FC<Props> = ({
  title,
  hint,
  children
}) => {
  return (
    <div className='CoachDashboardSmallTile d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-center'>
        <div className='CoachDashboardSmallTile__title mr-2'>{title}</div>
        <Tooltip
          className='d-flex align-items-center justify-content-center'
          trigger={<HintIcon />}
          content={<div className='CoachDashboardSmallTile__hint'>{hint}</div>}
          triggerType={isMobileDevice() ? 'click' : 'hover'}
          autoMaxWidth={false}
          textAlign='left'
        />
      </div>
      <div className='CoachDashboardSmallTile__btns ml-2'>{children}</div>
    </div>
  );
};

export default CoachDashboardSmallTile;
