import { Provider } from 'mobx-react';
import 'mobx-react/batchingForReactDom';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  ReactNode
} from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from 'react-router-dom';
import './App.styles.scss';
import Routes from './core/routes';
import RootStore from './store/RootStore';
import Scrollbars from 'react-custom-scrollbars';
import { appHeight, getOffsetTop, iOS } from './core/helpers';
import FeatureFlagsProvider from './core/firebase';
import { useStores } from './hooks';

interface ScrollableElemets {
  container: HTMLElement;
  scrollTargetElement?: HTMLElement;
}
interface Scrollable extends ScrollableElemets {
  scrollTop(value?: number): void;
  setScrollTo(el: HTMLElement): void;
}

export const AppScrollbarsContext = React.createContext<Scrollable | null>(
  null
);

const ScrollTop: React.FC = () => {
  const scroll = useContext(AppScrollbarsContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { rootStore } = useStores();

  useEffect(() => {
    if (rootStore.userStore.isSignedIn) {
      rootStore.userStore.signOut();
    }
  });

  const firstMount = useRef(true);

  useEffect(() => {
    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setTimeout(() => {
        const top = getOffsetTop(scroll.scrollTargetElement, scroll.container);
        scroll?.scrollTop(top);

        rootStore.setPrevUrl(pathname + search);
      });
    });
    return () => {
      unlisten();
    };
  }, [history, scroll, pathname, search]);

  useEffect(() => {
    if (firstMount.current) {
      scroll?.scrollTop(0);
      firstMount.current = false;
    }
  }, [scroll]);

  return null;
};

const ScrollWrapper: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const scroll = useRef<Scrollbars>(null);
  const [isIOS] = useState(iOS());
  const [value, setValue] = useState<ScrollableElemets>({
    container: document.body,
    scrollTargetElement: null
  });

  const scrollTop = useCallback(
    (top: number) => {
      const options = {
        top,
        left: 0,
        behavior: (top === 0 ? 'auto' : 'smooth') as ScrollBehavior
      };

      if (!isIOS) {
        ((scroll?.current as Scrollbars & { view: HTMLElement })
          ?.view as HTMLDivElement)?.scrollTo(options);
      } else {
        setTimeout(() => {
          window.scrollTo(options);
        });
      }
    },
    [isIOS]
  );

  const setScrollTo = useCallback((el: HTMLElement) => {
    setValue((prev) => ({
      ...prev,
      scrollTargetElement: el
    }));
  }, []);

  useEffect(() => {
    if (!isIOS) {
      setValue((prev) => ({
        ...prev,
        container: (scroll?.current as any)?.view
      }));
    }
  }, [isIOS, scroll]);

  const memoizedValue = useMemo(
    () => ({
      ...value,
      scrollTop,
      setScrollTo
    }),
    [scrollTop, setScrollTo, value]
  );

  return (
    <AppScrollbarsContext.Provider value={memoizedValue}>
      {isIOS ? children : <Scrollbars ref={scroll}>{children}</Scrollbars>}
    </AppScrollbarsContext.Provider>
  );
};

function App() {
  return (
    <Provider rootStore={RootStore}>
      <FeatureFlagsProvider>
        <HelmetProvider>
          <ScrollWrapper>
            <Router basename={process.env.REACT_APP_BASE_ROUTE}>
              <Routes />
              <ScrollTop />
            </Router>
          </ScrollWrapper>
        </HelmetProvider>
      </FeatureFlagsProvider>
    </Provider>
  );
}

export default App;
