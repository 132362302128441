import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SortOrder } from '../core/enums';
import { SortParams } from '../core/types';

export function usePagingSorting<T>(
  totalCount: number,
  isLoading: boolean,
  pageSize: number = 20,
  defaultSort?: {
    sortBy: keyof T;
    sortOrder: SortOrder;
  }
) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const page = parseInt(params.get('page'));
  const sortByParam = params.get('sortBy') as keyof T;
  const sortOrderParam = params.get('sortOrder');

  const initialSort =
    sortByParam && sortOrderParam
      ? {
          sortBy: sortByParam,
          sortOrder: +sortOrderParam
        }
      : undefined;

  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [sort, setSort] = useState<SortParams<T>>(initialSort || defaultSort);

  useEffect(() => {
    if (!isLoading && totalCount <= pageSize * (pageNumber - 1)) {
      onPageChange(1);
    }
  }, [pageNumber, pageSize, isLoading, totalCount]);

  useEffect(() => {
    const newParams = new URLSearchParams(search);
    if (sort) {
      newParams.set('sortBy', sort.sortBy as string);
      newParams.set('sortOrder', String(sort.sortOrder));
    }
    newParams.set('page', String(pageNumber));
    history.replace(pathname + '?' + newParams.toString());
  }, [pageNumber, history, pathname, sort, search]);

  const onPageChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const onSortChange = (sortBy: keyof T, sortOrder: SortOrder) => () => {
    setPageNumber(1);
    setSort({
      sortBy,
      sortOrder
    });
  };

  const getCurrentPageData = <T>(data: T[]) =>
    data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);

  return {
    pageNumber,
    pageSize,
    sort,
    onPageChange,
    onSortChange,
    getCurrentPageData
  };
}
