import React from 'react';
import InstagramIcon from '../../icons/instagram.svg';
import YoutubeIcon from '../../icons/youtube.svg';
import FacebookIcon from '../../icons/fb.svg';
import TwitterIcon from '../../icons/twitter.svg';
import LinkedinIcon from '../../icons/linkedin.svg';
import WebLinkIcon from '../../icons/webLink.svg';
import EnvelopeIcon from '../../icons/envelope.svg';
import Button from '../Button/Button';
import {
  BookingItemTypeEnum,
  iBookingItem,
  iCoachPageCustomizationData
} from '../../core/types';
import { CoachAccountViewModel } from '../../core/backend/api';
import './CoachPage.styles.scss';
import { isEmail } from '../../core/formValidation';
import CoachPageImage from '../CoachPageImage/CoachPageImage';
import ClientMeetingTile from '../ClientMeetingTile/ClientMeetingTile';
import { getPublicFile } from '../../core/api';
import { useMedia } from '../../hooks';
import TilesCollapsibleGroup from '../TilesCollapsibleGroup/TilesCollapsibleGroup';

interface iProps {
  coachData: iCoachPageCustomizationData;
  coachAccountData: CoachAccountViewModel;
  isInEditor?: boolean;
  bookingItems: iBookingItem[];
  handleTileClick: (bookingItem: iBookingItem) => void;
  handleSignInClick?: () => void;
  isSignInButtonHidden?: boolean;
  setNewImageId?: (image: string) => void;
}

const CoachPage: React.FC<iProps> = ({
  coachData,
  coachAccountData,
  isInEditor = false,
  bookingItems,
  handleTileClick,
  handleSignInClick = () => null,
  isSignInButtonHidden = false,
  setNewImageId = () => {}
}) => {
  const maxShownItems = useMedia(
    ['(min-width: 992px)', '(min-width: 576px)'],
    [3, 2],
    1
  );

  const events = bookingItems.filter(
    (item) => item.type !== BookingItemTypeEnum.AppointmentBlock
  );
  const appointments = bookingItems.filter(
    (item) => item.type === BookingItemTypeEnum.AppointmentBlock
  );

  events.sort((a, b) => (a.timestamp! < b.timestamp! ? -1 : 1));

  return (
    <div className='CoachPage'>
      {isInEditor && coachData.logoSrc ? (
        <img
          src={getPublicFile(coachData.logoSrc)}
          className='CoachPage__logo CoachPage__logo--mb'
          alt='logo'
        />
      ) : null}
      <div
        className={`
          CoachPage__tile px-md-5 px-sm-4 px-3 pb-3
          ${isInEditor ? 'CoachPageEditor--view-only' : ''}
          CZ__border
        `}
      >
        <CoachPageImage
          imageId={coachAccountData.imageId}
          isInEditor={isInEditor}
          setNewImageId={setNewImageId}
        />
        <div className='CoachPage__name CZ__heading mt-5'>
          {coachAccountData.firstName} {coachAccountData.lastName}
        </div>
        <div className='CoachPage__sub-title CZ__sub-heading mt-3'>
          {coachData.position || (isInEditor ? 'Your title' : '')}
        </div>
        {(coachData.bio || isInEditor) && (
          <div
            className='CoachPage__text CZ__text mt-3'
            dangerouslySetInnerHTML={{
              __html:
                coachData.bio ||
                (isInEditor
                  ? 'Your bio goes here...Write something amazing about your experience.'
                  : '')
            }}
          />
        )}
        <div className='CoachPage__socials mt-4'>
          {coachData.socialLinks.map((item) => {
            if (item.trim() === '') return null;
            if (isEmail(item)) {
              return (
                <a
                  key={item}
                  href={`mailto:${item}`}
                  title={item}
                  className='CoachPage__social-link CZ__icon-coach-page'
                >
                  <EnvelopeIcon />
                </a>
              );
            }

            let Icon = WebLinkIcon;
            if (item.indexOf('facebook.com') >= 0) {
              Icon = FacebookIcon;
            } else if (item.indexOf('twitter.com') >= 0) {
              Icon = TwitterIcon;
            } else if (item.indexOf('linkedin.com') >= 0) {
              Icon = LinkedinIcon;
            } else if (item.indexOf('youtube.com') >= 0) {
              Icon = YoutubeIcon;
            } else if (item.indexOf('instagram.com') >= 0) {
              Icon = InstagramIcon;
            }

            return (
              <a
                key={item}
                href={item.includes('http') ? item : `https://${item}`}
                title={item}
                className='CoachPage__social-link CZ__icon-coach-page'
              >
                <Icon />
              </a>
            );
          })}
          {coachAccountData.socialEmail.length ? (
            <a
              href={`mailto:${coachAccountData.socialEmail}`}
              title={coachAccountData.socialEmail}
              className='CoachPage__social-link CZ__icon-coach-page'
            >
              <EnvelopeIcon />
            </a>
          ) : null}
        </div>
        {!isSignInButtonHidden && !isInEditor && (
          <Button
            handleClick={handleSignInClick}
            className='CZ__button ml-0 mt-3'
            data-testid='login-btn'
          >
            Already a client? Login here
          </Button>
        )}
        <div className='w-100 mt-2'>
          {!!events.length ? (
            <>
              <TilesCollapsibleGroup
                title='Sign up for a meeting'
                titleClassName='TilesCollapsibleGroup__title--small'
                headerClassName='pb-2'
                events={events}
                maxShownItems={maxShownItems}
                className='pt-2'
                ItemRenderer={({ item, isVisible }) => (
                  <div
                    className='col-lg-4 col-sm-6 col-12 p-md-3 p-2'
                    key={item.id}
                  >
                    <ClientMeetingTile
                      className='CZ__border'
                      data={item}
                      handleClick={() => handleTileClick(item)}
                      showImage={isVisible}
                    />
                  </div>
                )}
              />
            </>
          ) : null}
          {!!appointments.length ? (
            <TilesCollapsibleGroup
              title='Schedule an appointment'
              titleClassName='TilesCollapsibleGroup__title--small'
              headerClassName='pb-2'
              events={appointments}
              maxShownItems={maxShownItems}
              className='pt-3'
              ItemRenderer={({ item, isVisible }) => (
                <div
                  className='col-lg-4 col-sm-6 col-12 p-md-3 p-2'
                  key={item.id}
                >
                  <ClientMeetingTile
                    className='CZ__border'
                    data={item}
                    handleClick={() => handleTileClick(item)}
                    buttonName='Schedule'
                    showDuration
                    showImage={isVisible}
                  />
                </div>
              )}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CoachPage;
