import React from 'react';
import './Warning.styles.scss';
import WarningIcon from '../../icons/warning.svg';

import CloseIcon from '../../icons/cross.svg';
import Button from '../Button/Button';
interface Props {
  className?: string;
  onClose?: () => void;
  children: React.ReactNode;
  type?: WarningType;
  title?: string;
}

export type WarningType = 'red' | 'yellow';

const Warning: React.FC<Props> = ({
  children,
  className = '',
  onClose,
  type = 'yellow',
  title
}: Props) => {
  return (
    <div
      className={`Warning Warning--${type} d-flex align-items-start p-2 p-md-3 ${className}`}
    >
      <div className='Warning__icon mr-2 d-flex align-items-center flex-shrink-0'>
        <WarningIcon />
      </div>
      <div className='flex-grow-1'>
        {title && <p className='Warning__title mb-1'>{title}</p>}
        <p className='Warning__text'>{children}</p>
      </div>
      {onClose && (
        <Button
          handleClick={onClose}
          variations={['naked']}
          className='ml-1 flex-shrink-0'
        >
          <CloseIcon />
        </Button>
      )}
    </div>
  );
};

export default Warning;
