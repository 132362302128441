import { Invoice } from '../../core/types';
import { searchBy } from '../../core/helpers';
import moment from 'moment';

export const filterForSearch = (
  debouncedSearchString: string,
  invoice: Invoice
) => {
  let total = String(invoice.amount || '');
  if (!debouncedSearchString.includes('.')) {
    total = total.split('.')[0];
  }

  return (
    searchBy(
      invoice.client?.firstName + ' ' + invoice.client?.lastName,
      debouncedSearchString
    ) ||
    searchBy(invoice.client?.email, debouncedSearchString) ||
    searchBy(String(invoice.number), debouncedSearchString) ||
    searchBy(total, debouncedSearchString) ||
    searchBy(
      moment.utc(invoice.startDate).format('MMM D'),
      debouncedSearchString
    ) ||
    searchBy(moment.utc(invoice.dueDate).format('MMM D'), debouncedSearchString)
  );
};
