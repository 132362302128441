import React, { useEffect } from 'react';
import './InfoBlockModal.styles.scss';
import Modal from '../../Modal/Modal';
import InfoBlock, { iInfoBlockData } from '../../InfoBlock/InfoBlock';

interface iProps {
  isOpened: boolean;
  close: () => void;
  data: iInfoBlockData;
  className?: string;
  imageIcon?: JSX.Element;
  actions?: JSX.Element;
  footer?: JSX.Element;
}

const InfoBlockModal: React.FC<iProps> = ({
  isOpened,
  data,
  imageIcon,
  className = '',
  actions = null,
  footer = null,
  close
}) => {
  useEffect(() => {
    if (isOpened) {
      // to resize window due to InfoBlock image resize
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [isOpened]);
  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{
        width: '540px',
        padding: 0
      }}
      className={`InfoBlockModal ${className}`}
      data-testid='info-block-modal'
    >
      {data && (
        <InfoBlock
          className='InfoBlock--InfoBlockModal'
          data={data}
          imageIcon={imageIcon}
          actions={actions}
        />
      )}
      <div className='InfoBlockModal__footer'>{footer}</div>
    </Modal>
  );
};

export default InfoBlockModal;
