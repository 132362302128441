import React, { useState, ReactElement, useEffect, useMemo } from 'react';
import moment, { Moment } from 'moment';
import './WeekCalendarListView.styles.scss';
import { getTimeInZone } from '../../core/helpers';
import { iListViewItem } from '../../core/types';
import EmptyState from '../EmptyState/EmptyState';
import { Pagination } from '../Pagination/Pagination';
import { usePagingSorting } from '../../hooks';

export interface iItemRendererProps<E = iListViewItem> {
  item: E;
  isDisabled: boolean;
  className?: string;
}

export interface CalendarEvent extends Record<string, any> {
  date: number;
}

const PAGE_SIZE = 6;

interface iProps<E extends { date: number } = iListViewItem> {
  isLoading: boolean;
  events: E[];
  totalCount: number;
  ItemRenderer: React.FC<iItemRendererProps<E>>;
  timeZone: string;
  headerBlock?: ReactElement<any>;
  className?: string;
  onChange?: (
    startTimeStamp: number,
    endTimeStamp: number,
    pageNumber: number,
    pageSize: number
  ) => void;
}

function getDayEvents<E extends { date: number } = iListViewItem>(
  intervals: E[],
  day: Moment
): E[] {
  const date = day.valueOf();
  const nextDate = day.clone().add(1, 'days').valueOf();
  return intervals
    .filter((item) => item.date >= date && item.date < nextDate)
    .sort((a, b) => (a.date <= b.date ? -1 : 1));
}

const WeekCalendarListView: React.FC<iProps> = ({
  isLoading,
  events,
  totalCount,
  ItemRenderer,
  timeZone,
  headerBlock,
  className = '',
  onChange
}) => {
  const [showUpcoming, setShowUpcoming] = useState<boolean>(true);
  const { pageNumber, pageSize, onPageChange } = usePagingSorting(
    totalCount,
    isLoading,
    PAGE_SIZE
  );

  const currentDateTime = useMemo(
    () => moment.utc(getTimeInZone(Date.now(), timeZone || '')),
    [timeZone]
  );

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const handleShowUpcomingChange = (val: boolean) => {
    setShowUpcoming(val);
    onPageChange(1);
  };

  useEffect(() => {
    onChange(
      showUpcoming ? currentDateTime.valueOf() : null,
      showUpcoming ? null : currentDateTime.valueOf(),
      pageNumber,
      pageSize
    );
  }, [showUpcoming, pageNumber, pageSize, currentDateTime, onChange]);

  const getCalendarDays = () => {
    const eventDatesSet = new Set<number>();
    events.forEach((e) => {
      eventDatesSet.add(moment.utc(e.date).startOf('day').valueOf());
    });

    return Array.from(eventDatesSet.values())
      .sort((a, b) => (a - b) * (showUpcoming ? 1 : -1))
      .map((date) => moment.utc(date));
  };

  const renderDays = () => {
    const days = getCalendarDays();

    return days.map((day, index) => (
      <div key={index} className='WeekCalendarListView__day'>
        <div className='WeekCalendarListView__day-name'>
          {day.format('ddd, MMM D')}
        </div>
        {getDayEvents(events, day).map((e) => {
          return (
            <ItemRenderer
              key={e.id}
              item={e}
              isDisabled={e.date < currentDateTime.valueOf()}
            />
          );
        })}
      </div>
    ));
  };

  return (
    <div className={`WeekCalendarListView ${className}`}>
      <div className='WeekCalendarListView__header'>
        <div className='WeekCalendarListView__tabs-block flex-shrink-0 mb-md-4 mb-3'>
          <div className='TabsMenu TabsMenu--week-calendar'>
            <div
              className={`TabsMenu__tabs-item ${showUpcoming ? 'active' : ''}`}
              onClick={() => handleShowUpcomingChange(true)}
            >
              Upcoming
            </div>
            <div
              className={`TabsMenu__tabs-item ${!showUpcoming ? 'active' : ''}`}
              onClick={() => handleShowUpcomingChange(false)}
            >
              Completed
            </div>
          </div>
        </div>
        <div className='WeekCalendarListView__legend mb-md-4 mb-3'>
          {headerBlock}
        </div>
      </div>
      <div className='WeekCalendarListView__body'>
        {!!events.length && !isLoading && renderDays()}
        {!events.length && !isLoading && <EmptyState className='mt-5 pt-3' />}
      </div>
      <Pagination
        pageNumber={pageNumber}
        pageSize={PAGE_SIZE}
        totalCount={totalCount}
        onPageGhange={handlePageChange}
      />
    </div>
  );
};

export default WeekCalendarListView;
