import React, { useState, useEffect, useRef, ReactNode } from 'react';
import SelectItem from './SelectItem';
import DropDownWithoutPortal from '../DropDownWithoutPortal/DropDownWithoutPortal';
import { toggleInArray } from '../../core/helpers';
import './Select.styles.scss';
import { iOption } from '../../core/types';
import DropDownWithPortal from '../DropDownWithPortal/DropDownWithPortal';
import { Scrollbars } from 'react-custom-scrollbars';

interface iProps {
  options: iOption[];
  value?: string | number | null;
  multipleSelectValue?: any[];
  title?: string;
  onChange: (id: any) => void;
  placeholder?: string;
  isCheckboxHidden?: boolean;
  className?: string;
  classNameSuffix?: string;
  withPortal?: boolean;
  children?: ReactNode;
  isDisabled?: boolean;
  readOnly?: boolean;
  isError?: boolean;
  tabIndex?: number;
  errorMessage?: string;
}

const Select: React.FC<iProps> = ({
  options = [],
  value = null,
  title,
  onChange,
  placeholder = 'None',
  isCheckboxHidden = true,
  multipleSelectValue = [],
  className = '',
  classNameSuffix = '',
  withPortal = false,
  children = null,
  isDisabled = false,
  readOnly = false,
  isError = false,
  errorMessage = '',
  tabIndex,
  ...rest
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  // const [isDropDownShowOverInput, setIsDropDownShowOverInput] = useState(false);
  const isRadioBtn = multipleSelectValue.length === 0;
  // const dropDownList = useRef<HTMLDivElement | null>(null);
  const selectContainerRef = useRef<HTMLDivElement | null>(null);
  const dropdown = useRef<Scrollbars>();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      selectContainerRef.current &&
      !selectContainerRef.current.contains(e.target as Node)
    ) {
      setIsDropdownOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*   useEffect(() => {
    if (dropDownList.current) {
      if (
        window.innerHeight - dropDownList.current.getBoundingClientRect().top <
        dropDownList.current.clientHeight
      ) {
        setIsDropDownShowOverInput(true);
      } else {
        setTimeout(() => {
          setIsDropDownShowOverInput(false);
        }, 500);
      }
    }
  }, [isDropdownOpened]); */

  useEffect(() => {
    if (isDropdownOpened) {
      setActiveIndex(options.findIndex((o) => o.id === value));
      dropdown.current?.scrollTop(activeIndex * 40);
    }
  }, [isDropdownOpened, dropdown.current]);
  /*
  const classList = new Set([
    'Select__dropdown',
    children && 'Select__dropdown--with-children',
    isDropDownShowOverInput ? 'Select__dropdown--show-up' : ''
  ]); */

  let content: string | JSX.Element = placeholder;

  if (isRadioBtn) {
    if (value) {
      const checkedOption = options.find((option) => value === option.id);
      content = checkedOption
        ? checkedOption.content || checkedOption.name
        : content;
    }
  } else {
    const optionsCount = options.length;
    const checkedOptionsCount = multipleSelectValue.length;

    if (checkedOptionsCount > 0) {
      if (checkedOptionsCount === 1) {
        const checkedOption = options.find(
          ({ id }) => id === multipleSelectValue[0]
        );
        content = checkedOption
          ? checkedOption.content || checkedOption.name
          : content;
      } else if (checkedOptionsCount === optionsCount) {
        content = 'All selected';
      } else {
        content = `${checkedOptionsCount} of ${optionsCount}`;
      }
    }
  }

  const handleSelect = (optionId: string) => {
    if (isRadioBtn) {
      onChange(optionId);
      setIsDropdownOpened(false);
    } else {
      onChange(toggleInArray(multipleSelectValue, optionId));
    }
  };

  const handleSelectAll = () => {
    if (options.length === multipleSelectValue.length) {
      onChange([]);
    } else {
      onChange(options.map((item) => item.id));
    }
  };

  const handleClickInput = (event: React.MouseEvent<HTMLElement>) => {
    handleFocus();
  };

  const handleFocus = () => {
    setIsDropdownOpened(!isDropdownOpened);
  };

  const closeDropdown = () => setIsDropdownOpened(false);

  const handleOptionClick = (index: number, optionId: any) => {
    setActiveIndex(index);
    handleSelect(optionId);
  };

  const handleKeyboardNav = (e: React.KeyboardEvent) => {
    if (e.key !== 'Tab') {
      e.preventDefault();
    }

    if (isRadioBtn) {
      switch (e.key) {
        case 'ArrowUp':
          if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
          }
          break;
        case 'ArrowDown':
          if (activeIndex < options.length - 1) {
            setActiveIndex(activeIndex + 1);
          }
          break;

        case 'Enter':
          handleSelect(options[activeIndex].id);
          break;

        case ' ':
          handleFocus();
          break;

        default:
          break;
      }
    }
  };

  const renderDropdownListContent = () => {
    const content = (
      <>
        {!isRadioBtn && (
          <div
            onClick={handleSelectAll}
            className='Select__top Select__top--checkbox'
          >
            <SelectItem
              isChecked={options.length === multipleSelectValue.length}
              labelText='Select All'
              onChange={() => {}}
              noIcon={isCheckboxHidden}
              isPartialChecked={
                multipleSelectValue.length < options.length &&
                multipleSelectValue.length > 0
              }
            />
          </div>
        )}
        {options.map((option, index) => (
          <div
            className='Select__row'
            key={option.id}
            onClick={() => handleOptionClick(index, option.id)}
          >
            <SelectItem
              isChecked={
                isRadioBtn
                  ? options[activeIndex]?.id === option.id
                  : multipleSelectValue.includes(option.id)
              }
              labelText={option.content || option.name}
              onChange={() => {}}
              isRadioBtn={isRadioBtn}
              noIcon={isCheckboxHidden}
            />
          </div>
        ))}
      </>
    );
    return options.length > 5 ? (
      <Scrollbars ref={dropdown} style={{ height: 200 }}>
        {content}
      </Scrollbars>
    ) : (
      content
    );
  };

  const renderSelectedOption = () => {
    if (children) {
      return children;
    }
    if (isRadioBtn) {
      return (
        <SelectItem
          tabIndex={tabIndex}
          isDisabled={isDisabled}
          className={`SelectItem--value ${isError ? 'SelectItem--error' : ''} ${
            classNameSuffix ? `SelectItem--${classNameSuffix}` : ''
          }`}
          labelText={content}
          onChange={() => {}}
          isChecked={value}
          noIcon={true}
          onFocus={handleFocus}
          onBlur={closeDropdown}
        />
      );
    }
    return (
      <SelectItem
        className={'SelectItem--disabled'}
        labelText={content}
        onChange={() => {}}
        isChecked={multipleSelectValue.length === options.length}
        noIcon={true}
      />
    );
  };

  return (
    <div
      className={`
                Select
                ${children ? 'Select--with-children' : ''}
                ${isDisabled ? 'Select--disabled' : ''}
                ${readOnly ? 'Select--readonly' : ''}
                ${className}
            `}
      ref={selectContainerRef}
      {...rest}
    >
      {title && <div className='Select__title'>{title}</div>}
      <div
        className={`
                    Select__input
                    ${isDropdownOpened ? 'Select__input--opened' : ''}
                    ${children ? 'Select__input--with-children' : ''}
                    ${isDisabled ? 'Select__input--disabled' : ''}
                    ${readOnly ? 'Select__input--readonly' : ''}
                    ${isError ? 'Select__input--error' : ''}
                `}
        onClick={handleClickInput}
        onKeyDown={handleKeyboardNav}
      >
        {renderSelectedOption()}
      </div>
      {isError && errorMessage && (
        <div className='Select__error-message' title={errorMessage}>
          {errorMessage}
        </div>
      )}
      {withPortal ? (
        <DropDownWithPortal
          triggerRef={selectContainerRef}
          shown={isDropdownOpened}
          onClose={closeDropdown}
        >
          {renderDropdownListContent()}
        </DropDownWithPortal>
      ) : (
        <DropDownWithoutPortal shown={isDropdownOpened}>
          {renderDropdownListContent()}
        </DropDownWithoutPortal>
      )}
    </div>
  );
};

export default Select;
