import React from 'react';
import PencilIcon from '../../../icons/pencil.svg';
import BucketIcon from '../../../icons/bucket.svg';
import { iInfoBlockData } from '../../InfoBlock/InfoBlock';
import moment from 'moment';
import { getTimeInZone } from '../../../core/helpers';
import RootStore from '../../../store/RootStore';
import Tooltip from '../../Tooltip/Tooltip';

interface Props {
  data: iInfoBlockData;
  onEdit: (item: any) => void;
  onCancel: (item: any) => void;
}

const CoachInfoBlockModalActions: React.FC<Props> = ({
  data,
  onEdit,
  onCancel
}) => {
  if (!data) return null;
  const currentTimeStamp = moment
    .utc(getTimeInZone(Date.now(), RootStore.userStore.timeZone || ''))
    .valueOf();
  const isCompleted = data?.date && data.date < currentTimeStamp;
  const isCanceled = data?.isCanceled;
  if (isCompleted || isCanceled) return null;
  return (
    <>
      <Tooltip
        trigger={
          <button
            className='CoachInfoBlockModal__btn flex-shrink-0 mx-sm-3 mx-2'
            onClick={onEdit}
          >
            <PencilIcon />
          </button>
        }
        content={<div className='text-center'>Edit</div>}
        triggerType='hover'
      />
      <Tooltip
        trigger={
          <button
            className='CoachInfoBlockModal__btn flex-shrink-0'
            onClick={onCancel}
          >
            <BucketIcon />
          </button>
        }
        content={<div className='text-center'>Cancel</div>}
        triggerType='hover'
      />
    </>
  );
};

export default CoachInfoBlockModalActions;
