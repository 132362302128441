import React, { ReactNode } from 'react';
import './AdminPage.styles.scss';
import SignedPage from '../SignedPage/SignedPage';

interface iProps {
  children: ReactNode;
  className?: string;
  title: string;
}

const AdminPage: React.FC<iProps> = ({ children, title, className = '' }) => {
  return (
    <SignedPage title={title} className={className}>
      <div className='AdminPage'>{children}</div>
    </SignedPage>
  );
};

export default AdminPage;
