import React, { useState } from 'react';

import Page from '../../containers/Page/Page';

import './CoachConnectStripe.styles.scss';
import StripeLogo from '../../images/stripe-logo.svg';
import Button from '../../components/Button/Button';
import { useStores } from '../../hooks/useStores';
import { CoachRoutesEnum } from '../../core/enums';
import {
  getStripeConnectUrl,
  STRIPE_CONNECTED_KEY
} from '../../core/integrations';
import { observer } from 'mobx-react';

interface iProps {}

const CoachConnectStripe: React.FC<iProps> = () => {
  const { rootStore } = useStores();

  const [isAlreadyHaveAccount, setIsAlreadyHaveAccount] = useState(false);

  const STRIPE_URL = getStripeConnectUrl(rootStore.userStore.id);

  return (
    <Page
      className='CoachConnectStripe d-flex flex-column align-items-stretch pt-2 px-md-4 px-sm-3 px-2'
      title='Stripe Integration'
    >
      {!isAlreadyHaveAccount ? (
        <>
          <div className='d-flex mb-2'>
            <Button
              link={`${
                rootStore.prevUrl || CoachRoutesEnum.Account
              }?${STRIPE_CONNECTED_KEY}=skip`}
              className='CoachConnectStripe__goBack'
            >
              Go Back
            </Button>
          </div>
          <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
            <div className='CoachConnectStripe__content d-flex flex-column text-center'>
              <h3 className='CoachConnectStripe__title mb-3'>
                Connect with Stripe
              </h3>
              <p className='CoachConnectStripe__description mb-sm-4 mb-2 pb-3'>
                Click one of the options below to connect your site to a Stripe
                account.
              </p>
              <div className='CoachConnectStripe__tile'>
                <div className='CoachConnectStripe__tile-header d-flex align-items-center justify-content-center'>
                  <StripeLogo />
                </div>
                <div className='CoachConnectStripe__tile-content p-4'>
                  <div className='d-flex flex-column align-items-center p-sm-2'>
                    <Button
                      variations={['primary']}
                      type='button'
                      url={STRIPE_URL}
                      className='m-0 mb-3'
                    >
                      I don’t have a Stripe Account
                    </Button>
                    <Button
                      variations={['inversed']}
                      className='m-0'
                      handleClick={() => setIsAlreadyHaveAccount(true)}
                    >
                      I have a Stripe Account
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='d-flex mb-2'>
            <Button
              handleClick={() => setIsAlreadyHaveAccount(false)}
              className='CoachConnectStripe__goBack'
            >
              Go Back
            </Button>
          </div>
          <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
            <div className='CoachConnectStripe__content CoachConnectStripe__content--has-account d-flex flex-column text-center'>
              <h3 className='CoachConnectStripe__title mb-3'>
                Sign in into your Stripe account and create a new sub account.
              </h3>
              <p className='CoachConnectStripe__description mb-sm-3 mb-1 pb-3'>
                Once connected this cannot be changed. Please make sure that you
                are connecting a new Stripe account dedicated to CoachPro.{' '}
                <a
                  className='link'
                  href='https://stripe.com/docs/multiple-accounts'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Click here
                </a>{' '}
                for more details.
              </p>
              <div className='CoachConnectStripe__tile mx-3'>
                <div className='CoachConnectStripe__tile-header d-flex align-items-center justify-content-center'>
                  <video width='100%' autoPlay controls>
                    <source
                      src='https://media.stripe.com/b0f6b1e0523b84256fd60d0500a6a9223dac0fd8.mp4'
                      type='video/mp4'
                    />
                  </video>
                </div>
                <div className='CoachConnectStripe__tile-content p-4'>
                  <div className='d-flex flex-column align-items-center p-sm-2'>
                    <Button
                      variations={['primary']}
                      url={STRIPE_URL}
                      className='m-0 mb-3'
                    >
                      Create a New Stripe Sub-account
                    </Button>
                    <Button
                      link={rootStore.prevUrl || CoachRoutesEnum.Account}
                      variations={['inversed']}
                      className='m-0'
                    >
                      I’ll set this up later
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default observer(CoachConnectStripe);
