import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';
import CrossIcon from '../../icons/cross.svg';
import cn from 'classnames';
import { useDebounce, useOverflow, useWindowResize } from '../../hooks';
import './Modal.styles.scss';
import { AppScrollbarsContext } from '../../App';
import { iOS } from '../../core/helpers';

interface Props {
  isOpened: boolean;
  close?: () => void;
  children: ReactNode;
  style?: React.CSSProperties;
  full?: boolean;
  hasInner?: boolean;
  className?: string;
}

const Modal = React.forwardRef<any, Props>(
  (
    {
      isOpened,
      style,
      children,
      close,
      className = '',
      hasInner = true,
      full,
      ...rest
    },
    ref
  ) => {
    const scroll = useContext(AppScrollbarsContext);
    const debouncedIsOpened = useDebounce(isOpened, 200);
    const innerRef = useRef<HTMLDivElement>();
    const bodyRef = useRef<HTMLDivElement>();
    const [overflowY, setOverflowY] = useState<'visible' | 'auto'>('visible');
    const isModalOpened = isOpened || debouncedIsOpened;

    useEffect(() => {
      if (isModalOpened) {
        setTimeout(() => {
          checkSize();
        }, 50);
      }
    }, [isModalOpened]);

    const hasFadeIn = useCallback(
      () => document.body.querySelectorAll('.Modal.fadeIn').length !== 0,
      []
    );

    useOverflow(isModalOpened, 'modal-opened', hasFadeIn);

    useWindowResize(() => {
      checkSize();
    }, 100);

    const checkSize = () => {
      setOverflowY(
        innerRef?.current?.clientHeight < bodyRef?.current?.clientHeight
          ? 'auto'
          : 'visible'
      );
    };

    useEffect(() => {
      if (!iOS()) {
        scroll.container.dispatchEvent(new Event('scroll'));
      } else {
        window.dispatchEvent(new Event('scroll'));
      }
    }, [isOpened, scroll.container]);

    return createPortal(
      <>
        {isModalOpened && (
          <div
            className={cn('Modal', className, {
              fadeIn: isOpened,
              fadeOut: !isOpened,
              'Modal--full-screen': full
            })}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
            ref={ref}
            {...rest}
          >
            <div className='Modal__overlay' />
            {hasInner ? (
              <div
                className='Modal__inner Scrollbar'
                ref={innerRef}
                style={{
                  ...style,
                  overflowY
                }}
              >
                <div className='Modal__body' ref={bodyRef}>
                  {children}
                </div>
                {close && (
                  <div className='Modal__close' onClick={close}>
                    <CrossIcon />
                  </div>
                )}
              </div>
            ) : (
              children
            )}
          </div>
        )}
      </>,
      document.getElementById('modals')!
    );
  }
);

export default Modal;
