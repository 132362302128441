import React, { useState, useEffect, useMemo } from 'react';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../Button/Button';
import Select from '../Select/Select';
import PreventUnsavedChangesModal from '../Modals/PreventUnsavedEditsModal/PreventUnsavedEditsModal';
import {
  CountriesList,
  getCountryId,
  getStateId,
  getStatesByCountry,
  StatesList,
  USA_COUNTRY_ID
} from '../Modals/ContactModal/ContactModal.helpers';
import { iOption } from '../../core/types';
import { createLink, getServerModelValidation } from '../../core/helpers';
import { isSiteNameExists } from '../../core/api';
import './PersonalDataForm.styles.scss';
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal';
import { iFormConfigItem } from '../../core/types';
import {
  getInputErrors,
  isDataValid,
  isInputValid
} from '../../core/formValidation';
import {
  ClientAccountViewModel,
  CoachAccountViewModel
} from '../../core/backend/api';
import CustomizedPhoneInput from '../CustomizedPhoneInput';
import PreviewIcon from '../../icons/preview.svg';
import getGlobal from '../../core/globals';
import { ClientRoutesEnum } from '../../core/enums';
import { getTimezones } from '../../core/helpers';
import LogoUploader from '../LogoUploader/LogoUploader';

export type PersonalData = (ClientAccountViewModel | CoachAccountViewModel) & {
  siteName?: string;
};

interface Props {
  initialData: PersonalData;
  formConfig: iFormConfigItem[];
  onSave: (newData: PersonalData) => Promise<unknown>;
  withSiteName?: boolean;
  withSiteNameView?: boolean;
  withAutoSiteName?: boolean;
}

let checkSiteNameTimer: number;

const PersonalDataForm: React.FC<Props> = ({
  initialData,
  formConfig,
  withSiteName,
  withSiteNameView,
  withAutoSiteName,
  onSave
}) => {
  const [data, setData] = useState<PersonalData>(initialData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});
  const [isSiteNameFieldDirty, setIsSiteNameFieldDirty] = useState(false);
  const [isSiteNameExist, setIsSiteNameExist] = useState(false);
  const countryId = getCountryId(data.country, true);
  const states = getStatesByCountry(countryId);
  const [selectedCountry, setSelectedCountry] = useState<string>(countryId);
  const [statesByCountry, setStatesByCountry] = useState<iOption[]>(states);
  const [selectedState, setSelectedState] = useState<string>(
    states.length ? getStateId(data.state, states, true) : ''
  );
  const [
    isConfirmChangeSiteNameOpened,
    setIsConfirmChangeSiteNameOpened
  ] = useState<boolean>(false);

  const isUSASelected =
    selectedCountry === USA_COUNTRY_ID && !!statesByCountry.length;

  const { timeZone } = data;

  const timeZones = useMemo(() => getTimezones(timeZone), [timeZone]);

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (
      initialData.siteName &&
      initialData.siteName !== data.siteName &&
      !isSiteNameExist &&
      isTheInputValid('siteName', data)
    ) {
      return setIsConfirmChangeSiteNameOpened(true);
    }
    saveForm();
  };

  const handleNameBlur = () => {
    if (
      withAutoSiteName &&
      !data.siteName &&
      data.firstName.trim() &&
      data.lastName.trim()
    ) {
      handleChangeSiteName(createLink(`${data.firstName}_${data.lastName}`));
    }
  };

  const closeConfirmChangeSiteNameModal = () => {
    handleChangeField('siteName')(initialData.siteName);
    setIsConfirmChangeSiteNameOpened(false);
  };

  const handleChangeSiteName = (value: string) => {
    if (!isSiteNameFieldDirty) {
      setIsSiteNameFieldDirty(true);
    }
    handleChangeField('siteName')(value);
  };

  const fomatSiteNameValue = (value: string) => {
    return value.toLowerCase().replace(/[^a-z\d-_]/, '');
  };

  const saveForm = () => {
    const country = CountriesList!.find(
      (country) => country.id === selectedCountry
    );
    const state = statesByCountry!.find((state) => state.id === selectedState);
    const payload = {
      ...data,
      country: country ? country.name : '',
      state: state && isUSASelected ? state.name : ''
    };
    if (isTheDataValid(payload) && !isSiteNameExist) {
      setIsValidationShown(false);
      setIsSiteNameFieldDirty(false);
      setIsSubmitting(true);
      onSave(payload)
        .then(() => {
          setIsConfirmChangeSiteNameOpened(false);
        })
        .catch((error) => {
          setIsValidationShown(true);
          setServerValidation(getServerModelValidation(error));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsValidationShown(true);
      setIsConfirmChangeSiteNameOpened(false);
    }
  };

  const handleChangeField = (field: string) => (value: any) => {
    setData({
      ...data,
      [field]: field === 'email' ? value.trim() : value
    });
  };

  const handleChangeCountry = (countryId: string) => {
    setSelectedCountry(countryId);
    setStatesByCountry(StatesList.filter((state) => state.value === countryId));
  };

  function isTheInputValid(inputField: string, data: any) {
    return isInputValid(formConfig)(inputField, data);
  }

  function getTheInputErrors(inputField: string, data: any) {
    return getInputErrors(formConfig)(inputField, data);
  }

  function isTheDataValid(data: any) {
    return isDataValid(formConfig)(data);
  }

  useEffect(() => {
    const currentCountryId = getCountryId(initialData?.country, true);
    setSelectedCountry(currentCountryId);
    const states = getStatesByCountry(currentCountryId);
    setStatesByCountry(states);
    if (states.length)
      setSelectedState(getStateId(initialData?.state, states, true));
    setData(initialData);
  }, [initialData]);

  const currentSiteName = data.siteName;
  const initialSiteName = initialData.siteName;

  useEffect(() => {
    if (currentSiteName?.trim() !== '' && isSiteNameFieldDirty) {
      clearTimeout(checkSiteNameTimer);
      checkSiteNameTimer = window.setTimeout(() => {
        isSiteNameExists(currentSiteName.trim()).then((response) => {
          setIsSiteNameExist(
            response.data && initialSiteName !== currentSiteName
          );
        });
      }, 300);
    } else {
      setIsSiteNameExist(false);
    }
  }, [currentSiteName, initialSiteName, isSiteNameFieldDirty]);

  return (
    <div className='PersonalDataForm'>
      <form
        className='PersonalDataForm__block row mx-n2'
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        <div className='d-flex flex-column col-xl-2 col-md-3 col-sm-4 col-6 offset-xl-1 offset-md-0 offset-sm-4 offset-3 px-2 mb-3'>
          <LogoUploader
            imageUrl={data.imageId}
            handleChangeField={handleChangeField}
          />
        </div>
        <div className='d-flex flex-column col-lg-8 col-md-9 col-sm-12 px-2'>
          <div className='row mx-n2 mt-n3'>
            <div className='col-sm-6 px-2'>
              <FormInput
                autoFocus
                label='First Name'
                value={data.firstName}
                handleChange={handleChangeField('firstName')}
                onBlur={handleNameBlur}
                isInvalid={
                  isValidationShown && !isTheInputValid('firstName', data)
                }
                errorMessage={getTheInputErrors('firstName', data)[0]}
                placeholder='e.g. Nick'
                data-testid='firstname-input'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                label='Last Name'
                value={data.lastName}
                handleChange={handleChangeField('lastName')}
                onBlur={handleNameBlur}
                isInvalid={
                  isValidationShown && !isTheInputValid('lastName', data)
                }
                errorMessage={getTheInputErrors('lastName', data)[0]}
                placeholder='e.g. Carter'
                data-testid='lastname-input'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <FormInput
                label='Email'
                value={data.email}
                handleChange={handleChangeField('email')}
                isInvalid={
                  isValidationShown &&
                  (!isTheInputValid('email', data) ||
                    !!serverValidation['email'])
                }
                errorMessage={
                  getTheInputErrors('email', data)[0] ||
                  serverValidation['email']
                }
                data-testid='email-input'
              />
            </div>
            <div className='col-sm-6 px-2' data-testid='phone-input'>
              <FormInput
                label='Phone number'
                value={data.phoneNumber}
                isInvalid={
                  isValidationShown && !isTheInputValid('phoneNumber', data)
                }
                errorMessage={getTheInputErrors('phoneNumber', data)[0]}
                handleChange={handleChangeField('phoneNumber')}
                inputClassName='FormInput__input--phoneNumber'
              >
                <CustomizedPhoneInput
                  value={data.phoneNumber}
                  onChange={(value) => handleChangeField('phoneNumber')(value)}
                  inputClass={
                    isValidationShown && !isTheInputValid('phoneNumber', data)
                      ? 'PhoneInputInput--error'
                      : ''
                  }
                />
              </FormInput>
            </div>
            <div className='col-12 px-2'>
              <Select
                options={timeZones}
                value={data.timeZone}
                title={'Time zone'}
                onChange={handleChangeField('timeZone')}
                className='Select--contact-timezone'
                classNameSuffix='PersonalDataForm'
                data-testid='timezone-select'
              />
            </div>
            <div className='col-sm-6 px-2'>
              <Select
                options={CountriesList}
                value={selectedCountry}
                title={'Country'}
                onChange={handleChangeCountry}
                className='Select--contact-country'
                classNameSuffix='PersonalDataForm'
                data-testid='country-select'
              />
            </div>
            {isUSASelected && (
              <div className='col-sm-6 px-2'>
                <Select
                  options={statesByCountry}
                  value={selectedState}
                  title={'State'}
                  onChange={setSelectedState}
                  className='Select--contact-state'
                  classNameSuffix='PersonalDataForm'
                  data-testid='state-select'
                />
              </div>
            )}
            <div className='col-sm-6 px-2'>
              <FormInput
                name='city'
                label='City'
                value={data.city}
                isInvalid={isValidationShown && !isTheInputValid('city', data)}
                errorMessage='This field is required'
                handleChange={handleChangeField('city')}
                placeholder='Add city'
                data-testid='city-input'
              />
            </div>
            {withSiteName && (
              <div
                className={`col-sm-6 px-2 ${
                  !isUSASelected ? 'offset-sm-6' : ''
                }`}
              >
                <FormInput
                  label='Page name'
                  value={data.siteName}
                  isInvalid={
                    isSiteNameExist ||
                    (isValidationShown && !isTheInputValid('siteName', data))
                  }
                  errorMessage={
                    isSiteNameExist
                      ? "This sitename isn't free, please choose another"
                      : getTheInputErrors('siteName', data)[0]
                  }
                  handleChange={handleChangeSiteName}
                  valueFormatter={fomatSiteNameValue}
                  data-testid='pagename-input'
                  append={
                    withSiteNameView ? (
                      <a
                        className='SuperAdminUsers__PreviewIcon d-flex aling-items-center justify-content-center'
                        href={`${getGlobal(
                          'domain'
                        )}${ClientRoutesEnum.CoachPage.replace(
                          ':siteName',
                          data.siteName
                        )}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <PreviewIcon />
                      </a>
                    ) : undefined
                  }
                />
              </div>
            )}
          </div>
          <div className='d-flex justify-content-end mt-4'>
            <Button
              className='button__account-save'
              data-testid='personal-data-save-btn'
            >
              Save
            </Button>
          </div>
        </div>
      </form>
      <PreventUnsavedChangesModal
        title='Edit profile'
        onSave={handleSubmit}
        disableConfirmBtn={isSubmitting}
        initialData={JSON.stringify(initialData)}
        currentData={JSON.stringify(data)}
      />
      {withSiteName && (
        <ConfirmModal
          isOpened={isConfirmChangeSiteNameOpened}
          close={closeConfirmChangeSiteNameModal}
          title='Page name editing'
          text="After this change, all old URLs to appointment blocks, events, group appointments with the old page name won't work. Are you sure you want to change your current page name to new?"
          confirmBtnText='Update'
          confirmCallback={saveForm}
          disableConfirmBtn={isSubmitting}
        />
      )}
    </div>
  );
};

export default PersonalDataForm;
