import React from 'react';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import './PromptModal.styles.scss';

interface Props {
  close: () => void;
  isOpened: boolean;
  title: string;
  text: string | JSX.Element;
  btnText?: string;
}

const PromptModal: React.FC<Props> = ({
  close,
  isOpened,
  title,
  text,
  btnText = 'Okay, I got it'
}) => {
  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{
        width: 640,
        padding: 24
      }}
    >
      <div className='PromptModal'>
        <div className='PromptModal__title'>{title}</div>
        <div className='PromptModal__text'>{text}</div>
        <Button type='button' handleClick={close} className='PromptModal_btn'>
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};

export default PromptModal;
