import React, { useContext, useRef } from 'react';
import './Table.styles.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { Link } from 'react-router-dom';
import { getOffsetTop, sortArrayBy } from '../../core/helpers';
import cn from 'classnames';
import ArrowDown from '../../icons/arrow_down_sm.svg';
import { highlightMatches } from '../../core/helpers';
import { SortOrder } from '../../core/enums';
import { Pagination } from '../Pagination/Pagination';
import { AppScrollbarsContext } from '../../App';
import { ListItemProps } from '../Dropdown/Dropdown';

export enum TableColumnType {
  Actions = 0,
  String = 1,
  Link = 2,
  JSX = 3
}

export interface TableColumn<T> {
  field: keyof T;
  title: string;
  type: TableColumnType;
  sortable?: boolean;
  formatter?: (
    v: any,
    searchString?: string
  ) => string | number | null | JSX.Element;
  minWidth?: number;
  maxWidth?: string;
  searchable?: boolean;
}

export interface TableAction<T> {
  title: string;
  callback: (data: T) => void;
  icon?: JSX.Element;
  isAvailable?: (data: T) => boolean;
}

export interface Props<T> {
  data: T[];
  columns: Array<TableColumn<T>>;
  actions?: Array<TableAction<T>>;
  baseUrl?: string;
  idField?: keyof T;
  className?: string;
  rowHeight?: number;
  headerHeight?: number;
  isShownMobileRenderer?: boolean;
  MobileRenderer?: React.FC<{ item: T; actions: ListItemProps[] }>;
  onRowClick?: (item: T) => (e?: React.MouseEvent) => void;
  onSort?: (sortBy: keyof T, sortOrder: SortOrder) => () => void;
  searchText?: string;
  withPaging?: boolean;
  pageSize?: number;
  scrollOffsetTop?: number;
  pageNumber?: number;
  onPageChange: (page: number) => void;
  totalCount?: number;
  sort?: {
    sortBy: keyof T;
    sortOrder: SortOrder;
  };
  clientSorting?: boolean;
}

const Table: <T>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  data,
  columns,
  actions = [],
  baseUrl = '',
  idField,
  className = '',
  rowHeight = 48,
  headerHeight = rowHeight,
  isShownMobileRenderer = false,
  MobileRenderer,
  pageNumber,
  onRowClick,
  onSort,
  onPageChange,
  searchText = '',
  withPaging = false,
  pageSize = 20,
  scrollOffsetTop = 90,
  totalCount,
  sort,
  clientSorting = false
}) => {
  const scroll = useContext(AppScrollbarsContext);
  const tableRef = useRef<HTMLDivElement>();

  const gridTemplateColumns =
    columns
      .map((c) => `minmax(${c.minWidth || 64}px, ${c.maxWidth || '1fr'})`)
      .join(' ') + (actions.length ? ' 50px' : '');

  const renderTableHead = () => {
    return [
      ...columns.map((col) => {
        return (
          <div
            className={cn(
              `Table__cell Table__header-cell Table__cell--${col.type}`,
              {
                'Table__header-cell--sortable': col.sortable,
                'Table__header-cell--sorted': sort?.sortBy === col.field
              }
            )}
            key={col.field as string}
            onClick={
              col.sortable && onSort
                ? onSort(
                    col.field,
                    col.field !== sort?.sortBy
                      ? SortOrder.Desc
                      : sort?.sortOrder === SortOrder.Asc
                      ? SortOrder.Desc
                      : SortOrder.Asc
                  )
                : undefined
            }
          >
            {col.title}
            {col.sortable && (
              <span
                className={cn('Table__arrow ml-1', {
                  Table__arrow_up:
                    sort?.sortBy === col.field &&
                    sort?.sortOrder === SortOrder.Asc
                })}
              >
                <ArrowDown />
              </span>
            )}
          </div>
        );
      }),
      actions.length ? (
        <div
          className='Table__cell Table__header-cell Table__cell--0'
          key='0'
        ></div>
      ) : null
    ];
  };

  const handleActionsCellClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handlePageChange = (page: number) => {
    const top = getOffsetTop(tableRef.current, scroll.container);
    scroll.scrollTop(top - (scrollOffsetTop || 0));
    onPageChange(page);
  };

  const renderTableRows = () => {
    return (clientSorting
      ? [...data].sort(
          sortArrayBy(sort?.sortBy as any, sort?.sortOrder || SortOrder.Desc)
        )
      : data
    ).map((row, index) => {
      const availableActions = actions
        .filter((a) => (a.isAvailable ? a.isAvailable(row) : true))
        .map((act) => ({
          name: act.title,
          callback: () => act.callback(row),
          icon: act.icon ? (
            <div className='Table__icon'>{act.icon}</div>
          ) : undefined
        }));
      if (isShownMobileRenderer && MobileRenderer) {
        return (
          <MobileRenderer
            item={row}
            key={(row[idField] as unknown) as string | number}
            actions={availableActions}
          />
        );
      }

      return (
        <div
          className='Table__row'
          key={index}
          style={{
            gridTemplateColumns,
            cursor: onRowClick ? 'pointer' : undefined
          }}
          onClick={onRowClick ? onRowClick(row) : undefined}
        >
          {columns.map((col) => {
            const searchString = col.searchable ? searchText : '';
            const value = col.formatter ? (
              col.formatter(
                col.type === TableColumnType.JSX
                  ? row
                  : (row[col.field] as any),
                searchString
              )
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(String(row[col.field]), searchString)
                }}
              />
            );

            return (
              <Cell
                key={col.field as string}
                colType={col.type}
                name={col.field.toString()}
              >
                {(() => {
                  switch (col.type) {
                    case TableColumnType.JSX:
                      return value;
                    case TableColumnType.String:
                      return <span className='Table__cell-text'>{value}</span>;
                    case TableColumnType.Link:
                      return (
                        <Link
                          to={baseUrl.replace(
                            ':' + idField,
                            String(row[idField])
                          )}
                          className='Table__cell-text Table__cell-link'
                        >
                          {value}
                        </Link>
                      );
                  }
                })()}
              </Cell>
            );
          })}
          {actions.length ? (
            <Cell
              key='actions'
              name='actions'
              colType={TableColumnType.Actions}
              onClick={handleActionsCellClick}
            >
              {!!availableActions.length && (
                <div className='Table__cell-options'>
                  <DropdownMenu
                    list={availableActions}
                    className='DropdownMenu--table'
                    withPortal={true}
                  />
                </div>
              )}
            </Cell>
          ) : null}
        </div>
      );
    });
  };

  const rows = renderTableRows();

  const tableHeight =
    rows.length * rowHeight +
    (isShownMobileRenderer && MobileRenderer ? 0 : headerHeight) +
    1;

  return (
    <>
      <div
        className={`Table ${className}`}
        style={{ height: !isShownMobileRenderer ? tableHeight : undefined }}
        ref={tableRef}
      >
        {!isShownMobileRenderer ? (
          <Scrollbars>
            <div className='Table__inner'>
              <div className='Table__header' style={{ gridTemplateColumns }}>
                {renderTableHead()}
              </div>

              <div className='Table__body'>{rows}</div>
            </div>
          </Scrollbars>
        ) : (
          rows
        )}
      </div>
      {withPaging && (
        <Pagination
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalCount={totalCount}
          onPageGhange={handlePageChange}
          className='py-3'
        />
      )}
    </>
  );
};

const Cell: React.FC<{
  colType: TableColumnType;
  name: string;
  width?: number;
  onClick?: (e?: React.MouseEvent) => void;
}> = ({ children, colType = '', name, onClick }) => {
  const classList = new Set(['Table__cell']);
  classList.add(`Table__cell--${name}`);
  if (colType !== undefined) {
    classList.add(`Table__cell--${colType}`);
  }
  return (
    <div className={Array.from(classList).join(' ')} onClick={onClick}>
      {children}
    </div>
  );
};

export default Table;
