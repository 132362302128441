import React from 'react';
import { NewTypes } from './AddNewModal.constants';
import CreateNewItem from '../../CreateNewItem/CreateNewItem';
import Modal from '../../Modal/Modal';
import './AddNewModal.styles.scss';
import { ModalAction } from './AddNewModal.constants';

interface Props {
  close: () => void;
  isOpened: boolean;
  onClick: (type: ModalAction) => void;
}

const AddNewModal: React.FC<Props> = ({ close, isOpened, onClick }) => {
  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 1112 }}>
      <div className='AddNewModal'>
        <h3 className='AddNewModal__title'>Add new</h3>
        <p className='AddNewModal__subTitle'>Choose below</p>
        {NewTypes.map((item) => (
          <div key={item.title} onClick={() => onClick(item.action)}>
            <CreateNewItem
              item={item}
              className='NewType_AddNewModal'
              imgBlockClassname='NewType_AddNewModal_image'
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AddNewModal;
