import React, { useEffect, useState } from 'react';
import Modal from '../../Modal/Modal';
import { width_options, subTitles } from './RescheduleAppointment.helpers';
import Loader from '../../Loader/Loader';
import ModalHeader from '../../ModalHeader/ModalHeader';
import StepsVisualizer from '../../StepsVisualizer/StepsVisualizer';
import { Appointment, AppointmentBlock } from '../../../core/types';
import InfoBlock from '../../InfoBlock/InfoBlock';
import AvailableDateTimePicker from '../../AvailableDateTimePicker/AvailableDateTimePicker';
import SummaryStep from '../../SummaryStep/SummaryStep';
import {
  getAppointmentBlockAvailableSlots,
  updateAppointment
} from '../../../core/api';
import { observer } from 'mobx-react';
import { getMilliseconds } from '../../../core/helpers';
import { DEFAULT_TIME_SLOT_INTERVAL } from '../../../core/defaults';
import '../AppointmentModal/AppointmentModal.styles.scss';
import Button from '../../Button/Button';
import { useStores } from '../../../hooks';
import Warning from '../../Warning/Warning';
import { NavLink } from 'react-router-dom';
import { CoachRoutesEnum, ScheduleItemType } from '../../../core/enums';

interface iLocalData {
  timeStampMs: number;
  timeMs: number;
}

function getDefaultLocalData(): iLocalData {
  return {
    timeStampMs: 0,
    timeMs: -1
  };
}

interface iProps {
  isOpened: boolean;
  close: () => void;
  rescheduled?: () => void;
  data: Appointment;
  appointmentBlockData: AppointmentBlock;
}

const RescheduleAppointment: React.FC<iProps> = ({
  isOpened,
  close,
  rescheduled,
  data,
  appointmentBlockData
}) => {
  const {
    rootStore: { userStore, incrementChangesCounter }
  } = useStores();
  const [localData, setLocalData] = useState<iLocalData>(getDefaultLocalData());
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickNext = () => setCurrentStep(currentStep + 1);

  const handleChangeField = (field: string) => (value: any) => {
    setLocalData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isOpened) {
      setLocalData({
        timeStampMs: data.timestampUtc,
        timeMs: data.timeMs
      });
      setCurrentStep(1);
      setIsLoading(false);

      // to resize window due to TimeSlots are load
      window.dispatchEvent(new Event('resize'));
    } else {
      timeout = setTimeout(() => {
        setCurrentStep(0);
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isOpened]);

  const update = () => {
    updateAppointment({
      ...data,
      timestampUtc: localData.timeStampMs,
      timeMs: localData.timeMs
    }).then(() => {
      setIsLoading(false);
      incrementChangesCounter();
      handleClickNext();
      if (rescheduled) {
        rescheduled();
      }
    });
  };

  const handleSave = () => {
    setIsLoading(true);
    update();
  };

  const getAvailableSlots = async (startMs: number, endMs: number) => {
    const res = await getAppointmentBlockAvailableSlots({
      appointmentTypeId: data.appointmentTypeId!,
      durationMs: getMilliseconds(
        appointmentBlockData.durationMinutes,
        'minutes'
      ),
      fromTimeStampUtc: startMs,
      toTimeStampUtc: endMs,
      intervalMs: DEFAULT_TIME_SLOT_INTERVAL,
      timeZone: userStore.timeZone
    });

    const selectedTime = data.timeMs + data.timestampUtc;
    const timeSlots = res;
    if (selectedTime >= startMs && selectedTime < endMs) {
      timeSlots.push(selectedTime);
    }
    return timeSlots.sort((a, b) => a - b);
  };

  const steps = [
    null,
    <div
      className={`AppointmentModal__step AppointmentModal__step--choose d-flex flex-column`}
    >
      {!data.location && (
        <Warning className='mb-3 mt-n3'>
          This appointment doesn't have any location set up. Please, add the
          location to let your clients know where your appointment will happen
          &nbsp;
          <NavLink
            className='link'
            to={CoachRoutesEnum.AppointmentBlockDetails.replace(
              ':blockId',
              appointmentBlockData.id + ''
            )}
          >
            here
          </NavLink>
          .
        </Warning>
      )}
      <div className='AppointmentModal__columns AppointmentModal__columns--reschedule'>
        <InfoBlock
          data={{
            id: appointmentBlockData.id,
            type: ScheduleItemType.Appointment,
            userName: `${userStore.firstName} ${userStore.lastName}`,
            name: appointmentBlockData.name,
            membersCountLimitString:
              appointmentBlockData.membersCountLimit === 1
                ? 'One-on-One'
                : 'Group',
            durationMinutes: appointmentBlockData!.durationMinutes,
            price: data.price,
            timeZone: userStore.timeZone,
            showCoachAvatar: false,
            description: appointmentBlockData!.description,
            remindBefore: data.remindBefore,
            isRemindBeforeDisabled: true,
            imageId: appointmentBlockData.thumbnailImageId
          }}
          className='InfoBlock--RescheduleAppointment'
        />
        <div className='MoreInvitesForEventModal__separator d-lg-none'></div>
        <div className='flex-grow-1'>
          <AvailableDateTimePicker
            date={localData.timeStampMs}
            timeMs={localData.timeMs}
            onChangeTime={handleChangeField('timeMs')}
            onChangeDate={handleChangeField('timeStampMs')}
            getAvailability={getAvailableSlots}
          />
        </div>
      </div>
      <Button
        handleClick={handleSave}
        className='align-self-sm-end mt-3 ml-0 ml-sm-2'
        disabled={localData.timeMs < 0}
      >
        Confirm
      </Button>
    </div>,
    <SummaryStep
      greetingMessage='Congratulations! The appointment is scheduled.'
      link={appointmentBlockData.location}
    />
  ];

  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{
        width: width_options[currentStep]
      }}
    >
      {isLoading && <Loader />}
      <div className='AppointmentModal'>
        <ModalHeader>
          <h2>Reschedule Appointment</h2>
          <h3>{subTitles[currentStep]}</h3>
        </ModalHeader>
        <StepsVisualizer
          currentStep={currentStep < 2 ? currentStep : currentStep + 1}
          stepsTotal={2}
        />
        {steps[currentStep]}
      </div>
    </Modal>
  );
};

export default observer(RescheduleAppointment);
