import React from 'react';
import { TableColumn, TableColumnType } from '../../components/Table/Table';
import { CoachContactItem, iOption } from '../../core/types';
import AvatarIcon from '../../icons/avatar.svg';
import { getPublicFile } from '../../core/api';
import { highlightMatches } from '../../core/helpers';
import TextEllipse from '../../components/TextEllipse';

export const periods: iOption[] = [
  {
    id: '1',
    value: 0,
    name: 'Not set'
  },
  {
    id: '2',
    value: 30,
    name: '30 days'
  },
  {
    id: '3',
    value: 90,
    name: '90 days'
  },
  {
    id: '4',
    value: 180,
    name: '180 days'
  },
  {
    id: '5',
    value: 365,
    name: '1 year'
  },
  {
    id: '6',
    value: Infinity,
    name: 'All'
  }
];

export const columns: TableColumn<CoachContactItem>[] = [
  {
    field: 'fullName',
    title: 'Name',
    type: TableColumnType.JSX,
    searchable: true,
    formatter: (client: CoachContactItem, searchString?: string) => {
      return (
        <div className='d-flex align-items-center CoachClients__cell'>
          <div className='CoachClients__avatar CoachClients__avatar--sm flex-shrink-0 mr-3'>
            {client?.imageId ? (
              <img src={getPublicFile(client.imageId)} alt='avatar' />
            ) : (
              <AvatarIcon />
            )}
          </div>
          <span className='CoachClients__ellipsis'>
            <TextEllipse text={`${client.fullName}`}>
              <span
                className='CoachClients__name'
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    String(`${client.fullName}`),
                    searchString
                  )
                }}
              />
            </TextEllipse>
          </span>
        </div>
      );
    },
    minWidth: 200,
    maxWidth: '3fr'
  },
  {
    field: 'email',
    title: 'Email',
    type: TableColumnType.String,
    searchable: true,
    minWidth: 180,
    maxWidth: '4fr'
  },
  {
    field: 'isGuest',
    title: 'Type',
    type: TableColumnType.String,
    formatter: (isGuest: boolean) => (isGuest ? 'Prospect' : 'Client'),
    minWidth: 90,
    maxWidth: '1fr'
  },
  {
    field: 'formattedPhoneNumber',
    title: 'Phone number',
    type: TableColumnType.String,
    searchable: true,
    minWidth: 168,
    maxWidth: 'max-content'
  },
  {
    field: 'address',
    title: 'Location',
    type: TableColumnType.String,
    searchable: true,
    minWidth: 168,
    maxWidth: '4fr'
  }
];
