import { action, observable } from 'mobx';
import { getContacts } from '../core/api';
import { CoachContactsViewModel } from '../core/backend/api';
import { CoachContactItem } from '../core/types';

export interface iContactsStore {
  contacts: CoachContactsViewModel[];
  getCoachContacts: () => void;
}

export default class ContactsStore implements iContactsStore {
  @observable contacts: CoachContactItem[] = [];
  private contactsMap: Map<string, CoachContactItem> = new Map();

  @action getCoachContacts = async () => {
    const contacts = await getContacts();
    this.contacts = contacts;
    this.contactsMap = new Map();
    contacts.forEach((c) => {
      this.contactsMap.set(c.email.toLowerCase(), c);
    });
  };

  getByEmail(email: string): CoachContactItem {
    return this.contactsMap.get(email.toLowerCase());
  }
}
