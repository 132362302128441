import React, { useState } from 'react';
import './PersonalDetails.styles.scss';
import FormInput from '../FormInput/FormInput';
import { ClientInfoViewModel } from '../../core/backend/api';
import { isTheInputValid, getTheInputErrors } from './PersonalDetails.helpers';
import Button from '../Button/Button';
import ClientSignInModal from '../../components/Modals/ClientSignInModal/ClientSignInModal';
import ClientForgotPasswordModal from '../../components/Modals/ClientForgotPasswordModal/ClientForgotPasswordModal';
import { UserStore } from '../../core/types';
import CustomizedPhoneInput from '../CustomizedPhoneInput';

interface iProps {
  data: ClientInfoViewModel;
  handleChangeField: (key: string) => (val: any) => void;
  isValidationShown: boolean;
  emailErrorMessage?: string;
  userStore?: UserStore;
  saveDateTimeDataBeforeLogIn?: () => void;
}

const PersonalDetails: React.FC<iProps> = ({
  data,
  handleChangeField,
  isValidationShown,
  emailErrorMessage,
  userStore,
  saveDateTimeDataBeforeLogIn
}) => {
  const [isSignInPopupOpened, setIsSignInPopupOpened] = useState<boolean>(
    false
  );
  const [isForgotPopupOpened, setIsForgotPopupOpened] = useState<boolean>(
    false
  );

  const handleClickLogIn = () => {
    setIsSignInPopupOpened(true);
  };

  return (
    <div className='PersonalDetails'>
      <div className='PersonalDetails__row'>
        <FormInput
          handleChange={handleChangeField('firstName')}
          value={data.firstName}
          isInvalid={isValidationShown && !isTheInputValid('firstName', data)}
          errorMessage={getTheInputErrors('firstName', data)[0]}
          label='First name'
          placeholder='First name'
          className='FormInput--right-margin'
          autoFocus
        />
        <FormInput
          handleChange={handleChangeField('lastName')}
          value={data.lastName}
          isInvalid={isValidationShown && !isTheInputValid('lastName', data)}
          errorMessage={getTheInputErrors('lastName', data)[0]}
          label='Last name'
          placeholder='Last name'
        />
      </div>
      <div className='PersonalDetails__row'>
        <FormInput
          handleChange={handleChangeField('email')}
          value={data.email}
          isInvalid={
            (isValidationShown && !isTheInputValid('email', data)) ||
            !!emailErrorMessage
          }
          errorMessage={
            getTheInputErrors('email', data)[0] || emailErrorMessage
          }
          label='Email'
          placeholder='Email'
          className='FormInput--right-margin'
        />
        <FormInput
          handleChange={handleChangeField('phoneNumber')}
          value={data.phoneNumber}
          isInvalid={isValidationShown && !isTheInputValid('phoneNumber', data)}
          errorMessage={getTheInputErrors('phoneNumber', data)[0]}
          label='Phone number'
          inputClassName='FormInput__input--phoneNumber'
        >
          <CustomizedPhoneInput
            value={data.phoneNumber}
            onChange={(value) => handleChangeField('phoneNumber')(value)}
            inputClass={
              isValidationShown && !isTheInputValid('phoneNumber', data)
                ? 'PhoneInputInput--error'
                : ''
            }
          />
        </FormInput>
      </div>
      <div className='PersonalDetails__text'>Already have an acount?</div>
      <Button
        handleClick={handleClickLogIn}
        className='CZ__button button__naked button__PersonalDetails'
      >
        Sign in
      </Button>
      {!userStore.isSignedIn && (
        <ClientSignInModal
          isOpened={isSignInPopupOpened}
          close={() => setIsSignInPopupOpened(false)}
          showForgot={() => setIsForgotPopupOpened(true)}
          setEventParams={saveDateTimeDataBeforeLogIn}
        />
      )}
      <ClientForgotPasswordModal
        isOpened={isForgotPopupOpened}
        close={() => setIsForgotPopupOpened(false)}
      />
    </div>
  );
};

export default PersonalDetails;
