import React, { useEffect, useState, useRef, useMemo } from 'react';
import './InviteClients.styles.scss';
import FormInput from '../FormInput/FormInput';
import Button from '../Button/Button';
import { CoachContactsExtendedViewModel } from '../../core/backend/api';
import AvatarIcon from '../../icons/avatar-small.svg';
import Scrollbars from 'react-custom-scrollbars';
import { highlightMatches } from '../../core/helpers';
import { isEmail } from '../../core/formValidation';
import { useStores } from '../../hooks';
import RowWithDelete from '../RowWithDelete/RowWithDelete';
import { getPublicFile } from '../../core/api';
import { observer } from 'mobx-react';
import TextEllipse from '../TextEllipse';
import CollapsibleRowList from '../CollapsibleRowList/CollapsibleRowList';
import {
  StripeRefundStatusEnum,
  ClientEventStatusEnum
} from '../../core/enums';

interface iProps {
  label?: string;
  className?: string;
  initialInvites?: string[];
  invites: string[];
  initialMembers?: CoachContactsExtendedViewModel[];
  membersToRemove?: string[];
  setInvites: (invites: string[]) => void;
  setMembersToRemove?: (ids: string[]) => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  autoFocus?: boolean;
  tabIndex?: number;
  placeholder?: string;
}

const InviteClients: React.FC<iProps> = ({
  initialInvites,
  invites,
  setInvites,
  initialMembers,
  membersToRemove,
  setMembersToRemove = () => {},
  isDisabled,
  isInvalid,
  autoFocus,
  className = '',
  errorMessage = '',
  tabIndex,
  label = 'Email',
  placeholder = ''
}) => {
  const {
    rootStore: { contactsStore }
  } = useStores();
  const contactsRef = useRef<HTMLDivElement>();
  const [inputValue, setInputValue] = useState<string>('');
  const [innerErrorMessage, setInnerErrorMessage] = useState<string>('');
  const trimmedInputValue = inputValue.trim();
  const [contactsMap, setContactsMap] = useState<
    Record<string, CoachContactsExtendedViewModel>
  >({});

  const members = useMemo(
    () => initialMembers.filter((i) => !membersToRemove.includes(i.clientId)),
    [initialMembers, membersToRemove]
  );

  const membersEmails =
    initialMembers
      ?.filter((m) => m.clientStatus !== ClientEventStatusEnum.Canceled)
      .map((m) => m.email.toLowerCase()) || [];

  useEffect(() => {
    setContactsMap(
      contactsStore.contacts.reduce(
        (acc, contact) => ({ ...acc, [contact.email.toLowerCase()]: contact }),
        {}
      )
    );
  }, [contactsStore.contacts]);

  const removeEmail = (email: string) => () => {
    const member = members.find(
      (m) =>
        m.email.toLowerCase() === email.toLowerCase() &&
        m.clientStatus !== ClientEventStatusEnum.Canceled
    );
    if (!!member) {
      setMembersToRemove([...membersToRemove, member.clientId]);
    }
    setInvites(
      invites.filter((invite) => invite.toLowerCase() !== email.toLowerCase())
    );
  };

  const addEmail = (email: string) => {
    if (trimmedInputValue !== '') {
      if (invites.some((i) => i.toLowerCase() === email.toLowerCase())) {
        setInnerErrorMessage('You have already invited this client');
      } else if (membersEmails.includes(email.toLowerCase())) {
        setInnerErrorMessage('The client has already accepted invitation');
      } else if (!isEmail(email)) {
        setInnerErrorMessage('Please input correct email');
      } else {
        const initialMember = initialMembers?.find(
          (m) => m.email.toLowerCase() === email.toLowerCase()
        );
        if (!!initialMember) {
          setMembersToRemove(
            membersToRemove.filter((m) => m !== initialMember.clientId)
          );
        }
        setInvites([...invites, email.toLowerCase()]);
        setInputValue('');
      }
    }
  };

  const handleAdd = () => {
    addEmail(trimmedInputValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleAdd();
    }
  };

  const matches = contactsStore.contacts.filter(
    (contact) =>
      !invites.includes(contact.email.trim()) &&
      !membersEmails?.includes(contact.email.trim()) &&
      trimmedInputValue !== '' &&
      (contact.email.toLowerCase().indexOf(trimmedInputValue.toLowerCase()) >=
        0 ||
        contact.firstName
          .toLowerCase()
          .indexOf(trimmedInputValue.toLowerCase()) >= 0 ||
        contact.lastName
          .toLowerCase()
          .indexOf(trimmedInputValue.toLowerCase()) >= 0)
  );

  const inviteMap = (email: string) => {
    const possibleContact = contactsMap[email];

    const title = possibleContact
      ? `${possibleContact.firstName} ${possibleContact.lastName}`
      : email;
    const isBeforeInvited = initialInvites?.includes(email);
    const isJustInvited =
      !initialInvites?.includes(email) && invites.includes(email);
    const isInvited = invites.includes(email);
    const isCanceled = initialMembers?.some(
      (m) =>
        m.email.toLowerCase() === email.toLowerCase() &&
        m.clientStatus === ClientEventStatusEnum.Canceled
    );
    const isConfirmed = initialMembers?.some(
      (m) =>
        m.email.toLowerCase() === email.toLowerCase() &&
        m.clientStatus === ClientEventStatusEnum.Active
    );
    const isRefunded = initialMembers?.some(
      (m) =>
        m.email.toLowerCase() === email.toLowerCase() &&
        m.refundStatus === StripeRefundStatusEnum.Succeeded
    );
    const invited = !isConfirmed && isBeforeInvited && isInvited;
    const deletable =
      !isDisabled &&
      (!isCanceled || (isBeforeInvited && isInvited) || isJustInvited);

    return (
      <RowWithDelete
        onDelete={removeEmail(email)}
        className='RowWithDelete_margin-top_8'
        isDeletable={deletable}
        key={email}
        item={
          <div className='InviteClients__item w-100'>
            <TextEllipse text={title} className='InviteClients__item-name mb-1'>
              <span>{title}</span>
            </TextEllipse>
            <div className='d-flex'>
              {invited && (
                <div className='InviteClients__badge mr-2'>invited</div>
              )}
              {isConfirmed && (
                <div className='InviteClients__badge InviteClients__badge--confirmed mr-2'>
                  confirmed
                </div>
              )}
              {isCanceled && !isInvited && (
                <div className='InviteClients__badge InviteClients__badge--canceled mr-2'>
                  registration canceled
                </div>
              )}
              {isRefunded && !isInvited && (
                <div className='InviteClients__badge InviteClients__badge--refunded mr-2'>
                  refunded
                </div>
              )}
            </div>
          </div>
        }
      />
    );
  };

  const getInvitesAndMembersList = [
    ...members
      .filter((m) => m.clientStatus !== ClientEventStatusEnum.Canceled)
      .map((m) => m.email.toLowerCase()),
    ...invites.filter((invite) => !membersEmails.includes(invite)),
    ...members
      .filter(
        (m) =>
          m.clientStatus === ClientEventStatusEnum.Canceled &&
          !invites.includes(m.email.toLowerCase())
      )
      .map((m) => m.email.toLowerCase())
  ].map(inviteMap);

  const hasMatches = !!matches.length;

  useEffect(() => {
    if (hasMatches) contactsRef.current?.scrollIntoView();
  }, [hasMatches]);

  return (
    <div className={`InviteClients ${className}`}>
      <div className='InviteClients__row'>
        <div className='flex-grow-1'>
          <FormInput
            tabIndex={tabIndex}
            key={JSON.stringify(invites)}
            autoFocus={autoFocus}
            className='FormInput--invite'
            value={inputValue}
            handleChange={(value: string) => {
              setInputValue(value.toLowerCase());
              setInnerErrorMessage('');
            }}
            onKeyDown={handleKeyDown}
            label={label}
            isInvalid={isInvalid || !!innerErrorMessage}
            errorMessage={isInvalid ? errorMessage : innerErrorMessage}
            isDisabled={isDisabled}
            placeholder={placeholder}
          />
          {!innerErrorMessage && (
            <div className='InviteClients__hint'>Add one email at a time</div>
          )}
        </div>
        <Button
          className='button__inversed button__add-client button_InviteClients flex-shrink-0'
          type='button'
          handleClick={handleAdd}
          disabled={isDisabled}
        >
          Add
        </Button>
        {matches.length > 0 && (
          <div className='Contacts' ref={contactsRef}>
            <Scrollbars>
              {matches.map((contact) => (
                <div
                  className='Contact'
                  key={contact.clientId!}
                  onClick={() => addEmail(contact.email)}
                >
                  <div className='Contact__image'>
                    {contact.imageId ? (
                      <img src={getPublicFile(contact.imageId)} alt='avatar' />
                    ) : (
                      <AvatarIcon />
                    )}
                  </div>
                  <div className='Contact__content'>
                    <div
                      className='Contact__name'
                      dangerouslySetInnerHTML={{
                        __html: `${highlightMatches(
                          contact.firstName,
                          trimmedInputValue
                        )} ${highlightMatches(
                          contact.lastName,
                          trimmedInputValue
                        )}`
                      }}
                    />
                    <div
                      className='Contact__email'
                      dangerouslySetInnerHTML={{
                        __html: highlightMatches(
                          contact.email,
                          trimmedInputValue
                        )
                      }}
                    />
                  </div>
                </div>
              ))}
            </Scrollbars>
          </div>
        )}
      </div>
      {(invites.length > 0 || members.length > 0) && (
        <CollapsibleRowList
          list={getInvitesAndMembersList}
          className='InviteClients__invited'
        />
      )}
    </div>
  );
};
export default observer(InviteClients);
