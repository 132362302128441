import React, { useState, useEffect } from 'react';
import './LinkModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import FormInput from '../../FormInput/FormInput';
import cn from 'classnames';
import AddedLink from './AddedLink';
import {
  editLibraryItem,
  uploadFileLinksCoachLibrary
} from '../../../core/api';
import Loader from '../../Loader/Loader';
import {
  formatVideoLink,
  isFormDataValid,
  LinkModalUrlType,
  getFormErrors
} from './LinkModal.helpers';
import LibraryModal from '../../../containers/LibraryModal/LibraryModal';
import { FileResponseViewModel } from '../../../core/backend/api';
import { FileResponseViewModelWithExt } from '../../../core/types';
import { parseLink } from '../../../pages/CoachLibraryAll/CoachLibraryAll.helpers';

interface iProps {
  isOpened: boolean;
  close: () => void;
  updateFiles: (newFile: FileResponseViewModel[]) => void;
  urlType: LinkModalUrlType;
  onBack: () => void;
  activeLink: FileResponseViewModelWithExt;
}

const initialValues = {
  name: '',
  fileLink: '',
  description: ''
};

const LinkModal: React.FC<iProps> = ({
  isOpened,
  close,
  updateFiles,
  activeLink,
  urlType,
  onBack
}) => {
  const isVideoLink = urlType === 'video';

  const {
    rootStore: { notificationStore }
  } = useStores();
  const [isPending, setIsPending] = useState(false);
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [newLink, setNewLink] = useState<Partial<FileResponseViewModelWithExt>>(
    initialValues
  );
  const [errors, setErrors] = useState(initialValues);
  const [addedLinks, setAddedLinks] = useState<
    Partial<FileResponseViewModelWithExt>[]
  >([]);

  const handleClickAdd = () => {
    if (isFormValid()) {
      setIsValidationShown(false);
      addLink();
    } else {
      setIsValidationShown(true);
    }
  };

  const addLink = () => {
    setAddedLinks((links) => [
      ...links,
      {
        ...newLink,
        id: Date.now()
      }
    ]);
    setNewLink(initialValues);
  };

  const handleAddLinksSubmit = () => {
    if (!addedLinks.length && !newLink.fileLink && !newLink.name) {
      isFormValid();
      return setIsValidationShown(true);
    }

    if (isFormValid(true)) {
      setIsValidationShown(false);
      let links = addedLinks;
      if (newLink.name && newLink.fileLink) {
        links = [
          ...links.map((addedLink) => ({
            ...addedLink,
            fileLink: isVideoLink
              ? formatVideoLink(addedLink.fileLink)
              : addedLink.fileLink
          })),
          {
            name: newLink.name,
            fileLink: isVideoLink
              ? formatVideoLink(newLink.fileLink)
              : newLink.fileLink,
            description: newLink.description
          }
        ];
      }
      setIsPending(true);
      uploadFileLinksCoachLibrary(
        links.map((link) => ({
          Name: link.name,
          Description: link.description,
          FileLink: link.fileLink
        }))
      )
        .then((response) => {
          updateFiles(response);
          notificationStore.addNotification({
            text: response.length > 1 ? 'Links' : 'Link',
            textColored: (response.length > 1 ? 'were' : 'was') + ' added',
            duration: 3000
          });
          close();
        })
        .finally(() => {
          setIsPending(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  const handleEditLinkSubmit = () => {
    if (!activeLink) return;

    if (isFormValid(true)) {
      setIsValidationShown(false);

      setIsPending(true);
      editLibraryItem(newLink.id, {
        name: newLink.name,
        description: newLink.description || null,
        fileLink: isVideoLink
          ? formatVideoLink(newLink.fileLink)
          : newLink.fileLink
      })
        .then((response) => {
          updateFiles([response]);
          notificationStore.addNotification({
            text: 'Link',
            textColored: 'was edited',
            duration: 3000
          });
          close();
        })
        .finally(() => {
          setIsPending(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  const isFormValid = (isSubmitValidation: boolean = false) => {
    setErrors(
      getFormErrors(
        { ...newLink, fileLink: parseLink(newLink.fileLink) },
        isVideoLink
      )
    );
    return isFormDataValid(
      { ...newLink, fileLink: parseLink(newLink.fileLink) },
      {
        isSubmitValidation,
        isVideoLink
      }
    );
  };

  const handleBack = () => {
    close();
    onBack();
  };

  const renderTitle = () => {
    if (!activeLink) {
      return isVideoLink ? 'Add Video' : 'Add Link';
    }
    return isVideoLink ? 'Edit Video' : 'Edit Link';
  };

  const handleChangeField = (key: 'name' | 'fileLink' | 'description') => (
    value: string
  ) => {
    setNewLink({
      ...newLink,
      [key]: value
    });
  };

  useEffect(() => {
    if (isOpened) {
      setNewLink(activeLink || initialValues);
      setErrors(initialValues);
      setAddedLinks([]);
    }
  }, [activeLink, isOpened]);

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 506 }}>
      <div className='LinkModal'>
        {isPending && <Loader />}
        <LibraryModal onClickBack={activeLink ? undefined : handleBack}>
          <div className='LinkModal__title'>{renderTitle()}</div>
          {!activeLink && (
            <button
              className={cn('LinkModal__add-button', {
                'LinkModal__add-button_top-space': addedLinks.length === 0
              })}
              onClick={handleClickAdd}
            >
              + Add more link
            </button>
          )}
          {!activeLink &&
            addedLinks.map((link) => (
              <AddedLink
                key={link.id}
                link={link}
                onDelete={() => {
                  setAddedLinks((prevlinks) =>
                    prevlinks.filter((prevLink) => prevLink.id !== link.id)
                  );
                }}
              />
            ))}
          <FormInput
            autoFocus
            inputClassName='FormInput__input'
            name='linkNameInLib'
            label={isVideoLink ? 'Video Title' : 'Link name'}
            value={newLink.name}
            handleChange={handleChangeField('name')}
            placeholder='Enter name'
            isInvalid={errors.name && isValidationShown}
            errorMessage={errors.name}
          />
          {isVideoLink && (
            <FormInput
              inputClassName='FormInput__input'
              name='linkNameInLib'
              label='Video Description'
              isInvalid={errors.description && isValidationShown}
              errorMessage={errors.description}
              value=''
              placeholder='Explain what do you like and what you don’t'
            >
              <textarea
                className='Scrollbar'
                rows={3}
                value={newLink.description || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChangeField('description')(e.target.value);
                }}
                placeholder='Enter description'
                style={{ resize: 'none' }}
              />
            </FormInput>
          )}
          <FormInput
            inputClassName='FormInput__input'
            name='linkInLib'
            label={isVideoLink ? 'Video Embeded' : 'Link field'}
            value={parseLink(newLink.fileLink)}
            handleChange={handleChangeField('fileLink')}
            placeholder={
              isVideoLink
                ? 'Youtube or Vimeo Link'
                : 'https://docs.google.com/document'
            }
            isInvalid={errors.fileLink && isValidationShown}
            errorMessage={errors.fileLink}
          />
          <div className='LinkModal__hint'>
            Please copy and paste the link into the field
          </div>
          <Button
            className='button__inversed button__new-link button__full-width'
            handleClick={
              activeLink ? handleEditLinkSubmit : handleAddLinksSubmit
            }
            disabled={isPending}
          >
            {activeLink ? 'Save' : 'Add'}
          </Button>
        </LibraryModal>
      </div>
    </Modal>
  );
};

export default observer(LinkModal);
