import React from 'react';
import './NotificationModal.styles.scss';
import ModalHeader from '../../ModalHeader/ModalHeader';
import Modal from '../../Modal/Modal';

interface iProps {
  close: () => void;
  isOpened: boolean;
  title: string;
  subTitle: string | JSX.Element;
  icon: JSX.Element;
}

const NotificationModal: React.FC<iProps> = ({
  close,
  isOpened,
  title,
  subTitle,
  icon
}) => {
  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{
        width: 648
      }}
    >
      <div className='NotificationModal'>
        <ModalHeader>
          <h2>{title}</h2>
          <h4>{subTitle}</h4>
        </ModalHeader>
        <div className='NotificationModal__icon'>{icon}</div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
