import React, { useEffect, useRef, useState } from 'react';
import FormInput from '../FormInput/FormInput';
import CalendarIcon from '../../icons/calendar.svg';
import DatePicker from '../DatePicker/DatePicker';
import './DayPickerModal.styles.scss';
import moment, { utc } from 'moment';
import Modal from '../Modal/Modal';

interface Props {
  value: number;
  onChange: (value: number) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  tabIndex?: number;
  className?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
}

const DatePickerModal: React.FC<Props> = ({
  value,
  onChange,
  isInvalid,
  isDisabled,
  errorMessage,
  tabIndex,
  className = '',
  disablePast = true,
  disableFuture = false
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [date, setDate] = useState<number>(value);
  const cases = [
    'ArrowDown',
    'ArrowUp',
    'ArrowLeft',
    'ArrowRight',
    'Enter',
    'Escape',
    ' '
  ];

  useEffect(() => {
    if (isOpened) {
      setDate(value);
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('keydown', handleNavigateModal);
    }
    return () => {
      document.removeEventListener('keydown', handleNavigateModal);
    };
  }, [isOpened, date]);

  const handleOpen = (e: any) => {
    if (!isDisabled) {
      setIsOpened(true);
    }
  };

  const handleClose = () => {
    if (!isDisabled) {
      setDate(value);
      setIsOpened(false);
    }
  };

  const handleChange = (dates: number[]) => {
    onChange(dates[0]);
    setIsOpened(false);
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (dropdownRef.current) {
      if (!dropdownRef.current!.contains(event.target as Node)) {
        setIsOpened(false);
      }
    }
  };

  const handleNavigateModal = (e: KeyboardEvent) => {
    e.preventDefault();

    switch (e.key) {
      case 'ArrowRight': {
        setDate((date) => utc(date).add(1, 'd').valueOf());
        break;
      }
      case 'ArrowLeft': {
        setDate((date) => utc(date).add(-1, 'd').valueOf());
        break;
      }
      case 'ArrowUp': {
        setDate((date) => utc(date).add(-7, 'd').valueOf());
        break;
      }
      case 'ArrowDown': {
        setDate((date) => utc(date).add(7, 'd').valueOf());
        break;
      }
      case 'Enter': {
        handleChange([date]);
        break;
      }
      case 'Escape': {
        handleClose();
        break;
      }
      default:
        break;
    }
  };

  const handleNavigateInput = (e: React.KeyboardEvent) => {
    if (!isOpened) {
      if (cases.find((c) => c === e.key)) {
        e.preventDefault();
      }

      switch (e.key) {
        case ' ': {
          handleOpen(e);
          break;
        }
        case 'ArrowUp': {
          onChange(utc(value).add(1, 'd').valueOf());
          break;
        }
        case 'ArrowDown': {
          onChange(utc(value).add(-1, 'd').valueOf());
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <FormInput
      value={''}
      className={`FormInput--no-mt ${className}`}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
    >
      <div className='DayPickerModal' onClick={handleOpen}>
        <div className='DayPickerModal__thumb'>
          <input
            tabIndex={tabIndex}
            onClick={handleOpen}
            onKeyDown={handleNavigateInput}
            type='text'
            name=''
            id=''
            value={moment.utc(value).format('MMMM D, YYYY')}
            readOnly
            className='DayPickerModal__input'
          />

          <div className='DayPickerModal__icon'>
            <CalendarIcon />
          </div>
          <Modal
            close={handleClose}
            isOpened={isOpened}
            className='DayPickerModal__modal'
          >
            <div className='DayPickerModal__drop-down'>
              <DatePicker
                value={[date]}
                onChange={handleChange}
                disablePast={disablePast}
                disableFuture={disableFuture}
              />
            </div>
          </Modal>
        </div>
      </div>
    </FormInput>
  );
};

export default DatePickerModal;
