import React, { useState } from 'react';
import { CoachClientScheduleItem as ItemModel } from '../../core/types';
import Button from '../Button/Button';
import classnames from 'classnames';
import './CoachClientScheduleList.styles.scss';

export interface iItemRendererProps {
  item: ItemModel;
}

interface iProps {
  title: string;
  eventsList: ItemModel[];
  subTitle: string;
  ItemRenderer: React.FC<iItemRendererProps>;
}

const CoachClientScheduleList: React.FC<iProps> = ({
  title,
  eventsList,
  subTitle,
  ItemRenderer
}) => {
  const [showAllevents, setshowAllevents] = useState<boolean>(false);

  const ITEMS_MAX_AMOUNT = 3;

  const renderBtn = () =>
    eventsList.length > ITEMS_MAX_AMOUNT && (
      <Button
        variations={['naked']}
        handleClick={() => setshowAllevents(!showAllevents)}
        className='flex-shrink-0'
      >
        {showAllevents ? 'Collapse' : 'View all'}
      </Button>
    );

  return (
    <>
      <div className='d-flex justify-content-between mb-2 align-items-end'>
        <div>
          <div className='CoachClientScheduleList__title'>{title}</div>
          {!!subTitle.length && (
            <div className='CoachClientScheduleList__subTitle'>{subTitle}</div>
          )}
        </div>
        <div className='CoachClientScheduleList__button ml-3'>
          {renderBtn()}
        </div>
      </div>

      <div className='mb-3'>
        <div>
          {eventsList.slice(0, ITEMS_MAX_AMOUNT).map((item) => (
            <ItemRenderer item={item} key={item.id + item.itemType} />
          ))}
        </div>
        <div
          className={classnames('CoachClientScheduleList__all', {
            'CoachClientScheduleList__all--expanded': showAllevents,
            'CoachClientScheduleList__all--hidden': !showAllevents
          })}
        >
          {eventsList.slice(ITEMS_MAX_AMOUNT).map((item) => (
            <ItemRenderer item={item} key={`${item.id}-${item.itemType}`} />
          ))}
        </div>
      </div>
    </>
  );
};
export default CoachClientScheduleList;
