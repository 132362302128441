import { useEffect, MutableRefObject } from 'react';

export function useOnClickOutside(
  ref: MutableRefObject<any>,
  handler: Function,
  isOpen = true
) {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    if (isOpen) {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      }
    };
  }, [ref, handler, isOpen]);
}
