import React, { ReactNode } from 'react';
import './ClientSchedulePage.styles.scss';
import { ClientRoutesEnum } from '../../core/enums';
import TabsMenu, { iTab } from '../../components/TabsMenu/TabsMenu';
import { getRoutePath } from '../../core/globals';
import PageTitle from '../../components/PageTitle/PageTitle';

interface iProps {
  children: ReactNode;
  title: string;
}

const tabs: iTab[] = [
  {
    name: 'Your calendar',
    link: getRoutePath(ClientRoutesEnum.Calendar)
  },
  {
    name: 'Invites',
    link: getRoutePath(ClientRoutesEnum.Invites)
  },
  {
    name: 'Archive',
    link: getRoutePath(ClientRoutesEnum.Archive)
  }
];

const ClientSchedulePage: React.FC<iProps> = ({ children, title }) => {
  return (
    <div className='ClientSchedulePage p-md-4 p-sm-3 px-2 py-3'>
      <PageTitle title={title} />
      <div className='mb-md-4 mb-3 px-2 px-sm-0'>
        <TabsMenu tabsList={tabs} />
      </div>
      {children}
    </div>
  );
};

export default ClientSchedulePage;
