import React from 'react';
import AccordionButton from '../../components/AccordionButton/AccordionButton';
import CoachDashboardTile from '../../components/CoachDashboardTile/CoachDashboardTile';
import ArrowIcon from '../../icons/arrow_down_right.svg';

import './AlphaHub.styles.scss';
interface Props {
  title: string;
  contentTitle: string;
  text: string;
  date: string;
}

const AlphaHub: React.FC<Props> = ({ title, text, date, contentTitle }) => {
  const content = (
    <div className='AlphaHub__content d-flex flex-column justify-content-between flex-grow-1'>
      <div className='flex-grow-1'>
        <div className='AlphaHub__date'>{date}</div>
        <div className='AlphaHub__title'>{contentTitle}</div>
        <div className='AlphaHub__text'>{text}</div>
      </div>
      <a
        href='https://www.coachpro.com/alpha-portal'
        className='align-self-end d-flex align-items-center AlphaHub__link'
        target='_blank'
        rel='noopener noreferrer'
      >
        Access
        <div className='ml-2'>
          <ArrowIcon />
        </div>
      </a>
    </div>
  );

  return (
    <div className='AlphaHub flex-grow-1 d-flex flex-column'>
      <AccordionButton
        className='AccordionButton--AlphaHub d-sm-none my-0'
        nodeContent={content}
      >
        {title}
      </AccordionButton>
      <CoachDashboardTile
        title={title}
        titleUnderline={true}
        className='d-sm-flex flex-column d-none flex-grow-1'
      >
        {content}
      </CoachDashboardTile>
    </div>
  );
};

export default AlphaHub;
