import React, {
  useState,
  useEffect,
  FormEvent,
  useContext,
  useCallback,
  useRef
} from 'react';
import './CoachMeeting.styles.scss';
import FormCheckbox from '../../components/FormCheckbox/FormCheckbox';
import {
  getDefaultData,
  isFormInputValid,
  getFormInputErrors,
  isFormDataValid,
  reminders
} from './CoachMeeting.helpers';
import FormInput from '../../components/FormInput/FormInput';
import {
  checkPageNameExist,
  getEvent,
  updateEvent,
  createEvent,
  getPublicFile
} from '../../core/api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { CoachEvent } from '../../core/types';
import {
  EventStatus,
  CoachRoutesEnum,
  LocationTypeEnum,
  ClientEventStatusEnum,
  StripeRefundStatusEnum
} from '../../core/enums';
import {
  createLink,
  copyToClipboard,
  getOffsetTop,
  isMobileDevice,
  getServerModelValidation
} from '../../core/helpers';
import WYSIWYGEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import getGlobal from '../../core/globals';
import { observer } from 'mobx-react';
import NoImage from '../../icons/no-image.svg';
import { formatValueAsPrice } from '../../core/formValidation';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import FileUploader from '../../components/FileUploader/FileUploader';
import { AppScrollbarsContext } from '../../App';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import DatePickerModal from '../../components/DayPickerModal/DayPickerModal';
import InviteClients from '../../components/InviteClients/InviteClients';
import TimePickerDropdown from '../../components/TimePickerDropdown/TimePickerDropdown';
import ImageCropper from '../../components/ImageCropper/ImageCropper';
import { useFeatures, useMedia, useStores } from '../../hooks';
import { useCropper } from '../../components/ImageCropper/useCropper';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { CoachContactsExtendedViewModel } from '../../core/backend/api';
import ArrowIcon from '../../icons/arrow-left.svg';
import Location, { iLocation } from '../../components/Location/Location';
import {
  getZoomConnectUrl,
  storeDataBeforeZoomConnect,
  restoreDataAfterZoomConnect,
  storeDataBeforeStripeConnect,
  restoreDataAfterStripeConnect,
  ZOOM_CONNECTED_KEY,
  STRIPE_CONNECTED_KEY
} from '../../core/integrations';
import Warning from '../../components/Warning/Warning';
import PageTitle from '../../components/PageTitle/PageTitle';
import StripeConnectHint from '../../components/StripeConnectHint/StripeConnectHint';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';

let checkLinkTimer: number;

const spotsValueFormatter = (value: string) => {
  return value.replace(/\D/g, '').replace(/^0+/g, '');
};

const MAX_IMAGE_SIZE = 3 * 1024 * 1024;
const MAX_FILE_TYPES = ['png', 'jpeg', 'svg'];

const CoachMeeting = () => {
  const {
    rootStore: { userStore, notificationStore }
  } = useStores();
  const isAfterIntegrationConnectRef = useRef(false);
  const { zoomEnabled } = useFeatures();

  const initialLink = useRef('');
  const initialInvites = useRef<string[]>([]);
  const initialMembers = useRef<CoachContactsExtendedViewModel[]>([]);
  const { meetingId } = useParams<{ meetingId: string }>();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const scroll = useContext(AppScrollbarsContext);
  const [localData, setLocalData] = useState<CoachEvent>(getDefaultData());
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [isPageNameExist, setIsPageNameExist] = useState(false);
  const [startTimeMsFieldIsDirty, setStartTimeMsFieldIsDirty] = useState(false);
  // const [selectedBefore, setSelectedBefore] = useState<string>(buffers[0].id);
  // const [selectedAfter, setSelectedAfter] = useState<string>(buffers[0].id);
  const [reminderTime, setReminderTime] = useState<string>(reminders[4].id);
  // const [hasBuffers, setHasBuffers] = useState<boolean>(false);
  const [initialLocation, setInitialLocation] = useState<iLocation>();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const isInvalidZoomMeeting =
    meetingId &&
    !userStore.isZoomConnected &&
    localData.locationType === LocationTypeEnum.Zoom;

  const isDisabled = localData.coachEventStatus !== EventStatus.Active;
  const isPaid = Number(localData.price) > 0;

  const hasConfirmedMembers = initialMembers.current?.some(
    (m) => m.clientStatus === ClientEventStatusEnum.Active
  );

  const inviteClientsPlaceholder = useMedia(
    ['(min-width: 1400px)', '(min-width: 1080px)'],
    [
      "Start typing client's email to send an invite",
      "Start typing client's email"
    ],
    'Start typing'
  );

  const {
    isStripeConnected = false,
    siteName = '',
    isZoomConnected,
    id: userId
  } = userStore || {};
  const params = new URLSearchParams(search);
  const isAfterZoomConnected = !!params.get(ZOOM_CONNECTED_KEY);
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);

  const handleChangeField = useCallback(
    (field: string) => (value: any) => {
      setLocalData((prevLocalData) => ({
        ...prevLocalData,
        [field]: value
      }));
    },
    []
  );

  const handleChange = useCallback((value: Partial<CoachEvent>) => {
    setLocalData((prevLocalData) => ({
      ...prevLocalData,
      ...value
    }));
  }, []);

  const onChangeCrop = useCallback(
    (croppedAreaString: string) => {
      handleChangeField('croppedArea')(croppedAreaString);
    },
    [handleChangeField]
  );

  const onFileUpload = (fileName: string) => {
    handleChangeField('imageId')(fileName);
    handleChangeField('thumbnailImageId')('');
    handleChangeField('croppedArea')('');
  };

  const { onCrop, isUploadingCroppedImg, uploadCroppedImg } = useCropper(
    onChangeCrop,
    localData.croppedArea,
    getPublicFile(localData.imageId),
    localData.thumbnailImageId,
    480
  );

  const getLinkPrefix = () => `${getGlobal('domain')}/${siteName}/`;

  const handleCopyLink = () => {
    copyToClipboard(`${getLinkPrefix()}${localData.link}`);
    notificationStore.addNotification({
      text: 'Link to appointment block',
      textColored: 'was copied to your clipboard'
    });
  };

  const isDayInputsInvalid =
    localData.startDateTimeStamp > localData.endDateTimeStamp;
  const isTimeInputsInvalid =
    localData.startDateTimeStamp === localData.endDateTimeStamp &&
    localData.startTimeMs >= localData.endTimeMs;

  const isFormValid = useCallback(() => {
    return (
      isFormDataValid(localData) &&
      !(localData.locationType === LocationTypeEnum.Zoom && !isZoomConnected) &&
      !isPageNameExist &&
      !isDayInputsInvalid &&
      !isTimeInputsInvalid
    );
  }, [isDayInputsInvalid, isPageNameExist, isTimeInputsInvalid, localData]);

  const handleAcceptConfirmation = () => {
    onSubmit();
    handleCloseConfirmModal();
  };

  const handleCancelConfirmation = () => {
    handleChangeField('membersToRemove')([]);
    handleCloseConfirmModal();
  };

  const onSubmit = async () => {
    const thumbnailImageId = await uploadCroppedImg();
    save(thumbnailImageId);
  };

  const getDataForSave = () => {
    const reminderOption = reminders.find((item) => item.id === reminderTime);

    return {
      ...localData,
      remindBeforeMs: reminderOption!.value
    };
  };

  const setDataFromSource = (source: CoachEvent) => {
    source.membersToRemove = source.membersToRemove || [];
    setLocalData(source);

    const { remindBeforeMs } = source;

    if (remindBeforeMs) {
      const item = reminders.find((item) => item.value === remindBeforeMs);
      setReminderTime(item!.id);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsValidationShown(false);

    if (isFormValid()) {
      if (localData.membersToRemove.length > 0) {
        setIsConfirmModalOpened(true);
      } else {
        onSubmit();
      }
    } else {
      setIsValidationShown(true);
    }
  };

  const handleCancel = () => {
    if (isAfterIntegrationConnectRef.current) {
      return history.push(CoachRoutesEnum.Calendar);
    }
    history.goBack();
  };

  const save = (thumbnailImageId: string) => {
    const data = getDataForSave();

    const dataToUpdate = {
      ...data,
      thumbnailImageId
    };

    const updateMethod = localData.id ? updateEvent : createEvent;

    setIsLoading(true);

    updateMethod(dataToUpdate)
      .then(() => {
        handleCancel();
        notificationStore.addNotification({
          text: `Meeting is ${localData.id ? 'updated' : 'created'}`
        });
      })
      .catch((error) => {
        if (error.status === 400 && error.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
        } else {
          setIsValidationShown(true);
          const { locationType, ...rest } = getServerModelValidation(error);
          if (locationType) {
            userStore.updateData({ isZoomConnected: false });
          }
          setServerValidation(rest);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConnectZoom = () => {
    storeDataBeforeZoomConnect(pathname, getDataForSave());
  };

  const handleConnectStripe = () => {
    storeDataBeforeStripeConnect(pathname, getDataForSave());
  };

  useEffect(() => {
    if (isAfterStripeConnected || isAfterZoomConnected) {
      history.replace(pathname);
      isAfterIntegrationConnectRef.current = true;
    }
    // eslint-disable-next-line
  }, [isAfterStripeConnected, isAfterZoomConnected]);

  useEffect(() => {
    if (isValidationShown) {
      const invalidElement = document.querySelector<HTMLElement>(
        '.FormInput__input--error, .WeekCalendar__invalid, Select__input--error'
      );

      if (invalidElement) {
        scroll?.scrollTop(
          getOffsetTop(invalidElement, scroll?.container) - 60 - 30
        );
      }
    }
  }, [isValidationShown, scroll]);

  useEffect(() => {
    const restoreData = isAfterZoomConnected
      ? restoreDataAfterZoomConnect<CoachEvent>(true).data
      : isAfterStripeConnected
      ? restoreDataAfterStripeConnect<CoachEvent>(true).data
      : null;

    if (meetingId) {
      setIsLoading(true);
      getEvent(+meetingId)
        .then((response) => {
          initialLink.current = response.link;
          initialInvites.current = response.invites;
          initialMembers.current = response.members;
          setInitialLocation({
            location: response.location,
            locationType: response.locationType,
            showLocationWhileBooking: response.showLocationWhileBooking
          });
          const source = restoreData || response;
          setDataFromSource(source);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (restoreData) {
      setDataFromSource(restoreData);
    }
  }, [isAfterZoomConnected, isAfterStripeConnected, meetingId]);

  useEffect(() => {
    if (
      userId &&
      !isLoading &&
      !localData.id &&
      zoomEnabled &&
      !isAfterIntegrationConnectRef.current
    ) {
      handleChangeField('locationType')(
        isZoomConnected ? LocationTypeEnum.Zoom : LocationTypeEnum.Custom
      );
    }
  }, [
    userId,
    localData.id,
    isLoading,
    zoomEnabled,
    handleChangeField,
    isZoomConnected
  ]);

  useEffect(() => {
    clearTimeout(checkLinkTimer);
    if (
      localData.link.trim() !== '' &&
      localData.link.trim() !== initialLink.current
    ) {
      checkLinkTimer = window.setTimeout(() => {
        checkPageNameExist(localData.link).then((isExist) => {
          setIsPageNameExist(isExist);
        });
      }, 300);
    } else {
      setIsPageNameExist(false);
    }
  }, [localData.link]);

  useEffect(() => {
    if (localData.startDateTimeStamp > localData.endDateTimeStamp) {
      handleChangeField('endDateTimeStamp')(localData.startDateTimeStamp);
    }
  }, [localData.startDateTimeStamp]);

  useEffect(() => {
    if (localData.startDateTimeStamp > localData.endDateTimeStamp) {
      handleChangeField('startDateTimeStamp')(localData.endDateTimeStamp);
    }
  }, [localData.endDateTimeStamp]);

  useEffect(() => {
    if (startTimeMsFieldIsDirty) {
      handleChangeField('endTimeMs')(localData.startTimeMs + 3600000);
    }
  }, [handleChangeField, localData.startTimeMs, startTimeMsFieldIsDirty]);

  const hasMembersForRefund = localData.members
    .filter((member) =>
      localData.membersToRemove.some((clientId) => member.clientId === clientId)
    )
    .some((m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded);

  const cofirmModalData = {
    title:
      isPaid && hasMembersForRefund
        ? 'Remove a member(-s) from the meeting'
        : 'Cancel registration',
    text:
      isPaid && hasMembersForRefund
        ? 'This is a paid meeting. If you remove this person(-s) will be automatically refunded. Do you want to proceed?'
        : 'Are you sure that you want to cancel registration for the client(s)?'
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpened(false);
  };

  const handleCloseRefundPrompt = () => {
    setIsRefundPromptModalOpened(false);
    handleCancel();
  };

  return (
    <>
      <PageTitle title='Meeting' />
      <GlobalLoader isLoading={isLoading} />
      <div className='mb-sm-3 mb-2 pb-1'>
        <Button variations={['naked']} handleClick={handleCancel}>
          <ArrowIcon />
          <span className='ml-2'>Back</span>
        </Button>
      </div>
      <form className='CoachMeeting' onSubmit={handleSubmit}>
        <div className='row justify-content-between align-items-center mb-6'>
          <div className='col-12 col-md-auto mb-3 mb-md-0'>
            <div className='CoachMeeting__title mb-2 d-flex align-items-center'>
              <span>
                {localData.id ? (isDisabled ? 'View' : 'Edit') : 'Create new'}{' '}
                meeting
              </span>

              {isDisabled && (
                <span className='CoachMeeting__badge'>
                  This meeting is {EventStatus[localData.coachEventStatus]}
                </span>
              )}
            </div>
            <div className='CoachMeeting__text'>Meeting Details</div>
          </div>
          <div className='col-12 col-md-auto'>
            <FormCheckbox
              id='isPrivate'
              checked={localData.isPrivate}
              handleChange={() =>
                handleChangeField('isPrivate')(!localData.isPrivate)
              }
              labelBefore='Public'
              label='Private'
              isDisabled={isDisabled}
            />
          </div>
        </div>
        <div className='row mx-n2 pb-3'>
          <div className='col-md-6 px-2 pt-3 d-flex CoachMeeting__img'>
            <FileUploader
              className='UploadArea'
              description='Drag and drop or click to add an image'
              isDisabled={isDisabled}
              fileMaxSize={MAX_IMAGE_SIZE}
              fileTypes={MAX_FILE_TYPES}
              isImage
              showPreview={!!localData.imageId}
              onFileUploaded={onFileUpload}
              withHint={!localData.imageId}
            >
              {!localData.imageId ? (
                <div className='UploadArea__placeholder'>
                  <NoImage />
                </div>
              ) : (
                <ImageCropper
                  croppedArea={
                    localData.croppedArea
                      ? JSON.parse(localData.croppedArea)
                      : undefined
                  }
                  aspect={16 / 9}
                  imageSrc={getPublicFile(localData.imageId)}
                  onCrop={onCrop}
                />
              )}
            </FileUploader>
          </div>
          <div className='col-md-6 px-2'>
            <FormInput
              autoFocus={!isMobileDevice()}
              handleChange={handleChangeField('name')}
              value={localData.name}
              label='Name'
              isInvalid={
                isValidationShown && !isFormInputValid('name', localData)
              }
              errorMessage={
                getFormInputErrors('name', localData)[0] || 'Please fill'
              }
              placeholder='e.g. V’s power Yoga'
              onBlur={() => {
                if (localData.link === '') {
                  handleChangeField('link')(createLink(localData.name));
                }
              }}
              isDisabled={isDisabled}
            />
            <FormInput
              handleChange={() => {}}
              value={''}
              label='Description'
              isDisabled={isDisabled}
            >
              <WYSIWYGEditor
                wrapperClassName='flex-grow-1'
                onChange={handleChangeField('description')}
                HTMLString={localData.description}
              />
            </FormInput>
          </div>
        </div>
        <div className='row mx-n2 pb-3'>
          <div className='col-md-6 px-2'>
            <FormInput
              label='Starts'
              value={''}
              noClassNameForChildren
              isDisabled={isDisabled || isInvalidZoomMeeting}
            >
              <div className='d-flex flex-grow-1 flex-sm-nowrap row'>
                <div className='mb-sm-0 mb-4 mr-0 mr-sm-3 col-sm col-12'>
                  <DatePickerModal
                    value={localData.startDateTimeStamp}
                    onChange={handleChangeField('startDateTimeStamp')}
                    isInvalid={isValidationShown && isDayInputsInvalid}
                    isDisabled={isDisabled || isInvalidZoomMeeting}
                    errorMessage='Please check the date'
                  />
                </div>
                <TimePickerDropdown
                  className={'col-sm-4 col-12 '}
                  timeMs={localData.startTimeMs}
                  minuteStep={15}
                  onChange={(value) => {
                    setStartTimeMsFieldIsDirty(true);
                    handleChangeField('startTimeMs')(value);
                  }}
                  isInvalid={isValidationShown && isTimeInputsInvalid}
                  isDisabled={isDisabled || isInvalidZoomMeeting}
                  errorMessage='Please check the time'
                />
              </div>
            </FormInput>
          </div>
          <div className='col-md-6 px-2'>
            <FormInput
              label='Ends'
              value={''}
              noClassNameForChildren
              isDisabled={isDisabled || isInvalidZoomMeeting}
            >
              <div className='d-flex flex-grow-1 flex-sm-nowrap row'>
                <div className='mb-sm-0 mb-4 mr-0 mr-sm-3 col-sm col-12'>
                  <DatePickerModal
                    value={localData.endDateTimeStamp}
                    onChange={handleChangeField('endDateTimeStamp')}
                    isInvalid={isValidationShown && isDayInputsInvalid}
                    errorMessage='Please check the date'
                    isDisabled={isDisabled || isInvalidZoomMeeting}
                  />
                </div>
                <TimePickerDropdown
                  className={'col-sm-4 col-12 '}
                  timeMs={localData.endTimeMs}
                  minuteStep={15}
                  onChange={handleChangeField('endTimeMs')}
                  isInvalid={isValidationShown && isTimeInputsInvalid}
                  errorMessage='Please check the time'
                  isDisabled={isDisabled || isInvalidZoomMeeting}
                />
              </div>
            </FormInput>
          </div>
          {isInvalidZoomMeeting && (
            <div className='col px-2 mt-3'>
              <Warning>
                You can't update the date &amp; time when Zoom is disconnected.
                Change the location type to Custom to proceed with changes.
              </Warning>
            </div>
          )}
        </div>
        <div className='CoachMeeting__group'>
          {/* <div className='CoachMeeting__subtitle CoachMeeting__subtitle--small'>
            Buffers
          </div>
          <div className='row pb-3 mx-n2'>
            <div className='col-6 px-2'>
              <FormCheckbox
                id='hasBlockTime'
                checked={hasBuffers}
                isDisabled={isDisabled}
                handleChange={() => setHasBuffers(!hasBuffers)}
                label='Set an amount of time to block before and/or after a client schedules'
              />
            </div>
          </div> */}

          <div className='row pb-3 pt-3 mx-n2'>
            {/* <div className='col-6 px-2 d-flex'>
              <Select
                className='col'
                options={buffers}
                isDisabled={isDisabled || !hasBuffers}
                value={selectedBefore}
                title={'Before'}
                onChange={(value) => setSelectedBefore(value)}
              />
              <Select
                className='mr-0 col'
                options={buffers}
                isDisabled={isDisabled || !hasBuffers}
                value={selectedAfter}
                title={'After'}
                onChange={(value) => setSelectedAfter(value)}
              />
            </div> */}
            <div className='col-sm-6 px-2 row'>
              <div className='col-12 col-xl-6 pr-xl-2 d-flex'>
                <Select
                  className='mr-0 flex-grow-1'
                  options={reminders}
                  isDisabled={isDisabled}
                  value={reminderTime}
                  title={'Reminder'}
                  onChange={(value) => setReminderTime(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row CoachMeeting__group pb-3'>
          <div className='col-md-6 pr-md-2 d-flex flex-column d-md-block'>
            <div className='row order-2 order-sm-1'>
              <div className='col-12 col-sm-6 pr-sm-2'>
                <FormInput
                  type='number'
                  step={1}
                  handleChange={handleChangeField('availableSpots')}
                  valueFormatter={spotsValueFormatter}
                  value={localData.availableSpots}
                  isInvalid={
                    isValidationShown &&
                    !isFormInputValid('availableSpots', localData)
                  }
                  isDisabled={isDisabled}
                  errorMessage={
                    getFormInputErrors('availableSpots', localData)[0]
                  }
                  label='Spots Available'
                  tabIndex={isMobile ? 3 : 0}
                />
              </div>
              <div className='col-12 col-sm-6 pl-sm-2'>
                <FormInput
                  className='FormInput--price FormInput--nowrap'
                  type='number'
                  isDisabled={
                    isDisabled || !isStripeConnected || hasConfirmedMembers
                  }
                  handleChange={handleChangeField('price')}
                  valueFormatter={formatValueAsPrice}
                  value={localData.price}
                  isInvalid={
                    isValidationShown &&
                    (!isFormInputValid('price', localData) ||
                      !!serverValidation['price'])
                  }
                  errorMessage={
                    getFormInputErrors('price', localData)[0] ||
                    serverValidation['price']
                  }
                  label='Amount to be collected'
                  append='USD'
                  labelClassName='letter-spacing-n1'
                  tabIndex={isMobile ? 4 : 0}
                />
                <StripeConnectHint
                  isShown={!isStripeConnected}
                  onConnectStripe={handleConnectStripe}
                />
                {isStripeConnected && hasConfirmedMembers && (
                  <div className='subLabel'>
                    The payment option can't be changed if the meeting has a
                    confirmed registration.
                  </div>
                )}
              </div>
            </div>

            <Location
              locationType={localData.locationType}
              location={localData.location}
              locationTitle='Meeting location'
              showLocationWhileBooking={localData.showLocationWhileBooking}
              isDisabled={isDisabled}
              isZoomConnected={isZoomConnected}
              connectUrl={getZoomConnectUrl(userId)}
              onConnect={handleConnectZoom}
              onChange={handleChange}
              initialLocation={initialLocation}
              isInvalid={
                isValidationShown
                  ? {
                      location: !isFormInputValid('location', localData),
                      locationType: !isFormInputValid('locationType', localData)
                    }
                  : null
              }
              errorMessage={{
                location: getFormInputErrors('location', localData)[0],
                locationType: getFormInputErrors('locationType', localData)[0]
              }}
              tabIndex={isMobile ? 1 : 0}
            />
            <FormInput
              tabIndex={isMobile ? 2 : 0}
              className='FormInput--customize-prepend'
              handleChange={handleChangeField('link')}
              value={localData.link}
              label='Meeting page address'
              isInvalid={
                isPageNameExist ||
                (isValidationShown && !isFormInputValid('link', localData))
              }
              isDisabled={isDisabled}
              valueFormatter={createLink}
              errorMessage={
                isPageNameExist
                  ? 'This link is used for another page, please choose another'
                  : `${getFormInputErrors('link', localData)[0]}`
              }
              prepend={getLinkPrefix()}
              append={
                <Button
                  type='button'
                  disabled={isDisabled}
                  variations={['naked']}
                  handleClick={handleCopyLink}
                >
                  Copy
                </Button>
              }
            />
          </div>
          <div className='col-md-6 pl-md-2 d-flex flex-column'>
            <InviteClients
              tabIndex={isMobile ? 5 : 0}
              initialInvites={initialInvites.current}
              initialMembers={initialMembers.current}
              className='mt-3 w-100'
              label='Add Clients'
              invites={localData.invites}
              setInvites={handleChangeField('invites')}
              membersToRemove={localData.membersToRemove}
              setMembersToRemove={handleChangeField('membersToRemove')}
              autoFocus={false}
              isDisabled={isDisabled}
              placeholder={inviteClientsPlaceholder}
            />
          </div>
        </div>
        <div className='row mx-n2 pt-3'>
          <div className='col-sm-6 mb-3 mb-sm-0 col-12 px-2'>
            {!isDisabled && (
              <Button
                className='button__stretch'
                variations={['gray']}
                type='button'
                handleClick={handleCancel}
              >
                Cancel
              </Button>
            )}
          </div>
          <div className='col-sm-6 mb-3 mb-sm-0 col-12 px-2'>
            {!isDisabled && (
              <Button
                className='button__stretch'
                disabled={isLoading || isUploadingCroppedImg}
              >
                Save
              </Button>
            )}
            {isDisabled && (
              <Button
                type='button'
                className='button__full-width'
                handleClick={handleCancel}
              >
                Close
              </Button>
            )}
          </div>
        </div>
      </form>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={handleCloseConfirmModal}
        title={cofirmModalData.title}
        text={cofirmModalData.text}
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={handleAcceptConfirmation}
        cancelCallback={handleCancelConfirmation}
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            Some payments for removed clients were made using your previous
            Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={handleCloseRefundPrompt}
        btnText='Close'
      />
    </>
  );
};

export default observer(CoachMeeting);
