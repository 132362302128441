import React, { useEffect, useRef } from 'react';
import './SideMenu.styles.scss';
import MenuPaymentsIcon from '../../icons/menu-payments.svg';
import MenuDashboardIcon from '../../icons/menu-dashboard.svg';
import MenuScheduleIcon from '../../icons/menu-schedule.svg';
import MenuContactsIcon from '../../icons/menu-contacts.svg';
import MenuInvoicingIcon from '../../icons/menu-invoicing.svg';
import MenuStatsIcon from '../../icons/menu-stats.svg';
import MenuTrainingIcon from '../../icons/menu-training.svg';
import MenuPagesIcon from '../../icons/menu-pages.svg';
import UserIcon from '../../icons/user.svg';
import QuestionIcon from '../../icons/question.svg';
import { useOnClickOutside } from '../../hooks';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import SideMenuList, {
  IconsProps,
  ParentMenuItem
} from '../SideMenuList/SideMenuList';
interface Props {
  menuList: ParentMenuItem[];
  isOpened: boolean;
  isTablet: boolean;
  onClose: (e?: React.MouseEvent) => void;
  className?: string;
  withHelpButton?: boolean;
}

const icons: IconsProps = {
  MenuPaymentsIcon: <MenuPaymentsIcon />,
  MenuDashboardIcon: <MenuDashboardIcon />,
  MenuScheduleIcon: <MenuScheduleIcon />,
  MenuContactsIcon: <MenuContactsIcon />,
  MenuInvoicingIcon: <MenuInvoicingIcon />,
  MenuStatsIcon: <MenuStatsIcon />,
  MenuTrainingIcon: <MenuTrainingIcon />,
  MenuPagesIcon: <MenuPagesIcon />,
  MenuUsersIcon: <UserIcon />
};

const SideMenu: React.FC<Props> = ({
  menuList = [],
  isOpened,
  isTablet,
  onClose,
  className = '',
  withHelpButton = true
}) => {
  const ref = useRef<HTMLDivElement>();
  const history = useHistory();

  useOnClickOutside(ref, onClose, isOpened && isTablet);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isTablet && isOpened) {
        onClose();
      }
    });
    return () => {
      unlisten();
    };
  }, [isOpened, isTablet]);

  return (
    <div
      ref={ref}
      className={cn('SideMenu', className, {
        'SideMenu--opened': isOpened
      })}
    >
      <div>
        <SideMenuList menuList={menuList} icons={icons} isTablet={isTablet} />
      </div>
      {withHelpButton && (
        <div className='SideMenu__help'>
          <a
            rel='noopener noreferrer'
            target='_blank'
            href='https://intercom.help/coachpro/en/'
            className='SideMenu__item'
          >
            <div className='SideMenu__item-icon'>
              <QuestionIcon />
            </div>
            <span className='SideMenu__item-text'>Help</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
