import React, { useState, useEffect } from 'react';
import './CoachMeetings.styles.scss';
import MeetingTile from '../../components/MeetingTile/MeetingTile';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { getEvents, updateEvent, cancelEvent } from '../../core/api';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import getGlobal from '../../core/globals';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import {
  StripeRefundStatusEnum,
  ClientEventStatusEnum,
  CoachRoutesEnum,
  NotificationTypesEnum
} from '../../core/enums';
import { CoachEvent } from '../../core/types';
import { EventStatus } from '../../core/enums';
import moment from 'moment';
import { getTimeInZone } from '../../core/helpers';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyImg from '../../images/empty-meetings.svg';
import { useStores } from '../../hooks';
import CoachSchedulePage from '../../containers/CoachSchedulePage/CoachSchedulePage';
import PageHeaderContent from '../../components/PageHeaderContent/PageHeaderContent';
import Button from '../../components/Button/Button';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';

const CoachMeetings = () => {
  const { rootStore } = useStores();
  const { userStore, notificationStore, incrementChangesCounter } = rootStore;

  const history = useHistory();
  const [meetings, setMeetings] = useState<CoachEvent[]>([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const [itemToCancel, setItemToCancel] = useState<CoachEvent>();
  const [isLoading, setIsLoading] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState({
    confirmBtnText: 'Yes',
    text: '',
    cancelBtnText: 'No'
  });
  const currentTimeStamp = moment
    .utc(getTimeInZone(Date.now(), userStore?.timeZone || ''))
    .valueOf();

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    setIsLoading(true);
    getEvents().then((data) => {
      setMeetings(
        data
          .filter(
            (m) =>
              !m.isScheduled &&
              m.endDateTimeStamp + m.endTimeMs > currentTimeStamp
          )
          .sort((a, b) =>
            a.startDateTimeStamp > b.startDateTimeStamp ? 1 : -1
          )
      );
      setIsLoading(false);
    });
  };

  const handlePrivateButtonClick = (itemId: number) => {
    const item = meetings.find(({ id }) => id === itemId);
    if (item) {
      updateEvent({
        ...item,
        isPrivate: !item.isPrivate
      }).then(() => {
        incrementChangesCounter();
        notificationStore.addNotification({
          text: 'Meeting was updated'
        });
      });
    }
  };

  const handleEditButtonClick = (itemId: number) => {
    history.push(
      `${CoachRoutesEnum.MeetingDetails}`.replace(':meetingId', `${itemId}`)
    );
  };

  const handleCancelButtonClick = (itemId: number) => {
    const currentEvent = meetings.find((m) => m.id === itemId);
    setItemToCancel(currentEvent);
    setConfirmModalData(currentEvent);
    setIsConfirmModalOpened(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpened(false);
  };

  const cancelEventItem = (withRefund: boolean) => {
    setIsCancelling(true);
    cancelEvent(itemToCancel!.id, withRefund)
      .then(() => {
        reFetchData();
        setItemToCancel(undefined);
        setIsConfirmModalOpened(false);
        notificationStore.addNotification({
          text: 'Meeting was canceled'
        });
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          reFetchData();
          setItemToCancel(undefined);
          setIsConfirmModalOpened(false);
        } else {
          notificationStore.addNotification({
            text: 'Something went wrong.',
            textColored: 'Try one more time,\n please.',
            duration: 3000,
            type: NotificationTypesEnum.Error
          });
        }
      })
      .finally(() => {
        setIsCancelling(false);
      });
  };

  const handleAcceptConfirm = () => {
    const activeMembers = itemToCancel?.members.filter(
      (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
    );
    const hasClientsToRefund = activeMembers.some(
      (m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded
    );
    cancelEventItem(
      Number(itemToCancel.price) > 0 &&
        !!activeMembers.length &&
        hasClientsToRefund
    );
  };

  const handleRejectConfirm = () => {
    const activeMembers = itemToCancel.members.filter(
      (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
    );

    if (Number(itemToCancel.price) === 0 || activeMembers.length === 0) {
      return closeConfirmModal();
    }

    cancelEventItem(false);
  };

  const setConfirmModalData = (meeting: CoachEvent) => {
    let confirmBtnText = 'Yes',
      text = 'Are you sure that you want to cancel it ?',
      cancelBtnText = 'No';

    const activeMembers = meeting?.members.filter(
      (m) => m.clientStatus === ClientEventStatusEnum.Active
    );

    const hasClientsToRefund = activeMembers.some(
      (m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded
    );

    if (
      Number(meeting.price) > 0 &&
      !!activeMembers.length &&
      hasClientsToRefund
    ) {
      text = `You are trying to cancel a paid meeting. If you press Cancel & Refund all members of this meeting will be automatically refunded.
        If you choose to Cancel you'll have to make a refund manually in your Stripe account.`;
      confirmBtnText = 'Cancel & Refund';
      cancelBtnText = 'Cancel Meeting';
    }

    setConfirmModalText({
      confirmBtnText,
      text,
      cancelBtnText
    });
  };

  return (
    <CoachSchedulePage title='Meetings'>
      <PageHeaderContent>
        <Button
          variations={['xs-width', 'floating-button']}
          link={CoachRoutesEnum.New}
          component='Link'
        >
          <span className='button__floating-button-plus'>+</span>Schedule
        </Button>
      </PageHeaderContent>
      <GlobalLoader isLoading={isLoading} />
      <div className='CoachMeetings mt-sm-4 mt-3'>
        <div className='row mx-n2'>
          <div className='col-xl-4 col-sm-6 px-2 mb-3'>
            <Link
              to={CoachRoutesEnum.MeetingNew}
              className='MeetingTile MeetingTile--add-new'
            >
              + Add new
            </Link>
          </div>
          {meetings.map((item) => (
            <div key={item.id} className='col-xl-4 col-sm-6 px-2 mb-3'>
              <MeetingTile
                handlePrivate={() =>
                  handlePrivateButtonClick(item.id as number)
                }
                handleEdit={() => handleEditButtonClick(item.id as number)}
                handleCancel={() => handleCancelButtonClick(item.id as number)}
                color={
                  item.coachEventStatus === EventStatus.Active
                    ? '#BB6BD9'
                    : '#D26B6B'
                }
                status={item.coachEventStatus}
                startDate={item.startDateTimeStamp}
                title={item.name}
                price={+item.price}
                meetingType={item.members.length === 1 ? 'single' : 'group'}
                isPrivate={item.isPrivate}
                link={`${getGlobal('domain')}/${userStore!.siteName}/${
                  item.link
                }`}
              />
            </div>
          ))}
        </div>
        {!meetings.length && !isLoading && (
          <div className='row mx-n2 mt-5'>
            <div className='col-xl-4 offset-xl-4 offset-sm-3 col-sm-6 px-2'>
              <EmptyState
                icon={<EmptyImg />}
                text="You don't have meetings yet."
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={closeConfirmModal}
        title={`Cancel ${itemToCancel?.name}`}
        text={confirmModalText.text}
        confirmBtnText={confirmModalText.confirmBtnText}
        cancelBtnText={confirmModalText.cancelBtnText}
        confirmCallback={handleAcceptConfirm}
        cancelCallback={handleRejectConfirm}
        disableConfirmBtn={isCancelling}
        disableCancelBtn={isCancelling}
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            Some payments were made using your previous Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
    </CoachSchedulePage>
  );
};

export default observer(CoachMeetings);
