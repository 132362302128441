import React from 'react';
import Badge, { BadgeType } from '../../components/Badge/Badge';
import { TableColumn, TableColumnType } from '../../components/Table/Table';
import { getPublicFile } from '../../core/api';
import { InvoiceStatusEnum, InvoiceTypeEnum } from '../../core/enums';
import { Invoice } from '../../core/types';
import AvatarIcon from '../../icons/avatar-small.svg';
import moment from 'moment';
import { highlightMatches, normalizePrice } from '../../core/helpers';
import TextEllipse from '../../components/TextEllipse';

export const getBadgeType = (
  type: keyof typeof InvoiceStatusEnum
): BadgeType => {
  switch (type) {
    case 'draft':
      return 'gray';
    case 'open':
      return 'blue';
    case 'canceled':
      return 'yellow';
    case 'paid':
      return 'green';
    case 'late':
      return 'red';
    case 'uncollectible':
    default:
      return 'disabled';
  }
};

const dateFormatter = (v: number, searchString: string) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: highlightMatches(moment.utc(v).format('MMM D'), searchString)
      }}
    />
  );
};

export const getColumns = (
  isStatusSortable: boolean
): TableColumn<Invoice>[] => [
  {
    field: 'amount',
    title: 'AMOUNT',
    type: TableColumnType.String,
    sortable: true,
    formatter: (v: number) => {
      return '$' + normalizePrice(v);
    },
    minWidth: 100,
    maxWidth: '0fr'
  },
  {
    field: 'status',
    title: 'STATUS',
    type: TableColumnType.String,
    sortable: isStatusSortable,
    formatter: (v: number) => {
      const status = InvoiceStatusEnum[v] as keyof typeof InvoiceStatusEnum;
      return (
        <Badge
          type={getBadgeType(status)}
          title={(status as string).toUpperCase()}
          className='Badge--CoachInvoices'
        />
      );
    },
    minWidth: 128
  },
  {
    field: 'client',
    title: 'CLIENT',
    type: TableColumnType.JSX,
    searchable: true,
    formatter: ({ client }: Invoice, searchString: string) => {
      const email = client?.email?.toLowerCase();

      return (
        <div className='d-flex align-items-center InvoicesList__cell'>
          <div className='InvoicesItem__avatar InvoicesItem__avatar--sm flex-shrink-0 mr-3'>
            {client?.imageId ? (
              <img src={getPublicFile(client.imageId)} alt='avatar' />
            ) : (
              <AvatarIcon />
            )}
          </div>
          <span className='InvoicesList__ellipsis'>
            <TextEllipse text={`${client.firstName} ${client.lastName}`}>
              <span
                className='InvoicesList__name'
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(
                    String(`${client.firstName} ${client.lastName}`),
                    searchString
                  )
                }}
              />
            </TextEllipse>
            <TextEllipse text={email}>
              <span
                className='InvoicesList__email'
                dangerouslySetInnerHTML={{
                  __html: highlightMatches(email, searchString)
                }}
              />
            </TextEllipse>
          </span>
        </div>
      );
    },
    minWidth: 200,
    maxWidth: '3fr'
  },
  {
    field: 'type',
    title: 'PAYMENT',
    type: TableColumnType.String,
    minWidth: 92,
    formatter: (v: InvoiceTypeEnum) =>
      v === InvoiceTypeEnum.OneTime ? 'One time' : 'Recurring'
  },
  {
    field: 'number',
    title: 'REF NUMBER',
    type: TableColumnType.String,
    minWidth: 100,
    searchable: true
  },
  {
    field: 'createdOn',
    title: 'CREATED ON',
    type: TableColumnType.String,
    sortable: true,
    formatter: dateFormatter,
    minWidth: 80,
    searchable: true
  },
  {
    field: 'dueDate',
    title: 'DUE',
    type: TableColumnType.JSX,
    sortable: true,
    formatter: (value: Invoice, searchText: string) => {
      return value.type !== InvoiceTypeEnum.Recurring ||
        [
          InvoiceStatusEnum.draft,
          InvoiceStatusEnum.open,
          InvoiceStatusEnum.late
        ].includes(value.status)
        ? dateFormatter(value.dueDate, searchText)
        : '-';
    },
    minWidth: 80,
    searchable: true
  }
];
