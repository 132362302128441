import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import Tooltip from './Tooltip/Tooltip';
import { useWindowResize } from '../hooks';

interface Props {
  text: string;
  className?: string;
  children: JSX.Element;
  lines?: number;
}

const TextEllipse: React.FC<Props> = ({
  text,
  className = '',
  children,
  lines = 1
}) => {
  const contentRef = useRef<HTMLSpanElement>();
  const [isDisable, setIsDisable] = useState<boolean>(true);

  useWindowResize(() => {
    createTextElipsis(lines);
  }, 100);

  useEffect(() => {
    createTextElipsis(lines);
  }, [contentRef, lines]);

  const createTextElipsis = (lines: number) => {
    const offset = lines === 1 ? 'offsetWidth' : 'offsetHeight';

    setIsDisable(
      contentRef.current?.[offset] - 1 <=
        contentRef.current?.parentElement[offset]
    );
  };

  const trigger = React.cloneElement(children, {
    ref: contentRef
  });

  return (
    <Tooltip
      className={`${lines === 1 ? 'Tooltip--ellipsis' : ''} ${
        lines > 1 ? 'Tooltip--ellipsis-multi' : ''
      } ${lines > 1 ? 'Elipsis-' + lines : ''} ${className}`}
      trigger={trigger}
      content={<span>{text}</span>}
      triggerType='hover'
      placement='top'
      minWidth={200}
      maxWidth={300}
      disabled={isDisable}
      withPortal
    />
  );
};

export default TextEllipse;
