import React from 'react';
import Modal from '../../Modal/Modal';
import ForgotPassword from '../../ForgotPassword/ForgotPassword';

interface iProps {
  close: () => void;
  isOpened: boolean;
}

const ClientForgotPasswordModal: React.FC<iProps> = ({ close, isOpened }) => {
  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 440 }}>
      <ForgotPassword isClient={true} />
    </Modal>
  );
};

export default ClientForgotPasswordModal;
