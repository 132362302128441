import React, { useState, ReactNode, useEffect, useRef } from 'react';
import './EditSharingModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { observer } from 'mobx-react';
import SharingInput from '../../SharingInput/SharingInput';
import { CoachContactsViewModel } from '../../../core/backend/api';
import { useStores } from '../../../hooks';
import {
  getSharedFileClients,
  postShareFile,
  unShareFiles
} from '../../../core/api';
import { FileResponseViewModelWithExt } from '../../../core/types';
import Loader from '../../Loader/Loader';
import SharedFilesContacts from '../../SharedFilesContacts/SharedFilesContacts';
import { searchBy, sortArrayBy } from '../../../core/helpers';

interface iProps {
  isOpened: boolean;
  close: () => void;
  FileIcon: ReactNode;
  file: FileResponseViewModelWithExt;
  contacts: CoachContactsViewModel[];
  onConfirm?: (id: number) => void;
  title?: string;
}

const EditSharingModal: React.FC<iProps> = ({
  isOpened,
  close,
  FileIcon,
  file,
  contacts,
  onConfirm,
  title = 'Edit sharing'
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const initialSharedContactsIds = useRef([]);
  const [sharedContactsIds, setSharedContactsIds] = useState<string[]>([]);
  const [unsharedContactsIds, setUnsharedContactsIds] = useState<string[]>([]);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState([]);

  const handleConfirm = () => {
    setIsPending(true);
    Promise.all([
      unShareFiles([file.id], unsharedContactsIds),
      postShareFile([file.id], sharedContactsIds)
    ])
      .then(() => {
        onConfirm && onConfirm(file.id);
        notificationStore.addNotification({
          text: 'Changes',
          textColored: 'were saved',
          duration: 3000
        });
      })
      .finally(() => {
        setIsPending(false);
        close();
      });
  };

  const handleDelete = (clientId: string) => {
    setSharedContactsIds((prevIds) =>
      prevIds.filter((id: string) => id !== clientId)
    );
    initialSharedContactsIds.current.includes(clientId) &&
      setUnsharedContactsIds((prevIds) => [...prevIds, clientId]);
  };

  useEffect(() => {
    if (file && isOpened) {
      setIsPending(true);
      getSharedFileClients(file.id)
        .then((res) => {
          initialSharedContactsIds.current = res;
          setSharedContactsIds(res);
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [file, isOpened]);

  useEffect(() => {
    if (isOpened) {
      setSharedContactsIds([]);
      setUnsharedContactsIds([]);
      setSelectedIds([]);
      setInputValue('');
    }
  }, [isOpened]);

  const handleChangeInputValue = (value: string) => {
    setInputValue(value);
  };

  const handleSelect = (optionId: string) => {
    setSelectedIds((prev) => {
      if (prev.includes(optionId)) return prev.filter((id) => id !== optionId);
      return [...prev, optionId];
    });
    handleChangeInputValue('');
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    handleChangeInputValue(value);
    const filtered: CoachContactsViewModel[] = contacts
      .filter((option) => {
        return (
          searchBy(option['firstName'], value) ||
          searchBy(option['email'], value) ||
          searchBy(option['lastName'], value)
        );
      })
      .filter((c) => !sharedContactsIds.includes(c.clientId));
    return filtered;
  };

  const handleUnselect = (clientId: string) => () =>
    setSelectedIds((prev) => prev.filter((prevId) => clientId !== prevId));

  const handleShare = () => {
    setSharedContactsIds((prevIds) => [...prevIds, ...selectedIds]);
    setSelectedIds([]);
    setInputValue('');
  };

  if (!file) return null;

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 506 }}>
      <div className='EditSharingModal'>
        {isPending && <Loader />}
        <div className='EditSharingModal__title'>{title}</div>
        <div className='EditSharingModal__file-info'>
          <div className='EditSharingModal__file-image'>{FileIcon}</div>
          <div className='EditSharingModal__file-info-content'>
            <div className='EditSharingModal__file-info-text'>{file.name}</div>
            <div className='EditSharingModal__file-info-members'>
              total {sharedContactsIds.length} of {contacts.length} members
            </div>
          </div>
        </div>
        <SharingInput
          options={contacts
            .filter((c) => !sharedContactsIds.includes(c.clientId))
            .sort(sortArrayBy('firstName'))}
          sharedIds={sharedContactsIds}
          selectedIds={selectedIds}
          title='To:'
          placeholder='Email or name'
          className='SharingInput--EditSharingModal'
          inputValue={inputValue}
          onShare={handleShare}
          onSelect={handleSelect}
          onChangeInput={onChangeInput}
          onUnselect={handleUnselect}
          withShareButton
        />
      </div>
      <SharedFilesContacts
        contacts={contacts.filter(({ clientId }) =>
          sharedContactsIds.includes(clientId)
        )}
        onDelete={handleDelete}
      />
      <Button
        className='button__full-width'
        handleClick={handleConfirm}
        disabled={isPending}
      >
        Confirm
      </Button>
    </Modal>
  );
};

export default observer(EditSharingModal);
