import React from 'react';
import './PaymentMethodModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { StripePaymentMethod } from '../../../core/types';
import { capitalize } from '../../../core/helpers';

export interface iProps {
  isOpened: boolean;
  paymentMethod: StripePaymentMethod;
  isSubmitting: boolean;
  onSubmit: () => void;
  onClose: () => void;
  className?: string;
  title: string;
  btnText: string;
}

const PaymentMethodModal: React.FC<iProps> = ({
  isOpened,
  paymentMethod,
  isSubmitting,
  onSubmit,
  onClose,
  className = '',
  title,
  btnText
}) => {
  return (
    <Modal
      className='PaymentMethodModal'
      close={onClose}
      isOpened={isOpened}
      style={{ width: 486 }}
    >
      <div className='PaymentMethodModal__title'>{title}</div>
      <div className='PaymentMethodModal__body py-4'>
        {paymentMethod && (
          <div className='CardData row'>
            <div className='CardData__type col-7 p-3'>
              <b>{capitalize(paymentMethod?.card?.brand)}</b> ending in{' '}
              {paymentMethod?.card?.last4}
            </div>
            <div className='CardData__ending col-5 p-3'>
              Expires {paymentMethod?.card?.exp_month}/
              {paymentMethod?.card?.exp_year}
            </div>
          </div>
        )}
      </div>
      <div className='PaymentMethodModal__footer'>
        <Button
          handleClick={onSubmit}
          variations={['full-width']}
          type='button'
          disabled={isSubmitting}
        >
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentMethodModal;
