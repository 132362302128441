import React from 'react';
import Modal from '../../components/Modal/Modal';
import { BillingItemViewModel } from '../../core/backend/api';
import Button from '../Button/Button';
import ArrowBack from '../../icons/arrow-left-lg.svg';
import './TransactionModal.styles.scss';
import {
  ClientRoutesEnum,
  CoachRoutesEnum,
  InvoiceStatusEnum,
  TransactionsEntityEnum
} from '../../core/enums';
import { Link } from 'react-router-dom';
import { getRoutePath } from '../../core/globals';
import moment from 'moment';
import { getBadgeType } from './TransactionsList.helpers';
import { normalizePrice } from '../../core/helpers';
import Badge from '../Badge/Badge';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  transaction: BillingItemViewModel;
  flow: 'client' | 'coach';
  onView: (item: BillingItemViewModel) => void;
}

const TransactionModal = ({
  isOpened,
  transaction,
  flow,
  onClose,
  onView
}: Props) => {
  if (!transaction) return null;
  const {
    entity,
    entityId,
    entityName,
    date,
    status,
    amount,
    description
  } = transaction;
  const title =
    entity === TransactionsEntityEnum.Appointment ||
    entity === TransactionsEntityEnum.Meeting
      ? entity
      : 'Invoice';

  const badgeType = InvoiceStatusEnum[status] as keyof typeof InvoiceStatusEnum;

  const handleView = () => {
    onView(transaction);
  };

  const name =
    entity === TransactionsEntityEnum.Appointment ||
    entity === TransactionsEntityEnum.Meeting
      ? entityName
      : `#${entityName}`;

  return (
    <Modal isOpened={isOpened} full className='TransactionModal'>
      <div className='TransactionModal__container'>
        <div className='d-flex align-items-center TransactionModal__header justify-content-between'>
          <div className='d-flex align-items-center'>
            <Button
              className='p-0 mb-0 mr-3'
              handleClick={onClose}
              variations={['naked']}
              type='button'
            >
              <ArrowBack />
            </Button>
            <div className='TransactionModal__title'>{title}</div>
          </div>
          {entity !== TransactionsEntityEnum.Appointment &&
            entity !== TransactionsEntityEnum.Meeting && (
              <Link
                to={
                  flow === 'coach'
                    ? CoachRoutesEnum.Invoice.replace(':id', String(entityId))
                    : getRoutePath(
                        ClientRoutesEnum.Invoice.replace(
                          ':number',
                          transaction.invoice?.number
                        )
                      )
                }
                className='TransactionsList__link'
              >
                View Invoice
              </Link>
            )}
        </div>
        <div className='d-flex align-items-center justify-content-between TransactionModal__row'>
          <div className='TransactionModal__field'>date</div>
          <div className='TransactionModal__text'>
            {moment.utc(date).format('MMM D, YYYY')}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between TransactionModal__row'>
          <div className='TransactionModal__field mr-3'>description</div>
          <div className='TransactionModal__text'>
            {description}{' '}
            <div
              className={
                entity === 'Appointment' || entity === 'Meeting'
                  ? 'TransactionsList__name'
                  : ''
              }
              onClick={handleView}
            >
              {name}
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between TransactionModal__row'>
          <div className='TransactionModal__field mr-3'>amount</div>
          <div className='TransactionModal__text'>
            ${normalizePrice(amount)}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between TransactionModal__row'>
          <div className='TransactionModal__field mr-3'>status</div>
          <div className='TransactionModal__text'>
            <Badge
              type={getBadgeType(badgeType)}
              title={badgeType}
              className='Badge--TransactionModal'
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionModal;
