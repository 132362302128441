import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

interface Props {
  initialData: string;
  currentData: string;
  disableConfirmBtn: boolean;
  onSave: () => void;
  title?: string;
  text?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  className?: string;
}

const PreventUnsavedChangesModal: React.FC<Props> = ({
  currentData,
  initialData,
  disableConfirmBtn,
  title = `Your changes aren't saved`,
  text = 'Are you sure you want to leave without saving your changes?',
  confirmBtnText = 'Save',
  cancelBtnText = 'Yes',
  onSave,
  className = ''
}) => {
  const history = useHistory();
  const unblockHistoryCallbackRef = useRef<() => void>();
  const [route, setRoute] = useState('');
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (currentData !== initialData) {
      unblockHistoryCallbackRef.current && unblockHistoryCallbackRef.current();
      unblockHistoryCallbackRef.current = history.block(({ pathname }) => {
        setRoute(pathname);
        setIsOpened(true);
        return false;
      });
    } else {
      unblockHistoryCallbackRef.current && unblockHistoryCallbackRef.current();
      unblockHistoryCallbackRef.current = null;
    }
  }, [currentData, history, initialData]);

  useEffect(() => {
    return () => {
      unblockHistoryCallbackRef.current && unblockHistoryCallbackRef.current();
    };
  }, []);

  const closeModal = () => {
    setIsOpened(false);
  };

  const handleSave = () => {
    onSave();
    closeModal();
  };

  const handleCancelEdits = () => {
    unblockHistoryCallbackRef.current && unblockHistoryCallbackRef.current();
    route && history.push(route);
  };

  return (
    <ConfirmModal
      testIdPrefix='prevent-unsaved-modal'
      className={className}
      isOpened={isOpened}
      close={closeModal}
      title={title}
      text={text}
      confirmBtnText={confirmBtnText}
      confirmCallback={handleSave}
      cancelCallback={handleCancelEdits}
      cancelBtnText={cancelBtnText}
      disableConfirmBtn={disableConfirmBtn}
    />
  );
};

export default PreventUnsavedChangesModal;
