import React from 'react';
import { NewTypes } from './CoachNew.constants';
import { Link } from 'react-router-dom';
import CreateNewItem from '../../components/CreateNewItem/CreateNewItem';
import './CoachNew.styles.scss';
import PageTitle from '../../components/PageTitle/PageTitle';

interface iProps {}

const CoachNew: React.FC<iProps> = () => {
  return (
    <div className='New'>
      <PageTitle title='New' />
      <h3 className='New__title'>Create new</h3>
      <p className='New__text mb-3'>Choose below</p>
      <div className='NewTypes'>
        {NewTypes.map((item) => (
          <Link key={item.url} to={item.url} className='New__link'>
            <CreateNewItem item={item} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CoachNew;
