import { SignUpClientViewModel } from '../../../core/backend/api';
import { iFormConfigItem } from '../../../core/types';
import {
  getInputErrors,
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty,
  isPhoneNumberLongEnough
} from '../../../core/formValidation';
import { getTimezone } from '../../../core/helpers';
import moment from 'moment';

export const getDefaultData = (): SignUpClientViewModel => ({
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '1',
  timeZone: getTimezone(moment.tz.guess())
});

const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] },
  {
    field: 'phoneNumber',
    checkIsValid: [isNotEmpty, isPhoneNumberLongEnough]
  }
];

export function isFormInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isFormDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}

export function getFormInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}
