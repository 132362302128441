import React, { useState } from 'react';

export const useArrowSelection = <T>(
  options: T[],
  onSelect: (id: T) => void
) => {
  const [selectedByKey, setSelectedByKey] = useState<number>(-1);

  const arrowSelect = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (options.length < 1) return;

    switch (event.which) {
      case 38: // 'Up'
        if (selectedByKey === 0) {
          break;
        }
        setSelectedByKey(selectedByKey - 1);
        break;

      case 40: // 'Down'
        if (selectedByKey === options.length - 1) {
          break;
        }
        setSelectedByKey(selectedByKey + 1);

        if (selectedByKey === -1) setSelectedByKey(0);
        break;

      case 13: // 'Enter'
        onSelect(options[selectedByKey]);
        setSelectedByKey(-1);
        break;

      default:
        break;
    }
  };

  return {
    arrowSelect,
    selectedByKey,
    setSelectedByKey
  };
};
