import { SignInInfoViewModel } from '../../core/backend/api';
import { iFormConfigItem } from '../../core/types';
import {
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty
} from '../../core/formValidation';

export const reducer = (
  storedData: SignInInfoViewModel,
  newData: SignInInfoViewModel
) => newData;

export const getDefaultData = (): SignInInfoViewModel => ({
  email: '',
  password: ''
});

const FormConfig: iFormConfigItem[] = [
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] },
  { field: 'password', checkIsValid: [isNotEmpty] }
];

export function isSignInInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isSignInDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
