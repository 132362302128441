import React, { useEffect } from 'react';
import './PaymentDetailsNew.styles.scss';
import FormInput from '../FormInput/FormInput';

import {
  isTheInputValid as isInputValid,
  customStylesForInput,
  isTheDataValid,
  renderError
} from './PaymentDetailsNew.helpers';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError
} from '@stripe/stripe-js';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { iOS } from '../../core/helpers';

export interface iPaymentData {
  cardNumber: StripeCardNumberElementChangeEvent;
  expiry: StripeCardExpiryElementChangeEvent;
  cvc: StripeCardCvcElementChangeEvent;
  postalCode: string;
}

interface iProps {
  data: iPaymentData;
  title?: string;
  onChangeField: (key: string) => (val: any) => void;
  isValidationShown: boolean;
  price?: number;
  isPaymentFailed?: boolean;
  paymentError?: StripeError;
}

export function getDefaultPaymentData(): iPaymentData {
  return {
    cardNumber: {
      brand: 'unknown',
      complete: false,
      elementType: 'cardNumber',
      empty: true,
      error: undefined
    },
    expiry: {
      complete: false,
      elementType: 'cardExpiry',
      empty: true,
      error: undefined
    },
    cvc: {
      complete: false,
      elementType: 'cardCvc',
      empty: true,
      error: undefined
    },
    postalCode: ''
  };
}

enum FieldsIds {
  card = 'card',
  cvc = 'cvc',
  expiry = 'expiry',
  postalCode = 'postal-code'
}

const PaymentDetailsNew: React.FC<iProps> = ({
  data,
  title = 'Pay by card',
  onChangeField,
  isValidationShown,
  isPaymentFailed,
  paymentError
}) => {
  const handleChangeField = (field: string) => (
    nextElementSelector: string
  ) => (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    if (
      (data.cardNumber.brand !== 'amex' || event.elementType !== 'cardCvc') &&
      event.complete &&
      !event.error &&
      !iOS()
    ) {
      (document.querySelector(nextElementSelector) as HTMLElement)?.focus();
    }
    onChangeField(field)(event);
  };

  useEffect(() => {
    if (iOS()) {
      disableTabIndex(`#${FieldsIds.card} input`);
      disableTabIndex(`#${FieldsIds.expiry} input`);
      disableTabIndex(`#${FieldsIds.cvc} input`);
      disableTabIndex(`#${FieldsIds.postalCode}`);
    }
  }, []);

  return (
    <div className='PaymentDetailsNew'>
      <div className='PaymentDetailsNew__title'>{title}</div>
      <div className='PaymentDetailsNew__form'>
        <div className='row'>
          <FormInput
            label=''
            value=''
            className='col mt-0'
            inputClassName='FormInput__stripeElement PaymentDetailsNew__card'
            handleChange={() => null}
            isInvalid={
              (isValidationShown && !isInputValid('cardNumber', data)) ||
              !!data.cardNumber.error
            }
          >
            <CardNumberElement
              options={customStylesForInput}
              id={FieldsIds.card}
              onChange={handleChangeField('cardNumber')(
                `#${FieldsIds.expiry} input`
              )}
            />
          </FormInput>
        </div>
        <div className='row'>
          <FormInput
            label=''
            value=''
            className='col-4 mt-0'
            inputClassName='FormInput__stripeElement PaymentDetailsNew__date'
            handleChange={() => null}
            isInvalid={
              (isValidationShown && !isInputValid('expiry', data)) ||
              !!data.expiry.error
            }
          >
            <CardExpiryElement
              options={customStylesForInput}
              id={FieldsIds.expiry}
              onChange={handleChangeField('expiry')(`#${FieldsIds.cvc} input`)}
            />
          </FormInput>
          <FormInput
            label=''
            value=''
            className='col-4 mt-0'
            inputClassName='FormInput__stripeElement PaymentDetailsNew__cvc'
            handleChange={() => null}
            isInvalid={
              (isValidationShown && !isInputValid('cvc', data)) ||
              !!data.cvc.error
            }
          >
            <CardCvcElement
              options={customStylesForInput}
              id={FieldsIds.cvc}
              onChange={handleChangeField('cvc')(`#${FieldsIds.postalCode}`)}
            />
          </FormInput>
          <FormInput
            id={FieldsIds.postalCode}
            name='postalCode'
            label=''
            value={data.postalCode}
            className='col-4 mt-0'
            inputClassName='FormInput__stripeElement PaymentDetailsNew__zip'
            placeholder='Zip code'
            isInvalid={isValidationShown && !isInputValid('postalCode', data)}
            handleChange={onChangeField('postalCode')}
          />
        </div>
      </div>

      <ErrorMessage
        isVisible={
          (isValidationShown && !isTheDataValid(data)) ||
          !!data.cardNumber.error ||
          !!data.cvc.error ||
          !!data.expiry.error ||
          (isValidationShown && isPaymentFailed)
        }
        style={{
          position: 'relative'
        }}
      >
        {(isPaymentFailed && paymentError.message) || renderError(data)}
      </ErrorMessage>
    </div>
  );
};

const disableTabIndex = (selector: string) => {
  (document.querySelector(selector) as HTMLElement).setAttribute(
    'tabindex',
    '-1'
  );
};

export default PaymentDetailsNew;
