import React from 'react';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import Button from '../../components/Button/Button';
import TilePage from '../../components/TilePage/TilePage';
import { CoachRoutesEnum } from '../../core/enums';

export default function Home() {
  return (
    <TilePage title="Home">
      <ModalHeader>
        <h2>Landing page</h2>
      </ModalHeader>
      <br />
      <nav
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div>
          <Button variations={['gray']} link={CoachRoutesEnum.SignIn}>
            sign in
          </Button>
          <br />
        </div>
        <div>
          <Button
            variations={['gray']}
            link={`${CoachRoutesEnum.SignUp}?pricingPlanId=1`}
          >
            sign up free + 40% fee
          </Button>
          <br />
        </div>
        <div>
          <Button variations={['gray']} link={`${CoachRoutesEnum.SignUp}`}>
            sign up subscription 150 + 0% fee
          </Button>
          <br />
        </div>
        <div>
          <Button
            variations={['gray']}
            link={`${CoachRoutesEnum.SignUp}?pricingPlanId=3`}
          >
            sign up subscription 49.97 + 20% fee
          </Button>
          <br />
        </div>
        <div>
          <Button variations={['gray']} link={CoachRoutesEnum.ForgotPassword}>
            forgot password
          </Button>
          <br />
        </div>
        <div>
          <Button variations={['gray']} link={CoachRoutesEnum.ResetPassword}>
            new password
          </Button>
          <br />
        </div>
      </nav>
    </TilePage>
  );
}
