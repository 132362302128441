import React, { ReactNode } from 'react';

import Page from '../../containers/Page/Page';

import './TilePage.styles.scss';
import { Link } from 'react-router-dom';
// import LogoImage from '../../images/logo.svg';

interface iProps {
  children: ReactNode;
  className?: string;
  title?: string;
  tileStyle?: object;
}

const TilePage: React.FC<iProps> = ({
  children,
  className = '',
  title = '',
  tileStyle = {}
}) => {
  return (
    <Page className={`TilePage ${className}`} title={title}>
      <Link to='/' className='TilePage__logo'>
        {/* <LogoImage /> */}
      </Link>
      <div className='Tile' style={tileStyle}>
        {children}
      </div>
    </Page>
  );
};

export default TilePage;
