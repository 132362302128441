import axios, { AxiosResponse } from 'axios';
import {
  SignInInfoViewModel,
  SignupCoachAdditionalInfoViewModel,
  SignUpCoachViewModel,
  UserViewModel
} from '../core/backend/api';
import { action, observable, computed } from 'mobx';
import { UserStore } from '../core/types';
import getGlobal from '../core/globals';
import { iContactsStore } from './ContactsStore';
import { signInCoach, signOutCoach } from '../core/api';
import { getTimezone } from '../core/helpers';

const TIMEZONE_DIFF_KEY = 'coachpro.timezone-diff-timestamp';
const GOOGLE_CALENDAR_CONNECTION_KEY = 'coachpro.google-connection';

export default class CoachUserStore extends UserStore {
  @observable isAdmin = false;

  @observable isZoomConnected: boolean = false;
  @observable zoomUserEmail: string = '';
  @observable isStripeConnected: boolean = false;
  @observable wasStripeConnected: boolean = false;
  @observable stripeUser: string = '';
  @observable isGoogleConnected: boolean = false;
  @observable isGoogleWasDisconnected: boolean = false;
  @observable googleUser: string = '';
  constructor(private contactsStore: iContactsStore) {
    super();
    this.isSignedIn = false;
    // this.isSignedIn = true;
    // this.loadData();
  }

  get lsTimeZoneLastCheckTimestampKey() {
    return `${TIMEZONE_DIFF_KEY}.${this.id}`;
  }

  get lsGoogleCalendarConnectionKey() {
    return `${GOOGLE_CALENDAR_CONNECTION_KEY}.${this.id}`;
  }

  @action updateData(data: Partial<CoachUserStore>) {
    Object.assign(this, data);
    if (data.timeZone) {
      this.checkTimeZoneDiff();
    }
    if (data.isGoogleConnected !== undefined) {
      if (data.isGoogleConnected) {
        this.checkGoogleConnection();
      } else {
        this.resetGoogleConnection();
      }
    }
  }

  @action signIn(data: SignInInfoViewModel) {
    return new Promise((resolve, reject) => {
      signInCoach(data)
        .then(() => {
          this.isSignedIn = false;
          throw new Error('Something went wrong. Please contact support team');
          // this.isSignedIn = true;
          // this.loadData();
          // resolve('OK');
        })
        .catch((error) => {
          reject(error || 'Sign in failed, please check your credentials');
        });
    });
  }

  @action signOut = () => {
    return new Promise((resolve, reject) => {
      signOutCoach()
        .then(() => {
          this.isSignedIn = false;
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action signUp(data: SignUpCoachViewModel) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${getGlobal('api')}/Auth/SignUpCoach`, data)
        .then(() => {
          this.isSignedIn = true;
          this.loadData();
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action completeRegistration(data: SignupCoachAdditionalInfoViewModel) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${getGlobal('api')}/Auth/AddAdditionalInfoToCoach`, data)
        .then((response) => {
          this.loadData();
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action loadData() {
    this.isDataLoaded = false;
    this.reloadData()
      .then(async () => {
        await this.contactsStore.getCoachContacts();
        this.isDataLoaded = true;
      })
      .catch((error) => {
        this.isSignedIn = false;
      });
  }

  @action reloadData() {
    return axios
      .get(`${getGlobal('api')}/Auth/GetUserInfo`)
      .then((response: AxiosResponse<UserViewModel>) => {
        this.isRegistrationCompleted = response.data.registrationCompleted;
        this.isAdmin = response.data.isAdmin;
        this.email = response.data.email!;
        this.firstName = response.data.firstName || '';
        this.lastName = response.data.lastName || '';
        this.siteName = response.data.siteName || '';
        this.timeZone = getTimezone(response.data.timeZone);
        this.country = response.data.country || '';
        this.state = response.data.state || '';
        this.city = response.data.city || '';
        this.phoneNumber = response.data.phoneNumber!;
        this.isStripeConnected = response.data.isStripeConnected;
        this.wasStripeConnected = response.data.wasStripeConnected;
        this.stripeUser = response.data.stripeUser || '';
        this.id = response.data.id;
        this.imageId = response.data.imageId;
        this.isZoomConnected = response.data.isZoomConnected;
        this.zoomUserEmail = response.data.zoomUserEmail || '';
        this.isGoogleConnected = response.data.isGoogleConnected;
        this.googleUser = response.data.googleUser;
        this.checkTimeZoneDiff();
        this.checkGoogleConnection();
        return response;
      });
  }

  @action checkGoogleConnection = () => {
    if (this.isGoogleConnected) {
      localStorage.setItem(this.lsGoogleCalendarConnectionKey, 'true');
      this.isGoogleWasDisconnected = false;
    } else {
      const storeValue = localStorage.getItem(
        this.lsGoogleCalendarConnectionKey
      );
      if (storeValue === 'true') {
        this.isGoogleWasDisconnected = true;
      }
    }
  };

  @action resetGoogleConnection = () => {
    this.isGoogleWasDisconnected = false;
    localStorage.removeItem(this.lsGoogleCalendarConnectionKey);
  };
}
