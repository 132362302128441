import { Appointment } from '../../../core/types';
import { EventStatus, LocationTypeEnum } from '../../../core/enums';
import { getMilliseconds } from '../../../core/helpers';

export const subTitles = [
  '',
  'Choose Appointment block',
  'Choose Date & Time',
  'Invite clients',
  'Summary'
];

export const width_options = [
  0,
  1180,
  746, // 910,
  854,
  600
];

export function getDefaultData(): Appointment {
  return {
    id: 0,
    appointmentTypeId: 0,
    price: '0',
    timestampUtc: 0,
    timeMs: -1,
    invites: [],
    appointmentStatus: EventStatus.Active,
    members: [],
    remindBefore: getMilliseconds(1, 'hours'),
    locationType: LocationTypeEnum.Custom,
    location: '',
    duration: 0
  };
}
