import React from 'react';
import MobilePhoneIcon from '../../icons/mobile-phone.svg';
import RepeatIcon from '../../icons/repeat.svg';
import './RotateWarning.styles.scss';

const RotateWarning = () => {
  return (
    <div className='RotateWarning'>
      <div className='RotateWarning__icons'>
        <div className='RotateWarning__icon'>
          <RepeatIcon />
        </div>
        <div className='RotateWarning__animation'>
          <MobilePhoneIcon />
        </div>
      </div>
      <p>Please rotate your device</p>
      <p className='RotateWarning__description'>
        This page is best viewed in Portrait orientation
      </p>
    </div>
  );
};

export default RotateWarning;
