import React from 'react';
import './RequestTile.scss';
import ClockIcon from '../../icons/clock.svg';
import OneOnOneIcon from '../../icons/one-on-one.svg';
import GroupIcon from '../../icons/group.svg';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { getFormattedDuration, normalizePrice } from '../../core/helpers';

type MeetingType = 'single' | 'group';

interface iProps {
  className?: string;
  color?: string;
  title: string;
  duration: number;
  meetingType: MeetingType;
  price: number;
  handleEdit: () => void;
  handleRemove: () => void;
}

const RequestTile: React.FC<iProps> = ({
  className = '',
  color = '#000000',
  title,
  duration,
  meetingType,
  price,
  handleEdit,
  handleRemove
}) => {
  const menuList = [
    {
      name: 'Edit',
      callback: handleEdit
    },
    {
      name: 'Remove',
      callback: handleRemove
    },
    {
      name: 'Reschedule',
      callback: () => {
        console.log('Cancel clicked');
      }
    }
  ];

  const renderTypeIcon = () => {
    switch (meetingType) {
      case 'group':
        return <GroupIcon />;
      default:
        return <OneOnOneIcon />;
    }
  };

  return (
    <div
      className={`RequestTile ${className}`}
      style={{ borderLeftColor: color }}
    >
      <DropdownMenu list={menuList} className='DropdownMenu--RequestTile' />
      <div className='RequestTile__name' title={title}>
        {title}
      </div>
      <div
        className='RequestTile__type'
        style={{ color: color, backgroundColor: 'rgba(187, 107, 217, 0.2)' }}
      >
        Appointment request
      </div>
      <div className='RequestTile__row'>
        <div className='RequestTile__date'>{'20 Apr, 11:00 AM'}</div>
        <div className='RequestTile__duration'>
          <ClockIcon />
          <span className='RequestTile__time-text'>
            {getFormattedDuration(duration)}
          </span>
        </div>
      </div>

      <div className='RequestTile__author'>by {'Amanda'}</div>
      <div className='RequestTile__tiles'>
        <div className='RequestTile__tile RequestTile__tile--group'>
          {renderTypeIcon()}
          {meetingType === 'single' ? 'One-on-One' : 'Group'}
        </div>
        <div className='RequestTile__tile RequestTile__tile--price'>
          {price ? `$ ${normalizePrice(price)}` : 'Free'}
        </div>
      </div>
    </div>
  );
};

export default RequestTile;
