import React, { useState } from 'react';
import './ForgotPassword.styles.scss';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { CoachRoutesEnum } from '../../core/enums';
import {
  sendForgotPasswordRequest,
  sendClientForgotPasswordRequest
} from '../../core/api';
import { isEmail, isNotEmpty } from '../../core/formValidation';
import CheckedIcon from '../../icons/huge-checked.svg';
import Loader from '../../components/Loader/Loader';

const ForgotPassword: React.FC<{ isClient?: boolean }> = ({
  isClient = false
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const isInputValueValid = isNotEmpty(inputValue) && isEmail(inputValue);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isInputValueValid) {
      setIsLoading(true);
      const sendRequest = isClient
        ? sendClientForgotPasswordRequest
        : sendForgotPasswordRequest;
      sendRequest({
        email: inputValue
      })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          setRequestError(true);
          setIsValidationShown(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  const renderForm = () => {
    return (
      <>
        <ModalHeader>
          <h2>Forgot password</h2>
          <h4>
            Enter your email below <br />
            to receive a password reset link
          </h4>
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <FormInput
            label='Email'
            value={inputValue}
            isInvalid={
              (isValidationShown && !isInputValueValid) || requestError
            }
            errorMessage={
              isValidationShown && !isInputValueValid
                ? 'Please enter correct email'
                : `A user with an email ${
                    inputValue.length < 29
                      ? inputValue
                      : inputValue.substring(0, 29) + '...'
                  } does not exist`
            }
            handleChange={(value: string) => {
              setInputValue(value.trim());
              setIsValidationShown(false);
              setRequestError(false);
            }}
          />
          <Button className='button__full-width mt-4'>Send instructions</Button>
        </form>
        {/* {!isClient ? (
          <div className='ForgotPassword__footer-text'>
            Don’t have an account?{' '}
            <Link to={CoachRoutesEnum.SignUp} className='ForgotPassword__link'>
              Sign Up
            </Link>
          </div>
        ) : null} */}
      </>
    );
  };

  const renderSuccessView = () => {
    return (
      <>
        <div className='ForgotPassword__success-title'>
          Instructions have been sent. <br />
          Check your mail please
        </div>
        <div className='ForgotPassword__icon'>
          <CheckedIcon />
        </div>
        {!isClient ? (
          <div className='ForgotPassword__footer-text'>
            Back to{' '}
            <Link to={CoachRoutesEnum.SignIn} className='ForgotPassword__link'>
              Sign in
            </Link>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className='ForgotPassword'>
      {isLoading && <Loader />}
      {isSuccess ? renderSuccessView() : renderForm()}
    </div>
  );
};

export default ForgotPassword;
