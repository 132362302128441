import { TableColumn, TableColumnType } from '../../components/Table/Table';
import { getTimeInZone } from '../../core/helpers';
import RootStore from '../../store/RootStore';
import moment from 'moment';

export interface UserItem {
  address: string;
  fullName: string;
  userId: string;
  email: string;
  plan: string | number;
  createdOn?: number | string;
  siteName: string;
}

export const columns: TableColumn<UserItem>[] = [
  /*   {
    field: 'userId',
    title: 'ID',
    type: TableColumnType.String,
    width: 220
  }, */
  {
    field: 'fullName',
    title: 'User Name',
    type: TableColumnType.Link,
    searchable: true,
    minWidth: 180,
    maxWidth: '3fr'
  },
  {
    field: 'email',
    title: 'Email',
    type: TableColumnType.String,
    searchable: true,
    minWidth: 180,
    maxWidth: '4fr'
  },
  {
    field: 'plan',
    title: 'Plan',
    type: TableColumnType.String,
    minWidth: 180,
    maxWidth: 'max-content'
  },
  {
    field: 'createdOn',
    title: 'Signup Date',
    type: TableColumnType.String,
    sortable: true,
    formatter: (v: string | number) => {
      if (!v) {
        return '';
      }
      if (typeof v === 'string') {
        return v;
      }
      return moment
        .utc(getTimeInZone(v, RootStore.userStore.timeZone))
        .format('MMMM DD, YYYY');
    },
    minWidth: 168,
    maxWidth: 'max-content'
  }
];

