import React, { useState, useEffect } from 'react';
import FormInput from '../FormInput/FormInput';
import SearchIcon from '../../icons/search.svg';
import cn from 'classnames';
import './SearchInput.styles.scss';
import { useMedia } from '../../hooks';

interface Props {
  className?: string;
  searchText: string;
  onSearch: (value: string) => void;
  collapsible?: boolean;
}

const SearchInput: React.FC<Props> = ({
  className = '',
  searchText,
  collapsible = false,
  onSearch
}) => {
  const [isOpened, setIsOpened] = useState(!!searchText);
  const isTablet = useMedia(['(min-width: 576px)'], [true], false);

  const handleClick = () => {
    if (collapsible && !isTablet) {
      setIsOpened(true);
      (document.querySelector(
        '.SearchInput input'
      ) as HTMLInputElement)?.focus();
    }
  };

  const handleBlur = () => {
    if (collapsible && !isTablet && !searchText) setIsOpened(false);
  };

  useEffect(() => {
    if (!!searchText && collapsible && !isTablet) {
      setIsOpened(true);
    }
  }, [collapsible, isTablet, searchText]);

  return (
    <div
      className={cn('SearchInput', className, {
        'SearchInput--collapsible': collapsible,
        'SearchInput--collapsible--shown': collapsible && isOpened
      })}
      onClick={handleClick}
    >
      <FormInput
        className='mt-0'
        name='search'
        label=''
        value={searchText}
        handleChange={onSearch}
        placeholder={(isOpened && collapsible) || isTablet ? 'Search' : ''}
        inputClassName='SearchInput__input'
        onBlur={handleBlur}
      />
      <div className='SearchInput__icon'>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
