import { useState } from 'react';
import { useDebounce } from '../../hooks';

export const useSearchInput = <T>(
  searchableArray: T[],
  filterFunction: (searchText: string, file: T) => boolean
) => {
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchString = useDebounce(searchText, 300);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const searchedItems = searchableArray.filter(
    filterFunction.bind(null, debouncedSearchString)
  );

  return { searchText, handleSearch, searchedItems };
};
