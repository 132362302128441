import React, { CSSProperties, ReactNode, useState, useEffect } from 'react';
import './SuperAdminWrapper.styles.scss';
import SuperAdminHeader from './SuperAdminHeader';
import SideMenu from '../../components/SideMenu/SideMenu';
import Page from '../Page/Page';
import { useMedia } from '../../hooks';
import cn from 'classnames';
import { SuperAdminRoutesEnum } from '../../core/enums';

interface Props {
  children: ReactNode;
  className?: string;
  title?: string;
  style?: CSSProperties;
}

const SuperAdminWrapper: React.FC<Props> = ({
  children,
  className = '',
  style
}) => {
  const isTablet = useMedia(['(min-width: 992px)'], [false], true);
  const [isSideMenuOpened, setIsSideMenuOpened] = useState<boolean>(!isTablet);

  const handleSideMenuClose = () => {
    setIsSideMenuOpened(false);
  };

  const handleSideMenuToggle = () => {
    setIsSideMenuOpened(!isSideMenuOpened);
  };

  useEffect(() => {
    setIsSideMenuOpened(!isTablet);
  }, [isTablet]);

  return (
    <Page
      className={cn('SuperAdminWrapper', className, {
        'Page--collapsed': isSideMenuOpened && !isTablet
      })}
      title='Admin'
      style={style}
    >
      <SuperAdminHeader
        isSideMenuOpened={isSideMenuOpened}
        onSideMenuToggle={handleSideMenuToggle}
      />
      <SideMenu
        className='SideMenu--dark'
        menuList={[
          {
            name: 'Users',
            iconName: 'MenuUsersIcon',
            path: SuperAdminRoutesEnum.Users
          }
        ]}
        isOpened={isSideMenuOpened}
        isTablet={isTablet}
        onClose={handleSideMenuClose}
        withHelpButton={false}
      />
      <div className={`SuperAdminWrapper__content p-md-4 p-sm-3 p-2`}>
        <div className='SuperAdminWrapper'>{children}</div>
      </div>
    </Page>
  );
};

export default SuperAdminWrapper;
