import React, { useState, useEffect } from 'react';
import { AppointmentBlock } from '../../core/types';
import AppointmentBlockTile from '../../components/AppointmentBlockTile/AppointmentBlockTile';
import CoachSchedulePage from '../../containers/CoachSchedulePage/CoachSchedulePage';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  deleteAppointmentBlock,
  getAppointmentBlocks,
  updateAppointmentBlock
} from '../../core/api';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import getGlobal from '../../core/globals';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { CoachRoutesEnum, LocationTypeEnum } from '../../core/enums';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyImg from '../../images/empty-appointment-blocks.svg';
import { useStores } from '../../hooks';
import Warning from '../../components/Warning/Warning';
import PageHeaderContent from '../../components/PageHeaderContent/PageHeaderContent';
import Button from '../../components/Button/Button';

const CoachAppointmentBlocks = () => {
  const { rootStore } = useStores();
  const { incrementChangesCounter, notificationStore, userStore } = rootStore;

  const history = useHistory();
  const [appointmentBlocks, setAppointmentBlocks] = useState<
    AppointmentBlock[]
  >([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<AppointmentBlock>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    setIsLoading(true);
    getAppointmentBlocks().then((data) => {
      setAppointmentBlocks(data);
      setIsLoading(false);
    });
  };

  const handlePrivateButtonClick = (itemId: number) => {
    const item = appointmentBlocks.find(({ id }) => id === itemId);
    if (item) {
      updateAppointmentBlock({
        ...item,
        isPrivate: !item.isPrivate
      }).then(() => {
        incrementChangesCounter();
        notificationStore.addNotification({
          text: 'Appointment block was updated'
        });
      });
    }
  };

  const handleEditButtonClick = (itemId: number) => {
    history.push(
      `${CoachRoutesEnum.AppointmentBlockDetails}`.replace(
        ':blockId',
        `${itemId}`
      )
    );
  };

  const handleRemoveButtonClick = (itemId: number) => {
    setItemToDelete(appointmentBlocks.find((ab) => ab.id === itemId));
    setIsConfirmModalOpened(true);
  };

  const handleConfirmationClose = () => {
    setItemToDelete(undefined);
    setIsConfirmModalOpened(false);
  };

  const handleConfirmDelete = () => {
    deleteAppointmentBlock(itemToDelete!.id as number).then(reFetchData);
    setItemToDelete(undefined);
    setIsConfirmModalOpened(false);
    notificationStore.addNotification({
      text: 'Appointment block was deleted'
    });
  };

  const haveInvalidBlocks = appointmentBlocks.some(
    (ab) => ab.locationType === LocationTypeEnum.Custom && !ab.location
  );

  return (
    <CoachSchedulePage title='Appointment Blocks'>
      <PageHeaderContent>
        <Button
          variations={['xs-width', 'floating-button']}
          link={CoachRoutesEnum.New}
          component='Link'
        >
          <span className='button__floating-button-plus'>+</span>Schedule
        </Button>
      </PageHeaderContent>
      <GlobalLoader isLoading={isLoading} />
      <div className='AppointmentBlocks mt-sm-4 mt-3'>
        {haveInvalidBlocks && (
          <Warning className='mb-3'>
            These appointment blocks don't have any location set up. Please, add
            the location to let your clients know where your appointments will
            happen.
          </Warning>
        )}
        <div className='row mx-n2'>
          <div className='col-xl-4 col-sm-6 px-2 mb-3'>
            <Link
              to={CoachRoutesEnum.AppointmentBlockNew}
              className='AppointmentBlockTile AppointmentBlockTile--add-new'
            >
              + Add new
            </Link>
          </div>
          {appointmentBlocks.map((item) => (
            <div key={item.id} className='col-xl-4 col-sm-6 px-2 mb-3'>
              <AppointmentBlockTile
                handlePrivate={() =>
                  handlePrivateButtonClick(item.id as number)
                }
                handleEdit={() => handleEditButtonClick(item.id as number)}
                handleRemove={() => handleRemoveButtonClick(item.id as number)}
                isInvalid={
                  item.locationType === LocationTypeEnum.Custom &&
                  !item.location
                }
                title={item.name}
                duration={item.durationMinutes}
                price={+item.price}
                meetingType={item.membersCountLimit === 1 ? 'single' : 'group'}
                isPrivate={item.isPrivate}
                link={`${getGlobal('domain')}/${userStore.siteName}/${
                  item.link
                }`}
              />
            </div>
          ))}
        </div>
        {!appointmentBlocks.length && !isLoading && (
          <div className='row mx-n2 mt-5'>
            <div className='col-xl-4 offset-xl-4 offset-sm-3 col-sm-6 px-2'>
              <EmptyState
                icon={<EmptyImg />}
                text="You don't have appointment blocks yet."
              />
            </div>
          </div>
        )}
      </div>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={handleConfirmationClose}
        title={`Delete ${itemToDelete?.name || ''}`}
        text='Are you sure that you want to delete it?'
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={handleConfirmDelete}
        confirmBtnClass='button__confirm-delete'
      />
    </CoachSchedulePage>
  );
};

export default observer(CoachAppointmentBlocks);
