import {
  BookingItemColorsEnum,
  EventStatus,
  LocationTypeEnum
} from '../../core/enums';
import { BookingItemTypeEnum, WelcomeMessageData } from '../../core/types';

export const getEmptyData = () => {
  const data: WelcomeMessageData = {
    welcomeText: 'Welcome to your Client Portal',
    message:
      'Here is where you can find information on upcoming appointments, access your library content, and schedule new meetings.'
  };

  return data;
};

export const appointments = Array(4)
  .fill(0)
  .map((_, index) => ({
    id: index,
    name: 'Sample appointment block',
    timestamp: 0,
    durationMinutes: (index + 1) * 15,
    color: BookingItemColorsEnum.Appointment,
    hasPrice: false,
    price: 0,
    type: BookingItemTypeEnum.AppointmentBlock,
    description: 'Description will go here... Description will go here...',
    locationType: LocationTypeEnum.Custom,
    location: '',
    link: '',
    status: EventStatus.Active,
    membersCountLimit: 12,
    terms: '',
    remindBeforeMs: 15000,
    imageId: '',
    thumbnailImageId: ''
  }));

export const meetings = [
  {
    id: 100,
    name: 'Sample meeting',
    timestamp: 0,
    durationMinutes: 60,
    color: BookingItemColorsEnum.Meeting,
    hasPrice: false,
    price: 0,
    type: BookingItemTypeEnum.Meeting,
    description: '',
    location: '',
    link: '',
    locationType: LocationTypeEnum.Custom,
    status: EventStatus.Active,
    membersCountLimit: 12,
    terms: '',
    remindBeforeMs: 15000,
    thumbnailImageId: ''
  }
];

export const menuList = [
  {
    name: 'Dashboard',
    iconName: 'MenuDashboardIcon',
    path: '#'
  },
  {
    name: 'Schedule',
    iconName: 'MenuScheduleIcon',
    path: '#'
  },
  {
    name: 'Payments',
    iconName: 'MenuInvoicingIcon',
    path: '#'
  },
  {
    name: 'Invoices',
    path: '#'
  },
  {
    name: 'Library',
    iconName: 'MenuTrainingIcon',
    path: '#'
  }
];
