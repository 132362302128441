import React, { CSSProperties, FC } from 'react';
import './EmptyState.styles.scss';
import EmptyImage from '../../images/empty-calendar.svg';

interface Props {
  icon?: JSX.Element;
  size?: 'lg' | 'md' | 'sm';
  text?: string;
  className?: string;
  absoluteCenter?: boolean;
  style?: CSSProperties;
  children?: JSX.Element;
}

const EmptyState: FC<Props> = ({
  icon,
  size = 'lg',
  text = 'You have no meetings and appointments yet',
  className = '',
  absoluteCenter = false,
  style,
  children
}) => {
  icon = icon || <EmptyImage />;
  return (
    <div
      className={`EmptyState EmptyState__${size} ${className} ${
        absoluteCenter ? 'EmptyState--absoluteCenter' : ''
      } d-flex flex-column align-items-center justify-content-center`}
      style={style}
    >
      <div className='p-2'>{icon}</div>
      <p className='mt-3'>{text}</p>
      {children}
    </div>
  );
};

export default EmptyState;
