import { iFormConfigItem, CoachEvent, Checker } from '../../core/types';
import { EventStatus, LocationTypeEnum } from '../../core/enums';
import {
  isNotEmpty,
  isInputValid,
  getInputErrors,
  isDataValid,
  isMaxValueValid,
  isMinValueValid,
  isMaxlengthValid
} from '../../core/formValidation';
import { getMilliseconds } from '../../core/helpers';
import moment from 'moment';

export const MAX_NAME_LENGTH = 255;

const isNameShortEnough = isMaxlengthValid(MAX_NAME_LENGTH);

const FormConfig: iFormConfigItem[] = [
  { field: 'name', checkIsValid: [isNotEmpty, isNameShortEnough] },
  { field: 'location', checkIsValid: [isNotEmpty] },
  { field: 'link', checkIsValid: [isNotEmpty] },
  {
    field: 'price',
    checkIsValid: [isNotEmpty, isMinValueValid(0), isMaxValueValid(999999)]
  }
];

function getFormConfig(data: CoachEvent) {
  return FormConfig.filter(
    (c) => data.locationType !== LocationTypeEnum.Zoom || c.field !== 'location'
  );
}

export function isFormInputValid(inputField: string, data: CoachEvent) {
  return isInputValid(getFormConfig(data))(inputField, data);
}

export function isFormDataValid(data: CoachEvent) {
  return isDataValid(getFormConfig(data))(data);
}

export function getFormInputErrors(inputField: string, data: any) {
  return getInputErrors(getFormConfig(data), (checker: Checker) => {
    switch (checker) {
      case FormConfig.find((c) => c.field === 'price').checkIsValid[1]: {
        return 'minimum amount is 0';
      }
      case FormConfig.find((c) => c.field === 'price').checkIsValid[2]: {
        return 'maximum amount is 999 999';
      }
      case isNameShortEnough: {
        return `should have at most ${MAX_NAME_LENGTH} characters`;
      }
      default:
        return undefined;
    }
  })(inputField, data);
}

export function getDefaultData(date?: number): CoachEvent {
  const startDate = date || Date.now();
  const addDays = date ? 0 : 1;
  return {
    id: 0,
    name: '',
    location: '',
    description: '',
    link: `${Date.now()}`,
    availableSpots: 1,
    startDateTimeStamp: moment
      .utc(startDate)
      .startOf('day')
      .add(addDays, 'day')
      .valueOf(),
    endDateTimeStamp: moment
      .utc(startDate)
      .startOf('day')
      .add(addDays, 'day')
      .valueOf(),
    startTimeMs: getMilliseconds(9, 'hours'),
    endTimeMs: getMilliseconds(10, 'hours'),
    invites: [],
    price: '0',
    coachEventStatus: EventStatus.Active,
    members: [],
    remindBeforeMs: getMilliseconds(1, 'hours'),
    isPrivate: false,
    isScheduled: true,
    imageId: '',
    thumbnailImageId: '',
    croppedArea: '',
    locationType: LocationTypeEnum.Custom,
    showLocationWhileBooking: true
  };
}

export function getOffsetTop(element: HTMLElement, parent: HTMLElement) {
  let offsetTop = 0;
  while (element && element !== parent) {
    offsetTop += element.offsetTop;
    element = element.offsetParent as HTMLElement;
  }
  return offsetTop;
}

export const buffers = [
  {
    id: '1',
    value: 0,
    name: '0 min'
  },
  {
    id: '2',
    value: getMilliseconds(5, 'minutes'),
    name: '5 min'
  },
  {
    id: '3',
    value: getMilliseconds(10, 'minutes'),
    name: '10 min'
  },
  {
    id: '4',
    value: getMilliseconds(15, 'minutes'),
    name: '15 min'
  },
  {
    id: '5',
    value: getMilliseconds(30, 'minutes'),
    name: '30 min'
  }
];
export const reminders = [
  {
    id: '1',
    value: 0,
    name: 'Not set'
  },
  {
    id: '2',
    value: getMilliseconds(5, 'minutes'),
    name: '5 min'
  },
  {
    id: '3',
    value: getMilliseconds(15, 'minutes'),
    name: '15 min'
  },
  {
    id: '4',
    value: getMilliseconds(30, 'minutes'),
    name: '30 min'
  },
  {
    id: '5',
    value: getMilliseconds(60, 'minutes'),
    name: '1 hour'
  }
];
