import React, { useEffect, useState } from 'react';
import { useStores } from '../../hooks';
import Button from '../Button/Button';
import ArrowBack from '../../icons/arrow-left.svg';
import InvoiceCardClientBlock from '../InvoiceCardClientBlock/InvoiceCardClientBlock';
import {
  Invoice,
  StripeSubscription,
  StripeInvoice,
  StripePaymentMethod
} from '../../core/types';
import PaymentDetailsNew, {
  getDefaultPaymentData,
  iPaymentData
} from '../PaymentDetailsNew/PaymentDetailsNew';
import { isTheDataValid as isPaymentDataValid } from '../PaymentDetails/PaymentDetails.helpers';
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  clientPayInvoice,
  clientRestoreUnpaidInvoice,
  createInvoiceSubscription,
  updateInvoiceSubscription
} from '../../core/api';
import './ClientPayInvoiceWizard.styles.scss';
import SucceedIcon from '../../icons/succeed.svg';
import { normalizePrice } from '../../core/helpers';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { StripeError } from '@stripe/stripe-js';
import {
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import Badge from '../Badge/Badge';

interface iProps {
  invoiceData: Invoice;
  email: string;
  onClose: () => void;
  onReload: () => void;
  className?: string;
}

const ClientPayInvoiceWizard: React.FC<iProps> = ({
  invoiceData,
  email,
  onClose,
  onReload,
  className = '',
  children
}) => {
  const { rootStore } = useStores();
  const { userStore, notificationStore, coachInfo } = rootStore;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [userSecret, setUserSecret] = useState<string>('');
  const [stripeSubscription, setStripeSubscription] = useState<
    StripeSubscription
  >(null);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPrePaymentFailed, setIsPrePaymentFailed] = useState<boolean>(false);
  const [prePaymentError, setPrePaymentError] = useState<string>('');
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<StripeError>(null);
  const [isNotAvailable, setIsNotAvailable] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const isPaid = invoiceData?.status === InvoiceStatusEnum.paid;

  const [paymentData, setPaymentData] = useState<iPaymentData>(
    getDefaultPaymentData()
  );

  useEffect(() => {
    setIsFormValid(isPaymentDataValid(paymentData));
  }, [paymentData]);

  useEffect(() => {
    setIsNotAvailable(
      (invoiceData?.status !== InvoiceStatusEnum.open &&
        invoiceData?.status !== InvoiceStatusEnum.late &&
        (invoiceData?.type === InvoiceTypeEnum.Recurring ||
          invoiceData?.status !== InvoiceStatusEnum.uncollectible)) ||
        !coachInfo.isStripeConnected
    );

    if (invoiceData?.subscription) {
      setStripeSubscription(invoiceData.subscription);
    }
  }, [invoiceData]);

  const handleChangePaymentField = (field: string) => (value: any) => {
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [field]: value
    }));
  };

  const payForOneTimeInvoice = async () => {
    let clientSecret = userSecret;

    setIsSubmitting(true);
    if (!clientSecret) {
      try {
        clientSecret = await clientPayInvoice(
          invoiceData.id,
          userStore.isSignedIn ? null : invoiceData.client.clientId
        );
        setUserSecret(clientSecret);
      } catch (error) {
        setIsSubmitting(false);
        setIsPrePaymentFailed(true);
        if (error.status === 400) {
          setIsNotAvailable(true);
          notificationStore.addNotification({
            type: NotificationTypesEnum.Error,
            text: `Invoice ${invoiceData.number}`,
            textColored: (
              <>
                You are unable to pay for this invoice.
                <br /> Please, contact your coach at{' '}
                <a className='link' href={`mailto:${coachInfo?.supportEmail}`}>
                  {coachInfo?.supportEmail}
                </a>
              </>
            ),
            duration: 8000
          });
        } else if (error.errorMessage) {
          setPrePaymentError(error.errorMessage[0]);
        } else {
          setPrePaymentError('Something went wrong!');
        }
        return error;
      }

      if (!clientSecret) {
        setIsSubmitting(false);
        setIsPrePaymentFailed(true);
        setPrePaymentError('Something went wrong!');
        return;
      }
    }
    const stripeResponse = await stripe!.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements!.getElement(CardNumberElement)!,
        billing_details: {
          email: userStore.isSignedIn ? userStore.email : email,
          address: {
            postal_code: paymentData.postalCode
          }
        }
      }
    });
    if (
      stripeResponse.paymentIntent &&
      stripeResponse.paymentIntent.status === 'succeeded'
    ) {
      setIsSubmitting(false);
      setCurrentStep(2);
    } else if (stripeResponse.error) {
      await clientRestoreUnpaidInvoice(
        invoiceData.id,
        userStore.isSignedIn ? null : invoiceData.client.clientId
      );
      setUserSecret(null);
      if (
        stripeResponse.error.code === 'resource_missing' ||
        stripeResponse.error.code === 'account_invalid'
      ) {
        rootStore.getCoachInfo();
        setStripeError({ message: '' } as StripeError);
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text: `Invoice ${invoiceData.number}`,
          textColored: 'Something went wrong. Please, try again later!',
          duration: 3000
        });
      } else {
        setStripeError(stripeResponse.error);
      }
      setIsSubmitting(false);
      setIsPaymentFailed(true);
      setIsValidationShown(true);
    }
  };

  const subscribeForRecurringInvoice = async () => {
    setIsSubmitting(true);
    let subscription: StripeSubscription = stripeSubscription;
    let paymentMethod: StripePaymentMethod =
      stripeSubscription?.latest_invoice?.payment_intent?.payment_method;
    if (
      stripeSubscription &&
      stripeSubscription.latest_invoice.payment_intent.status ===
        'requires_action'
    ) {
      // jump to handlePaymentThatRequiresCustomerAction
    } else {
      try {
        const stripeResponse = await stripe.createPaymentMethod({
          type: 'card',
          card: elements!.getElement(CardNumberElement)!,
          billing_details: {
            email: userStore.isSignedIn ? userStore.email : email,
            address: {
              postal_code: paymentData.postalCode
            }
          }
        });
        if (stripeResponse.error) {
          if (stripeResponse.error.code === 'account_invalid') {
            rootStore.getCoachInfo();
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: `Invoice ${invoiceData.number}`,
              textColored: 'Something went wrong. Please, try again later!',
              duration: 3000
            });
          } else {
            setStripeError(stripeResponse.error);
          }
          setIsSubmitting(false);
          setIsPaymentFailed(true);
          setIsValidationShown(true);
          return;
        } else {
          paymentMethod = stripeResponse.paymentMethod as StripePaymentMethod;
          try {
            if (!stripeSubscription) {
              subscription = await createInvoiceSubscription(
                invoiceData.number,
                stripeResponse.paymentMethod.id,
                invoiceData.client.clientId
              );
            } else {
              subscription = await updateInvoiceSubscription(
                invoiceData.number,
                stripeResponse.paymentMethod.id,
                invoiceData.client.clientId
              );
            }
            setStripeSubscription(subscription);
          } catch (e) {
            setIsSubmitting(false);
            if (e.stripeError && e.stripeError.code === 'resource_missing') {
              rootStore.getCoachInfo();
              notificationStore.addNotification({
                type: NotificationTypesEnum.Error,
                text: `Invoice ${invoiceData.number}`,
                textColored: 'Something went wrong. Please, try again later!',
                duration: 3000
              });
            } else if (e.stripeError) {
              setStripeError(e.stripeError);
              setIsPaymentFailed(true);
              setIsValidationShown(true);
            } else if (e.status === 400) {
              setIsNotAvailable(true);
              notificationStore.addNotification({
                type: NotificationTypesEnum.Error,
                text: `Invoice ${invoiceData.number}`,
                textColored: (
                  <>
                    You are unable to pay for this invoice.
                    <br /> Please, contact your coach at{' '}
                    <a
                      className='link'
                      href={`mailto:${coachInfo?.supportEmail}`}
                    >
                      {coachInfo?.supportEmail}
                    </a>
                  </>
                ),
                duration: 8000
              });
            } else {
              onReload();
            }
            return;
          }
        }
      } catch (e) {
        setIsSubmitting(false);
        setStripeError({ message: 'Something went wrong!' } as StripeError);
        setIsPaymentFailed(true);
        setIsValidationShown(true);
        return;
      }
    }
    const result = await handlePaymentThatRequiresCustomerAction({
      subscription,
      paymentMethodId: paymentMethod.id,
      isRetry: !!stripeSubscription
    });
    subscription = result ? result.subscription : null;
    if (subscription && subscription.status === 'active') {
      setIsSubmitting(false);
      setCurrentStep(2);
    }
  };

  const handlePaymentThatRequiresCustomerAction = async ({
    subscription,
    invoice,
    paymentMethodId,
    isRetry
  }: {
    subscription: StripeSubscription;
    invoice?: StripeInvoice;
    paymentMethodId: string;
    isRetry?: boolean;
  }) => {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      try {
        const stripeResponse = await stripe.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId
          }
        );
        if (stripeResponse.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          if (stripeResponse.error.payment_intent) {
            setStripeSubscription({
              ...subscription,
              latest_invoice: {
                ...subscription.latest_invoice,
                payment_intent: stripeResponse.error.payment_intent as any
              }
            });
          }
          setIsSubmitting(false);
          setStripeError(stripeResponse.error);
          setIsPaymentFailed(true);
          setIsValidationShown(true);
          return;
        } else {
          if (stripeResponse.paymentIntent.status === 'succeeded') {
            setIsSubmitting(false);
            setCurrentStep(2);
            return {
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId
            };
          }
        }
      } catch (e) {
        console.error('Handle confirm Card Payment failed', e);
      }
    } else {
      // No customer action needed.
      return { subscription, paymentMethodId };
    }
  };

  const onSubmit = async () => {
    if (isSubmitting || isNotAvailable) {
      return;
    }
    if (isFormValid) {
      if (invoiceData.type === InvoiceTypeEnum.OneTime) {
        await payForOneTimeInvoice();
      } else {
        await subscribeForRecurringInvoice();
      }
    } else {
      setIsValidationShown(true);
    }
  };

  const steps = [
    null,
    <>
      <div className='d-flex align-items-center flex-wrap mb-3'>
        <p className='ClientPayInvoiceWizard__title mr-4 mb-2'>Pay invoice</p>
        {isNotAvailable && (
          <Badge
            className='mb-2'
            type='red'
            title={
              isPaid
                ? 'This invoice is already paid'
                : 'This invoice is unavailable'
            }
          />
        )}
      </div>
      <div className='ClientPayInvoiceWizard__invoice-info  mb-4'>
        <InvoiceCardClientBlock invoiceData={invoiceData} />
      </div>
      <ErrorMessage
        isVisible={isPrePaymentFailed}
        style={{
          position: 'relative',
          textAlign: 'center',
          width: '100%'
        }}
      >
        {prePaymentError}
      </ErrorMessage>
      {!isNotAvailable && (
        <PaymentDetailsNew
          data={paymentData}
          onChangeField={handleChangePaymentField}
          isValidationShown={isValidationShown}
          isPaymentFailed={isPaymentFailed}
          paymentError={stripeError}
        />
      )}

      <Button
        handleClick={onSubmit}
        variations={['full-width']}
        className='mt-1'
        disabled={isNotAvailable}
      >
        {isSubmitting
          ? 'Processing...'
          : `Pay $${normalizePrice(invoiceData?.amount)}`}
      </Button>
    </>,
    <div>
      <SucceedIcon />
      <p className='ClientPayInvoiceWizard__title mt-3 mb-2'>
        Processing transaction
      </p>
      <p className='ClientPayInvoiceWizard__text'>
        We need a bit of time to process the transaction and will update its
        status when done
      </p>
      <Button handleClick={onClose} variations={['full-width']}>
        Close
      </Button>
    </div>
  ];

  return (
    <>
      {invoiceData && (
        <div className={`ClientPayInvoiceWizard ${className}`}>
          {currentStep === 1 && (
            <Button
              className='mb-2'
              handleClick={onClose}
              variations={['naked', 'ClientInvoiceView']}
            >
              <ArrowBack />
              <span>Back</span>
            </Button>
          )}
          <div
            className={`ClientPayInvoiceWizard__body ${
              isNotAvailable ? 'ClientPayInvoiceWizard__body--gray' : ''
            }`}
          >
            {steps[currentStep]}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default ClientPayInvoiceWizard;
