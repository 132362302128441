import moment from 'moment';
import {
  AppointmentForScheduleViewModel,
  AppointmentTypeAvailabilityViewModel,
  AppointmentTypesForScheduleViewModel,
  AppointmentTypeViewModel,
  AppointmentViewModel,
  AvailabilityTimeSpanViewModel,
  CoachAvailabilitySettingsViewModel,
  CoachEventViewModel,
  EventForScheduleViewModel,
  GetAllBookedViewModel,
  GetAllWithInvitesViewModel,
  GetAllWithMembersViewModel,
  AppointmentFullViewModel,
  CoachAccountViewModel,
  LocationType,
  NoteViewModel,
  FileResponseViewModel,
  InvoiceViewModel,
  ClientAccountViewModel
} from './backend/api';
import {
  Appointment,
  AppointmentBlock,
  Availability,
  AvailabilityInterval,
  AvailabilitySettingsType,
  BookingItemTypeEnum,
  CoachEvent,
  iBookingItem,
  iCity,
  CoachClientScheduleItem,
  CoachContactItem,
  InvoiceTax,
  Payment
} from './types';
import {
  BookingItemColorsEnum,
  LocationTypeEnum,
  PaymentEntityTypeEnum,
  ScheduleItemType,
  TaxType
} from './enums';
import RootStore from '../store/RootStore';
import {
  formatPhoneNumber,
  getFromMilliseconds,
  getMilliseconds,
  getStateAbbreviature,
  getTimeInUtc,
  getTimeInZone,
  getTimezone
} from './helpers';
import {
  CoachContactsViewModel,
  InvoiceTaxViewModel,
  CreateInvoiceViewModel
} from './backend/api';
import { UserItem } from '../pages/SuperAdminUsers/SuperAdminUsers.helpers';
import { Invoice, iOption } from './types';
import { PaymentItemViewModel } from './backend/api';

export function normalizeAvailabilityFromBackEnd(
  availability: AppointmentTypeAvailabilityViewModel
): Availability {
  if (!availability) {
    return {
      singleDates: [],
      weekly: [],
      excludeDates: []
    };
  }
  const {
    repeatingAvailability,
    specificDates,
    excludeDates = []
  } = availability;

  const currentDayStartInZone = moment.utc(Date.now()).startOf('day').valueOf();

  return {
    singleDates: specificDates
      .map((item) => {
        return {
          date: item.timestampDateUnixMs,
          interval: {
            timeStartMs: item.timeSpan.startMs,
            timeEndMs: item.timeSpan.finishMs
          }
        };
      })
      .filter((i) => i.date >= currentDayStartInZone),
    excludeDates: excludeDates
      .map((item) => {
        return {
          date: item.timestampDateUnixMs,
          interval: {
            timeStartMs: item.timeSpan.startMs,
            timeEndMs: item.timeSpan.finishMs
          }
        };
      })
      .filter((i) => i.date >= currentDayStartInZone),
    weekly: repeatingAvailability.map((item) => {
      return {
        day: item.dayOfWeek,
        interval: {
          timeStartMs: item.timeSpan.startMs,
          timeEndMs: item.timeSpan.finishMs
        }
      };
    })
  };
}

export function normalizeIntervalFromBackEnd(
  timeSpan: AvailabilityTimeSpanViewModel,
  date?: number
): AvailabilityInterval {
  if (!timeSpan) {
    return {
      timeStartMs: 0,
      timeEndMs: 0
    };
  } else {
    return {
      timeStartMs:
        getTimeInUtc(
          (date ? date : 0) + (timeSpan.startMs || 0),
          RootStore.userStore.timeZone
        ) - (date ? date : 0),
      timeEndMs:
        getTimeInUtc(
          (date ? date : 0) + (timeSpan.finishMs || 0),
          RootStore.userStore.timeZone
        ) - (date ? date : 0)
    };
  }
}

export function normalizeAvailabilitySettingsFromBackEnd(
  data: CoachAvailabilitySettingsViewModel
): AvailabilitySettingsType {
  const {
    hasOtherSettings = false,
    minTimeCanChangeAppointmentInAdvanceMs = 0,
    notice = {
      periodMs: 0
    },
    schedulesBuffer = {
      beforeMs: 0,
      afterMs: 0
    }
  } = data;

  return {
    minTimeCanChangeAppointmentInAdvanceMs,
    notice,
    schedulesBuffer,
    hasOtherSettings
  };
}

export function normalizeAvailabilityForBackEnd(
  availability: Availability
): AppointmentTypeAvailabilityViewModel {
  const { weekly, singleDates, excludeDates } = availability;
  return {
    specificDates: singleDates.map((item) => {
      return {
        timestampDateUnixMs: item.date,
        timeSpan: {
          startMs: item.interval.timeStartMs,
          finishMs: item.interval.timeEndMs
        }
      };
    }),
    excludeDates: excludeDates.map((item) => {
      return {
        timestampDateUnixMs: item.date,
        timeSpan: {
          startMs: item.interval.timeStartMs,
          finishMs: item.interval.timeEndMs
        }
      };
    }),
    repeatingAvailability: weekly.map((item) => {
      return {
        dayOfWeek: item.day,
        timeSpan: {
          startMs: item.interval.timeStartMs,
          finishMs: item.interval.timeEndMs
        }
      };
    })
  };
}

export function normalizeIntervalForBackEnd(
  interval: AvailabilityInterval,
  date?: number
): AvailabilityTimeSpanViewModel {
  return {
    startMs:
      getTimeInZone(
        (date ? date : 0) + interval.timeStartMs || 0,
        RootStore.userStore.timeZone
      ) - (date ? date : 0),
    finishMs:
      getTimeInZone(
        (date ? date : 0) + interval.timeEndMs || 0,
        RootStore.userStore.timeZone
      ) - (date ? date : 0)
  };
}

export function normalizeAppointmentBlockFromBackEnd(
  data: AppointmentTypeViewModel
): AppointmentBlock {
  const {
    id,
    name,
    imageId,
    description,
    locationType,
    location,
    showLocationWhileBooking,
    link,
    durationMs,
    availability,
    isPrivate,
    membersCountLimit,
    price,
    schedulesBuffer,
    remindBeforeMs,
    thumbnailImageId,
    croppedArea
  } = data;

  const localAvailability = normalizeAvailabilityFromBackEnd(availability);

  return {
    id,
    name: name || '',
    imageId: imageId || '',
    description: description || '',
    location: location || '',
    link: link || '',
    durationMinutes: getFromMilliseconds(durationMs, 'minutes'),
    availability: localAvailability,
    isPrivate: isPrivate || false,
    membersCountLimit: membersCountLimit || 0,
    price: `${price || 0}`,
    schedulesBuffer: schedulesBuffer || {
      afterMs: 0,
      beforeMs: 0
    },
    remindBeforeMs: remindBeforeMs || 0,
    thumbnailImageId: thumbnailImageId || '',
    croppedArea: croppedArea || '',
    locationType: locationType || (LocationTypeEnum.Custom as number),
    showLocationWhileBooking: showLocationWhileBooking || false
  };
}

export function normalizeAppointmentBlockForBackEnd(
  data: AppointmentBlock
): AppointmentTypeViewModel {
  const {
    id,
    imageId,
    name,
    description,
    location,
    link,
    durationMinutes,
    availability,
    isPrivate,
    membersCountLimit,
    price,
    schedulesBuffer,
    remindBeforeMs,
    thumbnailImageId,
    croppedArea,
    locationType,
    showLocationWhileBooking
  } = data;
  return {
    id: id as number,
    name,
    imageId,
    description,
    location,
    link,
    durationMs: getMilliseconds(durationMinutes, 'minutes'),
    availability: normalizeAvailabilityForBackEnd(availability),
    isPrivate,
    membersCountLimit,
    price: +price,
    schedulesBuffer,
    remindBeforeMs,
    thumbnailImageId,
    croppedArea,
    locationType: locationType as number,
    showLocationWhileBooking
  };
}

export const EMPTY_AVAILABILITY: Availability = {
  singleDates: [],
  weekly: []
};

export function isAvailabilityEmpty(availability: Availability): boolean {
  return (
    availability.singleDates.length === 0 && availability.weekly.length === 0
  );
}

export function isAvailabilityNotEmpty(availability: Availability): boolean {
  return !isAvailabilityEmpty(availability);
}

export function normalizeAppointmentFromBackEnd(
  data: AppointmentViewModel
): Appointment {
  const date = moment.utc(
    getTimeInZone(data.timestampUtc, RootStore.userStore.timeZone)
  );

  const timestampUtc = date.clone().startOf('day').valueOf();
  return {
    ...data,
    price: `${data.price}`,
    timestampUtc,
    timeMs: date.valueOf() - timestampUtc,
    appointmentStatus: data.appointmentStatus as number,
    locationType: (data.locationType as unknown) as LocationTypeEnum,
    duration: getFromMilliseconds(data.duration, 'minutes'),
    location: data.location || ''
  };
}

export function normalizeAppointmentForBackEnd(
  data: Appointment
): AppointmentViewModel {
  return {
    ...data,
    price: +data.price,
    timestampUtc: getTimeInUtc(
      data.timestampUtc + data.timeMs,
      RootStore.userStore.timeZone
    ),
    appointmentStatus: data.appointmentStatus as number,
    locationType: (data.locationType as unknown) as LocationType,
    duration: getMilliseconds(data.duration, 'minutes'),
    location: data.location
  };
}

export function normalizeEventFromBackEnd(
  data: CoachEventViewModel
): CoachEvent {
  const {
    price,
    timestampDateUnixMs,
    durationMs,
    members,
    location,
    ...rest
  } = data;
  const startDate = moment.utc(
    getTimeInZone(timestampDateUnixMs, RootStore.userStore.timeZone)
  );
  const endDate = moment.utc(
    getTimeInZone(
      timestampDateUnixMs + durationMs,
      RootStore.userStore.timeZone
    )
  );
  const startDateTimeStamp = startDate.clone().startOf('day').valueOf();
  const endDateTimeStamp = endDate.clone().startOf('day').valueOf();
  const startTimeMs = startDate.valueOf() - startDateTimeStamp;
  const endTimeMs = endDate.valueOf() - endDateTimeStamp;

  return {
    members: members || [],
    ...rest,
    price: `${price}`,
    startDateTimeStamp,
    endDateTimeStamp,
    startTimeMs,
    endTimeMs,
    coachEventStatus: data.coachEventStatus as number,
    locationType: data.locationType as number,
    location: location || ''
  };
}

export function normalizeEventForBackEnd(
  data: CoachEvent
): CoachEventViewModel {
  const {
    availableSpots,
    startDateTimeStamp,
    endDateTimeStamp,
    startTimeMs,
    endTimeMs,
    price,
    imageId,
    locationType,
    showLocationWhileBooking,
    ...rest
  } = data;
  const timestampDateUnixMs = getTimeInUtc(
    startDateTimeStamp + startTimeMs,
    RootStore.userStore.timeZone
  );
  return {
    ...rest,
    availableSpots: +availableSpots,
    imageId: imageId || '',
    price: +price,
    timestampDateUnixMs,
    durationMs:
      getTimeInUtc(endDateTimeStamp + endTimeMs, RootStore.userStore.timeZone) -
      timestampDateUnixMs,
    coachEventStatus: data.coachEventStatus as number,
    showLocationWhileBooking,
    locationType: locationType as number
  };
}

export function normalizeClientAppointmentTypeViewModel(
  item: AppointmentTypesForScheduleViewModel
): iBookingItem {
  const {
    durationMs,
    price,
    remindBeforeMs,
    status,
    locationType,
    link,
    ...rest
  } = item;
  return {
    durationMinutes: getFromMilliseconds(durationMs, 'minutes'),
    hasPrice: price > 0,
    color: BookingItemColorsEnum.Appointment,
    type: BookingItemTypeEnum.AppointmentBlock,
    price,
    remindBeforeMs,
    status: status as number,
    locationType: locationType as number,
    link: link || '',
    ...rest
  };
}

export function normalizeClientAppointmentViewModel(
  item: AppointmentForScheduleViewModel
): iBookingItem {
  const {
    timestampDateUnixMs,
    price,
    durationMs,
    status,
    locationType,
    link,
    ...rest
  } = item;
  return {
    ...rest,
    timestamp: getTimeInZone(timestampDateUnixMs, RootStore.userStore.timeZone),
    hasPrice: price > 0,
    color: BookingItemColorsEnum.Appointment,
    type: BookingItemTypeEnum.Appointment,
    price,
    durationMinutes: getFromMilliseconds(durationMs, 'minutes'),
    status: status as number,
    locationType: locationType as number,
    link: link || ''
  };
}

export function normalizeClientFullAppointmentViewModel(
  item: AppointmentFullViewModel
): iBookingItem {
  const {
    timestampUtc,
    price,
    durationMs,
    appointmentStatus,
    locationType,
    link,
    ...rest
  } = item;
  return {
    ...rest,
    timestamp: getTimeInZone(timestampUtc, RootStore.userStore.timeZone),
    hasPrice: price > 0,
    color: BookingItemColorsEnum.Appointment,
    type: BookingItemTypeEnum.Appointment,
    price,
    durationMinutes: getFromMilliseconds(durationMs, 'minutes'),
    status: appointmentStatus as number,
    locationType: locationType as number,
    link: link || ''
  };
}

export function normalizeClientEventViewModel(
  item: EventForScheduleViewModel
): iBookingItem {
  const {
    timestampDateUnixMs,
    price,
    durationMs,
    status,
    locationType,
    link,
    ...rest
  } = item;
  return {
    timestamp: getTimeInZone(timestampDateUnixMs, RootStore.userStore.timeZone),
    hasPrice: price > 0,
    color: BookingItemColorsEnum.Meeting,
    type: BookingItemTypeEnum.Meeting,
    price,
    durationMinutes: getFromMilliseconds(durationMs, 'minutes'),
    status: status as number,
    locationType: locationType as number,
    link: link || '',
    ...rest
  };
}

export function normalizeBookingItemsFromBackEnd(
  data: GetAllBookedViewModel
): iBookingItem[] {
  const result: iBookingItem[] = [];

  data.getAvailableGroupsBooked!.forEach((item) => {
    result.push(normalizeClientAppointmentViewModel(item));
  });
  data.getBookedEvents!.forEach((item) => {
    result.push(normalizeClientEventViewModel(item));
  });
  data.getAvailableSingle!.forEach((item) => {
    result.push(normalizeClientAppointmentTypeViewModel(item));
  });

  return result;
}

export function normalizeBookingItemsInvitedFromBackEnd(
  data: GetAllWithInvitesViewModel
): iBookingItem[] {
  const result: iBookingItem[] = [];

  data.getAppointmentsWithInvites!.forEach((item) => {
    result.push(normalizeClientAppointmentViewModel(item));
  });

  data.getEventsWithInvites!.forEach((item) => {
    result.push(normalizeClientEventViewModel(item));
  });

  return result;
}

export function normalizeBookingItemsMemberFromBackEnd(
  data: GetAllWithMembersViewModel
): iBookingItem[] {
  const result: iBookingItem[] = [];

  data.getAppointmentsWithMembers!.forEach((item) => {
    result.push(normalizeClientAppointmentViewModel(item));
  });

  data.getEventsWithMembers!.forEach((item) => {
    result.push(normalizeClientEventViewModel(item));
  });

  return result;
}

export function normalizeContactFromBackEnd(
  data: CoachContactsViewModel
): CoachContactItem {
  return {
    ...data,
    formattedPhoneNumber: formatPhoneNumber(data.phoneNumber),
    fullName: `${data.firstName} ${data.lastName}`.trim(),
    address:
      !!data.city.length &&
      !!data.state.length &&
      data.country === 'United States'
        ? `${data.city}, ${getStateAbbreviature(data.state)}`
        : `${data.city.length ? `${data.city}, ` : ''}${
            data.state.length ? `${data.state}, ` : ''
          }${data.country}` || '-'
  };
}

export function normalizeCoachFromBackEnd(
  data: CoachAccountViewModel
): UserItem {
  return {
    ...data,
    plan: data.pricingPlanId,
    fullName: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
    address:
      `${!!data.city?.length ? `${data.city}, ` : ''}${
        !!data.state?.length ? `${data.state}, ` : ''
      }${data.country}` || '-'
  };
}

export function normalizeCitiesToIOption(list: iCity[]): iOption[] {
  return list.map(
    (item: iCity, index): iOption => {
      return {
        id: index + '',
        value: item.city_name + '',
        name: item.city_name
      };
    }
  );
}

export function normalizeClientScheduleEventFromBackEnd(
  item: EventForScheduleViewModel
): CoachClientScheduleItem {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    itemType: item.isScheduled
      ? ScheduleItemType.OneToOne
      : ScheduleItemType.Event,
    price: item.price,
    timestamp: getTimeInZone(
      item.timestampDateUnixMs,
      RootStore.userStore.timeZone
    ),
    duration: item.durationMs,
    location: item.location,
    status: item.status || (item.clientEventStatus as number),
    refundStatus: item.refundStatus,
    membersCountLimit: item.membersCountLimit,
    imageId: item.imageId,
    activeMembersCount: item.activeMembersCount
  };
}

export function normalizeClientScheduleAppointmentFromBackEnd(
  item: AppointmentForScheduleViewModel
): CoachClientScheduleItem {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    itemType: ScheduleItemType.Appointment,
    price: item.price,
    timestamp: getTimeInZone(
      item.timestampDateUnixMs,
      RootStore.userStore.timeZone
    ),
    duration: item.durationMs,
    location: item.location,
    status: item.status || (item.clientAppointmentStatus as number),
    refundStatus: item.refundStatus,
    membersCountLimit: item.membersCountLimit,
    imageId: item.imageId
  };
}

export function normalizeNoteFromBackEnd(item: NoteViewModel): NoteViewModel {
  return {
    ...item,
    createdOn: getTimeInZone(item.createdOn, RootStore.userStore.timeZone),
    modifiedOn: item.modifiedOn
      ? getTimeInZone(item.modifiedOn, RootStore.userStore.timeZone)
      : null
  };
}

export function normalizeFileFromBackEnd(
  item: FileResponseViewModel
): FileResponseViewModel {
  return {
    ...item,
    createdOn: getTimeInZone(item.createdOn, RootStore.userStore.timeZone),
    sharedOn: item.sharedOn
      ? getTimeInZone(item.sharedOn, RootStore.userStore.timeZone)
      : null
  };
}

export function normalizeInvoiceFromBackEnd(item: InvoiceViewModel): Invoice {
  return {
    id: item.id,
    status: item.status as number,
    type: item.type as number,
    billingInterval: item.billingInterval as number,
    intervalValue: item.intervalValue,
    amount: item.amount,
    client: normalizeContactFromBackEnd(
      item.client || {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        state: '',
        city: '',
        phoneNumber: '1',
        timeZone: ''
      }
    ),
    startDate: getTimeInZone(item.startDate, RootStore.userStore.timeZone),
    dueDate: getTimeInZone(
      moment.utc(item.dueDate).subtract(1, 'day').add(1, 'second').valueOf(),
      RootStore.userStore.timeZone
    ),
    createdOn: getTimeInZone(item.createdOn, RootStore.userStore.timeZone),
    modifiedOn: getTimeInZone(item.modifiedOn, RootStore.userStore.timeZone),
    services: item.services,
    memo: item.memo,
    tax: item.tax ? normalizeCoachTaxFromBackEnd(item.tax) : null,
    customizeData: (item as any)['siteCustomizationJson']
      ? {
          ...JSON.parse((item as any)['siteCustomizationJson'])
        }
      : {
          primaryColor: '',
          secondaryColor: '',
          headlineColor: '',
          paragraphColor: '',
          logoSrc: null
        },
    number: item.number,
    subscriptionId: item.stripeSubscriptionId
  };
}

export function normalizeCoachInvoiceForBackEnd(
  item: Invoice
): CreateInvoiceViewModel {
  return {
    status: item.status as number,
    type: item.type as number,
    clientId: item.client?.clientId,
    startDate: getTimeInUtc(item.startDate, RootStore.userStore.timeZone),
    dueDate: getTimeInUtc(
      moment.utc(item.dueDate).add(1, 'day').subtract(1, 'second').valueOf(),
      RootStore.userStore.timeZone
    ),
    services: item.services.map((s) => ({
      ...s,
      price: +s.price,
      quantity: +s.quantity
    })),
    billingInterval: item.billingInterval as number,
    intervalValue: +item.intervalValue as number,
    memo: item.memo,
    tax: item.tax ? normalizeCoachTaxForBackEnd(item.tax) : null,
    siteCustomizationJson: JSON.stringify(item.customizeData)
  };
}

export function normalizeCoachTaxFromBackEnd(
  item: InvoiceTaxViewModel
): InvoiceTax {
  return {
    id: item.id,
    name: item.name,
    type: item.type as number,
    value: (item.type as number) === TaxType.Rate ? item.rate : item.amount
  };
}

export function normalizeCoachTaxForBackEnd(
  item: InvoiceTax
): InvoiceTaxViewModel {
  return {
    id: item.id,
    name: item.name,
    type: item.type as number,
    amount: (item.type as number) === TaxType.Rate ? 0 : +item.value,
    rate: (item.type as number) === TaxType.Rate ? +item.value : 0
  };
}

export function normalizeCoachPaymentFromBackEnd(
  item: PaymentItemViewModel
): Payment {
  return {
    id: item.id,
    status: item.status as number,
    amount: item.amount,
    client: normalizeContactFromBackEnd(
      item.client || {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        state: '',
        city: '',
        phoneNumber: '1',
        timeZone: ''
      }
    ),
    date: getTimeInZone(item.date, RootStore.userStore.timeZone),
    description: item.description || '',
    stripePaymentIntentId: item.stripePaymentIntentId || '',
    entityId:
      item.clientAppointment?.id || item.clientEvent?.id || item.invoice?.id,
    entityType: item.clientAppointment
      ? PaymentEntityTypeEnum.Appointment
      : item.clientEvent
      ? item.clientEvent.isScheduled
        ? PaymentEntityTypeEnum.OneToOne
        : PaymentEntityTypeEnum.Meeting
      : PaymentEntityTypeEnum.Invoice
  };
}

export const normalizeCoachAccountData = (
  data: CoachAccountViewModel
): CoachAccountViewModel => {
  return {
    ...data,
    timeZone: getTimezone(data.timeZone)
  };
};

export const normalizeClientAccountData = (
  data: ClientAccountViewModel
): ClientAccountViewModel => {
  return {
    ...data,
    timeZone: getTimezone(data.timeZone)
  };
};
