import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from '../DatePicker/DatePicker';
import AvailableTime from '../Modals/AppointmentModal/AvailableTime';
import moment from 'moment';
import './AvailableDateTimePicker.styles.scss';
import Loader from '../Loader/Loader';
import { getCurrentDayTimeStamp } from '../../core/helpers';

interface iProps {
  date: number;
  timeMs: number;
  onChangeDate: (date: number) => void;
  onChangeTime: (timeMs: number) => void;
  getAvailability: (
    monthStartTimeStamp: number,
    monthEndTimeStamp: number
  ) => Promise<number[]>;
  className?: string;
  isDisabled?: boolean;
}

const AvailableDateTimePicker: React.FC<iProps> = ({
  date,
  timeMs,
  getAvailability,
  onChangeDate,
  onChangeTime = () => {},
  className = '',
  isDisabled = false
}) => {
  const [availability, setAvailability] = useState<Record<number, Set<number>>>(
    {}
  );
  const [isInitial, setIsInitial] = useState<boolean>(true);
  const [monthStartTimeStamp, setMonthStartTimeStamp] = useState<number>(0);
  const [availabilityLoadedFor, setAvailabilityLoadedFor] = useState<
    Set<number>
  >(new Set());
  const [isAvailabilityLoading, setIsAvailabilityLoading] = useState<boolean>(
    false
  );
  const [dayTimeSlots, setDayTimeSlots] = useState<number[]>([]);

  const monthEndTimeStamp = moment
    .utc(monthStartTimeStamp)
    .endOf('month')
    .valueOf();

  useEffect(() => {
    if (!isDisabled) {
      const currentMonthAvailability = getAvailabilityForPeriod(
        availability,
        monthStartTimeStamp,
        monthEndTimeStamp
      );

      const dayAvailability = date
        ? currentMonthAvailability!.find((timeStamp) => timeStamp === date)
        : 0;

      if (dayAvailability) {
        setDayTimeSlots(Array.from(availability[dayAvailability]));
      }
    }
  }, [availability, date, isDisabled]);

  useEffect(() => {
    if (isDisabled || !monthStartTimeStamp || !monthEndTimeStamp) {
      return;
    }

    const monthAvailability = availabilityLoadedFor.has(monthStartTimeStamp);
    if (!monthAvailability) {
      setIsAvailabilityLoading(true);
      getAvailability(monthStartTimeStamp, monthEndTimeStamp).then((data) => {
        setAvailabilityLoadedFor(
          availabilityLoadedFor.add(monthStartTimeStamp)
        );
        const dayIterator = moment.utc(monthStartTimeStamp).subtract(1, 'day');
        const end = moment.utc(monthEndTimeStamp).add(1, 'day');
        const availabilityByDays = { ...availability };

        while (dayIterator.valueOf() < end.valueOf()) {
          const nextDay = dayIterator.clone().add(1, 'day');
          const existedSlots = availabilityByDays[dayIterator.valueOf()]
            ? Array.from(availabilityByDays[dayIterator.valueOf()])
            : [];
          availabilityByDays[dayIterator.valueOf()] = new Set([
            ...existedSlots,
            ...data
              .filter(
                (ts) => ts >= dayIterator.valueOf() && ts < nextDay.valueOf()
              )
              .map((ts) => ts - dayIterator.valueOf())
          ]);
          dayIterator.add(1, 'day');
        }

        setAvailability(availabilityByDays);

        if (
          isInitial &&
          !getAvailabilityForPeriod(
            availabilityByDays,
            monthStartTimeStamp,
            monthEndTimeStamp
          ).length
        ) {
          if (
            moment
              .utc(monthEndTimeStamp)
              .diff(moment.utc(getCurrentDayTimeStamp()), 'month') < 6
          ) {
            handleChangeMonth(monthEndTimeStamp + 1);
            return;
          } else {
            setMonthStartTimeStamp(
              moment.utc(getCurrentDayTimeStamp()).startOf('month').valueOf()
            );
          }
        }
        setIsInitial(false);

        setIsAvailabilityLoading(false);
      });
    }
  }, [monthStartTimeStamp, isDisabled]);

  const handleChangeMonth = useCallback((date: number) => {
    if (date === monthStartTimeStamp) {
      return;
    }
    setMonthStartTimeStamp(date);
  }, []);

  const dayPickerAvailability = getAvailableDates(availability);

  const isActive = !!date && dayPickerAvailability.includes(date);

  const handleDateChange = (value: number[]) => {
    onChangeDate(value[0]);
    onChangeTime(-1);
  };

  const isAvailable = (date: number) => {
    return dayPickerAvailability.includes(date);
  };

  return (
    <div className={`DateTimePicker ${className}`}>
      <div
        className={`DateTimePicker__calendar ${
          isActive ? 'DateTimePicker__calendar--active' : ''
        }`}
      >
        {isAvailabilityLoading && <Loader />}
        <DatePicker
          value={date}
          onChange={handleDateChange}
          onMonthChange={handleChangeMonth}
          shouldHighlightDate={isAvailable}
          data-testid='day-picker'
          disableFuture={isDisabled}
          initialDate={monthStartTimeStamp}
        />
      </div>
      <AvailableTime
        date={date}
        timeList={dayTimeSlots}
        isOpened={date > 0 && !!dayTimeSlots.length}
        selectedTime={timeMs}
        setSelectedTime={onChangeTime}
      />
    </div>
  );
};

function getAvailableDates(availability: Record<number, Set<number>>) {
  return Object.keys(availability)
    .map((key) => +key)
    .filter((day) => availability[day].size);
}

function getAvailabilityForPeriod(
  availability: Record<number, Set<number>>,
  from: number,
  to: number
) {
  return Object.keys(availability)
    .map((key) => +key)
    .filter((day) => availability[day].size)
    .filter((day) => day >= from && day <= to);
}

export default AvailableDateTimePicker;
