import './InvoicesList.styles.scss';
import React from 'react';
import SearchInput from '../SearchInput/SearchInput';
import TabsMenu, { iTab } from '../TabsMenu/TabsMenu';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
import { Invoice } from '../../core/types';
import Table, { TableAction, TableColumn } from '../Table/Table';
import { useMedia } from '../../hooks';
import InvoicesItem from '../InvoicesItem/InvoicesItem';
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import { useSearchInput } from '../SearchInput/useSearchInput';
import { filterForSearch } from './InvoicesList.helpers';
import { SortOrder } from '../../core/enums';
import { usePagingSorting } from '../../hooks';

export interface iProps {
  className?: string;
  tabs: iTab[];
  isLoading: boolean;
  invoices: Invoice[];
  columns: Array<TableColumn<Invoice>>;
  actions: Array<TableAction<Invoice>>;
  baseUrl: string;
  rowHeight: number;
  headerHeight?: number;
  onClick: (item: Invoice) => void;
}

const InvoicesList: React.FC<iProps> = ({
  className = '',
  tabs,
  isLoading,
  invoices,
  columns,
  actions,
  baseUrl,
  rowHeight,
  headerHeight,
  onClick
}) => {
  const {
    searchText,
    handleSearch,
    searchedItems: searchedInvoices
  } = useSearchInput(invoices, filterForSearch);

  const {
    pageNumber,
    onPageChange,
    pageSize,
    getCurrentPageData,
    onSortChange,
    sort
  } = usePagingSorting<Invoice>(searchedInvoices.length, isLoading, 20, {
    sortBy: 'modifiedOn',
    sortOrder: SortOrder.Desc
  });
  const isTablet = useMedia(['(min-width: 576px)'], [true], false);

  const handleRowClick = (item: Invoice) => () => {
    onClick(item);
  };

  const renderInvoices = () => {
    if (isLoading) return null;

    if (!searchedInvoices.length) {
      return (
        <div
          className='position-relative flex-grow-1'
          style={{ minHeight: '280px' }}
        >
          <EmptyState
            size='md'
            text="You don't have invoices yet"
            absoluteCenter
            icon={<EmptyStateIcon />}
            style={{ top: isTablet ? 'calc(50% - 50px)' : undefined }}
          />
        </div>
      );
    }

    return (
      <Table<Invoice>
        sort={sort}
        totalCount={searchedInvoices.length}
        withPaging
        onPageChange={onPageChange}
        pageSize={pageSize}
        pageNumber={pageNumber}
        searchText={searchText}
        className={`Table--InvoicesList mt-sm-4 ${
          !searchedInvoices.length ? 'hidden' : ''
        }`}
        data={getCurrentPageData(searchedInvoices)}
        columns={columns}
        actions={actions}
        baseUrl={baseUrl}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        idField='id'
        isShownMobileRenderer={!isTablet}
        onRowClick={handleRowClick}
        onSort={onSortChange}
        MobileRenderer={({ item, actions }) => (
          <InvoicesItem
            item={item}
            onClick={onClick}
            actions={actions}
            nameOption='name'
          />
        )}
      />
    );
  };

  return (
    <div className={`InvoicesList d-flex flex-column ${className}`}>
      <div className='d-flex justify-content-between align-items-sm-center align-items-start flex-wrap flex-sm-row-reverse flex-column px-sm-4 pt-3'>
        <div className='pb-3 px-3 px-sm-0 d-flex aling-items-center justify-content-between justify-content-sm-end align-self-stretch flex-grow-1'>
          <div className='InvoicesList__title d-none d-flex d-sm-none align-items-center'>
            Invoices
          </div>

          <SearchInput
            className='ml-3'
            onSearch={handleSearch}
            searchText={searchText}
            collapsible
          />
        </div>
        <TabsMenu
          tabsList={tabs}
          className='mt-sm-0 mt-1 TabsMenu--InvoicesList Scrollbar flex-grow-1'
        />
      </div>
      <GlobalLoader isLoading={isLoading} />
      {renderInvoices()}
    </div>
  );
};

export default InvoicesList;
