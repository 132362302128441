import React, { useState } from 'react';
import './NewPassword.styles.scss';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import { renderPasswordReminder } from '../ChangePasswordForm/ChangePasswordForm.helpers';
import {
  isPasswordsDataValid,
  getPasswordsInputErrors,
  isPasswordsInputValid
} from './NewPassword.helpers';

export interface iNewPasswordData {
  email: string;
  token: string;
}

const emptyData = {
  newPassword: '',
  repeatNewPassword: ''
};

interface iProps {
  sendRequest: (newPass: string, confirmPass: string) => void;
  buttonTitle?: string;
}

const NewPassword: React.FC<iProps> = ({
  sendRequest,
  buttonTitle = 'Save'
}) => {
  const [data, setData] = useState(emptyData);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);

  const handleChangeField = (field: string) => (value: any) => {
    setData({
      ...data,
      [field]: value
    });
  };

  const handleSavePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isPasswordsDataValid(data)) {
      setIsValidationShown(false);
      sendRequest(data.newPassword, data.repeatNewPassword);
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <div className='NewPassword'>
      <ModalHeader>
        <h2>Enter new password</h2>
      </ModalHeader>
      <div className='NewPassword__description my-3'>
        {renderPasswordReminder}
      </div>
      <form onSubmit={handleSavePassword}>
        <FormInput
          className='mt-0'
          type='password'
          label='New password'
          value={data.newPassword}
          isInvalid={
            isValidationShown && !isPasswordsInputValid('newPassword', data)
          }
          errorMessage={getPasswordsInputErrors('newPassword', data)[0]}
          handleChange={handleChangeField('newPassword')}
        />
        <FormInput
          type='password'
          label='Confirm new password'
          value={data.repeatNewPassword}
          isInvalid={
            isValidationShown &&
            !isPasswordsInputValid('repeatNewPassword', data)
          }
          handleChange={handleChangeField('repeatNewPassword')}
        />
        <Button type='submit' className='button__full-width'>
          {buttonTitle}
        </Button>
      </form>
    </div>
  );
};

export default NewPassword;
