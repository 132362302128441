import React from 'react';
import FormRadio from '../FormRadio/FormRadio';
import './Location.styles.scss';
import { LocationTypeEnum } from '../../core/enums';
import FormInput from '../FormInput/FormInput';
import Select from '../Select/Select';
import cn from 'classnames';
import ZoomIcon from '../../icons/zoom.svg';
import CustomLocationIcon from '../../icons/custom-location.svg';
import { iOption } from '../../core/types';
import Button from '../Button/Button';
import { copyToClipboard } from '../../core/helpers';
import { useStores, useFeatures } from '../../hooks';

const getOptionContent = (
  icon: JSX.Element,
  title: string,
  subTitle: string
) => {
  return (
    <div className='Location__select-option'>
      <div className='mr-2 d-flex align-items-center justify-content-center Location__select-icon'>
        {icon}
      </div>
      <div>
        <div className='Location__select-optionTitle'>{title}</div>
        <div className='Location__select-optionSubTitle'>{subTitle}</div>
      </div>
    </div>
  );
};

const selectOptions: iOption<LocationTypeEnum>[] = [
  {
    id: LocationTypeEnum.Zoom,
    value: LocationTypeEnum.Zoom,
    name: 'Zoom',
    content: getOptionContent(<ZoomIcon />, 'Zoom', 'Web Conference')
  },
  {
    id: LocationTypeEnum.Custom,
    value: LocationTypeEnum.Custom,
    name: 'Custom',
    content: getOptionContent(
      <CustomLocationIcon />,
      'Custom',
      'Leave Customized location details'
    )
  }
];

export interface iLocation {
  location: string;
  locationType: LocationTypeEnum;
  showLocationWhileBooking?: boolean;
}

interface Props {
  locationType: LocationTypeEnum;
  location: string;
  showLocationWhileBooking: boolean;
  isDisabled?: boolean;
  isZoomConnected: boolean;
  connectUrl?: string;
  onConnect?: () => void;
  onChange: (data: Partial<iLocation>) => void;
  isInvalid: Record<'location' | 'locationType', boolean>;
  errorMessage: Record<'location' | 'locationType', string>;
  tabIndex?: number;
  locationTitle?: string;
  initialLocation?: iLocation;
}

const Location: React.FC<Props> = ({
  locationType,
  location,
  showLocationWhileBooking,
  isZoomConnected,
  isDisabled,
  connectUrl,
  onConnect,
  onChange,
  isInvalid,
  errorMessage,
  tabIndex,
  locationTitle = '',
  initialLocation
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  const { zoomEnabled } = useFeatures();

  const handleLocationTypeChange = (locationType: LocationTypeEnum) => {
    if (locationType === initialLocation?.locationType) {
      onChange(initialLocation);
    } else {
      onChange({
        locationType,
        location: '',
        showLocationWhileBooking:
          locationType === LocationTypeEnum.Zoom
            ? false
            : showLocationWhileBooking
      });
    }
  };
  const handleLocationChange = (location: string) => {
    onChange({ location });
  };
  const handleShowLocationChange = (value: boolean) => {
    onChange({ showLocationWhileBooking: value });
  };

  const handleCopyLink = () => {
    copyToClipboard(location);
    notificationStore.addNotification({
      text: 'Location',
      textColored: 'was copied to your clipboard'
    });
  };

  const isInvalidZoom =
    locationType === LocationTypeEnum.Zoom && !isZoomConnected;

  return (
    <div className='Location'>
      {zoomEnabled && (
        <div className='Location__select'>
          <Select
            tabIndex={tabIndex}
            className={cn('w-100', {
              'Location__select--empty': !locationType
            })}
            isError={isInvalidZoom || (isInvalid && isInvalid.locationType)}
            options={selectOptions.slice(zoomEnabled ? 0 : 1)}
            isDisabled={isDisabled}
            value={locationType}
            title='Location type'
            onChange={handleLocationTypeChange}
            placeholder='Add a location'
            data-testid='location-type-select'
          />
          {isInvalidZoom && (
            <div
              className='FormInput__error-message'
              data-testid='location-type-error'
            >
              Zoom is not connected. Connect your zoom{' '}
              <a
                href={connectUrl}
                onClick={onConnect}
                className='Location__select__link'
              >
                here.
              </a>
            </div>
          )}
          {isInvalid && isInvalid.locationType && (
            <div className='FormInput__error-message'>
              {errorMessage.locationType}
            </div>
          )}
        </div>
      )}

      {(locationType === LocationTypeEnum.Custom || location) && (
        <FormInput
          tabIndex={tabIndex}
          handleChange={handleLocationChange}
          value={location}
          isInvalid={isInvalid && isInvalid.location}
          isDisabled={isDisabled || locationType === LocationTypeEnum.Zoom}
          errorMessage={errorMessage.location}
          label={
            locationTitle ||
            `${LocationTypeEnum.Custom ? 'Custom ' : ''}Location`
          }
          className='FormInput--Location'
          append={
            <Button
              disabled={!location.length}
              type='button'
              variations={['naked']}
              handleClick={handleCopyLink}
              tabIndex={tabIndex}
            >
              Copy
            </Button>
          }
          data-testid='location-input'
        />
      )}
      {!!locationType && (
        <>
          {locationType !== LocationTypeEnum.Zoom && (
            <>
              <div className='mt-3'>
                <FormRadio
                  tabIndex={tabIndex}
                  id='show'
                  checked={showLocationWhileBooking}
                  handleChange={() => handleShowLocationChange(true)}
                  label={`Display ${
                    locationTitle.toLowerCase() || 'location'
                  } while booking`}
                  data-testid='location-radio-btn-while-booking'
                />
              </div>
              <div className='mt-3'>
                <FormRadio
                  tabIndex={tabIndex}
                  id='dontShow'
                  checked={!showLocationWhileBooking}
                  handleChange={() => handleShowLocationChange(false)}
                  label={`Display ${
                    locationTitle.toLowerCase() || 'location'
                  } after confirmation`}
                  data-testid='location-radio-btn-after-confirmation'
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Location;
