import React from 'react';
import { iFormConfigItem } from '../../core/types';

import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  getInputErrors,
  isDataValid,
  isInputValid,
  isNotEmpty,
  isNotSpacesOnSides,
  isPasswordLongEnough
} from '../../core/formValidation';

export const getDefaultData = () => {
  return {
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  };
};

const PasswordsFormConfig: iFormConfigItem[] = [
  { field: 'oldPassword', checkIsValid: [isNotEmpty] },
  {
    field: 'newPassword',
    compareField: 'repeatNewPassword',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  },
  {
    field: 'repeatNewPassword',
    compareField: 'newPassword',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  }
];

export function isPasswordsInputValid(inputField: string, data: any) {
  return isInputValid(PasswordsFormConfig)(inputField, data);
}

export function getPasswordsInputErrors(inputField: string, data: any) {
  return getInputErrors(PasswordsFormConfig)(inputField, data);
}

export function isPasswordsDataValid(data: any) {
  return isDataValid(PasswordsFormConfig)(data);
}

export const renderPasswordReminder = (
  <>
    <p>
      The password should be at least 8 characters long and contain a digit, a
      capital, and a lower case letter.
    </p>
    <p>Example: Abcd1234 or aBcd1234</p>
  </>
);
