import React, { ReactNode } from 'react';
import './ModalHeader.styles.scss';

interface iProps {
  title?: string;
  subTitle?: string;
  subSubTitle?: string;
  children?: Array<any> | ReactNode | null;
  notInModal?: boolean;
}

const ModalHeader: React.FC<iProps> = ({
  title,
  subTitle,
  subSubTitle,
  children = [],
  notInModal
}) => {
  let childrenProp = null;
  if (Array.isArray(children)) {
    childrenProp = children;
  } else if (typeof children === 'object') {
    childrenProp = [children];
  }

  let content: Array<object | null> = childrenProp!.map((child, index) => {
    switch (child.type) {
      case 'h2':
        return (
          <div className="ModalHeader__title" key={index}>
            {child}
          </div>
        );
      case 'h3': {
        const subTitleClassList = new Set(['ModalHeader__sub-title']);
        if (index > 0)
          subTitleClassList.add('ModalHeader__sub-title--with-margin-top');
        if (notInModal)
          subTitleClassList.add('ModalHeader__sub-title--notInModal');
        return (
          <div className={Array.from(subTitleClassList).join(' ')} key={index}>
            {child}
          </div>
        );
      }
      case 'h4': {
        const subSubTitleClassList = new Set(['ModalHeader__sub-sub-title']);
        if (index > 0)
          subSubTitleClassList.add(
            'ModalHeader__sub-sub-title--with-margin-top'
          );
        return (
          <div
            className={Array.from(subSubTitleClassList).join(' ')}
            key={index}
          >
            {child}
          </div>
        );
      }
      default:
        return null;
    }
  });
  return (
    <div className="ModalHeader">
      {content}
      {/*{title ? <div className="ModalHeader__title">{title}</div> : null}*/}
      {/*{subTitle ? <div className={Array.from(subTitleClassList).join(' ')}>{subTitle}</div> : null}*/}
      {/*{subSubTitle ? <div className={Array.from(subSubTitleClassList).join(' ')}>{subSubTitle}</div> : null}*/}
    </div>
  );
};

export default ModalHeader;
