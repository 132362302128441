import './TransactionsItem.styles.scss';
import React, { useState, useRef } from 'react';
import Badge from '../Badge/Badge';
import { InvoiceStatusEnum, TransactionsEntityEnum } from '../../core/enums';
import moment from 'moment';
import cn from 'classnames';
import { useOnClickOutside } from '../../hooks';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import TextEllipse from '../TextEllipse';
import { highlightMatches, normalizePrice } from '../../core/helpers';
import { BillingItemViewModel } from '../../core/backend/api';
import { getBadgeType } from './TransactionsList.helpers';
import ArrowIcon from '../../icons/arrow_right.svg';

export type NameOptionType = 'name' | 'number';

interface Props {
  item: BillingItemViewModel;
  actions: ListItemProps[];
  searchString: string;
  onClick?: (i: BillingItemViewModel) => () => void;
}

const TOUCH_DURATION = 500;

const TransactionsItem: React.FC<Props> = ({
  item: transaction,
  actions,
  searchString,
  onClick
}) => {
  const ref = useRef();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [isActive, setIsActive] = useState<boolean>(false);

  const { status, entity, entityName, description } = transaction;

  useOnClickOutside(
    ref,
    () => {
      setIsActive(false);
    },
    isActive
  );

  const handleTouchEnd = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
  };

  const handleLongTouch = () => {
    setIsActive(!isActive);
  };

  const handleTouchStart = () => {
    setTimerId(setTimeout(handleLongTouch, TOUCH_DURATION));
  };

  const name =
    entity === TransactionsEntityEnum.Appointment ||
    entity === TransactionsEntityEnum.Meeting
      ? entityName
      : `#${entityName}`;

  const descriptionBlock = (
    <div className='TransactionsList__description'>
      <TextEllipse text={`${description} ${name}`}>
        <span>
          {description}{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: highlightMatches(name, searchString)
            }}
          />
        </span>
      </TextEllipse>
    </div>
  );

  const badgeType = InvoiceStatusEnum[status] as keyof typeof InvoiceStatusEnum;

  return (
    <div
      className='TransactionsItem d-flex align-items-stretch'
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      ref={ref}
      onClick={onClick(transaction)}
    >
      <div
        className={cn(
          'd-flex align-items-stretch w-100 TransactionsItem__container',
          {
            'TransactionsItem__container--opened': isActive
          }
        )}
      >
        <div className='d-flex align-items-center w-100 flex-shrink-0'>
          <div className='pl-3 py-3 d-flex flex-column flex-grow-1 TransactionsItem__content'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div className='TransactionsItem__desc flex-shrink-0 mr-2'>
                {descriptionBlock}
              </div>
              <div className='TransactionsItem__total'>
                ${normalizePrice(transaction.amount)}
              </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='TransactionsItem__date'>
                {moment.utc(transaction.date).format('MMM D')}
              </div>
              <Badge
                type={getBadgeType(badgeType)}
                title={badgeType}
                className='Badge--TransactionsItem'
              />
            </div>
          </div>
          <div className='flex-shrink-0 d-flex align-items-center TransactionsItem__arrow'>
            <ArrowIcon />
          </div>
        </div>

        <div className='TransactionsItem__menu flex-shrink-0 d-flex justify-content-center align-items-center'>
          <DropdownMenu
            list={actions}
            className='DropdownMenu--table'
            withPortal
            fullScreen
            title={descriptionBlock}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionsItem;
