import { useEffect } from 'react';

export function useOverflow(
  isOpened: boolean,
  className = 'modal-opened',
  checker?: () => boolean
) {
  useEffect(() => {
    if (isOpened || (checker && checker())) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
    return () => {
      if (!isOpened && !(checker && checker())) {
        document.body.classList.remove(className);
      }
    };
  }, [checker, className, isOpened]);
}
