import React, { ReactNode, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { matchPath, useLocation } from 'react-router-dom';
import Notification from '../../components/Notification/Notification';
import { ClientRoutesEnum, CoachRoutesEnum } from '../../core/enums';
import RotateWarning from '../../components/RotateWarning/RotateWarning';
import './Page.styles.scss';

interface iProps {
  children: ReactNode;
  title?: string;
  className?: string;
  style?: any;
  landscapeRoutesDisabled?: CoachRoutesEnum[] | ClientRoutesEnum[];
}

const Page: React.FC<iProps> = ({
  children,
  title = '',
  className = '',
  style = {},
  landscapeRoutesDisabled
}) => {
  const { pathname } = useLocation();

  const isLandscapeDisabled = useMemo(
    () => !!matchPath(pathname, landscapeRoutesDisabled),
    [landscapeRoutesDisabled, pathname]
  );

  return (
    <>
      <div className={`Page ${className}`} style={style}>
        {title && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
        {children}
        <div className='Page__copyright CZ__text-prim'>
          &copy; {new Date().getFullYear()} CoachPro. All Rights Reserved
        </div>
        <Notification />
      </div>
      {isLandscapeDisabled && <RotateWarning />}
    </>
  );
};

export default Page;
