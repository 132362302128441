import { useEffect, useState } from 'react';
import { useDebouncedCallback } from './useDebouncedCallback';

function useWindowResize(callback: () => void, delay = 50) {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResizeDebounced = useDebouncedCallback(() => {
    setWidth(window.innerWidth);
    callback();
  }, delay);

  useEffect(() => {
    callback();
    window.addEventListener('resize', handleResizeDebounced);
    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
    };
  }, []);

  return width;
}

export { useWindowResize };
