import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Invoice, InvoiceTax } from '../../../core/types';
import FormInput from '../../../components/FormInput/FormInput';
import DayPickerModal from '../../../components/DayPickerModal/DayPickerModal';
import { getDefaultContactData } from '../CoachInvoice.helpers';
import {
  BILLING_OPTIONS,
  CUSTOM_BILLING_OPTIONS,
  getFormInputErrors,
  isFormInputValid
} from './CoachInvoiceForm.helpers';

import './CoachInvoiceForm.styles.scss';
import { CoachContactsViewModel } from '../../../core/backend/api';
import Button from '../../../components/Button/Button';
import {
  InvoiceTypeEnum,
  TaxType,
  InvoiceBillingInterval
} from '../../../core/enums';
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import ClientAutocompleteItem from '../../../components/ClientAutocompleteItem/ClientAutocompleteItem';
import CoachInvoiceTaxModal from '../CoachInvoiceTaxModal/CoachInvoiceTaxModal';
import { normalizePrice, searchBy } from '../../../core/helpers';
import CoachInvoiceFormItems from './CoachInvoiceFormItems';
import { formatValueAsInteger } from '../../../core/formValidation';
import BucketIcon from '../../../icons/delete.svg';
import Select from '../../../components/Select/Select';
import FormRadio from '../../../components/FormRadio/FormRadio';

interface Props {
  data: Invoice;
  clients: CoachContactsViewModel[];
  taxOptions: InvoiceTax[];
  isValidationShown: boolean;
  onChange: (field: keyof Invoice) => (value: any) => void;
}
const searchClients = (item: CoachContactsViewModel, search: string) =>
  searchBy(item.firstName, search) ||
  searchBy(item.lastName, search) ||
  searchBy(item.email, search);

const CoachInvoiceForm: FC<Props> = ({
  data,
  isValidationShown,
  clients,
  taxOptions,
  onChange
}) => {
  const [duration, setDuration] = useState<number>(
    moment.utc(data.dueDate).diff(moment.utc(data.startDate), 'day')
  );
  const [billingInterval, setBillingInterval] = useState<
    InvoiceBillingInterval
  >(null);
  const [isTaxModalOpened, setIsTaxModalOpened] = useState<boolean>(false);

  useEffect(() => {
    onChange('dueDate')(
      moment.utc(data.startDate).add(duration, 'day').valueOf()
    );
  }, [data.startDate, duration, onChange]);

  useEffect(() => {
    if (billingInterval === InvoiceBillingInterval.Custom) {
      return;
    }
    if (data.intervalValue && data.intervalValue > 1) {
      setBillingInterval(InvoiceBillingInterval.Custom);
    } else {
      setBillingInterval(data.billingInterval);
    }
  }, [data, billingInterval]);

  const handleDurationChange = (value: string) => {
    setDuration(+value || 0);
  };

  const handleClientChange = (value: CoachContactsViewModel) => {
    if (value && value.clientId) {
      onChange('client')({ ...value });
    }
  };

  const handleClientFieldChange = (field: keyof CoachContactsViewModel) => (
    value: string
  ) => {
    if (data.client.clientId) {
      onChange('client')({
        ...getDefaultContactData(),
        [field]: field === 'email' ? value.trim() : value
      });
    } else {
      onChange('client')({
        ...data.client,
        [field]: field === 'email' ? value.trim() : value
      });
    }
  };

  const handleOpenTaxModal = () => {
    setIsTaxModalOpened(true);
  };

  const handleCloseTaxModal = () => {
    setIsTaxModalOpened(false);
  };

  const handleSubmitTax = (value: InvoiceTax) => {
    onChange('tax')(value);
    setIsTaxModalOpened(false);
  };

  const handleDeleteTax = () => {
    onChange('tax')(null);
  };

  const handleChangeType = (type: InvoiceTypeEnum) => () => {
    onChange('type')(type);
    onChange('billingInterval')(
      type === InvoiceTypeEnum.Recurring
        ? InvoiceBillingInterval.Monthly
        : InvoiceBillingInterval.None
    );
    onChange('intervalValue')(type === InvoiceTypeEnum.Recurring ? 1 : 0);
  };

  const handleChangeBillingInterval = (value: InvoiceBillingInterval) => {
    setBillingInterval(value);
    if (value === InvoiceBillingInterval.Custom) {
      onChange('billingInterval')(InvoiceBillingInterval.Monthly);
      onChange('intervalValue')(1);
    } else {
      onChange('billingInterval')(value);
      onChange('intervalValue')(1);
    }
  };

  const subTotal = data.services.reduce(
    (acc, s) => acc + (s.price || 0) * (s.quantity || 0),
    0
  );
  const tax = data.tax
    ? data.tax.type === TaxType.Amount
      ? data.tax.value
      : Math.round(subTotal * data.tax.value) / 100
    : 0;

  const handleIntervalChange = (value: string) => {
    onChange('intervalValue')(+value || 0);
  };

  return (
    <>
      <form className='CoachInvoiceForm p-md-3 mt-md-4 mt-5'>
        <div className='CoachInvoiceForm__section mb-sm-3 mb-2 py-4 px-lg-4 px-md-3 px-2'>
          <div className='row mx-n2 mt-n3 p-3'>
            <div className='col-sm-6 px-2'>
              <FormInput
                className='FormInput--inline'
                label='Date'
                value={''}
                noClassNameForChildren
              >
                <DayPickerModal
                  value={data.startDate}
                  onChange={onChange('startDate')}
                  disablePast={false}
                  isInvalid={
                    isValidationShown && !isFormInputValid('startDate', data)
                  }
                  errorMessage={getFormInputErrors('startDate', data)[0]}
                />
              </FormInput>
            </div>
            <div className='col-sm-6 px-2 d-flex flex-wrap align-items-end'>
              <FormInput
                type='number'
                handleChange={handleDurationChange}
                value={String(duration)}
                step={1}
                valueFormatter={formatValueAsInteger}
                isInvalid={
                  isValidationShown && !isFormInputValid('dueDate', data)
                }
                errorMessage={getFormInputErrors('dueDate', data)[0]}
                className='FormInput--inline CoachInvoiceForm__duration flex-shrink-0 mr-2'
                label='Due date'
                append='days'
                maxLength={3}
              />
              <p className='CoachInvoiceForm__duration-text'>
                After the specified date
              </p>
            </div>
          </div>
        </div>

        <div className='CoachInvoiceForm__section mb-sm-3 mb-2 py-4 px-lg-4 px-md-3 px-2'>
          <div className='CoachInvoiceForm__section-title px-3'>Recipient</div>
          <div className='row mx-n2 mt-n3 p-3'>
            <div className='col-sm-5 px-2'>
              <Autocomplete<CoachContactsViewModel>
                options={clients}
                optionKeyProp='clientId'
                value={data.client.email}
                onChange={handleClientFieldChange('email')}
                onSelect={handleClientChange}
                getOptionLabel={(opt) => opt.email}
                searchBy={searchClients}
                renderInput={({ value, ...rest }) => (
                  <FormInput
                    {...rest}
                    value={value}
                    name='email'
                    isInvalid={
                      isValidationShown &&
                      !isFormInputValid('client.email', data)
                    }
                    errorMessage={getFormInputErrors('client.email', data)[0]}
                    placeholder='Email'
                    isDisabled={!!data.id && !!data.client?.clientId}
                  />
                )}
                renderOption={(option, h) => (
                  <ClientAutocompleteItem item={option} highlight={h} />
                )}
              />
            </div>
            <div className='col-sm-7 row'>
              <div className='col-sm-6 px-2'>
                <FormInput
                  name='firstName'
                  value={data.client.firstName}
                  isInvalid={
                    isValidationShown &&
                    !isFormInputValid('client.firstName', data)
                  }
                  errorMessage={getFormInputErrors('client.firstName', data)[0]}
                  handleChange={handleClientFieldChange('firstName')}
                  placeholder='First name'
                  isDisabled={!!data.client.clientId}
                />
              </div>
              <div className='col-sm-6 px-2'>
                <FormInput
                  name='lastName'
                  value={data.client.lastName}
                  isInvalid={
                    isValidationShown &&
                    !isFormInputValid('client.lastName', data)
                  }
                  errorMessage={getFormInputErrors('client.lastName', data)[0]}
                  handleChange={handleClientFieldChange('lastName')}
                  placeholder='Last name'
                  isDisabled={!!data.client.clientId}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='CoachInvoiceForm__section mb-sm-3 mb-2 py-4 px-lg-4 px-md-3 px-2'>
          <div className='CoachInvoiceForm__section-title px-3'>Items</div>
          <CoachInvoiceFormItems
            items={data.services}
            isValidationShown={isValidationShown}
            onChange={onChange('services')}
          />

          <div className='row mx-n2 mt-sm-n5 mt-n3 p-3'>
            <div className='col-sm-6 offset-sm-6 p-2 row'>
              <div className='CoachInvoiceForm__subtotal col-12 py-2 pr-sm-5 d-flex justify-content-between'>
                <span>Subtotal</span>
                <span>${normalizePrice(subTotal)}</span>
              </div>
              <div className='col-12 py-2 mb-2 d-flex justify-content-between'>
                <div className='CoachInvoiceForm__tax d-flex justify-content-between flex-grow-1 mr-sm-2'>
                  <Button
                    className='ml-n1'
                    type='button'
                    variations={['naked']}
                    handleClick={handleOpenTaxModal}
                  >
                    {data.tax ? data.tax.name : 'Add tax'}
                  </Button>
                  {data.tax && <span>${normalizePrice(tax)}</span>}
                </div>
                {data.tax && (
                  <Button
                    className='CoachInvoiceForm__delete'
                    type='button'
                    variations={['naked']}
                    handleClick={handleDeleteTax}
                  >
                    <BucketIcon />
                  </Button>
                )}
              </div>
              <div className='CoachInvoiceForm__total col-12 pb-2 pt-3 pr-sm-5 d-flex justify-content-between'>
                <span>Total</span>
                <strong>${normalizePrice(subTotal + tax)}</strong>
              </div>
            </div>
          </div>
        </div>
        <div className='CoachInvoiceForm__section mb-sm-3 mb-2 py-4 px-lg-4 px-md-3 px-2'>
          <div className='CoachInvoiceForm__section-title px-3'>Payment</div>
          <div className='row mx-n2 p-3'>
            <div className='col-12 px-2'>
              <FormRadio
                id='oneTime'
                checked={data.type === InvoiceTypeEnum.OneTime}
                handleChange={handleChangeType(InvoiceTypeEnum.OneTime)}
                label='One time'
                className='FormRadio--button mr-3'
              />
              <FormRadio
                id='recurring'
                checked={data.type === InvoiceTypeEnum.Recurring}
                handleChange={handleChangeType(InvoiceTypeEnum.Recurring)}
                label='Recurring'
                className='FormRadio--button'
              />
            </div>
          </div>
          {data.type === InvoiceTypeEnum.Recurring && (
            <div className='d-flex  flex-wrap mx-n2 mt-n3 p-3 align-items-end'>
              <div className='px-2'>
                <Select
                  options={BILLING_OPTIONS}
                  value={billingInterval}
                  title='Billing period'
                  onChange={handleChangeBillingInterval}
                />
              </div>
              {billingInterval === InvoiceBillingInterval.Custom && (
                <div className='px-2'>
                  <FormInput
                    type='number'
                    step='1'
                    name='intervalValue'
                    maxLength={2}
                    value={String(data.intervalValue)}
                    valueFormatter={formatValueAsInteger}
                    isInvalid={
                      isValidationShown &&
                      !isFormInputValid('intervalValue', data)
                    }
                    errorMessage={getFormInputErrors('intervalValue', data)[0]}
                    handleChange={handleIntervalChange}
                    prepend='Every'
                    className='CoachInvoiceForm__interval-input'
                    append={
                      <Select
                        options={CUSTOM_BILLING_OPTIONS}
                        value={data.billingInterval}
                        onChange={onChange('billingInterval')}
                      />
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className='CoachInvoiceForm__section mb-sm-3 mb-2 py-4 px-lg-4 px-md-3 px-2'>
          <div className='CoachInvoiceForm__section-title px-3'>Notes</div>
          <div className='row mx-n2 mt-n3 p-3'>
            <div className='col-12 px-2'>
              <FormInput
                value={''}
                isInvalid={isValidationShown && !isFormInputValid('memo', data)}
                errorMessage={getFormInputErrors('memo', data)[0]}
              >
                <textarea
                  value={data.memo}
                  className='CoachInvoiceForm__memo Scrollbar'
                  placeholder='e.g. Thank you!'
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    onChange('memo')(event.target.value);
                  }}
                />
              </FormInput>
            </div>
          </div>
        </div>
      </form>
      <CoachInvoiceTaxModal
        isOpened={isTaxModalOpened}
        close={handleCloseTaxModal}
        onSubmit={handleSubmitTax}
        data={data.tax}
        options={taxOptions}
      />
    </>
  );
};

export default CoachInvoiceForm;
