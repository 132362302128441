import React from 'react';
import SearchInput from '../SearchInput/SearchInput';
import TabsMenu, { iTab } from '../TabsMenu/TabsMenu';
import { Payment } from '../../core/types';
import Table, { TableAction, TableColumn } from '../Table/Table';
import { useMedia } from '../../hooks';
import PaymentsItem from './PaymentsItem';
import EmptyState from '../EmptyState/EmptyState';
import EmptyStateIcon from '../../icons/empty-invoices.svg';
import { useSearchInput } from '../SearchInput/useSearchInput';
import { filterForSearch } from '../../pages/CoachPayments/CoachPayments.helpers';
import { SortOrder } from '../../core/enums';
import { useRef } from 'react';
import GlobalLoader from '../GlobalLoader/GlobalLoader';
// import { useHistory } from 'react-router-dom';
// import { AppScrollbarsContext } from '../../App';
import './PaymentsList.styles.scss';
import { usePagingSorting } from '../../hooks';

export interface Props {
  className?: string;
  tabs: iTab[];
  isLoading: boolean;
  payments: Payment[];
  columns: Array<TableColumn<Payment>>;
  actions: Array<TableAction<Payment>>;
  rowHeight: number;
  headerHeight?: number;
}

const PaymentsList: React.FC<Props> = ({
  className = '',
  tabs,
  isLoading,
  payments,
  columns,
  actions,
  rowHeight,
  headerHeight
}) => {
  // const { setScrollTo } = useContext(AppScrollbarsContext);
  const isTablet = useMedia(['(min-width: 576px)'], [true], false);
  // const history = useHistory();
  const scrollTarget = useRef<HTMLDivElement>();

  const { searchText, handleSearch, searchedItems } = useSearchInput(
    payments,
    filterForSearch
  );
  const {
    pageNumber,
    onPageChange,
    pageSize,
    getCurrentPageData,
    onSortChange,
    sort
  } = usePagingSorting<Payment>(searchedItems.length, isLoading, 20, {
    sortBy: 'date',
    sortOrder: SortOrder.Desc
  });

  const renderPayments = () => {
    if (isLoading) return null;

    if (searchedItems.length === 0) {
      return (
        <div
          className='position-relative flex-grow-1'
          style={{ minHeight: '280px' }}
        >
          <EmptyState
            size='md'
            text="You don't have payments yet"
            absoluteCenter
            icon={<EmptyStateIcon />}
            style={{ top: isTablet ? 'calc(50% - 50px)' : undefined }}
          />
        </div>
      );
    }

    return (
      <Table<Payment>
        sort={sort}
        totalCount={searchedItems.length}
        withPaging
        onPageChange={onPageChange}
        pageSize={pageSize}
        pageNumber={pageNumber}
        className={`Table--PaymentsList mt-sm-4 ${
          !searchedItems.length ? 'hidden' : ''
        }`}
        data={getCurrentPageData(searchedItems)}
        columns={columns}
        actions={actions}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        idField='id'
        isShownMobileRenderer={!isTablet}
        onSort={onSortChange}
        MobileRenderer={({ item, actions }) => (
          <PaymentsItem item={item} actions={actions} />
        )}
      />
    );
  };

  // const setScrollTarget = () => {
  //   scrollTarget.current && setScrollTo(scrollTarget.current);
  // };

  // useEffect(() => {
  //   setScrollTarget();
  //   return () => {
  //     setScrollTo(null);
  //   };
  // }, [scrollTarget, setScrollTo]);

  // const handleChangeTypeSelect = (path: string) => {
  //   setScrollTarget();
  //   history.push(path);
  // };

  // const linksForSelect = convertTabsToOptions(tabs);

  return (
    <div
      className={`PaymentsList d-flex flex-column ${className}`}
      ref={scrollTarget}
    >
      <div className='d-flex justify-content-between align-items-sm-center align-items-start flex-wrap flex-sm-row-reverse flex-column px-sm-4 pt-3'>
        <div className='pb-3 px-3 px-sm-0 d-flex aling-items-center justify-content-between justify-content-sm-end align-self-stretch flex-grow-1'>
          <div className='PaymentsList__title d-flex d-sm-none align-items-center'>
            Transactions
          </div>
          <SearchInput
            className='ml-3 SearchInput--PaymentsList'
            onSearch={handleSearch}
            searchText={searchText}
            collapsible
          />
        </div>
        <TabsMenu
          tabsList={tabs}
          className='mt-sm-0 mt-1 TabsMenu--PaymentsList Scrollbar flex-grow-1'
          // onClick={setScrollTarget}
        />
      </div>
      <GlobalLoader isLoading={isLoading} />
      {renderPayments()}
    </div>
  );
};

// const convertTabsToOptions = (tabs: iTabs[]) =>
//   tabs.map((t) => ({
//     id: t.link,
//     value: t.link,
//     name: t.name
//   }));

export default PaymentsList;
