import React from 'react';
import { CoachContactsViewModel } from '../../core/backend/api';
import RowWithDelete from '../RowWithDelete/RowWithDelete';
import AvatarIcon from '../../icons/avatar.svg';
import './SharedFilesContacts.stlyes.scss';
import { getPublicFile } from '../../core/api';

interface Props {
  contacts: CoachContactsViewModel[];
  onDelete: (id: string) => void;
}

const SharedFilesContacts: React.FC<Props> = ({ contacts, onDelete }) => {
  return (
    <div className='SharedFilesContacts'>
      <div className='SharedFilesContacts__title'>Shared with:</div>
      <div className='SharedFilesContacts__content Scrollbar'>
        {contacts.map((contact) => (
          <RowWithDelete
            onDelete={() => onDelete(contact.clientId)}
            key={contact.clientId}
            className='RowWithDelete_margin-bottom_8'
            item={
              <div className='SharedFilesContacts__contact'>
                <div className='SharedFilesContacts__avatar'>
                  {contact.imageId ? (
                    <img src={getPublicFile(contact.imageId)} alt='avatar' />
                  ) : (
                    <AvatarIcon />
                  )}
                </div>
                <div className='pr-2'>
                  <div className='SharedFilesContacts__name'>
                    {contact.firstName} {contact.lastName}
                  </div>
                  <div className='SharedFilesContacts__email'>
                    {contact.email}
                  </div>
                </div>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default SharedFilesContacts;
