import React, { useState, useEffect } from 'react';
import { NoteViewModel } from '../../../core/backend/api';
import Modal from '../../Modal/Modal';
import WYSIWYGEditor from '../../WYSIWYGEditor/WYSIWYGEditor';
import './NoteModal.styles.scss';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
import FormCheckbox from '../../FormCheckbox/FormCheckbox';
import Loader from '../../Loader/Loader';
import { useDebouncedCallback } from '../../../hooks';
import { isNoteInputValid } from '../../../pages/CoachClientNotes/CoachClientNotes.helpers';
import cn from 'classnames';
import { editNote, postNote } from '../../../core/api';
import {
  getFormErrors,
  isNoteDataValid
} from '../../../pages/CoachClientNotes/CoachClientNotes.helpers';

const initialErrorValues = {
  title: '',
  description: ''
};

interface Props {
  activeNote: NoteViewModel;
  isOpened: boolean;
  onClose: () => void;
  updateNotes: (newNote: NoteViewModel) => void;
}

const NoteModal: React.FC<Props> = ({
  activeNote,
  isOpened,
  onClose,
  updateNotes
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(initialErrorValues);
  const [isValidationShown, setIsValidationShown] = useState(false);

  const [note, setNote] = useState<NoteViewModel>(activeNote);

  const closeModal = () => {
    setErrors(initialErrorValues);
    setIsValidationShown(false);
    onClose();
  };

  const setLoadingDone = useDebouncedCallback(() => {
    setIsLoading(false);
  }, 450);

  const handleChangeField = (field: string) => (value: string | boolean) => {
    setNote((prevNote) => ({
      ...prevNote,
      [field]: value
    }));
  };

  const isFormValid = () => {
    setErrors(getFormErrors(note));
    return isNoteDataValid(note);
  };

  const handleSave = () => {
    if (isFormValid()) {
      setIsLoading(true);
      setIsValidationShown(false);
      (!note.id ? postNote(note) : editNote(note as NoteViewModel))
        .then((res) => {
          setErrors(initialErrorValues);
          updateNotes(res);
          closeModal();
        })
        .finally(() => {
          setLoadingDone();
        });
    } else {
      setIsValidationShown(true);
    }
  };

  useEffect(() => {
    setNote(activeNote);
  }, [activeNote]);

  const isDescriptionInvalid =
    isValidationShown && !isNoteInputValid('description', note);

  return (
    <Modal close={closeModal} isOpened={isOpened} style={{ width: 648 }}>
      <div className='NoteModal'>
        {isLoading && <Loader />}
        <div className='NoteModal__title'>{!note.id ? 'Add' : 'Edit'} Note</div>
        <FormInput
          autoFocus
          name='title'
          label='Note Title'
          value={note.title}
          errorMessage={errors.title}
          handleChange={handleChangeField('title')}
          placeholder='Give this note a title'
          isInvalid={isValidationShown && !isNoteInputValid('title', note)}
        />
        <FormInput
          value=''
          label='Note'
          errorMessage={errors.description}
          isInvalid={isDescriptionInvalid}
        >
          <WYSIWYGEditor
            wrapperClassName={cn('NoteModal__editor', {
              NoteModal__editor_error: isDescriptionInvalid
            })}
            onChange={handleChangeField('description')}
            HTMLString={note.description}
          />
        </FormInput>
        <FormCheckbox
          id='isShared'
          checked={note.isShared}
          handleChange={() => handleChangeField('isShared')(!note.isShared)}
          labelBefore='Share with Client'
          className='NoteModal_switch'
        />
        <div className='d-flex flex-column flex-sm-row align-items-sm-center justuify-content-sm-between'>
          <Button
            type='button'
            variations={['gray', 'full-width', 'sm-width']}
            handleClick={onClose}
            className='mr-sm-2'
          >
            Cancel
          </Button>
          <Button
            variations={['full-width', 'sm-width']}
            className='ml-sm-2'
            handleClick={handleSave}
            disabled={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
