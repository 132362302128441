import { DayOfWeek } from '../backend/api';

const Days = [
  { title: 'Sunday', value: DayOfWeek.NUMBER_0 },
  { title: 'Monday', value: DayOfWeek.NUMBER_1 },
  { title: 'Tuesday', value: DayOfWeek.NUMBER_2 },
  { title: 'Wednesday', value: DayOfWeek.NUMBER_3 },
  { title: 'Thursday', value: DayOfWeek.NUMBER_4 },
  { title: 'Friday', value: DayOfWeek.NUMBER_5 },
  { title: 'Saturday', value: DayOfWeek.NUMBER_6 }
];

export default Days;
