import React from 'react';
import { getPublicFile } from '../../core/api';
import { CoachContactsViewModel } from '../../core/backend/api';
import { highlightMatches } from '../../core/helpers';
import AvatarIcon from '../../icons/avatar-small.svg';
import './ClientAutocompleteItem.styles.scss';

interface Props {
  item: CoachContactsViewModel;
  highlight: string;
}

const ClientAutocompleteItem: React.FC<Props> = ({ item, highlight }) => {
  return (
    <div className='ClientAutocompleteItem'>
      <div className='ClientAutocompleteItem__image'>
        {item.imageId ? (
          <img src={getPublicFile(item.imageId)} alt='avatar' />
        ) : (
          <AvatarIcon />
        )}
      </div>
      <div className='ClientAutocompleteItem__content'>
        <div
          className='ClientAutocompleteItem__name'
          dangerouslySetInnerHTML={{
            __html: `${highlightMatches(
              item.firstName,
              highlight
            )} ${highlightMatches(item.lastName, highlight)}`
          }}
        />
        <div
          className='ClientAutocompleteItem__email'
          dangerouslySetInnerHTML={{
            __html: highlightMatches(item.email, highlight)
          }}
        />
      </div>
    </div>
  );
};

export default ClientAutocompleteItem;
