import moment from 'moment';
import { InvoiceServiceViewModel } from '../../../core/backend/api';
import {
  getInputErrors,
  isDataValid,
  isInputValid,
  isMaxValueValid,
  isMinValueValid,
  isNotEmpty,
  isNotEmptyArray,
  isEmail,
  isMaxlengthValid
} from '../../../core/formValidation';
import {
  Checker,
  iFormConfigItem,
  Invoice,
  iOption
} from '../../../core/types';
import { InvoiceBillingInterval, InvoiceTypeEnum } from '../../../core/enums';

const MEMO_MAX_LENGTH = 1000;
const MAX_NAME_LENGTH = 255;

const MAX_CUSTOM_PERIOD_LENGHT = {
  [InvoiceBillingInterval.None]: 99,
  [InvoiceBillingInterval.Daily]: 99,
  [InvoiceBillingInterval.Weekly]: 52,
  [InvoiceBillingInterval.Monthly]: 12,
  [InvoiceBillingInterval.Yearly]: 1,
  [InvoiceBillingInterval.Custom]: 1
};

export const BILLING_OPTIONS: iOption[] = [
  {
    id: InvoiceBillingInterval.Daily,
    name: 'Daily'
  },
  {
    id: InvoiceBillingInterval.Weekly,
    name: 'Weekly'
  },
  {
    id: InvoiceBillingInterval.Monthly,
    name: 'Monthly'
  },
  {
    id: InvoiceBillingInterval.Yearly,
    name: 'Yearly'
  },
  {
    id: InvoiceBillingInterval.Custom,
    name: 'Custom'
  }
];

export const CUSTOM_BILLING_OPTIONS: iOption[] = [
  {
    id: InvoiceBillingInterval.Daily,
    name: 'Days'
  },
  {
    id: InvoiceBillingInterval.Weekly,
    name: 'Weeks'
  },
  {
    id: InvoiceBillingInterval.Monthly,
    name: 'Months'
  }
];

const isNameShortEnough = isMaxlengthValid(MAX_NAME_LENGTH);

const isDueDateMaxValid = (value: number, data: Partial<Invoice>) => {
  const diff = moment.utc(value).diff(moment.utc(data.startDate), 'day');
  return diff < 1000;
};

const isDueDateMinValid = (value: number, data: Partial<Invoice>) => {
  const diff = moment.utc(value).diff(moment.utc(data.startDate), 'day');
  return diff > 0;
};

const isIntervalNotEmpty = (value: number, data: Partial<Invoice>) => {
  if (data.type === InvoiceTypeEnum.Recurring) {
    return isNotEmpty(value);
  }
  return true;
};

const isIntervalMaxValid = (value: number, data: Partial<Invoice>) => {
  const { type, billingInterval } = data;

  if (type === InvoiceTypeEnum.Recurring) {
    return isMaxValueValid(MAX_CUSTOM_PERIOD_LENGHT[billingInterval])(value);
  }
  return true;
};

const isIntervalMinValid = (value: number, data: Partial<Invoice>) => {
  if (data.type === InvoiceTypeEnum.Recurring) {
    return isMinValueValid(1)(value);
  }
  return true;
};

const FormConfig: iFormConfigItem[] = [
  { field: 'startDate', checkIsValid: [isNotEmpty] },
  {
    field: 'dueDate',
    checkIsValid: [isNotEmpty, isDueDateMinValid, isDueDateMaxValid]
  },
  { field: 'client.email', checkIsValid: [isNotEmpty, isEmail] },
  { field: 'services', checkIsValid: [isNotEmptyArray] },
  {
    field: 'intervalValue',
    checkIsValid: [isIntervalNotEmpty, isIntervalMinValid, isIntervalMaxValid]
  },
  { field: 'memo', checkIsValid: [isMaxlengthValid(MEMO_MAX_LENGTH)] }
];

const ServiceFormConfig: iFormConfigItem[] = [
  {
    field: 'title',
    checkIsValid: [isNotEmpty, isNameShortEnough]
  },
  {
    field: 'quantity',
    checkIsValid: [isNotEmpty, isMinValueValid(1), isMaxValueValid(100)]
  },
  {
    field: 'price',
    checkIsValid: [isNotEmpty, isMinValueValid(0.5), isMaxValueValid(10000)]
  }
];

export function isFormInputValid(inputField: string, data: Partial<Invoice>) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isServiceInputValid(
  inputField: string,
  data: InvoiceServiceViewModel
) {
  return isInputValid(ServiceFormConfig)(inputField, data);
}

export function isFormDataValid(data: Invoice) {
  return (
    isDataValid(FormConfig)(data) &&
    data.services.reduce(
      (acc, service) => acc && isServiceDataValid(service),
      true
    )
  );
}

export function isServiceDataValid(data: InvoiceServiceViewModel) {
  return isDataValid(ServiceFormConfig)(data);
}

export function getFormInputErrors(inputField: string, data: Partial<Invoice>) {
  return getInputErrors(FormConfig, (checker: Checker) => {
    switch (checker) {
      case FormConfig.find((c) => c.field === 'memo').checkIsValid[0]: {
        return `should have at most ${MEMO_MAX_LENGTH} characters`;
      }
      case FormConfig.find((c) => c.field === 'dueDate').checkIsValid[1]: {
        return 'minimum value is 1';
      }
      case FormConfig.find((c) => c.field === 'dueDate').checkIsValid[2]: {
        return 'maximum value is 999';
      }
      case FormConfig.find((c) => c.field === 'services').checkIsValid[0]: {
        return 'Please add item';
      }
      case FormConfig.find((c) => c.field === 'intervalValue')
        .checkIsValid[0]: {
        return 'should not be empty';
      }
      case FormConfig.find((c) => c.field === 'intervalValue')
        .checkIsValid[1]: {
        return 'minimum value is 1';
      }
      case FormConfig.find((c) => c.field === 'intervalValue')
        .checkIsValid[2]: {
        return `maximum value is ${
          MAX_CUSTOM_PERIOD_LENGHT[data.billingInterval]
        }`;
      }
      default:
        return undefined;
    }
  })(inputField, data);
}

export function getServiceInputErrors(
  inputField: string,
  data: InvoiceServiceViewModel
) {
  return getInputErrors(ServiceFormConfig, (checker: Checker) => {
    switch (checker) {
      case ServiceFormConfig.find((c) => c.field === 'quantity')
        .checkIsValid[1]: {
        return 'minimum quantity is 1';
      }
      case ServiceFormConfig.find((c) => c.field === 'quantity')
        .checkIsValid[2]: {
        return 'maximum quantity is 100';
      }
      case ServiceFormConfig.find((c) => c.field === 'price').checkIsValid[1]: {
        return 'minimum price is 0.5';
      }
      case ServiceFormConfig.find((c) => c.field === 'price').checkIsValid[2]: {
        return 'maximum price is 10,000';
      }
      case isNameShortEnough: {
        return `should have at most ${MAX_NAME_LENGTH} characters`;
      }
      default:
        return undefined;
    }
  })(inputField, data);
}
