import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import './CoachClients.styles.scss';
import FormInput from '../../components/FormInput/FormInput';
import { columns } from './CoachClients.helpers';
import Button from '../../components/Button/Button';
import {
  // deleteContact,
  resetContactPassword
} from '../../core/api';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import Loader from '../../components/Loader/Loader';
import { useStores, useDebouncedCallback, useMedia } from '../../hooks';
import { CoachRoutesEnum, SortOrder } from '../../core/enums';
import { RouteComponentProps } from 'react-router-dom';
import ContactModal from '../../components/Modals/ContactModal/ContactModal';
import Table from '../../components/Table/Table';
import { CoachContactItem } from '../../core/types';
import { TableAction } from '../../components/Table/Table';
import ViewIcon from '../../icons/view-icon.svg';
import AddClientIcon from '../../icons/add_client.svg';
import EmptyClients from '../../images/empty-clients.svg';
import ShareIcon from '../../icons/share.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';
import CoachClientsItem from './CoachClientsItem';
import { defaultTableSearchFn } from '../../components/Table/Table.helpers';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useContactModal } from '../../components/Modals/ContactModal/useContactModal';
import { usePagingSorting } from '../../hooks';
interface ConfirmationData {
  title: string;
  confirmBtnText: string;
  cancelBtnText?: string;
  callback: () => void;
}

const CoachClients: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    rootStore: { notificationStore, contactsStore }
  } = useStores();
  const isTablet = useMedia(['(min-width: 576px)'], [true], false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { searchText, handleSearch, searchedItems: data } = useSearchInput(
    contactsStore.contacts,
    defaultTableSearchFn(columns)
  );
  const {
    pageNumber,
    onPageChange,
    pageSize,
    getCurrentPageData
  } = usePagingSorting(data.length, isLoading);

  // const [period, setPeriod] = useState<string>(periods[0].id);
  // const [visibleColumnsIds, setVisibleColumnsIds] = useState<string[]>(
  //   columns.map((col) => col.field)
  // );

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [confirmationModalData, setConfirmationModalData] = useState<
    ConfirmationData
  >(null);
  const [isSubmittingReset, setIsSubmittingReset] = useState<boolean>(false);

  const {
    isContactModalOpened,
    onOpenContactModal,
    onCloseContactModal,
    isSubmitting,
    onSaveContactData
  } = useContactModal();

  const setIsConfirmationDone = useDebouncedCallback(() => {
    setConfirmationModalData(null);
    setIsSubmittingReset(false);
  }, 450);

  const reFetchData = useCallback(async () => {
    setIsLoading(true);
    await contactsStore.getCoachContacts();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    reFetchData();
  }, [reFetchData]);

  const onView = (item: CoachContactItem) => {
    history.push(
      CoachRoutesEnum.CoachClientSchedule.replace(':clientId', item.clientId)
    );
  };

  /*   const onDelete = (item: CoachContactItem) => {
    setIsConfirmModalOpened(true);
    setConfirmationModalData({
      title: `Are you sure you want to delete "${item.fullName}"?`,
      confirmBtnText: 'Yes',
      cancelBtnText: 'No',
      callback: () => confirmDelete(item.clientId)
    });
  };
 */
  const onResendPassword = (item: CoachContactItem) => {
    setIsConfirmModalOpened(true);
    setConfirmationModalData({
      title: `Are you sure you want to resend password for "${item.fullName}"?`,
      confirmBtnText: 'Resend',
      callback: () => confirmResendPassword(item.clientId)
    });
  };

  /*   const confirmDelete = (id: string) => {
    setIsSubmitting(true);
    deleteContact(id).then(() => {
      setIsConfirmModalOpened(false);
      setIsConfirmationDone();
      reFetchData();
      notificationStore!.addNotification({
        text: 'Contact',
        textColored: 'was deleted',
        duration: 3500
      });
    });
  }; */

  const confirmResendPassword = (id: string) => {
    setIsSubmittingReset(true);
    resetContactPassword(id).then(() => {
      setIsConfirmModalOpened(false);
      setIsConfirmationDone();
      notificationStore!.addNotification({
        text: 'Password',
        textColored: 'was resent',
        duration: 3500
      });
    });
  };

  const actions: TableAction<CoachContactItem>[] = [
    {
      title: 'View',
      callback: onView,
      icon: <ViewIcon />
    },
    {
      title: 'Resend password',
      callback: onResendPassword,
      icon: <ShareIcon />,
      isAvailable: (item) => !item.isGuest
    }
    // {
    //   title: 'Delete',
    //   callback: onDelete,
    //   icon: <BucketIcon />
    // }
  ];

  const handleItemClick = ({ clientId }: CoachContactItem) => () => {
    history.push(
      CoachRoutesEnum.CoachClientSchedule.replace(':clientId', clientId)
    );
  };

  const renderItems = () => {
    if (isLoading) {
      return (
        <div className='CoachClients__table-loader'>
          <Loader />
        </div>
      );
    }

    if (data.length === 0) {
      return (
        <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
          <EmptyState
            className='my-4'
            icon={<EmptyClients />}
            text='There are no clients'
          />
        </div>
      );
    }

    return (
      <Table<CoachContactItem>
        clientSorting
        withPaging
        totalCount={data.length}
        onPageChange={onPageChange}
        pageSize={pageSize}
        pageNumber={pageNumber}
        data={getCurrentPageData(data)}
        columns={columns}
        actions={actions}
        baseUrl={CoachRoutesEnum.CoachClientSchedule}
        idField='clientId'
        searchText={searchText}
        className='Table--CoachClients'
        headerHeight={56}
        rowHeight={73}
        sort={{
          sortBy: 'fullName' as keyof CoachContactItem,
          sortOrder: SortOrder.Asc
        }}
        isShownMobileRenderer={!isTablet}
        MobileRenderer={({ item }) => (
          <CoachClientsItem
            item={item}
            onClick={handleItemClick}
            actions={actions}
            nameOption='name'
            searchText={searchText}
          />
        )}
        onRowClick={handleItemClick}
      />
    );
  };

  return (
    <>
      <PageTitle title='Clients' />
      <div className='CoachClients'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <div className='CoachClients__title'>Clients</div>
          <Button
            handleClick={onOpenContactModal}
            className='button__sm-width flex-shrink-0 CoachClients__addBtn'
          >
            <AddClientIcon /> Add contact
          </Button>
        </div>

        <div className='CoachClients__content p-sm-4 p-3'>
          <FormInput
            className='FormInput--CoachClients mb-3 mt-0'
            name='search'
            label=''
            value={searchText}
            handleChange={handleSearch}
            placeholder='Search'
          />
          {/*<div className='CoachClients__table-tools'>
          <div className="CoachClients__table-tools-text">{'88'} selected items</div>
            <Button
            handleClick={() => {}}
            className="button__naked"
            >
            Send emails
            </Button>
            <FormCheckbox
            className="FormCheckbox--activated"
            id="activate"
            checked={!isActivated}
            handleChange={() => setIsActivated(!isActivated)}
            labelBefore="Deactivate"
            label="Activate"
            />
            <Button
            handleClick={() => {}}
            className="button__naked button__delete"
            >
            Delete
            </Button>
             <Select
              options={columns}
              multipleSelectValue={visibleColumnsIds}
              title={' '}
              onChange={(value) => setVisibleColumnsIds(value)}
              isCheckboxHidden={false}
              className="Select--CoachClients"
            >
              <Button className="button__CoachClients button__inversed">
                +
              </Button>
            </Select>
        </div> */}
          {renderItems()}
        </div>
      </div>

      <ContactModal
        isOpened={isContactModalOpened}
        close={onCloseContactModal}
        onSave={onSaveContactData}
        isSubmitting={isSubmitting}
        emailsInUse={contactsStore.contacts.map((contact) => contact.email)}
      />
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={() => setIsConfirmModalOpened(false)}
        title={confirmationModalData?.title}
        disableConfirmBtn={isSubmittingReset}
        confirmBtnText={confirmationModalData?.confirmBtnText}
        cancelBtnText={confirmationModalData?.cancelBtnText}
        confirmCallback={confirmationModalData?.callback}
      />
    </>
  );
};

export default observer(CoachClients);
