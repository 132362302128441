import React, {
  useState,
  useEffect,
  FormEvent,
  useContext,
  useCallback,
  useRef
} from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import './CoachSchedule.styles.scss';
import {
  getDefaultData,
  isFormInputValid,
  getFormInputErrors,
  isFormDataValid,
  reminders
} from './CoachSchedule.helpers';
import {
  getDefaultData as getContactDefaultData,
  isContactInputValid,
  isContactDataValid,
  getContactInputErrors
} from '../../components/Modals/ContactModal/ContactModal.helpers';
import FormInput from '../../components/FormInput/FormInput';
import {
  updateEvent,
  createEvent,
  addContact,
  getEvent,
  getPublicFile
} from '../../core/api';

import { CoachEvent } from '../../core/types';
import {
  getOffsetTop,
  highlightMatches,
  isMobileDevice,
  getServerModelValidation
} from '../../core/helpers';
import WYSIWYGEditor from '../../components/WYSIWYGEditor/WYSIWYGEditor';
import { formatValueAsPrice } from '../../core/formValidation';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { AppScrollbarsContext } from '../../App';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import DatePickerModal from '../../components/DayPickerModal/DayPickerModal';
import AvatarIcon from '../../icons/avatar-small.svg';
import { CoachContactsViewModel } from '../../core/backend/api';
import TimePickerDropdown from '../../components/TimePickerDropdown/TimePickerDropdown';
import ArrowIcon from '../../icons/arrow-left.svg';
import { CoachRoutesEnum, LocationTypeEnum } from '../../core/enums';
import Location, { iLocation } from '../../components/Location/Location';
import {
  getZoomConnectUrl,
  restoreDataAfterStripeConnect,
  restoreDataAfterZoomConnect,
  storeDataBeforeStripeConnect,
  storeDataBeforeZoomConnect,
  STRIPE_CONNECTED_KEY,
  ZOOM_CONNECTED_KEY
} from '../../core/integrations';
import { useFeatures, useMedia, useStores } from '../../hooks';
import CustomizedPhoneInput from '../../components/CustomizedPhoneInput';
import Warning from '../../components/Warning/Warning';
import PageTitle from '../../components/PageTitle/PageTitle';
import StripeConnectHint from '../../components/StripeConnectHint/StripeConnectHint';

const CoachSchedule = () => {
  const {
    rootStore: { contactsStore, notificationStore, userStore }
  } = useStores();

  const { zoomEnabled } = useFeatures();

  const { search, pathname } = useLocation();
  const { meetingId } = useParams<{ meetingId: string }>();
  const history = useHistory();
  const params = new URLSearchParams(search);

  const date = +params.get('date');
  const isAfterZoomConnected = !!params.get(ZOOM_CONNECTED_KEY);
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);
  const isAfterIntegrationConnectRef = useRef(false);

  const scroll = useContext(AppScrollbarsContext);

  const [localData, setLocalData] = useState<CoachEvent>(getDefaultData(date));
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});
  const [contactData, setContactData] = useState<CoachContactsViewModel>(
    getContactDefaultData()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isContactsShown, setIsContactsShown] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const [startTimeMsFieldIsDirty, setStartTimeMsFieldIsDirty] = useState(false);
  // const [selectedBefore, setSelectedBefore] = useState<string>(buffers[0].id);
  // const [selectedAfter, setSelectedAfter] = useState<string>(buffers[0].id);
  const [reminderTime, setReminderTime] = useState<string>(reminders[4].id);
  // const [hasBuffers, setHasBuffers] = useState<boolean>(false);
  const [initialLocation, setInitialLocation] = useState<iLocation>();
  const { isStripeConnected = false, isZoomConnected = false, id: userId } =
    userStore || {};

  const hasConfirmedMember = !!localData.members.length;

  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const placeholder = useMedia(
    ['(min-width: 1070px)'],
    ["Start typing client's email to send an invite"],
    "Start typing client's email"
  );

  const handleChangeField = useCallback(
    (field: string) => (value: any) => {
      setLocalData((prev) => ({
        ...prev,
        [field]: value
      }));
    },
    []
  );

  useEffect(() => {
    if (isAfterStripeConnected || isAfterZoomConnected) {
      history.replace(pathname);
      isAfterIntegrationConnectRef.current = true;
    }
    // eslint-disable-next-line
  }, [isAfterStripeConnected, isAfterZoomConnected]);

  useEffect(() => {
    if (
      userId &&
      !isLoading &&
      !localData.id &&
      zoomEnabled &&
      !isAfterIntegrationConnectRef.current
    ) {
      handleChangeField('locationType')(
        isZoomConnected ? LocationTypeEnum.Zoom : LocationTypeEnum.Custom
      );
    }
  }, [
    userId,
    localData.id,
    isLoading,
    zoomEnabled,
    handleChangeField,
    isZoomConnected
  ]);

  useEffect(() => {
    if (isValidationShown) {
      const invalidElement = document.querySelector<HTMLElement>(
        '.FormInput__input--error, .WeekCalendar__invalid, .Select__input--error'
      );

      if (invalidElement) {
        scroll?.scrollTop(
          getOffsetTop(invalidElement, scroll?.container) - 60 - 30
        );
      }
    }
  }, [isValidationShown, scroll]);

  useEffect(() => {
    const restoreData = isAfterZoomConnected
      ? restoreDataAfterZoomConnect<{
          data: CoachEvent;
          contactData: CoachContactsViewModel;
        }>(true).data
      : isAfterStripeConnected
      ? restoreDataAfterStripeConnect<{
          data: CoachEvent;
          contactData: CoachContactsViewModel;
        }>(true).data
      : null;

    if (restoreData) {
      setDataFromSource(restoreData.data);
      setContactData(restoreData.contactData);
    } else if (+meetingId) {
      setIsLoading(true);
      getEvent(+meetingId).then((response) => {
        setIsLoading(false);
        setDataFromSource(response);
        setInitialLocation({
          location: response.location,
          locationType: response.locationType,
          showLocationWhileBooking: response.showLocationWhileBooking
        });
        const contact = contactsStore.contacts.find((c) =>
          response.members.length
            ? response.members[0].clientId === c.clientId
            : response.invites
                .map((i) => i.toLowerCase())
                .includes(c.email.toLowerCase())
        );
        if (contact && !contactData.clientId) {
          setContactData(contact);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (localData.id) {
      const contact = contactsStore.contacts.find((c) =>
        localData.invites
          .map((i) => i.toLowerCase())
          .includes(c.email.toLowerCase())
      );
      if (contact && !contactData.clientId) {
        setContactData(contact);
      }
    }
  }, [contactsStore, localData]);

  useEffect(() => {
    if (localData.startDateTimeStamp > localData.endDateTimeStamp) {
      handleChangeField('endDateTimeStamp')(localData.startDateTimeStamp);
    }
  }, [localData.startDateTimeStamp]);

  useEffect(() => {
    if (localData.startDateTimeStamp > localData.endDateTimeStamp) {
      handleChangeField('startDateTimeStamp')(localData.endDateTimeStamp);
    }
  }, [localData.endDateTimeStamp]);

  useEffect(() => {
    if (startTimeMsFieldIsDirty) {
      handleChangeField('endTimeMs')(localData.startTimeMs + 3600000);
    }
  }, [localData.startTimeMs]);

  const handleChange = useCallback((value: Partial<CoachEvent>) => {
    setLocalData((prevLocalData) => ({
      ...prevLocalData,
      ...value
    }));
  }, []);

  const isDayInputsInvalid =
    localData.startDateTimeStamp > localData.endDateTimeStamp;
  const isTimeInputsInvalid =
    localData.startDateTimeStamp === localData.endDateTimeStamp &&
    localData.startTimeMs >= localData.endTimeMs;

  const isFormValid = () => {
    return (
      isFormDataValid(localData) &&
      isContactDataValid(contactData) &&
      !(localData.locationType === LocationTypeEnum.Zoom && !isZoomConnected) &&
      !isDayInputsInvalid &&
      !isTimeInputsInvalid
    );
  };

  const handleConnectZoom = () => {
    const data = getDataForSave();
    storeDataBeforeZoomConnect(pathname, { data, contactData });
  };

  const handleConnectStripe = () => {
    const data = getDataForSave();
    storeDataBeforeStripeConnect(pathname, { data, contactData });
  };

  const getDataForSave = () => {
    const reminderOption = reminders.find((item) => item.id === reminderTime);

    return {
      ...localData,
      remindBeforeMs: reminderOption!.value
    };
  };

  const setDataFromSource = (source: CoachEvent) => {
    setLocalData(source);

    const { remindBeforeMs } = source;

    if (remindBeforeMs) {
      const item = reminders.find((item) => item.value === remindBeforeMs);
      setReminderTime(item!.id);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsValidationShown(false);
    setTimeout(() => {
      if (isFormValid()) {
        save();
      } else {
        setIsValidationShown(true);
      }
    });
  };

  const handleCancel = () => {
    if (isAfterIntegrationConnectRef.current) {
      return history.push(CoachRoutesEnum.Calendar);
    }
    history.goBack();
  };
  const save = () => {
    const updateMethod = localData.id ? updateEvent : createEvent;
    setIsLoading(true);
    const data = getDataForSave();

    (!contactData.clientId ? addContact(contactData) : Promise.resolve({}))
      .then(() => {
        if (!contactData.clientId) {
          contactsStore.getCoachContacts();
        }
      })
      .finally(() => {
        updateMethod({
          ...data,
          invites: [contactData.email]
        })
          .then(() => {
            handleCancel();
            notificationStore.addNotification({
              text: `Meeting is ${localData.id ? 'updated' : 'scheduled'}`
            });
          })
          .catch((error) => {
            setIsValidationShown(true);
            const { locationType, ...rest } = getServerModelValidation(error);
            if (locationType) {
              userStore.updateData({ isZoomConnected: false });
            }
            setServerValidation(rest);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  const handleChangeContactField = (field: string) => (value: any) => {
    setContactData({
      ...contactData,
      [field]: value
    });
  };

  const handleBlurContactEmail = () => {
    setIsContactsShown(false);
    const contact = contactsStore.contacts.find(
      (c) => c.email.toLowerCase() === contactData.email.toLowerCase()
    );
    if (contact && !contactData.clientId) {
      setContactData({ ...contact });
    }
  };

  const handleChangeContactEmail = (value: any) => {
    if (contactData.clientId) {
      setContactData({ ...getContactDefaultData(), email: value.trim() });
    } else {
      setContactData({
        ...contactData,
        email: value.trim()
      });
    }
  };

  const handleSelectContact = (contact: CoachContactsViewModel) => {
    setContactData({ ...contact });
  };

  const trimmedInputValue = contactData.email.trim();

  const matches = contactsStore.contacts.filter(
    (contact) =>
      trimmedInputValue !== '' &&
      (contact.email.toLowerCase().indexOf(trimmedInputValue.toLowerCase()) >=
        0 ||
        contact.firstName
          .toLowerCase()
          .indexOf(trimmedInputValue.toLowerCase()) >= 0 ||
        contact.lastName
          .toLowerCase()
          .indexOf(trimmedInputValue.toLowerCase()) >= 0)
  );

  const isInvalidZoomMeeting =
    meetingId &&
    !userStore.isZoomConnected &&
    localData.locationType === LocationTypeEnum.Zoom;

  return (
    <>
      <PageTitle title='Schedule' />
      <GlobalLoader isLoading={isLoading} />
      <div className='mb-sm-3 mb-2 pb-1'>
        <Button variations={['naked']} handleClick={handleCancel}>
          <ArrowIcon />
          <span className='ml-2'>Back</span>
        </Button>
      </div>
      <form className='CoachSchedule' onSubmit={handleSubmit}>
        <div className='row justify-content-between align-items-center mb-6'>
          <div className='col'>
            <div className='CoachSchedule__title mb-2 d-flex align-items-center'>
              <span className='mr-3'>
                {localData.id ? 'Edit' : 'Add to'} schedule
              </span>
            </div>
          </div>
        </div>

        <div className='CoachSchedule__section-title'>Client details</div>
        <div className='row mx-n2 pb-4'>
          <div className='col-sm-6 px-2'>
            <div className='Contacts__wrapper'>
              <FormInput
                labelClassName='d-flex'
                autoFocus={
                  !isMobileDevice() &&
                  !(isAfterStripeConnected || isAfterZoomConnected) &&
                  !contactData.email
                }
                tabIndex={isMobile ? 1 : 0}
                name='email'
                label='Add client'
                hint='Start typing your existing client&#8242;s email for auto filling or
                  invite a new client by email.'
                value={contactData.email}
                isInvalid={
                  isValidationShown &&
                  !isContactInputValid('email', contactData)
                }
                errorMessage={getContactInputErrors('email', contactData)[0]}
                handleChange={handleChangeContactEmail}
                placeholder={placeholder}
                onBlur={handleBlurContactEmail}
                onFocus={() => setIsContactsShown(true)}
                isDisabled={!!meetingId}
                data-testid='schedule-client-select'
              />

              {isContactsShown && matches.length > 0 && (
                <div className='Contacts'>
                  <Scrollbars>
                    {matches.map((contact) => (
                      <div
                        className='Contact'
                        key={contact.clientId!}
                        onMouseDown={() => handleSelectContact(contact)}
                      >
                        <div className='Contact__image'>
                          {contact.imageId ? (
                            <img
                              src={getPublicFile(contact.imageId)}
                              alt='avatar'
                            />
                          ) : (
                            <AvatarIcon />
                          )}
                        </div>
                        <div className='Contact__content'>
                          <div
                            className='Contact__name'
                            dangerouslySetInnerHTML={{
                              __html: `${highlightMatches(
                                contact.firstName,
                                trimmedInputValue
                              )} ${highlightMatches(
                                contact.lastName,
                                trimmedInputValue
                              )}`
                            }}
                          />
                          <div
                            className='Contact__email'
                            dangerouslySetInnerHTML={{
                              __html: highlightMatches(
                                contact.email,
                                trimmedInputValue
                              )
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </Scrollbars>
                </div>
              )}
            </div>

            <FormInput
              name='firstName'
              label='First name'
              value={contactData.firstName}
              tabIndex={isMobile ? 2 : 0}
              isInvalid={
                isValidationShown &&
                !isContactInputValid('firstName', contactData)
              }
              errorMessage={getContactInputErrors('firstName', contactData)[0]}
              handleChange={handleChangeContactField('firstName')}
              placeholder='First name'
              isDisabled={!!contactData.clientId}
            />
          </div>
          <div className='col-sm-6 px-2 d-flex flex-column'>
            <FormInput
              className='order-2 order-sm-1'
              name='number'
              label='Phone Number'
              value={contactData.phoneNumber}
              isInvalid={
                isValidationShown &&
                !isContactInputValid('phoneNumber', contactData)
              }
              errorMessage='Please input correct phone number'
              isDisabled={!!contactData.clientId}
            >
              <CustomizedPhoneInput
                value={contactData.phoneNumber}
                onChange={handleChangeContactField('phoneNumber')}
                inputProps={{
                  tabIndex: isMobile ? 4 : 0
                }}
                disabled={!!contactData.clientId}
              />
            </FormInput>
            <FormInput
              className='order-1 order-sm-2'
              name='lastName'
              label='Last name'
              tabIndex={isMobile ? 3 : 0}
              value={contactData.lastName}
              isInvalid={
                isValidationShown &&
                !isContactInputValid('lastName', contactData)
              }
              errorMessage={getContactInputErrors('lastName', contactData)[0]}
              handleChange={handleChangeContactField('lastName')}
              placeholder='Last name'
              isDisabled={!!contactData.clientId}
            />
          </div>
        </div>

        <div className='CoachSchedule__section-title'>Meeting details</div>
        <div className='row mx-n2 pb-4'>
          <div className='col-md-6 px-2'>
            <FormInput
              handleChange={handleChangeField('name')}
              value={localData.name}
              tabIndex={isMobile ? 5 : 0}
              label='Title'
              isInvalid={
                isValidationShown && !isFormInputValid('name', localData)
              }
              errorMessage={getFormInputErrors('name', localData)[0]}
              placeholder='Add title'
              data-testid='schedule-title-input'
            />
          </div>
          <div className='col-md-6 px-2'>
            <FormInput handleChange={() => {}} value={''} label='Description'>
              <WYSIWYGEditor
                tabIndex={isMobile ? 6 : 0}
                wrapperClassName='flex-grow-1'
                onChange={handleChangeField('description')}
                HTMLString={localData.description}
              />
            </FormInput>
          </div>
        </div>

        <div className='CoachSchedule__group row pb-3'>
          <div className='col-md-6 pr-md-2'>
            <FormInput
              label='Starts'
              value={''}
              noClassNameForChildren
              isDisabled={isInvalidZoomMeeting}
            >
              <div className='d-flex flex-grow-1 flex-sm-nowrap row'>
                <div className='mb-sm-0 mb-4 mr-0 mr-sm-3 col-sm col-12'>
                  <DatePickerModal
                    tabIndex={isMobile ? 7 : 0}
                    value={localData.startDateTimeStamp}
                    onChange={handleChangeField('startDateTimeStamp')}
                    isInvalid={isValidationShown && isDayInputsInvalid}
                    errorMessage='Please check the date'
                    isDisabled={isInvalidZoomMeeting}
                  />
                </div>
                <TimePickerDropdown
                  className={'col-sm-4 col-12 '}
                  timeMs={localData.startTimeMs}
                  minuteStep={15}
                  onChange={(value) => {
                    setStartTimeMsFieldIsDirty(true);
                    handleChangeField('startTimeMs')(value);
                  }}
                  isInvalid={isValidationShown && isTimeInputsInvalid}
                  errorMessage='Please check the time'
                  isDisabled={isInvalidZoomMeeting}
                />
              </div>
            </FormInput>
          </div>
          <div className='col-md-6 pr-md-2'>
            <FormInput
              label='Ends'
              value={''}
              noClassNameForChildren
              isDisabled={isInvalidZoomMeeting}
            >
              <div className='d-flex flex-grow-1 flex-sm-nowrap row'>
                <div className='mb-sm-0 mb-4 mr-0 mr-sm-3 col-sm col-12'>
                  <DatePickerModal
                    tabIndex={isMobile ? 8 : 0}
                    value={localData.endDateTimeStamp}
                    onChange={handleChangeField('endDateTimeStamp')}
                    isInvalid={isValidationShown && isDayInputsInvalid}
                    errorMessage='Please check the date'
                    isDisabled={isInvalidZoomMeeting}
                  />
                </div>
                <TimePickerDropdown
                  className={'col-sm-4 col-12 '}
                  timeMs={localData.endTimeMs}
                  minuteStep={15}
                  onChange={handleChangeField('endTimeMs')}
                  isInvalid={isValidationShown && isTimeInputsInvalid}
                  errorMessage='Please check the time'
                  isDisabled={isInvalidZoomMeeting}
                />
              </div>
            </FormInput>
          </div>
          {isInvalidZoomMeeting && (
            <div className='col mt-3'>
              <Warning>
                You can't update the date &amp; time when Zoom is disconnected.
                Change the location type to Custom to proceed with changes.
              </Warning>
            </div>
          )}
        </div>
        <div className='CoachSchedule__group'>
          {/* <div className='CoachSchedule__subtitle CoachSchedule__subtitle--small'>
            Buffers
          </div>
          <div className='row pb-3 mx-n2'>
            <div className='col-6 px-2'>
              <FormCheckbox
                id='hasBlockTime'
                checked={hasBuffers}
                handleChange={() => setHasBuffers(!hasBuffers)}
                label='Set an amount of time to block before and/or after a client schedules'
              />
            </div>
          </div> */}

          <div className='row pb-3 pt-3 mx-n2'>
            {/* <div className='col-6 px-2 d-flex'>
              <Select
                options={buffers}
                isDisabled={!hasBuffers}
                value={selectedBefore}
                title={'Before'}
                onChange={(value) => setSelectedBefore(value)}
              />
              <Select
                className='mr-0'
                options={buffers}
                isDisabled={!hasBuffers}
                value={selectedAfter}
                title={'After'}
                onChange={(value) => setSelectedAfter(value)}
              />
            </div> */}
            <div className='col-sm-6 px-2 row'>
              <div className='col-12 col-xl-6 pr-xl-2 d-flex'>
                <Select
                  tabIndex={isMobile ? 9 : 0}
                  className='mr-0 flex-grow-1'
                  options={reminders}
                  value={reminderTime}
                  title={'Reminder'}
                  onChange={(value) => setReminderTime(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row CoachSchedule__group pb-3'>
          <div className='col-md-6 pr-md-2'>
            <Location
              tabIndex={isMobile ? 10 : 0}
              locationType={localData.locationType}
              location={localData.location}
              locationTitle='Meeting location'
              showLocationWhileBooking={localData.showLocationWhileBooking}
              isZoomConnected={isZoomConnected}
              connectUrl={getZoomConnectUrl(userId)}
              onConnect={handleConnectZoom}
              onChange={handleChange}
              initialLocation={initialLocation}
              isInvalid={
                isValidationShown
                  ? {
                      location: !isFormInputValid('location', localData),
                      locationType: !isFormInputValid('locationType', localData)
                    }
                  : null
              }
              errorMessage={{
                location: getFormInputErrors('location', localData)[0],
                locationType: getFormInputErrors('locationType', localData)[0]
              }}
            />
          </div>
          <div className='col-md-6 pl-md-2 row'>
            <div className='col-sm-6 pr-sm-2'>
              <FormInput
                className='FormInput--price FormInput--nowrap'
                type='number'
                isDisabled={!isStripeConnected || hasConfirmedMember}
                tabIndex={isMobile ? 11 : 0}
                handleChange={handleChangeField('price')}
                valueFormatter={formatValueAsPrice}
                value={localData.price}
                isInvalid={
                  isValidationShown &&
                  (!isFormInputValid('price', localData) ||
                    !!serverValidation['price'])
                }
                errorMessage={
                  getFormInputErrors('price', localData)[0] ||
                  serverValidation['price']
                }
                label='Amount to be collected'
                append='USD'
                labelClassName='letter-spacing-n1'
              />
              <StripeConnectHint
                isShown={!isStripeConnected}
                onConnectStripe={handleConnectStripe}
              />
              {isStripeConnected && hasConfirmedMember && (
                <div className='subLabel'>
                  The payment option can't be changed if the meeting has a
                  confirmed registration.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mx-n2 pt-3'>
          <div className='col-sm-6 mb-3 mb-sm-0 col-12 px-2'>
            <Button
              tabIndex={isMobile ? 12 : 0}
              className='button__stretch'
              variations={['gray']}
              type='button'
              handleClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div className='col-sm-6 col-12 px-2'>
            <Button
              tabIndex={isMobile ? 13 : 0}
              className='button__stretch'
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default observer(CoachSchedule);
