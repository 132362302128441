import React, { useEffect, useState, useCallback } from 'react';
import './CoachCalendar.styles.scss';
import {
  Appointment,
  AppointmentBlock,
  CoachEvent,
  iListViewItem
} from '../../core/types';
import {
  StripeRefundStatusEnum,
  ClientEventStatusEnum,
  EventStatus,
  LocationTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import { ScheduleItemViewModel } from '../../core/backend/api';
import { getFromMilliseconds, copyToClipboard } from '../../core/helpers';
import {
  getCalendarData,
  cancelEvent,
  cancelAppointment
} from '../../core/api';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { getDefaultData as getEmptyAppointment } from '../../components/Modals/AppointmentModal/AppointmentModal.helpers';
import { getDefaultData as getEmptyEvent } from '../../pages/CoachMeeting/CoachMeeting.helpers';
import { getDefaultData as getEmptyAppointmentBlock } from '../../pages/CoachAppointmentBlock/CoachAppointmentBlock.helpers';
import RescheduleAppointment from '../../components/Modals/RescheduleAppointment/RescheduleAppointment';
import MoreInvitesForEventModal from '../../components/Modals/MoreInvitesForEventModal/MoreInvitesForEventModal';
import MoreInvitesForAppointmentModal from '../../components/Modals/MoreClientsForAppointmentModal/MoreClientsForAppointmentModal';
import { useHistory } from 'react-router-dom';
import { CoachRoutesEnum, ScheduleItemType } from '../../core/enums';
import WeekCalendarListView from '../../components/WeekCalendarListView/WeekCalendarListView';
import CoachScheduleCalendarItem from '../../components/CoachScheduleCalendarItem/CoachScheduleCalendarItem';
import {
  getCalendarListViewItemDetails,
  PALETTE
} from './CoachCalendar.helpers';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { useDebouncedCallback, useStores } from '../../hooks';
import ListIcon from '../../icons/list.svg';
import getGlobal from '../../core/globals';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { useCancelConfirmationModal } from '../../hooks';
import CoachInfoBlockModal, {
  iCoachInfoBlockData
} from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import CoachInfoBlockModalActions from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModalActions';
import CoachSchedulePage from '../../containers/CoachSchedulePage/CoachSchedulePage';
import PageHeaderContent from '../../components/PageHeaderContent/PageHeaderContent';
import Button from '../../components/Button/Button';
import { PaginatedResponse } from '../../core/types';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';

const CoachCalendar = () => {
  const {
    rootStore: { userStore, notificationStore, contactsStore }
  } = useStores();

  const history = useHistory();
  const [data, setData] = useState<PaginatedResponse<ScheduleItemViewModel>>({
    items: [],
    totalCount: 0
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);
  const [isEditPopupOpened, setIsEditPopupOpened] = useState<boolean>(false);
  const [isInviteClientsPopupOpened, setIsInviteClientsPopupOpened] = useState<
    boolean
  >(false);
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [selectedItemType, setSelectedItemType] = useState<ScheduleItemType>(
    null
  );
  const [isCancelling, setIsCancelling] = useState(false);
  const [calendarData, setCalendarData] = useState<number[]>([0, 0, 0, 0]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );

  const {
    confirmModalData,
    setConfirmModalData
  } = useCancelConfirmationModal();
  const setCancellingDone = useDebouncedCallback(() => {
    setIsCancelling(false);
  }, 450);

  const reFetchData = useCallback(() => {
    if (calendarData[0] || calendarData[1]) {
      setIsLoading(true);
      setData({
        items: [],
        totalCount: 0
      });
      getCalendarData(
        calendarData[0],
        calendarData[1],
        calendarData[2],
        calendarData[3]
      ).then((data: PaginatedResponse<ScheduleItemViewModel>) => {
        setData(data);
        setIsLoading(false);
      });
    }
  }, [calendarData]);

  useEffect(() => {
    reFetchData();
  }, [reFetchData]);

  const handleChangeCalendarMove = useCallback(
    (
      startTimeStamp: number,
      endTimeStamp: number,
      pageNumber: number,
      pageSize: number
    ) => {
      setSelectedItemId(0);
      setSelectedItemType(null);
      setCalendarData([startTimeStamp, endTimeStamp, pageNumber, pageSize]);
    },
    []
  );

  const handleCalendarItemView = (id: number, itemType: ScheduleItemType) => {
    setSelectedItemType(itemType);
    setSelectedItemId(id);
    setIsViewPopupOpened(true);
  };

  const handleCalendarItemEdit = (id: number, itemType: ScheduleItemType) => {
    if (itemType === ScheduleItemType.Appointment) {
      setSelectedItemType(itemType);
      setSelectedItemId(id);
      setIsEditPopupOpened(true);
      setIsViewPopupOpened(false);
    } else {
      const event = data.items.find(
        (e) => e.id === id && (e.type as number) === itemType
      );
      if ((event.type as number) === ScheduleItemType.Event) {
        history.push(
          `${CoachRoutesEnum.MeetingDetails.replace(':meetingId', `${id}`)}`
        );
      } else {
        history.push(
          `${CoachRoutesEnum.ScheduleMeetingDetails.replace(
            ':meetingId',
            `${id}`
          )}`
        );
      }
    }
  };

  const handleCalendarItemInvite = (id: number, itemType: ScheduleItemType) => {
    setSelectedItemType(itemType);
    setSelectedItemId(id);
    setIsInviteClientsPopupOpened(true);
  };

  const handleCalendarItemShare = (id: number, itemType: ScheduleItemType) => {
    const item = data.items.find(
      (i) => i.id === id && (i.type as number) === itemType
    );
    copyToClipboard(
      `${getGlobal('domain')}/${userStore.siteName}/${item.link}`
    );
    notificationStore.addNotification({
      text: 'Link to the meeting',
      textColored: 'was copied to the clipboard.'
    });
  };

  const handleCalendarItemCancel = (
    id: number,
    itemType: ScheduleItemType,
    isFree: boolean
  ) => {
    setSelectedItemId(id);
    setSelectedItemType(itemType);
    setIsViewPopupOpened(false);

    const currentEvent = data.items.find(
      (event) => event.id === id && (event.type as number) === itemType
    );
    const activeMembers = currentEvent?.members.filter(
      (m) => m.clientStatus === ClientEventStatusEnum.Active
    );

    setConfirmModalData(
      itemType,
      isFree,
      activeMembers?.length,
      'allClients',
      activeMembers?.every(
        (m) => m.refundStatus === StripeRefundStatusEnum.Succeeded
      )
    );
    setIsConfirmModalOpened(true);
  };

  const cancelCalendarItem = (
    id: number,
    itemType: ScheduleItemType,
    withRefund: boolean = false
  ) => {
    let cancel;

    if (itemType === ScheduleItemType.Appointment) {
      cancel = cancelAppointment;
    } else {
      cancel = cancelEvent;
    }

    setIsCancelling(true);
    cancel(id, withRefund)
      .then(() => {
        setSelectedItemId(0);
        closeConfirmationModal();
        reFetchData();
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          setSelectedItemId(0);
          closeConfirmationModal();
          reFetchData();
        } else {
          notificationStore.addNotification({
            text: 'Something went wrong.',
            textColored: 'Try one more time,\n please.',
            duration: 3000,
            type: NotificationTypesEnum.Error
          });
        }
      })
      .finally(() => {
        setCancellingDone();
      });
  };

  const closeConfirmationModal = () => setIsConfirmModalOpened(false);

  const handleAcceptConfirm = () => {
    const item = data.items.find(
      (event) =>
        event.id === selectedItemId &&
        (event.type as number) === selectedItemType
    );

    const activeMembers = item?.members.filter(
      (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
    );

    cancelCalendarItem(
      selectedItemId,
      selectedItemType,
      item.price > 0 &&
        !!activeMembers.length &&
        activeMembers.some(
          (m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded
        )
    );
  };

  const handleRejectConfirm = () => {
    if (selectedItemType !== ScheduleItemType.Appointment) {
      const event = data.items.find(
        (event) =>
          event.id === selectedItemId &&
          (event.type as number) === selectedItemType
      );
      const activeMembers = event?.members.filter(
        (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
      );

      const hasMembersForRefund = activeMembers.some(
        (m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded
      );

      if (
        event.price === 0 ||
        activeMembers.length === 0 ||
        (event.type as number) === ScheduleItemType.OneToOne ||
        !hasMembersForRefund
      ) {
        return closeConfirmationModal();
      }

      cancelCalendarItem(selectedItemId, selectedItemType, false);
    } else {
      closeConfirmationModal();
    }
  };

  const intervals: iListViewItem[] = [
    ...data.items.map((item) => {
      const isCanceled = (item.status as number) !== EventStatus.Active;
      const styles = isCanceled
        ? PALETTE.canceled
        : (item.type as number) === ScheduleItemType.OneToOne
        ? PALETTE.scheduled
        : (item.type as number) === ScheduleItemType.Event
        ? PALETTE.meeting
        : PALETTE.appointment;
      return {
        itemType: (item.type as number) as ScheduleItemType,
        id: item.id,
        date: item.timestamp,
        details: getCalendarListViewItemDetails(item, contactsStore.contacts),
        isCanceled,
        ...styles
      };
    })
  ];

  let appointmentPopupData: Appointment = getEmptyAppointment();
  let appointmentBlockPopupData: AppointmentBlock = getEmptyAppointmentBlock();
  let eventPopupData: CoachEvent = getEmptyEvent();
  let infoBlockData: iCoachInfoBlockData = null;

  if (selectedItemType !== null && selectedItemId) {
    if (selectedItemType !== ScheduleItemType.Appointment) {
      const selectedItem = data.items.find(
        (item) => item.id === selectedItemId
      )!;

      if (selectedItem) {
        const startDateTimeStamp = moment
          .utc(selectedItem.timestamp)
          .startOf('day')
          .valueOf();
        const endDateTimeStamp = moment
          .utc(selectedItem.timestamp + selectedItem.durationMs)
          .startOf('day')
          .valueOf();

        eventPopupData = {
          invites: selectedItem.invites,
          location: selectedItem.location,
          startDateTimeStamp: startDateTimeStamp,
          startTimeMs: selectedItem.timestamp - startDateTimeStamp,
          endDateTimeStamp: endDateTimeStamp,
          endTimeMs:
            selectedItem.timestamp + selectedItem.durationMs - endDateTimeStamp,
          price: `${selectedItem.price}`,
          coachEventStatus: selectedItem.status as number,
          availableSpots: selectedItem.availableSpots,
          description: selectedItem.description,
          link: selectedItem.link,
          name: selectedItem.name,
          id: selectedItem.id,
          members: selectedItem.members || [],
          remindBeforeMs: selectedItem.remindBeforeMs,
          isScheduled:
            (selectedItem.type as number) === ScheduleItemType.OneToOne,
          isPrivate: selectedItem.isPrivate,
          imageId: selectedItem.imageId,
          thumbnailImageId: selectedItem.thumbnailImageId,
          croppedArea: selectedItem.croppedArea,
          locationType: LocationTypeEnum.Custom,
          showLocationWhileBooking: false
        };

        infoBlockData = {
          id: selectedItem.id,
          type: selectedItem.type as number,
          name: selectedItem.name,
          userName: '',
          durationMinutes: getFromMilliseconds(
            selectedItem.durationMs,
            'minutes'
          ),
          membersCountLimitString:
            selectedItem.availableSpots === 1 ? 'One-on-One' : 'Group',
          price: selectedItem.price.toString(),
          location: selectedItem.location,
          date: selectedItem.timestamp,
          showCoachAvatar: false,
          timeZone: userStore.timeZone,
          imageId: selectedItem.thumbnailImageId,
          remindBefore: selectedItem.remindBeforeMs,
          showSelectBefore: false,
          isCanceled: (selectedItem.status as number) !== EventStatus.Active,
          members: selectedItem.members,
          invites: selectedItem.invites
        };
      }
    } else {
      const selectedAppointment = data.items.find(
        (item) =>
          item.id === selectedItemId &&
          (item.type as number) === ScheduleItemType.Appointment
      )!;
      if (selectedAppointment) {
        const timestampUtc = moment
          .utc(selectedAppointment.timestamp)
          .startOf('day')
          .valueOf();

        appointmentPopupData = {
          id: selectedAppointment.id,
          timestampUtc,
          timeMs: selectedAppointment.timestamp - timestampUtc,
          appointmentStatus: selectedAppointment.status as number,
          price: `${selectedAppointment.price}`,
          appointmentTypeId: selectedAppointment.parentId,
          invites: selectedAppointment.invites,
          members: selectedAppointment.members,
          remindBefore: selectedAppointment.remindBeforeMs,
          locationType: (selectedAppointment.locationType as unknown) as LocationTypeEnum,
          location: selectedAppointment.location,
          duration: getFromMilliseconds(
            selectedAppointment.durationMs,
            'minutes'
          )
        };

        appointmentBlockPopupData = {
          ...getEmptyAppointmentBlock(),
          id: selectedAppointment.parentId,
          isPrivate: selectedAppointment.isPrivate,
          membersCountLimit: selectedAppointment.availableSpots,
          location: selectedAppointment.location,
          link: selectedAppointment.link!,
          durationMinutes: getFromMilliseconds(
            selectedAppointment.durationMs,
            'minutes'
          ),
          description: selectedAppointment.description,
          name: selectedAppointment.name,
          price: `${selectedAppointment.price}`
        };

        infoBlockData = {
          id: selectedAppointment.id,
          type: ScheduleItemType.Appointment,
          name: selectedAppointment.name,
          userName: '',
          durationMinutes: getFromMilliseconds(
            selectedAppointment.durationMs,
            'minutes'
          ),
          membersCountLimitString:
            selectedAppointment.availableSpots === 1 ? 'One-on-One' : 'Group',
          price: selectedAppointment.price.toString(),
          location: selectedAppointment.location,
          date: selectedAppointment.timestamp,
          showCoachAvatar: false,
          timeZone: userStore.timeZone,
          imageId: selectedAppointment.thumbnailImageId,
          remindBefore: selectedAppointment.remindBeforeMs,
          showSelectBefore: false,
          isCanceled:
            (selectedAppointment.status as number) !== EventStatus.Active,
          members: selectedAppointment.members,
          invites: []
        };
      }
    }
  }

  const renderCalendarLegend = () => {
    const hasData = !!data.totalCount;

    return (
      hasData && (
        <div className='CoachCalendar__legend '>
          <div className='d-flex flex-wrap mx-n2'>
            <div className='CoachCalendar__legend-item m-2'>
              <div
                className='CoachCalendar__legend-item-color'
                style={{
                  backgroundColor: PALETTE.appointment.cellCircleColor,
                  borderColor: 'transparent'
                }}
              />
              Appointment (added by Client)
            </div>
            <div className='CoachCalendar__legend-item m-2'>
              <div
                className='CoachCalendar__legend-item-color'
                style={{
                  backgroundColor: PALETTE.scheduled.cellCircleColor,
                  borderColor: 'transparent'
                }}
              />
              Scheduled
            </div>
            <div className='CoachCalendar__legend-item m-2'>
              <div
                className='CoachCalendar__legend-item-color'
                style={{
                  backgroundColor: PALETTE.meeting.cellCircleColor,
                  borderColor: 'transparent'
                }}
              />
              Meeting
            </div>
            <div className='CoachCalendar__legend-item m-2'>
              <div
                className='CoachCalendar__legend-item-color'
                style={{
                  backgroundColor: PALETTE.canceled.cellCircleColor,
                  borderColor: PALETTE.canceled.cellBorderColor
                }}
              />
              Canceled
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <CoachSchedulePage title='Calendar'>
      <PageHeaderContent>
        <Button
          variations={['xs-width', 'floating-button']}
          link={CoachRoutesEnum.New}
          component='Link'
        >
          <span className='button__floating-button-plus'>+</span>Schedule
        </Button>
      </PageHeaderContent>
      <GlobalLoader isLoading={isLoading} />
      <ul className='CoachCalendar__tabs'>
        <li className='CoachCalendar__tabs-item active'>
          {' '}
          <ListIcon /> View all
        </li>
      </ul>
      <div className='CoachCalendar__section'>
        <WeekCalendarListView
          isLoading={isLoading}
          events={intervals}
          totalCount={data.totalCount}
          timeZone={userStore.timeZone}
          onChange={handleChangeCalendarMove}
          ItemRenderer={({ item, ...props }) => (
            <CoachScheduleCalendarItem
              item={item}
              {...props}
              handleView={handleCalendarItemView}
              handleEdit={handleCalendarItemEdit}
              handleInvite={handleCalendarItemInvite}
              handleCancel={handleCalendarItemCancel}
              handleShare={handleCalendarItemShare}
            />
          )}
          headerBlock={renderCalendarLegend()}
        />
        <CoachInfoBlockModal
          isOpened={isViewPopupOpened}
          close={() => setIsViewPopupOpened(false)}
          data={infoBlockData}
          actions={
            <CoachInfoBlockModalActions
              data={infoBlockData}
              onCancel={() => {
                handleCalendarItemCancel(
                  infoBlockData.id,
                  infoBlockData.type,
                  Number(infoBlockData.price) === 0
                );
              }}
              onEdit={() =>
                handleCalendarItemEdit(infoBlockData.id, infoBlockData.type)
              }
            />
          }
        />
        <RescheduleAppointment
          isOpened={
            selectedItemType === ScheduleItemType.Appointment &&
            !!selectedItemId &&
            isEditPopupOpened
          }
          close={() => setIsEditPopupOpened(false)}
          rescheduled={reFetchData}
          data={appointmentPopupData}
          appointmentBlockData={appointmentBlockPopupData}
        />
        <MoreInvitesForAppointmentModal
          isOpened={
            selectedItemType === ScheduleItemType.Appointment &&
            !!selectedItemId &&
            isInviteClientsPopupOpened
          }
          close={() => setIsInviteClientsPopupOpened(false)}
          data={appointmentPopupData}
          appointmentBlockData={appointmentBlockPopupData}
        />
        <MoreInvitesForEventModal
          isOpened={
            selectedItemType === ScheduleItemType.Event &&
            !!selectedItemId &&
            isInviteClientsPopupOpened
          }
          close={() => setIsInviteClientsPopupOpened(false)}
          data={eventPopupData}
          onUpdate={reFetchData}
        />
        <ConfirmModal
          isOpened={isConfirmModalOpened}
          close={closeConfirmationModal}
          title={confirmModalData.title}
          text={confirmModalData.text}
          confirmBtnText={confirmModalData.confirmBtnText}
          cancelBtnText={confirmModalData.cancelBtnText}
          disableConfirmBtn={isCancelling}
          disableCancelBtn={isCancelling}
          confirmCallback={handleAcceptConfirm}
          cancelCallback={handleRejectConfirm}
          confirmBtnClass='button__confirm-delete'
        />
        <PromptModal
          text={
            <>
              Some payments were made using your previous Stripe account.
              <br /> We are unable to process refunds for these payments
              automatically.
              <br /> You can still make a refund directly from the Stripe
              dashboard.
            </>
          }
          title='Automatic refund failed'
          isOpened={isRefundPromptModalOpened}
          close={() => setIsRefundPromptModalOpened(false)}
          btnText='Close'
        />
      </div>
    </CoachSchedulePage>
  );
};

export default observer(CoachCalendar);
