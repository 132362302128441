import { TableColumn } from './Table';

export function defaultTableSearchFn<T>(columns: TableColumn<T>[]) {
  return function (searchText: string, item: T) {
    return (
      !searchText ||
      columns
        .filter((c) => c.searchable)
        .map((c) => c.field)
        .reduce<boolean>(
          (flag, col) =>
            flag ||
            String(item[col as keyof T])
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          false
        )
    );
  };
}
