import React, { useEffect } from 'react';
import { CoachAccountViewModel } from '../../core/backend/api';
import { isEmail, isNotEmpty, isUrl } from '../../core/formValidation';
import { iCoachPageCustomizationData } from '../../core/types';
import Button from '../Button/Button';
import CustomizeSidebarTab from './CustomizeSidebarTab';
import SocialNetworkLinkInput from './SocialNetworkLinkInput';

interface Props {
  updateSocialEmail: (arg0: string) => void;
  isValidationShown: boolean;
  coachAccountData: CoachAccountViewModel;
  data: iCoachPageCustomizationData;
  handleChangeField: (field: string) => (value: any) => void;
}

const socialLinkErrorMessage = 'Link is not valid';

const CustomizeSocialLinksTab: React.FC<Props> = ({
  updateSocialEmail,
  isValidationShown,
  coachAccountData,
  data,
  handleChangeField
}) => {
  const handleChange = (index: number) => (value: string) => {
    const updatedSocLinks = [...data.socialLinks];
    updatedSocLinks[index] = value;
    handleChangeField('socialLinks')(updatedSocLinks);
  };

  const getInvalidField = (index: number) => {
    return (
      isValidationShown &&
      !isUrl(data.socialLinks[index] || '') &&
      isNotEmpty(data.socialLinks[index])
    );
  };

  const handleDelete = (index: number) => () => {
    const updatedSocialLinks = [...data.socialLinks];
    updatedSocialLinks.splice(index, 1);
    handleChangeField('socialLinks')(updatedSocialLinks);
  };

  useEffect(() => {
    if (data.socialLinks.length === 0) {
      handleChangeField('socialLinks')(['']);
    }
  }, [data, handleChangeField]);

  return (
    <CustomizeSidebarTab>
      <SocialNetworkLinkInput
        value={coachAccountData.socialEmail ? coachAccountData.socialEmail : ''}
        handleChange={updateSocialEmail}
        handleDelete={() => updateSocialEmail('')}
        isSocialEmail
        isInvalid={
          isValidationShown &&
          !isEmail(coachAccountData.socialEmail.toLowerCase()) &&
          isNotEmpty(coachAccountData.socialEmail)
        }
        errorMessage='Email is not valid'
      />
      {data.socialLinks.map((_, index) => (
        <SocialNetworkLinkInput
          key={index}
          value={data.socialLinks[index]}
          handleChange={handleChange(index)}
          handleDelete={handleDelete(index)}
          isInvalid={getInvalidField(index)}
          errorMessage={socialLinkErrorMessage}
        />
      ))}
      <Button
        className='button__mt'
        variations={['naked']}
        handleClick={() => {
          const newLinks = [...data.socialLinks];
          newLinks.push('');
          handleChangeField('socialLinks')(newLinks);
        }}
        type='button'
      >
        + Add new link
      </Button>
    </CustomizeSidebarTab>
  );
};

export default CustomizeSocialLinksTab;
