import React, { useState } from 'react';
import OneOnOneIcon from '../../icons/one-on-one.svg';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import ImageCropUploadModal from '../Modals/ImageCropUploadModal/ImageCropUploadModal';
import { getPublicFile } from '../../core/api';
import Tooltip from '../Tooltip/Tooltip';
import Button from '../Button/Button';
import './LogoUploader.styles.scss';

interface Props {
  imageUrl: string;
  imageKey?: string;
  handleChangeField: (field: string) => (value: any) => void;
  croperModalTitle?: string;
  noImageIcon?: JSX.Element;
  className?: string;
  withHints?: boolean;
}

const MAX_IMAGE_SIZE = 3 * 1024 * 1024;
const MAX_FILE_TYPES = ['png', 'jpeg', 'svg'];

const LogoUploader = ({
  imageUrl,
  handleChangeField,
  croperModalTitle,
  noImageIcon,
  className = '',
  withHints = true,
  imageKey = 'imageId'
}: Props) => {
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);

  const handleImageDelete = () => {
    handleChangeField(imageKey)('');
  };

  const handleImageModalClose = () => {
    setIsImageModalOpened(false);
  };

  const handleImageUploaded = (imageId: string) => {
    handleChangeField(imageKey)(imageId);
    handleImageModalClose();
  };

  const handleImageModalOpen = () => {
    setIsImageModalOpened(true);
  };

  return (
    <div className={`LogoUploader ${className}`}>
      <div
        className='LogoUploader__image'
        onClick={!imageUrl ? handleImageModalOpen : undefined}
        data-testid='logo-uploader-image'
      >
        <div className='LogoUploader__image-inner'>
          {imageUrl && imageUrl.length > 1 ? (
            <img src={getPublicFile(imageUrl)} alt='' />
          ) : (
            <>
              {noImageIcon ? noImageIcon : <OneOnOneIcon />}
              {withHints && (
                <p className='LogoUploader__image-text'>
                  Click here to add an image
                </p>
              )}
            </>
          )}
        </div>
        {imageUrl && (
          <div className='LogoUploader__image-buttons'>
            <Tooltip
              withPortal
              trigger={
                <Button
                  type='button'
                  variations={['naked']}
                  handleClick={handleImageModalOpen}
                  data-testid='edit-image-btn'
                >
                  <PencilIcon />
                </Button>
              }
              content={<>Edit</>}
              triggerType='hover'
            />
            <div className='px-2 ml-n1'></div>
            <Tooltip
              withPortal
              minWidth={62}
              trigger={
                <Button
                  type='button'
                  variations={['naked']}
                  handleClick={handleImageDelete}
                  data-testid='remove-image-btn'
                >
                  <BucketIcon />
                </Button>
              }
              content={<>Remove</>}
              triggerType='hover'
            />
          </div>
        )}
      </div>
      {imageUrl && imageUrl.length > 1 && withHints && (
        <p className='LogoUploader__edit-image-hint mt-2'>
          Click on the image to edit it
        </p>
      )}
      <ImageCropUploadModal
        title={croperModalTitle}
        isOpened={isImageModalOpened}
        fileMaxSize={MAX_IMAGE_SIZE}
        fileFormats={MAX_FILE_TYPES}
        imageMaxWidth={480}
        aspect={1}
        onClose={handleImageModalClose}
        onUploaded={handleImageUploaded}
      />
    </div>
  );
};

export default LogoUploader;
