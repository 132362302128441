import React from 'react';
import './MeetingTile.styles.scss';
import OneOnOneIcon from '../../icons/one-on-one-circle.svg';
import CalendarIcon from '../../icons/calendar.svg';
import GroupIcon from '../../icons/group-plus.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import PrivateIcon from '../../icons/private.svg';
import NonPrivateIcon from '../../icons/nonPrivate.svg';
import PencilIcon from '../../icons/pencil.svg';
import BucketIcon from '../../icons/bucket.svg';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import { copyToClipboard, normalizePrice } from '../../core/helpers';
import { observer } from 'mobx-react';
import moment from 'moment';
import { EventStatus } from '../../core/enums';
import Button from '../Button/Button';
import Tooltip from '../Tooltip/Tooltip';
import { useStores } from '../../hooks';
import TextEllipse from '../TextEllipse';

type MeetingType = 'single' | 'group';

interface iProps {
  className?: string;
  color?: string;
  title?: string;
  startDate?: number;
  meetingType?: MeetingType;
  price?: number;
  handlePrivate?: () => void;
  handleEdit?: () => void;
  handleCancel?: () => void;
  handleClick?: () => void;
  link?: string;
  isFake?: boolean;
  isPrivate?: boolean;
  status?: EventStatus;
}

const MeetingTile: React.FC<iProps> = ({
  className = '',
  color = '#BB6BD9',
  title = '',
  startDate = null,
  meetingType = 'single',
  price = 0,
  handlePrivate = () => null,
  handleEdit = () => null,
  handleCancel = () => null,
  handleClick,
  link,
  isFake = false,
  isPrivate = false,
  status = EventStatus.Active
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const classList = ['MeetingTile', className];

  if (isFake) {
    classList.push('MeetingTile--fake');
  }

  if (handleClick) {
    classList.push('MeetingTile--clickable');
  }
  const menuList: ListItemProps[] = [];

  const isActive = status === EventStatus.Active;

  if (isActive && handlePrivate) {
    menuList.push({
      name: isPrivate ? 'Make Public' : 'Make private',
      callback: handlePrivate,
      icon: isPrivate ? <NonPrivateIcon /> : <PrivateIcon />
    });
  }
  if (handleEdit) {
    menuList.push({
      name: isActive ? 'Edit' : 'View',
      callback: handleEdit,
      icon: isActive ? <PencilIcon /> : <NonPrivateIcon />
    });
  }
  if (isActive && handleCancel) {
    menuList.push({
      name: 'Cancel',
      callback: handleCancel,
      icon: <BucketIcon />
    });
  }

  const handleCopyLink = (event?: React.MouseEvent) => {
    event!.stopPropagation();
    if (link) {
      copyToClipboard(link);
      notificationStore.addNotification({
        text: 'Link to meeting',
        textColored: 'was copied to your clipboard'
      });
    }
  };

  const renderTypeIcon = () => {
    switch (meetingType) {
      case 'group':
        return <GroupIcon />;
      default:
        return <OneOnOneIcon />;
    }
  };

  return (
    <div
      className={classList.join(' ')}
      style={isActive ? { borderLeftColor: color } : { borderColor: color }}
      onClick={handleClick || (() => null)}
    >
      {link && menuList.length > 0 && (
        <DropdownMenu list={menuList} className='MeetingTile__dropdown' />
      )}
      <TextEllipse className='MeetingTile__name' text={title}>
        <span>{title}</span>
      </TextEllipse>
      <div className='MeetingTile__info'>
        <div className='MeetingTile__info-item MeetingTile__calendar-icon mr-3 mr-sm-2 pr-md-1'>
          <CalendarIcon />
          <span className='MeetingTile__info-text'>
            {moment.utc(startDate).format("MMM D, 'YY")}
          </span>
        </div>
        <div className='MeetingTile__info-item mr-3 mr-sm-2 pr-md-1'>
          <DollarIcon />
          <span className='MeetingTile__info-text'>
            {price ? `$ ${normalizePrice(price)}` : 'Free'}
          </span>
        </div>
        {isPrivate ? (
          <div className='MeetingTile__info-item'>
            <Tooltip
              className='Tooltip--flex'
              trigger={<PrivateIcon />}
              content={<span>Private</span>}
              triggerType='hover'
            />
          </div>
        ) : null}
      </div>
      <div className='MeetingTile__tiles'>
        <div className='MeetingTile__tile MeetingTile__tile--group'>
          <div className='MeetingTile__icon'>{renderTypeIcon()}</div>
          {meetingType === 'single' ? 'One-on-One' : 'Group'}
        </div>
        <div className='MeetingTile__tile MeetingTile__tile--link'>
          {link ? (
            <Button
              className='button__copy-link'
              type='button'
              disabled={!isActive}
              variations={['naked']}
              handleClick={handleCopyLink}
            >
              Copy link
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(MeetingTile);
