import React from 'react';
import * as H from 'history';
import './TabsMenu.styles.scss';
import { NavLink, useLocation } from 'react-router-dom';

export interface iTab {
  name: string;
  link: string;
  queryParams?: Record<string, string>;
  icon?: undefined | JSX.Element;
}
interface iProps {
  tabsList: iTab[];
  className?: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

const TabsMenu: React.FC<iProps> = ({ tabsList, className = '', onClick }) => {
  useLocation();

  const isTabActive = (tab: iTab) => (
    _: any,
    { pathname, search }: H.Location
  ) => {
    const params = new URLSearchParams(search);
    return (
      tab.link === pathname &&
      (!tab.queryParams ||
        Object.keys(tab.queryParams).reduce<boolean>((acc, key) => {
          return (
            acc &&
            (params.get(key) === tab.queryParams[key] ||
              (!params.get(key) && !tab.queryParams[key]))
          );
        }, true))
    );
  };
  return (
    <div className={`TabsMenu ${className}`}>
      {tabsList.map((tab, index) => {
        const link =
          tab.link +
          (tab.queryParams
            ? `?${Object.keys(tab.queryParams)
                .filter((key) => !!tab.queryParams[key])
                .map((key) => `${key}=${tab.queryParams[key]}`)
                .join('&')}`
            : '');
        return (
          <NavLink
            exact
            key={index}
            className='TabsMenu__tabs-item d-flex align-items-center'
            to={link}
            isActive={isTabActive(tab)}
            onClick={onClick}
          >
            {tab.icon || null}
            {tab.name}
          </NavLink>
        );
      })}
    </div>
  );
};

export default TabsMenu;
