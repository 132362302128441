import React from 'react';
import './Dashboard.styles.scss';
import { iBookingItem, WelcomeMessageData } from '../../core/types';
import ClientScheduleTile from '../ClientScheduleTile/ClientScheduleTile';
import TilesCollapsibleGroup from '../TilesCollapsibleGroup/TilesCollapsibleGroup';
import EmptyState from '../EmptyState/EmptyState';
import ScheduleTile from '../ScheduleTile/ScheduleTile';
import { ClientRoutesEnum } from '../../core/enums';
import { Link } from 'react-router-dom';
import { getRoutePath } from '../../core/globals';
import { useMedia } from '../../hooks';

interface iProps {
  scheduled: iBookingItem[];
  meetings: iBookingItem[];
  appointments: iBookingItem[];
  siteName: string;
  isLoadingData?: boolean;
  motivationData?: WelcomeMessageData;
  isPreview?: boolean;
  onView?: (data: iBookingItem) => void;
}

const Dashboard: React.FC<iProps> = ({
  scheduled,
  meetings,
  appointments,
  siteName,
  isLoadingData,
  motivationData,
  isPreview,
  onView = () => {}
}) => {
  const maxShownItems = useMedia(
    ['(min-width: 1200px)', '(min-width: 768px)'],
    [3, 2],
    1
  );

  return (
    <div className='ClientDashboard flex-grow-1 p-sm-4 p-3'>
      <div className='row mx-n2'>
        <div className='col-md-6 px-2 pb-4'>
          <div className='Welcome__title'>
            {/* {motivationData.welcomeText.length
              ? motivationData.welcomeText
              : 'Welcome to your Client Portal'} */}
            {motivationData.welcomeText}
          </div>
          <div
            className='Welcome__message'
            contentEditable={false}
            suppressContentEditableWarning
          >
            {motivationData.message.split('\n').map((item, i) => (
              <p key={i}>{item}</p>
            ))}
            {/* {motivationData.message
              ? motivationData.message
                  .split('\n')
                  .map((item, i) => <p key={i}>{item}</p>)
              : 'Here is where you can find information on upcoming appointments, access your library content, and schedule new meetings.'} */}
          </div>
        </div>
        <div className='col-md-6 col-12 row px-2'>
          <div className='offset-xl-1 col-xl-11 col-12'>
            <div className='ClientDashboard__tiles-header mb-3'>
              <div className='ClientDashboard__tiles-title'>Your schedule</div>
              {scheduled.length > 0 && (
                <Link
                  className='ClientDashboard__view-all-button'
                  to={getRoutePath(ClientRoutesEnum.Calendar)}
                >
                  View all
                </Link>
              )}
            </div>
            <div className='ClientDashboard__tiles-scheduled'>
              {scheduled.slice(0, 3).map((item, index) => (
                <ClientScheduleTile
                  key={index}
                  data={item}
                  shortDetails
                  showDay
                  onView={onView}
                />
              ))}

              {!scheduled.length && !isLoadingData && (
                <EmptyState
                  size='md'
                  className='mt-5 pt-3'
                  text='You have no booked meetings or appointments yet'
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {!!meetings.length && (
        <TilesCollapsibleGroup
          title='Sign up for a meeting'
          titleClassName='CZ__text-prim'
          events={meetings}
          maxShownItems={maxShownItems}
          className='mt-4'
          ItemRenderer={({ item, isVisible }) => (
            <div className='col-xl-4 col-md-6 col-12 p-md-3 p-2'>
              <ScheduleTile
                className='ScheduleTile--ClientDashboard'
                data={item}
                siteName={isPreview ? '' : siteName}
                showImage={isVisible}
              />
            </div>
          )}
        />
      )}

      {!!appointments.length && (
        <TilesCollapsibleGroup
          title='Schedule an appointment'
          titleClassName='CZ__text-prim'
          events={appointments}
          maxShownItems={maxShownItems}
          className='mt-3'
          data-testid='appointments-tiles-group'
          ItemRenderer={({ item, isVisible }) => (
            <div className='col-xl-4 col-md-6 col-12 p-md-3 p-2'>
              <ScheduleTile
                className='ScheduleTile--ClientDashboard'
                data={item}
                siteName={isPreview ? '' : siteName}
                showImage={isVisible}
              />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default Dashboard;
