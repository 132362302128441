import React, { useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import './ClientSideMenu.styles.scss';
import MenuDashboardIcon from '../../icons/menu-dashboard.svg';
import MenuScheduleIcon from '../../icons/menu-schedule.svg';
import MenuContactsIcon from '../../icons/menu-contacts.svg';
import MenuPaymentsIcon from '../../icons/menu-payments.svg';
import MenuStatsIcon from '../../icons/menu-stats.svg';
import MenuTrainingIcon from '../../icons/menu-training.svg';
import AvatarIcon from '../../icons/avatar.svg';
import { getPublicFile } from '../../core/api';
import { CoachAccountViewModel } from '../../core/backend/api';
import { iCoachPageCustomizationData } from '../../core/types';
import { useMedia, useOnClickOutside } from '../../hooks';
import SideMenuList, {
  ParentMenuItem,
  IconsProps
} from '../SideMenuList/SideMenuList';
import { useHistory } from 'react-router-dom';
// import { inject, observer } from 'mobx-react';
// import { iRootStore, iStores } from '../../store/RootStore';
// import PreviewIcon from '../../icons/preview.svg';
// import InstagramIcon from '../../icons/instagram.svg';
// import YoutubeIcon from '../../icons/youtube.svg';
// import EnvelopeIcon from '../../icons/envelope.svg';
// import { ClientRoutesEnum } from '../../core/enums';
// import { Link } from 'react-router-dom';
// import { getRoutePath } from '../../core/globals';

interface Props {
  menuList: ParentMenuItem[];
  isOpened: boolean;
  onClose?: () => void;
  coachInfo?: Partial<CoachAccountViewModel>;
  coachCustomizationInfo?: iCoachPageCustomizationData;
}

const icons: IconsProps = {
  MenuDashboardIcon: <MenuDashboardIcon />,
  MenuScheduleIcon: <MenuScheduleIcon />,
  MenuContactsIcon: <MenuContactsIcon />,
  MenuInvoicingIcon: <MenuPaymentsIcon />,
  MenuStatsIcon: <MenuStatsIcon />,
  MenuTrainingIcon: <MenuTrainingIcon />
};

const ClientSideMenu: React.FC<Props> = ({
  menuList = [],
  isOpened,
  coachInfo,
  coachCustomizationInfo,
  onClose = () => {}
}) => {
  const ref = useRef<HTMLDivElement>();
  const history = useHistory();

  const isTablet = useMedia(['(min-width: 992px)'], [false], true);

  useOnClickOutside(ref, onClose, isOpened && isTablet);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (isTablet && isOpened) {
        onClose();
      }
    });
    return () => {
      unlisten();
    };
  }, [isOpened, isTablet]);

  return (
    <div
      className={`ClientSideMenu d-flex ${
        isOpened ? 'ClientSideMenu--opened' : ''
      }`}
      ref={ref}
    >
      <Scrollbars style={{ height: 'auto' }}>
        <div className='ClientSideMenu__coach-title d-flex'>
          <div className='flex-grow-1'>Your coach</div>
          <div
            className='ClientPageHeader__menu ClientPageHeader__menu--cross d-lg-none'
            onClick={onClose}
          >
            <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--one' />
            <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--two' />
            <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--three' />
          </div>
        </div>
        <div className='ClientSideMenu__coach'>
          <div className='ClientSideMenu__coach-image'>
            {coachInfo.imageId ? (
              <img
                src={getPublicFile(coachInfo.imageId)}
                alt=''
                className='ClientSideMenu__coach-img'
              />
            ) : (
              <AvatarIcon />
            )}
          </div>
          <div className='ClientSideMenu__coach-name'>
            {coachInfo.firstName} {coachInfo.lastName}
          </div>
          <div className='ClientSideMenu__coach-position'>
            {coachCustomizationInfo.position}
          </div>
          {/*<div className="ClientSideMenu__socials">*/}
          {/*    <a className="ClientSideMenu__social-link CZ__icon">*/}
          {/*        <InstagramIcon/>*/}
          {/*    </a>*/}
          {/*    <a className="ClientSideMenu__social-link CZ__icon">*/}
          {/*        <YoutubeIcon/>*/}
          {/*    </a>*/}
          {/*    <a className="ClientSideMenu__social-link CZ__icon">*/}
          {/*        <EnvelopeIcon/>*/}
          {/*    </a>*/}
          {/*</div>*/}
        </div>
        <SideMenuList menuList={menuList} icons={icons} isTablet={isTablet} />
      </Scrollbars>
    </div>
  );
};

export default ClientSideMenu;
