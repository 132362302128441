import React, { ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import BackIcon from '../../icons/back.svg';
import './Button.styles.scss';

interface BtnProps extends React.AnchorHTMLAttributes<any> {
  className?: string;
  handleClick?: (e?: React.MouseEvent) => void;
  title?: string;
  children: ReactNode;
  style?: object;
  type?: any;
  variations?: string[];
  link?: string;
  replace?: boolean;
  url?: string;
  component?: 'Link' | 'NavLink';
  isBackButton?: boolean;
  disabled?: boolean;
  tabIndex?: number;
}

const Button = React.forwardRef<any, BtnProps>(
  (
    {
      handleClick = () => {},
      className = '',
      children,
      style,
      type = 'submit',
      variations = [],
      link = '',
      url = '',
      component = 'Link',
      isBackButton = false,
      disabled = false,
      replace = false,
      tabIndex,
      ...rest
    },
    ref
  ) => {
    const classList = [
      'button',
      className,
      ...variations.map((variation) => `button__${variation} `),
      link ? 'button__link' : '',
      disabled ? 'button--disabled' : ''
    ];

    if (link !== '') {
      const LinkComponent = component === 'Link' ? Link : NavLink;
      return (
        <LinkComponent
          className={classList.join(' ')}
          onClick={handleClick}
          to={link}
          replace={replace}
          {...rest}
        >
          {children}
        </LinkComponent>
      );
    }

    if (url !== '') {
      return (
        <a
          className={[...classList, 'button--link'].join(' ')}
          onClick={handleClick}
          href={url}
          style={style}
          {...rest}
        >
          {children}
        </a>
      );
    }
    if (isBackButton) {
      return (
        <div className='button--back-btn' onClick={handleClick}>
          <BackIcon />
        </div>
      );
    }
    return (
      <button
        tabIndex={tabIndex}
        className={classList.join(' ')}
        onClick={handleClick}
        style={style}
        type={type}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

export default Button;
