import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getCoachAccount,
  getCoachInvoice,
  updateCoachInvoiceStatus
} from '../../core/api';
import { CoachAccountViewModel } from '../../core/backend/api';
import {
  CoachRoutesEnum,
  ConfirmModalEnum,
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  NotificationTypesEnum
} from '../../core/enums';
import { useParams, useHistory } from 'react-router-dom';
import { Invoice } from '../../core/types';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import ModalFullHeader from '../../components/ModalFullHeader/ModalFullHeader';
import InvoiceCard from '../../components/InvoiceCard/InvoiceCard';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import CloseIcon from '../../icons/cross.svg';
import './CoachInvoiceView.styles.scss';
import Badge from '../../components/Badge/Badge';
import { getBadgeType } from '../CoachInvoices/CoachInvoices.helpers';
import { useStores } from '../../hooks/useStores';
import ConfirmModal, {
  iProps as ConfirmModalData
} from '../../components/Modals/ConfirmModal/ConfirmModal';

const CoachInvoiceView = () => {
  const { rootStore } = useStores();
  const { notificationStore } = rootStore;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [invoice, setInvoice] = useState<Invoice>(null);
  const [coachAccountData, setCoachAccountData] = useState<
    CoachAccountViewModel
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState<ConfirmModalEnum>();

  const status = InvoiceStatusEnum[
    invoice?.status
  ] as keyof typeof InvoiceStatusEnum;

  useEffect(() => {
    if (invoice?.status === InvoiceStatusEnum.draft) {
      history.push(CoachRoutesEnum.InvoiceEdit.replace(':id', id));
    }
  }, [id, history, invoice]);

  const redirectToInvoicesPage = useCallback(
    () => history.push(CoachRoutesEnum.Invoices),
    [history]
  );

  const cancelCoachInvoice = (id: string | number) => {
    return updateCoachInvoiceStatus(id, InvoiceStatusEnum.canceled);
  };

  const handleConfirmAction = (
    action: (id: number) => Promise<unknown>,
    notification: string,
    actionStatus: string
  ) => () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    action(invoice.id)
      .then(() => {
        setInvoice({
          ...invoice,
          status: InvoiceStatusEnum.refunded
        });
        notificationStore.addNotification({
          text: `Invoice ${invoice.number}`,
          textColored: notification
        });
        redirectToInvoicesPage();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.status === 404) {
          notificationStore.addNotification({
            text: `Invoice ${invoice.number} not found`,
            type: NotificationTypesEnum.Error
          });
        }
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text: `Invoice ${invoice.number}`,
          textColored: `payment ${actionStatus} failed.`
        });
      })
      .finally(() => {
        handleConfirmationClose();
      });
  };

  const menuList = useMemo(() => {
    return [
      ...([InvoiceStatusEnum.open, InvoiceStatusEnum.late].includes(
        invoice?.status
      ) ||
      (invoice?.type === InvoiceTypeEnum.Recurring &&
        (invoice?.status === InvoiceStatusEnum.paid ||
          invoice?.status === InvoiceStatusEnum.uncollectible))
        ? [
            {
              name: 'Cancel',
              callback: () => {
                setConfirmModalType(ConfirmModalEnum.cancel);
                setIsConfirmModalOpened(true);
              }
            }
          ]
        : [])
    ];
  }, [invoice]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getCoachAccount(), getCoachInvoice(id)])
      .then(([coachAccountResponse, invoiceResponse]) => {
        setCoachAccountData(coachAccountResponse);
        setInvoice(invoiceResponse);
      })
      .finally(() => {
        setIsLoading(false);
        window.dispatchEvent(new Event('resize'));
      });
  }, [id]);

  let confimModalData: Partial<ConfirmModalData & { callback: () => void }>;
  switch (confirmModalType) {
    case ConfirmModalEnum.cancel:
    default:
      confimModalData = {
        title: `Cancel ${invoice?.number}`,
        text: 'Are you sure that you want to cancel it?',
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
        callback: handleConfirmAction(
          cancelCoachInvoice,
          'canceled successfully',
          ConfirmModalEnum[2]
        )
      };
      break;
  }

  const handleConfirmationClose = () => {
    setIsConfirmModalOpened(false);
  };

  const handleClose = () => {
    history.push(rootStore.prevUrl || CoachRoutesEnum.Invoices);
  };

  const renderButtons = () => {
    if (!invoice) {
      return null;
    }
    if (
      invoice?.status !== InvoiceStatusEnum.open &&
      invoice?.status !== InvoiceStatusEnum.late
    ) {
      // override button
      return <></>;
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal isOpened full className='CoachInvoiceView'>
        <GlobalLoader isLoading={isLoading} />
        <ModalFullHeader>
          <div className='d-flex align-items-center'>
            <Button
              handleClick={handleClose}
              className='CoachInvoiceView__close ml-3'
              variations={['naked']}
            >
              <CloseIcon />
            </Button>
            <span className='CoachInvoiceView__title ml-3 d-none d-sm-inline-block'>
              {invoice?.number}
            </span>

            <Badge
              type={getBadgeType(status)}
              title={status?.toUpperCase()}
              className='Badge--CoachInvoiceView ml-4 ml-sm-3'
            />
          </div>
          <div className='d-flex'>
            <Button
              className='button--sm d-none d-sm-block mr-2'
              variations={['inversed']}
              url={`/api/Invoices/${id}/pdf/invoice_${invoice?.number}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              Download as PDF
            </Button>
            {!!menuList.length && (
              <DropdownMenu
                withPortal={false}
                list={menuList}
                className='CoachInvoiceView__dropdown ml-2 mr-2'
              />
            )}
          </div>
        </ModalFullHeader>
        {invoice && (
          <InvoiceCard
            link=''
            coachData={coachAccountData}
            invoiceData={invoice}
            buttonsBlock={renderButtons()}
            className='mx-sm-2'
          />
        )}
      </Modal>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={handleConfirmationClose}
        title={confimModalData.title}
        text={confimModalData.text}
        confirmBtnText={confimModalData.confirmBtnText}
        confirmCallback={confimModalData.callback}
        confirmBtnClass='button__confirm-delete'
        cancelBtnText={confimModalData.cancelBtnText}
      />
    </>
  );
};

export default CoachInvoiceView;
