import React, { useState } from 'react';
import { addContact, editContact } from '../../../core/api';
import { CoachContactsViewModel } from '../../../core/backend/api';
import { CoachContactItem } from '../../../core/types';
import { useStores } from '../../../hooks/useStores';

export const useContactModal = () => {
  const {
    rootStore: { contactsStore, notificationStore }
  } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactData, setContactData] = useState<CoachContactItem>(null);
  const [isContactModalOpened, setIsContactModalOpened] = useState<boolean>(
    false
  );
  const onOpenContactModal = () => setIsContactModalOpened(true);
  const onCloseContactModal = () => setIsContactModalOpened(false);

  const onSaveContactData = async (
    contact: CoachContactsViewModel
  ): Promise<CoachContactsViewModel> => {
    setIsSubmitting(true);
    if (contactData && contactData.clientId) {
      try {
        const response = await editContact(contactData.clientId, contact);
        setContactData({
          ...contactData,
          ...contact
        });
        onCloseContactModal();
        await contactsStore.getCoachContacts();
        notificationStore.addNotification({
          text: `Client's details`,
          textColored: 'are updated',
          duration: 3000
        });
        return response;
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const response = await addContact(contact);
        onCloseContactModal();
        await contactsStore.getCoachContacts();
        notificationStore.addNotification({
          text: (
            <>
              <p className='mb-1'>New contact is added.</p>
              {!contact.isGuest && (
                <p>
                  Credentials to the account
                  <br />
                  were sent to the indicated
                  <br />
                  Client's email.
                </p>
              )}
            </>
          ),
          duration: 3500
        });
        return response;
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return {
    isContactModalOpened,
    onOpenContactModal,
    onCloseContactModal,
    contactData,
    setContactData,
    isSubmitting,
    onSaveContactData
  };
};
