import React, { FC } from 'react';
import './MemberItem.styles.scss';
import { Link } from 'react-router-dom';
import { CoachContactsExtendedViewModel } from '../../core/backend/api';
import TextEllipse from '../TextEllipse';
import {
  StripeRefundStatusEnum,
  ClientEventStatusEnum
} from '../../core/enums';

interface Props {
  item: Pick<CoachContactsExtendedViewModel, 'email'> &
    Partial<CoachContactsExtendedViewModel>;
  link?: string;
}

const MemberItem: FC<Props> = ({ item, link }) => {
  const title =
    item.firstName && item.lastName
      ? `${item.firstName} ${item.lastName}`
      : item.email;

  const isConfirmed = item.clientStatus === ClientEventStatusEnum.Active;
  const isCanceled = item.clientStatus === ClientEventStatusEnum.Canceled;
  const isRefunded = item.refundStatus === StripeRefundStatusEnum.Succeeded;

  const isInvited = !isCanceled && !isConfirmed && !isRefunded;
  return (
    <div className='MemberItem w-100'>
      <TextEllipse className='MemberItem__title mb-2' text={title}>
        {link ? <Link to={link}>{title}</Link> : <span>{title}</span>}
      </TextEllipse>
      <div className='d-flex align-items-center flex-shrink-0 mb-2'>
        {isInvited && <div className='MemberItem__badge'>invited</div>}
        {isConfirmed && (
          <div className='MemberItem__badge MemberItem__badge--confirmed'>
            confirmed
          </div>
        )}
        {isCanceled && (
          <div className='MemberItem__badge MemberItem__badge--canceled'>
            registration canceled
          </div>
        )}
        {isRefunded && (
          <div className='MemberItem__badge MemberItem__badge--refunded'>
            refunded
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberItem;
