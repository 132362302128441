import React from 'react';
import TilePage from '../../components/TilePage/TilePage';
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword';

const ForgotPasswordPage: React.FC<any> = () => {
  return (
    <TilePage
      className='ForgotPasswordPage'
      title='Log In'
      tileStyle={{ maxWidth: 440, width: '100%' }}
    >
      <ForgotPassword />
    </TilePage>
  );
};

export default ForgotPasswordPage;
