import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Thumbnail from '../../components/Thumbnail';
import AccordionButton from '../../components/AccordionButton/AccordionButton';
import { FileResponseViewModelWithExt } from '../../core/types';
import ShareIcon from '../../icons/share.svg';
import cn from 'classnames';
import './FileItem.styles.scss';
import Loader from '../Loader/Loader';
import { roundFormatedFileSize } from '../../components/FileUploader/FileUploader.helpers';
import {
  getValidLink,
  parseLink
} from '../../pages/CoachLibraryAll/CoachLibraryAll.helpers';
import TextEllipse from '../TextEllipse';

interface Props {
  children?: ReactNode;
  file: FileResponseViewModelWithExt;
  linkType: string;
  isDownloading: boolean;
  className?: string;
  isShared?: boolean;
  nameMaxLength?: number;
  onIconClick?: () => void;
  isExpanded: boolean;
  onToggle: (id: string | number) => void;
}

const FileItem: React.FC<Props> = ({
  file,
  children,
  linkType,
  className = '',
  isDownloading,
  onIconClick,
  isExpanded,
  onToggle,
  isShared = false
}) => {
  const { createdOn, description, name, fileLink, ext } = file;
  const isVideoLink = fileLink && linkType === 'video';
  const [ref, setRef] = useState<undefined | HTMLElement>();
  const [videoHeight, setVideoHeight] = useState(350);

  const setItemRef = (node: HTMLElement | undefined) => {
    setRef(node);
  };

  const getVideoHeight = useCallback(() => {
    if (!ref) return;
    const itemWidth = ref.getBoundingClientRect().width;
    setVideoHeight(((Math.round(itemWidth) - 74) / 16) * 9);
  }, [ref]);

  useEffect(() => {
    if (ref) {
      getVideoHeight();
      window.addEventListener('resize', getVideoHeight);

      return () => {
        window.removeEventListener('resize', getVideoHeight);
      };
    }
  }, [getVideoHeight, ref]);

  const handleClick = (
    handleClickAccordion: () => void,
    willBeExpanded: boolean
  ) => {
    onToggle(willBeExpanded ? file.id : 0);
    handleClickAccordion();
  };

  return (
    <div
      className={`FileItem p-3 mb-sm-4 mb-3 ${className}`}
      ref={(node) => setItemRef(node)}
    >
      {isDownloading && <Loader className='Loader_FileItem' />}
      <div className='d-flex allign-items-start'>
        <div>
          <div
            className={cn('FileItem__icon', {
              'FileItem__icon--clickable': onIconClick
            })}
            onClick={onIconClick}
          >
            <Thumbnail type={linkType || ext} />
          </div>
          {!fileLink && (
            <div className='FileItem__size'>
              {roundFormatedFileSize(file.size)}
            </div>
          )}
        </div>
        {isShared && file?.clientIds?.length > 0 && (
          <div className='FileItem__shared'>
            <span className='FileItem__sharedIcon'>
              <ShareIcon />
            </span>
            Shared
          </div>
        )}
        <div className='FileItem__content'>
          <div className='FileItem__date'>
            {moment.utc(createdOn).format('ll')}
          </div>
          <div className='d-flex justify-content-between w-100 align-items-start'>
            {description || isVideoLink ? (
              <AccordionButton
                onClick={handleClick}
                isExpanded={isExpanded}
                className={'AccordionButton--FileItem w-100'}
                btnClassName={'AccordionButton__toggler--FileItem'}
                nodeContent={
                  <>
                    <div className='FileItem__desc'>{description}</div>
                    {isVideoLink && (
                      <div
                        className='embed-responsive mt-2 mb-4'
                        style={{ minHeight: videoHeight }}
                      >
                        {isExpanded && (
                          <iframe
                            id={`FileItem-video-${file.id}`}
                            className='embed-responsive-item'
                            src={getValidLink(parseLink(fileLink))}
                            allowFullScreen
                            title={file.name}
                          ></iframe>
                        )}
                      </div>
                    )}
                  </>
                }
              >
                <TextEllipse text={name}>
                  <span>{name}</span>
                </TextEllipse>
              </AccordionButton>
            ) : (
              <TextEllipse className='FileItem__name' text={name}>
                <span>{name}</span>
              </TextEllipse>
            )}
            {children}
          </div>
        </div>
      </div>
      {fileLink && !isVideoLink && (
        <a
          href={getValidLink(fileLink)}
          className='FileItem__link'
          target='_blank'
          rel='noopener noreferrer'
        >
          {fileLink}
        </a>
      )}
    </div>
  );
};

export default FileItem;
