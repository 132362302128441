import React, { useEffect, useState } from 'react';
import './ChangePaymentMethodModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import {
  getDefaultPaymentData,
  iPaymentData
} from '../../PaymentDetails/PaymentDetails.helpers';
import PaymentDetailsNew from '../../PaymentDetailsNew/PaymentDetailsNew';
import { isTheDataValid as isPaymentDataValid } from '../../PaymentDetails/PaymentDetails.helpers';
import { StripeError } from '@stripe/stripe-js';

export interface iProps {
  isOpened: boolean;
  isSubmitting: boolean;
  isPaymentFailed: boolean;
  error: StripeError;
  onSubmit: (data: iPaymentData) => void;
  onClose: () => void;
  className?: string;
  title: string;
}

const ChangePaymentMethodModal: React.FC<iProps> = ({
  isOpened,
  isSubmitting,
  isPaymentFailed,
  error,
  onSubmit,
  onClose,
  className = '',
  title
}) => {
  const [paymentData, setPaymentData] = useState<iPaymentData>(
    getDefaultPaymentData()
  );
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);

  useEffect(() => {
    setIsFormValid(isPaymentDataValid(paymentData));
  }, [paymentData]);

  useEffect(() => {
    if (isPaymentFailed || error) {
      setIsValidationShown(true);
    }
  }, [isPaymentFailed, error]);

  useEffect(() => {
    if (isOpened) {
      setIsFormValid(true);
      setIsValidationShown(false);
      setPaymentData(getDefaultPaymentData());
    }
  }, [isOpened]);

  const handleChangePaymentField = (field: string) => (value: any) => {
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    if (isFormValid) {
      onSubmit(paymentData);
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <Modal
      className='ChangePaymentMethodModal'
      close={onClose}
      isOpened={isOpened}
      style={{ width: 486 }}
    >
      <div className='ChangePaymentMethodModal__title mb-2'>{title}</div>
      <PaymentDetailsNew
        title='Card information'
        data={paymentData}
        onChangeField={handleChangePaymentField}
        isValidationShown={isValidationShown}
        isPaymentFailed={isPaymentFailed}
        paymentError={error}
      />
      <div className='ChangePaymentMethodModal__footer'>
        <Button
          handleClick={handleSubmit}
          variations={['full-width']}
          type='button'
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
