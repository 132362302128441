import React from 'react';
import './Badge.styles.scss';

interface Props {
  className?: string;
  type: BadgeType;
  title?: string;
}

export type BadgeType =
  | 'disabled'
  | 'gray'
  | 'green'
  | 'red'
  | 'yellow'
  | 'blue';

const Badge: React.FC<Props> = ({ className = '', type, title }) => {
  return <div className={`Badge Badge--${type} ${className}`}>{title}</div>;
};

export default Badge;
