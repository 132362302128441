import React, { useState, useEffect, useCallback } from 'react';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import VideoModal from '../../components/Modals/VideoModal/VideoModal';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';
import LinkModal from '../../components/Modals/LinkModal/LinkModal';
import FileModal from '../../components/Modals/FileModal/FileModal';
import AddNewModal from '../../components/Modals/AddNewModal/AddNewModal';
import ShareFileModal from '../../components/Modals/ShareFileModal/ShareFileModal';
import CoachLibrary from '../../containers/CoachLibrary/CoachLibrary';
import CoachLibraryItem from './CoachLibraryItem';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import Thumbnail from '../../components/Thumbnail';
import { FileResponseViewModelWithExt } from '../../core/types';
import {
  getCoachFilesLibrary,
  deleteFileCoachFilesLibrary,
  getCoachFilesLibraryFileById
} from '../../core/api';
import { CoachRoutesEnum } from '../../core/enums';
import { FileResponseViewModel } from '../../core/backend/api';
import { sortArrayByTimestampDesc } from '../../core/helpers';
import { ModalAction } from '../../components/Modals/AddNewModal/AddNewModal.constants';
import { LinkModalUrlType } from '../../components/Modals/LinkModal/LinkModal.helpers';
import { filterForSearch, getLinkType } from './CoachLibraryAll.helpers';
import { useDebouncedCallback, useStores } from '../../hooks';
import { useLocation, useHistory, Link } from 'react-router-dom';
import EmptyIcon from '../../icons/file_large.svg';
import EmptyState from '../../components/EmptyState/EmptyState';
import './CoachLibraryAll.styles.scss';
import SearchInput from '../../components/SearchInput/SearchInput';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';
import PageTitle from '../../components/PageTitle/PageTitle';

const CoachLibraryAll: React.FC = () => {
  const {
    rootStore: {
      notificationStore,
      contactsStore: { contacts }
    }
  } = useStores();

  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const openModal = params.get('openModal') === 'true';

  const [files, setFiles] = useState<FileResponseViewModel[]>([]);
  const [expandedId, setExpandedId] = useState<string | number>(0);

  const {
    searchText,
    handleSearch,
    searchedItems: searchedFiles
  } = useSearchInput(files, filterForSearch);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddNewModalOpened, setIsAddNewModalOpened] = useState<boolean>(
    openModal
  );
  const [isLinkModalOpened, setIsLinkModalOpened] = useState<boolean>(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState<boolean>(
    false
  );
  const [isFileModalOpened, setIsFileModalOpened] = useState<boolean>(false);
  const [isVideoModalOpened, setIsVideoModalOpened] = useState<boolean>(false);
  const [linkModalUrlType, setLinkModalUrlType] = useState<LinkModalUrlType>(
    'link'
  );

  const [activeFile, setActiveFile] = useState<
    FileResponseViewModelWithExt | undefined
  >(undefined);
  const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
  const [isPromptModalOpened, setIsPromptModalOpened] = useState<boolean>(
    false
  );
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState<boolean>(false);

  const openAddNewModal = () => setIsAddNewModalOpened(true);

  const setLoadingDone = useDebouncedCallback(() => {
    setIsDeleteSubmitting(false);
  }, 450);

  const handleAddNewModalClick = (type: ModalAction) => {
    setActiveFile(undefined);
    setIsAddNewModalOpened(false);
    if (type === 'addLink') {
      setLinkModalUrlType('link');
      openLinkModal();
    }
    if (type === 'uploadFile') openFileModal();
    if (type === 'postVideo') {
      setLinkModalUrlType('video');
      openLinkModal();
    }
  };

  const handleDelete = () => {
    setIsDeleteSubmitting(true);
    deleteFileCoachFilesLibrary(activeFile.id)
      .then(() => {
        setIsDeleteModalOpened(false);
        setFiles((prevList) =>
          prevList.filter(({ id }) => id !== activeFile.id)
        );
        notificationStore.addNotification({
          text: !!activeFile?.fileLink ? 'Link' : 'File',
          textColored: 'was deleted'
        });
      })
      .finally(() => {
        setLoadingDone();
      });
  };

  const updateFiles = useCallback((updatedFiles: FileResponseViewModel[]) => {
    setFiles((prevFiles) => [
      ...prevFiles.filter(
        (file) =>
          !updatedFiles.some((updatedFile) => file.id === updatedFile.id)
      ),
      ...updatedFiles
    ]);
  }, []);

  const onShare = (id: number) => {
    getCoachFilesLibraryFileById(id).then((res) => {
      setFiles((prevList) =>
        prevList.map((file) => {
          if (file.id === id) return res;
          return file;
        })
      );
    });
  };

  const handleClickShare = () => {
    if (contacts.length > 0) {
      setIsShareModalOpened(true);
    } else {
      setIsPromptModalOpened(true);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpened(true);
  };
  const openVideoModal = () => {
    setIsVideoModalOpened(true);
  };
  const openFileModal = () => {
    setIsFileModalOpened(true);
  };

  const openLinkModal = () => {
    setIsLinkModalOpened(true);
  };

  useEffect(() => {
    setIsLoading(true);
    getCoachFilesLibrary()
      .then((filesList) => {
        setFiles(filesList);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (openModal) {
      setActiveFile(undefined);
      history.replace(pathname);
    }
  }, [history, openModal, pathname]);

  return (
    <CoachLibrary className='CoachLibraryAll'>
      <PageTitle title='All files' />
      <GlobalLoader isLoading={isLoading} />
      <div className='d-flex justify-content-sm-end mb-3 mb-md-0'>
        <SearchInput onSearch={handleSearch} searchText={searchText} />
      </div>
      <div className='row mx-n2 pt-md-4 mt-md-3'>
        <div className='col-md-6 px-2 pr-md-3 mr-md-n1'>
          <div
            className='CoachLibraryAll__addNew'
            onClick={() => setIsAddNewModalOpened(true)}
          >
            <span className='m-auto'>+ Add new</span>
          </div>
        </div>
      </div>
      {searchedFiles.length > 0 ? (
        <div className='row pt-sm-4 pt-3 mx-md-n3 px-md-1'>
          {searchedFiles
            .sort(sortArrayByTimestampDesc('createdOn'))
            .map((file) => {
              return (
                <div className='col-md-6 px-md-3' key={file.id}>
                  <CoachLibraryItem
                    file={file}
                    setActiveFile={setActiveFile}
                    onClickShare={handleClickShare}
                    openDeleteModal={openDeleteModal}
                    openVideoModal={openVideoModal}
                    openFileModal={openFileModal}
                    openLinkModal={openLinkModal}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
          <EmptyState
            text='There are no files here'
            icon={<EmptyIcon />}
            className='my-4'
          />
        </div>
      )}
      <ConfirmModal
        isOpened={isDeleteModalOpened}
        close={() => setIsDeleteModalOpened(false)}
        title={`Delete ${activeFile?.name || ''}`}
        text='This file is shared with other users. Are you sure that you want to delete it?'
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={handleDelete}
        disableConfirmBtn={isDeleteSubmitting}
        confirmBtnClass='button__confirm-delete'
      />
      <AddNewModal
        isOpened={isAddNewModalOpened}
        close={() => setIsAddNewModalOpened(false)}
        onClick={handleAddNewModalClick}
      />
      <VideoModal
        isOpened={isVideoModalOpened}
        close={() => setIsVideoModalOpened(false)}
        file={activeFile}
      />
      <LinkModal
        isOpened={isLinkModalOpened}
        close={() => setIsLinkModalOpened(false)}
        updateFiles={updateFiles}
        urlType={
          activeFile ? getLinkType(activeFile.fileLink || '') : linkModalUrlType
        }
        onBack={openAddNewModal}
        activeLink={activeFile}
      />
      <FileModal
        isOpened={isFileModalOpened}
        close={() => setIsFileModalOpened(false)}
        updateFiles={updateFiles}
        onBack={openAddNewModal}
        activeFile={activeFile}
      />
      <ShareFileModal
        onShare={onShare}
        isOpened={isShareModalOpened}
        close={() => setIsShareModalOpened(false)}
        FileIcon={
          <Thumbnail
            type={
              activeFile?.fileLink
                ? getLinkType(activeFile.fileLink)
                : activeFile?.ext
            }
          />
        }
        file={activeFile}
        contacts={contacts}
      />
      <PromptModal
        text={
          <div className='pr-4'>
            You will be able to share the file only when the client is added to
            the{' '}
            <Link
              className='CoachLibraryAll__contactsLink'
              to={CoachRoutesEnum.CoachClients}
            >
              Contacts
            </Link>{' '}
            section
          </div>
        }
        title='You cannot share the file'
        isOpened={isPromptModalOpened}
        close={() => setIsPromptModalOpened(false)}
      />
    </CoachLibrary>
  );
};

export default CoachLibraryAll;
