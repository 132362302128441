import React, { ReactNode, useEffect } from 'react';
import TabsMenu, { iTab } from '../../components/TabsMenu/TabsMenu';
import { useParams } from 'react-router-dom';
import { CoachRoutesEnum } from '../../core/enums';
import CoachClientCard from '../../components/CoachClientCard/CoachClientCard';
import { observer } from 'mobx-react';
import ContactModal from '../../components/Modals/ContactModal/ContactModal';
import { useContactModal } from '../../components/Modals/ContactModal/useContactModal';
import './CoachClient.styles.scss';
import cn from 'classnames';
import { useStores } from '../../hooks';

interface Props {
  children: ReactNode;
  className?: string;
}

const CoachClient: React.FC<Props> = ({ children, className }) => {
  const {
    rootStore: {
      contactsStore: { contacts }
    }
  } = useStores();

  const { clientId } = useParams<{ clientId: string }>();
  const {
    isContactModalOpened,
    onOpenContactModal,
    onCloseContactModal,
    contactData: client,
    setContactData,
    isSubmitting,
    onSaveContactData
  } = useContactModal();

  const tabs: iTab[] = [
    /*     {
      name: 'Client Profile',
      link: ''
    }, */
    {
      name: 'Schedule',
      link: CoachRoutesEnum.CoachClientSchedule.replace(':clientId', clientId)
    },
    {
      name: 'Notes',
      link: CoachRoutesEnum.CoachClientNotes.replace(':clientId', clientId)
    },
    {
      name: 'Transactions',
      link: CoachRoutesEnum.CoachClientTransactions.replace(
        ':clientId',
        clientId
      )
    },
    {
      name: 'Library Access',
      link: CoachRoutesEnum.CoachClientLibraryAccess.replace(
        ':clientId',
        clientId
      )
    }
  ];

  useEffect(() => {
    const contact = contacts.find((contact) => contact.clientId === clientId);
    contact && setContactData(contact);
  }, [contacts, clientId]);

  return (
    <div className='CoachClient'>
      <div className='d-md-none mb-3'>
        {client && (
          <CoachClientCard client={client} onEdit={onOpenContactModal} />
        )}
      </div>
      <TabsMenu tabsList={tabs} className='TabsMenu--CoachClient' />
      <div className={cn('CoachClient__container mt-md-4 mt-3', [className])}>
        <div className='CoachClient__card d-md-block d-none'>
          {client && (
            <CoachClientCard client={client} onEdit={onOpenContactModal} />
          )}
        </div>
        <div className='CoachClient__content'>{children}</div>
      </div>
      <ContactModal
        isOpened={isContactModalOpened}
        data={client}
        close={onCloseContactModal}
        onSave={onSaveContactData}
        isSubmitting={isSubmitting}
        emailsInUse={contacts
          .map((contact) => contact.email)
          .filter((e) => e !== client?.email)}
      />
    </div>
  );
};

export default observer(CoachClient);
