import React from 'react';
import { iCoachPageCustomizationData } from '../../core/types';
import FormInput from '../FormInput/FormInput';
import CustomizeSidebarTab from './CustomizeSidebarTab';
interface Props {
  handleChangeField: (field: string) => (value: any) => void;
  data: iCoachPageCustomizationData;
}

const CustomizeBioTab: React.FC<Props> = ({ data, handleChangeField }) => {
  return (
    <CustomizeSidebarTab>
      <FormInput
        label='Your Position'
        value={data.position}
        placeholder='Your title'
        handleChange={handleChangeField('position')}
        tabIndex={1}
      />
      <FormInput handleChange={() => {}} value={''} label='Your Bio'>
        <textarea
          className='CustomizeSidebar__bio-textarea'
          value={data.bio}
          placeholder='Your bio goes here...Write something amazing about your experience.'
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChangeField('bio')(event.target.value);
          }}
          tabIndex={1}
        />
      </FormInput>
    </CustomizeSidebarTab>
  );
};

export default CustomizeBioTab;
