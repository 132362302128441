import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CoachSteps } from '../../core/types';
import './QuickStartSetup.styles.scss';
import AccordionButton from '../../components/AccordionButton/AccordionButton';
import QuickStartSetupItem from './QuickStartSetupItem';
import NextIcon from '../../icons/next.svg';
import cn from 'classnames';

export interface iQuickStartSetupItem {
  key: keyof CoachSteps;
  name: string;
  description: string;
  icon: JSX.Element;
  url: string;
  isCompleted: boolean;
}

interface Props {
  menuList: iQuickStartSetupItem[];
  parent: HTMLDivElement;
  isMobile: boolean;
}

type BtnDirection = 'left' | 'right';

const MENU_ITEM_MAX_WIDTH = 235.6;

const QuickStartSetup: React.FC<Props> = ({ menuList, parent, isMobile }) => {
  const [isDisabledLeft, setIsDisabledLeft] = useState(false);
  const [isDisabledRight, setIsDisabledRight] = useState(false);
  const [isArrowsShown, setIsArrowsShown] = useState(false);

  const ref = useRef<HTMLDivElement>();

  const onScroll = useCallback(() => {
    if (ref.current.scrollLeft === 0) return setIsDisabledLeft(true);
    setIsDisabledLeft(false);
    if (
      ref.current.scrollLeft >=
      ref.current.scrollWidth - ref.current.offsetWidth
    )
      return setIsDisabledRight(true);
    setIsDisabledRight(false);
  }, []);

  const onResize = useCallback(() => {
    setIsArrowsShown(
      parent?.offsetWidth < MENU_ITEM_MAX_WIDTH * menuList.length - 16
    );
  }, [menuList.length, parent]);

  useEffect(() => {
    if (!parent) return;
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isMobile, onResize, parent]);

  useEffect(() => {
    if (ref.current) {
      const div = ref.current;
      if (!isMobile) {
        div.addEventListener('scroll', onScroll);
        return () => {
          div.removeEventListener('scroll', onScroll);
        };
      }
    }
  }, [isMobile, onScroll]);

  const handleClick = (direction: BtnDirection) => () => {
    if (direction === 'left' && isDisabledLeft) return;
    if (direction === 'right' && isDisabledRight) return;

    ref.current.scrollTo({
      top: 0,
      left:
        direction === 'left'
          ? ref.current.scrollLeft - MENU_ITEM_MAX_WIDTH
          : ref.current.scrollLeft + MENU_ITEM_MAX_WIDTH,
      behavior: 'smooth'
    });
  };

  const btns: BtnDirection[] = ['left', 'right'];

  if (isMobile) {
    return (
      <div className='QuickStartSetup mx-2 mx-sm-0'>
        <AccordionButton
          className='AccordionButton--QuickStartSetup my-0'
          nodeContent={
            <>
              {menuList.map((item) => (
                <QuickStartSetupItem item={item} key={item.name} />
              ))}
            </>
          }
        >
          <div className='QuickStartSetup__title'>Quick Start Setup</div>
        </AccordionButton>
      </div>
    );
  }

  return (
    <div className='QuickStartSetup p-3 mx-n3 mt-n3'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='QuickStartSetup__title mb-0'>Quick Start Setup</div>
        <div className='d-flex align-items-center'>
          {isArrowsShown &&
            btns.map((btn) => (
              <div
                key={btn}
                className={cn(
                  `QuickStartSetup__btn QuickStartSetup__btn_${btn}`,
                  {
                    QuickStartSetup__btn_disabled:
                      btn === 'left' ? isDisabledLeft : isDisabledRight
                  }
                )}
                onClick={handleClick(btn)}
              >
                <NextIcon />
              </div>
            ))}
        </div>
      </div>
      <div
        className='d-flex align-items-stretched QuickStartSetup__container py-4'
        ref={ref}
        style={{ overflowX: 'auto' }}
      >
        {menuList.map((item) => (
          <QuickStartSetupItem
            item={item}
            key={item.name}
            maxWidth={MENU_ITEM_MAX_WIDTH}
          />
        ))}
      </div>
    </div>
  );
};

export default QuickStartSetup;
