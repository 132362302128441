import { ScheduleItemType, StripeRefundStatusEnum } from '../../core/enums';
import { iListViewItem } from '../../core/types';
import { BadgeType } from '../Badge/Badge';

export type CoachScheduleCalendarItemStatus =
  | 'invited'
  | 'canceled'
  | 'refunded'
  | 'confirmed';

export const getBadgeType = (
  type: 'invited' | 'canceled' | 'refunded' | 'confirmed'
): BadgeType => {
  switch (type) {
    case 'refunded':
      return 'red';
    case 'confirmed':
      return 'green';
    case 'invited':
    case 'canceled':
    default:
      return 'disabled';
  }
};

export const generateBadges = ({
  itemType,
  isCanceled,
  details
}: iListViewItem) => {
  if (itemType === ScheduleItemType.Event) {
    return [];
  }

  return [
    ...(itemType === ScheduleItemType.OneToOne &&
    !isCanceled &&
    !details.membersAmount
      ? [
          {
            title: 'Invited',
            type: getBadgeType('invited')
          }
        ]
      : []),
    ...(!isCanceled &&
    details.membersAmount > 0 &&
    (itemType === ScheduleItemType.Appointment ||
      itemType === ScheduleItemType.OneToOne)
      ? [
          {
            title: 'Confirmed',
            type: getBadgeType('confirmed')
          }
        ]
      : []),
    ...(isCanceled &&
    (itemType === ScheduleItemType.Appointment ||
      itemType === ScheduleItemType.OneToOne)
      ? [
          {
            title: details.members.length
              ? 'Registration canceled'
              : 'Invitation canceled',
            type: getBadgeType('canceled')
          }
        ]
      : []),
    ...(details.members[0]?.refundStatus === StripeRefundStatusEnum.Succeeded &&
    details.price > 0
      ? [
          {
            title: 'Refunded',
            type: getBadgeType('refunded')
          }
        ]
      : [])
  ];
};
