import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Availability, AvailabilityInterval } from '../../core/types';
import { even, odd } from '../../core/helpers';
import './CalendarAvailabilityCell.styles.scss';
import { iCellRendererProps } from '../WeekCalendar/WeekCalendar';
import { useMedia } from '../../hooks';
import { getDayAvailability } from '../../core/helpers';
import ArrowDown from '../../icons/arrow_down_sm.svg';

interface Interval extends AvailabilityInterval {
  cellText: string;
  cellTextColor: string;
  cellBgColor: string;
}

interface Props {
  availability: Availability;
  className?: string;
  onClick?: (day: Moment, intervals: AvailabilityInterval[]) => void;
  readOnly?: boolean;
}

const CalendarAvailabilityCell: React.FC<Props & iCellRendererProps> = ({
  availability,
  day,
  isDisabled,
  className = '',
  onClick,
  readOnly = false
}) => {
  const [isToggle, setIsToggle] = useState(false);
  const [intervals, setIntervals] = useState<AvailabilityInterval[]>([]);

  const classList = new Set(['CalendarAvailabilityCell', className]);

  const isDesktop = useMedia(['(min-width: 768px)'], [true], false);
  const columnsCount = useMedia(
    ['(min-width: 768px)', '(min-width: 576px)'],
    [1, 2],
    1
  );

  useEffect(() => {
    setIntervals(getDayAvailability(availability, day));
  }, [availability, day]);

  const isDayUnavailable =
    intervals.length === 1 && intervals[0].timeStartMs === -1;

  if (isDisabled) {
    classList.add('CalendarAvailabilityCell--disabled');
  }

  if (isDayUnavailable) {
    classList.add('CalendarAvailabilityCell--unavailable');
  }

  if (readOnly) {
    classList.add('CalendarAvailabilityCell--readonly');
  }

  const intervalsWithAdditionalProps = intervals
    .filter(
      (interval) => interval.timeStartMs !== -1 || interval.timeEndMs !== -1
    )
    .map(
      (interval): Interval => ({
        ...interval,
        cellText: `${moment
          .utc(interval.timeStartMs)
          .format('hh:mm a')} - ${moment
          .utc(interval.timeEndMs)
          .format('hh:mm a')}`,
        cellTextColor: '#50565B',
        cellBgColor: '#F2F2F2'
      })
    );

  intervalsWithAdditionalProps.sort((a, b) => {
    return a.timeStartMs <= b.timeStartMs ? -1 : 1;
  });

  const renderColumn = (item: Interval) => {
    const styles = {
      color: item.cellTextColor,
      backgroundColor: item.cellBgColor
    };
    return (
      <div
        key={item.timeStartMs}
        className='CalendarAvailabilityCell__interval'
        style={styles}
      >
        {item.cellText}
      </div>
    );
  };

  const isRenderButton =
    !isDisabled && intervalsWithAdditionalProps.length / columnsCount > 2;

  const toggleShowAll = (event?: React.MouseEvent) => {
    event!.stopPropagation();
    setIsToggle((prev) => !prev);
  };

  const calcMaxHeight = () => {
    if (columnsCount === 2) {
      return 38 * Math.round(intervalsWithAdditionalProps.length / 2) + 10;
    }

    if (columnsCount === 1) {
      return 38 * intervalsWithAdditionalProps.length + 10;
    }

    return;
  };

  const handleClick = () => {
    onClick(day, intervals);
  };

  return (
    <>
      <div
        style={
          !isDesktop
            ? {
                maxHeight: isToggle ? calcMaxHeight() : 100
              }
            : {}
        }
        className={`${Array.from(classList).join(' ')} ${
          isToggle ? 'CalendarAvailabilityCell--full-height' : ''
        }`}
        onMouseDown={handleClick}
        data-testid={`availability-cell-${day.format('D')}-${day.format(
          'MMM'
        )}`}
      >
        {isDesktop ? (
          <div className='CalendarAvailabilityCell__date-day'>
            {day.format('D')}
          </div>
        ) : (
          <div className='CalendarAvailabilityCell__date-full'>
            <div>{day.format('ddd D')}</div>
            <div>{day.format('MMM')}</div>
          </div>
        )}

        {columnsCount === 2 ? (
          <>
            <div className='flex-grow-1 px-3 px-md-0'>
              {!isDisabled &&
                intervalsWithAdditionalProps
                  .filter((_, index) => even(index))
                  .map((interval) => renderColumn(interval))}
            </div>
            <div className='flex-grow-1 px-3 px-md-0'>
              {!isDisabled &&
                intervalsWithAdditionalProps
                  .filter((_, index) => odd(index))
                  .map((interval) => renderColumn(interval))}
            </div>
          </>
        ) : (
          <div className='flex-grow-1 px-3 px-md-0'>
            {!isDisabled &&
              intervalsWithAdditionalProps.map((interval) =>
                renderColumn(interval)
              )}
          </div>
        )}

        {isRenderButton && (
          <button
            type='button'
            className={`CalendarAvailabilityCell__button d-md-none ${
              isToggle ? 'CalendarAvailabilityCell__button--rotate' : ''
            }`}
            onMouseDown={toggleShowAll}
          >
            <ArrowDown />
          </button>
        )}
      </div>
    </>
  );
};

export default CalendarAvailabilityCell;
