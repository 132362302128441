import React from 'react';
import './CopyLinkInput.styles.scss';
import LinkIcon from '../../icons/link.svg';
import { copyToClipboard } from '../../core/helpers';
import { observer } from 'mobx-react';
import CopyIcon from '../../icons/copy-blue.svg';
import { useStores } from '../../hooks';

interface iProps {
  link: string;
  className?: string;
  isDisabled?: boolean;
}

const CopyLinkInput: React.FC<iProps> = ({
  link = '',
  className = '',
  isDisabled
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const handleClick = () => {
    if (!isDisabled) {
      copyToClipboard(link);
      notificationStore.addNotification({
        text: 'Link to the video call',
        textColored: 'was copied to your buffer'
      });
    }
  };
  return (
    <div className={`CopyLinkInput__link-input-wrapper ${className}`}>
      <div className='CopyLinkInput__link-icon d-sm-flex d-none'>
        <LinkIcon />
      </div>
      <input
        className='CopyLinkInput__link-input'
        disabled
        defaultValue={link}
      />
      <div
        className={`CopyLinkInput__link-copy d-flex align-items-center ${
          isDisabled ? 'CopyLinkInput__link-copy--disabled' : ''
        }`}
        onClick={handleClick}
      >
        <span className='d-sm-block d-none'>Copy</span>
        <span className='d-sm-none d-flex align-items-center'>
          <CopyIcon />
        </span>
      </div>
    </div>
  );
};

export default observer(CopyLinkInput);
