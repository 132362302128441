import React, { useEffect, useState } from 'react';
import FormInput from '../../../components/FormInput/FormInput';

import Modal from '../../../components/Modal/Modal';
import ModalHeader from '../../../components/ModalHeader/ModalHeader';
import Button from '../../../components/Button/Button';
import './CoachInvoiceItemModal.styles.scss';
import { InvoiceServiceViewModel } from '../../../core/backend/api';
import {
  getServiceInputErrors,
  isServiceDataValid,
  isServiceInputValid
} from '../CoachInvoiceForm/CoachInvoiceForm.helpers';
import {
  formatValueAsInteger,
  formatValueAsPrice
} from '../../../core/formValidation';
import { normalizePrice } from '../../../core/helpers';

interface Props {
  isOpened: boolean;
  data?: InvoiceServiceViewModel;
  close: () => void;
  onSubmit: (data: InvoiceServiceViewModel) => void;
}

const CoachInvoiceItemModal: React.FC<Props> = ({
  isOpened,
  data,
  close,
  onSubmit
}) => {
  const [localData, setLocalData] = useState<InvoiceServiceViewModel>({
    title: '',
    quantity: 1,
    price: 0
  });
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);

  useEffect(() => {
    if (isOpened) {
      if (data) {
        setLocalData(data);
      } else {
        setLocalData({
          title: '',
          quantity: 1,
          price: 0
        });
      }
    }
  }, [data, isOpened]);

  const handleFieldChange = (field: keyof InvoiceServiceViewModel) => (
    value: any
  ) => {
    setLocalData((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsValidationShown(false);

    if (isServiceDataValid(localData)) {
      onSubmit(localData);
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 424 }}>
      <div className='CoachInvoiceItemModal'>
        <ModalHeader>
          <h2>{data ? 'Edit' : 'New'} item</h2>
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <FormInput
            label='Item'
            name='serviceTitle'
            value={localData.title}
            isInvalid={
              isValidationShown && !isServiceInputValid('title', localData)
            }
            errorMessage={getServiceInputErrors('title', localData)[0]}
            handleChange={handleFieldChange('title')}
            placeholder='Name'
            autoFocus
          />
          <FormInput
            label='QTY'
            name='serviceQuantity'
            type='number'
            step={1}
            value={localData.quantity}
            valueFormatter={formatValueAsInteger}
            isInvalid={
              isValidationShown && !isServiceInputValid('quantity', localData)
            }
            errorMessage={getServiceInputErrors('quantity', localData)[0]}
            handleChange={handleFieldChange('quantity')}
            placeholder='Qty'
          />
          <FormInput
            label='Price'
            name='servicePrice'
            type='number'
            step={0.01}
            value={localData.price}
            valueFormatter={formatValueAsPrice}
            isInvalid={
              isValidationShown && !isServiceInputValid('price', localData)
            }
            errorMessage={getServiceInputErrors('price', localData)[0]}
            handleChange={handleFieldChange('price')}
            placeholder='Price'
            prepend='$'
          />
          <div className='CoachInvoiceItemModal__total py-4 mt-4 d-flex justify-content-between'>
            <span>Total</span>
            <strong>
              $
              {normalizePrice(
                (localData.price || 0) * (localData.quantity || 0)
              )}
            </strong>
          </div>
          <div className='d-flex mt-4 justify-content-end'>
            <Button
              type='button'
              variations={['inversed', 'xs-width']}
              handleClick={close}
            >
              Cancel
            </Button>
            <Button variations={['sm-width']}>
              {data ? 'Update' : 'Add'} item
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CoachInvoiceItemModal;
