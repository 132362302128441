import React, { useState, useEffect } from 'react';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../Button/Button';
import { getServerModelValidation } from '../../core/helpers';
import './SupportEmailForm.styles.scss';
import {
  getFormInputErrors,
  isFormDataValid,
  isFormInputValid
} from './SupportEmailForm.helpers';

interface Props {
  email: string;
  onSave: (email: string) => Promise<unknown>;
  className?: string;
}

const SupportEmailForm: React.FC<Props> = ({
  email,
  onSave,
  className = ''
}) => {
  const [data, setData] = useState<{ email: string }>({ email });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [serverValidation, setServerValidation] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    setData({ email });
  }, [email]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormDataValid(data)) {
      setIsValidationShown(false);
      setIsSubmitting(true);
      onSave(data.email)
        .catch((error) => {
          setIsValidationShown(true);
          setServerValidation(getServerModelValidation(error));
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  const handleChangeField = (value: any) => {
    setData({ email: value });
  };

  return (
    <form
      className={`SupportEmailForm ${className}`}
      onSubmit={handleSubmit}
      autoComplete='off'
    >
      <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
        <div className='SupportEmailForm__title'>Support email</div>
        <p className='SupportEmailForm__text mt-2'>
          Set your support email to communicate with your clients. CoachPro will
          use it to send invitations or updates about appointments and meetings
          you have.
        </p>
        <div className='row mx-n2'>
          <div className='col-sm-6 px-2'>
            <FormInput
              label='Support email'
              placeholder='Support email'
              value={data.email}
              handleChange={handleChangeField}
              isInvalid={
                isValidationShown &&
                (!isFormInputValid('email', data) ||
                  !!serverValidation['supportEmail'])
              }
              errorMessage={
                getFormInputErrors('email', data)[0] ||
                serverValidation['supportEmail']
              }
              data-testid='support-email'
            />
          </div>
        </div>
        <div className='d-flex justify-content-end mt-4'>
          <Button
            disabled={isSubmitting}
            className='button__account-save'
            data-testid='support-email-save-button'
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SupportEmailForm;
