import React, { useEffect } from 'react';
import SignedPage from '../../containers/SignedPage/SignedPage';
import { CoachRoutesEnum, NotificationTypesEnum } from '../../core/enums';
import { iRoute } from '../../core/routes';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useFeatures, useStores } from '../../hooks';
import CoachNew from '../CoachNew/CoachNew';
import CoachAppointmentBlock from '../CoachAppointmentBlock/CoachAppointmentBlock';
import CoachAppointmentBlocks from '../CoachAppointmentBlocks/CoachAppointmentBlocks';
import CoachMeeting from '../CoachMeeting/CoachMeeting';
import CoachMeetings from '../CoachMeetings/CoachMeetings';
import CoachCalendar from '../CoachCalendar/CoachCalendar';
import CoachRequests from '../CoachRequests/CoachRequests';
import CoachSchedule from '../CoachSchedule/CoachSchedule';
import Account from '../Account/Account';
import CoachClientNotes from '../CoachClientNotes/CoachClientNotes';
import CoachClientTransactions from '../CoachClientTransactions/CoachClientTransactions';
import CoachClientSchedule from '../CoachClientSchedule/CoachClientSchedule';
import CoachClientLibraryAccess from '../CoachClientLibraryAccess/CoachClientLibraryAccess';
import CoachClients from '../CoachClients/CoachClients';
import SharingHistory from '../SharingHistory/SharingHistory';
import CoachLibraryAll from '../CoachLibraryAll/CoachLibraryAll';
import Pages from '../Pages/Pages';
import CoachPayments from '../CoachPayments/CoachPayments';
import CoachInvoice from '../CoachInvoice/CoachInvoice';
import CoachInvoiceView from '../CoachInvoiceView/CoachInvoiceView';
import CoachInvoices from '../CoachInvoices/CoachInvoices';
import CoachDashboard from '../CoachDashboard/CoachDashboard';
import {
  GOOGLE_CONNECTED_KEY,
  GOOGLE_SUCCESS_MESSAGE,
  restoreDataAfterStripeConnect,
  restoreDataAfterZoomConnect,
  STRIPE_CONNECTED_KEY,
  STRIPE_SUCCESS_MESSAGE,
  ZOOM_CONNECTED_KEY,
  ZOOM_SUCCESS_MESSAGE
} from '../../core/integrations';

export const routes: iRoute[] = [
  {
    path: CoachRoutesEnum.New,
    component: CoachNew
  },
  {
    path: CoachRoutesEnum.AppointmentBlockNew,
    component: CoachAppointmentBlock
  },
  {
    path: CoachRoutesEnum.AppointmentBlockDetails,
    component: CoachAppointmentBlock
  },
  {
    path: CoachRoutesEnum.AppointmentBlocks,
    component: CoachAppointmentBlocks
  },
  {
    path: CoachRoutesEnum.MeetingNew,
    component: CoachMeeting
  },
  {
    path: CoachRoutesEnum.MeetingDetails,
    component: CoachMeeting
  },
  {
    path: CoachRoutesEnum.Meetings,
    component: CoachMeetings
  },
  {
    path: CoachRoutesEnum.Calendar,
    component: CoachCalendar
  },
  {
    path: CoachRoutesEnum.Requests,
    component: CoachRequests
  },
  {
    path: CoachRoutesEnum.ScheduleMeetingNew,
    component: CoachSchedule
  },
  {
    path: CoachRoutesEnum.ScheduleMeetingDetails,
    component: CoachSchedule
  },
  {
    path: CoachRoutesEnum.Account,
    component: Account
  },
  {
    path: CoachRoutesEnum.CoachClientNotes,
    component: CoachClientNotes,
    exact: true
  },
  {
    path: CoachRoutesEnum.CoachClientTransactions,
    component: CoachClientTransactions,
    exact: true
  },
  {
    path: CoachRoutesEnum.CoachClientSchedule,
    component: CoachClientSchedule,
    exact: true
  },
  {
    path: CoachRoutesEnum.CoachClientLibraryAccess,
    component: CoachClientLibraryAccess,
    exact: true
  },
  {
    path: CoachRoutesEnum.CoachClients,
    component: CoachClients
  },
  {
    path: CoachRoutesEnum.LibraryHistory,
    component: SharingHistory
  },
  {
    path: CoachRoutesEnum.Library,
    component: CoachLibraryAll
  },

  {
    path: CoachRoutesEnum.Pages,
    component: Pages
  },
  {
    path: CoachRoutesEnum.Transactions,
    component: CoachPayments
  },
  {
    path: CoachRoutesEnum.InvoiceNew,
    component: CoachInvoice
  },
  {
    path: CoachRoutesEnum.InvoiceEdit,
    component: CoachInvoice
  },
  {
    path: CoachRoutesEnum.Invoice,
    component: CoachInvoiceView
  },
  {
    path: CoachRoutesEnum.Invoices,
    component: CoachInvoices,
    exact: true
  },
  {
    path: CoachRoutesEnum.Dashboard,
    component: CoachDashboard
  },
  {
    type: 'redirect',
    path: CoachRoutesEnum.Schedule,
    redirectTo: CoachRoutesEnum.Calendar
  },
  {
    type: 'redirect',
    path: '*',
    redirectTo: CoachRoutesEnum.Dashboard
  }
];

const CoachPortal = () => {
  const { invoicesEnabled } = useFeatures();
  const history = useHistory();
  const { rootStore } = useStores();
  const { notificationStore, userStore } = rootStore;
  const params = new URLSearchParams(history.location.search);
  const isAfterZoomConnected = !!params.get(ZOOM_CONNECTED_KEY);
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);
  const isAfterGoogleConnected = !!params.get(GOOGLE_CONNECTED_KEY);

  const googleConnectionError = isAfterGoogleConnected
    ? params.get('error')
    : '';
  const stripeConnectionError = isAfterStripeConnected
    ? params.get('error')
    : '';

  useEffect(() => {
    if (isAfterZoomConnected) {
      if (params.get(ZOOM_CONNECTED_KEY) === 'true') {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Success,
          text: ZOOM_SUCCESS_MESSAGE
        });
      } else if (params.get(ZOOM_CONNECTED_KEY) === 'false') {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text: 'Zoom connection failed.'
        });
      }
    }

    // eslint-disable-next-line
  }, [isAfterZoomConnected]);

  useEffect(() => {
    if (isAfterStripeConnected) {
      if (params.get(STRIPE_CONNECTED_KEY) === 'true') {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Success,
          text: STRIPE_SUCCESS_MESSAGE
        });
      } else if (params.get(STRIPE_CONNECTED_KEY) === 'false') {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text:
            stripeConnectionError === 'access_denied'
              ? 'You denied Stripe connection request.'
              : stripeConnectionError === 'already_connected'
              ? 'You have Stripe already connected.'
              : 'Stripe connection failed.'
        });
      }
    }

    // eslint-disable-next-line
  }, [isAfterStripeConnected]);

  useEffect(() => {
    if (isAfterGoogleConnected) {
      if (params.get(GOOGLE_CONNECTED_KEY) === 'true') {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Success,
          text: GOOGLE_SUCCESS_MESSAGE
        });
      } else {
        notificationStore.addNotification({
          type: NotificationTypesEnum.Error,
          text:
            googleConnectionError === 'already_connected'
              ? 'You have Google Calendar already connected'
              : 'Google Calendar connection failed.'
        });
      }
    }
    // eslint-disable-next-line
  }, [isAfterGoogleConnected]);

  const restoreData = isAfterZoomConnected
    ? restoreDataAfterZoomConnect()
    : isAfterStripeConnected
    ? restoreDataAfterStripeConnect()
    : { path: '' };

  const getConnectParam = () => {
    if (isAfterZoomConnected) {
      return `?${ZOOM_CONNECTED_KEY}=${params.get(ZOOM_CONNECTED_KEY)}`;
    }
    if (isAfterStripeConnected) {
      return `?${STRIPE_CONNECTED_KEY}=${params.get(STRIPE_CONNECTED_KEY)}`;
    }
  };

  useEffect(() => {
    if (
      [isAfterZoomConnected, isAfterStripeConnected].some((p) => !!p) &&
      restoreData.path
    ) {
      history.push(restoreData.path + getConnectParam());
    }
  }, [isAfterZoomConnected, isAfterStripeConnected]);

  return (
    <SignedPage>
      <Switch>
        {routes
          .filter((r) =>
            invoicesEnabled
              ? userStore.isStripeConnected
                ? true
                : ![
                    CoachRoutesEnum.Invoice,
                    CoachRoutesEnum.InvoiceEdit,
                    CoachRoutesEnum.InvoiceNew
                  ].includes(r.path as CoachRoutesEnum)
              : !r.path.includes(CoachRoutesEnum.Invoices)
          )
          .map((route) => {
            if (route.type === 'redirect') {
              return (
                <Route key={`redirect-${route.path}`} exact path={route.path}>
                  {(() => {
                    return (
                      <div>
                        <Redirect to={route.redirectTo} />
                      </div>
                    );
                  })()}
                </Route>
              );
            }
            return <Route key={route.path} {...route} />;
          })}
      </Switch>
    </SignedPage>
  );
};

export default CoachPortal;
