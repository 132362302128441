import React from 'react';
import './ResetPassword.styles.scss';
import Button from '../../components/Button/Button';
interface Props {
  className?: string;
  onReset?: (e?: React.MouseEvent) => void;
}

const ResetPassword: React.FC<Props> = ({ className = '', onReset }) => {
  return (
    <div className={`ResetPassword ${className}`}>
      <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
        <div className='ResetPassword__title'>Reset password</div>
        <div className='ResetPassword__text'>
          Send user email instructions to reset password below.
        </div>
        <Button
          variations={['naked']}
          handleClick={onReset}
          className='m-0 p-0 ResetPassword__btn font-weight-normal'
        >
          Reset password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;
