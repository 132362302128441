import { action, observable } from 'mobx';
import { BookingItemTypeEnum, iBookingItem } from '../core/types';
import { EventStatus, LocationTypeEnum } from '../core/enums';
import { BookingItemColorsEnum } from '../core/enums';
import { getMilliseconds } from '../core/helpers';

export function getEmptyBookingItem(): iBookingItem {
  return {
    id: 0,
    name: '',
    color: BookingItemColorsEnum.Meeting,
    hasPrice: false,
    type: BookingItemTypeEnum.Meeting,
    price: 0,
    description: '',
    status: EventStatus.Active,
    locationType: LocationTypeEnum.Custom,
    location: '',
    link: '',
    membersCountLimit: 0,
    remindBeforeMs: getMilliseconds(1, 'hours'),
    thumbnailImageId: ''
  };
}

export default class ClientBookingStore {
  @observable isBookingOpened: boolean = false;

  @action openBookingPopup = (email = '') => {
    this.isBookingOpened = true;
  };

  @action closePopup = () => {
    this.isBookingOpened = false;
  };
}
