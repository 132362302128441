import React, { useState } from 'react';
import { FileResponseViewModelWithExt } from '../../core/types';
import { getCoachFilesLibraryFileID } from '../../core/api';
import DownloadIcon from '../../icons/download.svg';
import ShareIcon from '../../icons/share.svg';
import DeleteIcon from '../../icons/bucket.svg';
import CopyIcon from '../../icons/copy.svg';
import ViewIcon from '../../icons/view-icon.svg';
import {
  downloadFileFromMemory,
  openLinkNewTab,
  stopVideo
} from '../../core/helpers';
import { useStores } from '../../hooks';
import { copyToClipboard, getExt } from '../../core/helpers';
import {
  getLinkType,
  getValidLink,
  parseLink
} from './CoachLibraryAll.helpers';
import FileItem from '../../components/FileItem/FileItem';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import { FileResponseViewModel } from '../../core/backend/api';
import PencilIcon from '../../icons/pencil.svg';
import { NotificationTypesEnum } from '../../core/enums';

interface iProps {
  file: FileResponseViewModel;
  setActiveFile: (data: FileResponseViewModelWithExt) => void;
  onClickShare: () => void;
  openDeleteModal: () => void;
  openVideoModal: () => void;
  openFileModal: () => void;
  openLinkModal: () => void;
  expandedId: string | number;
  setExpandedId: (id: string | number) => void;
}

const CoachLibraryItem: React.FC<iProps> = ({
  file,
  setActiveFile,
  onClickShare,
  openDeleteModal,
  openVideoModal,
  openFileModal,
  openLinkModal,
  expandedId,
  setExpandedId
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { id, awsUrl, fileLink, name } = file;
  const ext = getExt(awsUrl);
  const isVideoLink = fileLink && getLinkType(fileLink) === 'video';
  const videoLink = isVideoLink ? parseLink(fileLink) : undefined;
  const linkType = fileLink ? getLinkType(fileLink) : undefined;

  const getMenuList = () => {
    const menuList = [
      {
        name: 'Edit',
        callback: () => {
          stopVideo(`#FileItem-video-${expandedId}`);
          setActiveFile({ ...file, ext });
          file.fileLink ? openLinkModal() : openFileModal();
        },
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <PencilIcon />
          </div>
        )
      },
      {
        name: 'Share',
        callback: () => {
          stopVideo(`#FileItem-video-${expandedId}`);
          setActiveFile({ ...file, ext });
          onClickShare();
        },
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <ShareIcon />
          </div>
        )
      },
      {
        name: 'Delete',
        callback: () => {
          stopVideo(`#FileItem-video-${expandedId}`);
          setActiveFile({ ...file, ext });
          openDeleteModal();
        },
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <DeleteIcon />
          </div>
        )
      }
    ];

    if (fileLink && !isVideoLink) {
      menuList.unshift({
        name: 'Copy link',
        callback: () => {
          if (!fileLink) return;
          copyToClipboard(
            videoLink ? getValidLink(videoLink) : getValidLink(fileLink)
          );
          notificationStore.addNotification({
            text: 'Link',
            textColored: 'was copied to your clipboard'
          });
        },
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <CopyIcon />
          </div>
        )
      });
    } else if (isVideoLink) {
      menuList.unshift({
        name: 'View',
        callback: handleView,
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <ViewIcon />
          </div>
        )
      });
    } else {
      menuList.unshift({
        name: 'Download',
        callback: handleDownload,
        icon: (
          <div className='CoachLibraryAll__dd-menu-icon'>
            <DownloadIcon />
          </div>
        )
      });
    }

    return menuList;
  };

  const handleDownload = async () => {
    stopVideo(`#FileItem-video-${expandedId}`);
    setIsDownloading(true);
    try {
      const guid = await getCoachFilesLibraryFileID(id);
      downloadFileFromMemory(name, ext, guid);
    } catch (e) {
      notificationStore.addNotification({
        text: '',
        textColored: `File not found.`,
        duration: 3000,
        type: NotificationTypesEnum.Error
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleView = () => {
    stopVideo(`#FileItem-video-${expandedId}`);
    openVideoModal();
    setActiveFile({
      ...file,
      ext: 'video',
      fileLink: videoLink
    });
  };

  const handleIconClick = () => {
    if (!fileLink) return handleDownload();
    if (!isVideoLink) return openLinkNewTab(getValidLink(fileLink));
    handleView();
  };

  return (
    <FileItem
      file={{ ...file, ext }}
      linkType={linkType}
      isShared
      className='mb-4 pt-sm-4 mx-md-n1'
      isDownloading={isDownloading}
      nameMaxLength={35}
      onIconClick={handleIconClick}
      isExpanded={expandedId === file.id}
      onToggle={setExpandedId}
    >
      <DropdownMenu
        list={getMenuList()}
        withPortal={false}
        className='CoachLibraryAll__dropdown'
      />
    </FileItem>
  );
};

export default CoachLibraryItem;
