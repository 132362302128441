import {
  isNotEmpty,
  getInputErrors,
  isInputValid,
  isDataValid
} from '../../../core/formValidation';
import { Checker } from '../../../core/types';
import {
  isDescriptionShortEnough,
  MAXIMAL_DESCRIPTION_LENGTH,
  isNameShortEnough,
  MAXIMAL_NAME_LENGTH
} from '../LinkModal/LinkModal.helpers';

export const formConfig = [
  { field: 'name', checkIsValid: [isNotEmpty, isNameShortEnough] },
  { field: 'description', checkIsValid: [isDescriptionShortEnough] }
];

export function isAddFileDataValid(data: any) {
  return isDataValid(formConfig)(data);
}
export function isAddFileInputValid(key: string, data: any) {
  return isInputValid(formConfig)(key, data);
}

export const getFormErrors = <T>(data: T) => {
  return {
    name: getInputErrors(formConfig, getErrorMessage)('name', data)[0],
    description: getInputErrors(formConfig, getErrorMessage)(
      'description',
      data
    )[0]
  };
};

const getErrorMessage = (checker: Checker) => {
  let message: string | undefined = undefined;

  switch (checker) {
    case isNameShortEnough: {
      message = `should have at most ${MAXIMAL_NAME_LENGTH} characters`;
      break;
    }
    case isDescriptionShortEnough: {
      message = `should have at most ${MAXIMAL_DESCRIPTION_LENGTH} characters`;
      break;
    }
    default: {
      message = undefined;
    }
  }

  return message;
};
