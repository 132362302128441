import React from 'react';
import ScheduleIcon from '../../icons/schedule-setup.svg';
import PaymentsIcon from '../../icons/payments-setup.svg';
import ContactsIcon from '../../icons/meeting-setup.svg';
import PagesIcon from '../../icons/customize-setup.svg';
import TrainingIcon from '../../icons/library-setup.svg';
import { iQuickStartSetupItem } from '../../components/QuickStartSetup/QuickStartSetup';
import { CoachRoutesEnum } from '../../core/enums';
import { CoachSteps } from '../../core/types';
import { getStripeConnectUrl } from '../../core/integrations';

export const getQuickStartSetup = (
  data: CoachSteps,
  userId: string
): Array<iQuickStartSetupItem> => {
  data = data || {
    isPageCistomized: false,
    isScheduled: false,
    isEventCreated: false,
    isStripeAccountConnected: false,
    isFileAdded: false
  };
  return [
    {
      key: 'isPageCistomized',
      icon: <PagesIcon />,
      isCompleted: data.isPageCistomized,
      name: 'Customize Page',
      url: CoachRoutesEnum.CoachPageEditor.toString() + '?d=true',
      ...(data.isPageCistomized
        ? {
            description:
              'You have branded your Coach page. Now you can continue customizing it.'
          }
        : {
            description:
              'Brand your Coach page with your colors, logos, and bio.'
          })
    },
    {
      key: 'isEventCreated',
      icon: <ContactsIcon />,
      isCompleted: data.isEventCreated,
      name: 'Create meeting',
      url: CoachRoutesEnum.MeetingNew.toString(),
      ...(data.isEventCreated
        ? {
            description:
              'You have already scheduled some meetings for your clients. Check them or add more.',
            url: CoachRoutesEnum.Meetings
          }
        : {
            description: 'Set up a quick meeting and invite a client to it.'
          })
    },
    {
      key: 'isScheduled',
      isCompleted: data.isScheduled,
      icon: <ScheduleIcon />,
      name: 'Set up Schedule',
      url: CoachRoutesEnum.AppointmentBlockNew.toString(),
      ...(data.isScheduled
        ? {
            description:
              'You have already scheduled some appointment blocks for your clients. Check them or add more.',
            url: CoachRoutesEnum.AppointmentBlocks.toString()
          }
        : {
            description:
              'Set up your appointment blocks and availability times for when clients can schedule with you.'
          })
    },
    {
      key: 'isFileAdded',
      icon: <TrainingIcon />,
      isCompleted: data.isFileAdded,
      name: 'Add Library Content',
      url: CoachRoutesEnum.Library.toString() + '?openModal=true',
      ...(data.isFileAdded
        ? {
            url: CoachRoutesEnum.Library.toString(),
            description:
              'Add more content for your clients: post videos, share links, upload files.'
          }
        : {
            description:
              'Post videos, share links, upload digital downloads, or anything else that will help your clients.'
          })
    },
    {
      key: 'isStripeAccountConnected',
      isCompleted: data.isStripeAccountConnected,
      icon: <PaymentsIcon />,
      name: 'Set up Payments',
      url: getStripeConnectUrl(userId),
      ...(data.isStripeAccountConnected
        ? {
            description:
              'Your Stripe account is now connected and you can get payments for your appointments and meetings.',
            url: CoachRoutesEnum.Account.toString()
          }
        : {
            description:
              'Connect your Stripe account so you can start accepting payments for your meetings.'
          })
    }
  ];
};
