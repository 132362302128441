import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';
import FormInput from '../../components/FormInput/FormInput';
import {
  getDefaultData,
  getSignUpInputErrors,
  isSignUpDataValid as isDataValid,
  isSignUpInputValid as isInputValid
} from './CoachSignUp.helpers';
import {
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import Button from '../../components/Button/Button';
import {
  PricingPlanViewModel,
  SignUpCoachViewModel
} from '../../core/backend/api';
import { observer } from 'mobx-react';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import PaymentDetails from '../../components/PaymentDetails/PaymentDetails';
import {
  getDefaultPaymentData,
  iPaymentData,
  isTheDataValid as isPaymentDataValid
} from '../../components/PaymentDetails/PaymentDetails.helpers';
import { CoachRoutesEnum, BillingIntervalEnum } from '../../core/enums';
import { useStores } from '../../hooks';
import CustomizedPhoneInput from '../../components/CustomizedPhoneInput';
import { renderPasswordReminder } from '../../components/ChangePasswordForm/ChangePasswordForm.helpers';

interface iProps {
  pricingPlans: PricingPlanViewModel[];
}

const SignUpForm: React.FC<iProps> = ({ pricingPlans }) => {
  const {
    rootStore: { userStore }
  } = useStores();

  const DEFAULT_PRICING_PLAN: PricingPlanViewModel = useMemo(
    () => pricingPlans[0],
    [pricingPlans]
  );
  const [data, setData] = useState<SignUpCoachViewModel>(getDefaultData());
  const [paymentData, setPaymentData] = useState<iPaymentData>(
    getDefaultPaymentData()
  );
  const [isValidationShown, setIsValidationShown] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [failureMessage, setFailureMessage] = useState<string>('');
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const history = useHistory();

  const pricingPlan = pricingPlans.find(({ id }) => id === data.pricingPlanId);

  if (data.pricingPlanId !== 0 && !pricingPlan) {
    history.replace(CoachRoutesEnum.SignIn);
  }

  const signUp = (stripePaymentMethodId: string) => {
    setIsLoading(true);
    userStore
      .signUp({ ...data, stripePaymentMethodId })
      .catch((errorData: { detail: string }) => {
        setIsFailed(true);
        setFailureMessage(errorData.detail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsValidationShown(false);

    if (!stripe || !elements) {
      return;
    }

    if (!isDataValid(data) || !isPaymentDataValid(paymentData)) {
      setIsValidationShown(true);
      return;
    }

    setIsPaymentFailed(false);
    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements!.getElement(CardNumberElement)!,
        billing_details: {
          email: data.email,
          address: {
            postal_code: paymentData.postalCode
          }
        }
      })
      .then((result) => {
        if (result.error) throw new Error(result.error.message);
        signUp(result.paymentMethod.id);
      })
      .catch(() => {
        setIsPaymentFailed(true);
        setIsValidationShown(true);
      });
  };

  const handleChangePaymentField = (field: string) => (value: any) => {
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      [field]: value
    }));
  };

  const handleChangeField = useCallback(
    (field: string) => (value: any) => {
      if (field === 'email') {
        setIsFailed(false);
      }
      setData((prevData) => ({
        ...prevData,
        [field]: field === 'email' ? value.trim() : value
      }));
    },
    []
  );

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const pricingPlanId = search.get('pricingPlanId');

    handleChangeField('pricingPlanId')(
      Number(pricingPlanId) || DEFAULT_PRICING_PLAN.id
    );
  }, [DEFAULT_PRICING_PLAN.id, handleChangeField, pricingPlans]);

  return (
    <form className='' onSubmit={handleSubmit} noValidate autoComplete='off'>
      <GlobalLoader isLoading={isLoading} />
      {/* fake form inputs to avoid autoComplete */}
      <div style={{ position: 'absolute', opacity: 0 }}>
        <label htmlFor='_email'>email</label>
        <input name='_email' type='email' />
        <label htmlFor='_password'>password</label>
        <input name='_password' type='password' />
      </div>
      {/* end of fake form inputs to avoid autoComplete */}
      <div className='TileOption'>
        <div className='TileOption__title'>Contact Details</div>
        <div className='row mx-n3'>
          <div className='col-6 px-3'>
            <FormInput
              tabIndex={0}
              name='firstName'
              label='First Name'
              value={data.firstName}
              isInvalid={isValidationShown && !isInputValid('firstName', data)}
              errorMessage='Please fill'
              handleChange={handleChangeField('firstName')}
            />
          </div>
          <div className='col-6 px-3'>
            <FormInput
              tabIndex={0}
              name='lastName'
              label='Last Name'
              value={data.lastName}
              isInvalid={isValidationShown && !isInputValid('lastName', data)}
              errorMessage='Please fill'
              handleChange={handleChangeField('lastName')}
            />
          </div>
          <div className='col-6 px-3'>
            <FormInput
              tabIndex={0}
              name='email'
              label='Email'
              value={data.email}
              isInvalid={
                (isValidationShown && !isInputValid('email', data)) || isFailed
              }
              errorMessage={
                isFailed ? failureMessage : 'Please input correct email'
              }
              handleChange={handleChangeField('email')}
            />
          </div>
          <div className='col-6 px-3'>
            <FormInput
              name='phoneNumber'
              label='Phone number'
              value={data.phoneNumber}
              isInvalid={
                isValidationShown && !isInputValid('phoneNumber', data)
              }
              errorMessage={`Phone number ${
                getSignUpInputErrors('phoneNumber', data)[0]
              }`}
              handleChange={handleChangeField('phoneNumber')}
              inputClassName='FormInput__input--phoneNumber'
            >
              <CustomizedPhoneInput
                value={data.phoneNumber}
                onChange={(value) => handleChangeField('phoneNumber')(value)}
                inputClass={
                  isValidationShown && !isInputValid('phoneNumber', data)
                    ? 'PhoneInputInput--error'
                    : ''
                }
                inputProps={{
                  tabIndex: 0
                }}
              />
            </FormInput>
          </div>
          <div className='row col-12'>
            <div className='col-6 px-3'>
              <FormInput
                tabIndex={0}
                name='password'
                type='password'
                label='Password'
                value={data.password}
                isInvalid={isValidationShown && !isInputValid('password', data)}
                errorMessage={`Passwords ${
                  getSignUpInputErrors('password', data)[0]
                }`}
                handleChange={handleChangeField('password')}
              />
            </div>
            <div className='col-6 px-3'>
              <FormInput
                tabIndex={0}
                name='confirmPassword'
                type='password'
                label='Confirm password'
                value={data.confirmPassword}
                isInvalid={
                  isValidationShown && !isInputValid('confirmPassword', data)
                }
                handleChange={handleChangeField('confirmPassword')}
              />
            </div>
            {!isValidationShown === !isInputValid('password', data) && (
              <div className='CoachSignUp__password-reminder px-3'>
                {renderPasswordReminder}
              </div>
            )}
          </div>
        </div>
      </div>
      {data.pricingPlanId !== 0 && pricingPlan!.subscription && (
        <PaymentDetails
          isFailed={isPaymentFailed}
          className='PaymentDetails--CoachSignUp'
          data={paymentData}
          onChangeField={handleChangePaymentField}
          isValidationShown={isValidationShown}
          paymentInfo={{
            price: pricingPlan!.subscription?.priceAmount,
            paymentDescription: BillingIntervalEnum[
              pricingPlan!.subscription?.billingInterval
            ].toLowerCase()
          }}
        />
      )}
      <Button className='button__full-width'>Activate My Account Now!</Button>
      <div className='CoachSignUp__bottom-text'>
        By placing an order you are stating that you agree to the &nbsp;
        <Link
          to={CoachRoutesEnum.TermsAndConditions}
          className='CoachSignUp__link'
        >
          Terms & Conditions
        </Link>
      </div>
    </form>
  );
};

export default observer(SignUpForm);
