import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useMedia } from '../../hooks';
import './CoachScheduleCalendarItem.styles.scss';
import { iListViewItem } from '../../core/types';
import { iItemRendererProps } from '../WeekCalendarListView/WeekCalendarListView';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import PencilIcon from '../../icons/pencil.svg';
import OneOnOneIcon from '../../icons/one-on-one-circle.svg';
import BucketIcon from '../../icons/bucket.svg';
import GroupIcon from '../../icons/group-gray.svg';
import PriceIcon from '../../icons/dollar-circle.svg';
import ViewIcon from '../../icons/view-icon.svg';
import ShareIcon from '../../icons/share.svg';
import CopyLinkInput from '../CopyLinkInput/CopyLinkInput';
import AccordionButton from '../AccordionButton/AccordionButton';
import Badge from '../Badge/Badge';
import Button from '../Button/Button';
import TextEllipse from '../TextEllipse';
import Tooltip from '../Tooltip/Tooltip';
import { ScheduleItemType } from '../../core/enums';
import { generateBadges } from './CoachScheduleCalendarItem.helpers';
import { normalizePrice } from '../../core/helpers';

interface iProps {
  item: iListViewItem;
  handleView: (id: number, itemType: ScheduleItemType) => void;
  handleEdit: (id: number, itemType: ScheduleItemType) => void;
  handleInvite?: (id: number, itemType: ScheduleItemType) => void;
  handleShare?: (id: number, itemType: ScheduleItemType) => void;
  handleCancel: (
    id: number,
    itemType: ScheduleItemType,
    isFree: boolean
  ) => void;
}

const CoachScheduleCalendarItem: React.FC<iProps & iItemRendererProps> = ({
  item,
  isDisabled,
  className = '',
  handleView,
  handleEdit,
  handleInvite,
  handleCancel,
  handleShare
}) => {
  const classNames = classnames('CoachScheduleCalendarItem', className, {
    'CoachScheduleCalendarItem--disabled': isDisabled,
    'CoachScheduleCalendarItem--readonly': item.isCanceled
  });

  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const menuList = [
    ...(!item.isCanceled
      ? [
          {
            name: 'Edit',
            callback: () => {
              handleEdit(item.id, item.itemType);
            },
            icon: <PencilIcon />
          },
          ...(item.itemType === ScheduleItemType.Event && !item.isCanceled
            ? [
                {
                  name: 'Share',
                  callback: () => {
                    handleShare(item.id, item.itemType);
                  },
                  icon: <ShareIcon />
                },
                {
                  name: 'Invite clients',
                  callback: () => {
                    handleInvite(item.id, item.itemType);
                  },
                  icon: <OneOnOneIcon />
                }
              ]
            : []),
          {
            name: 'Cancel',
            callback: () => {
              handleCancel(item.id, item.itemType, item.details.price === 0);
            },
            icon: <BucketIcon />
          }
        ]
      : [])
  ];

  const isEndInSameDate =
    moment.utc(item.date).get('date') ===
    moment.utc(item.date + item.details.duration).get('date');
  const time = `${moment.utc(item.date).format('h:mma')}${
    isEndInSameDate
      ? ' - ' + moment.utc(item.date + item.details.duration).format('h:mma')
      : ''
  }`;

  const normalizeMembersForMob = (data: string) => {
    return data.split(' ')[0];
  };

  const badges = generateBadges(item).map(({ title, type }) => (
    <Badge title={title} type={type} key={type} className='mr-2' />
  ));

  const { title, text } = item.details;

  return (
    <div className={classNames} style={{ borderColor: item.cellBorderColor }}>
      <div
        className='CoachScheduleCalendarItem__time mr-md-3 mr-2 flex-shrink-0 d-sm-block d-none'
        style={{ backgroundColor: item.cellBgColor, color: item.cellTextColor }}
      >
        {time}
      </div>
      <div className='CoachScheduleCalendarItem__content flex-grow-1'>
        <div className='d-flex mt-sm-0 mb-2 align-items-start'>
          <div className='d-flex flex-wrap flex-grow-1'>
            <div
              className={`CoachScheduleCalendarItem__title mr-3 flex-grow-1 ${
                item.itemType === ScheduleItemType.Event
                  ? 'd-sm-block d-none'
                  : ''
              }`}
            >
              {item.details.title}
            </div>
            {item.details.membersToAvailable &&
              item.itemType === ScheduleItemType.Event && (
                <div className='CoachScheduleCalendarItem__members mr-sm-3 mr-2'>
                  <GroupIcon />
                  <div className='CoachScheduleCalendarItem__members-text'>
                    {isMobile
                      ? normalizeMembersForMob(item.details.membersToAvailable)
                      : item.details.membersToAvailable}
                  </div>
                </div>
              )}
          </div>
          <div
            className={`CoachScheduleCalendarItem__price mr-sm-3 mr-1 ${
              !item.details.price
                ? 'CoachScheduleCalendarItem__price--free'
                : ''
            }`}
          >
            {!!item.details.price && <PriceIcon />}
            {item.details.price
              ? `$${normalizePrice(item.details.price)}`
              : 'Free'}
          </div>
        </div>
        <div
          className='CoachScheduleCalendarItem__time d-sm-none  mt-2 mb-sm-2 mb-4'
          style={{
            backgroundColor: item.cellBgColor,
            color: item.cellTextColor
          }}
        >
          {time}
        </div>
        <div className='CoachScheduleCalendarItem__footer'>
          <div className='CoachScheduleCalendarItem__text flex-grow-1'>
            <AccordionButton
              className='CoachScheduleCalendarItem__accordion'
              nodeContent={
                <div
                  className={`CoachScheduleCalendarItem__location mt-2 ${
                    item.isCanceled
                      ? 'CoachScheduleCalendarItem__location--canceled'
                      : ''
                  }`}
                >
                  {!isEndInSameDate && (
                    <div className='CoachScheduleCalendarItem__time-details d-flex align-items-center'>
                      <span
                        className='CoachScheduleCalendarItem__time-details__prefix'
                        style={{ backgroundColor: item.cellBgColor }}
                      ></span>
                      {moment.utc(item.date).format('MMM D, h:mma')} -{' '}
                      {moment
                        .utc(item.date + item.details.duration)
                        .format('MMM D, h:mma')}
                    </div>
                  )}
                  <span>Location:</span>
                  <CopyLinkInput
                    link={item.details.location}
                    isDisabled={isDisabled || item.isCanceled}
                  />
                </div>
              }
            >
              <TextEllipse text={item.details.text}>
                <span>{item.details.text}</span>
              </TextEllipse>
              <div className='d-sm-none d-flex mt-2'>{badges}</div>
            </AccordionButton>
          </div>
        </div>

        <div className='d-sm-block d-none'>{badges}</div>
      </div>
      <div className='CoachScheduleCalendarItem__view-btn'>
        <Tooltip
          className='d-flex align-items-center justify-content-center'
          trigger={
            <Button
              handleClick={() => {
                handleView(item.id, item.itemType);
              }}
              variations={['naked']}
              className='ml-0 p-0 CoachScheduleCalendarItem__btn'
            >
              <ViewIcon />
            </Button>
          }
          content={<>View</>}
          triggerType='hover'
        />
      </div>

      {!item.isCanceled && !isDisabled && (
        <DropdownMenu
          withPortal={false}
          list={menuList}
          className='CoachScheduleCalendarItem__dropdown pl-sm-2 ml-sm-1 '
          fullScreen
          title={
            item.itemType === ScheduleItemType.Event
              ? `${title} ${text}`
              : item.itemType === ScheduleItemType.OneToOne
              ? `Scheduled ${title}`
              : `Appointment ${title}`
          }
        />
      )}
    </div>
  );
};

export default CoachScheduleCalendarItem;
