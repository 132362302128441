import React, { useEffect, useState } from 'react';
import CoachClient from '../../containers/CoachClient/CoachClient';
import './CoachClientNotes.styles.scss';
import Note from '../../components/Note/Note';
import { deleteNote, getNotes } from '../../core/api';
import { RouteComponentProps } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import NoteModal from '../../components/Modals/NoteModal/NoteModal';
import { NoteViewModel } from '../../core/backend/api';
import { sortArrayByTimestampDesc } from '../../core/helpers';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import { useStores } from '../../hooks';
import { useDebouncedCallback } from '../../hooks';
import EmptyState from '../../components/EmptyState/EmptyState';
import IconNotes from '../../icons/notes_large.svg';
import PageTitle from '../../components/PageTitle/PageTitle';

const CoachClientNotes: React.FC<RouteComponentProps<{ clientId: string }>> = ({
  match: {
    params: { clientId }
  }
}) => {
  const {
    rootStore: { notificationStore }
  } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteViewModel[]>([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [activeNote, setActiveNote] = useState<NoteViewModel>({
    title: '',
    description: '',
    isShared: false,
    clientId
  });
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState<boolean>(false);

  const setLoadingDone = useDebouncedCallback(() => {
    setIsDeleteSubmitting(false);
  }, 450);

  useEffect(() => {
    setIsLoading(true);
    getNotes(clientId)
      .then((res) => {
        setNotes(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clientId]);

  const handleNewNote = () => {
    openModal({
      title: '',
      description: '',
      isShared: false,
      clientId
    });
  };

  const openModal = (note: NoteViewModel) => {
    setActiveNote(note);
    setIsModalOpened(true);
  };

  const openDeleteModal = (note: NoteViewModel) => {
    setActiveNote(note);
    setIsDeleteModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  const updateNotes = (newNote: NoteViewModel) => {
    const existedNote = notes.find((note) => note.id === newNote.id);
    let message = 'created';
    if (existedNote) {
      message = 'edited';
      setNotes((prevNotes) =>
        prevNotes.map((prevNote) => {
          if (prevNote.id === newNote.id) return newNote;
          return prevNote;
        })
      );
    } else {
      setNotes((prevNotes) => [newNote, ...prevNotes]);
    }

    notificationStore.addNotification({
      text: 'Note',
      textColored: `was ${message}`
    });
  };

  const handleDeleteConfirmation = () => {
    setIsDeleteSubmitting(true);
    deleteNote(activeNote.id)
      .then(() => {
        setIsDeleteModalOpened(false);
        setNotes((prevList) =>
          prevList.filter(({ id }) => id !== activeNote.id)
        );
        notificationStore.addNotification({
          text: 'Note',
          textColored: 'was deleted'
        });
      })
      .finally(() => {
        setLoadingDone();
      });
  };

  return (
    <CoachClient className='CoachClientNotes'>
      <PageTitle title='Notes' />
      {isLoading && <Loader />}
      <div className='CoachClient__title'>Client Notes</div>
      <div className='d-flex justify-content-between aling-items-center mb-4'>
        <div className='CoachClient__subTitle'>
          Add a note about this client
        </div>
        <button
          className='CoachClientNotes__newNoteBtn'
          onClick={handleNewNote}
        >
          + New Note
        </button>
      </div>
      {notes.length === 0 && !isLoading && (
        <EmptyState
          icon={<IconNotes />}
          text='There are no notes here'
          absoluteCenter={true}
          className='ml-xl-0 ml-md-5 ml-0'
        />
      )}
      {notes
        .sort(
          sortArrayByTimestampDesc<NoteViewModel>('createdOn', 'modifiedOn')
        )
        .map((note) => (
          <Note
            note={note}
            key={note.id}
            onEdit={() => openModal(note)}
            onDelete={openDeleteModal}
            withShared
            accordionClassName='AccordionButton--CoachClientNotes'
          />
        ))}
      <NoteModal
        onClose={closeModal}
        activeNote={activeNote}
        isOpened={isModalOpened}
        updateNotes={updateNotes}
      />
      <ConfirmModal
        isOpened={isDeleteModalOpened}
        close={() => setIsDeleteModalOpened(false)}
        title={`Delete Note`}
        text={`Are you sure that you want to delete note '${activeNote?.title}'?`}
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={handleDeleteConfirmation}
        disableConfirmBtn={isDeleteSubmitting}
        confirmBtnClass='button__confirm-delete'
      />
    </CoachClient>
  );
};

export default CoachClientNotes;
