import { TaxType } from '../../../core/enums';
import {
  getInputErrors,
  isDataValid,
  isInputValid,
  isMaxValueValid,
  isMinValueValid,
  isNotEmpty
} from '../../../core/formValidation';
import { Checker, iFormConfigItem, InvoiceTax } from '../../../core/types';

export const getDefaultData = (): InvoiceTax => ({
  name: '',
  type: TaxType.Rate,
  value: 0
});

const FormConfig: iFormConfigItem[] = [
  { field: 'name', checkIsValid: [isNotEmpty] },
  {
    field: 'value',
    checkIsValid: [
      isNotEmpty,
      isMinValueValid(1),
      (value: any, data: InvoiceTax) => {
        return data.type === TaxType.Rate
          ? isMaxValueValid(100)(value)
          : isMaxValueValid(999)(value);
      }
    ]
  }
];

export function isFormInputValid(inputField: string, data: InvoiceTax) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isFormDataValid(data: InvoiceTax) {
  return isDataValid(FormConfig)(data);
}

export function getFormInputErrors(inputField: string, data: InvoiceTax) {
  return getInputErrors(FormConfig, (checker: Checker) => {
    switch (checker) {
      case FormConfig.find((c) => c.field === 'value').checkIsValid[1]: {
        return `minimum ${data.type === TaxType.Rate ? 'rate' : 'amount'} is 1`;
      }
      case FormConfig.find((c) => c.field === 'value').checkIsValid[2]: {
        return `maximum ${data.type === TaxType.Rate ? 'rate' : 'amount'} is ${
          data.type === TaxType.Rate ? '100' : '999'
        }`;
      }
      default:
        return undefined;
    }
  })(inputField, data);
}
