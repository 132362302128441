import React from 'react';
import ColorPickerWrapper from '../ColorPickerWrapper/ColorPickerWrapper';
import './ColorPickerInput.styles.scss';

interface Props {
  value: string;
  onChange: (arg0: string) => void;
  label: string;
  className?: string;
}

const ColorPickerInput = ({
  label,
  value,
  onChange,
  className = ''
}: Props) => (
  <div className={`ColorPickerInput ${className}`}>
    <div className='d-flex align-items-center justify-content-between'>
      <div className='flex-shrink-0 mr-2'>{label}</div>
      <ColorPickerWrapper
        className='ColorPickerWrapper--ColorPickerInput p-0'
        color={value}
        setColor={onChange}
        align='center'
      />
    </div>
  </div>
);

export default ColorPickerInput;
