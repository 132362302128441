type iConfigItemProperty =
  | 'baseRoute'
  | 'domain'
  | 'api'
  | 'googleClientId'
  | 'zoomClientId'
  | 'stripeClientId'
  | 'stripeKey'
  | 'intercomApiKey'
  | 'gaKey'
  | 'firebase';
type iConfigProperty =
  | 'startLocal'
  | 'startDev'
  | 'startQa'
  | 'startStage'
  | 'startLive'
  | 'buildDev'
  | 'buildQa'
  | 'buildStage'
  | 'buildLive';

interface iConfigItem {
  baseRoute: string;
  domain: string;
  api: string;
  zoomClientId: string;
  googleClientId: string;
  stripeClientId: string;
  stripeKey: string;
  intercomApiKey: string;
  gaKey: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
}

type iConfig = {
  [buildKey in iConfigProperty]: Partial<iConfigItem>;
};

const BASE_CONFIG: iConfigItem = {
  baseRoute: '/',
  domain: '',
  api: '/api',
  zoomClientId: 'fPrv3KpwTRWyue2TObcZyg',
  googleClientId:
    '254459992166-2tqd77u2ug343liqse2cvp61ad4na049.apps.googleusercontent.com',
  stripeClientId: 'ca_HIevQBzZi0Zhy58qdr80r3dMVNngFkGV',
  stripeKey: 'pk_test_kNGCTOj1OCQSyrwOcJsIOdzE002q6ywdRs',
  intercomApiKey: 'tb0nrz0j',
  gaKey: 'UA-184182353-1',
  firebase: {
    apiKey: 'AIzaSyD0nARrT0_DBfDYZrC_Hqhws_ROJCDwLRE',
    authDomain: 'coacsnap-dev.firebaseapp.com',
    projectId: 'coacsnap-dev',
    storageBucket: 'coacsnap-dev.appspot.com',
    messagingSenderId: '209236859244',
    appId: '1:209236859244:web:5623e195913395e73a7b49'
  }
};

const GLOBALS_CONFIG: iConfig = {
  startLocal: {
    domain: 'http://localhost:3000'
  },
  startDev: {
    domain: 'http://localhost:3000'
  },
  startQa: {
    domain: 'http://localhost:3000',
    firebase: {
      apiKey: 'AIzaSyCZOkqpdg4aJoE82MJhYl8G2I7VRf-xSls',
      authDomain: 'coachpro-qa.firebaseapp.com',
      projectId: 'coachpro-qa',
      storageBucket: 'coachpro-qa.appspot.com',
      messagingSenderId: '384927926038',
      appId: '1:384927926038:web:7119b04c50a8621c757237'
    }
  },
  startStage: {
    domain: 'http://localhost:3000',
    firebase: {
      // live settings
      apiKey: 'AIzaSyCkDfQxLkJq6zANb51Kx70iFm0N9YPmhP0',
      authDomain: 'coachpro-live.firebaseapp.com',
      projectId: 'coachpro-live',
      storageBucket: 'coachpro-live.appspot.com',
      messagingSenderId: '564274475444',
      appId: '1:564274475444:web:33b47b8fb791c06f0002e1'
    }
  },
  startLive: {
    domain: 'http://localhost:3000',
    zoomClientId: '8go4sMlPSKanJjllRi6Rg',
    stripeClientId: 'ca_HIevbesS2RL3KjxbO4is9dCxDM8u4R0O',
    stripeKey: 'pk_live_nSqGMleLxc1buX6ENRhX0Kcl00yiVaqUEA',
    intercomApiKey: 'vdwu56xt',
    gaKey: 'UA-184182353-2',
    firebase: {
      apiKey: 'AIzaSyCkDfQxLkJq6zANb51Kx70iFm0N9YPmhP0',
      authDomain: 'coachpro-live.firebaseapp.com',
      projectId: 'coachpro-live',
      storageBucket: 'coachpro-live.appspot.com',
      messagingSenderId: '564274475444',
      appId: '1:564274475444:web:33b47b8fb791c06f0002e1'
    }
  },
  buildDev: {
    domain: 'https://coachpro-dev.trustsourcing.com',
    api: 'https://coachpro-dev.trustsourcing.com/api'
  },
  buildQa: {
    domain: 'https://coachpro-qa.trustsourcing.com',
    api: 'https://coachpro-qa.trustsourcing.com/api',
    firebase: {
      apiKey: 'AIzaSyCZOkqpdg4aJoE82MJhYl8G2I7VRf-xSls',
      authDomain: 'coachpro-qa.firebaseapp.com',
      projectId: 'coachpro-qa',
      storageBucket: 'coachpro-qa.appspot.com',
      messagingSenderId: '384927926038',
      appId: '1:384927926038:web:7119b04c50a8621c757237'
    }
  },
  buildStage: {
    domain: 'https://coachpro-stage.trustsourcing.com',
    api: 'https://coachpro-stage.trustsourcing.com/api',
    firebase: {
      // live settings
      apiKey: 'AIzaSyCkDfQxLkJq6zANb51Kx70iFm0N9YPmhP0',
      authDomain: 'coachpro-live.firebaseapp.com',
      projectId: 'coachpro-live',
      storageBucket: 'coachpro-live.appspot.com',
      messagingSenderId: '564274475444',
      appId: '1:564274475444:web:33b47b8fb791c06f0002e1'
    }
  },
  buildLive: {
    baseRoute: '/',
    domain: 'https://coachpro.club',
    api: 'https://coachpro.club/api',
    zoomClientId: '8go4sMlPSKanJjllRi6Rg',
    stripeClientId: 'ca_HIevbesS2RL3KjxbO4is9dCxDM8u4R0O',
    stripeKey: 'pk_live_nSqGMleLxc1buX6ENRhX0Kcl00yiVaqUEA',
    intercomApiKey: 'vdwu56xt',
    gaKey: 'UA-184182353-2',
    firebase: {
      apiKey: 'AIzaSyCkDfQxLkJq6zANb51Kx70iFm0N9YPmhP0',
      authDomain: 'coachpro-live.firebaseapp.com',
      projectId: 'coachpro-live',
      storageBucket: 'coachpro-live.appspot.com',
      messagingSenderId: '564274475444',
      appId: '1:564274475444:web:33b47b8fb791c06f0002e1'
    }
  }
};

const configName: iConfigProperty =
  (process.env.REACT_APP_CONFIG_NAME as iConfigProperty) || 'startQa';

export default function getGlobal(key: iConfigItemProperty): any {
  try {
    return { ...BASE_CONFIG, ...GLOBALS_CONFIG[configName] }[key];
  } catch (err) {
    return '';
  }
}

export function getSiteName() {
  return window.location.pathname.split('/')[1];
}

export function getRoutePath(path: string) {
  return path.replace(':siteName', getSiteName());
}
