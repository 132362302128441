import React, { useReducer, useState } from 'react';
import './ClientSignInModal.styles.scss';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import FormInput from '../../FormInput/FormInput';
import Button from '../../Button/Button';
// import FormCheckbox from "../../FormCheckbox/FormCheckbox";
import {
  getDefaultData,
  isSignInDataValid,
  isSignInInputValid,
  reducer
} from './ClientSignIn.helpers';
import { observer } from 'mobx-react';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import GlobalLoader from '../../GlobalLoader/GlobalLoader';
import { useStores } from '../../../hooks';
import ClientUserStore from '../../../store/ClientUserStore';

interface iProps {
  isOpened: boolean;
  close: () => void;
  showForgot: () => void;
  setEventParams?: () => void;
}

const ClientSignInModal: React.FC<iProps> = ({
  isOpened,
  close,
  showForgot,
  setEventParams = () => {}
}) => {
  const {
    rootStore: { userStore }
  } = useStores<ClientUserStore>();

  const [data, setData] = useReducer(reducer, getDefaultData());
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChangeField = (field: string) => (value: any) => {
    setIsLoginFailed(false);
    setData({
      ...data,
      [field]: field === 'email' ? value.trim() : value
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isSignInDataValid(data)) {
      setIsLoading(true);
      setEventParams();
      userStore
        .signIn({ email: data.email, password: data.password.trim() })
        .catch((error) => {
          setIsLoginFailed(true);
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 440 }}>
      <GlobalLoader isLoading={isLoading} />
      <ModalHeader>
        <h2>Sign In</h2>
      </ModalHeader>
      <form onSubmit={handleSubmit} className='ClientSignInModal'>
        <FormInput
          name='login'
          label='Email'
          value={data.email}
          isInvalid={isValidationShown && !isSignInInputValid('email', data)}
          errorMessage='Please input correct email'
          handleChange={handleChangeField('email')}
          data-testid='email-input'
        />
        <FormInput
          name='password'
          type='password'
          label='Password'
          value={data.password}
          isInvalid={isValidationShown && !isSignInInputValid('password', data)}
          errorMessage='Please fill'
          handleChange={handleChangeField('password')}
          data-testid='password-input'
        />
        <ErrorMessage isVisible={isLoginFailed}>{error}</ErrorMessage>
        <div className='ClientSignInModal__row'>
          {/*<FormCheckbox*/}
          {/*    id="rememberMe"*/}
          {/*    checked={!isAvailable}*/}
          {/*    handleChange={() => setIsAvailable(!isAvailable)}*/}
          {/*    label="Remember me"*/}
          {/*    isSquare*/}
          {/*/>*/}
          <Button
            type='button'
            variations={['naked']}
            className='CZ__link'
            handleClick={showForgot}
          >
            Forgot password
          </Button>
        </div>
        <Button className='button__full-width' data-testid='signin-btn'>
          Sign In
        </Button>
      </form>
    </Modal>
  );
};

export default observer(ClientSignInModal);
