import React, { useState, useEffect, useReducer } from 'react';
import './ManageAvailabilityIntervalsModal.styles.scss';
import { AvailabilityInterval } from '../../../core/types';
import Modal from '../../Modal/Modal';
import moment from 'moment';
import {
  isIntervalsValid,
  validateIntervalOverlaps,
  getMilliseconds,
  makeEnding,
  validateIntervalDuration,
  getFromMilliseconds
} from '../../../core/helpers';
import ApplyToMultiple from './ApplyToMultiple';
import Button from '../../../components/Button/Button';
import ModalHeader from '../../ModalHeader/ModalHeader';
import BucketIcon from '../../../icons/delete.svg';
import { DayOfWeek } from '../../../core/backend/api';
import TimePickerDropdown from '../../TimePickerDropdown/TimePickerDropdown';
// import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox';

interface iProps {
  data: AvailabilityInterval[];
  date: number;
  minInterval: number;
  close: () => void;
  isOpened: boolean;
  applyToDates: (intervals: AvailabilityInterval[], dates: number[]) => void;
  applyToWeekDays: (
    intervals: AvailabilityInterval[],
    days: DayOfWeek[]
  ) => void;
}

function reducer(
  storedData: AvailabilityInterval[],
  newData: AvailabilityInterval[]
): AvailabilityInterval[] {
  return newData;
}

const ManageAvailabilityIntervalsModal: React.FC<iProps> = ({
  close,
  isOpened,
  data,
  date,
  minInterval,
  applyToDates,
  applyToWeekDays
}) => {
  const [localData, setLocalData] = useReducer(reducer, []);
  const [currentStep, setCurrentStep] = useState(1);
  // const [isAvailable, setIsAvailable] = useState(true);
  const [isValidationShown, setIsValidationShown] = useState(false);

  const isAvailable = true;

  const isValidIntervals = isAvailable
    ? isIntervalsValid(localData, minInterval)
    : true;

  const weekDay = +moment.utc(date).format('d');
  const weekDayName = moment.utc(date).format('dddd');

  useEffect(() => {
    if (data) {
      const filteredData = data.filter(
        (interval) => interval.timeStartMs !== -1 || interval.timeEndMs !== -1
      );
      filteredData.sort((a, b) => {
        return a.timeStartMs <= b.timeStartMs ? -1 : 1;
      });

      if (filteredData.length === 0) {
        setLocalData([
          {
            timeStartMs: getMilliseconds(9, 'hours'),
            timeEndMs: getMilliseconds(18, 'hours')
          }
        ]);
      } else {
        setLocalData(filteredData);
      }
    }
  }, [data, isOpened]);

  const handlePeriodTimeChange = (
    index: number,
    field: 'timeStartMs' | 'timeEndMs',
    value: number
  ) => {
    setIsValidationShown(false);
    setLocalData([
      ...localData.slice(0, index),
      {
        ...localData[index],
        [field]: value
      },
      ...localData.slice(index + 1)
    ]);
  };

  const getDefaultIntervalDuration = () => {
    let duration = getMilliseconds(60, 'minutes');
    if (minInterval) {
      if (duration < minInterval) {
        duration =
          Math.ceil(minInterval / getMilliseconds(15, 'minutes')) *
          getMilliseconds(15, 'minutes');
      }
    }
    return duration;
  };

  const getNewInterval = (): AvailabilityInterval => {
    const last = localData[localData.length - 1];
    const duration = getDefaultIntervalDuration();
    const timeStartMs = last
      ? last.timeEndMs + getMilliseconds(60, 'minutes')
      : 0;
    const timeEndMs = last ? timeStartMs + duration : 0;
    return {
      timeStartMs,
      timeEndMs
    };
  };

  const canAddInterval = (interval: AvailabilityInterval) => {
    return getFromMilliseconds(interval.timeEndMs, 'hours') <= 24;
  };

  const addNewPeriod = () => {
    const interval = getNewInterval();
    if (canAddInterval(interval)) {
      setLocalData([...localData, getNewInterval()]);
    }
  };

  const getIntervals = (): AvailabilityInterval[] => {
    if (isAvailable) return localData;
    else
      return [
        {
          timeEndMs: -1,
          timeStartMs: -1
        }
      ];
  };

  const removeInterval = (index: number) => {
    setLocalData([...localData.slice(0, index), ...localData.slice(index + 1)]);
  };

  const width_options = [0, 660, 600];
  const isAddNewDisabled = !canAddInterval(getNewInterval());

  return (
    <Modal
      close={close}
      isOpened={isOpened}
      style={{ width: width_options[currentStep] }}
    >
      <ModalHeader>
        <h3>Edit Availability</h3>
      </ModalHeader>
      <div
        className='ManageAvailabilityIntervalsModal'
        data-testid='manage-availability-intervals-modal'
      >
        {currentStep === 1 && (
          <div className='ManageAvailabilityIntervalsModal__step pt-2'>
            {/* <FormCheckbox
                            className="FormCheckbox--custom"
                            isCustom={true}
                            id="unavaliable"
                            checked={!isAvailable}
                            handleChange={() => setIsAvailable(!isAvailable)}
                            labelBefore="I am Available"
                            label="I am Unavailable"
                        /> */}
            {isAvailable ? (
              <div className='ManageAvailabilityIntervalsModal__intervals-wrapper'>
                {localData.map((interval, index) => {
                  const intervalDurationValidation = validateIntervalDuration(
                    interval,
                    minInterval
                  );
                  const intervalOverlapValidation = validateIntervalOverlaps(
                    interval,
                    [
                      ...localData.slice(0, index),
                      ...localData.slice(index + 1)
                    ]
                  );
                  const validation = {
                    isValid:
                      intervalOverlapValidation.isValid &&
                      intervalDurationValidation.isValid,
                    errorMessage:
                      intervalOverlapValidation.errorMessage ||
                      intervalDurationValidation.errorMessage
                  };
                  return (
                    <div
                      key={index}
                      className='ManageAvailabilityIntervalsModal__interval row'
                      data-testid={`interval-${index}`}
                    >
                      <div className='row mx-n2 col-11'>
                        <div className='col-6 px-2'>
                          <TimePickerDropdown
                            timeMs={interval.timeStartMs}
                            minuteStep={15}
                            onChange={(value) =>
                              handlePeriodTimeChange(
                                index,
                                'timeStartMs',
                                value
                              )
                            }
                            isInvalid={isValidationShown && !validation.isValid}
                            errorMessage={validation.errorMessage}
                            label='From'
                            data-class='TimePickerDropdown-from'
                          />
                        </div>
                        <div className='col-6 px-2'>
                          <TimePickerDropdown
                            timeMs={interval.timeEndMs}
                            minuteStep={15}
                            onChange={(value) =>
                              handlePeriodTimeChange(index, 'timeEndMs', value)
                            }
                            isInvalid={isValidationShown && !validation.isValid}
                            label='To'
                            data-class='TimePickerDropdown-to'
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => removeInterval(index)}
                        className='ManageAvailabilityIntervalsModal__button-delete col-1'
                        data-class='interval-delete-btn'
                      >
                        <BucketIcon />
                      </button>
                    </div>
                  );
                })}
                <Button
                  handleClick={addNewPeriod}
                  disabled={isAddNewDisabled}
                  variations={['naked']}
                  className='ManageAvailabilityIntervalsModal__button mt-3'
                  data-testid='new-interval-btn'
                >
                  + New Interval
                </Button>
              </div>
            ) : (
              <div className='ManageAvailabilityIntervalsModal__intervals-wrapper'>
                <div className='ManageAvailabilityIntervalsModal__warning'>
                  Make me unavailable for the whole day
                </div>
              </div>
            )}

            <div className='ManageAvailabilityIntervalsModal__footer  row'>
              <Button
                className='button__sm-width col-sm-5 order-3 order-sm-1'
                style={{ flexGrow: 1 }}
                handleClick={() => {
                  if (isValidIntervals) {
                    applyToDates(getIntervals(), [date]);
                  } else {
                    setIsValidationShown(true);
                  }
                }}
                data-testid='btn-apply-to-date'
              >
                Apply to {moment.utc(date).format('MMM D')}
              </Button>
              <Button
                data-testid='btn-apply-to-current-day-of-week'
                className='button__sm-width col ml-sm-3 ml-0 mb-3 mb-sm-0 order-2'
                style={{ flexGrow: 1 }}
                handleClick={() => {
                  if (isValidIntervals) {
                    applyToWeekDays(getIntervals(), [
                      +moment.utc(date).format('d')
                    ]);
                  } else {
                    setIsValidationShown(true);
                  }
                }}
              >
                Apply to all {makeEnding(weekDayName)}
              </Button>
              <Button
                handleClick={() => {
                  if (isValidIntervals) {
                    setCurrentStep(2);
                  } else {
                    setIsValidationShown(true);
                  }
                }}
                className='button__inversed button__full-width mt-0 mt-sm-3 order-1 order-sm-3 mb-3 mb-sm-0'
                data-testid='btn-apply-to-multiple-days'
              >
                <span className='d-sm-inline d-none'>or&nbsp;</span>Apply to
                multiple days
              </Button>
              {isValidationShown && !isValidIntervals && (
                <div className='ManageAvailabilityIntervalsModal__error-message'>
                  Some intervals are invalid. Please check
                </div>
              )}
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <ApplyToMultiple
            applyToDates={(dates) => applyToDates(getIntervals(), dates)}
            applyToDays={(days) => applyToWeekDays(getIntervals(), days)}
            day={weekDay}
            date={date}
            cancel={() => setCurrentStep(1)}
          />
        )}
      </div>
    </Modal>
  );
};

export default ManageAvailabilityIntervalsModal;
