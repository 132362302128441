import './CollapsibleRowList.style.scss';
import React, { useRef, useState, useEffect } from 'react';
import Button from '../Button/Button';
import ArrowIcon from '../../icons/arrow_down_sm.svg';

export interface iProps {
  list: JSX.Element[];
  className?: string;
  titleClassName?: string;
}

const CollapsibleRowList: React.FC<iProps> = ({
  list,
  className = '',
  titleClassName = ''
}) => {
  const [isListOpened, setIsListOpened] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const eventRef = useRef<HTMLDivElement>(null);

  const MAX_SHOWN_ITEMS = 3;

  const handleListOpen = () => {
    setIsListOpened(!isListOpened);
  };

  useEffect(() => {
    setMaxHeight(isListOpened ? eventRef.current.scrollHeight : 0);
  }, [list, isListOpened]);

  return (
    <>
      <div className={className}>
        <div className={titleClassName}>
          {list.length > 1 ? `Members` : 'Member'}
        </div>
        <div>{list.slice(0, MAX_SHOWN_ITEMS)}</div>
        <div
          ref={eventRef}
          style={{ maxHeight }}
          className='CollapsibleRowList__collapsible'
        >
          {list.slice(MAX_SHOWN_ITEMS)}
        </div>
      </div>
      {list.length > MAX_SHOWN_ITEMS && (
        <Button
          className='align-self-start mx-auto'
          variations={['naked']}
          type='button'
          handleClick={handleListOpen}
        >
          {isListOpened ? 'Less' : 'More'}
          <span
            className={`button__expand-arrow ml-2 ${
              isListOpened ? 'button__expand-arrow--expanded' : ''
            }`}
          >
            <ArrowIcon />
          </span>
        </Button>
      )}
    </>
  );
};

export default CollapsibleRowList;
