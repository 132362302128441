import React from 'react';
import LinkIcon from '../../../icons/link_large.svg';
import FileIcon from '../../../icons/file_white.svg';
import CameraIcon from '../../../icons/camera.svg';

export type ModalAction = 'uploadFile' | 'addLink' | 'postVideo';

export interface iNewType {
  title: string;
  description: string;
  icon: JSX.Element;
  action?: ModalAction;
  url?: string;
}

export const NewTypes: iNewType[] = [
  {
    title: 'Upload File',
    description: '<p>Upload a file to your library.</p>',
    icon: <FileIcon />,
    action: 'uploadFile'
  },
  {
    title: 'Add Link',
    description:
      'Add a link to an existing file. This is useful if you have content that already exists online you want to share easily.',
    icon: <LinkIcon />,
    action: 'addLink'
  },
  {
    title: 'Embed Video',
    description: 'Use an embed link from YouTube or Vimeo to share a video.',
    icon: <CameraIcon />,
    action: 'postVideo'
  }
];
