import { CoachClientScheduleItem as ItemModel } from '../../../core/types';
import { getFromMilliseconds } from '../../../core/helpers';
import { CoachContactsExtendedViewModel } from '../../../core/backend/api';
import { iCoachInfoBlockData } from './CoachInfoBlockModal';
import { EventStatus } from '../../../core/enums';

export const getCoachClientScheduleInfoBlockData = (
  item: ItemModel,
  timeZone: string,
  clientId: string,
  members: CoachContactsExtendedViewModel[],
  invites: string[]
): iCoachInfoBlockData => {
  return {
    id: item.id,
    name: item.name,
    type: item.itemType,
    userName: '',
    durationMinutes: getFromMilliseconds(item.duration, 'minutes'),
    membersCountLimitString:
      item.membersCountLimit === 1 ? 'One-on-One' : 'Group',
    price: item.price.toString(),
    location: item.location,
    date: item.timestamp,
    showCoachAvatar: false,
    timeZone: timeZone,
    imageId: item.imageId,
    remindBefore: 0,
    showSelectBefore: false,
    members: [
      ...members.filter((m) => m.clientId === clientId),
      ...members.filter((m) => m.clientId !== clientId)
    ],
    invites,
    isCanceled: item.status !== EventStatus.Active
  };
};
