import React from 'react';
import './PaymentResultModal.styles.scss';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import ErrorIcon from '../../../icons/error.svg';
import SucceededIcon from '../../../icons/succeed.svg';

export interface iProps {
  isOpened: boolean;
  onSubmit: () => void;
  onClose: () => void;
  className?: string;
  type: 'succeeded' | 'error';
  btnText?: string;
}

const PaymentResultModal: React.FC<iProps> = ({
  isOpened,
  onSubmit,
  onClose,
  className = '',
  type,
  btnText = 'Close'
}) => {
  return (
    <Modal
      className='PaymentResultModal'
      isOpened={isOpened}
      close={onClose}
      style={{ width: 518 }}
    >
      <div className='mb-3'>
        {type === 'succeeded' ? <SucceededIcon /> : <ErrorIcon />}
      </div>
      <div className='PaymentResultModal__title mb-2'>
        {type === 'succeeded' ? 'Processing transaction' : 'Payment error'}
      </div>
      <div className='PaymentResultModal__body mb-5'>
        {type === 'succeeded'
          ? 'We need a bit of time to process the transaction and will update its status when done.'
          : 'Your payment has not been successfully accepted.'}
      </div>
      <div className='PaymentResultModal__footer'>
        <Button
          handleClick={onSubmit}
          variations={['full-width']}
          type='button'
        >
          {btnText}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentResultModal;
