import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import WarningIcon from '../../icons/warning.svg';
import ArrowIcon from '../../icons/arrow_polygon_sm_down.svg';
import './SideMenuList.styles.scss';
import AccordionButton from '../AccordionButton/AccordionButton';
interface MenuItem {
  name: string;
  iconName?: string;
  path?: string;
  hasWarnings?: boolean;
}

export interface ParentMenuItem extends MenuItem {
  childMenuItems?: MenuItem[];
}

export interface IconsProps {
  [index: string]: any;
}

interface Props {
  menuList: ParentMenuItem[];
  icons: IconsProps;
  isTablet: boolean;
}

const SideMenuList: React.FC<Props> = ({ menuList, icons, isTablet }) => {
  const history = useHistory();

  const warning = (
    <span className='SideMenu__item-warning'>
      <WarningIcon />
    </span>
  );

  const rednerLink = (
    item: MenuItem | ParentMenuItem,
    index: number,
    isLink: boolean
  ) => {
    return (
      <NavLink
        key={index}
        to={item.path}
        className={`SideMenuList__item SideMenuList__item--${
          isLink ? 'link' : 'child'
        }`}
      >
        <div className='SideMenuList__item-icon'>{icons[item.iconName]}</div>
        <span className='SideMenuList__item-text'>{item.name}</span>
        {item.hasWarnings && warning}
      </NavLink>
    );
  };

  return (
    <>
      {menuList.map((menuItem, index) => {
        const isActiveChild = menuItem.childMenuItems?.some((m) =>
          history.location.pathname.startsWith(m.path)
        );
        const isLink = !menuItem.childMenuItems && !!menuItem.path;

        if (isLink) {
          return rednerLink(menuItem, index, isLink);
        }

        return (
          <AccordionButton
            isExpanded={isActiveChild}
            key={index}
            className='SideMenuList__accordion'
            customArrowIcon={<ArrowIcon />}
            nodeContent={menuItem.childMenuItems.map((m, i) =>
              rednerLink(m, i, isLink)
            )}
            onClick={(handleClickAccordion, willBeExpanded) => {
              if (!isTablet && willBeExpanded) {
                history.push(menuItem.childMenuItems[0].path);
              }
              handleClickAccordion();
            }}
          >
            <div className='SideMenuList__item px-0'>
              <div className='SideMenuList__item-icon'>
                {icons[menuItem.iconName]}
              </div>
              <span className='SideMenuList__item-text'>{menuItem.name}</span>
              {menuItem.hasWarnings && warning}
            </div>
          </AccordionButton>
        );
      })}
    </>
  );
};

export default SideMenuList;
