import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import './CoachDashboard.styles.scss';
import CoachDashboardTile from '../../components/CoachDashboardTile/CoachDashboardTile';
import PencilIcon from '../../icons/pencil.svg';
import {
  ClientEventStatusEnum,
  CoachRoutesEnum,
  NotificationTypesEnum,
  ScheduleItemType,
  StripeRefundStatusEnum
} from '../../core/enums';
import {
  cancelAppointment,
  cancelEvent,
  getAppointment,
  getAppointmentBlock,
  getCalendarData,
  getCoachSteps,
  getEvent
} from '../../core/api';
import { getFromMilliseconds, getTimeInZone } from '../../core/helpers';
import {
  useCancelConfirmationModal,
  useDebouncedCallback,
  useMedia,
  useStores
} from '../../hooks';
import moment from 'moment';
import { Appointment, iListViewItem, AppointmentBlock } from '../../core/types';
import { EventStatus } from '../../core/enums';
import { CoachContactsExtendedViewModel } from '../../core/backend/api';
import {
  getCalendarListViewItemDetails,
  PALETTE
} from '../CoachCalendar/CoachCalendar.helpers';
import CoachDashboardScheduleItem from '../../components/CoachDashboardScheduleItem/CoachDashboardScheduleItem';

import { getDefaultData as getEmptyAppointment } from '../../components/Modals/AppointmentModal/AppointmentModal.helpers';
import { getDefaultData as getEmptyAppointmentBlock } from '../../pages/CoachAppointmentBlock/CoachAppointmentBlock.helpers';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import RescheduleAppointment from '../../components/Modals/RescheduleAppointment/RescheduleAppointment';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { getQuickStartSetup } from './CoachDashboard.helpers';

import EmptyState from '../../components/EmptyState/EmptyState';
import { Scrollbars } from 'react-custom-scrollbars';
import QuickStartSetup, {
  iQuickStartSetupItem
} from '../../components/QuickStartSetup/QuickStartSetup';
import AlphaHub from './AlphaHub';
import CoachDashboardSmallTile from './CoachDashboardSmallTile';
import Button from '../../components/Button/Button';
import ViewIcon from '../../icons/view-icon.svg';
import LinkIcon from '../../icons/link_sharp.svg';
import { copyToClipboard } from '../../core/helpers';
import getGlobal from '../../core/globals';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import CoachInfoBlockModal from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import CoachInfoBlockModalActions from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModalActions';
import { iCoachInfoBlockData } from '../../components/Modals/CoachInfoBlockModal/CoachInfoBlockModal';
import Warning from '../../components/Warning/Warning';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useLazyLoad } from '../../hooks/useLazyLoad';
import PageHeaderContent from '../../components/PageHeaderContent/PageHeaderContent';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';
import { STRIPE_CONNECTED_KEY } from '../../core/integrations';

interface ViewItem {
  id: number;
  name: string;
  description: string;
  itemType: ScheduleItemType;
  price: number;
  timestamp: number;
  duration: number;
  location: string;
  status: EventStatus;
  membersCountLimit: number;
  imageId: string;
  members: CoachContactsExtendedViewModel[];
  invites: string[];
}

const CoachDashboard = () => {
  const {
    rootStore: {
      userStore,
      contactsStore: { contacts },
      notificationStore
    }
  } = useStores();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const eventId = +params.get('eventId');
  const appointmentId = +params.get('appointmentId');
  const isAfterStripeConnected = !!params.get(STRIPE_CONNECTED_KEY);
  const scrollRef = useRef<Scrollbars>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [menuItems, setMenuItems] = useState<iQuickStartSetupItem[]>(
    getQuickStartSetup(null, userStore.id)
  );
  const [items, setItems] = useState<iListViewItem[]>([]);
  const [isViewItemOpen, setIsViewItemOpen] = useState<boolean>(false);
  const [viewItem, setViewItem] = useState<ViewItem>(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );

  const containerRef = useRef<HTMLDivElement>();
  const isMobile = useMedia(['(min-width: 576px)'], [false], true);

  const {
    confirmModalData,
    setConfirmModalData
  } = useCancelConfirmationModal();

  const currentTimeStamp = useMemo(
    () =>
      moment.utc(getTimeInZone(Date.now(), userStore.timeZone || '')).valueOf(),
    [userStore.timeZone]
  );

  const loadData = useCallback(
    (pageNumber: number, pageSize: number) => {
      const start = currentTimeStamp;
      setIsLoading(true);

      return getCalendarData(
        start,
        null,
        pageNumber,
        pageSize,
        EventStatus.Active
      ).finally(() => {
        setIsLoading(false);
      });
    },
    [currentTimeStamp]
  );

  const { data, reLoad } = useLazyLoad(
    (scrollRef?.current as Scrollbars & { view: HTMLElement })?.view,
    loadData,
    10
  );

  const closeConfirmationModal = () => setIsConfirmModalOpened(false);

  const [
    isRescheduleAppointmentOpen,
    setIsRescheduleAppointmentOpen
  ] = useState<boolean>(false);
  const [rescheduleAppointmentData, setRescheduleAppointmentData] = useState<{
    data: Appointment;
    appointmentBlockData: AppointmentBlock;
  }>({
    data: getEmptyAppointment(),
    appointmentBlockData: getEmptyAppointmentBlock()
  });

  const setCancellingDone = useDebouncedCallback(() => {
    setIsCancelling(false);
  }, 450);

  const handleCalendarItemCancel = (item: ViewItem) => {
    const activeMembers = item.members.filter(
      (m) => m.clientStatus !== 'Canceled'
    );

    setConfirmModalData(
      item.itemType,
      item.price === 0,
      activeMembers?.length,
      'allClients',
      activeMembers?.every(
        (m) => m.refundStatus === StripeRefundStatusEnum.Succeeded
      )
    );
    setIsConfirmModalOpened(true);
  };

  const cancelItem = (item: ViewItem, withRefund: boolean = false) => {
    const cancel: (id: number, withRefund: boolean) => Promise<unknown> =
      item.itemType !== ScheduleItemType.Appointment
        ? cancelEvent
        : cancelAppointment;

    if (!cancel) return;
    setIsCancelling(true);
    cancel(item.id, withRefund)
      .then(() => {
        closeConfirmationModal();
        setIsViewItemOpen(false);
        reLoad();
      })
      .catch((err) => {
        if (err.status === 400 && err.detail === 'RefundFailed') {
          setIsRefundPromptModalOpened(true);
          closeConfirmationModal();
          setIsViewItemOpen(false);
          reLoad();
        } else {
          notificationStore.addNotification({
            text: 'Something went wrong.',
            textColored: 'Try one more time,\n please.',
            duration: 3000,
            type: NotificationTypesEnum.Error
          });
        }
      })
      .finally(() => {
        setCancellingDone();
      });
  };

  const handleAcceptConfirm = () => {
    cancelItem(viewItem, true);
  };

  const handleRejectConfirm = () => {
    if (viewItem.itemType !== ScheduleItemType.Appointment) {
      const activeMembers = viewItem.members.filter(
        (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
      );

      const hasMembersForRefund = activeMembers.some(
        (m) => m.refundStatus !== StripeRefundStatusEnum.Succeeded
      );

      if (
        viewItem.price === 0 ||
        activeMembers.length === 0 ||
        viewItem.itemType === ScheduleItemType.OneToOne ||
        !hasMembersForRefund
      ) {
        return closeConfirmationModal();
      }

      cancelItem(viewItem, false);
    } else {
      closeConfirmationModal();
    }
  };

  useEffect(() => {
    if (isAfterStripeConnected) {
      history.replace(pathname);
    }
    // eslint-disable-next-line
  }, [isAfterStripeConnected]);

  useEffect(() => {
    getCoachSteps().then((response) => {
      setMenuItems(getQuickStartSetup(response, userStore.id));
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (contacts.length) {
      const result: iListViewItem[] = [
        ...data.items.map((item) => {
          const isCanceled = (item.status as number) !== EventStatus.Active;
          const styles = isCanceled
            ? PALETTE.canceled
            : (item.type as number) === ScheduleItemType.OneToOne
            ? PALETTE.scheduled
            : (item.type as number) === ScheduleItemType.Event
            ? PALETTE.meeting
            : PALETTE.appointment;

          return {
            itemType: (item.type as number) as ScheduleItemType,
            id: item.id,
            date: item.timestamp,
            details: getCalendarListViewItemDetails(item, contacts),
            isCanceled,
            ...styles
          };
        })
      ];
      setItems(result);
    }
  }, [contacts, data]);

  useEffect(() => {
    if (eventId) {
      getEvent(eventId).then((data) => {
        setViewItem({
          id: data.id,
          name: data.name,
          description: data.description,
          price: +data.price,
          location: data.location,
          timestamp: data.startDateTimeStamp + data.startTimeMs,
          duration:
            data.endDateTimeStamp +
            data.endTimeMs -
            data.startDateTimeStamp -
            data.startTimeMs,
          status: data.coachEventStatus as number,
          imageId: data.thumbnailImageId,
          membersCountLimit: data.availableSpots,
          itemType: data.isScheduled
            ? ScheduleItemType.OneToOne
            : ScheduleItemType.Event,
          members: data.members,
          invites: data.invites
        });
        setIsViewItemOpen(true);
      });
      history.replace(pathname);
    }
    if (appointmentId) {
      getAppointment(appointmentId).then((appointment) => {
        getAppointmentBlock(appointment.appointmentTypeId).then(
          (appointmentBlock) => {
            setViewItem({
              id: appointment.id,
              name: appointmentBlock.name,
              description: appointmentBlock.description,
              price: +appointment.price,
              location: appointment.location,
              timestamp: appointment.timestampUtc + appointment.timeMs,
              duration: appointment.duration,
              status: appointment.appointmentStatus as number,
              imageId: appointmentBlock.thumbnailImageId,
              membersCountLimit: appointmentBlock.membersCountLimit,
              itemType: ScheduleItemType.Appointment,
              members: appointment.members,
              invites: []
            });
            setIsViewItemOpen(true);
          }
        );
      });
      history.replace(pathname);
    }
  }, [eventId, appointmentId]);

  const handleView = (itemId: number, itemType: ScheduleItemType) => {
    const {
      id,
      name,
      description,
      price,
      timestamp,
      durationMs,
      location,
      status,
      availableSpots,
      thumbnailImageId,
      members,
      invites
    } = data.items.find(
      (i) => i.id === itemId && (i.type as number) === itemType
    );
    setViewItem({
      id,
      name,
      description,
      price,
      location,
      timestamp,
      duration: durationMs,
      status: status as number,
      imageId: thumbnailImageId,
      membersCountLimit: availableSpots,
      itemType: itemType,
      members,
      invites: itemType === ScheduleItemType.Appointment ? [] : invites
    });
    setIsViewItemOpen(true);
  };

  const handleEdit = (item: ViewItem) => {
    if (item.itemType !== ScheduleItemType.Appointment) {
      if (item.itemType === ScheduleItemType.OneToOne) {
        history.push(
          CoachRoutesEnum.ScheduleMeetingDetails.replace(
            ':meetingId',
            item.id.toString()
          )
        );
      } else {
        history.push(
          CoachRoutesEnum.MeetingDetails.replace(
            ':meetingId',
            item.id.toString()
          )
        );
      }
    } else {
      getAppointment(item.id).then((appointment) => {
        getAppointmentBlock(appointment.appointmentTypeId).then(
          (appointmentBlock) => {
            setRescheduleAppointmentData({
              data: appointment,
              appointmentBlockData: appointmentBlock
            });
            setIsRescheduleAppointmentOpen(true);
            setIsViewItemOpen(false);
          }
        );
      });
    }
  };

  const renderViewModal = () => {
    const data: iCoachInfoBlockData = viewItem
      ? {
          id: viewItem.id,
          type: viewItem.itemType,
          name: viewItem.name,
          userName: '',
          durationMinutes: getFromMilliseconds(viewItem.duration, 'minutes'),
          membersCountLimitString:
            viewItem.membersCountLimit === 1 ? 'One-on-One' : 'Group',
          price: viewItem.price.toString(),
          location: viewItem.location,
          date: viewItem.timestamp,
          showCoachAvatar: false,
          timeZone: userStore.timeZone,
          imageId: viewItem.imageId,
          remindBefore: 0,
          showSelectBefore: false,
          isCanceled: viewItem.status !== EventStatus.Active,
          members: viewItem.members,
          invites: viewItem.invites
        }
      : null;

    return (
      <CoachInfoBlockModal
        isOpened={isViewItemOpen}
        close={() => setIsViewItemOpen(false)}
        data={data}
        actions={
          <CoachInfoBlockModalActions
            data={data}
            onCancel={() => handleCalendarItemCancel(viewItem)}
            onEdit={() => handleEdit(viewItem)}
          />
        }
      />
    );
  };

  const handleCopyLink = () => {
    copyToClipboard(`${getGlobal('domain')}/${userStore.siteName}`);
    notificationStore.addNotification({
      text: 'Link to Your Coach Page',
      textColored: 'was copied to your clipboard'
    });
  };

  const getUpcomingOrder = () => {
    return !items.length && !isLoading ? 1 : 0;
  };

  return (
    <>
      <PageTitle title='Dashboard' />
      <PageHeaderContent>
        <Button
          variations={['xs-width', 'floating-button']}
          link={CoachRoutesEnum.New}
          component='Link'
        >
          <span className='button__floating-button-plus'>+</span>Schedule
        </Button>
      </PageHeaderContent>
      <GlobalLoader isLoading={isLoading} />
      {userStore.isTimeZoneDiffers && (
        <Warning
          className='mb-3'
          onClose={userStore.setTimezoneLastCheckTimestamp}
        >
          We've noticed that your local timezone differs from the timezone set
          in your account. Please, check your{' '}
          <NavLink className='link' to={CoachRoutesEnum.Account}>
            account settings
          </NavLink>
          .
        </Warning>
      )}
      {userStore.isGoogleWasDisconnected && (
        <Warning className='mb-3' onClose={userStore.resetGoogleConnection}>
          Your Google Calendar was automatically disconnected. This may happen
          if you changed the credentials to your account or you didn't use it
          for a while. You can connect Google Calendar back from{' '}
          <NavLink className='link' to={CoachRoutesEnum.Account}>
            your account
          </NavLink>
          .
        </Warning>
      )}
      {userStore.wasStripeConnected && (
        <Warning className='mb-3'>
          Stripe account isn't connected. You won't be able to create invoices,
          paid meetings and appointments. All your existing invoices, paid
          meetings and appointments are hidden.{' '}
          <NavLink className='link' to={CoachRoutesEnum.ConnectStripe}>
            Connect Stripe
          </NavLink>{' '}
          to get them back.
        </Warning>
      )}
      <div className='CoachDashboard' ref={containerRef}>
        <QuickStartSetup
          isMobile={isMobile}
          menuList={menuItems}
          parent={containerRef.current}
        />
        <div className='row px-2 px-sm-0'>
          <div
            className={`col-md-6 pr-md-2 pt-3 pt-md-0 order-${getUpcomingOrder()} order-md-0`}
          >
            <CoachDashboardTile title='Upcoming Schedule'>
              <div className='CoachDashboard__upcoming pl-3 pr-2 pb-2 d-flex flex-column'>
                <Scrollbars
                  style={{ height: 'auto' }}
                  className='flex-grow-1'
                  ref={scrollRef}
                >
                  {items.map((item) => (
                    <CoachDashboardScheduleItem
                      key={`${item.itemType}-${item.id}`}
                      item={item}
                      onView={handleView}
                      className='mr-2'
                    />
                  ))}
                  {!items.length && !isLoading && (
                    <EmptyState size='md' className='mt-5 pt-3' />
                  )}
                </Scrollbars>
              </div>
            </CoachDashboardTile>
          </div>
          <div className='col-md-6 pl-md-2 d-flex flex-column '>
            <div className={`order-2 order-md-1 pt-3 pt-md-0`}>
              <CoachDashboardSmallTile
                title='Your Coach Page'
                hint={`Your coach page is the page your customers see when they want to schedule an appointment or see your upcoming availability. Use this space to showcase your brand and personality by adding your colors, logo, and bio.`}
              >
                <>
                  <a
                    className='button button__naked button--link'
                    href={`${getGlobal('domain')}/${userStore.siteName}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div className='CoachDashboard__icon CoachDashboard__icon--blue'>
                      <ViewIcon />
                    </div>
                    <div className='d-none d-sm-block ml-sm-2'>View</div>
                  </a>
                  <Button handleClick={handleCopyLink} variations={['naked']}>
                    <div className='CoachDashboard__icon CoachDashboard__icon--blue'>
                      <LinkIcon />
                    </div>
                    <div className='d-none d-sm-block ml-sm-2'>Copy link</div>
                  </Button>
                  <Button
                    component='Link'
                    url={CoachRoutesEnum.CoachPageEditor + '?d=true'}
                    variations={['naked']}
                  >
                    <div className='CoachDashboard__icon CoachDashboard__icon--blue'>
                      <PencilIcon />
                    </div>
                    <div className='d-none d-sm-block ml-sm-2'>Edit</div>
                  </Button>
                </>
              </CoachDashboardSmallTile>
            </div>
            <div className={`order-3 pt-3 order-md-2`}>
              <CoachDashboardSmallTile
                title='Your Client Portal'
                hint={`The client portal is the private login area of your clients. It’s their personal space where they see upcoming appointments, and schedule new ones. They’ll also be able to access their personal library and see content that you share with them.`}
              >
                <Button
                  component='Link'
                  url={CoachRoutesEnum.ClientPageEditor + '?d=true'}
                  variations={['naked']}
                >
                  <div className='CoachDashboard__icon CoachDashboard__icon--blue'>
                    <PencilIcon />
                  </div>
                  <div className='d-none d-sm-block ml-sm-2'>Edit</div>
                </Button>
              </CoachDashboardSmallTile>
            </div>
            <div
              className={`order-1 order-md-3 pt-3 flex-grow-1 d-sm-flex flex-column`}
            >
              <AlphaHub
                date=''
                title='Alpha Hub'
                contentTitle='Your CoachPro All-Access Pass'
                text={`Access your Alpha users-only portal where you’ll find insider updates on CoachPro, view our Product Roadmap, information on upcoming Zoom meetings, and much more.`}
              />
            </div>
          </div>
        </div>
      </div>
      {renderViewModal()}
      <RescheduleAppointment
        isOpened={isRescheduleAppointmentOpen}
        close={() => setIsRescheduleAppointmentOpen(false)}
        rescheduled={reLoad}
        {...rescheduleAppointmentData}
      />
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={closeConfirmationModal}
        title={confirmModalData.title}
        text={confirmModalData.text}
        confirmBtnText={confirmModalData.confirmBtnText}
        cancelBtnText={confirmModalData.cancelBtnText}
        disableConfirmBtn={isCancelling}
        disableCancelBtn={isCancelling}
        confirmCallback={handleAcceptConfirm}
        cancelCallback={handleRejectConfirm}
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            Some payments were made using your previous Stripe account.
            <br /> We are unable to process refunds for these payments
            automatically.
            <br /> You can still make a refund directly from the Stripe
            dashboard.
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
    </>
  );
};

export default observer(CoachDashboard);
