import {
  getLinkType,
  parseLink
} from '../CoachLibraryAll/CoachLibraryAll.helpers';
import { searchBy } from '../../core/helpers';
import { FileResponseViewModel } from '../../core/backend/api';

export const filterForSearch = (
  debouncedSearchString: string,
  file: FileResponseViewModel
) => {
  let link = '';

  if (file.fileLink) {
    const isVideoLink = getLinkType(file.fileLink) === 'video';
    link = isVideoLink ? parseLink(file.fileLink) : file.fileLink;
  }

  return (
    (searchBy(file.name, debouncedSearchString) ||
      searchBy(link, debouncedSearchString)) &&
    file.clientIds.length > 0
  );
};
