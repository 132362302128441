import React from 'react';
import Modal from '../../Modal/Modal';
import { FileResponseViewModelWithExt } from '../../../core/types';
import './VideoModal.styles.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';

interface Props {
  isOpened: boolean;
  close: () => void;
  file: FileResponseViewModelWithExt;
}

const VideoModal: React.FC<Props> = ({ close, isOpened, file }) => {
  const { siteName } = useParams<{ siteName: string }>();

  if (!file) return null;
  return (
    <Modal
      close={close}
      isOpened={isOpened}
      className='VideoModal'
      style={{
        width: 952,
        height: '100%',
        maxHeight: '685px'
      }}
    >
      <div className='VideoModal__inner'>
        <div className='VideoModal__title'>{file.name}</div>
        {file.sharedOn && (
          <div className='VideoModal__shared'>
            Shared {siteName && 'with you'}:
            {moment.utc(file.sharedOn).format('ll')}
          </div>
        )}
        <div className='VideoModal__desc'>{file.description}</div>
        <div className='embed-responsive flex-grow-1 mb-4'>
          <iframe
            className='embed-responsive-item'
            src={file.fileLink}
            allowFullScreen
            title={file.name}
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
