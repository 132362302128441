import { iFormConfigItem } from '../../core/types';
import {
  getInputErrors,
  isCardValid,
  isCvcValid,
  isDataValid,
  isExpiryValid,
  isInputValid,
  isNotEmpty,
  isZipCode,
  isZipLongEnough
} from '../../core/formValidation';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';

const FormConfig: iFormConfigItem[] = [
  { field: 'cardNumber', checkIsValid: [isCardValid] },
  { field: 'expiry', checkIsValid: [isExpiryValid] },
  { field: 'cvc', checkIsValid: [isCvcValid] },
  {
    field: 'postalCode',
    checkIsValid: [isNotEmpty, isZipLongEnough]
  }
];

export function isTheInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getTheInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isTheDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}

export function getDefaultPaymentData(): iPaymentData {
  return {
    cardNumber: {
      brand: 'unknown',
      complete: false,
      elementType: 'cardNumber',
      empty: true,
      error: undefined
    },
    expiry: {
      complete: false,
      elementType: 'cardExpiry',
      empty: true,
      error: undefined
    },
    cvc: {
      complete: false,
      elementType: 'cardCvc',
      empty: true,
      error: undefined
    },
    postalCode: ''
  };
}

export enum FieldsIds {
  card = 'card',
  cvc = 'cvc',
  expiry = 'expiry',
  postalCode = 'postal-code'
}

export interface PaymentInfo {
  price: number;
  paymentDescription: string;
}
export interface iPaymentData {
  cardNumber: StripeCardNumberElementChangeEvent;
  expiry: StripeCardExpiryElementChangeEvent;
  cvc: StripeCardCvcElementChangeEvent;
  postalCode: string;
}

export const disableTabIndex = (element: Element) => {
  element?.setAttribute('tabindex', '-1');
};

export const customStylesForInput = {
  style: {
    base: {
      color: '#50565b',
      fontFamily: 'Rubik, sans-serif',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '400',
      '::placeholder': {
        fontWeight: '400',
        color: '#9fa5ad'
      }
    },
    invalid: {
      fontWeight: '400',
      color: '#eb5757'
    },
    focus: {}
  }
};
