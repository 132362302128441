import React, { useReducer, useState } from 'react';
import './CoachSignIn.styles.scss';
import TilePage from '../../components/TilePage/TilePage';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import {
  getDefaultData,
  isSignInDataValid,
  isSignInInputValid,
  reducer
} from './CoachSignIn.helpers';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { CoachRoutesEnum } from '../../core/enums';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { useStores } from '../../hooks';

const CoachSignIn = () => {
  const {
    rootStore: { userStore }
  } = useStores();

  const [data, setData] = useReducer(reducer, getDefaultData());
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChangeField = (field: string) => (value: any) => {
    setIsLoginFailed(false);
    setData({
      ...data,
      [field]: field === 'email' ? value.trim() : value
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isSignInDataValid(data)) {
      setIsLoading(true);
      userStore
        .signIn({ email: data.email.trim(), password: data.password.trim() })
        .catch((err: any) => {
          setIsLoginFailed(true);
          setIsLoading(false);
          setError(err.message);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <TilePage
      className='LogIn'
      title='Log In'
      tileStyle={{ maxWidth: 440, width: '100%' }}
    >
      <ModalHeader>
        <h2>Sign In</h2>
      </ModalHeader>
      <GlobalLoader isLoading={isLoading} />
      <form onSubmit={handleSubmit}>
        <FormInput
          name='login'
          label='Email'
          value={data.email}
          isInvalid={isValidationShown && !isSignInInputValid('email', data)}
          errorMessage='Please input correct email'
          handleChange={handleChangeField('email')}
          data-testid='email-input'
        />
        <FormInput
          name='password'
          type='password'
          label='Password'
          value={data.password}
          isInvalid={isValidationShown && !isSignInInputValid('password', data)}
          errorMessage='Please fill'
          handleChange={handleChangeField('password')}
          data-testid='password-input'
        />
        <ErrorMessage
          data-testid='login-error-message'
          isVisible={isLoginFailed}
        >
          {error}
        </ErrorMessage>
        <div className='LogIn__row'>
          {/*<FormCheckbox*/}
          {/*    id="rememberMe"*/}
          {/*    checked={!isAvailable}*/}
          {/*    handleChange={() => setIsAvailable(!isAvailable)}*/}
          {/*    label="Remember me"*/}
          {/*    isSquare*/}
          {/*/>*/}
          <Link
            data-testid='forgot-password'
            to={CoachRoutesEnum.ForgotPassword}
            className='LogIn__link'
          >
            Forgot password
          </Link>
        </div>
        <Button data-testid='sign-in-btn' className='button__full-width'>
          Sign In
        </Button>
      </form>
      {/* <div className='LogIn__footer-text'>
        Don’t have an account? &nbsp;
        <Link to={`${CoachRoutesEnum.SignUp}`} className='LogIn__link'>
          Sign Up
        </Link>
      </div> */}
    </TilePage>
  );
};

export default observer(CoachSignIn);
