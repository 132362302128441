import React from 'react';
import BucketIcon from '../../icons/delete.svg';
import LinkIcon from '../../icons/link.svg';
import EnvelopeIcon from '../../icons/envelope.svg';
import cn from 'classnames';
import Tooltip from '../Tooltip/Tooltip';

const SocialNetworkLinkInput: React.FC<{
  value: string;
  handleChange: (arg0: string) => void;
  handleDelete: () => void;
  isSocialEmail?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
}> = ({
  value,
  handleChange,
  handleDelete,
  isSocialEmail = false,
  isInvalid,
  errorMessage
}) => {
  return (
    <div className='CustomizeSidebar__link-input'>
      <div className='d-flex align-items-center justify-content-between w-100'>
        <label htmlFor='' className='CustomizeSidebar__link-label'>
          {isSocialEmail ? 'Email address' : 'Social network link'}
        </label>
        <Tooltip
          minWidth={62}
          trigger={
            <div
              className='CustomizeSidebar__link-delete'
              onClick={handleDelete}
            >
              <BucketIcon />
            </div>
          }
          content={<>Remove</>}
          triggerType='hover'
        />
      </div>
      <div className='CustomizeSidebar__link-input-outer'>
        <div className='CustomizeSidebar__link-icon'>
          {isSocialEmail ? <EnvelopeIcon /> : <LinkIcon />}
        </div>
        <input
          type='text'
          className={cn('CustomizeSidebar__link-input-input', {
            'CustomizeSidebar__link-input-input--error': isInvalid
          })}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(event.target.value)
          }
          placeholder={isSocialEmail ? 'Type email' : 'Type new link here...'}
        />
      </div>
      {isInvalid && errorMessage && (
        <div
          className='CustomizeSidebar__link-input-error-message'
          title={errorMessage}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default SocialNetworkLinkInput;
