import React from 'react';
import { toggleInArray } from '../../core/helpers';
import CalendarView from './CalendarView';

export interface Props {
  value: number | number[];
  isMultiple?: boolean;
  onChange: (data: number[]) => void;
  shouldDisableDate?: (date: number) => boolean;
  shouldHighlightDate?: (date: number) => boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  onMonthChange?: (monthTimeStamp: number) => void;
  initialDate?: number;
}

const DatePicker: React.FC<Props> = ({
  value,
  onChange,
  isMultiple = false,
  disablePast = true,
  disableFuture = false,
  ...rest
}) => {
  if (isMultiple && !Array.isArray(value)) {
    throw new Error('In multiple mode value should be an array');
  }
  const toggleDate = (date: number) => {
    onChange(toggleInArray(value as number[], date));
  };

  const handleClick = (date: number) => {
    if (isMultiple) {
      toggleDate(date);
    } else {
      onChange([date]);
    }
  };

  return (
    <CalendarView
      mode='picker'
      value={value}
      disablePast={disablePast}
      disableFuture={disableFuture}
      onDayClick={handleClick}
      {...rest}
    />
  );
};

export default DatePicker;
