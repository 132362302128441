import { Area, Point } from 'react-easy-crop/types';

export interface CroppedArea {
  crop: Point;
  zoom: number;
  rotation: number;
}

export interface CroppedData {
  croppedAreaPixels: Partial<Area>;
  rotation: number;
  imgSrc: string;
}

export async function getCroppedImg(
  imgSrc: string,
  type: string,
  pixelCrop: Partial<Area>,
  rotation: number,
  maxWidth?: number
) {
  const image = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise<string>((resolve) => {
    image.onload = function () {
      const maxSize = Math.max(image.width, image.height);
      // const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2)); // for rotation other than 0 90 180 270
      const safeArea = maxSize;
      canvas.width = safeArea;
      canvas.height = safeArea;
      ctx.translate(safeArea / 2, safeArea / 2);
      ctx.rotate(getRadianAngle(rotation));
      ctx.translate(-safeArea / 2, -safeArea / 2);
      ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
      );
      const data = ctx.getImageData(0, 0, safeArea, safeArea);
      const cropWidth = pixelCrop.width || image.width;
      const cropHeight = pixelCrop.height || image.height;
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
      );

      if (maxWidth && cropWidth > maxWidth) {
        const width = maxWidth;
        const height = (maxWidth / cropWidth) * cropHeight;
        const resizeCanvas = document.createElement('canvas');
        const resizeCtx = resizeCanvas.getContext('2d');
        resizeCanvas.width = cropWidth;
        resizeCanvas.height = cropHeight;
        resizeCtx.drawImage(canvas, 0, 0, cropWidth, cropHeight);

        ctx.clearRect(0, 0, cropWidth, cropHeight);
        ctx.drawImage(
          resizeCanvas,
          0,
          0,
          cropWidth,
          cropHeight,
          0,
          0,
          width,
          height
        );
        const data = ctx.getImageData(0, 0, width, height);
        canvas.width = width;
        canvas.height = height;
        ctx.putImageData(data, 0, 0);
      }

      resolve(canvas.toDataURL(type));
    };
    image.src = imgSrc;
  });
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export const getCroppedFile = async (
  { croppedAreaPixels, rotation, imgSrc }: CroppedData,
  maxWidth?: number
) => {
  const isBase64 = imgSrc.indexOf('data:image/') === 0;
  let type = '';
  let ext = '';

  if (isBase64) {
    type = imgSrc.split(';')[0].slice(5);
    ext = type.split('/')[1];
  } else {
    const parts = imgSrc.split('.');
    ext = parts[parts.length - 1];
    if (ext === 'jpg') {
      ext = 'jpeg';
    }
    type = `image/${ext}`;
  }
  const data = await getCroppedImg(
    imgSrc,
    type,
    croppedAreaPixels,
    rotation,
    maxWidth
  );

  if (!data) {
    return;
  }

  const blobBin = atob(data.split(',')[1]);
  const array = [];

  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }

  return new File([new Uint8Array(array)], `cropped.${ext}`, {
    type
  });
};
