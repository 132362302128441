export interface IErrorProps {
  title: string;
  description: string;
}

export const errors: Record<number, IErrorProps> = {
  500: {
    title: 'Something wrong',
    description: 'Please return to the previous page and try again'
  },
  400: {
    title: 'Bad Request',
    description:
      'The server cannot process the request due to an apparent client error'
  },
  401: {
    title: 'Unauthorized',
    description: 'Sorry, your request could not be processed'
  },
  404: {
    title: 'Page not found',
    description:
      'The page you are working for might have been removed had its name changed or is temporarily unavailable'
  }
};
