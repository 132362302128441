import React from 'react';
import FormInput from '../../FormInput/FormInput';
import InvoiceCard from '../../InvoiceCard/InvoiceCard';
import Modal from '../../Modal/Modal';
import './SendInvoiceModal.styles.scss';
import { Invoice } from '../../../core/types';
import Button from '../../Button/Button';
import {
  CoachAccountViewModel,
  UserViewModel
} from '../../../core/backend/api';

interface Props {
  onClose: () => void;
  isOpened: boolean;
  coachAccountData: CoachAccountViewModel | Partial<UserViewModel>;
  invoice: Partial<Invoice>;
  onSend: () => void;
  isSending: boolean;
}

const SendInvoiceModal = ({
  onClose,
  isOpened,
  invoice,
  coachAccountData,
  onSend,
  isSending
}: Props) => {
  return (
    <Modal
      isOpened={isOpened}
      close={onClose}
      className='SendInvoiceModal'
      style={{ width: '600px' }}
    >
      {invoice && (
        <>
          <div className='SendInvoiceModal__header'>
            <div className='SendInvoiceModal__title'>Send invoice</div>
            <div className='SendInvoiceModal__separator my-4'></div>

            <div className='px-3 px-4'>
              <FormInput
                label='Send to'
                value={invoice.client?.email || ''}
                data-testid='email-input'
                disabled
              />
            </div>
          </div>
          <div className='SendInvoiceModal__content Scrollbar'>
            <InvoiceCard
              className='order-md-2 w-100 pb-5'
              link=''
              coachData={coachAccountData}
              invoiceData={invoice}
            />
          </div>
          <div className='SendInvoiceModal__footer py-4 d-flex align-items-center justify-content-end px-3 px-sm-4'>
            <Button
              className='mt-0'
              variations={['xs-width', 'inversed']}
              handleClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className='mt-0'
              variations={['xs-width']}
              handleClick={onSend}
              disabled={isSending}
            >
              Send
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default SendInvoiceModal;
