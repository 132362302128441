import { action, observable } from 'mobx';
import { NotificationTypesEnum } from '../core/enums';

interface iUnregisteredNotification {
  text: string | JSX.Element;
  textColored?: string | JSX.Element;
  duration?: number;
  type?: NotificationTypesEnum;
}

export interface iNotification extends iUnregisteredNotification {
  id: number;
}

const DEFAULT_DURATION = 3500;
const DEFAULT_TYPE = NotificationTypesEnum.Success;

export default class NotificationStore {
  @observable notifications: iNotification[] = [];

  @action addNotification = (notification: iUnregisteredNotification) => {
    const id = new Date().getTime();
    const duration = notification.duration || DEFAULT_DURATION;
    const type = notification.type || DEFAULT_TYPE;
    this.notifications = [...this.notifications, { ...notification, id, type }];
    setTimeout(() => {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== id
      );
    }, duration);
  };

  @action removeNotification = (id: number) => {
    this.notifications = this.notifications.filter(
      (notification) => notification.id !== id
    );
  };
}
