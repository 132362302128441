import { iListViewItem, CoachContactItem } from '../../core/types';
import { ScheduleItemViewModel } from '../../core/backend/api';
import { ClientEventStatusEnum, ScheduleItemType } from '../../core/enums';

export const PALETTE = {
  appointment: {
    cellCircleColor: '#009688',
    cellBgColor: '#009688',
    cellTextColor: '#FFFFFF'
  },
  meeting: {
    cellCircleColor: '#BB6BD9',
    cellBgColor: '#BB6BD9',
    cellTextColor: '#FFFFFF'
  },
  scheduled: {
    cellCircleColor: '#FAE20A',
    cellBgColor: '#FAE20A',
    cellTextColor: '#50565B'
  },
  canceled: {
    cellCircleColor: '#FFFFFF',
    cellBgColor: '#D5D9E0',
    cellTextColor: '#FFFFFF',
    cellBorderColor: '#D26B6B'
  }
};

export const getCalendarListViewItemDetails = (
  item: ScheduleItemViewModel,
  clients: CoachContactItem[] = []
): iListViewItem['details'] => {
  const filteredMembers = item.members!.filter(
    (m) => m.clientStatus !== ClientEventStatusEnum.Canceled
  );
  const base = {
    duration: item.durationMs,
    price: item.price,
    location: item.location
  };
  if ((item.type as number) === ScheduleItemType.Appointment) {
    if (item.availableSpots === 1 && item.members!.length === 1) {
      return {
        ...base,
        title: item.name,
        text: `${item.members![0].firstName} ${item.members![0].lastName}`,
        membersToAvailable: '',
        membersAmount: filteredMembers.length,
        members: item.members
      };
    } else {
      return {
        ...base,
        title: 'Appointment',
        text: item.name,
        membersToAvailable: `${filteredMembers.length}/${item.availableSpots} members`,
        membersAmount: filteredMembers.length,
        members: item.members
      };
    }
  } else if ((item.type as number) === ScheduleItemType.OneToOne) {
    const member =
      item.members[0] ||
      clients.find((c) => c.email.toLowerCase() === item.invites[0]);
    return {
      ...base,
      title: item.name,
      text: `${member?.firstName} ${member?.lastName}`,
      membersToAvailable: '',
      membersAmount: filteredMembers.length,
      members: item.members
    };
  } else {
    return {
      ...base,
      title: 'Meeting',
      text: item.name,
      membersToAvailable: `${filteredMembers.length}/${item.availableSpots} members`,
      membersAmount: filteredMembers.length,
      members: item.members
    };
  }
};

export interface iCalendarPopupData {
  title: string;
  date: number;
  duration: number;
  price: number;
  description: string;
  membersCountLimit: number;
  link: string;
  isCanceled: boolean;
  cellCircleColor: string;
  itemType: string;
  cancel: () => void;
  isPrivate: boolean;
  isScheduled?: boolean;
  invites: string[];
  acceptedInvites: string[];
}

export function getEmptyCalendarPopupData(): iCalendarPopupData {
  return {
    title: '',
    date: 0,
    duration: 0,
    price: 0,
    description: '',
    membersCountLimit: 0,
    link: '',
    isCanceled: false,
    cellCircleColor: '',
    itemType: '',
    cancel: () => null,
    isPrivate: false,
    invites: [],
    acceptedInvites: []
  };
}
