import React from 'react';
import './CoachInfoBlockModal.styles.scss';
import { iInfoBlockData } from '../../InfoBlock/InfoBlock';
import { CoachContactsExtendedViewModel } from '../../../core/backend/api';
import InfoBlockModal from '../InfoBlockModal/InfoBlockModal';
import ScheduleImageIcon from '../../../icons/schedule-icon.svg';
import { useStores } from '../../../hooks';
import MemberItem from '../../MemberItem/MemberItem';
import {
  ClientEventStatusEnum,
  CoachRoutesEnum,
  ScheduleItemType,
  StripeRefundStatusEnum
} from '../../../core/enums';
import CollapsibleRowList from '../../CollapsibleRowList/CollapsibleRowList';

export interface iCoachInfoBlockData extends iInfoBlockData {
  members: CoachContactsExtendedViewModel[];
  invites: string[];
}

interface iProps {
  isOpened: boolean;
  data: iCoachInfoBlockData;
  close: () => void;
  actions?: JSX.Element;
}

const CoachInfoBlockModal: React.FC<iProps> = ({
  isOpened,
  data,
  actions = null,
  close
}) => {
  const {
    rootStore: { contactsStore }
  } = useStores();

  if (!data) return null;

  const { invites, members, ...rest } = data;

  const invitesNotMembersYet = invites.filter(
    (email) =>
      !members.some((m) => m.email.toLowerCase() === email.toLowerCase())
  );

  const amount = members.length + invitesNotMembersYet.length;

  const getInvitesAndMembersList = [
    ...members
      .filter((m) => m.clientStatus !== ClientEventStatusEnum.Canceled)
      .map((m) => {
        return <MemberItem item={m} key={m.email} />;
      }),
    ...invitesNotMembersYet.map((email) => {
      const contact = contactsStore.getByEmail(email);
      const item = {
        ...(contact ? contact : { email })
      };
      return <MemberItem item={item} key={email} />;
    }),
    ...members
      .filter((m) => m.clientStatus === ClientEventStatusEnum.Canceled)
      .map((m) => {
        const link =
          m.refundStatus !== StripeRefundStatusEnum.Succeeded && +rest.price > 0
            ? `${CoachRoutesEnum.CoachClientTransactions.replace(
                ':clientId',
                m.clientId
              )}?id=${rest.id}&type=${rest.type}`
            : null;

        return <MemberItem item={m} link={link} key={m.email} />;
      })
  ];

  return (
    <InfoBlockModal
      className='CoachInfoBlockModal'
      data={rest}
      isOpened={isOpened}
      close={close}
      imageIcon={
        rest.type === ScheduleItemType.OneToOne ? <ScheduleImageIcon /> : null
      }
      actions={actions}
      footer={
        !!amount && (
          <CollapsibleRowList
            list={getInvitesAndMembersList}
            className='d-flex flex-column'
            titleClassName='InfoBlock__sub-title'
          />
        )
      }
    />
  );
};

export default CoachInfoBlockModal;
