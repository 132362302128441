import { iFormConfigItem, Checker } from './types';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import { isCoachRoute } from './enums';

const MINIMAL_PASSWORD_LENGTH = 8;
const MAXIMAL_SITENAME_LENGTH = 50;
const PHONE_NUMBER_LENGTH = 10;

export function formatValueAsPrice(value: string) {
  return value
    .replace(/[^\d.]/g, '')
    .replace(/^\./g, '')
    .replace(/^(\d*\.\d{0,2}).*/g, '$1')
    .replace(/^0([^.].*)/g, '$1');
}

export function formatValueAsInteger(value: string) {
  return value.replace(/[^\d.]/g, '').replace(/^0+/g, '');
}

export function isNotEmpty(str: string | number) {
  return !!str && `${str}`.trim() !== '';
}

export function isNotSpacesOnSides(str: string | number) {
  return `${str}`.trim() === `${str}`;
}

export function isNotEmptyArray(arr: any[]) {
  return arr.length > 0;
}

export function isPrice(value: string) {
  return +value > 0;
}

export function isMinValueValid(minValue: number) {
  return (value: number) => {
    return value >= minValue;
  };
}

export function isMaxValueValid(maxValue: number) {
  return (value: number) => {
    return value <= maxValue;
  };
}

export function isDurationValid(duration: number) {
  return duration % 5 === 0;
}

export function containsCapital(password: string) {
  return /[A-Z]/.test(password);
}

export function containsNonCapital(password: string) {
  return /[a-z]/.test(password);
}

export function containsDigit(password: string) {
  return /\d/.test(password);
}

export function containsSpecialCharacter(password: string) {
  return /[^a-zA-Z\d\s:]/.test(password);
}

export function isPasswordLongEnough(password: string) {
  return password.length >= MINIMAL_PASSWORD_LENGTH;
}

export function isSiteNameShortEnough(siteName: string) {
  return siteName.length <= MAXIMAL_SITENAME_LENGTH;
}

export function isSiteNameReserved(siteName: string) {
  return !siteName || !isCoachRoute(siteName);
}

export function isSiteNameSymbolsAllowed(siteName: string) {
  return !/[^-a-zA-Z0-9!*)(_+$']+/.test(siteName);
}

export function isUrl(str: string) {
  // eslint-disable-next-line
  return /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
    str.trim()
  );
}

export function isEmail(email: string) {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.trim());
}

export function isPhoneNumberLongEnough(number: string) {
  return number.length >= PHONE_NUMBER_LENGTH;
}

export function isZipCode(zip: string) {
  const regex = /^[A-Z0-9]+((-|\s)[A-Z0-9]+)?((-|\s)[A-Z0-9]+)?$/;
  return regex.test(zip.trim());
}

export function isZipLongEnough(zip: string) {
  return zip.trim().length >= 4;
}

export function isInputValid<T>(config: iFormConfigItem[]) {
  return function (inputField: string, data: T) {
    let isValid = true;
    const input = config.find((input) => input.field === inputField);

    if (input) {
      const path = inputField.split('.');
      const fieldValue = path.reduce(
        (acc, field) => (acc ? (acc as any)[field] : null),
        data
      );
      input.checkIsValid.forEach((checker) => {
        isValid = isValid && checker(fieldValue, data);
      });

      if (input.compareField) {
        const comparePath = input.compareField.split('.');
        const fieldCompareValue = comparePath.reduce(
          (acc, field) => (acc ? (acc as any)[field] : null),
          data
        );
        isValid = isValid && fieldValue === fieldCompareValue;
      }
    }

    return isValid;
  };
}

export function getInputErrors(
  config: iFormConfigItem[],
  getCustomCheckersErrorMessage?: (checker: Checker) => string | undefined
) {
  return function (inputField: string, data: any) {
    const errors: string[] = [];
    const input = config.find((input) => input.field === inputField);
    if (input) {
      const path = inputField.split('.');
      const fieldValue = path.reduce(
        (acc, field) => (acc ? (acc as any)[field] : null),
        data
      );
      input.checkIsValid.forEach((checker) => {
        if (!checker(fieldValue, data)) {
          const customCheckersErrorMessage = getCustomCheckersErrorMessage
            ? getCustomCheckersErrorMessage(checker)
            : undefined;

          errors.push(
            customCheckersErrorMessage ||
              getDefaultCheckersErrorMessage(checker) ||
              'Please check the field'
          );
        }
      });

      if (input.compareField) {
        const comparePath = input.compareField.split('.');
        const fieldCompareValue = comparePath.reduce(
          (acc, field) => (acc ? (acc as any)[field] : null),
          data
        );
        if (fieldValue !== fieldCompareValue) {
          errors.push('should be equal');
        }
      }
    }
    return errors;
  };
}

const getDefaultCheckersErrorMessage = (checker: Checker) => {
  let message: string | undefined = undefined;

  switch (checker) {
    case isNotEmpty: {
      message = 'should not be empty';
      break;
    }
    case isNotEmptyArray: {
      message = 'should not be empty';
      break;
    }
    case isEmail: {
      message = 'incorrect email';
      break;
    }
    case containsCapital: {
      message = 'should contain a capital character';
      break;
    }
    case containsNonCapital: {
      message = 'should contain a non-capital character';
      break;
    }
    case containsDigit: {
      message = 'should contain a digit';
      break;
    }
    case containsSpecialCharacter: {
      message = 'should contain a special character';
      break;
    }
    case isPasswordLongEnough: {
      message = `should have at least ${MINIMAL_PASSWORD_LENGTH} characters`;
      break;
    }
    case isPhoneNumberLongEnough: {
      message = `should have at least ${PHONE_NUMBER_LENGTH} digits`;
      break;
    }
    case isSiteNameSymbolsAllowed: {
      message = `Use only: -, a-z, A-Z, 0-9, !, *, ), (, _, +, $ or ' characters`;
      break;
    }
    case isSiteNameShortEnough: {
      message = `should have at most ${MAXIMAL_SITENAME_LENGTH} characters`;
      break;
    }
    case isSiteNameReserved: {
      message = `is used`;
      break;
    }
    case isZipLongEnough: {
      message = `Should have at least 4 symbols`;
      break;
    }
    case isZipCode: {
      message = `Use only: -, , A-Z, 0-9`;
      break;
    }
    case isNotSpacesOnSides: {
      message = `Please don't begin or end your password with blank space`;
      break;
    }
    default: {
      message = undefined;
    }
  }
  return message;
};

export function isDataValid(config: iFormConfigItem[]) {
  return function (data: any) {
    let isValid = true;
    config.forEach((conf) => {
      isValid = isValid && isInputValid(config)(conf.field, data);
    });

    return isValid;
  };
}

export function isCardValid(card: StripeCardNumberElementChangeEvent) {
  return card.complete && !card.error;
}

export function isExpiryValid(card: StripeCardExpiryElementChangeEvent) {
  return card.complete && !card.error;
}

export function isCvcValid(card: StripeCardCvcElementChangeEvent) {
  return card.complete && !card.error;
}
export const isMaxlengthValid = (maxLength: number) => (value: string) =>
  !value || value.length <= maxLength;
