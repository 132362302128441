import React from 'react';
import Thumbnail from '../../Thumbnail';
import './UploadedFileItem.styles.scss';

interface Props {
  ext: string;
  title: string;
  className?: string;
}

const UploadedFileItem: React.FC<Props> = ({ ext, title, className = '' }) => {
  return (
    <div className={`UploadedFileItem ${className}`}>
      <Thumbnail type={ext} />
      <div className='UploadedFileItem__title'>{title}</div>
    </div>
  );
};

export default UploadedFileItem;
