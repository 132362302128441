import React from 'react';

import { iCoachPageCustomizationData } from '../../core/types';
import { hexToHSLArray, isSimilarColors } from '../../core/helpers';

export const getDefaultData: () => iCoachPageCustomizationData = () => {
  return {
    position: '',
    bio: '',
    primaryColor: '#37A0CE',
    secondaryColor: '#E8F2F7',
    headlineColor: '#000000',
    paragraphColor: '#50565B',
    additionalColor: '#9FA5AD',
    backgroundColor: '#E5E5E5',
    socialLinks: [],
    logoSrc: ''
  };
};

interface iProps {
  coachData: iCoachPageCustomizationData;
}

export const CustomizationStyles: React.FC<iProps> = ({ coachData }) => {
  const primaryColorHSL: number[] = hexToHSLArray(coachData.primaryColor);
  const contrastedPrimaryColor = isSimilarColors(
    coachData.primaryColor,
    coachData.secondaryColor
  )
    ? '#fff'
    : coachData.primaryColor;
  const primaryColorLowLighter = `hsl(${primaryColorHSL[0]},${
    primaryColorHSL[1]
  }%,${Math.ceil(primaryColorHSL[2] * 1.2)}%)`;
  /*  const primaryColorLighter = `hsl(${hoverColorHSL[0]},${
    hoverColorHSL[1]
  }%,${Math.ceil(hoverColorHSL[2] * 1.45)}%)`;
  const primaryColorMoreLighter = `hsl(${hoverColorHSL[0]},${
    hoverColorHSL[1]
  }%,${Math.ceil(hoverColorHSL[2] * 1.65)}%)`; */
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
                .CZ__heading {
                    color: ${coachData.headlineColor};
                }

                .CZ__sub-heading {
                    color: ${coachData.additionalColor};
                }

                .CZ__text {
                    color: ${coachData.paragraphColor};
                }

                .CZ__text-prim {
                    color: ${coachData.primaryColor};
                }

                .CZ__icon {
                    color: ${contrastedPrimaryColor};
                    background-color: ${coachData.secondaryColor};
                }

                .CZ__icon-coach-page:hover {
                    color: ${coachData.primaryColor};
                }

                .CZ__button {
                    background-color: ${coachData.primaryColor};
                }

                .CZ__button-hover:hover {
                    background-color: ${primaryColorLowLighter};
                }

                .CZ__dropdown-button:hover {
                    background-color: ${coachData.secondaryColor};
                }

                .CZ__bg {
                    background-color: ${coachData.backgroundColor};
                }

                .CZ__border {
                    border-color: ${coachData.secondaryColor};
                }
            `
      }}
    />
  );
};

export const ClientCustomizationStyles: React.FC<iProps> = ({ coachData }) => {
  const primaryColorHSL: number[] = hexToHSLArray(coachData.primaryColor);
  const hoverColor = `hsl(${primaryColorHSL[0]},${primaryColorHSL[1]}%,${93}%)`;
  const contrastedPrimaryColor = isSimilarColors(
    coachData.primaryColor,
    coachData.secondaryColor
  )
    ? '#fff'
    : coachData.primaryColor;
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
                body {
                  background-color: ${coachData.backgroundColor};
                }
                .ClientPage {
                    background-color: ${coachData.backgroundColor};
                }

                .ItemTile__side-block:hover {
                    background-color: ${hoverColor};
                }

                .ClientPageHeader__back-button path {
                    stroke: ${coachData.primaryColor};
                }

                .ClientAccount__image-inner path,
                .InfoBlock__date-icon path,
                .ClientSideMenu__coach-image svg,
                .ClientScheduleTile__viewBtn svg,
                .ClientLibraryDownloads__download svg,
                .SideMenuList__item-icon svg {
                    fill: ${coachData.primaryColor};
                }

                .ClientSideMenu .SideMenuList__accordion .AccordionButton__arrow svg path{
                    fill: ${coachData.primaryColor};
                }

                .SideMenuList__item.active,
                .SideMenuList__item--active-bg,
                .CopyLinkInput__link-copy:hover,
                .ClientPageHeader__user-circle {
                    color: ${contrastedPrimaryColor};
                    background-color: ${coachData.secondaryColor};
                }

                .AvailableTime__text--selected{
                    background-color: ${coachData.primaryColor};
                    border-color: ${coachData.primaryColor}
                }

                .SideMenuList__item.active {
                    border-color: ${coachData.primaryColor}
                }

                .ClientDashboard__tiles-title,
                .ClientDashboard__view-all-button,
                .ClientSchedule__view-all-button,
                .CopyLinkInput__link-copy,
                .Calendar__day--available,
                .InfoBlock__date,
                .AvailableTime__date,
                .TabsMenu__tabs-item:hover,
                .SideMenuList__item,
                .ClientSideMenu__coach-name,
                .TabsMenu__tabs-item.active,
                .TilesCollapsibleGroup__view-all-button {
                    color: ${coachData.primaryColor};
                }

                .FormCheckbox__circle--checked,
                .ClientPageHeader__menu-line--one,
                .ClientPageHeader__menu-line--three {
                    background: ${coachData.primaryColor};
                }

                .FormInput__input:focus,
                .FormInput .react-tel-input .PhoneInputInput:focus {
                  border-color: ${coachData.secondaryColor} !important;
                }

                .PhoneInputInput:focus+.PhoneInputButton>.selected-flag {
                     border-color: ${coachData.secondaryColor} !important;
                }

                .TilesCollapsibleGroup__view-all-count {
                  color: ${contrastedPrimaryColor};
                  background: ${coachData.secondaryColor};
                }
                .ClientSchedule__view-all-count,
                .FormCheckbox__toggler--checked,
                .PaymentDetails__payment-summary,
                .Calendar__day--active .Calendar__day-text,
                .ClientAccount__image {
                    background: ${coachData.secondaryColor};
                }

                .button {
                    background: ${coachData.primaryColor};
                }

                .button__naked {
                  background: transparent;
                  color: ${coachData.primaryColor};
                }

                .button__inversed {
                    color: ${coachData.primaryColor};
                    background-color: ${hoverColor};
                }

            `
      }}
    />
  );
};

export const MAX_IMAGE_SIZE = 3 * 1024 * 1024;
export const MAX_FILE_TYPES = ['png', 'jpeg', 'svg'];

export interface CusomizeSidebarMenuItem {
  id: number;
  name: string;
  hint: string;
  icon: JSX.Element;
}
