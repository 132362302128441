import React, { ReactNode, useEffect, useState } from 'react';
import { getSharedClientFiles } from '../../core/api';
import { FileResponseViewModelWithExt } from '../../core/types';
import ClientLibraryDownloadsItem from './ClientLibraryDownloadsItem';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import ClientLibrary from '../../containers/ClientLibrary/ClientLibrary';
import './ClientLibraryDownloads.styles.scss';
import VideoModal from '../../components/Modals/VideoModal/VideoModal';
import { sortArrayByTimestampDesc } from '../../core/helpers';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyIcon from '../../icons/file_large.svg';
import { filterForSearch } from '../CoachLibraryAll/CoachLibraryAll.helpers';
import { FileResponseViewModel } from '../../core/backend/api';
import SearchInput from '../../components/SearchInput/SearchInput';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';

interface iProps {
  children: ReactNode;
  title: string;
}

const ClientLibraryDownloads: React.FC<iProps> = () => {
  const [isVideoModalOpened, setIsVideoModalOpened] = useState<boolean>(false);
  const [activeFile, setActiveFile] = useState<
    FileResponseViewModelWithExt | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileResponseViewModel[]>([]);
  const [expandedId, setExpandedId] = useState<string | number>(0);

  const {
    searchText,
    handleSearch,
    searchedItems: searchedFiles
  } = useSearchInput(files, filterForSearch);

  useEffect(() => {
    setIsLoading(true);
    getSharedClientFiles()
      .then((filesList) => {
        setFiles(filesList);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <ClientLibrary title='Downloads' className='ClientLibraryDownloads'>
      <GlobalLoader isLoading={isLoading} />
      <div className='d-flex justify-content-sm-end'>
        <SearchInput onSearch={handleSearch} searchText={searchText} />
      </div>
      {searchedFiles.length > 0 ? (
        <div className='row pt-sm-4 pt-3 d-flex justify-content-between mt-3 mx-md-n3 px-md-1'>
          {searchedFiles
            .sort(sortArrayByTimestampDesc('createdOn'))
            .map((file) => {
              return (
                <div className='col-md-6 px-md-3' key={file.id}>
                  <ClientLibraryDownloadsItem
                    file={file}
                    key={file.id}
                    setIsVideoModalOpened={setIsVideoModalOpened}
                    setActiveFile={setActiveFile}
                    expandedId={expandedId}
                    setExpandedId={setExpandedId}
                  />
                </div>
              );
            })}
        </div>
      ) : (
        <EmptyState
          text='There are no files here'
          icon={<EmptyIcon />}
          className='pt-5 pt-sm-0'
          absoluteCenter
        />
      )}
      <VideoModal
        isOpened={isVideoModalOpened}
        close={() => setIsVideoModalOpened(false)}
        file={activeFile}
      />
    </ClientLibrary>
  );
};

export default ClientLibraryDownloads;
