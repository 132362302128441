import getGlobal from './globals';
import { saveToLocalStorage } from './helpers';
export const getStripeConnectUrl = (userId: string) =>
  `https://connect.stripe.com/oauth/authorize?state=${userId}&client_id=${getGlobal(
    'stripeClientId'
  )}&scope=read_write&response_type=code&redirect_uri=${getGlobal(
    'api'
  )}/connect/oauth`;

export const getZoomConnectUrl = (userId: string) =>
  `https://zoom.us/oauth/authorize?state=${userId}&client_id=${getGlobal(
    'zoomClientId'
  )}&response_type=code&redirect_uri=${getGlobal('api')}/connect/zoom`;

export const getGoogleConnectUrl = (userId: string) =>
  `https://accounts.google.com/o/oauth2/v2/auth?state=${userId}&client_id=${getGlobal(
    'googleClientId'
  )}&redirect_uri=${getGlobal(
    'api'
  )}/connect/google&access_type=offline&include_granted_scopes=true&response_type=code&scope=https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email`;

export const ZOOM_STORE_KEY = 'coachpro_zoom_connect';
export const STRIPE_STORE_KEY = 'coachpro_stripe_connect';
export const STRIPE_SUCCESS_MESSAGE = 'Stripe is successfully connected.';
export const ZOOM_SUCCESS_MESSAGE = 'Zoom is successfully connected.';
export const GOOGLE_SUCCESS_MESSAGE =
  'Google Calendar is successfully connected.';
export const STRIPE_CONNECTED_KEY = 'StripeConnected';
export const ZOOM_CONNECTED_KEY = 'ZoomConnected';
export const GOOGLE_CONNECTED_KEY = 'GoogleConnected';

export const storeDataBeforeZoomConnect = <T>(path: string, data: T): void => {
  saveToLocalStorage(ZOOM_STORE_KEY, {
    path,
    data
  });
};
export const storeDataBeforeStripeConnect = <T>(
  path: string,
  data: T
): void => {
  saveToLocalStorage(STRIPE_STORE_KEY, {
    path,
    data
  });
};

const restoreDataFromLocalStorage = <T>(
  key: string,
  clear?: boolean
): { path: string; data: T } => {
  try {
    const data = JSON.parse(localStorage.getItem(key)) || {};
    if (clear) {
      localStorage.removeItem(key);
    }
    return {
      path: data.path || '',
      data: data.data || null
    };
  } catch {
    return { path: '', data: null };
  }
};

export const restoreDataAfterZoomConnect = <T>(
  clear?: boolean
): { path: string; data: T } => {
  return restoreDataFromLocalStorage<T>(ZOOM_STORE_KEY, clear);
};

export const restoreDataAfterStripeConnect = <T>(
  clear?: boolean
): { path: string; data: T } => {
  return restoreDataFromLocalStorage<T>(STRIPE_STORE_KEY, clear);
};

export function loadIntercom(apiKey: string): Promise<unknown> {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://widget.intercom.io/widget/${apiKey}`;
    script.onload = () => {
      resolve();
    };
    document.head.appendChild(script);
  });
}
