// tslint:disable
/**
 *CoachPro API
 * CoachPro Web API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AllAppointmentsAndEventsViewModel
 */
export interface AllAppointmentsAndEventsViewModel {
    /**
     *
     * @type {Array<AppointmentForScheduleViewModel>}
     * @memberof AllAppointmentsAndEventsViewModel
     */
    getAppointments: Array<AppointmentForScheduleViewModel>;
    /**
     *
     * @type {Array<EventForScheduleViewModel>}
     * @memberof AllAppointmentsAndEventsViewModel
     */
    getEvents: Array<EventForScheduleViewModel>;
}
/**
 *
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     *
     * @type {number}
     * @memberof Appointment
     */
    id?: number;
    /**
     *
     * @type {AppointmentType}
     * @memberof Appointment
     */
    appointmentType?: AppointmentType;
    /**
     *
     * @type {number}
     * @memberof Appointment
     */
    appointmentTypeId?: number;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    timestamp?: string;
    /**
     *
     * @type {boolean}
     * @memberof Appointment
     */
    isPrivate?: boolean;
    /**
     *
     * @type {Array<AppointmentInvite>}
     * @memberof Appointment
     */
    invites?: Array<AppointmentInvite> | null;
    /**
     *
     * @type {Array<ClientAppointment>}
     * @memberof Appointment
     */
    clients?: Array<ClientAppointment> | null;
    /**
     *
     * @type {number}
     * @memberof Appointment
     */
    price?: number;
    /**
     *
     * @type {EventStatus}
     * @memberof Appointment
     */
    appointmentStatus?: EventStatus;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    lastRemainderSent?: string;
    /**
     *
     * @type {TimeSpan}
     * @memberof Appointment
     */
    duration?: TimeSpan;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    googleEventId?: string | null;
    /**
     *
     * @type {TimeSpan}
     * @memberof Appointment
     */
    remindBefore?: TimeSpan;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    location?: string | null;
    /**
     *
     * @type {number}
     * @memberof Appointment
     */
    meeting?: number;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    createdBy?: string;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    modifiedOn?: string;
    /**
     *
     * @type {string}
     * @memberof Appointment
     */
    modifiedBy?: string;
}
/**
 *
 * @export
 * @interface AppointmentForScheduleViewModel
 */
export interface AppointmentForScheduleViewModel {
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    timestampDateUnixMs: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    durationMs: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    link?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    location: string;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentForScheduleViewModel
     */
    status: EventStatus;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentForScheduleViewModel
     */
    clientAppointmentStatus: EventStatus;
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    membersCountLimit: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    terms: string;
    /**
     *
     * @type {number}
     * @memberof AppointmentForScheduleViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentForScheduleViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentForScheduleViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentForScheduleViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface AppointmentFullViewModel
 */
export interface AppointmentFullViewModel {
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    appointmentTypeId: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    timestampUtc: number;
    /**
     *
     * @type {Array<string>}
     * @memberof AppointmentFullViewModel
     */
    invites: Array<string>;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentFullViewModel
     */
    appointmentStatus: EventStatus;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    location: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    link?: string | null;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    durationMs: number;
    /**
     *
     * @type {AppointmentTypeAvailabilityViewModel}
     * @memberof AppointmentFullViewModel
     */
    availability: AppointmentTypeAvailabilityViewModel;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentFullViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    membersCountLimit: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentFullViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentFullViewModel
     */
    appointmentTypeStatus: EventStatus;
    /**
     *
     * @type {Array<CoachContactsExtendedViewModel>}
     * @memberof AppointmentFullViewModel
     */
    members: Array<CoachContactsExtendedViewModel>;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentFullViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentFullViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentFullViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface AppointmentInvite
 */
export interface AppointmentInvite {
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    email?: string | null;
    /**
     *
     * @type {number}
     * @memberof AppointmentInvite
     */
    hourlyPrice?: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentInvite
     */
    appointmentId?: number;
    /**
     *
     * @type {Appointment}
     * @memberof AppointmentInvite
     */
    appointment?: Appointment;
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    createdBy?: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    modifiedOn?: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentInvite
     */
    modifiedBy?: string;
}
/**
 *
 * @export
 * @interface AppointmentType
 */
export interface AppointmentType {
    /**
     *
     * @type {number}
     * @memberof AppointmentType
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    location?: string | null;
    /**
     *
     * @type {AppointmentTypePageLink}
     * @memberof AppointmentType
     */
    link?: AppointmentTypePageLink;
    /**
     *
     * @type {TimeSpan}
     * @memberof AppointmentType
     */
    duration?: TimeSpan;
    /**
     *
     * @type {AppointmentTypeAvailability}
     * @memberof AppointmentType
     */
    availability?: AppointmentTypeAvailability;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentType
     */
    isPrivate?: boolean;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    coachId?: string;
    /**
     *
     * @type {Coach}
     * @memberof AppointmentType
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    thumbnailImageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    croppedArea?: string | null;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentType
     */
    locationType?: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentType
     */
    showLocationWhileBooking?: boolean;
    /**
     *
     * @type {number}
     * @memberof AppointmentType
     */
    membersCountLimit?: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentType
     */
    price?: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentType
     */
    terms?: string | null;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentType
     */
    appointmentTypeStatus?: EventStatus;
    /**
     *
     * @type {Array<Appointment>}
     * @memberof AppointmentType
     */
    appointments?: Array<Appointment> | null;
    /**
     *
     * @type {SchedulesBuffer}
     * @memberof AppointmentType
     */
    schedulesBuffer?: SchedulesBuffer;
    /**
     *
     * @type {TimeSpan}
     * @memberof AppointmentType
     */
    remindBefore?: TimeSpan;
}
/**
 *
 * @export
 * @interface AppointmentTypeAvailability
 */
export interface AppointmentTypeAvailability {
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeAvailability
     */
    appointmentTypeId?: number;
    /**
     *
     * @type {AppointmentType}
     * @memberof AppointmentTypeAvailability
     */
    appointmentType?: AppointmentType;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeAvailability
     */
    id?: number;
    /**
     *
     * @type {Array<RepeatingAvailabilityItem>}
     * @memberof AppointmentTypeAvailability
     */
    repeatingAvailability?: Array<RepeatingAvailabilityItem> | null;
    /**
     *
     * @type {Array<SpecificAvailabilityDate>}
     * @memberof AppointmentTypeAvailability
     */
    specificDates?: Array<SpecificAvailabilityDate> | null;
    /**
     *
     * @type {Array<ExcludeDate>}
     * @memberof AppointmentTypeAvailability
     */
    excludeDates?: Array<ExcludeDate> | null;
}
/**
 *
 * @export
 * @interface AppointmentTypeAvailabilityViewModel
 */
export interface AppointmentTypeAvailabilityViewModel {
    /**
     *
     * @type {Array<RepeatingAvailabilityItemViewModel>}
     * @memberof AppointmentTypeAvailabilityViewModel
     */
    repeatingAvailability: Array<RepeatingAvailabilityItemViewModel>;
    /**
     *
     * @type {Array<SpecificAvailabilityDateViewModel>}
     * @memberof AppointmentTypeAvailabilityViewModel
     */
    specificDates: Array<SpecificAvailabilityDateViewModel>;
    /**
     *
     * @type {Array<ExcludeDateViewModel>}
     * @memberof AppointmentTypeAvailabilityViewModel
     */
    excludeDates?: Array<ExcludeDateViewModel> | null;
}
/**
 *
 * @export
 * @interface AppointmentTypePageLink
 */
export interface AppointmentTypePageLink {
    /**
     *
     * @type {number}
     * @memberof AppointmentTypePageLink
     */
    appointmentTypeId?: number;
    /**
     *
     * @type {AppointmentType}
     * @memberof AppointmentTypePageLink
     */
    appointmentType?: AppointmentType;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypePageLink
     */
    pageName?: string | null;
    /**
     *
     * @type {Coach}
     * @memberof AppointmentTypePageLink
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypePageLink
     */
    coachId?: string;
}
/**
 *
 * @export
 * @interface AppointmentTypeViewModel
 */
export interface AppointmentTypeViewModel {
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    location?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    link?: string | null;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeViewModel
     */
    durationMs: number;
    /**
     *
     * @type {AppointmentTypeAvailabilityViewModel}
     * @memberof AppointmentTypeViewModel
     */
    availability: AppointmentTypeAvailabilityViewModel;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentTypeViewModel
     */
    isPrivate?: boolean;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeViewModel
     */
    membersCountLimit?: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeViewModel
     */
    price?: number;
    /**
     *
     * @type {CoachViewModel}
     * @memberof AppointmentTypeViewModel
     */
    coach?: CoachViewModel;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentTypeViewModel
     */
    appointmentTypeStatus?: EventStatus;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypeViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {SchedulesBufferViewModel}
     * @memberof AppointmentTypeViewModel
     */
    schedulesBuffer?: SchedulesBufferViewModel;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypeViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentTypeViewModel
     */
    locationType?: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentTypeViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface AppointmentTypesForScheduleViewModel
 */
export interface AppointmentTypesForScheduleViewModel {
    /**
     *
     * @type {number}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    durationMs: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    link?: string | null;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    location: string;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    status: EventStatus;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    membersCountLimit: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    terms: string;
    /**
     *
     * @type {number}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {SchedulesBufferViewModel}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    schedulesBuffer?: SchedulesBufferViewModel;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentTypesForScheduleViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface AppointmentViewModel
 */
export interface AppointmentViewModel {
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    appointmentTypeId: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    timestampUtc: number;
    /**
     *
     * @type {Array<string>}
     * @memberof AppointmentViewModel
     */
    invites: Array<string>;
    /**
     *
     * @type {EventStatus}
     * @memberof AppointmentViewModel
     */
    appointmentStatus: EventStatus;
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    remindBefore: number;
    /**
     *
     * @type {Array<CoachContactsExtendedViewModel>}
     * @memberof AppointmentViewModel
     */
    members: Array<CoachContactsExtendedViewModel>;
    /**
     *
     * @type {string}
     * @memberof AppointmentViewModel
     */
    location?: string | null;
    /**
     *
     * @type {LocationType}
     * @memberof AppointmentViewModel
     */
    locationType?: LocationType;
    /**
     *
     * @type {number}
     * @memberof AppointmentViewModel
     */
    duration?: number;
    /**
     *
     * @type {boolean}
     * @memberof AppointmentViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface AvailabilityTimeSpan
 */
export interface AvailabilityTimeSpan {
    /**
     *
     * @type {TimeSpan}
     * @memberof AvailabilityTimeSpan
     */
    start?: TimeSpan;
    /**
     *
     * @type {TimeSpan}
     * @memberof AvailabilityTimeSpan
     */
    finish?: TimeSpan;
}
/**
 *
 * @export
 * @interface AvailabilityTimeSpanViewModel
 */
export interface AvailabilityTimeSpanViewModel {
    /**
     *
     * @type {number}
     * @memberof AvailabilityTimeSpanViewModel
     */
    startMs?: number;
    /**
     *
     * @type {number}
     * @memberof AvailabilityTimeSpanViewModel
     */
    finishMs?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum BillingIntervalEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 *
 * @export
 * @interface BillingItemViewModel
 */
export interface BillingItemViewModel {
    /**
     *
     * @type {string}
     * @memberof BillingItemViewModel
     */
    id?: string | null;
    /**
     *
     * @type {number}
     * @memberof BillingItemViewModel
     */
    amount?: number;
    /**
     *
     * @type {number}
     * @memberof BillingItemViewModel
     */
    date?: number;
    /**
     *
     * @type {InvoiceStatus}
     * @memberof BillingItemViewModel
     */
    status?: InvoiceStatus;
    /**
     *
     * @type {string}
     * @memberof BillingItemViewModel
     */
    entityName?: string | null;
    /**
     *
     * @type {number}
     * @memberof BillingItemViewModel
     */
    entityId?: number;
    /**
     *
     * @type {string}
     * @memberof BillingItemViewModel
     */
    entity?: string | null;
    /**
     *
     * @type {string}
     * @memberof BillingItemViewModel
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {ScheduleItemViewModel}
     * @memberof BillingItemViewModel
     */
    appointment?: ScheduleItemViewModel;
    /**
     *
     * @type {ScheduleItemViewModel}
     * @memberof BillingItemViewModel
     */
    coachEvent?: ScheduleItemViewModel;
    /**
     *
     * @type {InvoiceViewModel}
     * @memberof BillingItemViewModel
     */
    invoice?: InvoiceViewModel;
    /**
     *
     * @type {string}
     * @memberof BillingItemViewModel
     */
    description?: string | null;
}
/**
 *
 * @export
 * @interface BookedClientEventsAndAppointmentsViewModel
 */
export interface BookedClientEventsAndAppointmentsViewModel {
    /**
     *
     * @type {Array<ClientEventViewModel>}
     * @memberof BookedClientEventsAndAppointmentsViewModel
     */
    clientEvents: Array<ClientEventViewModel>;
    /**
     *
     * @type {Array<ClientAppointmentFullViewModel>}
     * @memberof BookedClientEventsAndAppointmentsViewModel
     */
    clientAppointments: Array<ClientAppointmentFullViewModel>;
}
/**
 *
 * @export
 * @interface BookedCoachEventsAndAppointmentsViewModel
 */
export interface BookedCoachEventsAndAppointmentsViewModel {
    /**
     *
     * @type {Array<ScheduleItemViewModel>}
     * @memberof BookedCoachEventsAndAppointmentsViewModel
     */
    items?: Array<ScheduleItemViewModel> | null;
    /**
     *
     * @type {number}
     * @memberof BookedCoachEventsAndAppointmentsViewModel
     */
    totalCount?: number;
}
/**
 *
 * @export
 * @interface ChangePasswordViewModel
 */
export interface ChangePasswordViewModel {
    /**
     *
     * @type {string}
     * @memberof ChangePasswordViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof ChangePasswordViewModel
     */
    newPassword: string;
    /**
     *
     * @type {string}
     * @memberof ChangePasswordViewModel
     */
    currentPassword: string;
}
/**
 *
 * @export
 * @interface Client
 */
export interface Client {
    /**
     *
     * @type {Array<ClientFile>}
     * @memberof Client
     */
    clientFiles?: Array<ClientFile> | null;
    /**
     *
     * @type {Array<ClientAppointment>}
     * @memberof Client
     */
    clientAppointments?: Array<ClientAppointment> | null;
    /**
     *
     * @type {Array<ClientEvent>}
     * @memberof Client
     */
    clientEvents?: Array<ClientEvent> | null;
    /**
     *
     * @type {Array<UsrInvoice>}
     * @memberof Client
     */
    invoices?: Array<UsrInvoice> | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    createdByCoach?: boolean;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    lastName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    country?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    timeZone?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    stripeApiKey?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    registrationCompleted?: boolean;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    siteId?: string;
    /**
     *
     * @type {Array<UserClaim>}
     * @memberof Client
     */
    claims?: Array<UserClaim> | null;
    /**
     *
     * @type {Array<UserRole>}
     * @memberof Client
     */
    userRoles?: Array<UserRole> | null;
    /**
     *
     * @type {Array<UserLogin>}
     * @memberof Client
     */
    logins?: Array<UserLogin> | null;
    /**
     *
     * @type {Array<UserToken>}
     * @memberof Client
     */
    tokens?: Array<UserToken> | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    isSoftDeleted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    userName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    normalizedUserName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    normalizedEmail?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    emailConfirmed?: boolean;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    passwordHash?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    securityStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    concurrencyStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    phoneNumberConfirmed?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    twoFactorEnabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof Client
     */
    lockoutEnd?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Client
     */
    lockoutEnabled?: boolean;
    /**
     *
     * @type {number}
     * @memberof Client
     */
    accessFailedCount?: number;
}
/**
 *
 * @export
 * @interface ClientAccountViewModel
 */
export interface ClientAccountViewModel {
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof ClientAccountViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @interface ClientAppointment
 */
export interface ClientAppointment {
    /**
     *
     * @type {number}
     * @memberof ClientAppointment
     */
    appointmentId?: number;
    /**
     *
     * @type {Appointment}
     * @memberof ClientAppointment
     */
    appointment?: Appointment;
    /**
     *
     * @type {string}
     * @memberof ClientAppointment
     */
    clientId?: string;
    /**
     *
     * @type {Client}
     * @memberof ClientAppointment
     */
    client?: Client;
    /**
     *
     * @type {EventStatus}
     * @memberof ClientAppointment
     */
    clientAppointmentStatus?: EventStatus;
    /**
     *
     * @type {PaymentReceivedItemClientAppointment}
     * @memberof ClientAppointment
     */
    paymentItem?: PaymentReceivedItemClientAppointment;
}
/**
 *
 * @export
 * @interface ClientAppointmentFullViewModel
 */
export interface ClientAppointmentFullViewModel {
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentFullViewModel
     */
    appointmentId: number;
    /**
     *
     * @type {AppointmentViewModel}
     * @memberof ClientAppointmentFullViewModel
     */
    appointment: AppointmentViewModel;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    clientId: string;
    /**
     *
     * @type {object}
     * @memberof ClientAppointmentFullViewModel
     */
    client: object;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentFullViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    location: string;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    link: string;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentFullViewModel
     */
    durationMs: number;
    /**
     *
     * @type {AppointmentTypeAvailabilityViewModel}
     * @memberof ClientAppointmentFullViewModel
     */
    availability: AppointmentTypeAvailabilityViewModel;
    /**
     *
     * @type {boolean}
     * @memberof ClientAppointmentFullViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentFullViewModel
     */
    membersCountLimit: number;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentFullViewModel
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    terms: string;
    /**
     *
     * @type {EventStatus}
     * @memberof ClientAppointmentFullViewModel
     */
    appointmentTypeStatus: EventStatus;
    /**
     *
     * @type {Array<CoachContactsViewModel>}
     * @memberof ClientAppointmentFullViewModel
     */
    members: Array<CoachContactsViewModel>;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentFullViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {LocationType}
     * @memberof ClientAppointmentFullViewModel
     */
    locationType?: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof ClientAppointmentFullViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface ClientAppointmentInfoViewModel
 */
export interface ClientAppointmentInfoViewModel {
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentInfoViewModel
     */
    id?: number;
    /**
     *
     * @type {AppointmentTypeViewModel}
     * @memberof ClientAppointmentInfoViewModel
     */
    appointmentType?: AppointmentTypeViewModel;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentInfoViewModel
     */
    appointmentTypeId?: number;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInfoViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInfoViewModel
     */
    timestamp?: string;
    /**
     *
     * @type {boolean}
     * @memberof ClientAppointmentInfoViewModel
     */
    isPrivate?: boolean;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentInfoViewModel
     */
    price?: number;
    /**
     *
     * @type {EventStatus}
     * @memberof ClientAppointmentInfoViewModel
     */
    appointmentStatus?: EventStatus;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInfoViewModel
     */
    lastRemainderSent?: string;
    /**
     *
     * @type {TimeSpan}
     * @memberof ClientAppointmentInfoViewModel
     */
    remindBefore?: TimeSpan;
}
/**
 *
 * @export
 * @interface ClientAppointmentInvoiceViewModel
 */
export interface ClientAppointmentInvoiceViewModel {
    /**
     *
     * @type {ClientAppointmentInfoViewModel}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    clientAppointment?: ClientAppointmentInfoViewModel;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    clientId?: string;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    timestamp?: number;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof ClientAppointmentInvoiceViewModel
     */
    refundDate?: number | null;
}
/**
 *
 * @export
 * @interface ClientEvent
 */
export interface ClientEvent {
    /**
     *
     * @type {number}
     * @memberof ClientEvent
     */
    coachEventId?: number;
    /**
     *
     * @type {CoachEvent}
     * @memberof ClientEvent
     */
    coachEvent?: CoachEvent;
    /**
     *
     * @type {string}
     * @memberof ClientEvent
     */
    clientId?: string;
    /**
     *
     * @type {Client}
     * @memberof ClientEvent
     */
    client?: Client;
    /**
     *
     * @type {EventStatus}
     * @memberof ClientEvent
     */
    clientEventStatus?: EventStatus;
    /**
     *
     * @type {PaymentReceivedItemClientEvent}
     * @memberof ClientEvent
     */
    paymentItem?: PaymentReceivedItemClientEvent;
}
/**
 *
 * @export
 * @interface ClientEventInfoViewModel
 */
export interface ClientEventInfoViewModel {
    /**
     *
     * @type {number}
     * @memberof ClientEventInfoViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    coachId?: string;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    location?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    description?: string | null;
    /**
     *
     * @type {TimeSpan}
     * @memberof ClientEventInfoViewModel
     */
    duration?: TimeSpan;
    /**
     *
     * @type {CoachEventPageLink}
     * @memberof ClientEventInfoViewModel
     */
    link?: CoachEventPageLink;
    /**
     *
     * @type {number}
     * @memberof ClientEventInfoViewModel
     */
    availableSpots?: number;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    timestamp?: string;
    /**
     *
     * @type {boolean}
     * @memberof ClientEventInfoViewModel
     */
    isPrivate?: boolean;
    /**
     *
     * @type {number}
     * @memberof ClientEventInfoViewModel
     */
    price?: number;
    /**
     *
     * @type {EventStatus}
     * @memberof ClientEventInfoViewModel
     */
    coachEventStatus?: EventStatus;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    terms?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    lastRemainderSent?: string;
    /**
     *
     * @type {TimeSpan}
     * @memberof ClientEventInfoViewModel
     */
    remindBefore?: TimeSpan;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    thumbnailImageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInfoViewModel
     */
    croppedArea?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof ClientEventInfoViewModel
     */
    isScheduled?: boolean;
}
/**
 *
 * @export
 * @interface ClientEventInvoiceViewModel
 */
export interface ClientEventInvoiceViewModel {
    /**
     *
     * @type {ClientEventInfoViewModel}
     * @memberof ClientEventInvoiceViewModel
     */
    clientEvent?: ClientEventInfoViewModel;
    /**
     *
     * @type {string}
     * @memberof ClientEventInvoiceViewModel
     */
    clientId?: string;
    /**
     *
     * @type {number}
     * @memberof ClientEventInvoiceViewModel
     */
    timestamp?: number;
    /**
     *
     * @type {string}
     * @memberof ClientEventInvoiceViewModel
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInvoiceViewModel
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInvoiceViewModel
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientEventInvoiceViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof ClientEventInvoiceViewModel
     */
    refundDate?: number | null;
}
/**
 *
 * @export
 * @interface ClientEventViewModel
 */
export interface ClientEventViewModel {
    /**
     *
     * @type {number}
     * @memberof ClientEventViewModel
     */
    coachEventId?: number;
    /**
     *
     * @type {CoachEventViewModel}
     * @memberof ClientEventViewModel
     */
    coachEvent?: CoachEventViewModel;
    /**
     *
     * @type {string}
     * @memberof ClientEventViewModel
     */
    clientId?: string;
    /**
     *
     * @type {object}
     * @memberof ClientEventViewModel
     */
    client?: object;
}
/**
 *
 * @export
 * @interface ClientFile
 */
export interface ClientFile {
    /**
     *
     * @type {Client}
     * @memberof ClientFile
     */
    client?: Client;
    /**
     *
     * @type {string}
     * @memberof ClientFile
     */
    clientId?: string;
    /**
     *
     * @type {any}
     * @memberof ClientFile
     */
    file?: any;
    /**
     *
     * @type {number}
     * @memberof ClientFile
     */
    fileId?: number;
}
/**
 *
 * @export
 * @interface ClientInfoViewModel
 */
export interface ClientInfoViewModel {
    /**
     *
     * @type {string}
     * @memberof ClientInfoViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof ClientInfoViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof ClientInfoViewModel
     */
    timeZone: string;
    /**
     *
     * @type {string}
     * @memberof ClientInfoViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof ClientInfoViewModel
     */
    email: string;
}
/**
 *
 * @export
 * @interface ClientInvoiceViewModel
 */
export interface ClientInvoiceViewModel {
    /**
     *
     * @type {Array<ClientAppointmentInvoiceViewModel>}
     * @memberof ClientInvoiceViewModel
     */
    clientAppointmentPayments?: Array<ClientAppointmentInvoiceViewModel> | null;
    /**
     *
     * @type {Array<ClientEventInvoiceViewModel>}
     * @memberof ClientInvoiceViewModel
     */
    clientEventPayments?: Array<ClientEventInvoiceViewModel> | null;
    /**
     *
     * @type {Array<ClientPaymentInvoiceViewModel>}
     * @memberof ClientInvoiceViewModel
     */
    clientInvoicePayments?: Array<ClientPaymentInvoiceViewModel> | null;
}
/**
 *
 * @export
 * @interface ClientPaymentInvoiceViewModel
 */
export interface ClientPaymentInvoiceViewModel {
    /**
     *
     * @type {InvoiceViewModel}
     * @memberof ClientPaymentInvoiceViewModel
     */
    invoice?: InvoiceViewModel;
    /**
     *
     * @type {string}
     * @memberof ClientPaymentInvoiceViewModel
     */
    clientId?: string;
    /**
     *
     * @type {number}
     * @memberof ClientPaymentInvoiceViewModel
     */
    timestamp?: number;
    /**
     *
     * @type {string}
     * @memberof ClientPaymentInvoiceViewModel
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientPaymentInvoiceViewModel
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientPaymentInvoiceViewModel
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof ClientPaymentInvoiceViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {number}
     * @memberof ClientPaymentInvoiceViewModel
     */
    refundDate?: number | null;
}
/**
 *
 * @export
 * @interface ClientShortViewModel
 */
export interface ClientShortViewModel {
    /**
     *
     * @type {string}
     * @memberof ClientShortViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof ClientShortViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof ClientShortViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof ClientShortViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof ClientShortViewModel
     */
    phoneNumber: string;
}
/**
 *
 * @export
 * @interface Coach
 */
export interface Coach {
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    siteName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    stripeApiKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    stripeUser?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    country?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    state?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    industry?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    lastName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    registrationCompleted?: boolean;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    timeZone?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    socialEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    motivationMessage?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    zoomUser?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    zoomUserEmail?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    accessToken?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    refreshToken?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    expiredDate?: string;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    googleUser?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    accessToken_Google?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    refreshToken_Google?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    expiredDate_Google?: string;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    supportEmail?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    isAdmin?: boolean;
    /**
     *
     * @type {number}
     * @memberof Coach
     */
    pricingPlanId?: number;
    /**
     *
     * @type {PricingPlan}
     * @memberof Coach
     */
    pricingPlan?: PricingPlan;
    /**
     *
     * @type {GeneralAvailability}
     * @memberof Coach
     */
    availability?: GeneralAvailability;
    /**
     *
     * @type {SchedulesBuffer}
     * @memberof Coach
     */
    schedulesBuffer?: SchedulesBuffer;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    canCancelAppointment?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    canAcceptUntilFullyBooked?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    canAcceptMaxNumberAppointments?: boolean;
    /**
     *
     * @type {TimeSpan}
     * @memberof Coach
     */
    minTimeCanChangeAppointmentInAdvance?: TimeSpan;
    /**
     *
     * @type {Notice}
     * @memberof Coach
     */
    notice?: Notice;
    /**
     *
     * @type {Array<PageLink>}
     * @memberof Coach
     */
    pageLinks?: Array<PageLink> | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    imageId?: string | null;
    /**
     *
     * @type {Array<CoachEvent>}
     * @memberof Coach
     */
    coachEvents?: Array<CoachEvent> | null;
    /**
     *
     * @type {Array<UsrInvoice>}
     * @memberof Coach
     */
    usrInvoices?: Array<UsrInvoice> | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    siteCustomizationJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    siteId?: string;
    /**
     *
     * @type {Array<UserClaim>}
     * @memberof Coach
     */
    claims?: Array<UserClaim> | null;
    /**
     *
     * @type {Array<UserRole>}
     * @memberof Coach
     */
    userRoles?: Array<UserRole> | null;
    /**
     *
     * @type {Array<UserLogin>}
     * @memberof Coach
     */
    logins?: Array<UserLogin> | null;
    /**
     *
     * @type {Array<UserToken>}
     * @memberof Coach
     */
    tokens?: Array<UserToken> | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    isSoftDeleted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    userName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    normalizedUserName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    normalizedEmail?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    emailConfirmed?: boolean;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    passwordHash?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    securityStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    concurrencyStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    phoneNumberConfirmed?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    twoFactorEnabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof Coach
     */
    lockoutEnd?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof Coach
     */
    lockoutEnabled?: boolean;
    /**
     *
     * @type {number}
     * @memberof Coach
     */
    accessFailedCount?: number;
}
/**
 *
 * @export
 * @interface CoachAccountViewModel
 */
export interface CoachAccountViewModel {
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    siteCustomizationJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    siteName: string;
    /**
     *
     * @type {number}
     * @memberof CoachAccountViewModel
     */
    pricingPlanId: number;
    /**
     *
     * @type {boolean}
     * @memberof CoachAccountViewModel
     */
    isStripeConnected: boolean;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    userId: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    stripeApiKey: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    socialEmail: string;
    /**
     *
     * @type {boolean}
     * @memberof CoachAccountViewModel
     */
    isZoomConnected?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoachAccountViewModel
     */
    isGoogleConnected?: boolean;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    supportEmail?: string | null;
    /**
     *
     * @type {number}
     * @memberof CoachAccountViewModel
     */
    createdOn?: number;
    /**
     *
     * @type {boolean}
     * @memberof CoachAccountViewModel
     */
    isAdmin?: boolean | null;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof CoachAccountViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @interface CoachAvailabilitySettingsViewModel
 */
export interface CoachAvailabilitySettingsViewModel {
    /**
     *
     * @type {GeneralAvailabilityViewModel}
     * @memberof CoachAvailabilitySettingsViewModel
     */
    availability: GeneralAvailabilityViewModel;
    /**
     *
     * @type {SchedulesBufferViewModel}
     * @memberof CoachAvailabilitySettingsViewModel
     */
    schedulesBuffer: SchedulesBufferViewModel;
    /**
     *
     * @type {boolean}
     * @memberof CoachAvailabilitySettingsViewModel
     */
    hasOtherSettings?: boolean;
    /**
     *
     * @type {number}
     * @memberof CoachAvailabilitySettingsViewModel
     */
    minTimeCanChangeAppointmentInAdvanceMs?: number;
    /**
     *
     * @type {NoticeViewModel}
     * @memberof CoachAvailabilitySettingsViewModel
     */
    notice: NoticeViewModel;
}
/**
 *
 * @export
 * @interface CoachContactsExtendedViewModel
 */
export interface CoachContactsExtendedViewModel {
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    clientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    clientStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsExtendedViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @interface CoachContactsViewModel
 */
export interface CoachContactsViewModel {
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    clientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof CoachContactsViewModel
     */
    timeZone: string;
    /**
     *
     * @type {boolean}
     * @memberof CoachContactsViewModel
     */
    isGuest?: boolean;
}
/**
 *
 * @export
 * @interface CoachCreateViewModel
 */
export interface CoachCreateViewModel {
    /**
     *
     * @type {string}
     * @memberof CoachCreateViewModel
     */
    email: string;
    /**
     *
     * @type {number}
     * @memberof CoachCreateViewModel
     */
    pricingPlanId: number;
    /**
     *
     * @type {boolean}
     * @memberof CoachCreateViewModel
     */
    isAdmin: boolean;
}
/**
 *
 * @export
 * @interface CoachEvent
 */
export interface CoachEvent {
    /**
     *
     * @type {number}
     * @memberof CoachEvent
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    coachId?: string;
    /**
     *
     * @type {Coach}
     * @memberof CoachEvent
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    location?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    description?: string | null;
    /**
     *
     * @type {TimeSpan}
     * @memberof CoachEvent
     */
    duration?: TimeSpan;
    /**
     *
     * @type {CoachEventPageLink}
     * @memberof CoachEvent
     */
    link?: CoachEventPageLink;
    /**
     *
     * @type {number}
     * @memberof CoachEvent
     */
    availableSpots?: number;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    timestamp?: string;
    /**
     *
     * @type {boolean}
     * @memberof CoachEvent
     */
    isPrivate?: boolean;
    /**
     *
     * @type {Array<EventInvite>}
     * @memberof CoachEvent
     */
    invites?: Array<EventInvite> | null;
    /**
     *
     * @type {Array<ClientEvent>}
     * @memberof CoachEvent
     */
    clients?: Array<ClientEvent> | null;
    /**
     *
     * @type {number}
     * @memberof CoachEvent
     */
    price?: number;
    /**
     *
     * @type {EventStatus}
     * @memberof CoachEvent
     */
    coachEventStatus?: EventStatus;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    terms?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    lastRemainderSent?: string;
    /**
     *
     * @type {TimeSpan}
     * @memberof CoachEvent
     */
    remindBefore?: TimeSpan;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    imageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    thumbnailImageId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    croppedArea?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof CoachEvent
     */
    isScheduled?: boolean;
    /**
     *
     * @type {number}
     * @memberof CoachEvent
     */
    meeting?: number;
    /**
     *
     * @type {LocationType}
     * @memberof CoachEvent
     */
    locationType?: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof CoachEvent
     */
    showLocationWhileBooking?: boolean;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    googleEventId?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    createdBy?: string;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    modifiedOn?: string;
    /**
     *
     * @type {string}
     * @memberof CoachEvent
     */
    modifiedBy?: string;
}
/**
 *
 * @export
 * @interface CoachEventPageLink
 */
export interface CoachEventPageLink {
    /**
     *
     * @type {number}
     * @memberof CoachEventPageLink
     */
    coachEventId?: number;
    /**
     *
     * @type {CoachEvent}
     * @memberof CoachEventPageLink
     */
    coachEvent?: CoachEvent;
    /**
     *
     * @type {string}
     * @memberof CoachEventPageLink
     */
    pageName?: string | null;
    /**
     *
     * @type {Coach}
     * @memberof CoachEventPageLink
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof CoachEventPageLink
     */
    coachId?: string;
}
/**
 *
 * @export
 * @interface CoachEventUpdateViewModel
 */
export interface CoachEventUpdateViewModel {
    /**
     *
     * @type {Array<string>}
     * @memberof CoachEventUpdateViewModel
     */
    membersToRemove: Array<string>;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    location?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    durationMs: number;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    link: string;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    availableSpots: number;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    timestampDateUnixMs: number;
    /**
     *
     * @type {Array<string>}
     * @memberof CoachEventUpdateViewModel
     */
    invites: Array<string>;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    price: number;
    /**
     *
     * @type {EventStatus}
     * @memberof CoachEventUpdateViewModel
     */
    coachEventStatus: EventStatus;
    /**
     *
     * @type {number}
     * @memberof CoachEventUpdateViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {Array<CoachContactsExtendedViewModel>}
     * @memberof CoachEventUpdateViewModel
     */
    members: Array<CoachContactsExtendedViewModel>;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventUpdateViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventUpdateViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventUpdateViewModel
     */
    isScheduled: boolean;
    /**
     *
     * @type {LocationType}
     * @memberof CoachEventUpdateViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventUpdateViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface CoachEventViewModel
 */
export interface CoachEventViewModel {
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    location?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    durationMs: number;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    link: string;
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    availableSpots: number;
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    timestampDateUnixMs: number;
    /**
     *
     * @type {Array<string>}
     * @memberof CoachEventViewModel
     */
    invites: Array<string>;
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    price: number;
    /**
     *
     * @type {EventStatus}
     * @memberof CoachEventViewModel
     */
    coachEventStatus: EventStatus;
    /**
     *
     * @type {number}
     * @memberof CoachEventViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {Array<CoachContactsExtendedViewModel>}
     * @memberof CoachEventViewModel
     */
    members: Array<CoachContactsExtendedViewModel>;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof CoachEventViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventViewModel
     */
    isScheduled: boolean;
    /**
     *
     * @type {LocationType}
     * @memberof CoachEventViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof CoachEventViewModel
     */
    showLocationWhileBooking?: boolean;
}
/**
 *
 * @export
 * @interface CoachViewModel
 */
export interface CoachViewModel {
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    siteName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    stripeApiKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    city?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    country?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    industry?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    lastName?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof CoachViewModel
     */
    registrationCompleted?: boolean;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    timeZone?: string | null;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    zoomUser?: string | null;
    /**
     *
     * @type {number}
     * @memberof CoachViewModel
     */
    pricingPlanId?: number;
    /**
     *
     * @type {PricingPlanViewModel}
     * @memberof CoachViewModel
     */
    pricingPlan?: PricingPlanViewModel;
    /**
     *
     * @type {GeneralAvailabilityViewModel}
     * @memberof CoachViewModel
     */
    availability?: GeneralAvailabilityViewModel;
    /**
     *
     * @type {SchedulesBufferViewModel}
     * @memberof CoachViewModel
     */
    schedulesBuffer?: SchedulesBufferViewModel;
    /**
     *
     * @type {boolean}
     * @memberof CoachViewModel
     */
    canCancelAppointment?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoachViewModel
     */
    canAcceptUntilFullyBooked?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CoachViewModel
     */
    canAcceptMaxNumberAppointments?: boolean;
    /**
     *
     * @type {TimeSpan}
     * @memberof CoachViewModel
     */
    minTimeCanChangeAppointmentInAdvance?: TimeSpan;
    /**
     *
     * @type {NoticeViewModel}
     * @memberof CoachViewModel
     */
    notice?: NoticeViewModel;
    /**
     *
     * @type {string}
     * @memberof CoachViewModel
     */
    siteCustommization?: string | null;
}
/**
 *
 * @export
 * @interface CreateClientByCoachRequestViewModel
 */
export interface CreateClientByCoachRequestViewModel {
    /**
     *
     * @type {string}
     * @memberof CreateClientByCoachRequestViewModel
     */
    clientId?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateClientByCoachRequestViewModel
     */
    isClientExists?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CreateClientByCoachRequestViewModel
     */
    emailConfirmed?: boolean;
}
/**
 *
 * @export
 * @interface CreateInvoiceViewModel
 */
export interface CreateInvoiceViewModel {
    /**
     *
     * @type {InvoiceStatus}
     * @memberof CreateInvoiceViewModel
     */
    status: InvoiceStatus;
    /**
     *
     * @type {number}
     * @memberof CreateInvoiceViewModel
     */
    startDate: number;
    /**
     *
     * @type {number}
     * @memberof CreateInvoiceViewModel
     */
    dueDate: number;
    /**
     *
     * @type {InvoiceType}
     * @memberof CreateInvoiceViewModel
     */
    type: InvoiceType;
    /**
     *
     * @type {BillingIntervalEnum}
     * @memberof CreateInvoiceViewModel
     */
    billingInterval?: BillingIntervalEnum;
    /**
     *
     * @type {number}
     * @memberof CreateInvoiceViewModel
     */
    intervalValue?: number;
    /**
     *
     * @type {string}
     * @memberof CreateInvoiceViewModel
     */
    clientId: string;
    /**
     *
     * @type {InvoiceTaxViewModel}
     * @memberof CreateInvoiceViewModel
     */
    tax?: InvoiceTaxViewModel;
    /**
     *
     * @type {Array<InvoiceServiceViewModel>}
     * @memberof CreateInvoiceViewModel
     */
    services?: Array<InvoiceServiceViewModel> | null;
    /**
     *
     * @type {string}
     * @memberof CreateInvoiceViewModel
     */
    siteCustomizationJson?: string | null;
    /**
     *
     * @type {string}
     * @memberof CreateInvoiceViewModel
     */
    memo?: string | null;
}
/**
 *
 * @export
 * @interface CreatedFileViewModel
 */
export interface CreatedFileViewModel {
    /**
     *
     * @type {string}
     * @memberof CreatedFileViewModel
     */
    createdFileName?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

/**
 *
 * @export
 * @interface EventForScheduleViewModel
 */
export interface EventForScheduleViewModel {
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    price: number;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    timestampDateUnixMs: number;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    durationMs: number;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    location: string;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    link?: string | null;
    /**
     *
     * @type {EventStatus}
     * @memberof EventForScheduleViewModel
     */
    status: EventStatus;
    /**
     *
     * @type {EventStatus}
     * @memberof EventForScheduleViewModel
     */
    clientEventStatus: EventStatus;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    membersCountLimit: number;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    terms: string;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof EventForScheduleViewModel
     */
    croppedArea: string;
    /**
     *
     * @type {boolean}
     * @memberof EventForScheduleViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {boolean}
     * @memberof EventForScheduleViewModel
     */
    isScheduled: boolean;
    /**
     *
     * @type {LocationType}
     * @memberof EventForScheduleViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {boolean}
     * @memberof EventForScheduleViewModel
     */
    showLocationWhileBooking?: boolean;
    /**
     *
     * @type {number}
     * @memberof EventForScheduleViewModel
     */
    activeMembersCount?: number;
}
/**
 *
 * @export
 * @interface EventInvite
 */
export interface EventInvite {
    /**
     *
     * @type {string}
     * @memberof EventInvite
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof EventInvite
     */
    email?: string | null;
    /**
     *
     * @type {number}
     * @memberof EventInvite
     */
    hourlyPrice?: number;
    /**
     *
     * @type {number}
     * @memberof EventInvite
     */
    coachEventId?: number;
    /**
     *
     * @type {CoachEvent}
     * @memberof EventInvite
     */
    coachEvent?: CoachEvent;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum EventStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 *
 * @export
 * @interface ExcludeDate
 */
export interface ExcludeDate {
    /**
     *
     * @type {string}
     * @memberof ExcludeDate
     */
    timestampDate?: string;
    /**
     *
     * @type {AvailabilityTimeSpan}
     * @memberof ExcludeDate
     */
    timeSpan?: AvailabilityTimeSpan;
}
/**
 *
 * @export
 * @interface ExcludeDateViewModel
 */
export interface ExcludeDateViewModel {
    /**
     *
     * @type {number}
     * @memberof ExcludeDateViewModel
     */
    timestampDateUnixMs?: number;
    /**
     *
     * @type {AvailabilityTimeSpanViewModel}
     * @memberof ExcludeDateViewModel
     */
    timeSpan?: AvailabilityTimeSpanViewModel;
}
/**
 *
 * @export
 * @interface FileLinkUploadViewModel
 */
export interface FileLinkUploadViewModel {
    /**
     *
     * @type {string}
     * @memberof FileLinkUploadViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileLinkUploadViewModel
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileLinkUploadViewModel
     */
    fileLink?: string | null;
}
/**
 *
 * @export
 * @interface FileResponseViewModel
 */
export interface FileResponseViewModel {
    /**
     *
     * @type {number}
     * @memberof FileResponseViewModel
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof FileResponseViewModel
     */
    createdOn?: number;
    /**
     *
     * @type {string}
     * @memberof FileResponseViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileResponseViewModel
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof FileResponseViewModel
     */
    uploadedByUserId?: string;
    /**
     *
     * @type {string}
     * @memberof FileResponseViewModel
     */
    awsUrl?: string | null;
    /**
     *
     * @type {number}
     * @memberof FileResponseViewModel
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof FileResponseViewModel
     */
    fileLink?: string | null;
    /**
     *
     * @type {number}
     * @memberof FileResponseViewModel
     */
    sharedOn?: number | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FileResponseViewModel
     */
    clientIds?: Array<string> | null;
}
/**
 *
 * @export
 * @interface FileShareViewModel
 */
export interface FileShareViewModel {
    /**
     *
     * @type {Array<number>}
     * @memberof FileShareViewModel
     */
    fileIds?: Array<number> | null;
    /**
     *
     * @type {Array<string>}
     * @memberof FileShareViewModel
     */
    clientIds?: Array<string> | null;
}
/**
 *
 * @export
 * @interface ForgotPasswordViewModel
 */
export interface ForgotPasswordViewModel {
    /**
     *
     * @type {string}
     * @memberof ForgotPasswordViewModel
     */
    email: string;
}
/**
 *
 * @export
 * @interface GeneralAvailability
 */
export interface GeneralAvailability {
    /**
     *
     * @type {string}
     * @memberof GeneralAvailability
     */
    coachId?: string;
    /**
     *
     * @type {Coach}
     * @memberof GeneralAvailability
     */
    coach?: Coach;
    /**
     *
     * @type {number}
     * @memberof GeneralAvailability
     */
    id?: number;
    /**
     *
     * @type {Array<RepeatingAvailabilityItem>}
     * @memberof GeneralAvailability
     */
    repeatingAvailability?: Array<RepeatingAvailabilityItem> | null;
    /**
     *
     * @type {Array<SpecificAvailabilityDate>}
     * @memberof GeneralAvailability
     */
    specificDates?: Array<SpecificAvailabilityDate> | null;
    /**
     *
     * @type {Array<ExcludeDate>}
     * @memberof GeneralAvailability
     */
    excludeDates?: Array<ExcludeDate> | null;
}
/**
 *
 * @export
 * @interface GeneralAvailabilityViewModel
 */
export interface GeneralAvailabilityViewModel {
    /**
     *
     * @type {Array<RepeatingAvailabilityItemViewModel>}
     * @memberof GeneralAvailabilityViewModel
     */
    repeatingAvailability: Array<RepeatingAvailabilityItemViewModel>;
    /**
     *
     * @type {Array<SpecificAvailabilityDateViewModel>}
     * @memberof GeneralAvailabilityViewModel
     */
    specificDates: Array<SpecificAvailabilityDateViewModel>;
    /**
     *
     * @type {Array<ExcludeDateViewModel>}
     * @memberof GeneralAvailabilityViewModel
     */
    excludeDates?: Array<ExcludeDateViewModel> | null;
}
/**
 *
 * @export
 * @interface GetAllBookedViewModel
 */
export interface GetAllBookedViewModel {
    /**
     *
     * @type {Array<AppointmentTypesForScheduleViewModel>}
     * @memberof GetAllBookedViewModel
     */
    getAvailableSingle: Array<AppointmentTypesForScheduleViewModel>;
    /**
     *
     * @type {Array<AppointmentForScheduleViewModel>}
     * @memberof GetAllBookedViewModel
     */
    getAvailableGroupsBooked: Array<AppointmentForScheduleViewModel>;
    /**
     *
     * @type {Array<EventForScheduleViewModel>}
     * @memberof GetAllBookedViewModel
     */
    getBookedEvents: Array<EventForScheduleViewModel>;
    /**
     *
     * @type {Array<EventForScheduleViewModel>}
     * @memberof GetAllBookedViewModel
     */
    getSignedEventsByClient: Array<EventForScheduleViewModel>;
}
/**
 *
 * @export
 * @interface GetAllWithInvitesViewModel
 */
export interface GetAllWithInvitesViewModel {
    /**
     *
     * @type {Array<AppointmentForScheduleViewModel>}
     * @memberof GetAllWithInvitesViewModel
     */
    getAppointmentsWithInvites: Array<AppointmentForScheduleViewModel>;
    /**
     *
     * @type {Array<EventForScheduleViewModel>}
     * @memberof GetAllWithInvitesViewModel
     */
    getEventsWithInvites: Array<EventForScheduleViewModel>;
}
/**
 *
 * @export
 * @interface GetAllWithMembersViewModel
 */
export interface GetAllWithMembersViewModel {
    /**
     *
     * @type {Array<AppointmentForScheduleViewModel>}
     * @memberof GetAllWithMembersViewModel
     */
    getAppointmentsWithMembers: Array<AppointmentForScheduleViewModel>;
    /**
     *
     * @type {Array<EventForScheduleViewModel>}
     * @memberof GetAllWithMembersViewModel
     */
    getEventsWithMembers: Array<EventForScheduleViewModel>;
}
/**
 *
 * @export
 * @interface GetAvailableSlotsAppointmentSettingsViewModel
 */
export interface GetAvailableSlotsAppointmentSettingsViewModel {
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    appointmentTypeId: number;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    fromTimeStampUtc: number;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    toTimeStampUtc: number;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    intervalMs: number;
    /**
     *
     * @type {string}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    timeZone: string;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsAppointmentSettingsViewModel
     */
    durationMs: number;
}
/**
 *
 * @export
 * @interface GetAvailableSlotsEventSettingsViewModel
 */
export interface GetAvailableSlotsEventSettingsViewModel {
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsEventSettingsViewModel
     */
    fromTimeStampUtc: number;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsEventSettingsViewModel
     */
    toTimeStampUtc: number;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsEventSettingsViewModel
     */
    intervalMs: number;
    /**
     *
     * @type {string}
     * @memberof GetAvailableSlotsEventSettingsViewModel
     */
    timeZone: string;
    /**
     *
     * @type {number}
     * @memberof GetAvailableSlotsEventSettingsViewModel
     */
    durationMs: number;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     *
     * @type {string}
     * @memberof InlineObject
     */
    Name?: string | null;
    /**
     *
     * @type {string}
     * @memberof InlineObject
     */
    Description?: string | null;
    /**
     *
     * @type {any}
     * @memberof InlineObject
     */
    File: any;
    /**
     *
     * @type {number}
     * @memberof InlineObject
     */
    Size?: number;
}
/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     *
     * @type {string}
     * @memberof InlineObject1
     */
    Name?: string | null;
    /**
     *
     * @type {string}
     * @memberof InlineObject1
     */
    Description?: string | null;
    /**
     *
     * @type {any}
     * @memberof InlineObject1
     */
    File: any;
    /**
     *
     * @type {number}
     * @memberof InlineObject1
     */
    Size?: number;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     *
     * @type {any}
     * @memberof InlineObject2
     */
    File: any;
}
/**
 *
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     *
     * @type {any}
     * @memberof InlineObject3
     */
    File: any;
}
/**
 *
 * @export
 * @interface InvoiceServiceViewModel
 */
export interface InvoiceServiceViewModel {
    /**
     *
     * @type {number}
     * @memberof InvoiceServiceViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvoiceServiceViewModel
     */
    title?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvoiceServiceViewModel
     */
    quantity?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceServiceViewModel
     */
    price?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum InvoiceStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7
}

/**
 *
 * @export
 * @interface InvoiceSubscriptionRequest
 */
export interface InvoiceSubscriptionRequest {
    /**
     *
     * @type {string}
     * @memberof InvoiceSubscriptionRequest
     */
    paymentMethodId?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvoiceSubscriptionRequest
     */
    invoiceNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvoiceSubscriptionRequest
     */
    clientId?: string | null;
}
/**
 *
 * @export
 * @interface InvoiceTaxViewModel
 */
export interface InvoiceTaxViewModel {
    /**
     *
     * @type {number}
     * @memberof InvoiceTaxViewModel
     */
    id?: number | null;
    /**
     *
     * @type {string}
     * @memberof InvoiceTaxViewModel
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvoiceTaxViewModel
     */
    amount?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceTaxViewModel
     */
    rate?: number;
    /**
     *
     * @type {TaxType}
     * @memberof InvoiceTaxViewModel
     */
    type?: TaxType;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum InvoiceType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 *
 * @export
 * @interface InvoiceViewModel
 */
export interface InvoiceViewModel {
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof InvoiceViewModel
     */
    number?: string | null;
    /**
     *
     * @type {InvoiceType}
     * @memberof InvoiceViewModel
     */
    type?: InvoiceType;
    /**
     *
     * @type {BillingIntervalEnum}
     * @memberof InvoiceViewModel
     */
    billingInterval?: BillingIntervalEnum;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    intervalValue?: number;
    /**
     *
     * @type {string}
     * @memberof InvoiceViewModel
     */
    stripeSubscriptionId?: string | null;
    /**
     *
     * @type {string}
     * @memberof InvoiceViewModel
     */
    stripePaymentMethodId?: string | null;
    /**
     *
     * @type {InvoiceStatus}
     * @memberof InvoiceViewModel
     */
    status?: InvoiceStatus;
    /**
     *
     * @type {CoachContactsViewModel}
     * @memberof InvoiceViewModel
     */
    client?: CoachContactsViewModel;
    /**
     *
     * @type {InvoiceTaxViewModel}
     * @memberof InvoiceViewModel
     */
    tax?: InvoiceTaxViewModel;
    /**
     *
     * @type {Array<InvoiceServiceViewModel>}
     * @memberof InvoiceViewModel
     */
    services?: Array<InvoiceServiceViewModel> | null;
    /**
     *
     * @type {string}
     * @memberof InvoiceViewModel
     */
    siteCustomizationJson?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    startDate?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    createdOn?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    modifiedOn?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    dueDate?: number;
    /**
     *
     * @type {string}
     * @memberof InvoiceViewModel
     */
    memo?: string | null;
    /**
     *
     * @type {number}
     * @memberof InvoiceViewModel
     */
    amount?: number;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum LocationType {
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 *
 * @export
 * @enum {string}
 */
export enum MeetingType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 *
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     *
     * @type {number}
     * @memberof ModelFile
     */
    id?: number;
    /**
     *
     * @type {User}
     * @memberof ModelFile
     */
    createdBy?: User;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    createdById?: string;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    awsUrl?: string | null;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    fileLink?: string | null;
    /**
     *
     * @type {number}
     * @memberof ModelFile
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof ModelFile
     */
    sharedOn?: string | null;
    /**
     *
     * @type {Array<ClientFile>}
     * @memberof ModelFile
     */
    clientFiles?: Array<ClientFile> | null;
}
/**
 *
 * @export
 * @interface MotivationMessageViewModel
 */
export interface MotivationMessageViewModel {
    /**
     *
     * @type {string}
     * @memberof MotivationMessageViewModel
     */
    text: string;
}
/**
 *
 * @export
 * @interface NoteViewModel
 */
export interface NoteViewModel {
    /**
     *
     * @type {number}
     * @memberof NoteViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof NoteViewModel
     */
    title?: string | null;
    /**
     *
     * @type {string}
     * @memberof NoteViewModel
     */
    description?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof NoteViewModel
     */
    isShared?: boolean;
    /**
     *
     * @type {string}
     * @memberof NoteViewModel
     */
    clientId?: string;
    /**
     *
     * @type {number}
     * @memberof NoteViewModel
     */
    createdOn?: number;
    /**
     *
     * @type {number}
     * @memberof NoteViewModel
     */
    modifiedOn?: number | null;
    /**
     *
     * @type {number}
     * @memberof NoteViewModel
     */
    sharedOn?: number | null;
}
/**
 *
 * @export
 * @interface Notice
 */
export interface Notice {
    /**
     *
     * @type {TimeSpan}
     * @memberof Notice
     */
    period?: TimeSpan;
}
/**
 *
 * @export
 * @interface NoticeViewModel
 */
export interface NoticeViewModel {
    /**
     *
     * @type {number}
     * @memberof NoticeViewModel
     */
    periodMs?: number;
}
/**
 *
 * @export
 * @interface PageLink
 */
export interface PageLink {
    /**
     *
     * @type {string}
     * @memberof PageLink
     */
    pageName?: string | null;
    /**
     *
     * @type {Coach}
     * @memberof PageLink
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof PageLink
     */
    coachId?: string;
}
/**
 *
 * @export
 * @interface PageResponseViewModel
 */
export interface PageResponseViewModel {
    /**
     *
     * @type {number}
     * @memberof PageResponseViewModel
     */
    totalCount?: number;
    /**
     *
     * @type {Array<BillingItemViewModel>}
     * @memberof PageResponseViewModel
     */
    items?: Array<BillingItemViewModel> | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PageType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 *
 * @export
 * @interface PageTypeViewModel
 */
export interface PageTypeViewModel {
    /**
     *
     * @type {number}
     * @memberof PageTypeViewModel
     */
    id?: number | null;
    /**
     *
     * @type {PageType}
     * @memberof PageTypeViewModel
     */
    pageType?: PageType;
}
/**
 *
 * @export
 * @interface PaymentItemDetailsViewModel
 */
export interface PaymentItemDetailsViewModel {
    /**
     *
     * @type {string}
     * @memberof PaymentItemDetailsViewModel
     */
    clientSecret?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof PaymentItemDetailsViewModel
     */
    isFree?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentItemStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 *
 * @export
 * @interface PaymentItemViewModel
 */
export interface PaymentItemViewModel {
    /**
     *
     * @type {string}
     * @memberof PaymentItemViewModel
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentItemViewModel
     */
    amount?: number;
    /**
     *
     * @type {PaymentItemStatus}
     * @memberof PaymentItemViewModel
     */
    status?: PaymentItemStatus;
    /**
     *
     * @type {CoachContactsViewModel}
     * @memberof PaymentItemViewModel
     */
    client?: CoachContactsViewModel;
    /**
     *
     * @type {string}
     * @memberof PaymentItemViewModel
     */
    clientId?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentItemViewModel
     */
    description?: string | null;
    /**
     *
     * @type {number}
     * @memberof PaymentItemViewModel
     */
    date?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentItemViewModel
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {ClientAppointmentInfoViewModel}
     * @memberof PaymentItemViewModel
     */
    clientAppointment?: ClientAppointmentInfoViewModel;
    /**
     *
     * @type {ClientEventInfoViewModel}
     * @memberof PaymentItemViewModel
     */
    clientEvent?: ClientEventInfoViewModel;
    /**
     *
     * @type {InvoiceViewModel}
     * @memberof PaymentItemViewModel
     */
    invoice?: InvoiceViewModel;
}
/**
 *
 * @export
 * @interface PaymentReceivedItemClientAppointment
 */
export interface PaymentReceivedItemClientAppointment {
    /**
     *
     * @type {ClientAppointment}
     * @memberof PaymentReceivedItemClientAppointment
     */
    clientAppointment?: ClientAppointment;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemClientAppointment
     */
    clientAppointmentAppointmentId?: number | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    clientAppointmentClientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemClientAppointment
     */
    amount?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    timestamp?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    clientSecret?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientAppointment
     */
    refundDate?: string | null;
}
/**
 *
 * @export
 * @interface PaymentReceivedItemClientEvent
 */
export interface PaymentReceivedItemClientEvent {
    /**
     *
     * @type {ClientEvent}
     * @memberof PaymentReceivedItemClientEvent
     */
    clientEvent?: ClientEvent;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemClientEvent
     */
    clientEventCoachEventId?: number | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    clientEventClientId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemClientEvent
     */
    amount?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    timestamp?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    clientSecret?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemClientEvent
     */
    refundDate?: string | null;
}
/**
 *
 * @export
 * @interface PaymentReceivedItemInvoice
 */
export interface PaymentReceivedItemInvoice {
    /**
     *
     * @type {UsrInvoice}
     * @memberof PaymentReceivedItemInvoice
     */
    invoice?: UsrInvoice;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemInvoice
     */
    invoiceId?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    subscriptionId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    id?: string;
    /**
     *
     * @type {number}
     * @memberof PaymentReceivedItemInvoice
     */
    amount?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    timestamp?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    paymentStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    clientSecret?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    stripePaymentIntentId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    stripeRefundId?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    refundStatus?: string | null;
    /**
     *
     * @type {string}
     * @memberof PaymentReceivedItemInvoice
     */
    refundDate?: string | null;
}
/**
 *
 * @export
 * @interface PricingPlan
 */
export interface PricingPlan {
    /**
     *
     * @type {string}
     * @memberof PricingPlan
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof PricingPlan
     */
    description?: string | null;
    /**
     *
     * @type {number}
     * @memberof PricingPlan
     */
    id?: number;
    /**
     *
     * @type {SubscriptionInfo}
     * @memberof PricingPlan
     */
    subscription?: SubscriptionInfo;
    /**
     *
     * @type {number}
     * @memberof PricingPlan
     */
    systemFeePercent?: number;
    /**
     *
     * @type {boolean}
     * @memberof PricingPlan
     */
    isActive?: boolean;
}
/**
 *
 * @export
 * @interface PricingPlanViewModel
 */
export interface PricingPlanViewModel {
    /**
     *
     * @type {number}
     * @memberof PricingPlanViewModel
     */
    id?: number;
    /**
     *
     * @type {SubscriptionInfoViewModel}
     * @memberof PricingPlanViewModel
     */
    subscription?: SubscriptionInfoViewModel;
    /**
     *
     * @type {number}
     * @memberof PricingPlanViewModel
     */
    systemFeePercent?: number;
    /**
     *
     * @type {string}
     * @memberof PricingPlanViewModel
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof PricingPlanViewModel
     */
    description?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum RefundStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 *
 * @export
 * @interface RepeatingAvailabilityItem
 */
export interface RepeatingAvailabilityItem {
    /**
     *
     * @type {DayOfWeek}
     * @memberof RepeatingAvailabilityItem
     */
    dayOfWeek?: DayOfWeek;
    /**
     *
     * @type {AvailabilityTimeSpan}
     * @memberof RepeatingAvailabilityItem
     */
    timeSpan?: AvailabilityTimeSpan;
}
/**
 *
 * @export
 * @interface RepeatingAvailabilityItemViewModel
 */
export interface RepeatingAvailabilityItemViewModel {
    /**
     *
     * @type {DayOfWeek}
     * @memberof RepeatingAvailabilityItemViewModel
     */
    dayOfWeek: DayOfWeek;
    /**
     *
     * @type {AvailabilityTimeSpanViewModel}
     * @memberof RepeatingAvailabilityItemViewModel
     */
    timeSpan: AvailabilityTimeSpanViewModel;
}
/**
 *
 * @export
 * @interface ResetPasswordViewModel
 */
export interface ResetPasswordViewModel {
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    confirmPassword?: string | null;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    token: string;
}
/**
 *
 * @export
 * @interface Role
 */
export interface Role {
    /**
     *
     * @type {Array<UserRole>}
     * @memberof Role
     */
    userRoles?: Array<UserRole> | null;
    /**
     *
     * @type {Array<RoleClaim>}
     * @memberof Role
     */
    roleClaims?: Array<RoleClaim> | null;
    /**
     *
     * @type {string}
     * @memberof Role
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Role
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof Role
     */
    normalizedName?: string | null;
    /**
     *
     * @type {string}
     * @memberof Role
     */
    concurrencyStamp?: string | null;
}
/**
 *
 * @export
 * @interface RoleClaim
 */
export interface RoleClaim {
    /**
     *
     * @type {Role}
     * @memberof RoleClaim
     */
    role?: Role;
    /**
     *
     * @type {number}
     * @memberof RoleClaim
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof RoleClaim
     */
    roleId?: string;
    /**
     *
     * @type {string}
     * @memberof RoleClaim
     */
    claimType?: string | null;
    /**
     *
     * @type {string}
     * @memberof RoleClaim
     */
    claimValue?: string | null;
}
/**
 *
 * @export
 * @interface ScheduleItemViewModel
 */
export interface ScheduleItemViewModel {
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    description: string;
    /**
     *
     * @type {MeetingType}
     * @memberof ScheduleItemViewModel
     */
    type?: MeetingType;
    /**
     *
     * @type {EventStatus}
     * @memberof ScheduleItemViewModel
     */
    status: EventStatus;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    price: number;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    timestamp: number;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    durationMs: number;
    /**
     *
     * @type {LocationType}
     * @memberof ScheduleItemViewModel
     */
    locationType: LocationType;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    location: string;
    /**
     *
     * @type {boolean}
     * @memberof ScheduleItemViewModel
     */
    showLocationWhileBooking?: boolean;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    link?: string | null;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    parentId?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof ScheduleItemViewModel
     */
    invites: Array<string>;
    /**
     *
     * @type {Array<CoachContactsExtendedViewModel>}
     * @memberof ScheduleItemViewModel
     */
    members: Array<CoachContactsExtendedViewModel>;
    /**
     *
     * @type {boolean}
     * @memberof ScheduleItemViewModel
     */
    isPrivate: boolean;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    availableSpots: number;
    /**
     *
     * @type {number}
     * @memberof ScheduleItemViewModel
     */
    remindBeforeMs: number;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    imageId: string;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    thumbnailImageId: string;
    /**
     *
     * @type {string}
     * @memberof ScheduleItemViewModel
     */
    croppedArea: string;
}
/**
 *
 * @export
 * @interface SchedulesBuffer
 */
export interface SchedulesBuffer {
    /**
     *
     * @type {TimeSpan}
     * @memberof SchedulesBuffer
     */
    before?: TimeSpan;
    /**
     *
     * @type {TimeSpan}
     * @memberof SchedulesBuffer
     */
    after?: TimeSpan;
}
/**
 *
 * @export
 * @interface SchedulesBufferViewModel
 */
export interface SchedulesBufferViewModel {
    /**
     *
     * @type {number}
     * @memberof SchedulesBufferViewModel
     */
    beforeMs?: number;
    /**
     *
     * @type {number}
     * @memberof SchedulesBufferViewModel
     */
    afterMs?: number;
}
/**
 *
 * @export
 * @interface Service
 */
export interface Service {
    /**
     *
     * @type {number}
     * @memberof Service
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Service
     */
    name?: string | null;
    /**
     *
     * @type {number}
     * @memberof Service
     */
    price?: number;
    /**
     *
     * @type {number}
     * @memberof Service
     */
    quantity?: number;
    /**
     *
     * @type {UsrInvoice}
     * @memberof Service
     */
    invoice?: UsrInvoice;
    /**
     *
     * @type {number}
     * @memberof Service
     */
    invoiceId?: number;
}
/**
 *
 * @export
 * @interface SignInInfoViewModel
 */
export interface SignInInfoViewModel {
    /**
     *
     * @type {string}
     * @memberof SignInInfoViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof SignInInfoViewModel
     */
    password: string;
}
/**
 *
 * @export
 * @interface SignUpClientViewModel
 */
export interface SignUpClientViewModel {
  /**
   *
   * @type {string}
   * @memberof SignUpClientViewModel
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof SignUpClientViewModel
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof SignUpClientViewModel
   */
  timeZone: string;
  /**
   *
   * @type {string}
   * @memberof SignUpClientViewModel
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof SignUpClientViewModel
   */
  email: string;
}
/**
 *
 * @export
 * @interface SignUpCoachViewModel
 */
export interface SignUpCoachViewModel {
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    confirmPassword: string;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {number}
     * @memberof SignUpCoachViewModel
     */
    pricingPlanId: number;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    stripePaymentMethodId?: string | null;
    /**
     *
     * @type {string}
     * @memberof SignUpCoachViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @interface SignupClientAdditionalInfoViewModel
 */
export interface SignupClientAdditionalInfoViewModel {
    /**
     *
     * @type {string}
     * @memberof SignupClientAdditionalInfoViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof SignupClientAdditionalInfoViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof SignupClientAdditionalInfoViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof SignupClientAdditionalInfoViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof SignupClientAdditionalInfoViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @interface SignupCoachAdditionalInfoViewModel
 */
export interface SignupCoachAdditionalInfoViewModel {
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    lastName: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    city: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    industry?: string | null;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    siteName: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof SignupCoachAdditionalInfoViewModel
     */
    timeZone: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SortOrder {
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 *
 * @export
 * @interface SpecificAvailabilityDate
 */
export interface SpecificAvailabilityDate {
    /**
     *
     * @type {string}
     * @memberof SpecificAvailabilityDate
     */
    timestampDate?: string;
    /**
     *
     * @type {AvailabilityTimeSpan}
     * @memberof SpecificAvailabilityDate
     */
    timeSpan?: AvailabilityTimeSpan;
}
/**
 *
 * @export
 * @interface SpecificAvailabilityDateViewModel
 */
export interface SpecificAvailabilityDateViewModel {
    /**
     *
     * @type {number}
     * @memberof SpecificAvailabilityDateViewModel
     */
    timestampDateUnixMs?: number;
    /**
     *
     * @type {AvailabilityTimeSpanViewModel}
     * @memberof SpecificAvailabilityDateViewModel
     */
    timeSpan?: AvailabilityTimeSpanViewModel;
}
/**
 *
 * @export
 * @interface StripeSubscriptionViewModel
 */
export interface StripeSubscriptionViewModel {
    /**
     *
     * @type {string}
     * @memberof StripeSubscriptionViewModel
     */
    stripePaymentMethodId?: string | null;
    /**
     *
     * @type {number}
     * @memberof StripeSubscriptionViewModel
     */
    pricingPlanId?: number;
}
/**
 *
 * @export
 * @interface SubscriptionInfo
 */
export interface SubscriptionInfo {
    /**
     *
     * @type {number}
     * @memberof SubscriptionInfo
     */
    pricingPlanId?: number;
    /**
     *
     * @type {number}
     * @memberof SubscriptionInfo
     */
    priceAmount?: number;
    /**
     *
     * @type {BillingIntervalEnum}
     * @memberof SubscriptionInfo
     */
    billingInterval?: BillingIntervalEnum;
    /**
     *
     * @type {string}
     * @memberof SubscriptionInfo
     */
    stripePricingPlanId?: string | null;
}
/**
 *
 * @export
 * @interface SubscriptionInfoViewModel
 */
export interface SubscriptionInfoViewModel {
    /**
     *
     * @type {number}
     * @memberof SubscriptionInfoViewModel
     */
    priceAmount?: number;
    /**
     *
     * @type {BillingIntervalEnum}
     * @memberof SubscriptionInfoViewModel
     */
    billingInterval?: BillingIntervalEnum;
}
/**
 *
 * @export
 * @interface Tax
 */
export interface Tax {
    /**
     *
     * @type {number}
     * @memberof Tax
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Tax
     */
    name?: string | null;
    /**
     *
     * @type {TaxType}
     * @memberof Tax
     */
    taxType?: TaxType;
    /**
     *
     * @type {number}
     * @memberof Tax
     */
    amount?: number;
    /**
     *
     * @type {number}
     * @memberof Tax
     */
    rate?: number;
    /**
     *
     * @type {Array<UsrInvoice>}
     * @memberof Tax
     */
    usrInvoices?: Array<UsrInvoice> | null;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum TaxType {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

/**
 *
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    ticks?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    days?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    hours?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    milliseconds?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    minutes?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    seconds?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    totalDays?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    totalHours?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    totalMilliseconds?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    totalMinutes?: number;
    /**
     *
     * @type {number}
     * @memberof TimeSpan
     */
    totalSeconds?: number;
}
/**
 *
 * @export
 * @interface TokenViewModel
 */
export interface TokenViewModel {
    /**
     *
     * @type {string}
     * @memberof TokenViewModel
     */
    token?: string | null;
}
/**
 *
 * @export
 * @interface UpdateFileViewModel
 */
export interface UpdateFileViewModel {
    /**
     *
     * @type {string}
     * @memberof UpdateFileViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UpdateFileViewModel
     */
    description?: string | null;
    /**
     *
     * @type {string}
     * @memberof UpdateFileViewModel
     */
    fileLink?: string | null;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    siteId?: string;
    /**
     *
     * @type {Array<UserClaim>}
     * @memberof User
     */
    claims?: Array<UserClaim> | null;
    /**
     *
     * @type {Array<UserRole>}
     * @memberof User
     */
    userRoles?: Array<UserRole> | null;
    /**
     *
     * @type {Array<UserLogin>}
     * @memberof User
     */
    logins?: Array<UserLogin> | null;
    /**
     *
     * @type {Array<UserToken>}
     * @memberof User
     */
    tokens?: Array<UserToken> | null;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    isSoftDeleted?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    isActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof User
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    userName?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    normalizedUserName?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    normalizedEmail?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    emailConfirmed?: boolean;
    /**
     *
     * @type {string}
     * @memberof User
     */
    passwordHash?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    securityStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    concurrencyStamp?: string | null;
    /**
     *
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    phoneNumberConfirmed?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    twoFactorEnabled?: boolean;
    /**
     *
     * @type {string}
     * @memberof User
     */
    lockoutEnd?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    lockoutEnabled?: boolean;
    /**
     *
     * @type {number}
     * @memberof User
     */
    accessFailedCount?: number;
}
/**
 *
 * @export
 * @interface UserClaim
 */
export interface UserClaim {
    /**
     *
     * @type {User}
     * @memberof UserClaim
     */
    user?: User;
    /**
     *
     * @type {number}
     * @memberof UserClaim
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UserClaim
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UserClaim
     */
    claimType?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserClaim
     */
    claimValue?: string | null;
}
/**
 *
 * @export
 * @interface UserLogin
 */
export interface UserLogin {
    /**
     *
     * @type {User}
     * @memberof UserLogin
     */
    user?: User;
    /**
     *
     * @type {string}
     * @memberof UserLogin
     */
    loginProvider?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserLogin
     */
    providerKey?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserLogin
     */
    providerDisplayName?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserLogin
     */
    userId?: string;
}
/**
 *
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     *
     * @type {User}
     * @memberof UserRole
     */
    user?: User;
    /**
     *
     * @type {Role}
     * @memberof UserRole
     */
    role?: Role;
    /**
     *
     * @type {string}
     * @memberof UserRole
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UserRole
     */
    roleId?: string;
}
/**
 *
 * @export
 * @interface UserToken
 */
export interface UserToken {
    /**
     *
     * @type {User}
     * @memberof UserToken
     */
    user?: User;
    /**
     *
     * @type {string}
     * @memberof UserToken
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof UserToken
     */
    loginProvider?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserToken
     */
    name?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserToken
     */
    value?: string | null;
}
/**
 *
 * @export
 * @interface UserViewModel
 */
export interface UserViewModel {
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    lastName: string;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    registrationCompleted: boolean;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    siteName: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    timeZone: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    email: string;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    isStripeConnected: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    wasStripeConnected: boolean;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    stripeUser?: string | null;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    country: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    state: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    city: string;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    isZoomConnected?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    zoomUserEmail?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    isGoogleConnected?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    googleUser?: string;
    /**
     *
     * @type {string}
     * @memberof UserViewModel
     */
    imageId: string;
    /**
     *
     * @type {boolean}
     * @memberof UserViewModel
     */
    isAdmin?: boolean;
}
/**
 *
 * @export
 * @interface UsrInvoice
 */
export interface UsrInvoice {
    /**
     *
     * @type {number}
     * @memberof UsrInvoice
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof UsrInvoice
     */
    amount?: number;
    /**
     *
     * @type {InvoiceStatus}
     * @memberof UsrInvoice
     */
    status?: InvoiceStatus;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    refNumber?: string | null;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    coachId?: string | null;
    /**
     *
     * @type {Coach}
     * @memberof UsrInvoice
     */
    coach?: Coach;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    clientId?: string | null;
    /**
     *
     * @type {Client}
     * @memberof UsrInvoice
     */
    client?: Client;
    /**
     *
     * @type {Array<Service>}
     * @memberof UsrInvoice
     */
    services?: Array<Service> | null;
    /**
     *
     * @type {number}
     * @memberof UsrInvoice
     */
    taxId?: number | null;
    /**
     *
     * @type {Tax}
     * @memberof UsrInvoice
     */
    tax?: Tax;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    startDate?: string;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    dueDate?: string;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    siteCustomizationJson?: string | null;
    /**
     *
     * @type {boolean}
     * @memberof UsrInvoice
     */
    deletedForCoach?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsrInvoice
     */
    deletedForClient?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UsrInvoice
     */
    isLetterSent?: boolean;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    memo?: string | null;
    /**
     *
     * @type {InvoiceType}
     * @memberof UsrInvoice
     */
    type?: InvoiceType;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    stripeProductId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    stripePriceId?: string | null;
    /**
     *
     * @type {BillingIntervalEnum}
     * @memberof UsrInvoice
     */
    durationRepeating?: BillingIntervalEnum;
    /**
     *
     * @type {number}
     * @memberof UsrInvoice
     */
    countRepeating?: number;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    subscriptionId?: string | null;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    paymentMethodId?: string | null;
    /**
     *
     * @type {Array<PaymentReceivedItemInvoice>}
     * @memberof UsrInvoice
     */
    paymentItems?: Array<PaymentReceivedItemInvoice> | null;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    createdOn?: string;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    createdBy?: string;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    modifiedOn?: string;
    /**
     *
     * @type {string}
     * @memberof UsrInvoice
     */
    modifiedBy?: string;
}
/**
 *
 * @export
 * @interface ValidateTokenViewModel
 */
export interface ValidateTokenViewModel {
    /**
     *
     * @type {string}
     * @memberof ValidateTokenViewModel
     */
    email?: string | null;
    /**
     *
     * @type {string}
     * @memberof ValidateTokenViewModel
     */
    token?: string | null;
}

