import React, { useState, useEffect, useCallback } from 'react';
import { iCoachPageCustomizationData } from '../../core/types';
import { CoachAccountViewModel } from '../../core/backend/api';
import NotificationStore from '../../store/NotificationStore';
import { useMedia } from '../../hooks';

import CustomizeBioTab from '../CustomizeSidebar/CustomizeBioTab';
import CustomizeSidebarMenu from '../CustomizeSidebar/CustomizeSidebarMenu';
import CustomizeStylesTab from '../CustomizeSidebar/CustomizeStylesTab';
import CustomizeSocialLinksTab from '../CustomizeSidebar/CustomizeSocialLinksTab';
import CustomizeSidebar from '../CustomizeSidebar/CustomizeSidebar';

import BioIcon from '../../icons/bio.svg';
import StyleIcon from '../../icons/style.svg';
import LinksIcon from '../../icons/links.svg';

import { getDefaultData } from '../../components/CustomizeSidebar/CustomizeSidebar.helpers';

interface iProps {
  data: iCoachPageCustomizationData;
  updateViewData: (arg0: iCoachPageCustomizationData) => void;
  coachAccountData: CoachAccountViewModel;
  handleSave: () => void;
  handleView: () => void;
  notificationsStore?: NotificationStore;
  updateSocialEmail: (arg0: string) => void;
  isValidationShown: boolean;
}

const CoachPageCustomizeSidebar: React.FC<iProps> = ({
  data,
  updateViewData,
  coachAccountData,
  handleSave,
  handleView,
  updateSocialEmail,
  isValidationShown
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<number>(0);
  const isTablet = useMedia(['(min-width: 992px)'], [false], true);
  const [isOpen, setIsOpen] = useState<boolean>(!isTablet);

  useEffect(() => {
    if (!isTablet) {
      setIsOpen(true);
    }
  }, [isTablet]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleChangeField = useCallback(
    (field: string) => (value: any) => {
      updateViewData({
        ...data,
        [field]: value
      });
    },
    [data, updateViewData]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSave();
  };

  const renderActiveTab = () => {
    switch (selectedMenuItem) {
      case 0:
        return (
          <CustomizeSidebarMenu
            onMenuItemSelect={setSelectedMenuItem}
            className='d-flex'
            menuList={tabsMenuList}
          />
        );
      case 1:
        return (
          <CustomizeBioTab data={data} handleChangeField={handleChangeField} />
        );
      case 2:
        return (
          <CustomizeStylesTab
            data={data}
            onChangeField={handleChangeField}
            onResetStyles={resetStylesCustomizaton}
          />
        );
      case 3:
        return (
          <CustomizeSocialLinksTab
            data={data}
            handleChangeField={handleChangeField}
            updateSocialEmail={updateSocialEmail}
            isValidationShown={isValidationShown}
            coachAccountData={coachAccountData}
          />
        );
      default:
        return null;
    }
  };

  const handleTabClose = () => setSelectedMenuItem(0);

  const resetStylesCustomizaton = () => {
    updateViewData({
      ...getDefaultData(),
      position: data.position,
      bio: data.bio,
      socialLinks: data.socialLinks,
      logoSrc: data.logoSrc
    });
  };

  return (
    <CustomizeSidebar
      onSubmit={handleSubmit}
      isOpen={isOpen}
      onToggle={handleToggle}
      coachAccountData={coachAccountData}
      onTabClose={handleTabClose}
      tabName={tabsMenuList.find((item) => selectedMenuItem === item.id)?.name}
      handleView={handleView}
      selectedMenuItem={selectedMenuItem}
    >
      {renderActiveTab()}
    </CustomizeSidebar>
  );
};

export const tabsMenuList = [
  {
    id: 1,
    name: 'Bio',
    hint: 'Edit your bio',
    icon: <BioIcon />
  },
  {
    id: 2,
    name: 'Styles & Images',
    hint: 'Colors edit',
    icon: <StyleIcon />
  },
  {
    id: 3,
    name: 'Links',
    hint: 'Edit or add social links',
    icon: <LinksIcon />
  }
];

export default CoachPageCustomizeSidebar;
