import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { CusomizeSidebarMenuItem } from './CustomizeSidebar.helpers';

interface Props {
  onMenuItemSelect: (id: number) => void;
  className?: string;
  menuList: CusomizeSidebarMenuItem[];
}

const CustomizeSidebarMenu: React.FC<Props> = ({
  className = '',
  onMenuItemSelect,
  menuList
}) => {
  return (
    <div className={`CustomizeSidebar__tab d-flex ${className}`}>
      <Scrollbars style={{ height: 'auto', minHeight: '100%' }}>
        <div className='CustomizeSidebar__menu'>
          {menuList.map((item) => {
            return (
              <div
                key={item.id}
                className='CustomizeSidebar__menu-item'
                onClick={() => onMenuItemSelect(item.id)}
              >
                <div className='CustomizeSidebar__menu-item-icon'>
                  {item.icon}
                </div>
                <div className='CustomizeSidebar__menu-item-text'>
                  <div className='CustomizeSidebar__menu-item-name'>
                    {item.name}
                  </div>
                  <div className='CustomizeSidebar__menu-item-hint'>
                    {item.hint}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default CustomizeSidebarMenu;
