import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import { getNotesClient } from '../../core/api';
import { NoteViewModel } from '../../core/backend/api';
import { searchBy, sortArrayByTimestampDesc } from '../../core/helpers';
import Note from '../../components/Note/Note';
import ClientLibrary from '../../containers/ClientLibrary/ClientLibrary';
import EmptyState from '../../components/EmptyState/EmptyState';
import EmptyIcon from '../../icons/notes_large.svg';
import SearchInput from '../../components/SearchInput/SearchInput';
import './ClientLibraryNotes.styles.scss';
import { useSearchInput } from '../../components/SearchInput/useSearchInput';

const ClientLibraryNotes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteViewModel[]>([]);
  const {
    searchText,
    handleSearch,
    searchedItems: searchedNotes
  } = useSearchInput(
    notes,
    (searchString, note) =>
      searchBy(note.title, searchString) ||
      searchBy(note.description, searchString)
  );

  useEffect(() => {
    setIsLoading(true);
    getNotesClient()
      .then((res) => {
        setNotes(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <ClientLibrary title='Notes' className='ClientLibraryNotes'>
      {isLoading && <Loader />}
      <div className='d-flex justify-content-sm-end'>
        <SearchInput onSearch={handleSearch} searchText={searchText} />
      </div>
      {!isLoading && searchedNotes.length > 0 ? (
        <div className='ClientLibraryNotes__list flex-grow-1'>
          {searchedNotes
            .sort(
              sortArrayByTimestampDesc<NoteViewModel>('createdOn', 'modifiedOn')
            )
            .map((note) => (
              <Note
                note={note}
                key={note.id}
                accordionClassName='AccordionButton--ClientLibraryNotes'
                breakpoint='sm'
              />
            ))}
        </div>
      ) : (
        <EmptyState
          icon={<EmptyIcon />}
          text='There are no notes here'
          className='mt-5'
        />
      )}
    </ClientLibrary>
  );
};

export default ClientLibraryNotes;
