import React, { useState, useEffect, useRef } from 'react';
import './FileUploader.styles.scss';
import {
  formatFileSize,
  messageFormats,
  readURLasBASE64
} from './FileUploader.helpers';
import BucketIcon from '../../icons/bucket.svg';
import { uploadPublicFile } from '../../core/api';
import DropdownMenu, { ListItemProps } from '../DropdownMenu/DropdownMenu';
import PencilIcon from '../../icons/pencil.svg';
import Loader from '../Loader/Loader';
import { getCroppedFile } from '../ImageCropper/ImageCropper.helpers';

interface iProps {
  className?: string;
  showPreview: boolean;
  multiple?: boolean;
  isDisabled?: boolean;
  withHint?: boolean;
  isImage?: boolean;
  recomendedRatio?: string;
  description?: string;
  fileTypes: string[];
  fileMaxSize: number;
  children: React.ReactElement;
  onFileUploaded?: (fileName: string) => void;
  onFileSelected?: (file: File) => void;
  isResponsive?: boolean;
  ActionsRenderer?: (menuList: ListItemProps[]) => JSX.Element;
}

export const FileUploaderInner: React.FC<{
  isResponsive: boolean;
  children: React.ReactElement;
}> = ({ children, isResponsive }) => {
  return isResponsive ? (
    <div className='FileUploader__inner'>{children}</div>
  ) : (
    children
  );
};

const FileUploader: React.FC<iProps> = ({
  className = '',
  children,
  showPreview,
  fileTypes,
  fileMaxSize,
  isImage = false,
  recomendedRatio = '960 x 540',
  multiple,
  description,
  isDisabled,
  onFileUploaded,
  onFileSelected,
  withHint = true,
  isResponsive = false,
  ActionsRenderer,
  ...rest
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [supportedType, setSupportedType] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id] = useState<number>(Date.now());

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const accept = supportedType.map((t) => '.' + t).join(', ');

  useEffect(() => {
    setSupportedType([
      ...fileTypes,
      ...(fileTypes.includes('jpeg') ? ['jpg'] : [])
    ]);
  }, [fileTypes]);

  const handleRemove = () => {
    if (onFileSelected) {
      onFileSelected(null);
    }
    if (onFileUploaded) {
      onFileUploaded('');
    }
    setErrorMessage('');
  };

  const handleEdit = () => {
    inputFileRef.current?.click();
  };

  const menuList: ListItemProps[] = [
    {
      name: 'Edit',
      callback: handleEdit,
      icon: <PencilIcon />
    },
    {
      name: 'Delete',
      callback: handleRemove,
      icon: <BucketIcon />
    }
  ];

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (!isDisabled && !isDragOver) {
      event.dataTransfer.dropEffect = 'copy';
      setIsDragOver(true);
    }
    preventAndStop(event);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    if (!isDisabled && isDragOver) {
      setIsDragOver(false);
    }
    preventAndStop(event);
  };

  const onDropFiles = (event: React.DragEvent<HTMLDivElement>) => {
    if (!isDisabled) {
      setIsDragOver(false);
      const files = [];
      for (var i = 0; i < event.dataTransfer.files.length; i++) {
        files.push(event.dataTransfer.files[i]);
      }
      onSelectFiles(files);
    }
    preventAndStop(event);
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = [];
    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
      }
      event.target.value = '';
      onSelectFiles(files);
    }
    preventAndStop(event);
  };

  const isValidFileType = (fileName: string) => {
    const idxDot = fileName.lastIndexOf('.') + 1;
    const ext = fileName.substr(idxDot, fileName.length).toLowerCase();
    return supportedType.includes(ext);
  };

  const isValidFileSize = (file: File) => {
    return file.size <= fileMaxSize;
  };

  const validateFile = (file: File) => {
    if (!isValidFileSize(file) || !isValidFileType(file.name)) {
      setErrorMessage('File isn’t uploaded. Check file format and size');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const onSelectFiles = async (newFiles: File[]) => {
    if (validateFile(newFiles[0])) {
      if (onFileSelected) {
        onFileSelected(newFiles[0]);
      } else if (onFileUploaded) {
        setIsLoading(true);
        try {
          let file = newFiles[0];
          if (isImage) {
            const base64 = await readURLasBASE64(newFiles[0]);
            file = await getCroppedFile(
              {
                croppedAreaPixels: { x: 0, y: 0 },
                rotation: 0,
                imgSrc: base64
              },
              parseInt(recomendedRatio.split(' ')[0]) || 960
            );
          }
          const data = await uploadPublicFile(file);
          onFileUploaded(data.createdFileName);
        } catch (e) {
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const preventAndStop = (
    event:
      | Event
      | React.DragEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div
      className={`${className} DropArea ${isDragOver ? 'DropArea__over' : ''} ${
        showPreview ? 'DropArea__preview' : ''
      } ${isDisabled ? 'DropArea--disabled' : ''} ${
        errorMessage ? 'DropArea--error' : ''
      }`}
      onDrop={onDropFiles}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      {...rest}
    >
      <label htmlFor={'fileUpload' + id} className='FileUploader__label'>
        <input
          ref={inputFileRef}
          type='file'
          id={'fileUpload' + id}
          accept={accept}
          disabled={isDisabled}
          multiple={multiple}
          className='FileUploader__input'
          onChange={onFileChange}
          tabIndex={1}
        />
      </label>
      <FileUploaderInner isResponsive={isResponsive}>
        <>
          {children}
          {errorMessage && (
            <p className='FileUploader__error'>{errorMessage}</p>
          )}
          {withHint && (
            <>
              <p className={'FileUploader__description'}>{description}</p>
              <p className='FileUploader__text'>
                You can upload {messageFormats(fileTypes)} formats with a
                maximum size up to {formatFileSize(fileMaxSize)}{' '}
                {isImage &&
                  `and recommended
                dimension of ${recomendedRatio}`}
              </p>
            </>
          )}
          {showPreview &&
            (ActionsRenderer ? (
              ActionsRenderer(menuList)
            ) : (
              <DropdownMenu
                list={menuList}
                className='FileUploader__dropdown'
              />
            ))}
          {isLoading && <Loader />}
        </>
      </FileUploaderInner>
    </div>
  );
};

export default FileUploader;
