import React from 'react';
import './ClientPageHeader.styles.scss';
import { useLocation, useHistory } from 'react-router-dom';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { getPublicFile } from '../../core/api';
import { ClientRoutesEnum } from '../../core/enums';
import { getRoutePath } from '../../core/globals';
import { UserViewModel } from '../../core/backend/api';

interface iProps {
  userData: Partial<UserViewModel>;
  logoImg: string;
  hideMenuButton: boolean;
  sideMenuOpened: boolean;
  onSideMenuToggle: (arg0: boolean) => void;
  onLogout: () => void;
}

const ClientPageHeader: React.FC<iProps> = ({
  userData,
  logoImg,
  hideMenuButton,
  sideMenuOpened,
  onSideMenuToggle,
  onLogout
}) => {
  const location = useLocation();
  const history = useHistory();

  const toAccountPage = () => {
    if (location.pathname !== getRoutePath(ClientRoutesEnum.Account)) {
      history.push(getRoutePath(ClientRoutesEnum.Account));
    }
  };

  return (
    <div className='ClientPageHeader py-2 px-md-4 px-sm-3 px-2'>
      <div className='ClientPageHeader__column'>
        <div
          className={`ClientPageHeader__menu d-lg-none mt-2 ${
            sideMenuOpened ? 'ClientPageHeader__menu--cross' : ''
          } ${hideMenuButton ? 'd-none' : ''}`}
          onMouseDown={() => onSideMenuToggle(!sideMenuOpened)}
        >
          <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--one' />
          <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--two' />
          <div className='ClientPageHeader__menu-line ClientPageHeader__menu-line--three' />
        </div>
      </div>

      {logoImg ? (
        <img src={getPublicFile(logoImg)} className='CoachPage__logo' alt='' />
      ) : null}
      <div className='ClientPageHeader__column'>
        <DropdownMenu
          list={[
            {
              name: 'My Account',
              callback: toAccountPage
            },
            {
              name: 'Logout',
              callback: onLogout
            }
          ]}
          className='DropdownMenu--logout'
          withPortal={false}
        >
          <div className='ClientPageHeader__user-circle'>
            {userData.imageId ? (
              <img src={getPublicFile(userData.imageId)} alt='Avatar' />
            ) : (
              `${userData.firstName.charAt(0)}${userData.lastName.charAt(0)}`
            )}
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default ClientPageHeader;
