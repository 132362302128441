import React, { useState } from 'react';
import { makeEnding, toggleInArray } from '../../../core/helpers';
import Days from '../../../core/data/Days';
import DatePicker from '../../DatePicker/DatePicker';
import Button from '../../../components/Button/Button';
import FormCheckbox from '../../../components/FormCheckbox/FormCheckbox';
import FormRadio from '../../../components/FormRadio/FormRadio';
import { DayOfWeek } from '../../../core/backend/api';

interface iProps {
  applyToDates: (dates: number[]) => void;
  applyToDays: (days: DayOfWeek[]) => void;
  day: DayOfWeek;
  date: number;
  cancel: () => void;
}

const ApplyToMultiple: React.FC<iProps> = ({
  applyToDates,
  applyToDays,
  date,
  day,
  cancel
}) => {
  const [applyType, setApplyType] = useState('day');
  const [dates, setDates] = useState([date]);
  const [days, setDays] = useState([day]);

  const handleApply = () => {
    if (applyType === 'date') {
      applyToDates(dates);
    } else {
      applyToDays(days);
    }
  };

  const toggleDay = (day: DayOfWeek) => {
    setDays(toggleInArray(days, day));
  };

  return (
    <div className='ManageAvailabilityIntervalsModal__step'>
      <div className='ManageAvailabilityIntervalsModal__input-outer'>
        <FormRadio
          id='dates'
          checked={applyType === 'date'}
          handleChange={() => setApplyType('date')}
          label='specific dates'
          data-testid='specific-dates-days-radio-btn'
        />
      </div>
      <div className='ManageAvailabilityIntervalsModal__input-outer'>
        <FormRadio
          id='days'
          checked={applyType === 'day'}
          handleChange={() => setApplyType('day')}
          label='repeating days of the week'
          data-testid='repeating-days-of-the-week-radio-btn'
        />
      </div>
      {applyType === 'day' &&
        Days.map((day, index) => (
          <div
            key={index}
            className='ManageAvailabilityIntervalsModal__input-outer'
          >
            <FormCheckbox
              id={`day_${day.value}`}
              checked={days.includes(day.value)}
              handleChange={() => toggleDay(day.value)}
              label={makeEnding(day.title)}
              isSquare
              testIdPrefix={`day_${day.value}`}
            />
          </div>
        ))}
      {applyType === 'date' && (
        <div className='ManageAvailabilityIntervalsModal__daypicker-wrapper'>
          <DatePicker
            value={dates}
            onChange={(data) => setDates(data)}
            isMultiple
            data-testid='specific-dates-daypicker'
          />
        </div>
      )}
      <div className='ManageAvailabilityIntervalsModal__footer row'>
        <div className='col-12 col-sm-6 mb-3 mb-sm-0 pr-sm-2'>
          <Button
            handleClick={cancel}
            className='button__inversed button__stretch'
            data-testid='cancel-multiple-dates-btn'
          >
            Cancel
          </Button>
        </div>
        <div className='col-12 col-sm-6 pl-sm-2'>
          <Button
            handleClick={handleApply}
            className='button__stretch'
            data-testid='apply-multiple-dates-btn'
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplyToMultiple;
