import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FormInput from '../../components/FormInput/FormInput';
import { ChangePasswordViewModel } from '../../core/backend/api';
import {
  isPasswordsInputValid,
  getPasswordsInputErrors,
  isPasswordsDataValid,
  getDefaultData,
  renderPasswordReminder
} from './ChangePasswordForm.helpers';
import './ChangePasswordForm.styles.scss';

interface Props {
  email: string;
  onSave: (newData: ChangePasswordViewModel) => Promise<unknown>;
  className?: string;
}

const ChangePasswordForm: React.FC<Props> = ({
  email,
  className = '',
  onSave
}) => {
  const [localData, setLocalData] = useState(getDefaultData());
  const [isValidationShown, setIsValidationShown] = useState(false);
  const [isPasswordChangeFailed, setIsPasswordChangeFailed] = useState(false);

  const handleChangeField = (field: string) => (value: any) => {
    setLocalData({
      ...localData,
      [field]: value
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPasswordsDataValid(localData)) {
      setIsValidationShown(false);
      setIsPasswordChangeFailed(false);
      onSave({
        email: email,
        currentPassword: localData.oldPassword.trim(),
        newPassword: localData.repeatNewPassword
      })
        .then(() => {
          setLocalData(getDefaultData());
        })
        .catch(() => {
          setIsPasswordChangeFailed(true);
        });
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <form className={`ChangePasswordForm ${className}`} onSubmit={handleSubmit}>
      <div className='col-lg-8 col-md-9 col-sm-12 offset-lg-3 offset-md-3 px-2'>
        <div className='ChangePasswordForm__title'>Change password</div>
        <div className='ChangePasswordForm__text mt-2'>
          {renderPasswordReminder}
        </div>
        {/* fake form inputs to enable autoComplete */}
        <div style={{ display: 'none' }}>
          <label htmlFor='email'>email</label>
          <input value={email} name='email' type='email' readOnly />
        </div>
        {/* end of fake form inputs to enable autoComplete */}
        <div className='row mx-n2'>
          <div className='col-sm-4 px-2'>
            <FormInput
              label='Current'
              name='oldPassword'
              type='password'
              value={localData.oldPassword}
              isInvalid={
                isValidationShown &&
                !isPasswordsInputValid('oldPassword', localData)
              }
              errorMessage={
                getPasswordsInputErrors('oldPassword', localData)[0]
              }
              handleChange={handleChangeField('oldPassword')}
              data-testid='old-password'
            />
          </div>
          <div className='col-sm-4 px-2'>
            <FormInput
              type='password'
              label='New'
              name='new_password'
              value={localData.newPassword}
              isInvalid={
                isValidationShown &&
                !isPasswordsInputValid('newPassword', localData)
              }
              errorMessage={
                getPasswordsInputErrors('newPassword', localData)[0]
              }
              handleChange={handleChangeField('newPassword')}
              data-testid='new-password'
            />
          </div>
          <div className='col-sm-4 px-2'>
            <FormInput
              type='password'
              label='Confirm'
              name='repeat_new_password'
              value={localData.repeatNewPassword}
              isInvalid={
                isValidationShown &&
                !isPasswordsInputValid('repeatNewPassword', localData)
              }
              handleChange={handleChangeField('repeatNewPassword')}
              data-testid='confirm-new-password'
            />
          </div>
        </div>
        <ErrorMessage isVisible={isPasswordChangeFailed}>
          Changing password failed, please check your credentials
        </ErrorMessage>
        <div className='d-flex justify-content-end mt-4'>
          <Button
            className='button__account-save'
            data-testid='change-password-save-button'
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ChangePasswordForm;
