import React from 'react';
import { useLocation } from 'react-router-dom';
import PageErrorIcon from '../../icons/page-error-icon.svg';
import { errors } from './ErrorPage.helpers';
import './ErrorPage.styles.scss';

const ErrorPage = () => {
  const { pathname } = useLocation();
  const errorStatus = pathname.replace('/error/', '');
  const error = errors[+errorStatus];

  const renderStatus = () => {
    return errorStatus.split('').map((e, index) => {
      if (+e === 0) {
        return (
          <span key={index} className='ErrorPage__icon'>
            <PageErrorIcon />
          </span>
        );
      }
      return +e;
    });
  };

  return (
    <div className='ErrorPage p-3'>
      <div className='ErrorPage__content'>
        <div className='ErrorPage__status'>{renderStatus()}</div>
        {!!error ? (
          <>
            <p className='ErrorPage__title'>{error.title}</p>
            <p>{error.description}</p>
          </>
        ) : (
          <p className='ErrorPage__title'>Something went wrong</p>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
