import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import NewPassword, { iNewPasswordData } from '../../NewPassword/NewPassword';
import { sendClientNewPasswordRequest } from '../../../core/api';
import GlobalLoader from '../../GlobalLoader/GlobalLoader';
import { SignInInfoViewModel } from '../../../core/backend/api';
interface iProps {
  close: () => void;
  isOpened: boolean;
  data: iNewPasswordData;
  onError: () => void;
  onSucces: (data: SignInInfoViewModel) => void;
}

const ClientNewPasswordModal: React.FC<iProps> = ({
  close,
  isOpened,
  data,
  onError,
  onSucces
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const request = async (newPass: string, repeatPass: string) => {
    setIsLoading(true);
    try {
      await sendClientNewPasswordRequest({
        ...data,
        password: newPass,
        confirmPassword: repeatPass
      });
      onSucces({ email: data.email.trim(), password: newPass });
    } catch (event) {
      if (event.errors.InvalidToken.length) {
        onError();
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal close={close} isOpened={isOpened} style={{ width: 440 }}>
      <GlobalLoader isLoading={isLoading} />
      <NewPassword sendRequest={request} buttonTitle='Save & Sing in' />
    </Modal>
  );
};

export default ClientNewPasswordModal;
