import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDebounce } from './useDebounce';

export function useSearch(initialValue: string, delay = 300, minLength = 2) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const searchValue = params.get('search');

  const [searchText, setSearchText] = useState<string>(
    searchValue || initialValue
  );
  const trimmed = searchText.trim();
  const debouncedSearchText = useDebounce(
    trimmed.length >= minLength ? trimmed : '',
    delay
  );

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('search') !== debouncedSearchText) {
      if (!!debouncedSearchText) {
        params.set('search', debouncedSearchText);
      } else {
        params.delete('search');
      }
      history.replace(pathname + '?' + params.toString());
    }
  }, [history, pathname, search, debouncedSearchText]);

  const onSearchChange = (value: string) => {
    setSearchText(value);
  };
  return {
    debouncedSearchText,
    searchText,
    onSearchChange
  };
}
