import React from 'react';
import './StepsVisualizer.styles.scss';

interface SVProps {
  stepsTotal: number;
  currentStep: number;
  className?: string;
}

const StepsVisualizer: React.FC<SVProps> = ({
  currentStep,
  stepsTotal,
  className = ''
}) => {
  const steps = Array.from(Array(stepsTotal + 1).keys());
  steps.shift();
  return (
    <div className={`StepsVisualizer ${className}`}>
      {steps.map((step) => {
        const stepClass = new Set([
          'StepsVisualizer__step',
          step === currentStep ? 'StepsVisualizer__step--current' : '',
          step < currentStep ? 'StepsVisualizer__step--complete' : ''
        ]);
        return <div key={step} className={Array.from(stepClass).join(' ')} />;
      })}
    </div>
  );
};

export default StepsVisualizer;
