import React, { useEffect, useState } from 'react';
import './ClientCalendar.styles.scss';
import ClientSchedulePage from '../../containers/ClientSchedulePage/ClientSchedulePage';
import { BookingItemTypeEnum, iBookingItem } from '../../core/types';
import {
  StripeRefundStatusEnum,
  EventStatus,
  NotificationTypesEnum
} from '../../core/enums';
import {
  cancelClientAppointment,
  cancelClientEvent,
  getClientBookingItemsWithMember
} from '../../core/api';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import ClientScheduleTile from '../../components/ClientScheduleTile/ClientScheduleTile';
import moment from 'moment';
import { observer } from 'mobx-react';
import EmptyState from '../../components/EmptyState/EmptyState';
import CancelIcon from '../../icons/cancel-icon.svg';
import { useDebouncedCallback, useStores } from '../../hooks';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import InfoBlockModal from '../../components/Modals/InfoBlockModal/InfoBlockModal';
import { iInfoBlockData } from '../../components/InfoBlock/InfoBlock';
import { getInfoBlockData } from '../../components/Modals/InfoBlockModal/InfoBlockModal.helpers';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import PromptModal from '../../components/Modals/PromptModal/PromptModal';
import { ERROR_MESSAGES } from '../../core/errorMessages';

interface ConfirmationData {
  title: string;
  text: string;
  callback: () => void;
}

const ClientCalendar = () => {
  const { rootStore } = useStores();
  const { userStore, notificationStore, coachInfo } = rootStore;
  const [data, setData] = useState<iBookingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [infoBlockData, setInfoBlockData] = useState<iInfoBlockData>(null);
  const [isViewPopupOpened, setIsViewPopupOpened] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState<boolean>(
    false
  );
  const [isRefundPromptModalOpened, setIsRefundPromptModalOpened] = useState(
    false
  );
  const [confirmationModalData, setConfirmationModalData] = useState<
    ConfirmationData
  >(null);
  const [activeItem, setActiveItem] = useState<iBookingItem>();

  const setIsConfirmationDone = useDebouncedCallback(() => {
    setConfirmationModalData(null);
    setIsSubmitting(false);
  }, 450);

  useEffect(() => {
    reFetchData();
  }, [rootStore.changesCounter]);

  const reFetchData = () => {
    setIsLoading(true);
    const date = moment.utc();

    getClientBookingItemsWithMember(date.clone().valueOf()).then((response) => {
      response.sort((a, b) => {
        return a.timestamp! <= b.timestamp! ? -1 : 1;
      });
      setData(response);
      setIsLoading(false);
    });
  };

  const handleCancel = (item: iBookingItem) => () => {
    const isMeeting =
      item.type === BookingItemTypeEnum.Meeting && !item.isScheduled;
    const entity = isMeeting ? 'meeting' : 'appointment';
    setIsViewPopupOpened(false);
    setIsConfirmModalOpened(true);
    setConfirmationModalData({
      title: `Cancel ${entity}`,
      text: `Are you sure you want to cancel this ${entity}${
        item.hasPrice && item.refundStatus !== StripeRefundStatusEnum.Succeeded
          ? ' and get refunded'
          : ''
      }?`,
      callback: () => confirmCancel(item)
    });
  };

  const confirmCancel = (item: iBookingItem) => {
    setIsSubmitting(true);
    const update =
      item.type === BookingItemTypeEnum.Meeting
        ? cancelClientEvent
        : cancelClientAppointment;

    update(item.id)
      .then(() => {
        rootStore.incrementChangesCounter();
        setIsConfirmModalOpened(false);
      })
      .catch((err) => {
        if (err.status === 400) {
          if (err.detail === 'RefundFailed') {
            setIsRefundPromptModalOpened(true);
            setActiveItem(item);
            rootStore.incrementChangesCounter();
            setIsConfirmModalOpened(false);
          } else {
            const message =
              err.detail === 'AlreadyRefunded'
                ? ERROR_MESSAGES.AlreadyRefundedEvent
                : ERROR_MESSAGES.SomethingWentWrong;
            notificationStore.addNotification({
              type: NotificationTypesEnum.Error,
              text: 'Refund failed',
              textColored: message
            });
          }
        }
      })
      .finally(() => {
        setIsConfirmationDone();
      });
  };

  const handleCalendarItemView = (data: iBookingItem) => {
    setIsViewPopupOpened(true);
    setInfoBlockData(getInfoBlockData(data, userStore.timeZone));
    setActiveItem(data);
  };

  const entity =
    activeItem?.type === BookingItemTypeEnum.Meeting && !activeItem?.isScheduled
      ? 'meeting'
      : 'appointment';

  return (
    <ClientSchedulePage title='Your calendar'>
      <GlobalLoader isLoading={isLoading} />
      <div className='ClientCalendar'>
        {data.length > 0 && (
          <>
            {data.map((item, index) => (
              <ClientScheduleTile
                key={index}
                data={item}
                menu={
                  item.status !== EventStatus.Canceled && [
                    {
                      icon: <CancelIcon />,
                      name:
                        item.hasPrice &&
                        item.refundStatus !== StripeRefundStatusEnum.Succeeded
                          ? 'Cancel & Ask Refund'
                          : "I can't attend",
                      callback: handleCancel(item)
                    }
                  ]
                }
                hasLink
                onView={handleCalendarItemView}
              />
            ))}
          </>
        )}
        {!data.length && !isLoading && (
          <EmptyState
            text='You have no booked meetings or appointments yet'
            absoluteCenter={true}
          />
        )}
      </div>
      <ConfirmModal
        isOpened={isConfirmModalOpened}
        close={() => setIsConfirmModalOpened(false)}
        title={confirmationModalData && confirmationModalData.title}
        text={confirmationModalData && confirmationModalData.text}
        disableConfirmBtn={isSubmitting}
        confirmBtnText='Yes'
        cancelBtnText='No'
        confirmCallback={
          confirmationModalData && confirmationModalData.callback
        }
        confirmBtnClass='button__confirm-delete'
      />
      <PromptModal
        text={
          <>
            The {entity} is canceled, but we are unable to process refund for
            this {entity} automatically.
            <br /> Please, contact your coach at{' '}
            <a className='link' href={`mailto:${coachInfo?.supportEmail}`}>
              {coachInfo?.supportEmail}
            </a>
          </>
        }
        title='Automatic refund failed'
        isOpened={isRefundPromptModalOpened}
        close={() => setIsRefundPromptModalOpened(false)}
        btnText='Close'
      />
      <InfoBlockModal
        isOpened={isViewPopupOpened}
        close={() => setIsViewPopupOpened(false)}
        data={infoBlockData}
        actions={
          isViewPopupOpened && (
            <div className='flex-grow-1 ml-sm-3 ml-2 d-flex justify-content-end'>
              <DropdownMenu
                list={[
                  {
                    icon: <CancelIcon />,
                    name:
                      infoBlockData && +infoBlockData.price > 0
                        ? activeItem.refundStatus ===
                          StripeRefundStatusEnum.Succeeded
                          ? 'Cancel'
                          : 'Cancel & Ask Refund'
                        : `I can't attend`,
                    callback: handleCancel(activeItem)
                  }
                ]}
                buttonClassName='CZ__dropdown-button'
              />
            </div>
          )
        }
      />
    </ClientSchedulePage>
  );
};

export default observer(ClientCalendar);
