import React, { useCallback, useEffect, useState } from 'react';
import './ImageCropUploadModal.styles.scss';
import Modal from '../../Modal/Modal';
import ModalHeader from '../../ModalHeader/ModalHeader';
import Button from '../../Button/Button';

import FileUploader from '../../FileUploader/FileUploader';
import NoImage from '../../../icons/no-image.svg';
import ImageCropper from '../../ImageCropper/ImageCropper';
import { useCropper } from '../../ImageCropper/useCropper';
import Loader from '../../Loader/Loader';

interface iProps {
  isOpened: boolean;
  fileFormats: string[];
  fileMaxSize: number;
  imageMaxWidth?: number;
  recommendedWidth?: number;
  aspect: number;
  onUploaded: (imageId: string) => void;
  onClose: () => void;
  className?: string;
  title?: string;
}

const ImageCropUploadModal: React.FC<iProps> = ({
  isOpened,
  fileFormats,
  fileMaxSize,
  recommendedWidth = 240,
  imageMaxWidth,
  aspect,
  onUploaded,
  onClose,
  title = 'Upload your photo',
  className = ''
}) => {
  const [imageSrc, setImageSrc] = useState('');
  const [croppedArea, setCroppedArea] = useState<string>('');

  useEffect(() => {
    if (!isOpened) {
      setImageSrc(null);
    }
  }, [isOpened]);

  const onChangeCrop = useCallback((croppedArea: string) => {
    setCroppedArea(croppedArea);
  }, []);

  const { onCrop, isUploadingCroppedImg, uploadCroppedImg } = useCropper(
    onChangeCrop,
    croppedArea,
    imageSrc,
    '',
    imageMaxWidth
  );

  const handleImageSelected = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      setImageSrc(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImageSrc(null);
    }
  };

  const handleSubmit = () => {
    uploadCroppedImg().then((imgId) => {
      onUploaded(imgId);
    });
  };

  return (
    <Modal
      className={className}
      close={onClose}
      isOpened={isOpened}
      style={{ width: 506 }}
    >
      <div className='ImageCropUploadModal'>
        <ModalHeader>
          <h2>{title}</h2>
        </ModalHeader>
        <div className='flex-grow-1 d-flex flex-column mt-2'>
          <div className='row mx-n2 flex-grow-1'>
            <div className='col px-2'>
              <FileUploader
                className='ImageCropUploadModal__uploader'
                recomendedRatio={`${recommendedWidth} x ${
                  recommendedWidth / aspect
                }`}
                description='Drag and drop or click to add an image'
                fileMaxSize={fileMaxSize}
                fileTypes={fileFormats}
                isImage
                showPreview={!!imageSrc}
                onFileSelected={handleImageSelected}
                withHint={!imageSrc}
                isResponsive={true}
                data-testid='image-uploader'
              >
                {!imageSrc ? (
                  <div className='ImageCropUploadModal__placeholder'>
                    <NoImage />
                  </div>
                ) : (
                  <>
                    <ImageCropper
                      imageSrc={imageSrc}
                      onCrop={onCrop}
                      aspect={aspect}
                    />
                    {isUploadingCroppedImg && <Loader />}
                  </>
                )}
              </FileUploader>
            </div>
          </div>

          <div className='row mt-4 mx-n2'>
            <div className='col-sm-6 px-2'>
              <Button
                className='flex-grow-1'
                type='button'
                variations={['gray', 'full-width', 'sm-width']}
                handleClick={onClose}
              >
                Cancel
              </Button>
            </div>
            <div className='col-sm-6 px-2'>
              <Button
                disabled={isUploadingCroppedImg || !imageSrc}
                className='flex-grow-1'
                variations={['full-width', 'sm-width']}
                handleClick={handleSubmit}
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropUploadModal;
