import './PaymentsItem.styles.scss';
import React, { useState, useRef } from 'react';
import { Payment } from '../../core/types';
import AvatarIcon from '../../icons/avatar-small.svg';
import { getPublicFile } from '../../core/api';
import Badge from '../Badge/Badge';
import { getBadgeType } from '../../pages/CoachPayments/CoachPayments.helpers';
import { PaymentStatusEnum } from '../../core/enums';
import moment from 'moment';
import cn from 'classnames';
import { useOnClickOutside } from '../../hooks';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import TextEllipse from '../TextEllipse';
import { normalizePrice } from '../../core/helpers';
import { ListItemProps } from '../Dropdown/Dropdown';

export type NameOptionType = 'name' | 'number';

interface Props {
  item: Payment;
  actions: ListItemProps[];
}

const TOUCH_DURATION = 500;

const PaymentsItem: React.FC<Props> = ({ item: payment, actions }) => {
  const ref = useRef();
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [isActive, setIsActive] = useState<boolean>(false);

  useOnClickOutside(
    ref,
    () => {
      setIsActive(false);
    },
    isActive
  );

  const handleTouchEnd = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
  };

  const handleLongTouch = () => {
    setIsActive(!isActive);
  };

  const handleTouchStart = () => {
    if (!actions.length) {
      return;
    }
    setTimerId(setTimeout(handleLongTouch, TOUCH_DURATION));
  };

  return (
    <div
      className='PaymentsItem d-flex align-items-stretch'
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      ref={ref}
    >
      <div
        className={cn(
          'd-flex align-items-stretch w-100 PaymentsItem__container',
          {
            'PaymentsItem__container--opened': isActive
          }
        )}
      >
        <div className='p-3 flex-shrink-0  d-flex align-items-center w-100'>
          <div className='PaymentsItem__avatar flex-shrink-0 mr-3'>
            {payment.client?.imageId ? (
              <img src={getPublicFile(payment.client.imageId)} alt='avatar' />
            ) : (
              <AvatarIcon />
            )}
          </div>
          <div className='PaymentsItem__content d-flex justify-content-between flex-grow-1'>
            <div className='PaymentsItem__info'>
              <div className='PaymentsItem__client mb-1 mr-3'>
                <TextEllipse
                  text={`${payment.client?.firstName} ${payment.client?.lastName}`}
                >
                  <span>
                    {payment.client?.firstName} {payment.client?.lastName}
                  </span>
                </TextEllipse>
              </div>
              <Badge
                type={getBadgeType(payment.status)}
                title={PaymentStatusEnum[payment.status].toUpperCase()}
                className='Badge--PaymentsItem'
              />
            </div>
            <div className='flex-shrink-0 d-flex flex-column align-items-end'>
              <div className='PaymentsItem__total mb-2'>
                ${normalizePrice(payment.amount)}
              </div>
              <div className='PaymentsItem__date'>
                Due on {moment.utc(payment.date).format('MMM D')}
              </div>
            </div>
          </div>
        </div>
        <div className='PaymentsItem__menu flex-shrink-0 d-flex justify-content-center align-items-center'>
          <DropdownMenu
            list={actions}
            className='DropdownMenu--table'
            withPortal
            fullScreen
            title={
              <>
                <div>Payment for</div>
                <TextEllipse text={payment.description}>
                  <span>{payment.description}</span>
                </TextEllipse>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentsItem;
