import React from 'react';
import Loader from '../Loader/Loader';
import Modal from '../Modal/Modal';

interface iProps {
  isLoading: boolean;
}

const GlobalLoader: React.FC<iProps> = ({ isLoading }) => {
  return (
    <Modal hasInner={false} close={() => null} isOpened={isLoading}>
      <Loader />
    </Modal>
  );
};

export default GlobalLoader;
