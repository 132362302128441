import React from 'react';
import './ModalFullHeader.styles.scss';

interface Props {}

const ModalFullHeader: React.FC<Props> = ({ children }) => {
  return (
    <div className='ModalFullHeader py-2 px-md-4 px-sm-3 px-2'>{children}</div>
  );
};

export default ModalFullHeader;
