import React, {
  useRef,
  ReactElement,
  useState,
  useEffect,
  useContext
} from 'react';
import { createPortal } from 'react-dom';
import { AppScrollbarsContext } from '../../App';
import cn from 'classnames';
import { useDebounce, useOnClickOutside } from '../../hooks';
import { Alignment } from '../Dropdown/Dropdown';

interface iProps {
  children: ReactElement<any>;
  shown: boolean;
  triggerRef: React.MutableRefObject<HTMLElement>;
  align?: Alignment;
  onClose?: () => void;
  fullScreen?: boolean;
}

const DropDownWithPortal: React.FC<iProps> = ({
  children,
  shown,
  align = 'left',
  onClose,
  fullScreen = false,
  triggerRef
}) => {
  const dropdown = useRef(null);
  const scroll = useContext(AppScrollbarsContext);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (!triggerRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  useOnClickOutside(dropdown, handleOutsideClick, shown && !fullScreen);

  // close on scroll
  useEffect(() => {
    const listener = () => {
      onClose();
    };
    const container = scroll.container
      ? scroll.container === document.body
        ? document
        : scroll.container
      : null;
    if (container && shown) {
      container.addEventListener('scroll', listener);
    }
    return () => {
      container.removeEventListener('scroll', listener);
    };
  }, [scroll, shown]);

  // open above if no space
  useEffect(() => {
    if (top > 0 && left > 0 && !fullScreen) {
      if (dropdown.current) {
        const dropdownRect = dropdown.current?.children[0]?.getBoundingClientRect();
        if (
          dropdownRect.bottom >
          (window.innerHeight || document.documentElement.clientHeight)
        ) {
          setTop(
            triggerRef.current.getBoundingClientRect().top - dropdownRect.height
          );
        }
      }
    }
  }, [top, left, fullScreen]);

  useEffect(() => {
    if (shown && !fullScreen) {
      const boundingRect = triggerRef.current.getBoundingClientRect();
      const dropdownRect = dropdown.current.getBoundingClientRect();
      setLeft(
        align === 'left'
          ? boundingRect.left
          : boundingRect.left + boundingRect.width - dropdownRect.width
      );
      setTop(boundingRect.top + boundingRect.height);
      setWidth(boundingRect.width);
    }
  }, [shown, fullScreen, triggerRef]);

  const debouncedIsShown = useDebounce(shown, 200);

  const isShown = shown || debouncedIsShown;

  return isShown
    ? createPortal(
        <>
          <div
            style={
              fullScreen
                ? undefined
                : {
                    top,
                    left,
                    minWidth: width
                  }
            }
            className={cn('Dropdown__menu Dropdown__menu--portal', {
              'Dropdown__menu--fullScreen': fullScreen,
              fadeIn: shown,
              fadeOut: !shown
            })}
            ref={dropdown}
          >
            {children}
          </div>
          {fullScreen && (
            <div className='Dropdown__backdrop' onClick={onClose}></div>
          )}
        </>,
        // @ts-ignore
        document.getElementById('dropdowns')
      )
    : null;
};

export default DropDownWithPortal;
