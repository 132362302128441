import React from 'react';
import AddAppointment from '../../icons/add-appointment-block.svg';
import Schedule from '../../icons/schedule-meeting.svg';
import AddToSchedule from '../../icons/add-to-schedule.svg';
import { CoachRoutesEnum } from '../../core/enums';

export const NewTypes = [
  {
    title: 'Create Meeting',
    description:
      '<p>Set up a one-on-one coaching call or group meeting at a specific date and time. Meetings can be publicly shown on your Coach page or set to private.</p>',
    url: CoachRoutesEnum.MeetingNew,
    icon: <Schedule />
  },
  {
    title: 'Add Appointment Block',
    description:
      '<p>Set up an appointment block, which is a meeting with open availability during the dates and times you define. Clients can pick a time to meet with you when booking through your Coach page.</p>',
    url: CoachRoutesEnum.AppointmentBlockNew,
    icon: <AddAppointment />
  },
  {
    title: 'Add to Schedule',
    description:
      '<p>Perfect for setting up a quick coaching call. Just pick a time and date, and invite your client(s) to this session.</p>',
    url: CoachRoutesEnum.ScheduleMeetingNew,
    icon: <AddToSchedule />
  }
];
