import React, { useState, useRef, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import ArrowIcon from '../../icons/arrow_down_sm.svg';
import './AccordionButton.styles.scss';

interface iProps {
  content?: string;
  nodeContent?: ReactNode;
  classSuffix?: string;
  isDisabled?: boolean;
  isExpanded?: boolean;
  className?: string;
  btnClassName?: string;
  onClick?: (
    handleClickAccordion: () => void,
    willBeExpanded: boolean,
    e: React.MouseEvent
  ) => void;
  customArrowIcon?: JSX.Element;
  reversedIcon?: boolean;
}
const AccordionButton: React.FC<iProps> = ({
  children,
  content = '',
  nodeContent = null,
  classSuffix = '',
  isDisabled = false,
  className = '',
  btnClassName = '',
  onClick,
  customArrowIcon,
  isExpanded: isExpandedExternal,
  reversedIcon = false
}) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickAccordion = () => {
    if (!isDisabled) {
      setIsExpanded(!isExpanded);
    }
  };

  const setMaxHeight = () => {
    return {
      maxHeight: isExpanded ? accordionRef!.current!.scrollHeight : 0
    };
  };

  useEffect(() => {
    isExpandedExternal !== undefined && setIsExpanded(isExpandedExternal);
  }, [isExpandedExternal]);

  return (
    <div className={`AccordionButton__accordion ${className}`}>
      <div
        className={`AccordionButton__accordion-toggler ${btnClassName}`}
        onClick={
          onClick
            ? (e: React.MouseEvent) => {
                onClick(handleClickAccordion, !isExpanded, e);
              }
            : handleClickAccordion
        }
      >
        <div className='AccordionButton__accordion-toggler-inner'>
          {children}
        </div>

        <span
          className={cn('AccordionButton__arrow', {
            'AccordionButton__arrow--disabled': isDisabled,
            'AccordionButton__arrow--expanded': isExpanded,
            'AccordionButton__arrow--reversed': reversedIcon
          })}
        >
          {customArrowIcon || (
            <span className='AccordionButton__arrow_default'>
              <ArrowIcon />
            </span>
          )}
        </span>
      </div>
      <div className='AccordionButton__accordion-content-outer'>
        {content ? (
          <div
            className={cn('AccordionButton__accordion-content', {
              [`AccordionButton__accordion-content--${classSuffix}`]: classSuffix
            })}
            ref={accordionRef}
            style={setMaxHeight()}
            dangerouslySetInnerHTML={{
              __html: content
            }}
          />
        ) : (
          <div
            className={cn('AccordionButton__accordion-content', {
              [`AccordionButton__accordion-content--${classSuffix}`]: classSuffix
            })}
            ref={accordionRef}
            style={setMaxHeight()}
          >
            {!content ? nodeContent : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionButton;
