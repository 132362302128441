import React, { useEffect, useState } from 'react';
import './ClientCompleteRegistration.styles.scss';
import ModalHeader from '../../components/ModalHeader/ModalHeader';
import {
  getDefaultData,
  getCompleteRegistrationInputErrors,
  isCompleteRegistrationDataValid as isDataValid,
  isCompleteRegistrationInputValid as isInputValid
} from './ClientCompleteRegistration.helpers';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { SignupClientAdditionalInfoViewModel } from '../../core/backend/api';
import Select from '../../components/Select/Select';
import {
  CountriesList,
  StatesList,
  getStateId,
  getCountryId,
  USA_COUNTRY_ID
} from '../../components/Modals/ContactModal/ContactModal.helpers';
import CustomizedPhoneInput from '../../components/CustomizedPhoneInput';
import { useStores } from '../../hooks';
import PageTitle from '../../components/PageTitle/PageTitle';
import ClientUserStore from '../../store/ClientUserStore';

const ClientCompleteRegistration = () => {
  const {
    rootStore: { userStore }
  } = useStores();

  const [data, setData] = useState<SignupClientAdditionalInfoViewModel>(
    getDefaultData(userStore)
  );
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const countryId = getCountryId(data.country, true);
  const [selectedCountry, setSelectedCountry] = useState<string>(countryId);
  const [selectedState, setSelectedState] = useState<string>(
    getStateId(data.state, StatesList, true)
  );

  const isUSASelected = selectedCountry === USA_COUNTRY_ID;

  const handleChangeField = (field: string) => (value: any) => {
    setData({
      ...data,
      [field]: value
    });
  };

  useEffect(() => {
    handleChangeField('timeZone')(userStore.timeZone);
  }, [userStore.timeZone]);

  const setCountryName = (countryId: string) => {
    const country = CountriesList!.find((country) => country.id === countryId);
    handleChangeField('country')(country ? country.name : '');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const country = CountriesList!.find(
      (country) => country.id === selectedCountry
    );
    if (isDataValid({ ...data, country: country.name })) {
      const state = StatesList!.find((state) => state.id === selectedState);
      ((userStore as unknown) as ClientUserStore)
        .completeRegistration({
          ...data,
          country: country ? country.name : '',
          state: state && isUSASelected ? state.name : ''
        })
        .catch();
    } else {
      setIsValidationShown(true);
    }
  };

  return (
    <div className='Tile ClientCompleteRegistration' style={{ width: 648 }}>
      <PageTitle title='Complete registration' />
      <ModalHeader>
        <h2>Complete your registration</h2>
        <h4>All fields are required</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit} noValidate autoComplete='off'>
        <div className='TileOption'>
          <div className='row mx-n2'>
            <div className='col-6 px-2'>
              <Select
                options={CountriesList}
                value={selectedCountry}
                title={'Country'}
                onChange={(countryId) => {
                  setSelectedCountry(countryId);
                  setCountryName(countryId);
                }}
                className='Select--contact-country'
                classNameSuffix='ClientCompleteRegistration'
                withPortal
                placeholder='Select Country'
              />
            </div>
            {selectedCountry === USA_COUNTRY_ID && (
              <div className='col-6 px-2'>
                <Select
                  options={StatesList}
                  value={selectedState}
                  title={'State'}
                  onChange={setSelectedState}
                  className='Select--contact-state'
                  classNameSuffix='ClientCompleteRegistration'
                  withPortal
                  placeholder='Select State'
                />
              </div>
            )}
            <div className='col-6 px-2'>
              <FormInput
                inputClassName='FormInput__input--ClientCompleteRegistration'
                label='City'
                value={data.city}
                isInvalid={isValidationShown && !isInputValid('city', data)}
                errorMessage='Please fill'
                handleChange={handleChangeField('city')}
                placeholder='Add City'
              />
            </div>
            <div className='col-6 px-2'>
              <FormInput
                label='Phone number'
                value={data.phoneNumber}
                isInvalid={
                  isValidationShown && !isInputValid('phoneNumber', data)
                }
                errorMessage={`Phone number ${
                  getCompleteRegistrationInputErrors('phoneNumber', data)[0]
                }`}
                handleChange={handleChangeField('phoneNumber')}
                inputClassName='FormInput__input--phoneNumber'
              >
                <CustomizedPhoneInput
                  value={data.phoneNumber}
                  onChange={(value) => handleChangeField('phoneNumber')(value)}
                  inputClass={
                    isValidationShown && !isInputValid('phoneNumber', data)
                      ? 'PhoneInputInput--error'
                      : ''
                  }
                />
              </FormInput>
            </div>
          </div>
        </div>
        <div className='Tile__footer'>
          <Button>Save</Button>
        </div>
      </form>
    </div>
  );
};

export default observer(ClientCompleteRegistration);
