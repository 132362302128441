import React, { ReactElement } from 'react';
import './DropdownMenu.styles.scss';
import Tooltip from '../Tooltip/Tooltip';
import HorizontalDotsIcon from '../../icons/horizontal-dots.svg';
import cn from 'classnames';
import Dropdown from '../Dropdown/Dropdown';

export interface ListItemProps {
  name: string;
  callback: () => void;
  icon?: any;
  dataTestid?: string;
}

interface iProps {
  children?: ReactElement<any>;
  list: Array<ListItemProps>;
  className?: string;
  withPortal?: boolean;
  withToolitip?: boolean;
  align?: 'right' | 'left';
  buttonClassName?: string;
  itemTextClassName?: string;
  fullScreen?: boolean;
  title?: string | JSX.Element;
  menuListClassName?: string;
}

const DropdownMenu: React.FC<iProps> = ({
  list,
  className = '',
  children,
  align = 'right',
  withPortal = true,
  withToolitip = true,
  buttonClassName = '',
  itemTextClassName = '',
  menuListClassName = '',
  fullScreen = false,
  title
}) => {
  const handleClick = (
    callback: any,
    closeDropdown: () => void,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();
    callback();
    closeDropdown();
    (e.target as HTMLDivElement).setAttribute('disabled', 'disabled');
  };

  const renderList = (closeDropdown: () => void) => {
    return (
      <div
        className={cn('DropdownMenu__list', menuListClassName, {
          'DropdownMenu__list--fullScreen': fullScreen
        })}
      >
        {title && <div className='DropdownMenu__title mb-2'>{title}</div>}
        {list.map((listItem, index) => {
          return (
            <button
              data-testid={listItem.dataTestid}
              className={cn('DropdownMenu__item', {
                'DropdownMenu__item--fullScreen': fullScreen
              })}
              key={index}
              type='button'
              onClick={handleClick.bind(null, closeDropdown, listItem.callback)}
            >
              {listItem.icon ? (
                <span className='DropdownMenu__item-icon'>{listItem.icon}</span>
              ) : null}
              <span className={`DropdownMenu__item-text ${itemTextClassName}`}>
                {listItem.name}
              </span>
            </button>
          );
        })}
      </div>
    );
  };

  const trigger = children ? (
    children
  ) : (
    <div
      className={`
        DropdownMenu__button
        DropdownMenu__button--dark-gray
        ${buttonClassName}
      `}
    >
      <HorizontalDotsIcon />
    </div>
  );

  const triggerElem = !withToolitip ? (
    trigger
  ) : (
    <Tooltip
      withPortal
      className='d-flex align-items-center justify-content-center'
      trigger={trigger}
      content={<>Menu</>}
      triggerType='hover'
    />
  );

  return (
    <Dropdown
      className={`DropdownMenu ${className}`}
      trigger={triggerElem}
      align={align}
      withPortal={withPortal}
      fullScreen={fullScreen}
    >
      {renderList}
    </Dropdown>
  );
};

export default DropdownMenu;
