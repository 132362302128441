import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  getClientAppointmentBlock,
  getClientEvent,
  getClientPageType,
  getPublicFile
} from '../../core/api';
import { PageType } from '../../core/backend/api';
import { getRoutePath } from '../../core/globals';
import { ClientRoutesEnum } from '../../core/enums';
import { iBookingItem } from '../../core/types';
import { Elements } from '@stripe/react-stripe-js';
import ClientBookingWizard from '../../components/ClientBookingWizard/ClientBookingWizard';
import { elementsOptions } from './ClientBookingPage.helpers';
import { useStores } from '../../hooks';
import { StripeElementsOptions } from '@stripe/stripe-js';
import PageTitle from '../../components/PageTitle/PageTitle';

const ClientBookingPage = () => {
  const { rootStore } = useStores();
  const { clientBookingStore, userStore } = rootStore;

  const params = useParams<{ siteName: string; link: string }>();
  const history = useHistory();
  const { search } = useLocation();
  const code = new URLSearchParams(search).get('code');

  const email = code
    ? // eslint-disable-next-line
      window.atob(code).replace(/[^a-z\d\.\@\_\-\+]/gi, '')
    : '';

  const [data, setData] = useState<iBookingItem>();
  const [isLoading, setIsLoading] = useState(true);

  const reFetchData = useCallback(() => {
    getClientPageType(params.link)
      .then((data) => {
        if (data.id) {
          if (data.pageType === PageType.NUMBER_0) {
            return getClientAppointmentBlock(data.id!).then(
              (appointmentBlock) => {
                setData(appointmentBlock);
              }
            );
          } else {
            return getClientEvent(data.id!).then((event) => {
              setData(event);
            });
          }
        } else {
          history.replace(getRoutePath(ClientRoutesEnum.CoachPage));
        }
      })
      .catch(() => {
        history.replace(getRoutePath(ClientRoutesEnum.CoachPage));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    reFetchData();
  }, [reFetchData]);

  useEffect(() => {
    clientBookingStore.openBookingPopup();
    return () => {
      clientBookingStore.closePopup();
    };
  }, [clientBookingStore]);

  const handleClose = () => {
    if (history.length > 2) {
      if (!userStore.isSignedIn) {
        return history.push(getRoutePath(ClientRoutesEnum.CoachPage));
      }
      history.push(
        rootStore.prevUrl || getRoutePath(ClientRoutesEnum.CoachPage)
      );
    } else {
      history.replace(getRoutePath(ClientRoutesEnum.CoachPage));
    }
  };

  return (
    <>
      <PageTitle title={`Booking ${data ? ` - ${data.name}` : ''}`} />
      <GlobalLoader isLoading={isLoading} />
      {clientBookingStore.isBookingOpened &&
      !userStore.isSignedIn &&
      rootStore.coachCustomizationInfo.logoSrc ? (
        <img
          src={getPublicFile(rootStore.coachCustomizationInfo.logoSrc)}
          alt='Logo'
          className='CoachPage__logo'
        />
      ) : null}
      {rootStore.coachStripePromise && !isLoading && (
        <Elements
          stripe={rootStore.coachStripePromise}
          options={elementsOptions as StripeElementsOptions}
        >
          <ClientBookingWizard
            email={email}
            onClose={handleClose}
            onReload={reFetchData}
            data={data}
          />
        </Elements>
      )}
    </>
  );
};

export default observer(ClientBookingPage);
