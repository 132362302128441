import React, { useEffect } from 'react';
import './PaymentDetails.styles.scss';
import FormInput from '../FormInput/FormInput';
import PaymentTypesImage from '../../images/accepted-cards.png';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent
} from '@stripe/stripe-js';
import { iOS, normalizePrice } from '../../core/helpers';
import {
  disableTabIndex,
  FieldsIds,
  iPaymentData,
  PaymentInfo,
  isTheInputValid as isInputValid,
  getTheInputErrors as getInputErrors,
  customStylesForInput
} from './PaymentDetails.helpers';

interface iProps {
  data: iPaymentData;
  onChangeField: (key: string) => (val: any) => void;
  isValidationShown: boolean;
  className?: string;
  withImage?: boolean;
  paymentInfo: PaymentInfo;
  isFailed: boolean;
}

const PaymentDetails: React.FC<iProps> = ({
  data,
  onChangeField,
  isValidationShown,
  className = '',
  withImage = false,
  paymentInfo,
  isFailed
}) => {
  const handleChangeField = (field: string) => (
    nextElementSelector: string
  ) => (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    if (
      (data.cardNumber.brand !== 'amex' || event.elementType !== 'cardCvc') &&
      event.complete &&
      !event.error &&
      !iOS()
    ) {
      (document.querySelector(nextElementSelector) as HTMLElement)?.focus();
    }
    onChangeField(field)(event);
  };

  useEffect(() => {
    if (iOS()) {
      const inputs = document.querySelectorAll('.PaymentDetails input');
      inputs.forEach((i) => disableTabIndex(i));
    }
  }, []);

  return (
    <div className={`PaymentDetails ${className}`}>
      <div className='PaymentDetails__title'>Payment Details</div>
      {withImage && (
        <img
          src={PaymentTypesImage}
          alt='Accepted cards'
          title='Accepted cards'
        />
      )}
      <div className='row mx-n3 PaymentDetails__form'>
        <div className='col-12 px-3'>
          <FormInput
            label='Card Number'
            value=''
            inputClassName='FormInput__stripeElement'
            handleChange={() => null}
            isInvalid={
              isValidationShown &&
              (!isInputValid('cardNumber', data) || isFailed)
            }
            errorMessage={
              data.cardNumber.error
                ? data.cardNumber.error.message
                : isFailed
                ? 'Your credit card is declined'
                : 'Please fill correct card number'
            }
          >
            <CardNumberElement
              options={customStylesForInput}
              id={FieldsIds.card}
              onChange={handleChangeField('cardNumber')(
                `#${FieldsIds.expiry} input`
              )}
            />
          </FormInput>
        </div>
        <FormInput
          label='Expiration'
          value=''
          className='col-4 px-3'
          inputClassName='FormInput__stripeElement'
          handleChange={() => null}
          isInvalid={
            (isValidationShown && !isInputValid('expiry', data)) ||
            !!data.expiry.error
          }
          errorMessage={
            data.expiry.error ? data.expiry.error.message : 'Please fill'
          }
        >
          <CardExpiryElement
            options={customStylesForInput}
            id={FieldsIds.expiry}
            onChange={handleChangeField('expiry')(`#${FieldsIds.cvc} input`)}
          />
        </FormInput>
        <FormInput
          label='CVC'
          value=''
          className='col-4 px-3'
          inputClassName='FormInput__stripeElement'
          handleChange={() => null}
          isInvalid={
            (isValidationShown && !isInputValid('cvc', data)) ||
            !!data.cvc.error
          }
          errorMessage={data.cvc.error ? data.cvc.error.message : 'Please fill'}
        >
          <CardCvcElement
            options={customStylesForInput}
            id={FieldsIds.cvc}
            onChange={handleChangeField('cvc')(`#${FieldsIds.postalCode}`)}
          />
        </FormInput>
        <FormInput
          id={FieldsIds.postalCode}
          name='postalCode'
          label='Zip Code'
          value={data.postalCode}
          className='col-4 px-3'
          inputClassName='FormInput__stripeElement'
          placeholder='Zip code'
          isInvalid={isValidationShown && !isInputValid('postalCode', data)}
          errorMessage={getInputErrors('postalCode', data)[0]}
          handleChange={onChangeField('postalCode')}
        />
      </div>
      <div className='PaymentDetails__payment-summary'>
        <div className='PaymentDetails__payment-text'>Order Summary</div>
        <div className='PaymentDetails__payment-price'>
          <span className='PaymentDetails__payment-price--bold'>
            ${normalizePrice(paymentInfo.price)}
          </span>
        </div>
        <div className='PaymentDetails__payment-total'>Total:</div>
        <div className='PaymentDetails__payment-text PaymentDetails__payment-text--to-right'>
          {paymentInfo.paymentDescription}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
