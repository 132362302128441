import React, { ReactNode } from 'react';
import TabsMenu, { iTab } from '../../components/TabsMenu/TabsMenu';
import { ClientRoutesEnum } from '../../core/enums';
import './ClientLibrary.styles.scss';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';

interface iProps {
  children: ReactNode;
  title: string;
  className?: string;
}

const ClientLibrary: React.FC<iProps> = ({
  title,
  children,
  className = ''
}) => {
  const { siteName } = useParams<{
    siteName: string;
  }>();

  const tabs: iTab[] = [
    {
      name: 'Downloads',
      link: ClientRoutesEnum.Downloads.replace(':siteName', siteName)
    },
    {
      name: 'Notes',
      link: ClientRoutesEnum.Notes.replace(':siteName', siteName)
    }
  ];

  return (
    <div className='ClientLibrary p-sm-4 p-3'>
      <PageTitle title={title} />
      <div className='ClientLibrary__header mb-3 mb-sm-0'>
        <TabsMenu tabsList={tabs} />
      </div>
      <div className={className}>{children}</div>
    </div>
  );
};

export default ClientLibrary;
