import React from 'react';
import './Loader.styles.scss';

interface Props {
  className?: string;
}

const Loader: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={`Loader ${className}`}>
      <div className='lds-roller'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
