import React, { useState, useRef, useEffect } from 'react';
import './CoachInvoiceBranding.styles.scss';
import InvoiceCard from '../../../components/InvoiceCard/InvoiceCard';
import { Invoice, InvoiceCustomizeData } from '../../../core/types';
import AccordionButton from '../../../components/AccordionButton/AccordionButton';
import { useMedia, useOverflow } from '../../../hooks';
import UploadLogoIcon from '../../../icons/upload-logo.svg';
import HintIcon from '../../../icons/hint.svg';
import Tooltip from '../../../components/Tooltip/Tooltip';
import ColorPickerInput from '../../../components/ColorPickerInput/ColorPickerInput';
import { CoachAccountViewModel } from '../../../core/backend/api';
import Button from '../../../components/Button/Button';
import { getDefaultData } from '../CoachInvoice.helpers';
import FileUploader from '../../../components/FileUploader/FileUploader';
import {
  MAX_IMAGE_SIZE,
  MAX_FILE_TYPES
} from '../../../components/CustomizeSidebar/CustomizeSidebar.helpers';
import { getPublicFile } from '../../../core/api';
import PencilIcon from '../../../icons/pencil.svg';
import BucketIcon from '../../../icons/bucket.svg';

interface Props {
  invoice: Invoice;
  coachAccountData: Partial<CoachAccountViewModel>;
  onChange: (value: InvoiceCustomizeData) => void;
}

const CoachInvoiceBranding = ({
  invoice,
  coachAccountData,
  onChange
}: Props) => {
  const backdropRef = useRef();
  const isTablet = useMedia(['(min-width: 768px)'], [true], false);
  const [isExpanded, setIsExpaned] = useState(false);

  const handleAccordionButtonClick = () => {
    setIsExpaned(!isExpanded);
  };

  const closeAccordion = (e: React.MouseEvent) => {
    if (e.target === backdropRef.current) {
      setIsExpaned(false);
    }
  };

  const handleChangeCustomizeDataField = (field: string) => (value: any) => {
    onChange({
      ...invoice.customizeData,
      [field]: value
    });
  };

  const handleReset = () => {
    onChange({
      ...getDefaultData().customizeData,
      logoSrc: invoice.customizeData.logoSrc
    });
  };

  useEffect(() => {
    isTablet && setIsExpaned(false);
  }, [isTablet]);

  useOverflow(isExpanded, 'dropdown-opened');

  const settingsContent = (
    <div className='pt-3 pt-md-4 pb-md-0 pb-5'>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <div className='d-flex align-items-center'>
          <span className='mr-2'>Logo</span>
          <Tooltip
            minWidth={185}
            className='d-flex'
            triggerType='hover'
            trigger={<HintIcon />}
            withPortal
            content={
              <p className='CoachInvoiceBranding__edit-image-hint'>
                Click on the image{' '}
                {!invoice.customizeData.logoSrc ? 'to upload it' : 'to edit it'}
              </p>
            }
          />
        </div>
        {/* <LogoUploader
          cropShape='round'
          croperModalTitle='Upload your logo'
          className='LogoUploader--CoachInvoiceBranding'
          imageUrl={invoice.customizeData.logoSrc}
          imageKey='logoSrc'
          handleChangeField={handleChangeCustomizeDataField}
          withHints={false}
          noImageIcon={
            <div
              style={{
                width: 16,
                height: 16
              }}
            >
              <UploadLogoIcon />
            </div>
          }
        /> */}
        <FileUploader
          withHint={false}
          className='CoachInvoiceBranding__UploadArea'
          description='Place your logo here'
          fileMaxSize={MAX_IMAGE_SIZE}
          fileTypes={MAX_FILE_TYPES}
          showPreview={!!invoice.customizeData.logoSrc}
          onFileUploaded={handleChangeCustomizeDataField('logoSrc')}
          ActionsRenderer={(menuList) => {
            return (
              <div className='CoachInvoiceBranding__image-btns'>
                {menuList.map((m, i: number) => {
                  return (
                    <Tooltip
                      key={m.name}
                      withPortal
                      trigger={
                        <Button
                          type='button'
                          variations={['naked']}
                          handleClick={m.callback}
                          data-testid={`${m.name.toLowerCase()}-image-btn`}
                          className='mx-1'
                        >
                          {m.name === 'Edit' ? <PencilIcon /> : <BucketIcon />}
                        </Button>
                      }
                      content={<>{m.name === 'Delete' ? 'Remove' : m.name}</>}
                      triggerType='hover'
                      minWidth={62}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          {invoice.customizeData.logoSrc ? (
            <div className='CoachInvoiceBranding__preview-container'>
              <img
                src={getPublicFile(invoice.customizeData.logoSrc)}
                alt='Coach logo'
                className='CoachInvoiceBranding__preview'
              />
            </div>
          ) : (
            <div className='CoachInvoiceBranding__noImage'>
              <UploadLogoIcon />
            </div>
          )}
        </FileUploader>
      </div>
      <ColorPickerInput
        className='mb-4'
        value={invoice.customizeData.primaryColor}
        label='Branding color'
        onChange={handleChangeCustomizeDataField('primaryColor')}
      />
      <ColorPickerInput
        className='mb-4'
        value={invoice.customizeData.backgroundColor}
        label='Accent color'
        onChange={handleChangeCustomizeDataField('backgroundColor')}
      />
      <div className='CoachInvoiceBranding__separator mb-4'></div>
      <ColorPickerInput
        className='mb-4'
        value={invoice.customizeData.headlineColor}
        label='Headline color'
        onChange={handleChangeCustomizeDataField('headlineColor')}
      />
      <ColorPickerInput
        value={invoice.customizeData.paragraphColor}
        label='Text color'
        onChange={handleChangeCustomizeDataField('paragraphColor')}
      />
      {JSON.stringify(invoice.customizeData) !==
        JSON.stringify(getDefaultData().customizeData) && (
        <Button
          type='button'
          className='mx-0 mt-4 p-0'
          variations={['naked']}
          handleClick={handleReset}
        >
          Back to default styles
        </Button>
      )}
    </div>
  );

  return (
    <div className='CoachInvoiceBranding d-flex justify-content-center'>
      <div className='CoachInvoiceBranding__settings-container order-md-1 order-2'>
        <div
          className={isExpanded ? 'CoachInvoiceBranding__backdrop' : undefined}
          ref={backdropRef}
          onClick={closeAccordion}
        ></div>
        <div className='CoachInvoiceBranding__settings'>
          {!isTablet ? (
            <AccordionButton
              className='CoachInvoiceBranding__accordion mb-0'
              nodeContent={settingsContent}
              classSuffix='ClientScheduleTile'
              onClick={handleAccordionButtonClick}
              isExpanded={isExpanded}
              reversedIcon
            >
              <div className='CoachInvoiceBranding__settings-title'>
                Branding settings
              </div>
            </AccordionButton>
          ) : (
            <>
              <div className='CoachInvoiceBranding__settings-title'>
                Branding settings
              </div>
              {settingsContent}
            </>
          )}
        </div>
      </div>
      <InvoiceCard
        className='order-md-2 mb-4 mb-lg-0 pb-5'
        link=''
        coachData={coachAccountData}
        invoiceData={invoice}
      />
    </div>
  );
};

export default CoachInvoiceBranding;
