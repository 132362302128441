import moment from 'moment';
import {
  InvoiceStatusEnum,
  InvoiceTypeEnum,
  InvoiceBillingInterval
} from '../../core/enums';
import { Invoice } from '../../core/types';
import { normalizeContactFromBackEnd } from '../../core/normalization';
import { CoachContactsViewModel } from '../../core/backend/api';

export const getDefaultContactData = (): CoachContactsViewModel => ({
  clientId: null,
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  state: '',
  city: '',
  phoneNumber: '',
  timeZone: '',
  isGuest: true
});

export function getDefaultData(): Invoice {
  return {
    type: InvoiceTypeEnum.OneTime,
    billingInterval: InvoiceBillingInterval.None,
    intervalValue: 0,
    status: InvoiceStatusEnum.draft,
    startDate: moment.utc(Date.now()).startOf('day').valueOf(),
    dueDate: moment.utc(Date.now()).startOf('day').add(30, 'day').valueOf(),
    client: normalizeContactFromBackEnd(getDefaultContactData()),
    services: [
      {
        title: '',
        quantity: 1,
        price: 0
      }
    ],
    tax: null,
    memo: '',
    customizeData: {
      primaryColor: '#37a0ce',
      backgroundColor: '#e8f2f7',
      headlineColor: '#000000',
      paragraphColor: '#50565B',
      logoSrc: null
    }
  };
}
