import React, { AllHTMLAttributes, ReactElement, useRef } from 'react';
import Tooltip from '../Tooltip/Tooltip';
import InfoIcon from '../../icons/info.svg';
import './FormInput.styles.scss';

export interface Props extends AllHTMLAttributes<HTMLInputElement> {
  type?: string;
  value: any;
  handleChange?: (value: any) => void;
  label?: string;
  subLabel?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  prepend?: string;
  append?: string | ReactElement<any>;
  children?: ReactElement<any>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  maxLength?: number;
  valueFormatter?: (value: any) => any;
  autoComplete?: string;
  noClassNameForChildren?: boolean;
  onFocus?: (event?: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event?: React.FocusEvent<HTMLElement>) => void;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
  style?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  labelClassName?: string;
  hint?: string;
  id?: string;
}

const FormInput: React.FC<Props> = ({
  type = 'text',
  value,
  handleChange = () => null,
  label,
  subLabel,
  name = '',
  placeholder = '',
  className = '',
  inputClassName = '',
  prepend = '',
  append = '',
  children,
  isInvalid,
  isDisabled = false,
  errorMessage,
  noClassNameForChildren = false,
  valueFormatter = (value) => value,
  style,
  labelClassName = '',
  maxLength,
  hint,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const formInputClassList = new Set([
    'FormInput',
    className,
    isDisabled ? 'FormInput--disabled' : ''
  ]);
  const inputClassList = new Set([
    inputClassName,
    'FormInput__input',
    prepend ? 'FormInput__input--with-prepend' : '',
    append ? 'FormInput__input--with-append' : '',
    isInvalid ? 'FormInput__input--error' : '',
    isDisabled ? 'FormInput__input--disabled' : ''
  ]);

  const labelClassList = new Set([
    labelClassName,
    'FormInput__label',
    isDisabled ? 'FormInput__label--disabled' : ''
  ]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const formattedValue = valueFormatter(rawValue);
    handleChange(
      maxLength ? formattedValue.slice(0, maxLength) : formattedValue
    );
  };

  const onPrependClick = () => {
    inputRef.current.focus();
  };

  return (
    <div className={Array.from(formInputClassList).join(' ')} style={style}>
      <div className='FormInput__wrapper'>
        {!!label && (
          <label className={Array.from(labelClassList).join(' ')}>
            <span>{label}</span>
            {!!hint && (
              <Tooltip
                className='d-inline-flex ml-3 Tooltip--small'
                trigger={<InfoIcon />}
                content={<span>{hint}</span>}
                triggerType='hover'
                minWidth={220}
              />
            )}
          </label>
        )}
        {!!subLabel && !isInvalid && (
          <label className={`FormInput__sub-label`}>{subLabel}</label>
        )}
        <div className='d-flex align-items-center position-relative flex-grow-1'>
          {!!prepend && (
            <div
              onClick={onPrependClick}
              className={`FormInput__prepend ${
                isInvalid ? 'FormInput__prepend--error' : ''
              }`}
            >
              {prepend}
            </div>
          )}
          {children
            ? noClassNameForChildren
              ? children
              : React.cloneElement(children, {
                  className: `${children.props.className} ${Array.from(
                    inputClassList
                  ).join(' ')}`
                })
            : null}
          {!children ? (
            <input
              ref={inputRef}
              type={type}
              name={name}
              className={Array.from(inputClassList).join(' ')}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              disabled={isDisabled}
              autoComplete='off'
              step={type === 'number' ? '0.01' : undefined}
              {...rest}
            />
          ) : null}
          {!!append && (
            <div
              className={`FormInput__append ${
                typeof append === 'string' ? '' : 'custom'
              }`}
            >
              {append}
            </div>
          )}
        </div>
      </div>
      {isInvalid && errorMessage && (
        <div
          className='FormInput__error-message'
          title={errorMessage}
          data-testid={
            (rest as any)['data-testid']
              ? `${(rest as any)['data-testid']}-error-message`
              : null
          }
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FormInput;
