import { SignUpCoachViewModel } from '../../core/backend/api';
import { iFormConfigItem } from '../../core/types';
import {
  containsCapital,
  containsDigit,
  containsNonCapital,
  getInputErrors,
  isDataValid,
  isEmail,
  isInputValid,
  isNotEmpty,
  isPasswordLongEnough,
  isPhoneNumberLongEnough,
  isNotSpacesOnSides
} from '../../core/formValidation';
import moment from 'moment-timezone';
import { getTimezone } from '../../core/helpers';

export function getDefaultData(): SignUpCoachViewModel {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    pricingPlanId: 0,
    stripePaymentMethodId: '',
    timeZone: getTimezone(moment.tz.guess())
  };
}

const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] },
  {
    field: 'phoneNumber',
    checkIsValid: [isNotEmpty, isPhoneNumberLongEnough]
  },
  {
    field: 'password',
    compareField: 'confirmPassword',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  },
  {
    field: 'confirmPassword',
    compareField: 'password',
    checkIsValid: [
      isNotEmpty,
      isPasswordLongEnough,
      containsNonCapital,
      containsCapital,
      containsDigit,
      isNotSpacesOnSides
    ]
  }
];

export function isSignUpInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function getSignUpInputErrors(inputField: string, data: any) {
  return getInputErrors(FormConfig)(inputField, data);
}

export function isSignUpDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}
