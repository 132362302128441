import React, { useEffect, useState } from 'react';
import CoachClient from '../../containers/CoachClient/CoachClient';
import {
  getCoachFilesLibrary,
  getCoachFilesLibraryFileById
} from '../../core/api';
import { FileResponseViewModelWithExt } from '../../core/types';
import { useParams } from 'react-router-dom';
import './CoachClientLibraryAccess.styles.scss';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import { sortArrayByTimestampDesc } from '../../core/helpers';
import CoachClientLibraryAccessFile from './CoachClientLibraryAccessFile';
import VideoModal from '../../components/Modals/VideoModal/VideoModal';
import { FileResponseViewModel } from '../../core/backend/api';
import EmptyState from '../../components/EmptyState/EmptyState';
import FileIcon from '../../icons/file_large.svg';
import Button from '../../components/Button/Button';
import GrantAccessModal from '../../components/Modals/GrantAccessModal/GrantAccessModal';
import { useStores } from '../../hooks';
import { postShareFile } from '../../core/api';
import PageTitle from '../../components/PageTitle/PageTitle';

const CoachClientLibraryAccess = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const {
    rootStore: {
      notificationStore,
      contactsStore: { contacts }
    }
  } = useStores();
  const [files, setFiles] = useState<FileResponseViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModalOpened, setIsVideoModalOpened] = useState<boolean>(false);
  const [isGrantAccessModalOpened, setIsGrantAccessModalOpened] = useState<
    boolean
  >(false);
  const [activeFile, setActiveFile] = useState<
    FileResponseViewModelWithExt | undefined
  >(undefined);
  const [expandedId, setExpandedId] = useState<string | number>(0);

  const refetchData = () => {
    setIsLoading(true);
    getCoachFilesLibrary()
      .then((filesList) => {
        setFiles(filesList);
        setIsGrantAccessModalOpened(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refetchData();
  }, [clientId]);

  const onRemoveAccess = (id: number) => {
    getCoachFilesLibraryFileById(id).then((res) => {
      setFiles((prevList) =>
        prevList.map((file) => {
          if (file.id === id) return res;
          return file;
        })
      );
    });
  };

  const clientsFiles = files
    .filter((file) => file.clientIds.includes(clientId))
    .sort(sortArrayByTimestampDesc('createdOn'));

  const openGrantAccessModal = () => {
    setIsGrantAccessModalOpened(true);
  };

  const closeGrantAccessModal = () => {
    setIsGrantAccessModalOpened(false);
  };

  const closeVideoModal = () => setIsVideoModalOpened(false);

  const onGrantAccessSubmit = async (selectedIds: number[]) => {
    return postShareFile(selectedIds, [clientId]).then(() => {
      notificationStore.addNotification({
        text: `${selectedIds.length > 1 ? `${selectedIds.length} ` : ''}File${
          selectedIds.length > 1 ? 's' : ''
        }`,
        textColored: `${selectedIds.length > 1 ? 'were' : 'was'} shared`,
        duration: 3000
      });
      refetchData();
    });
  };

  return (
    <CoachClient className='CoachClientLibraryAccess'>
      <PageTitle title='Library Access' />
      <GlobalLoader isLoading={isLoading} />
      <div className='CoachClientLibraryAccess'>
        <div className='d-flex justify-content-between mb-4'>
          <div>
            <div className='CoachClient__title'>Library Access</div>
            <div className='CoachClient__subTitle'>
              This client has access to these files.
            </div>
          </div>
          <Button handleClick={openGrantAccessModal} variations={['naked']}>
            + Grant Access
          </Button>
        </div>
      </div>

      {clientsFiles.length > 0 ? (
        clientsFiles.map((file) => (
          <CoachClientLibraryAccessFile
            key={file.id}
            file={file}
            clientId={clientId}
            onRemoveAccess={onRemoveAccess}
            setActiveFile={setActiveFile}
            setIsVideoModalOpened={setIsVideoModalOpened}
            expandedId={expandedId}
            setExpandedId={setExpandedId}
          />
        ))
      ) : (
        <EmptyState
          text='There are no files here'
          absoluteCenter={true}
          icon={<FileIcon />}
          className='ml-xl-0 ml-md-5 ml-0'
        />
      )}

      <VideoModal
        isOpened={isVideoModalOpened}
        close={closeVideoModal}
        file={activeFile}
      />
      <GrantAccessModal
        files={files
          .filter((file) => !file.clientIds.includes(clientId))
          .sort(sortArrayByTimestampDesc('createdOn'))}
        isOpened={isGrantAccessModalOpened}
        onClose={closeGrantAccessModal}
        onSubmit={onGrantAccessSubmit}
        clientName={
          contacts.find((c) => c.clientId === clientId)?.fullName || ''
        }
      />
    </CoachClient>
  );
};

export default CoachClientLibraryAccess;
