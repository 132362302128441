import { iFormConfigItem } from '../../core/types';
import { isEmail, isNotEmpty } from '../../core/formValidation';
import { ClientAccountViewModel } from '../../core/backend/api';

export const FormConfig: iFormConfigItem[] = [
  { field: 'firstName', checkIsValid: [isNotEmpty] },
  { field: 'lastName', checkIsValid: [isNotEmpty] },
  { field: 'email', checkIsValid: [isNotEmpty, isEmail] }
];

export const getEmptyAccount = (): ClientAccountViewModel => {
  return {
    city: '',
    country: '',
    state: '',
    email: '',
    firstName: '',
    imageId: '',
    lastName: '',
    phoneNumber: '',
    timeZone: ''
  };
};
