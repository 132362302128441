import { iFormConfigItem } from '../../core/types';
import {
  isCardValid,
  isCvcValid,
  isDataValid,
  isExpiryValid,
  isInputValid,
  isNotEmpty,
  isZipCode,
  isZipLongEnough
} from '../../core/formValidation';
import { iPaymentData } from './PaymentDetailsNew';

const FormConfig: iFormConfigItem[] = [
  { field: 'cardNumber', checkIsValid: [isCardValid] },
  { field: 'expiry', checkIsValid: [isExpiryValid] },
  { field: 'cvc', checkIsValid: [isCvcValid] },
  {
    field: 'postalCode',
    checkIsValid: [isNotEmpty, isZipCode, isZipLongEnough]
  }
];

export function isTheInputValid(inputField: string, data: any) {
  return isInputValid(FormConfig)(inputField, data);
}

export function isTheDataValid(data: any) {
  return isDataValid(FormConfig)(data);
}

export const renderError = (data: iPaymentData) => {
  return (
    (data.cardNumber.error?.message &&
      (data.expiry.error?.message || data.cvc.error?.message) &&
      'Please fill') ||
    (data.expiry.error?.message && data.cvc.error?.message && 'Please fill') ||
    data.cardNumber.error?.message ||
    data.expiry.error?.message ||
    data.cvc.error?.message ||
    'Please fill'
  );
};

export const customStylesForInput = {
  style: {
    base: {
      color: '#161717',
      fontFamily: 'Rubik, sans-serif',
      fontSize: '16px',
      fontWeight: '400',
      '::placeholder': {
        color: '#747487',
        fontWeight: '400'
      },
      lineHeight: '24px'
    },
    invalid: {
      fontWeight: '400',
      color: '#eb5757'
    },
    focus: {}
  }
};
