import {
  isNotEmpty,
  isMaxlengthValid,
  getInputErrors,
  isInputValid,
  isUrl
} from '../../../core/formValidation';
import { parse } from 'query-string';
import {
  Checker,
  FileResponseViewModelWithExt,
  iFormConfigItem
} from '../../../core/types';

interface AddLinkFormValidateOptions {
  isSubmitValidation: boolean;
  isVideoLink: boolean;
}

export type LinkModalUrlType = 'link' | 'video';

export const MAXIMAL_DESCRIPTION_LENGTH = 255;
export const MAXIMAL_NAME_LENGTH = 50;

const ALLOWED_VIDEO_LINKS = [
  'www.youtube.com/watch?',
  'www.youtube.com/embed/',
  'youtu.be/',
  'vimeo.com/',
  'player.vimeo.com/video/'
];

export const getFormConfig = (isVideoLink: boolean) => {
  let urlCheckers = [isNotEmpty, isUrl];
  if (isVideoLink) {
    urlCheckers = [...urlCheckers, isAllowedVideoUrl, isValidVideoUrl];
  }
  return [
    { field: 'name', checkIsValid: [isNotEmpty, isNameShortEnough] },
    { field: 'fileLink', checkIsValid: urlCheckers },
    { field: 'description', checkIsValid: [isDescriptionShortEnough] }
  ];
};

export const validateField = (
  key: string,
  data: Partial<FileResponseViewModelWithExt>,
  config: iFormConfigItem[]
) => {
  return isInputValid(config)(key, data);
};

export function isAllowedVideoUrl(str: string) {
  return ALLOWED_VIDEO_LINKS.some((fileLink) => str.includes(fileLink));
}

export function isValidVideoUrl(str: string) {
  if (str.includes(ALLOWED_VIDEO_LINKS[0])) {
    return str.includes('v=');
  }
  const parsedStringArr = str.split('/');
  return !!parsedStringArr[parsedStringArr.length - 1];
}

export const isDescriptionShortEnough = isMaxlengthValid(
  MAXIMAL_DESCRIPTION_LENGTH
);
export const isNameShortEnough = isMaxlengthValid(MAXIMAL_NAME_LENGTH);

export function formatVideoLink(value: string) {
  const youtubeLink = 'https://www.youtube.com/embed/';
  if (value.includes(ALLOWED_VIDEO_LINKS[0])) {
    return `youtube|${youtubeLink}${parse(value.split('?')[1]).v}`;
  }
  const parsedStringArr = value.split('/');
  if (
    value.includes(ALLOWED_VIDEO_LINKS[1]) ||
    value.includes(ALLOWED_VIDEO_LINKS[2])
  ) {
    return `youtube|${youtubeLink}${
      parsedStringArr[parsedStringArr.length - 1]
    }`;
  }
  if (
    value.includes(ALLOWED_VIDEO_LINKS[3]) ||
    value.includes(ALLOWED_VIDEO_LINKS[4])
  ) {
    return `vimeo|https://${ALLOWED_VIDEO_LINKS[4]}${
      parsedStringArr[parsedStringArr.length - 1]
    }`;
  }

  return value;
}

export function isFormDataValid(
  data: Partial<FileResponseViewModelWithExt>,
  { isSubmitValidation, isVideoLink }: AddLinkFormValidateOptions
) {
  const config = getFormConfig(isVideoLink);
  let nameValid, urlValid, descriptionValid;

  if (!isSubmitValidation) {
    nameValid = validateField('name', data, config);
    urlValid = validateField('fileLink', data, config);
  } else {
    nameValid =
      data.fileLink && !data.name ? validateField('name', data, config) : true;
    urlValid =
      data.fileLink || (!data.fileLink && data.name)
        ? validateField('fileLink', data, config)
        : true;
  }

  descriptionValid = isVideoLink
    ? validateField('description', data, config)
    : true;

  return nameValid && urlValid && descriptionValid;
}

const getErrorMessage = (checker: Checker) => {
  let message: string | undefined = undefined;

  switch (checker) {
    case isUrl: {
      message = 'Link is not Valid';
      break;
    }
    case isAllowedVideoUrl: {
      message = 'Link is not Valid, only Youtube and Vimeo links is allowed';
      break;
    }
    case isNameShortEnough: {
      message = `Should have at most ${MAXIMAL_NAME_LENGTH} characters`;
      break;
    }
    case isDescriptionShortEnough: {
      message = `Should have at most ${MAXIMAL_DESCRIPTION_LENGTH} characters`;
      break;
    }
    case isValidVideoUrl: {
      message = 'Embeded link is not Valid';
      break;
    }
    default: {
      message = undefined;
    }
  }

  return message;
};

export const getFormErrors = <T>(data: T, isVideoLink: boolean) => {
  const formConfig = getFormConfig(isVideoLink);

  return {
    name: getInputErrors(formConfig, getErrorMessage)('name', data)[0],
    fileLink: getInputErrors(formConfig, getErrorMessage)('fileLink', data)[0],
    description: getInputErrors(formConfig, getErrorMessage)(
      'description',
      data
    )[0]
  };
};
