import axios, { AxiosResponse, Canceler } from 'axios';
import {
  AppointmentTypesForScheduleViewModel,
  AppointmentTypeViewModel,
  AppointmentFullViewModel,
  AppointmentViewModel,
  BookedCoachEventsAndAppointmentsViewModel,
  ChangePasswordViewModel,
  ClientAccountViewModel,
  ClientInfoViewModel,
  CoachAccountViewModel,
  CoachAvailabilitySettingsViewModel,
  CoachContactsViewModel,
  CoachEventViewModel,
  CreatedFileViewModel,
  EventForScheduleViewModel,
  ForgotPasswordViewModel,
  GetAllBookedViewModel,
  GetAllWithInvitesViewModel,
  GetAllWithMembersViewModel,
  GetAvailableSlotsAppointmentSettingsViewModel,
  GetAvailableSlotsEventSettingsViewModel,
  PageTypeViewModel,
  PaymentItemDetailsViewModel,
  PricingPlanViewModel,
  ResetPasswordViewModel,
  MotivationMessageViewModel,
  NoteViewModel,
  AllAppointmentsAndEventsViewModel,
  ClientInvoiceViewModel,
  FileResponseViewModel,
  CoachCreateViewModel,
  SignInInfoViewModel,
  InvoiceServiceViewModel,
  CreateInvoiceViewModel,
  BillingItemViewModel
} from './backend/api';
import {
  normalizeAppointmentForBackEnd,
  normalizeAppointmentFromBackEnd,
  normalizeAppointmentBlockForBackEnd,
  normalizeAppointmentBlockFromBackEnd,
  normalizeBookingItemsFromBackEnd,
  normalizeBookingItemsInvitedFromBackEnd,
  normalizeBookingItemsMemberFromBackEnd,
  normalizeClientAppointmentTypeViewModel,
  normalizeClientEventViewModel,
  normalizeEventForBackEnd,
  normalizeEventFromBackEnd,
  normalizeClientFullAppointmentViewModel,
  normalizeCoachFromBackEnd,
  normalizeInvoiceFromBackEnd,
  normalizeContactFromBackEnd,
  normalizeNoteFromBackEnd,
  normalizeFileFromBackEnd,
  normalizeCoachTaxFromBackEnd,
  normalizeCoachInvoiceForBackEnd,
  normalizeCoachPaymentFromBackEnd,
  normalizeCoachAccountData,
  normalizeClientAccountData
} from './normalization';
import {
  Appointment,
  AppointmentBlock,
  CoachEvent,
  iCoachPageCustomizationData,
  CoachSteps,
  WelcomeMessageData,
  Invoice,
  Payment,
  StripeSubscription,
  PaginatedResponse
} from '../core/types';
import RootStore from '../store/RootStore';
import {
  getFromMilliseconds,
  getTimeInUtc,
  getTimeInZone,
  sortArrayBy
} from './helpers';
import getGlobal, { getSiteName } from './globals';
import {
  ValidateTokenViewModel,
  InvoiceTaxViewModel,
  InvoiceViewModel,
  PaymentItemViewModel,
  InvoiceSubscriptionRequest
} from './backend/api';
import { normalizeInvoicesData } from '../pages/CoachClientInvoices/CoachClientInvoices.helpers';
import {
  EventStatus,
  InvoiceStatusEnum,
  PaymentStatusEnum,
  SortOrder,
  InvoiceTypeEnum
} from './enums';
import { SortParams, StripePaymentMethod } from './types';
import { ScheduleItemViewModel } from './backend/api';

export async function signInCoach(data: SignInInfoViewModel) {
  await axios.post(`${getGlobal('api')}/Auth/LogIn`, data);
}

export async function signOutCoach() {
  await axios.post(`${getGlobal('api')}/Auth/LogOut`, {});
}

export async function signInClient(
  siteName: string,
  data: SignInInfoViewModel
) {
  await axios.post(`${getGlobal('api')}/${siteName}/Auth/LogIn`, data);
}

export async function signOutClient(siteName: string) {
  await axios.post(`${getGlobal('api')}/${siteName}/Auth/LogOut`, {});
}

export async function getAppointmentBlocks() {
  const response: AxiosResponse<AppointmentTypeViewModel[]> = await axios.get(
    `${getGlobal('api')}/AppointmentTypes`
  );
  return response.data.map(normalizeAppointmentBlockFromBackEnd);
}

export async function getCoachAvailabilitySettings() {
  const response: AxiosResponse<CoachAvailabilitySettingsViewModel> = await axios.get(
    `${getGlobal('api')}/CoachAvailabilitySettings`
  );
  return response.data;
}

export async function setCoachPageInfo(
  data: iCoachPageCustomizationData,
  imageId = '',
  socialEmail: string
) {
  const coachAccountData: CoachAccountViewModel = await getCoachAccount();
  const payload = {
    ...coachAccountData,
    socialEmail,
    imageId,
    siteCustomizationJson: data !== null ? JSON.stringify(data) : null
  };
  return await updateCoachAccount(payload);
}

export async function getAppointmentBlockAvailableSlots(
  data: GetAvailableSlotsAppointmentSettingsViewModel
) {
  const requestData: GetAvailableSlotsAppointmentSettingsViewModel = {
    ...data,
    fromTimeStampUtc: Math.max(
      Date.now(),
      getTimeInUtc(data.fromTimeStampUtc, RootStore.userStore.timeZone)
    ),
    toTimeStampUtc: getTimeInUtc(
      data.toTimeStampUtc,
      RootStore.userStore.timeZone
    )
  };
  const response: AxiosResponse<number[]> = await axios.post(
    `${getGlobal('api')}/Appointment/GetAvailableSlotsForAppointment`,
    requestData
  );
  const currentTimeStamp = Date.now();
  return response.data
    .filter((time) => time > currentTimeStamp)
    .map((time) => getTimeInZone(time, RootStore.userStore.timeZone));
}

export async function createAppointment(data: Appointment) {
  const response: AxiosResponse<AppointmentViewModel> = await axios.post(
    `${getGlobal('api')}/Appointment`,
    normalizeAppointmentForBackEnd(data)
  );
  return normalizeAppointmentFromBackEnd(response.data);
}

export async function updateAppointment(data: Appointment) {
  const response: AxiosResponse<AppointmentViewModel> = await axios.put(
    `${getGlobal('api')}/Appointment/${data.id}`,
    normalizeAppointmentForBackEnd(data)
  );
  return normalizeAppointmentFromBackEnd(response.data);
}

export async function checkPageNameExist(value: string) {
  const response: AxiosResponse<boolean> = await axios.get(
    `${getGlobal('api')}/PageLinks/IsPageNameExist?pageName=${value.trim()}`
  );
  return response.data;
}

export async function getEventAvailableSlots(
  data: GetAvailableSlotsEventSettingsViewModel
) {
  const requestData: GetAvailableSlotsEventSettingsViewModel = {
    ...data,
    fromTimeStampUtc: Math.max(
      Date.now(),
      getTimeInUtc(data.fromTimeStampUtc, RootStore.userStore.timeZone)
    ),
    toTimeStampUtc: getTimeInUtc(
      data.toTimeStampUtc,
      RootStore.userStore.timeZone
    )
  };
  const response: AxiosResponse<number[]> = await axios.post(
    `${getGlobal('api')}/Events/GetAvailableSlotsForEvent`,
    requestData
  );
  const currentTimeStamp = Date.now();
  return response.data
    .filter((time) => time > currentTimeStamp)
    .map((time) => getTimeInZone(time, RootStore.userStore.timeZone));
}

export async function getEvents() {
  const response: AxiosResponse<CoachEventViewModel[]> = await axios.get(
    `${getGlobal('api')}/Events`
  );

  return response.data.map(normalizeEventFromBackEnd);
}

export async function createEvent(data: CoachEvent) {
  const response: AxiosResponse<CoachEventViewModel> = await axios.post(
    `${getGlobal('api')}/Events`,
    normalizeEventForBackEnd(data)
  );

  return normalizeEventFromBackEnd(response.data);
}

export async function updateEvent(data: CoachEvent) {
  const response: AxiosResponse<CoachEventViewModel> = await axios.put(
    `${getGlobal('api')}/Events/${data.id}`,
    normalizeEventForBackEnd(data)
  );

  return normalizeEventFromBackEnd(response.data);
}

export async function deleteEvent(id: number) {
  return await axios.delete(`${getGlobal('api')}/Events/${id}`);
}

export async function getCalendarData(
  startTimeStamp: number,
  endTimeStamp: number,
  page = 1,
  pageSize = 100,
  status: EventStatus = null
) {
  const fromTimeStampUtc = startTimeStamp
    ? getTimeInUtc(startTimeStamp, RootStore.userStore.timeZone)
    : null;
  const toTimeStampUtc = endTimeStamp
    ? getTimeInUtc(endTimeStamp, RootStore.userStore.timeZone)
    : null;
  const url = `${getGlobal('api')}/Booking/GetBookedCoachEventsAndAppointments`;
  const response: AxiosResponse<PaginatedResponse<
    ScheduleItemViewModel
  >> = await axios.get(url, {
    params: {
      fromTimeStampUtc,
      toTimeStampUtc,
      page,
      pageSize,
      status
    }
  });

  return {
    items: response.data.items.map((item) => ({
      ...item,
      timestamp: getTimeInZone(item.timestamp, RootStore.userStore.timeZone)
    })),
    totalCount: response.data.totalCount
  };
}

export async function getEvent(id: number) {
  const response: AxiosResponse<CoachEventViewModel> = await axios.get(
    `${getGlobal('api')}/Events/${id}`
  );
  return normalizeEventFromBackEnd(response.data);
}

export async function cancelClientScheduleEvent(
  clientId: string,
  eventId: number
) {
  const response: AxiosResponse<unknown> = await axios.put(
    `${getGlobal('api')}/Contact/${clientId}/Event/${eventId}/cancel`
  );
  return response;
}

export async function cancelEvent(id: number, withRefund: boolean = false) {
  const response = await axios.put(
    `${getGlobal('api')}/Events/${id}/cancel${withRefund ? 'With' : 'Without'}`
  );
  return response.data;
}

export async function getAppointment(id: number) {
  const response: AxiosResponse<AppointmentViewModel> = await axios.get(
    `${getGlobal('api')}/Appointment/${id}`
  );
  return normalizeAppointmentFromBackEnd(response.data);
}

export async function cancelAppointment(id: number) {
  const response = await axios.put(
    `${getGlobal('api')}/Appointment/${id}/cancel`
  );
  return response.data;
}

export async function getCoachAccount() {
  const response: AxiosResponse<CoachAccountViewModel> = await axios.get(
    `${getGlobal('api')}/CoachPortal/CoachAccount`
  );
  return normalizeCoachAccountData(response.data);
}

export async function getCoachSteps() {
  const response: AxiosResponse<CoachSteps> = await axios.get(
    `${getGlobal('api')}/CoachPortal/CoachStepsStates`
  );
  return response.data;
}

export async function updateCoachAccount(data: CoachAccountViewModel) {
  const response: AxiosResponse<CoachAccountViewModel> = await axios.put(
    `${getGlobal('api')}/CoachPortal/UpdateCoachAccount`,
    normalizeCoachAccountData(data)
  );
  return response.data;
}

export async function getClientAccount() {
  const response: AxiosResponse<ClientAccountViewModel> = await axios.get(
    getClientApiLink('CoachPortal/ClientAccount')
  );
  return normalizeClientAccountData(response.data);
}

export async function updateClientAccount(data: ClientAccountViewModel) {
  const response: AxiosResponse<ClientAccountViewModel> = await axios.put(
    getClientApiLink('CoachPortal/UpdateClientAccount'),
    normalizeClientAccountData(data)
  );
  return response.data;
}

export async function getAppointmentBlock(id: number) {
  const response: AxiosResponse<AppointmentTypeViewModel> = await axios.get(
    `${getGlobal('api')}/AppointmentTypes/${id}`
  );

  return normalizeAppointmentBlockFromBackEnd(response.data);
}

export async function createAppointmentBlock(data: AppointmentBlock) {
  const response: AxiosResponse<AppointmentTypeViewModel> = await axios.post(
    `${getGlobal('api')}/AppointmentTypes`,
    normalizeAppointmentBlockForBackEnd(data)
  );

  return normalizeAppointmentBlockFromBackEnd(response.data);
}

export async function updateAppointmentBlock(data: AppointmentBlock) {
  const response: AxiosResponse<AppointmentTypeViewModel> = await axios.put(
    `${getGlobal('api')}/AppointmentTypes/${data.id}`,
    normalizeAppointmentBlockForBackEnd(data)
  );

  return normalizeAppointmentBlockFromBackEnd(response.data);
}

export async function deleteAppointmentBlock(id: number) {
  return await axios.put(`${getGlobal('api')}/AppointmentTypes/${id}/cancel`);
}

export async function getPricingPlans() {
  const response: AxiosResponse<PricingPlanViewModel[]> = await axios.get(
    `${getGlobal('api')}/PricingPlans`
  );

  return response.data;
}

export async function uploadPublicFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const response: AxiosResponse<CreatedFileViewModel> = await axios.post(
    `${getGlobal('api')}/PublicFile/UploadPublicFile`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export async function uploadClientPublicFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  const response: AxiosResponse<CreatedFileViewModel> = await axios.post(
    getClientApiLink('PublicFile/UploadPublicFile'),
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );

  return response.data;
}

export function getPublicFile(fileName: string) {
  if (!fileName) return;
  return `${getGlobal(
    'api'
  )}/PublicFile/DownloadPublicFile?filename=${fileName}`;
}

export async function changePassword(data: ChangePasswordViewModel) {
  const response: AxiosResponse = await axios.post(
    `${getGlobal('api')}/Auth/ChangePassword`,
    data
  );
  return response.data;
}

export async function clientChangePassword(data: ChangePasswordViewModel) {
  const response: AxiosResponse = await axios.post(
    `${getClientApiLink('Auth/ChangePassword')}`,
    data
  );
  return response.data;
}

export function getClientApiLink(url: string) {
  const api = getGlobal('api');
  return `${api}/${getSiteName()}/${url}`;
}

export async function getCoachInfoForClient() {
  const response: AxiosResponse<CoachAccountViewModel> = await axios.get(
    getClientApiLink('CoachPortal/CoachAccount')
  );
  return response.data;
}

export async function getCoachBookedEvents() {
  const response: AxiosResponse<GetAllBookedViewModel> = await axios.get(
    getClientApiLink('CoachPortal/GetAllBooked')
  );

  return normalizeBookingItemsFromBackEnd(response.data);
}

export async function getClientAppointmentBlockAvailableSlots(
  data: GetAvailableSlotsAppointmentSettingsViewModel
) {
  const requestData: GetAvailableSlotsAppointmentSettingsViewModel = {
    ...data,
    fromTimeStampUtc: Math.max(
      Date.now(),
      getTimeInUtc(data.fromTimeStampUtc, RootStore.userStore.timeZone)
    ),
    toTimeStampUtc: getTimeInUtc(
      data.toTimeStampUtc,
      RootStore.userStore.timeZone
    )
  };
  const response: AxiosResponse<number[]> = await axios.post(
    getClientApiLink('Appointment/GetAvailableSlotsForAppointment'),
    requestData
  );
  const currentTimeStamp = Date.now();
  return response.data
    .filter((time) => time > currentTimeStamp)
    .map((time) => getTimeInZone(time, RootStore.userStore.timeZone));
}

export async function createClientAppointment(data: Appointment) {
  const response: AxiosResponse<AppointmentViewModel> = await axios.post(
    getClientApiLink('Appointment'),
    normalizeAppointmentForBackEnd(data)
  );

  return normalizeAppointmentFromBackEnd(response.data);
}

export async function createClient(data: ClientInfoViewModel) {
  return await axios.post(getClientApiLink('Auth/CreateClient'), data);
}

export async function attachClientToAppointment(appointmentId: number) {
  const response: AxiosResponse<PaymentItemDetailsViewModel> = await axios.post(
    `${getClientApiLink(
      'Booking/AttachClientToAppointment'
    )}?appointmentId=${appointmentId}`
  );

  return response.data;
}

export async function attachClientToAppointmentWithoutToken(
  appointmentId: number,
  clientId: string
) {
  const response: AxiosResponse<PaymentItemDetailsViewModel> = await axios.post(
    `${getClientApiLink(
      'Booking/AttachClientToAppointmentWithoutToken'
    )}?appointmentId=${appointmentId}&clientId=${clientId}`
  );

  return response.data;
}

export async function attachClientToEvent(appointmentId: number) {
  const response: AxiosResponse<PaymentItemDetailsViewModel> = await axios.post(
    `${getClientApiLink(
      'Booking/AttachClientToEvent'
    )}?coachEventId=${appointmentId}`
  );

  return response.data;
}

export async function attachClientToEventWithoutToken(
  appointmentId: number,
  clientId: string
) {
  const response: AxiosResponse<PaymentItemDetailsViewModel> = await axios.post(
    `${getClientApiLink(
      'Booking/AttachClientToEventWithoutToken'
    )}?coachEventId=${appointmentId}&clientId=${clientId}`
  );

  return response.data;
}

export async function cancelUnPaidAppointmentWithoutToken(
  appointmentId: number,
  clientId: string
) {
  return axios.put(
    `${getClientApiLink(
      'Booking/CancelUnPaidAppointmentWithoutToken'
    )}?appointmentId=${appointmentId}&clientId=${clientId}`
  );
}

export async function cancelUnPaidAppointment(appointmentId: number) {
  return axios.put(
    `${getClientApiLink(
      'Booking/CancelUnPaidAppointment'
    )}?appointmentId=${appointmentId}`
  );
}

export async function cancelUnPaidEventWithoutToken(
  appointmentId: number,
  clientId: string
) {
  return axios.put(
    `${getClientApiLink(
      'Booking/CancelUnPaidEventWithoutToken'
    )}?coachEventId=${appointmentId}&clientId=${clientId}`
  );
}

export async function cancelUnPaidEvent(appointmentId: number) {
  return axios.put(
    `${getClientApiLink(
      'Booking/CancelUnPaidEvent'
    )}?coachEventId=${appointmentId}`
  );
}

export async function getClientBookingItemsWithInvites() {
  const response: AxiosResponse<GetAllWithInvitesViewModel> = await axios.get(
    getClientApiLink('Booking/GetAllWithInvites')
  );

  return normalizeBookingItemsInvitedFromBackEnd(response.data);
}

export async function getClientBookingItemsWithMember(
  fromMs: number,
  toMs: number = 0
) {
  const response: AxiosResponse<GetAllWithMembersViewModel> = await axios.get(
    `${getClientApiLink(
      'Booking/GetAllWithMembers'
    )}?fromTimeStampUtc=${fromMs}&toTimeStampUtc=${toMs}`
  );

  return normalizeBookingItemsMemberFromBackEnd(response.data);
}

export async function cancelClientAppointment(id: number) {
  return await axios.get(
    `${getClientApiLink(
      'CoachPortal/CancelAppointmentByClient'
    )}?appointmentId=${id}`
  );
}

export async function cancelClientEvent(id: number) {
  return await axios.get(
    `${getClientApiLink('CoachPortal/CancelEventByClient')}?eventId=${id}`
  );
}

export async function cancelClientAppointmentInvite(id: number) {
  return await axios.get(
    `${getClientApiLink('Booking/CancelAppointmentInvite')}?appointmentId=${id}`
  );
}

export async function cancelClientEventInvite(id: number) {
  return await axios.get(
    `${getClientApiLink('Booking/CancelEventInvite')}?eventId=${id}`
  );
}

export async function getClientPageType(link: string) {
  const response: AxiosResponse<PageTypeViewModel> = await axios.get(
    `${getClientApiLink('PageLinks/GetPageType')}?link=${link}`
  );

  return response.data;
}

export async function getClientAppointmentBlock(id: number) {
  const response: AxiosResponse<AppointmentTypesForScheduleViewModel> = await axios.get(
    `${getClientApiLink(
      'AppointmentTypes/GetAppointmentTypeForSchedule'
    )}?id=${id}`
  );

  return normalizeClientAppointmentTypeViewModel(response.data);
}

export async function getClientEvent(id: number) {
  const response: AxiosResponse<EventForScheduleViewModel> = await axios.get(
    `${getClientApiLink('Events/GetCoachEventForSchedule')}?id=${id}`
  );

  return normalizeClientEventViewModel(response.data);
}

export async function getClientAppointment(id: number) {
  console.warn('__TRY__ API TO GET SCHEDULED (INVITED) APPOINTMENT!');
  const response: AxiosResponse<AppointmentFullViewModel> = await axios.get(
    `${getClientApiLink(
      'Appointment/GetAppointmentForClientWithoutToken'
    )}/?id=${id}`
  );
  return normalizeClientFullAppointmentViewModel(response.data);
}

export async function sendForgotPasswordRequest(data: ForgotPasswordViewModel) {
  return await axios.post(`${getGlobal('api')}/Auth/ForgotPassword`, data);
}

export async function sendClientForgotPasswordRequest(
  data: ForgotPasswordViewModel
) {
  return await axios.post(`${getClientApiLink('Auth/ForgotPassword')}`, data);
}

export async function getContacts() {
  const response: AxiosResponse<CoachContactsViewModel[]> = await axios.get(
    `${getGlobal('api')}/Contact/Contacts`
  );

  return response.data.map(normalizeContactFromBackEnd);
}

export async function addContact(data: CoachContactsViewModel) {
  const response: AxiosResponse<CoachContactsViewModel> = await axios.post(
    `${getGlobal('api')}/Contact`,
    data
  );
  return normalizeContactFromBackEnd(response.data);
}

export async function deleteContact(id: string) {
  return await axios.delete(`${getGlobal('api')}/Contact/${id}`);
}

export async function editContact(id: string, data: CoachContactsViewModel) {
  const response: AxiosResponse<CoachContactsViewModel> = await axios.put(
    `${getGlobal('api')}/Contact/${id}`,
    data
  );
  return response.data;
}

export async function resetContactPassword(userId: string) {
  return await axios.post(
    `${getGlobal('api')}/Auth/ResetClientPasswordByCoach?clientId=${userId}`,
    {}
  );
}

export async function reSendClientConfirmationLink(userId: string) {
  return await axios.get(
    `${getClientApiLink('Auth/ResendConfirmationLink')}?clientId=${userId}`
  );
}

export async function sendNewPasswordRequest(data: ResetPasswordViewModel) {
  return await axios.post(`${getGlobal('api')}/Auth/ResetPassword`, data);
}

export async function sendClientNewPasswordRequest(
  data: ResetPasswordViewModel
) {
  return await axios.post(getClientApiLink('Auth/ResetPassword'), data);
}

export async function isSiteNameExists(siteName: string) {
  const response: AxiosResponse<boolean> = await axios.get(
    `${getGlobal('api')}/Auth/IsSiteNameExists?siteName=${siteName}`
  );
  return response;
}

export async function getClientMotivationMessage(): Promise<
  WelcomeMessageData
> {
  const response: AxiosResponse<MotivationMessageViewModel> = await axios.get(
    `${getClientApiLink('CoachPortal/GetMotivationMessage')}`
  );
  try {
    return JSON.parse(response.data.text);
  } catch (e) {
    return {
      welcomeText: '',
      message: response.data.text
    };
  }
}

export async function getMotivationMessage(): Promise<WelcomeMessageData> {
  const response: AxiosResponse<MotivationMessageViewModel> = await axios.get(
    `${getGlobal('api')}/CoachPortal/GetMotivationMessage`
  );
  try {
    return JSON.parse(response.data.text);
  } catch (e) {
    return {
      welcomeText: '',
      message: response.data.text
    };
  }
}

export async function updateMotivationMessage(messageData: WelcomeMessageData) {
  return await axios.put(
    `${getGlobal('api')}/CoachPortal/UpdateMotivationMessage`,
    { text: JSON.stringify(messageData) }
  );
}

export async function isClientResetPasswordTokenValid(
  model: ValidateTokenViewModel
) {
  const response: AxiosResponse<boolean> = await axios.post(
    `${getClientApiLink('Auth/IsResetPasswordTokenValid')}`,
    model
  );

  return response.data;
}

export async function isCoachResetPasswordTokenValid(
  model: ValidateTokenViewModel
) {
  const response: AxiosResponse<boolean> = await axios.post(
    `${getGlobal('api')}/Auth/IsResetPasswordTokenValid`,
    model
  );

  return response.data;
}

export async function uploadFileCoachFilesLibrary(
  file: Blob,
  name: string,
  description: string
) {
  const formData = new FormData();
  formData.append('File', file);
  formData.append('Name', name);
  formData.append('Description', description);
  formData.append('Size', String(file.size));
  const response: AxiosResponse<FileResponseViewModel> = await axios.post(
    `${getGlobal('api')}/FilesLibrary/UploadFile`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
  );
  return response.data;
}
export async function uploadFileLinksCoachLibrary(
  linksArr: { Name: string; FileLink: string }[]
) {
  const response: AxiosResponse<FileResponseViewModel[]> = await axios.post(
    `${getGlobal('api')}/FilesLibrary/UploadFileLinks`,
    linksArr
  );
  return response.data;
}
export async function editLibraryItem(
  id: number,
  data: Partial<FileResponseViewModel>
) {
  const response: AxiosResponse<FileResponseViewModel> = await axios.put(
    `${getGlobal('api')}/FilesLibrary/${id}`,
    {
      ...data
    }
  );
  return response.data;
}

export async function getCoachFilesLibrary() {
  const response: AxiosResponse<FileResponseViewModel[]> = await axios.get(
    `${getGlobal('api')}/FilesLibrary`
  );
  return response.data.map(normalizeFileFromBackEnd);
}
export async function getCoachFilesLibraryFileById(id: number) {
  const response: AxiosResponse<FileResponseViewModel> = await axios.get(
    `${getGlobal('api')}/FilesLibrary/${id}`
  );
  return normalizeFileFromBackEnd(response.data);
}

export async function deleteFileCoachFilesLibrary(fileId: number) {
  return await axios.delete(`${getGlobal('api')}/FilesLibrary/${fileId}`);
}

export async function getCoachFilesLibraryFileID(fileId: number) {
  const response = await axios.get<string>(
    `${getGlobal('api')}/FilesLibrary/downloadFile/${fileId}`
  );

  return response.data;
}

export async function getSharedClientFiles() {
  const response: AxiosResponse<FileResponseViewModel[]> = await axios.get(
    `${getClientApiLink('FilesLibrary/GetAllShared')}`
  );
  return response.data.map(normalizeFileFromBackEnd);
}

export async function getSharedFileClients(fileId: number) {
  const response = await axios.get<string[]>(
    `${getGlobal('api')}/FilesLibrary/ClientFiles/${fileId}`
  );
  return response.data;
}

export async function postShareFile(fileIds: number[], clientIds: string[]) {
  const response = await axios.post(
    `${getGlobal('api')}/FilesLibrary/ShareFiles`,
    {
      fileIds,
      clientIds
    }
  );
  return response.data;
}

export async function unShareFiles(fileIds: number[], clientIds: string[]) {
  const response = await axios.post(
    `${getGlobal('api')}/FilesLibrary/UnShareFiles`,
    {
      fileIds,
      clientIds
    }
  );
  return response.data;
}

export async function getClientFilesLibraryFileID(fileId: number) {
  const response = await axios.get<string>(
    `${getClientApiLink(`FilesLibrary/downloadFile/${fileId}`)}`
  );

  return response.data;
}

export async function getNotes(clientId?: string) {
  const response = await axios.get<NoteViewModel[]>(
    `${getGlobal('api')}/Notes${clientId ? '?clientId=' + clientId : ''}`
  );
  return response.data.map(normalizeNoteFromBackEnd);
}

export async function getNotesClient() {
  const response = await axios.get<NoteViewModel[]>(
    `${getClientApiLink('Notes')}`
  );
  return response.data.map(normalizeNoteFromBackEnd);
}

export async function postNote(note: NoteViewModel) {
  const response = await axios.post<NoteViewModel>(
    `${getGlobal('api')}/Notes`,
    { ...note }
  );

  return response.data;
}

export async function editNote(note: NoteViewModel) {
  const response = await axios.put<NoteViewModel>(
    `${getGlobal('api')}/Notes/${note.id}`,
    {
      ...note
    }
  );
  return response.data;
}
export async function deleteNote(noteId: number) {
  const response = await axios.delete(`${getGlobal('api')}/Notes/${noteId}`);
  return response.data;
}

export async function getClientSchedule(clientId: string) {
  const response = await axios.get<AllAppointmentsAndEventsViewModel>(
    `${getGlobal('api')}/CoachPortal/GetAllEventsAndAppointments`,
    { params: { clientId } }
  );
  return response.data;
}

export async function getClientInvoicesCoach(clientId: string) {
  const response = await axios.get<ClientInvoiceViewModel>(
    `${getGlobal('api')}/Contact/ClientInvoice`,
    { params: { clientId } }
  );
  return normalizeInvoicesData(response.data);
}

export async function refundClientPayment(stripePaymentIntentId: string) {
  const response = await axios.post(
    `${getGlobal(
      'api'
    )}/Stripe/CreateRefund?stripePaymentIntentId=${stripePaymentIntentId}`
  );
  return response.data;
}

export async function getInvoicesClient() {
  const response = await axios.get<ClientInvoiceViewModel>(
    `${getClientApiLink('Contact/ClientInvoice')}`
  );
  return normalizeInvoicesData(response.data);
}

export async function disconnectZoom() {
  const response = await axios.get(`${getGlobal('api')}/disconnect/zoom`);
  return response.data;
}

export async function disconnectGoogle() {
  const response = await axios.get(`${getGlobal('api')}/disconnect/google`);
  return response.data;
}

export async function disconnectStripe() {
  const response = await axios.get(`${getGlobal('api')}/disconnect/oauth`);
  return response.data;
}

export async function getClientInfoByEmail(email: string) {
  const response = await axios.get<any>( //will be updated after BE model come
    `${getClientApiLink('Auth/GetClientInfo')}`,
    {
      params: {
        email
      }
    }
  );
  return response.data;
}

export async function getAllCoachesSuperAdmin() {
  const response = await axios.get<CoachAccountViewModel[]>(
    `${getGlobal('api')}/SuperAdmin/Coaches`
  );

  return response.data
    .sort(sortArrayBy('createdOn', SortOrder.Desc))
    .map(normalizeCoachFromBackEnd);
}

export async function getCoachSuperAdmin(id: string) {
  const response = await axios.get<CoachAccountViewModel>(
    `${getGlobal('api')}/SuperAdmin/Coaches/${id}`
  );

  return normalizeCoachAccountData({
    ...response.data,
    siteName: response.data.siteName || '',
    timeZone: response.data.timeZone || ''
  });
}

export async function updateCoachAccountSuperAdmin(
  id: string,
  data: CoachAccountViewModel
) {
  const response: AxiosResponse = await axios.put(
    `${getGlobal('api')}/SuperAdmin/Coaches/${id}`,
    normalizeCoachAccountData(data)
  );
  return response.data;
}

export async function updateCoachAccountIsAdmin(id: string, isAdmin: boolean) {
  const response: AxiosResponse = await axios.put(
    `${getGlobal('api')}/SuperAdmin/Coaches/${id}/IsAdmin?isAdmin=${isAdmin}`,
    {}
  );
  return response.data;
}

export async function resetCoachPasswordSuperAdmin(id: string) {
  const response: AxiosResponse = await axios.post(
    `${getGlobal('api')}/SuperAdmin/Coaches/${id}/ResetPassword`
  );
  return response.data;
}

export async function createCoach(data: CoachCreateViewModel) {
  return await axios.post(`${getGlobal('api')}/SuperAdmin/Coaches`, data);
}

export async function getAppointmentBlockInvalidZoom() {
  const { data } = await axios.get(
    `${getGlobal('api')}/AppointmentTypes/HasInvalid`
  );
  return data;
}

export async function getCoachInvoices(
  sort: SortParams<Invoice>,
  status: InvoiceStatusEnum = null
) {
  const response = await axios.get<InvoiceServiceViewModel[]>(
    `${getGlobal('api')}/Invoices`,
    {
      params: {
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        status
      }
    }
  );

  return response.data.map(normalizeInvoiceFromBackEnd);
}

export async function getCoachTaxOptions() {
  const response = await axios.get<InvoiceTaxViewModel[]>(
    `${getGlobal('api')}/Invoices/Taxes`
  );

  return response.data.map(normalizeCoachTaxFromBackEnd);
}

export async function createCoachInvoice(data: Invoice) {
  const response = await axios.post<
    CreateInvoiceViewModel,
    AxiosResponse<InvoiceViewModel>
  >(`${getGlobal('api')}/Invoices`, normalizeCoachInvoiceForBackEnd(data));

  return normalizeInvoiceFromBackEnd(response.data);
}

export async function getCoachInvoice(id: string | number) {
  const response = await axios.get<InvoiceServiceViewModel>(
    `${getGlobal('api')}/Invoices/${id}`
  );

  return normalizeInvoiceFromBackEnd(response.data);
}

export async function updateCoachInvoice(data: Invoice) {
  const response = await axios.put<InvoiceServiceViewModel>(
    `${getGlobal('api')}/Invoices/${data.id}`,
    normalizeCoachInvoiceForBackEnd(data)
  );

  return normalizeInvoiceFromBackEnd(response.data);
}

export async function deleteCoachInvoice(id: string | number) {
  const response = await axios.delete<unknown>(
    `${getGlobal('api')}/Invoices/${id}`
  );

  return response.data;
}

export async function updateCoachInvoiceStatus(
  id: string | number,
  status: number
) {
  const response = await axios.put<{}, unknown>(
    `${getGlobal('api')}/Invoices/${id}/updateStatus?status=${status}`,
    {}
  );

  return response;
}

export async function refundCoachInvoice(id: string | number) {
  const response = await axios.post<{}, unknown>(
    `${getGlobal('api')}/Invoices/${id}/refund`,
    {}
  );

  return response;
}

export async function refundClientInvoice(id: string | number) {
  const response = await axios.post<{}, unknown>(
    `${getClientApiLink(`/Invoices/${id}/refund`)}`,
    {}
  );

  return response;
}

export async function getClientInvoices(
  sort: SortParams<Invoice>,
  status: InvoiceStatusEnum = null
) {
  const response = await axios.get<InvoiceViewModel[]>(
    `${getClientApiLink('Invoices')}`,
    {
      params: {
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        status
      }
    }
  );

  return response.data.map(normalizeInvoiceFromBackEnd);
}

export async function getClientInvoice(id: string | number) {
  const response = await axios.get<InvoiceServiceViewModel>(
    getClientApiLink(`Invoices/${id}`)
  );

  return normalizeInvoiceFromBackEnd(response.data);
}

export async function getClientInvoiceByNumber(num: string) {
  const response = await axios.get<InvoiceViewModel>(
    getClientApiLink(`Invoices/byNumber/${num}`)
  );

  const invoice = normalizeInvoiceFromBackEnd(response.data);

  if (
    invoice.type === InvoiceTypeEnum.Recurring &&
    response.data.stripeSubscriptionId
  ) {
    try {
      const subsResponse = await axios.post<
        InvoiceSubscriptionRequest,
        AxiosResponse<StripeSubscription>
      >(`${getClientApiLink('Stripe/GetSubscriptionForInvoice')}`, {
        invoiceNumber: invoice.number,
        clientId: invoice.client.clientId
      });

      invoice.subscription = subsResponse.data;
      if (
        subsResponse.data?.latest_invoice?.payment_intent?.payment_method &&
        subsResponse.data?.latest_invoice?.payment_intent?.payment_method.card
      ) {
        invoice.paymentMethod =
          subsResponse.data?.latest_invoice?.payment_intent?.payment_method;
      } else {
        const pmResponse = await axios.post<
          InvoiceSubscriptionRequest,
          AxiosResponse<StripePaymentMethod>
        >(`${getClientApiLink('Stripe/GetPaymentMethodForInvoice')}`, {
          invoiceNumber: invoice.number,
          clientId: invoice.client.clientId
        });
        invoice.paymentMethod = pmResponse.data;
      }
    } catch {}
  }

  return invoice;
}

export async function deleteClientIncoice(id: number) {
  const response = await axios.delete<Invoice>(
    `${getClientApiLink(`Invoices/${id}`)}`
  );

  return response.data;
}

export async function clientPayInvoice(id: number, clientId?: string) {
  const response = await axios.post<PaymentItemDetailsViewModel>(
    `${getClientApiLink(
      `Invoices/${id}/pay${clientId ? '?clientId=' + clientId : ''}`
    )}`,
    {}
  );
  return response.data.clientSecret;
}

export async function clientRestoreUnpaidInvoice(
  id: number,
  clientId?: string
) {
  const response = await axios.post<PaymentItemDetailsViewModel>(
    `${getClientApiLink(
      `Invoices/${id}/unpaid${clientId ? '?clientId=' + clientId : ''}`
    )}`,
    {}
  );
  return response.data.clientSecret;
}

export async function updateClientInvoiceStatus(id: number, status: number) {
  const response = await axios.put<Invoice>(
    `${getClientApiLink(`Invoices/${id}/updateStatus?status=${status}`)}`
  );

  return response.data;
}

// export async function getChartCoachPayments(
//   startDate: number,
//   endDate: number
// ) {
//   const today = getCurrentDayTimeStamp();
//   let data: CoachPayment[];

//   const normalizeData = (data: CoachPayment[]) => {
//     return data.reduce((acc: CoachPayment[], item) => {
//       const compareItem = acc[acc.length - 1];
//       if (
//         compareItem &&
//         moment.utc(compareItem.timestamp).format('DD MM YYYY') ===
//           moment.utc(item.timestamp).format('DD MM YYYY')
//       ) {
//         acc[acc.length - 1] = {
//           ...compareItem,
//           amount: compareItem.amount + item.amount
//         };
//       } else {
//         acc.push(item);
//       }

//       return acc;
//     }, []);
//   };

//   if (
//     moment.utc(startDate).format('DD MM YYYY') ===
//     moment.utc(endDate).format('DD MM YYYY')
//   ) {
//     data = [
//       { timestamp: startDate + 13 * 60 * 60 * 1000, amount: 25 },
//       { timestamp: startDate + 17 * 60 * 60 * 1000, amount: 124 },
//       { timestamp: startDate + 20 * 60 * 60 * 1000, amount: 13 }
//     ];
//   } else {
//     data = [
//       { timestamp: 1607990400000, amount: 50 },
//       { timestamp: 1606773600000, amount: 100 },
//       { timestamp: 1607299200000, amount: 125 },
//       { timestamp: 1608163200000, amount: 150 },
//       { timestamp: 1608854400000, amount: 400 },
//       { timestamp: 1609200000000, amount: 300 },
//       { timestamp: 1609372800000, amount: 700 },
//       { timestamp: 1610409600000, amount: 400 },
//       { timestamp: 1610409600000, amount: 450 },
//       { timestamp: 1610409600000, amount: 474 },
//       { timestamp: 1610409600000, amount: 100 },
//       { timestamp: 1610409600000, amount: 222 },
//       { timestamp: 1610409600000, amount: 11.5 },
//       { timestamp: 1610409600000, amount: 400 },
//       { timestamp: today, amount: 1000 }
//     ];

//     data = normalizeData(data);
//   }

//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(
//         data.filter((item) => {
//           return item.timestamp >= startDate && item.timestamp <= endDate;
//         })
//       );
//     }, 300);
//   }) as Promise<CoachPayment[]>;
// }

// export async function getComparableCoachPayments(date: number) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         today: {
//           amount: 162
//         },
//         yesterday: {
//           amount: 300,
//           comparable: 125
//         },
//         week: {
//           amount: 1250,
//           comparable: 1700
//         },
//         month: {
//           amount: 3000,
//           comparable: 2250
//         }
//       });
//     }, 300);
//   }) as Promise<CoachComparablePayments>;
// }

export async function getCoachPayments(
  sort: SortParams<Payment>,
  status: PaymentStatusEnum = null
) {
  const response = await axios.get<PaymentItemViewModel[]>(
    `${getGlobal('api')}/Payments`,
    {
      params: {
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
        status
      }
    }
  );

  return response.data.map(normalizeCoachPaymentFromBackEnd);
}

export async function deleteCoachPayment(id: string) {
  return await axios.delete<unknown>(`${getGlobal('api')}/Payments/${id}`);
}

export async function deleteClientPayment(id: string) {
  return await axios.delete<unknown>(`${getClientApiLink(`Payments/${id}`)}`);
}

export async function createInvoiceSubscription(
  invoiceNumber: string,
  paymentMethodId: string,
  clientId: string
) {
  const response = await axios.post<
    InvoiceSubscriptionRequest,
    AxiosResponse<StripeSubscription>
  >(`${getClientApiLink('Stripe/CreateSubscriptionForInvoice')}`, {
    invoiceNumber,
    paymentMethodId,
    clientId
  });

  return response.data;
}

export async function updateInvoiceSubscription(
  invoiceNumber: string,
  paymentMethodId: string,
  clientId: string
) {
  const response = await axios.put<
    InvoiceSubscriptionRequest,
    AxiosResponse<StripeSubscription>
  >(`${getClientApiLink('Stripe/UpdateSubscriptionForInvoice')}`, {
    invoiceNumber,
    paymentMethodId,
    clientId
  });

  return response.data;
}

export async function cancelInvoiceSubscription(invoiceNumber: string) {
  const response = await axios.put<
    InvoiceSubscriptionRequest,
    AxiosResponse<StripeSubscription>
  >(`${getClientApiLink('Stripe/CancelSubscriptionForInvoice')}`, {
    invoiceNumber
  });

  return response.data;
}

let getCoachClientBillingCancel: Canceler;
export async function getCoachClientBilling(
  clientId: string,
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: SortOrder,
  searchValue = ''
) {
  if (getCoachClientBillingCancel !== undefined) {
    getCoachClientBillingCancel();
  }
  const response: AxiosResponse<PaginatedResponse<
    BillingItemViewModel
  >> = await axios.get(`${getGlobal('api')}/Billing`, {
    params: {
      clientId,
      page,
      pageSize,
      sortBy,
      sortOrder,
      searchValue
    },
    cancelToken: new axios.CancelToken((cancel) => {
      getClientBillingCancel = cancel;
    })
  });
  return {
    ...response.data,
    items: response.data.items.map((i) => ({
      ...i,
      date: getTimeInZone(i.date, RootStore.userStore.timeZone),
      invoice: i.invoice
        ? {
            ...i.invoice,
            startDate: getTimeInZone(
              i.invoice.startDate,
              RootStore.userStore.timeZone
            )
          }
        : null,
      appointment: i.appointment
        ? {
            ...i.appointment,
            timestamp: getTimeInZone(
              i.appointment.timestamp,
              RootStore.userStore.timeZone
            )
          }
        : null,
      coachEvent: i.coachEvent
        ? {
            ...i.coachEvent,
            timestamp: getTimeInZone(
              i.coachEvent.timestamp,
              RootStore.userStore.timeZone
            )
          }
        : null
    }))
  };
}

let getClientBillingCancel: Canceler;
export async function getClientBilling(
  page: number,
  pageSize: number,
  sortBy: string,
  sortOrder: SortOrder,
  searchValue = ''
) {
  if (getClientBillingCancel !== undefined) {
    getClientBillingCancel();
  }
  const response: AxiosResponse<PaginatedResponse<
    BillingItemViewModel
  >> = await axios.get(`${getClientApiLink('Billing')}`, {
    params: {
      page,
      pageSize,
      sortBy,
      sortOrder,
      searchValue
    },
    cancelToken: new axios.CancelToken((cancel) => {
      getClientBillingCancel = cancel;
    })
  });
  return {
    ...response.data,
    items: response.data.items.map((i) => ({
      ...i,
      date: getTimeInZone(i.date, RootStore.userStore.timeZone),
      invoice: i.invoice
        ? {
            ...i.invoice,
            startDate: getTimeInZone(
              i.invoice.startDate,
              RootStore.userStore.timeZone
            )
          }
        : null,
      appointment: i.appointment
        ? {
            ...i.appointment,
            timestamp: getTimeInZone(
              i.appointment.timestamp,
              RootStore.userStore.timeZone
            )
          }
        : null,
      coachEvent: i.coachEvent
        ? {
            ...i.coachEvent,
            timestamp: getTimeInZone(
              i.coachEvent.timestamp,
              RootStore.userStore.timeZone
            )
          }
        : null
    }))
  };
}
