import React from 'react';
import './ScheduleTile.styles.scss';
import { iBookingItem, BookingItemTypeEnum } from '../../core/types';
import { EventStatus } from '../../core/enums';
import Button from '../Button/Button';
import ImageIcon from '../../icons/image.svg';
import DollarIcon from '../../icons/dollar-circle.svg';
import { getPublicFile } from '../../core/api';
import moment from 'moment';
import { getFormattedDuration } from '../../core/helpers';
import TextEllipse from '../TextEllipse';

interface iProps {
  data: iBookingItem;
  siteName: string;
  showImage?: boolean;
  className?: string;
}

const ScheduleTile: React.FC<iProps> = ({
  data,
  siteName,
  showImage = true,
  className = ''
}) => {
  const {
    name,
    durationMinutes,
    status,
    description,
    thumbnailImageId,
    type,
    timestamp,
    hasPrice
  } = data;
  const isCanceled = status === EventStatus.Canceled;
  return (
    <div
      className={`ScheduleTile ${className} ${
        isCanceled ? 'ScheduleTile--canceled' : ''
      }`}
    >
      <div className='ScheduleTile__inner'>
        <div className='ScheduleTile__content'>
          {type !== BookingItemTypeEnum.Meeting && (
            <div className='ScheduleTile__duration'>
              {getFormattedDuration(durationMinutes)}
            </div>
          )}
          {type === BookingItemTypeEnum.Meeting && (
            <div className='ScheduleTile__duration'>
              {moment.utc(timestamp).format('MMM D, hh:mm a')}
            </div>
          )}
          <div className='d-flex mt-2'>
            <div className='ScheduleTile__name flex-grow-1'>
              <TextEllipse text={name} lines={2}>
                <span>{name}</span>
              </TextEllipse>
            </div>
            {hasPrice && (
              <span className='flex-shrink-0'>
                <DollarIcon />
              </span>
            )}
          </div>
          <div
            className='ScheduleTile__desctiption'
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </div>
        <div className='ScheduleTile__image'>
          <div className='ScheduleTile__image-inner'>
            {thumbnailImageId ? (
              <img
                src={showImage ? getPublicFile(thumbnailImageId) : ''}
                alt=''
                className='ScheduleTile__img'
              />
            ) : (
              <ImageIcon />
            )}
          </div>
        </div>
      </div>
      <Button
        link={siteName ? `/${siteName}/${data.link}` : ''}
        className='button__ScheduleTile'
      >
        {type !== BookingItemTypeEnum.Meeting
          ? 'Schedule this now'
          : 'Register for this'}
      </Button>
    </div>
  );
};

export default ScheduleTile;
