import React, { ReactNode } from 'react';
import Scrollbars from 'react-custom-scrollbars';

interface Props {
  children: ReactNode;
  className?: string;
  setScrollbarsRef?: (node: Scrollbars) => void;
}

const CustomizeSidebarTab: React.FC<Props> = ({
  children,
  setScrollbarsRef,
  className = ''
}) => (
  <div className='CustomizeSidebar__tab d-flex'>
    <Scrollbars
      style={{ height: 'auto', minHeight: '100%' }}
      ref={setScrollbarsRef ? (node) => setScrollbarsRef(node) : undefined}
    >
      <div className={`CustomizeSidebar__tab-inner ${className}`}>
        {children}
      </div>
    </Scrollbars>
  </div>
);

export default CustomizeSidebarTab;
