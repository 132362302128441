import { useState, useEffect, useCallback } from 'react';

export function useLazyLoad<T>(
  container: HTMLElement,
  load: (
    pageNumber: number,
    pageSize: number
  ) => Promise<{ items: T[]; totalCount: number }>,
  pageSize: number
) {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [data, setData] = useState<{ items: T[]; totalCount: number }>({
    items: [],
    totalCount: 0
  });

  const loadData = useCallback(
    (pageNumber: number, pageSize: number, concat = true) => {
      setIsLoading(true);
      return load(pageNumber, pageSize)
        .then((response) => {
          setData((prev) => ({
            totalCount: response.totalCount,
            items: concat ? prev.items.concat(response.items) : response.items
          }));
          return response;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [load]
  );

  const onScroll = useCallback(() => {
    if (
      !isLoading &&
      data.items.length < data.totalCount &&
      container.scrollTop + container.clientHeight > container.scrollHeight - 50
    ) {
      loadData(pageNumber + 1, pageSize).then(() => {
        setPageNumber((prev) => prev + 1);
      });
    }
  }, [isLoading, pageSize, pageNumber, loadData]);

  const reLoad = useCallback(() => {
    loadData(1, pageNumber * pageSize, false);
  }, [loadData, pageNumber, pageSize]);

  useEffect(() => {
    loadData(1, pageSize, false);
  }, []);

  useEffect(() => {
    if (container) {
      container.addEventListener('scroll', onScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', onScroll);
      }
    };
  }, [container, onScroll]);

  return {
    data,
    reLoad
  };
}
