import React from 'react';
import { createPortal } from 'react-dom';
export interface IProps {
  children: JSX.Element;
}
const PageHeaderContent: React.FC<IProps> = ({ children }) => {
  const headerElement = document.getElementById('headerContent');
  return headerElement && createPortal(<>{children}</>, headerElement);
};
export default PageHeaderContent;
