import React, { useCallback, useState, useEffect, useRef } from 'react';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import ModalFullHeader from '../../components/ModalFullHeader/ModalFullHeader';
import ArrowIcon from '../../icons/arrow-left.svg';
import CloseIcon from '../../icons/cross.svg';
import { useHistory, useParams } from 'react-router-dom';
import {
  CoachRoutesEnum,
  InvoiceStatusEnum,
  NotificationTypesEnum
} from '../../core/enums';
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader';
import {
  iCoachPageCustomizationData,
  Invoice,
  InvoiceTax
} from '../../core/types';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react';
import { getDefaultData } from './CoachInvoice.helpers';
import { getDefaultData as getCustomizationDefaultData } from '../../components/CustomizeSidebar/CustomizeSidebar.helpers';
import {
  addContact,
  createCoachInvoice,
  getCoachAccount,
  getCoachInvoice,
  getCoachTaxOptions,
  updateCoachInvoice
} from '../../core/api';
import CoachInvoiceBranding from './CoachInvoiceBranding/CoachInvoiceBranding';
import { CoachAccountViewModel } from '../../core/backend/api';
import SendInvoiceModal from '../../components/Modals/SendInvoiceModal/SendInvoiceModal';
import { useSendInvoiceModal } from '../../components/Modals/SendInvoiceModal/useSendInvoiceModal';
import './CoachInvoice.styles.scss';
import CoachInvoiceForm from './CoachInvoiceForm/CoachInvoiceForm';
import { InvoiceCustomizeData } from '../../core/types';
import { isFormDataValid } from './CoachInvoiceForm/CoachInvoiceForm.helpers';
import PreventUnsavedChangesModal from '../../components/Modals/PreventUnsavedEditsModal/PreventUnsavedEditsModal';
import { getOffsetTop } from '../../core/helpers';

type Steps = 'form' | 'branding';

const CoachInvoice = () => {
  const { rootStore } = useStores();
  const { userStore, contactsStore, notificationStore } = rootStore;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [localData, setLocalData] = useState<Invoice>(undefined);
  const [initialData, setInitialData] = useState<Invoice>(getDefaultData());
  const [taxOptions, setTaxOptions] = useState<InvoiceTax[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isValidationShown, setIsValidationShown] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>();
  const [currentStep, setCurrentStep] = useState<Steps>('form');
  const coachCustomizationDataRef = useRef<InvoiceCustomizeData>();
  const isFormStep = currentStep === 'form';

  useEffect(() => {
    getCoachTaxOptions().then((data) => {
      setTaxOptions(data);
    });
  }, []);

  useEffect(() => {
    if (!!localData) {
      if (localData?.status !== InvoiceStatusEnum.draft) {
        history.push(CoachRoutesEnum.Invoice.replace(':id', id));
      }
    }
  }, [localData, history, id]);

  useEffect(() => {
    if (!coachCustomizationDataRef.current) {
      getCoachAccount()
        .then((data: CoachAccountViewModel) => {
          let customizationData = getCustomizationDefaultData();
          try {
            if (data.siteCustomizationJson) {
              const {
                primaryColor,
                secondaryColor,
                paragraphColor,
                headlineColor,
                logoSrc
              } = JSON.parse(
                data.siteCustomizationJson
              ) as iCoachPageCustomizationData;
              customizationData = {
                ...customizationData,
                primaryColor,
                secondaryColor,
                paragraphColor,
                headlineColor,
                logoSrc
              };
            }
          } finally {
          }

          coachCustomizationDataRef.current = {
            primaryColor: customizationData.primaryColor,
            backgroundColor: customizationData.secondaryColor,
            paragraphColor: customizationData.paragraphColor,
            headlineColor: customizationData.headlineColor,
            logoSrc: customizationData.logoSrc
          };

          if (!id) {
            setLocalData((prevData) => {
              const newData = {
                ...prevData,
                customizeData: {
                  ...prevData.customizeData,
                  ...coachCustomizationDataRef.current
                }
              };
              setInitialData(newData);
              return newData;
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, coachCustomizationDataRef]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getCoachInvoice(id)
        .then((res: Invoice) => {
          setLocalData(res);
          setInitialData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.status === 404) {
            notificationStore.addNotification({
              text: 'Invoice not found',
              type: NotificationTypesEnum.Error
            });
            history.push(CoachRoutesEnum.Invoices);
          }
        });
    } else {
      setLocalData(getDefaultData());
    }
  }, [history, id, notificationStore]);

  useEffect(() => {
    if (isValidationShown) {
      const invalidInput = document.querySelector<HTMLElement>(
        '.CoachInvoiceForm__items-row--error, .FormInput__input--error'
      );
      if (invalidInput) {
        modalRef.current
          .querySelector<HTMLElement>('.Scrollbar')
          .scrollTo(0, getOffsetTop(invalidInput, modalRef.current) - 74);
        return;
      }
    }
  }, [isValidationShown]);

  const handleClose = () => {
    history.push(rootStore.prevUrl || CoachRoutesEnum.Invoices);
  };

  const handleNextStepClick = () => {
    if (isFormDataValid(localData)) {
      setCurrentStep('branding');
    } else {
      setIsValidationShown(true);
    }
  };
  const handlePreviouseStepClick = () => {
    setCurrentStep('form');
  };

  const handleChangeField = useCallback(
    (field: keyof Invoice) => (value: any) => {
      setLocalData((prevLocalData) => ({
        ...prevLocalData,
        [field]: value
      }));
    },
    []
  );

  const save = async (send = false) => {
    const apiMethod = localData.id ? updateCoachInvoice : createCoachInvoice;
    setIsSubmitting(true);

    try {
      const client = !localData.client.clientId
        ? await addContact({ ...localData.client }).then((data) => {
            contactsStore.getCoachContacts();
            return data;
          })
        : localData.client;
      await apiMethod({
        ...localData,
        client,
        status: send ? InvoiceStatusEnum.open : InvoiceStatusEnum.draft
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSave = () => {
    setIsValidationShown(false);

    if (isFormDataValid(localData)) {
      save()
        .then(() => {
          notificationStore.addNotification({
            text: `Draft of the invoice is saved`
          });
          setInitialData({ ...localData });
          setTimeout(() => {
            history.push(CoachRoutesEnum.Invoices);
          }, 100);
        })
        .catch((err) => {
          if (err.status === 404) {
            notificationStore.addNotification({
              text: 'Invoice not found',
              type: NotificationTypesEnum.Error
            });
            setLocalData(initialData);
            setTimeout(() => {
              history.push(CoachRoutesEnum.Invoices);
            }, 100);
          }
        });
    } else {
      setIsValidationShown(true);
      setCurrentStep('form');
    }
  };

  const handleSend = () => {
    sendInvoice()
      .then(() => {
        notificationStore.addNotification({
          text: 'Invoice sent'
        });
        setInitialData({ ...localData });
        setTimeout(() => {
          history.push(CoachRoutesEnum.Invoices);
        }, 100);
      })
      .catch((err) => {
        if (err.status === 404) {
          setLocalData(initialData);
          notificationStore.addNotification({
            text: 'Invoice not found',
            type: NotificationTypesEnum.Error
          });
          setTimeout(() => {
            history.push(CoachRoutesEnum.Invoices);
          }, 100);
        }
      });
  };

  const {
    isSendInvoiceModalOpened,
    isSending,
    closeSendInvoiceModal,
    openSendInvoiceModal,
    sendInvoice
  } = useSendInvoiceModal(localData, () => save(true));

  const renderStep = () => {
    if (!localData) return null;
    switch (currentStep) {
      case 'form':
        return (
          <CoachInvoiceForm
            data={localData}
            clients={contactsStore.contacts}
            taxOptions={taxOptions}
            isValidationShown={isValidationShown}
            onChange={handleChangeField}
          />
        );
      case 'branding':
        return (
          <CoachInvoiceBranding
            invoice={localData}
            onChange={handleChangeField('customizeData')}
            coachAccountData={userStore.userData}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <GlobalLoader isLoading={isLoading || isSubmitting} />
      <Modal
        isOpened
        full
        className='CoachInvoice'
        style={{ overflow: 'auto' }}
        ref={modalRef}
      >
        <ModalFullHeader>
          <Button
            className='CoachInvoice__back'
            variations={['naked']}
            handleClick={isFormStep ? handleClose : handlePreviouseStepClick}
          >
            {isFormStep ? (
              <CloseIcon />
            ) : (
              <div className='d-flex align-items-center'>
                <ArrowIcon />
                <span className='CoachInvoice__title ml-2 pl-1'>
                  Previous Step
                </span>
              </div>
            )}
          </Button>
          {isFormStep && (
            <span className='CoachInvoice__title flex-grow-1 ml-2'>
              {id ? `# ${localData?.number || ''}` : 'New invoice'}
            </span>
          )}
          <div className='d-flex'>
            <Button
              className='button--sm'
              variations={['inversed']}
              handleClick={handleSave}
            >
              Save as Draft
            </Button>
            {isFormStep ? (
              <Button className='button--sm' handleClick={handleNextStepClick}>
                Next Step
              </Button>
            ) : (
              <Button className='button--sm' handleClick={openSendInvoiceModal}>
                Send invoice
              </Button>
            )}
          </div>
        </ModalFullHeader>
        {renderStep()}
      </Modal>
      {localData && (
        <>
          <SendInvoiceModal
            coachAccountData={userStore.userData}
            invoice={localData}
            onClose={closeSendInvoiceModal}
            isOpened={isSendInvoiceModalOpened}
            onSend={handleSend}
            isSending={isSending}
          />
          <PreventUnsavedChangesModal
            title={`${id ? 'Edit' : 'Create'} invoice`}
            onSave={handleSave}
            disableConfirmBtn={isSubmitting}
            initialData={JSON.stringify(initialData)}
            currentData={JSON.stringify(localData)}
          />
        </>
      )}
    </>
  );
};
export default observer(CoachInvoice);
