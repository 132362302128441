import axios, { AxiosResponse } from 'axios';
import {
  SignInInfoViewModel,
  UserViewModel,
  SignupClientAdditionalInfoViewModel
} from '../core/backend/api';
import { action, observable } from 'mobx';
import { UserStore } from '../core/types';
import getGlobal from '../core/globals';
import moment from 'moment-timezone';
import { getDefaultData } from '../pages/ClientCompleteRegistration/ClientCompleteRegistration.helpers';
import { signInClient, signOutClient } from '../core/api';
import { getTimezone } from '../core/helpers';
import { SignUpClientViewModel } from '../core/backend/api';

const TIMEZONE_DIFF_KEY = 'coachpro.timezone-diff-timestamp';
export default class ClientUserStore extends UserStore {
  @observable showEmailConfirmed: boolean = false;

  get lsTimeZoneLastCheckTimestampKey() {
    return `${TIMEZONE_DIFF_KEY}.${this.siteName}.${this.id}`;
  }

  constructor(siteName: string) {
    super();
    this.siteName = siteName;
    this.timeZone = getTimezone(moment.tz.guess());
    this.isSignedIn = false;

    // this.isSignedIn = true;
    // this.loadData();
  }

  @action
  setShowEmailConfirmed(value: boolean) {
    this.showEmailConfirmed = value;
  }

  @action signOut = () => {
    return new Promise((resolve, reject) => {
      signOutClient(this.siteName)
        .then(() => {
          this.isSignedIn = false;
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action signIn(data: SignInInfoViewModel) {
    return new Promise((resolve, reject) => {
      signInClient(this.siteName, data)
        .then(() => {
          this.isSignedIn = false;
          throw new Error('Something went wrong. Please contact support team');
          // this.isSignedIn = true;
          // this.loadData();
          // resolve('OK');
        })
        .catch((error) => {
          reject(error || 'Sign in failed, please check your credentials');
        });
    });
  }

  @action signUp(data: SignUpClientViewModel) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${getGlobal('api')}/${this.siteName}/Auth/SignUpClient`, data)
        .then(() => {
          this.isSignedIn = true;
          this.loadData();
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action completeRegistration(data: SignupClientAdditionalInfoViewModel) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${getGlobal('api')}/${this.siteName}/Auth/AddAdditionalInfoToClient`,
          data
        )
        .then(() => {
          this.loadData();
          resolve('OK');
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @action loadData() {
    this.isDataLoaded = false;
    return axios
      .get(`${getGlobal('api')}/${this.siteName}/Auth/GetUserInfo`)
      .then((response: AxiosResponse<UserViewModel>) => {
        this.isRegistrationCompleted = response.data.registrationCompleted;
        this.email = response.data.email;
        this.firstName = response.data.firstName;
        this.lastName = response.data.lastName;
        this.timeZone = response.data.timeZone;
        this.country = response.data.country;
        this.state = response.data.state;
        this.city = response.data.city;
        this.phoneNumber = response.data.phoneNumber;
        this.imageId = response.data.imageId;
        this.checkTimeZoneDiff();
        if (!this.isRegistrationCompleted) {
          this.completeRegistration(getDefaultData(this));
        } else {
          this.isDataLoaded = true;
        }
      })
      .catch((error) => {
        this.isSignedIn = false;
      });
  }
}
