import React from 'react';
import './CoachDashboardTile.styles.scss';

interface iProps {
  title?: string;
  titleUnderline?: boolean;
  className?: string;
}

const CoachDashboardTile: React.FC<iProps> = ({
  title,
  titleUnderline,
  children,
  className = ''
}) => {
  return (
    <div className={`CoachDashboardTile ${className}`}>
      <div
        className={`CoachDashboardTile__title ${
          titleUnderline ? 'CoachDashboardTile__title--underlined' : ''
        }`}
      >
        {title}
      </div>
      {children}
    </div>
  );
};

export default CoachDashboardTile;
